import { Preferences } from '@capacitor/preferences';

export interface IStorageOptions {
    envNeutral: boolean;
    userNeutral: boolean;
    storageType: 'localStorage' | '@capacitor/preferences';
    maxBytes: number;
    removeOnTooBig: boolean;
}

interface IStorageImplementation {
    getItem: (key: string) => Promise<string | undefined>;
    setItem: (key: string, value: string) => Promise<void>;
    removeItem: (key: string) => Promise<void>;

    // consider deprecating this since it usually leads to 2 async calls where putting the ownes on the caller would have resulted in only one
    exists: (key: string) => Promise<boolean>;
}

export class PreferencesStorageAdapter implements IStorageImplementation {
    getItem = async (key: string) => {
        const result = await Preferences.get({ key });
        return result.value || undefined;
    };

    setItem = async (key: string, value: string) => {
        await Preferences.set({ key, value });
    };

    removeItem = async (key: string) => {
        await Preferences.remove({ key });
    };

    exists = async (key: string) => {
        const { keys }  = await Preferences.keys();
        return keys.includes(key);
    };
}


class LocalStorageAdapter implements IStorageImplementation {
    getItem = async (key: string) => {
        return window.localStorage.getItem(key) || undefined;
    };

    setItem = async (key: string, value: string) => {
        window.localStorage.setItem(key, value);
    };

    removeItem = async (key: string) => {
        window.localStorage.removeItem(key);
    };

    exists = async (key: string) => {
        return window.localStorage.getItem(key) !== null;
    };
}

export interface ISmartStorageProviderV2<T> {
    exists: () => boolean;
    get: () => T | undefined;
    set: (value: T) => void;
    remove: () => void;
}
export interface IAsyncSmartStorageProvider<T> {
    exists: () => Promise<boolean>;
    get: () => Promise<T | undefined>;
    set: (value: T) => Promise<void>;
    remove: () => Promise<void>;
}

type ISupportedEnv = 'staging' | 'faa-testing' | 'prod';
export class StorageProvider {
    env: ISupportedEnv | 'unknown' = 'unknown';
    userId: string | undefined = undefined;

    private providers = {
        localStorage: new LocalStorageAdapter(),
        '@capacitor/preferences': new PreferencesStorageAdapter(),
    };

    private getStorageImplementation = (options?: Partial<IStorageOptions>): IStorageImplementation => {
        return this.providers[options?.storageType || '@capacitor/preferences'];
    };


    setEnv = (env: ISupportedEnv) => {
        this.env = env;
        return this;
    };

    setUserId = (userId: string) => {
        this.userId = userId;
        return this;
    };

    private get = (key: string, options?: Partial<IStorageOptions>): string | undefined => {
        const val = localStorage.getItem(this.getFullKey(key, options));
        if (!val){
            return undefined;
        }
        return val;
    };

    private getAsync = async (key: string, options?: Partial<IStorageOptions>): Promise<string | undefined> => {
        return await this.getStorageImplementation(options).getItem(this.getFullKey(key, options));
    };

    private exists = (key: string, options?: Partial<IStorageOptions>): boolean => {
        return localStorage.getItem(this.getFullKey(key, options)) !== null;
    };

    private existsAsync = async (key: string, options?: Partial<IStorageOptions>): Promise<boolean> => {
        return await this.getStorageImplementation(options).exists(this.getFullKey(key, options));
    };

    private remove = (key: string, options?: Partial<IStorageOptions>): void => {
        localStorage.removeItem(this.getFullKey(key, options));
    };

    private removeAsync = async (key: string, options?: Partial<IStorageOptions>): Promise<void> => {
        await this.getStorageImplementation(options).removeItem(this.getFullKey(key, options));
    };

    private getJSON = <T>(key: string, options?: Partial<IStorageOptions>): T | undefined => {
        const json = this.get(key, options);
        return json ? JSON.parse(json) as T : undefined;
    };

    private getJSONAsync = async <T>(key: string, options?: Partial<IStorageOptions>): Promise<T | undefined> => {
        const json = await this.getAsync(key, options);
        return json ? JSON.parse(json) as T : undefined;
    };

    private getFullKey = (key: string, options?: Partial<IStorageOptions>) => {
        options = options || {} as IStorageOptions;
        // default to NOT env neutral and NOT user neutral since that should be most things
        if (typeof options.envNeutral !== 'boolean'){
            options.envNeutral = false;
        }
        if (typeof options.userNeutral !== 'boolean'){
            options.userNeutral = false;
        }

        let fullKey = key;
        if (!options.envNeutral && this.env === 'unknown') {
            throw new Error(`attempted to get key "${key}" but env is not yet known`);
        }

        if (!options.userNeutral && !this.userId) {
            throw new Error(`attempted to get key "${key}" but userId is not yet known`);
        }

        if (!options.userNeutral) {
            fullKey = `${this.userId}:${fullKey}`;
        }

        if (!options.envNeutral) {
            fullKey = `${this.env}:${fullKey}`;
        }

        return fullKey;
    };

    private set = (key: string, value: string, options?: Partial<IStorageOptions>) => {
        localStorage.setItem(this.getFullKey(key, options), value);
    };

    private setAsync = async (key: string, value: string, options?: Partial<IStorageOptions>) => {
        const fullKey = this.getFullKey(key, options);
        if (options?.maxBytes && value.length >= options!.maxBytes){
            const error: any = new Error(`cannot save to ${key} b/c its length is ${value.length} which exceeds ${options!.maxBytes}`);
            error.errorCode = 'too-big';

            if (options?.removeOnTooBig){
                await this.getStorageImplementation(options).removeItem(fullKey);
            }

            throw error;
        }
        await this.getStorageImplementation(options).setItem(fullKey, value);
    };

    private setJSON = <T>(key: string, value: T, options?: Partial<IStorageOptions>) => {
        localStorage.setItem(this.getFullKey(key, options), JSON.stringify(value));
    };

    private setJSONAsync = async <T>(key: string, value: T, options?: Partial<IStorageOptions>) => {
        await this.setAsync(key, JSON.stringify(value), options);
    };

    /**
     * @deprecated the sync methods only work with local storage and so they should not be used
     *              b/c they make it harder to switch to preferences (or server managed state)
     *               when the time is appropriate
     */
    getStringProvider = (key: string, options: Partial<IStorageOptions> & { storageType: 'localStorage'} ): ISmartStorageProviderV2<string> => ({
        exists: () => this.exists(key, options),
        get: () => this.get(key, options),
        set: (value: string) => this.set(key, value, options),
        remove: () => this.remove(key, options)
    });

    getAsyncStringProvider = (key: string, options: IStorageOptions): IAsyncSmartStorageProvider<string> => ({
        exists: () => this.existsAsync(key, options),
        get: () => this.getAsync(key, options),
        set: (value: string) => this.setAsync(key, value, options),
        remove: () => this.removeAsync(key, options)
    });

    /**
     * @deprecated the sync methods only work with local storage and so they should not be used
     *              b/c they make it harder to switch to preferences (or server managed state)
     *               when the time is appropriate
     */
    getBooleanProvider = (key: string, options: Partial<IStorageOptions> & { storageType: 'localStorage'}): ISmartStorageProviderV2<boolean> => ({
        exists: () => this.exists(key, options),
        get: () => (this.get(key, options) === 'true'),
        set: (value: boolean) => this.set(key, String(value), options),
        remove: () => this.remove(key, options)
    });

    getAsyncBooleanProvider = (key: string, options?: Partial<IStorageOptions>): IAsyncSmartStorageProvider<boolean> => ({
        exists: () => this.existsAsync(key, options),
        get: async () => {
            const value = await this.getAsync(key, options);
            return value === 'true';
        },
        set: (value: boolean) => this.setAsync(key, String(value), options),
        remove: () => this.removeAsync(key, options)
    });

    /**
     * @deprecated the sync methods only work with local storage and so they should not be used
     *              b/c they make it harder to switch to preferences (or server managed state)
     *               when the time is appropriate
     */
    getJSONProvider = <T>(key: string, options: Partial<IStorageOptions> & { storageType: 'localStorage'}): ISmartStorageProviderV2<T> => ({
        exists: () => this.exists(key, options),
        get: () => this.getJSON<T>(key, options),
        set: (value: T) => this.setJSON<T>(key, value, options),
        remove: () => this.remove(key, options)
    });

    getAsyncJSONProvider = <T>(key: string, options?: Partial<IStorageOptions>): IAsyncSmartStorageProvider<T> => ({
        exists: () => this.existsAsync(key, options),
        get: () => this.getJSONAsync<T>(key, options),
        set: (value: T) => this.setJSONAsync<T>(key, value, options),
        remove: () => this.removeAsync(key, options)
    });
}
