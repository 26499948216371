import { useEffect, useRef } from 'react';
import { IonModal } from '@ionic/react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { DialogPresenter } from './DialogPresenter';
import { useSubscription } from '@meraki-internal/state';

export const DialogBinder: React.FC = () => {
    const dialog = useRef<HTMLIonModalElement>(null);


    const presenter = useInstance(DialogPresenter);
    useSubscription(() => presenter);

    useEffect(() => {
        presenter.setController(dialog.current!);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const Component = presenter.state?.Component;

    return (
        <IonModal ref={dialog}>
            {Component && (
                <Component {...presenter.state!.props} onDismiss={() => presenter.hide()} />
            )}
        </IonModal>
    );
};
