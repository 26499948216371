
import { MissionFeatureCollection } from '@autopylot-internal/tiles-client';
import { NoWrap } from '../../components/typography/NoWrap';
import { formatLocalDayAndTime } from '../../support/helpers/format';
import { SecondaryWarningAdvisory } from '../SecondaryAdvisory';
import { ITFRAdvisoryProperties } from '@autopylot-internal/tiles-client';

const TFRLabel = (props: ITFRAdvisoryProperties) => {
    const startedBeforeToday = props.effectiveStart < new Date().toISOString();
    return <strong>{startedBeforeToday ? 'TFR' : 'Scheduled TFR'}</strong>;
};

export const TFRLink = (props: ITFRAdvisoryProperties) => {
    const tfrUrl = `https://tfr.faa.gov/save_pages/detail_${props.number.replace('/', '_')}.html`;
    return <a href={tfrUrl} target="_blank"><strong>{props.number}</strong></a>;
};

export const TFRValidLine = (props: ITFRAdvisoryProperties & { mission: MissionFeatureCollection }) => {
    const startedBeforeToday = props.effectiveStart < new Date().toISOString();

    const end = props.effectiveEnd === 'PERM' ? undefined : formatLocalDayAndTime({ time: props.effectiveEnd, location: props.mission.getMissionCenter() });
    const start = formatLocalDayAndTime({ time: props.effectiveStart, location: props.mission.getMissionCenter() });

    if (end) {
        if (startedBeforeToday) {
            return <span>valid through <strong><NoWrap>{end}</NoWrap></strong></span>;
        } else {
            return <span>valid from <strong><NoWrap>{start}</NoWrap> to <NoWrap>{end}</NoWrap></strong></span>;
        }
    } else {
        if (startedBeforeToday) {
            return <span>valid <strong>Permanently</strong></span>;
        } else {
            return <span>valid from <strong><NoWrap>{start}</NoWrap> Permanently</strong></span>;
        }
    }
};

export const SingleTFRAdvisory = (props: ITFRAdvisoryProperties & { mission: MissionFeatureCollection } ) => {
    const verb = props.isPointTFR ? 'is near' : 'overlaps';
    return (
        <SecondaryWarningAdvisory header="Temporary Flight Restriction" advisoryType={props.advisoryType} GLOBAL_ID={props.GLOBAL_ID}>
            <>
                {props.advisoryType === 'block' && (
                    <>
                        <p>
                            Your mission overlaps <TFRLabel {...props} /> <TFRLink {...props} />, <TFRValidLine {...props} />.
                        </p>
                        <p>
                            No person may operate a small unmanned aircraft in restricted airspace
                            unless that person has permission from the appropriate authority.
                        </p>
                        <p>
                            Individuals violating any of these restrictions may be subject to FAA and/or law enforcement action.
                        </p>
                    </>
                )}
                {props.advisoryType === 'advise' && (
                    <span>Your mission {verb} <TFRLabel {...props} /> <TFRLink {...props} />, <TFRValidLine {...props} /> which may impact your mission.</span>
                )}
            </>
        </SecondaryWarningAdvisory>
    );
};
