import moment from 'moment';
import { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonItem, IonLabel, IonList } from '@ionic/react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { tryAndCatch } from '../support/helpers/tryAndCatch';
import { Logger } from '../support/debug/Logger';
import { Page } from '../components/page/Page';
import { HistoryViewModel } from '../app/HistoryViewModel';
import { Badge } from '../components/badge/Badge';
import { useSubscription } from '@meraki-internal/state';
import { InsuranceState } from './InsuranceState';
import { usePageView } from '../support/tracking/usePageView';
import { formatCoverageLimit, formatPeriod, getFees, getPayment, getPolicyStatus, getPolicyColor, getPolicyType, getPremium, getTaxes, getPendingPolicyRefreshIntervalMs } from './policyHelpers';
import { InsuranceErrorPage } from './InsuranceErrorPage';
import { Spinner } from '../components/spinner/Spinner';
import { Refresher } from '../components/refresher/Refresher';
import { useRefresher } from '../support/hooks/useRefresher';
import { RefreshTimer } from '../components/refresher/RefreshTimer';
import { DocumentLink } from './DocumentLink';

const useStyles = createUseStyles({
    details: {
        '& ion-card-title': {
            fontSize: 24
        },
        '& ion-item': {
            marginLeft: -15,
            marginRight: -15,
            '& h2': {
                fontSize: 18
            },
            '& ion-icon': {
                fontSize: '32px!important'
            }
        }
    },
    documentList: {
        margin: 0,
        paddingLeft: 20,
        '& li': {
            margin: '5px 0',
            '&::marker': {
                fontSize: 14
            }
        }
    }
});

const getShortLabel = (stereLabel: string) => ({
    'Drone liability, single limit including Property Damage and Bodily Injury': 'Drone Liability (Damage & Injury)',
    'Coverage for Unexpected Landing Event': 'Unexpected Landing',
    'Physical Damage to special equipment': 'Damage to Special Equipment'
}[stereLabel] || stereLabel);

export const InsuranceDetailsPage = ({ id }: { id: string }) => {
    usePageView('Insurance Details');

    const classes = useStyles();
    const history = useInstance(HistoryViewModel);
    const log = useInstance(Logger);

    const insuranceState = useInstance(InsuranceState);
    useSubscription(() => insuranceState);

    const { onRefresh, isRefreshing } = useRefresher(insuranceState.load);

    useEffect(() => {
        if (!insuranceState.state.loaded) {
            tryAndCatch(insuranceState.load, log.error);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!insuranceState.state.loaded && insuranceState.state.error) {
        return <InsuranceErrorPage />;
    }

    const policy = insuranceState.state.loaded ? insuranceState.getPolicyById(id) : undefined;

    // stere doesn't provide meta to identify documents, so use file_name for now
    const policyDocument = (policy?.documents || []).find(d => d.file_name === policy?.policy_number);
    const noticeDocument = (policy?.documents || []).find(d => d.file_name.startsWith('PolicyHolderNotice'));

    const currencyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

    return (
        <Page title="Insurance Policy" onBack={() => history.back('/insurance')}>

            <Refresher onRefresh={onRefresh} />

            {policy?.pending &&
                <RefreshTimer getIntervalMs={() => getPendingPolicyRefreshIntervalMs(policy)} handler={insuranceState.load} />
            }

            {policy &&
                <IonCard className={classes.details}>
                    <IonCardHeader>
                        <IonCardTitle>
                            <p style={{ textAlign: 'center', margin: 0, padding: 0 }}>
                                Policy {policy.policy_number}
                            </p>
                        </IonCardTitle>

                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <h5>{formatPeriod(policy)}</h5>
                            <Badge color={getPolicyColor(policy)}>
                                <span>{policy.pending ? 'Pending' : getPolicyStatus(policy)}</span>
                            </Badge>
                        </div>
                    </IonCardHeader>
                    <IonCardContent>
                        <IonList>
                            <IonItem detail={false}>
                                <IonLabel>
                                    <h2>Policy</h2>
                                    <p>Policy Type: {getPolicyType(policy)}</p>
                                    <p>Insurer: {policy.product_details.insurer}</p>
                                </IonLabel>
                            </IonItem>

                            <IonItem detail={false}>
                                <IonLabel>
                                    <h2>Coverage</h2>
                                    {policy.coverage.sections.map((section, sectionIdx) => (
                                        section.children.map((item, itemIdx) => (
                                            <p key={`item-${sectionIdx}-${itemIdx}`} style={{maxWidth: '100%', display: 'flex', alignItems: 'center', gap: 5}}>
                                                <span style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>{getShortLabel(item.label.default)}:</span>
                                                <span style={{flexGrow: 0}}>{formatCoverageLimit(item.limit?.default || '')}</span>
                                            </p>
                                        ))
                                    ))}
                                </IonLabel>
                            </IonItem>

                            <IonItem detail={false}>
                                <IonLabel>
                                    <h2>Insured</h2>
                                    <p>Name: {policy.params.applicant_first_name} {policy.params.applicant_last_name}</p>
                                    {policy.params.business_name &&
                                        <p>Business: {policy.params.business_name}</p>
                                    }
                                    <p>Address: {policy.params.applicant_street}, {policy.params.applicant_city}, {policy.params.applicant_state} {policy.params.applicant_zip_code}</p>
                                    <p>Email: {policy.params.applicant_email}</p>
                                    <p>Phone: {policy.params.applicant_phone_number}</p>
                                </IonLabel>
                            </IonItem>

                            {policy.params.add_additional_insured === 'Yes' &&
                                <IonItem detail={false}>
                                    <IonLabel>
                                        <h2>Additional Insured</h2>
                                        <p>Name/Business: {policy.params.name_business_addl_insured}</p>
                                        <p>Address: {policy.params.addl_insured_street}, {policy.params.addl_insured_city}, {policy.params.addl_insured_state} {policy.params.addl_insured_zip_code}</p>
                                        <p>Email: {policy.params.addl_insured_email}</p>
                                    </IonLabel>
                                </IonItem>
                            }

                            <IonItem detail={false}>
                                <IonLabel>
                                    <h2>Payment</h2>
                                    <p>Premium: {currencyFormatter.format(getPremium(policy))}</p>
                                    <p>Fees: {currencyFormatter.format(getFees(policy))}</p>
                                    <p>Taxes: {currencyFormatter.format(getTaxes(policy))}</p>
                                    <p>Total: {currencyFormatter.format(getPayment(policy))}</p>
                                    <p>Paid: {moment(policy.payments[0].created_at).format('M/D/YYYY h:mm a')}</p>
                                </IonLabel>
                            </IonItem>
                        </IonList>

                        <IonItem detail={false}>
                            <IonLabel>
                                <h2>Documents</h2>
                                {!policy.documents &&
                                    <p>PENDING</p>
                                }
                                <ul className={classes.documentList}>
                                    {policyDocument &&
                                        <li>
                                            <DocumentLink doc={policyDocument}>
                                                <p>Your Drone Policy: {policy.policy_number}</p>
                                            </DocumentLink>
                                        </li>

                                    }
                                    {noticeDocument &&
                                        <li>
                                            <DocumentLink doc={noticeDocument}>
                                                <p>Policy Holder Notice</p>
                                            </DocumentLink>
                                        </li>

                                    }
                                </ul>
                            </IonLabel>
                        </IonItem>

                    </IonCardContent>
                </IonCard>
            }

            {insuranceState.state.loading && !isRefreshing &&
                <Spinner name="crescent" centered />
            }

        </Page>
    );
};
