import shortid from 'shortid';
import { IScreenshotablePage } from '../support/screenshots/IScreenshotablePage';
import { MissionsState } from './model/MissionsState';
import moment from 'moment';
import { MissionListPage } from './MissionListPage';
import { IMission, IMissionStatus } from '../mission/model/EditableMissionState';
import { IPersistableLAANCStatus, MissionFeatureCollectionFacadeAdapter } from '@autopylot-internal/tiles-client';
import { getWellKnownLocation } from '../support/screenshots/getWellKnownLocation';

interface IMyPageProps {
    onMissionsVM?: (missions: MissionsState) => void;
    showAccordion?: string;
    showFeedback?: boolean;
}

/**
 * Screenshots readme
 *  - cover all permutations of visible risk
 */

export default class ScreenshotMissionListPage implements IScreenshotablePage<IMyPageProps> {
    static inject = () => [MissionsState, MissionFeatureCollectionFacadeAdapter];
    constructor(private missions: MissionsState, private mfcAdapter: MissionFeatureCollectionFacadeAdapter){}

    screenId = 'mission-list';

    permutationToProps = ({ onMissionsVM, showAccordion, showFeedback }: IMyPageProps) => {
        if (onMissionsVM) {
            this.missions.setState({ isFullyLoaded: true });
            onMissionsVM(this.missions);
        }

        const storageJSON = (showFeedback ? {} : { enjoyFirstMissionShown: true });
        window.localStorage.setItem('staging:screenshots:ap:feedback-alerts', JSON.stringify(storageJSON));

        return {
            initialAccordionValue: showAccordion
        };
    };

    ComponentUnderTest = MissionListPage;

    private createMission = ({ wellKnownLocation = 'uncontrolled', startTime, status = 'active', laancStatus, reminders }: { wellKnownLocation?: string, startTime?: string, status?: IMissionStatus, laancStatus?: IPersistableLAANCStatus, reminders?: number } = {}) => {
        const durationMinutes = 60;
        startTime = startTime || moment().startOf('h').add(2, 'h').toISOString();
        const endTime = moment(startTime).add(durationMinutes, 'm').toISOString();

        const { address, radiusPolygon } = getWellKnownLocation(wellKnownLocation);

        radiusPolygon.features[0].properties.startTime = startTime;
        radiusPolygon.features[0].properties.endTime = endTime;
        radiusPolygon.features[0].properties.isLicensed = true;

        if (laancStatus) {
            const laancAdvisory = radiusPolygon.features.find((f: any) => f.properties.source === 'uasfm+authorizable');
            laancAdvisory.properties.operationID = 'G8R3M9S20';
            laancAdvisory.properties.laancStatus = laancStatus;
            laancAdvisory.properties.laancStatusHistory.push({ status: laancStatus, timestamp: new Date().toISOString() }); // likely incomplete, but close enough
        }

        const checklist = reminders ? {
            sections: [{ items: [...new Array(reminders)].map(i => ({ completed: false, notification: { at: new Date().toISOString() } })) }]
        } : undefined;

        return {
            missionId: shortid.generate(),
            status,
            startTime,
            durationMinutes,
            radiusPolygon: this.mfcAdapter.adaptFromPersisted(radiusPolygon),
            address,
            checklist,
            links: {}
        } as IMission;
    };

    permutations = {
        'no-missions': {},
        'one-mission': {
            onMissionsVM: (missions: MissionsState) => {
                missions.addMissionToState(this.createMission());
            }
        },
        'many-missions': { // shows all 3 accordions and active missions in one screenshot
            onMissionsVM: (missions: MissionsState) => {
                // an active mission
                missions.addMissionToState(this.createMission({
                    startTime: moment().add(1, 'day').startOf('hour').toISOString()
                }));

                // a past mission
                missions.addMissionToState(this.createMission({
                    startTime: moment().add(-1, 'months').startOf('hour').toISOString()
                }));

                // a canceled mission
                missions.addMissionToState(this.createMission({
                    startTime: moment().add(1, 'week').startOf('hour').toISOString(),
                    status: 'user-canceled'
                }));
            }
        },
        'many-flight-status': {
            onMissionsVM: (missions: MissionsState) => {
                const createMissionWithLocation = (wellKnownLocation: string, laancStatus?: IPersistableLAANCStatus) => {
                    const startTime = moment().add(1, 'week').startOf('hour').toISOString();
                    const mission = this.createMission({ wellKnownLocation, startTime, laancStatus });
                    missions.addMissionToState(mission);
                };

                createMissionWithLocation('uncontrolled');
                createMissionWithLocation('laanc-aa', 'authorized');
                createMissionWithLocation('laanc-aa');
            }
        },
        'current-invalid': {
            onMissionsVM: (missions: MissionsState) => {
                missions.addMissionToState(this.createMission({
                    wellKnownLocation: 'uncontrolled',
                }));

                missions.addMissionToState(this.createMission({
                    wellKnownLocation: 'laanc-aa',
                    laancStatus: 'rescinded'
                }));
            },
        },
        'only-past-missions': { // should get BIG CTA to add a new mission
            onMissionsVM: (missions: MissionsState) => {
                missions.addMissionToState(this.createMission({
                    startTime: moment().add(-1, 'week').startOf('hour').toISOString()
                }));
            }
        },
        'past-missions-expanded': { // looks like a completed mission, not like an active mission
            onMissionsVM: (missions: MissionsState) => {
                // this is the past mission that we are mitigating risk with
                missions.addMissionToState(this.createMission({
                    startTime: moment().add(-1, 'week').startOf('hour').toISOString()
                }));

                // this is a future mission so we don't get the big CTA (which is more likely to have false negatives)
                missions.addMissionToState(this.createMission({
                    startTime: moment().add(1, 'week').startOf('hour').toISOString()
                }));
            },
            showAccordion: 'past'
        },
        'canceled-missions-expanded': { // looks like a canceled mission, not like an active, or completed
            onMissionsVM: (missions: MissionsState) => {
                // this is the canceled mission that we are mitigating risk with
                missions.addMissionToState(this.createMission({
                    startTime: moment().add(-1, 'week').startOf('hour').toISOString(),
                    status: 'user-canceled'
                }));

                // this is a future mission so we don't get the big CTA (which is more likely to have false negatives)
                missions.addMissionToState(this.createMission({
                    startTime: moment().add(1, 'week').startOf('hour').toISOString()
                }));
            },
            showAccordion: 'canceled'
        },
        'past-invalid-expanded': { // looks like a completed mission, not like an active mission
            onMissionsVM: (missions: MissionsState) => {
                missions.addMissionToState(this.createMission({
                    startTime: moment().add(-2, 'day').startOf('hour').toISOString(),
                    wellKnownLocation: 'laanc-aa',
                    laancStatus: 'invalidated'
                }));
                missions.addMissionToState(this.createMission({
                    startTime: moment().add(-3, 'day').startOf('hour').toISOString(),
                    wellKnownLocation: 'laanc-aa',
                    status: 'user-canceled',
                    laancStatus: 'rescind-acked'
                }));

                // this is a future mission so we don't get the big CTA (which is more likely to have false negatives)
                missions.addMissionToState(this.createMission({
                    startTime: moment().add(1, 'week').startOf('hour').toISOString()
                }));            },
            showAccordion: 'invalid'
        },
        'past-missions-loading': {
            onMissionsVM: (missions: MissionsState) => {
                missions.addMissionToState(this.createMission({
                    startTime: moment().add(-1, 'week').startOf('hour').toISOString()
                }));
                missions.addMissionToState(this.createMission({
                    startTime: moment().add(1, 'week').startOf('hour').toISOString()
                }));
                missions.setState({ isFullyLoaded: false });
            },
            showAccordion: 'past'
        },
        'today-and-future-missions': { // today but done, vs today, vs tomorrow, vs 2 days from now vs 2 months from now
            onMissionsVM: (missions: MissionsState) => {
                // TODO: need to mock the clock, so that these don't vary every day
                // Done
                missions.addMissionToState(this.createMission({
                    startTime: moment().add(-2, 'hours').startOf('hour').toISOString()
                }));

                // Today
                missions.addMissionToState(this.createMission({
                    startTime: moment().add(2, 'hours').startOf('hour').toISOString()
                }));
                // Tomorrow
                missions.addMissionToState(this.createMission({
                    startTime: moment().add(1, 'day').startOf('hour').toISOString()
                }));

                // [Wednesday]
                missions.addMissionToState(this.createMission({
                    startTime: moment().add(2, 'day').startOf('hour').toISOString()
                }));

                // [03/07/2022]
                missions.addMissionToState(this.createMission({
                    startTime: moment().add(1, 'week').startOf('hour').toISOString()
                }));
            },
            showAccordion: 'canceled'
        },
        'feedback-alert': {
            showFeedback: true,
            onMissionsVM: (missions: MissionsState) => {
                missions.addMissionToState(this.createMission());
            }
        },
        'with-reminders': {
            onMissionsVM: (missions: MissionsState) => {
                missions.addMissionToState(this.createMission({
                    wellKnownLocation: 'uncontrolled',
                }));

                missions.addMissionToState(this.createMission({
                    wellKnownLocation: 'laanc-aa',
                    laancStatus: 'authorized',
                    reminders: 2
                }));
            }
        },
        'past-mission-reminder': {
            onMissionsVM: (missions: MissionsState) => {
                missions.addMissionToState(this.createMission({
                    startTime: moment().add(-1, 'week').startOf('hour').toISOString(),
                    reminders: 1
                }));
            }
        }
    };
}
