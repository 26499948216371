import { APIClient } from '@autopylot-internal/autopylot-api-client';

type ISO8601 = string;

export interface IExperimentConfig {
    startsAt: ISO8601;
    endsAt: ISO8601; // sort key (get me those that haven't ended yet)
    endsAtUserCount: number;
    enablesFlags: string[]; // eg preMissionWeather
    targetUsers: string[]; // userids
}

export class AdminCommands {

    static inject = () => [APIClient];
    constructor(private apiClient: APIClient) {}

    switchTiles = async (name: string) => {

        try {
            await this.apiClient.post('/admin/commands', {
                command: 'tiles:switch-to-names-tiles',
                name
            });

            console.log(`Request to switch tiles to "${name}" has been successfully queued`);

        } catch (e: any) {
            console.error(`Failed to switch tiles to "${name}": ${e.message}`);
        }
    };

    bindQuote = async (quoteId: string) => {
        try {
            const { id, status, error } = await this.apiClient.post('/admin/commands', { command: 'insurance:bind-quote', quoteId });
            console.log('Response from bind command:', {id, status, error});

        } catch (e: any) {
            console.error(`Failed to bind quote "${quoteId}": ${e.message}`);
        }
    };

    approveQuote = async (quoteId: string) => {
        try {
            const { id, status, error } = await this.apiClient.post('/admin/commands', { command: 'insurance:approve-quote', quoteId });
            console.log('Response from approve command:', {id, status, error});

        } catch (e: any) {
            console.error(`Failed to approve quote "${quoteId}": ${e.message}`);
        }
    };

    rejectQuote = async (quoteId: string) => {
        try {
            const {id, status, error} = await this.apiClient.post('/admin/commands', { command: 'insurance:reject-quote', quoteId });
            console.log('Response from reject command:', {id, status, error});

        } catch (e: any) {
            console.error(`Failed to reject quote "${quoteId}": ${e.message}`);
        }
    };

    deleteInsuranceMapping = async (userId: string, secretApplicantId: string) => {
        try {
            await this.apiClient.post('/admin/commands', {
                command: 'insurance:delete-mapping',
                userId,
                secretApplicantId
            });

            console.log(`Mapping for user "${userId}" successfully deleted`);

        } catch (e: any) {
            console.error(`Failed to delete mapping: ${e.message}`);
        }
    };

    addExperiment = async (experiment: IExperimentConfig) => {
        try {
            await this.apiClient.post('/admin/commands', {
                command: 'experiments:add',
                ...experiment
            });

            console.log(`Experiment created`);

        } catch (e: any) {
            console.error(`Failed to add experiment: ${e.message}`);
        }
    };
}
