import { useState } from 'react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { AlertPresenter } from '../../app/AlertBinder';
import { RefresherEvent } from '../../components/refresher/Refresher';

export const useRefresher = (delegate: () => Promise<void>) => {

    const alert = useInstance(AlertPresenter);

    const [isRefreshing, setRefreshing] = useState(false);

    const onRefresh = async (event: RefresherEvent) => {

        try{
            setRefreshing(true);
            await delegate();
        } catch (err){
            alert.showAndLogError(err);
        } finally {
            event.detail.complete();
            setRefreshing(false);
        }

    };

    return { onRefresh, isRefreshing };
};
