import { AppleIcon } from '../../components/icons/Icons';

export const WeatherAttribution: React.FC = () => {
    return (
        <div style={{textAlign: 'center', marginTop: -10, marginBottom: 20}}>
            <span style={{fontSize: 10, color: 'var(--ion-color-medium'}}>
                Source <span style={{verticalAlign: 'text-top'}}><AppleIcon /></span> Weather see also <a style={{color: 'inherit'}} href="https://weatherkit.apple.com/legal-attribution.html" target="_blank">other data sources</a>
            </span>
        </div>
    );
};
