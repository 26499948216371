import moment from 'moment';
import { CSSProperties } from 'react';
import { formatLocalHour } from '../../support/helpers/format';
import { IHourlyForecast } from '../model/IHourlyForecast';

export const HourHeading: React.FC<{forecast: IHourlyForecast, highlightStart: string, highlightEnd: string}> = ({ forecast, highlightStart, highlightEnd }) => {

    const delayToScroll = 10;

    const forecastMoment = moment(forecast.forecastStart);
    const isHighlighted = (!forecastMoment.isBefore(moment(highlightStart).startOf('h')) && forecastMoment.isBefore(highlightEnd));
    const isStart = (forecastMoment.isSame(moment(highlightStart).startOf('h')));
    const isEnd = (forecastMoment.isSame(moment(highlightEnd).subtract(1, 's').startOf('h')));

    const style: CSSProperties = {
        width: '100%',
        textAlign: 'center',
        paddingBottom: 2,
        paddingTop: 1,
        marginBottom: 3,
        fontSize: 13,
        color: (isHighlighted ? 'white' : undefined),
        backgroundColor: (isHighlighted ? 'var(--ion-color-primary' : undefined),
        fontWeight: (isHighlighted ? 'bold' : undefined),
        borderTopLeftRadius: (isStart ? 10 : 0),
        borderBottomLeftRadius: (isStart ? 10 : 0),
        borderTopRightRadius: (isEnd ? 10 : 0),
        borderBottomRightRadius: (isEnd ? 10 : 0)
    };

    const scrollHourHighlightIntoView = (e: any) => {
        if (e && moment(forecast.forecastStart).isSame(moment(highlightStart).startOf('h'))) {
            setTimeout(() => e.scrollIntoView({ block: 'nearest', inline: 'start' }), delayToScroll);
        }
    };

    return (
        <div style={style} ref={scrollHourHighlightIntoView}>
            {formatLocalHour({ time: forecast.forecastStart, location: forecast.location })}
        </div>

    );
};
