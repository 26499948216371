import { FAAAdvisory } from './FAAAdvisory';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { AdvisoryProvider } from './AdvisoryProvider';
import { ICommonMissionState } from '../missions/model/ICommonMissionState';
import { PrimaryAdvisory } from './PrimaryAdvisory';
import { MissionColorProvider } from './MissionColorProvider';
import { LastAuthorizedRadiusModel } from '../map/mission-pin-and-radius/LastAuthorizedRadiusModel';

export const FlightChecklist: React.FC<{ mission: ICommonMissionState }> = ({ mission }) => {
    const lastAuthorizedModel = useInstance(LastAuthorizedRadiusModel);

    const missionRadius = mission.getMissionRadius() || lastAuthorizedModel.lastAuthorizedRadius;

    if (!missionRadius) {
        throw new Error('FlightChecklist() was rendered for a mission with no missionRadius polygon');
    }

    const color = useInstance(MissionColorProvider).getColor(missionRadius);

    const advisoryProvider = useInstance(AdvisoryProvider);
    const advisories = advisoryProvider.getAdvisories(missionRadius, mission.getConcurrentLAANCMissions());

    return (
        <div style={{ marginTop: 10, borderLeft: `5px solid var(--ion-color-${color})`, borderTopLeftRadius: 2, borderBottomLeftRadius: 2 }}>
            <PrimaryAdvisory mission={mission} />
            {advisories.map((advisory, i) => (
                <FAAAdvisory key={`advisory-${i}`} advisory={advisory} source={advisory.source} mission={missionRadius} />
            ))}
        </div>
    );
};
