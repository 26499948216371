import moment from 'moment';
import { CSSProperties } from 'react';
import { formatLocalDateShort } from '../../support/helpers/format';
import { IDailyForecast } from '../model/IDailyForecast';

export const DayHeading: React.FC<{forecast: IDailyForecast, highlightDay: string}> = ({forecast, highlightDay}) => {

    const diff = moment(highlightDay).diff(moment(forecast.forecastStart), 'h');
    const isHighlighted = (diff >= 0 && diff < 24);

    const style: CSSProperties = {
        borderRadius: 10,
        marginLeft: -10,
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 1,
        fontSize: 14,
        fontWeight: 'bold',
        color: (isHighlighted ? 'white' : undefined),
        backgroundColor: (isHighlighted ? 'var(--ion-color-primary' : undefined)
    };

    return (
        <span style={style}>
            {formatLocalDateShort({ date: forecast.forecastStart, location: forecast.location })}
        </span>
    );
};
