import { useInstance } from '@meraki-internal/react-dependency-injection';
import { IonButton, IonContent, IonPage, useIonAlert } from '@ionic/react';
import { useSubscription } from '@meraki-internal/state';
import moment from 'moment';
import { AutopylotLiveUpdatesService } from './AutopylotLiveUpdatesService';
import { useState } from 'react';
import { DelayLiveUpdateInstallService } from './DelayLiveUpdateInstallService';

const Field: React.FC<{ label: string; value: string; children?: any }> = ({ label, value, children }) => {
    return (
        <div>
            {label}:
            <div style={{ fontSize: '.8em', margin: 10, color: 'grey'}}>
                <div>{value}</div>
                {children}
            </div>
        </div>
    );
};

const SmartButton: React.FC<{ onClick: () => Promise<void>; disabled?: boolean; }> = ({ onClick, children, disabled }) => {
    const [alert] = useIonAlert();
    const [isInvoking, setIsInvoking] = useState(false);

    const smartOnClick = async () => {
        try {
            setIsInvoking(true);
            await onClick();
        }
        catch (err: any){
            alert(err.toString());
        }
        setIsInvoking(false);
    };

    const smartDisabled = isInvoking || disabled;

    return (
        <IonButton disabled={smartDisabled} onClick={smartOnClick}>{children}</IonButton>
    );
};

export const LiveUpdatesManager: React.FC = () => {
    const liveUpdatesService = useInstance(AutopylotLiveUpdatesService);
    useSubscription(() => liveUpdatesService);

    const delayLiveUpdateService = useInstance(DelayLiveUpdateInstallService);

    const isDevServer = process.env.NODE_ENV === 'development';
    const installedSha = (process.env.REACT_APP_SHA || '(no sha)');
    const installedLiveUpdateVersion = (liveUpdatesService.getCurrentBundle()?.version || 'native') + (isDevServer ? ' (overridden by dev server)': '');

    // disabling install when running locally b/c it is not intuitive
    // it DOES install the bundle (so can test all that, but it DOES NOT change out the web view)
    const canInstall = !isDevServer && liveUpdatesService.getLiveUpdateStatus() === 'downloaded';

    return (
        <IonPage style={{margin: 20 }}>
            <IonContent>
                <h1>Live Updates Manager</h1>
                <h2>{liveUpdatesService.getLiveUpdateStatus()}</h2>
                <Field label="Native Version" value={liveUpdatesService.getNativeVersion() || ''} />
                <Field label="Installed SHA" value={installedSha} />
                <Field label="Installed Live Update Version" value={installedLiveUpdateVersion} />
                <Field label="Available Live Update Version" value={(liveUpdatesService.latestVersionAvailable?.version || '') +  (liveUpdatesService.getLiveUpdateStatus() === 'up-to-date' ? ' (up to date)': '')}>
                    <>

                        {liveUpdatesService.getLiveUpdateStatus() !== 'up-to-date' && (
                            <>
                                <SmartButton disabled={liveUpdatesService.getLiveUpdateStatus() !== 'live-update-available'} onClick={liveUpdatesService.downloadLatest}>Download</SmartButton>
                                <SmartButton disabled={!canInstall} onClick={liveUpdatesService.installLatest}>Install</SmartButton>
                            </>
                        )}
                        {liveUpdatesService.getLiveUpdateStatus() === 'downloaded' && (
                            <>
                                {delayLiveUpdateService.getCanInstallOnForegroundAfter() && (
                                    <>
                                        {delayLiveUpdateService.getCanInstallOnForegroundAfter()! < new Date().toISOString() && (
                                            <div>Auto Install on Next Foreground</div>
                                        )}
                                        {delayLiveUpdateService.getCanInstallOnForegroundAfter()! > new Date().toISOString() && (
                                            <div>Auto Install on Foreground after {moment(delayLiveUpdateService.getCanInstallOnForegroundAfter()).format('hh:mm a')}</div>
                                        )}
                                    </>
                                )}
                                {delayLiveUpdateService.getCanInstallOnHashChangeAfter() && (
                                    <>
                                        {delayLiveUpdateService.getCanInstallOnHashChangeAfter()! < new Date().toISOString() && (
                                            <div>Auto Install on Next Hash Change</div>
                                        )}
                                        {delayLiveUpdateService.getCanInstallOnHashChangeAfter()! > new Date().toISOString() && (
                                            <div>Auto Install on Hash Change after {moment(delayLiveUpdateService.getCanInstallOnHashChangeAfter()).format('hh:mm a')}</div>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                        <div>{liveUpdatesService.getAvailableLiveUpdateUrl()}</div>
                        {liveUpdatesService.latestVersionAvailable?.asOf && (
                            <div>As of {liveUpdatesService.latestVersionAvailable!.asOf} {moment(liveUpdatesService.latestVersionAvailable!.asOf).fromNow()}</div>
                        )}
                        <SmartButton onClick={liveUpdatesService.refreshLatestAvailableLiveUpdate}>Refresh</SmartButton>
                    </>
                </Field>
                <Field label="Last Installed Live Update Version" value={liveUpdatesService.lastInstalledLiveUpdateVersion || '(none)'} />
                <div>
                    Bundles
                    <ul style={{ margin: 0, padding: 15}}>
                        {(liveUpdatesService.bundles || []).map(bundle => (
                            <li key={bundle.id}>
                                <div>
                                    {bundle.version}
                                    <span style={{ color: 'grey', marginLeft: 10}}>{bundle.status}</span>
                                    <span style={{ color: 'grey', marginLeft: 10, display: 'block'}}>Downloaded {moment(bundle.downloaded).fromNow()}</span>
                                    <SmartButton disabled={!liveUpdatesService.canDeleteBundle(bundle)} onClick={() => liveUpdatesService.deleteBundle(bundle)}>Delete</SmartButton>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </IonContent>
        </IonPage>
    );
};
