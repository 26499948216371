import { isPlatform } from '@ionic/react';
import { Intercom } from '@awesome-cordova-plugins/intercom';
import { IIntercomAdapter, IIntercomEnv } from './IntercomAdapter';
import { IntercomNativeEnvProvider } from './IntercomNativeEnvProvider';

// don't show messages while app is loading
if (isPlatform('ios') || isPlatform('android')) {
    Intercom.setInAppMessageVisibility('GONE');
}

export class IntercomNative implements IIntercomAdapter {

    private unreadCount = 0;

    private unreadCountListener?: (unreadCount: number) => void;

    private updateUnreadCount = () => {
        Intercom.unreadConversationCount().then(count => {
            if (count !== this.unreadCount) {
                this.unreadCount = count;
                if (this.unreadCountListener) {
                    this.unreadCountListener(count);
                }
            }
        });
    };

    static inject = () => [IntercomNativeEnvProvider];
    constructor(private nativeEnvProvider: IntercomNativeEnvProvider) {
        this.updateUnreadCount();
        setInterval(this.updateUnreadCount, 5000);
    };

    getEnv = (): IIntercomEnv => {
        return this.nativeEnvProvider.getEnv();
    };

    init = async ({ userId, email, name }: { userId: string, email?: string, name?: string }) => {
        // logout in case this is a different user
        // otherwise, it appears that subsequent calls to registerIdentifiedUser are ignored
        try {
            await Intercom.reset();
        }
        catch (err) {
        }

        await Intercom.registerIdentifiedUser({ userId, email });

        if (name) {
            await Intercom.updateUser({ name });
        }
    };

    updateUser = async (user: { name: string }) => {
        await Intercom.updateUser(user);
    };

    displayMessenger = async () => {
        await Intercom.displayMessenger();
    };

    displayMessageComposerWithInitialMessage = async (message: string) => {
        await Intercom.displayMessageComposerWithInitialMessage(message);
    };

    canDisplayCarousel = true;

    displayCarousel = async (carouselId: string) => {
        await Intercom.displayCarousel(carouselId);
    };

    displayArticle = async (articleId: string) => {
        await Intercom.displayArticle(articleId);
    };

    onUnreadCountChange = (listener: (unreadCount: number) => void) => {
        listener(this.unreadCount);
        this.unreadCountListener = listener;
    };

    setMessageVisibility = async (visible: boolean) => {
        await Intercom.setInAppMessageVisibility(visible ? 'VISIBLE' : 'GONE');
    };
}
