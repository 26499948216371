import { useEffect, useRef } from 'react';
import { ActionPerformed, PushNotificationSchema,PushNotifications, Token } from '@capacitor/push-notifications';
import { Device } from '../support/Device';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { Logger } from '../support/debug/Logger';
import { APIClient } from '@autopylot-internal/autopylot-api-client';
import { HistoryViewModel } from '../app/HistoryViewModel';
import { ToastPresenter, IToastButton } from '../components/toast/ToastPresenter';
import { TrackingService } from '../support/tracking/TrackingService';

interface IAPPushNotificationExtras {
    // eg /insurance
    goto?: string;

    // '0' for never auto dismiss
    // '30000' for after 302
    durationMS?: string;

    // to not show toast, eg a campaign to drive people into the app
    // should be ignored for someone that is actively using the app
    // b/c we don't want to risk interupting what they are doing (eg in
    // the middle of mission creation)
    doNotShowToast?: string; // 'true' | undefined

    // this gets sent to mix panel
    campaignId?: string;
}

export const PushNotificationListener: React.FC = () => {

    const logger = useInstance(Logger);
    const tracker = useInstance(TrackingService);

    const history = useInstance(HistoryViewModel);
    const toast = useInstance(ToastPresenter);

    const deviceInfo = useInstance(Device);

    const apiClient = useInstance(APIClient);

    const registrationErrors = useRef<string[]>([]);

    // save device token to dynamo
    const persistTokenWithRetry = async (token: string, attempt = 1) => {
        try {
            const entry = await apiClient.entry();
            const { name, manufacturer, model } = deviceInfo;
            const device = { token, description: `${name}/${manufacturer}/${model}` };
            await apiClient.put(entry.links.pushNotificationDevices, device);
            logger.info('Persisted device token');
        } catch (e: any) {
            if (attempt < 5) {
                logger.info('Retrying persist device token: ' + e.message);
                setTimeout(() => persistTokenWithRetry(token, attempt + 1), 10000);
            } else {
                e.message = `Failed to persist device token after 5 attempts: ${e.message}`;
                logger.error(e);
            }
        }
    };

    useEffect(() => {

        if (!['ios', 'android'].includes(deviceInfo.platform)) {
            // capacitor plugin doesn't support web
            return;
        }

        PushNotifications.addListener('registration',
            async (token: Token) => {
                logger.info('Push notifications registration success, token: ' + token.value);
                registrationErrors.current = [];

                // save device token to dynamo
                await persistTokenWithRetry(token.value);
            }
        );

        PushNotifications.addListener('registrationError',
            ({ error }: { error: string }) => {
                registrationErrors.current.push(error);

                if (registrationErrors.current.length < 5) {
                    logger.info('Retrying push notification registration: ' + error);
                    setTimeout(PushNotifications.register, 10000);
                } else {
                    logger.error(`Push notifications registration failed ${registrationErrors.current.length} times. Errors: ${JSON.stringify(registrationErrors.current)}`);
                }

            }
        );

        // invoked when a notification is received while the app is in the foreground
        PushNotifications.addListener('pushNotificationReceived',
            (notification: PushNotificationSchema) => {
                logger.info('Push notification received in app: ' + JSON.stringify(notification));

                const { goto, durationMS: durationMSString, doNotShowToast } = notification.data as IAPPushNotificationExtras;

                if (doNotShowToast){
                    return;
                }

                const buttons: IToastButton[] = [];

                // if there is a url to go to, and we're not already on it
                // NOTE: they could change the page before they click OK on the toast, but
                // edge case of edge case
                if (goto && history.getCurrentLocation().pathname !== goto) {
                    buttons.push({ text: 'Open',  handler: () => history.push(goto) });
                }

                let duration = Number(durationMSString);
                if (Number.isNaN(duration)){
                    duration = 3000;
                }

                toast.showToast({
                    header: notification.title,
                    message: notification.body || '',
                    buttons,
                    duration
                });
            }
        );

        // invoked when user taps on ios notification while app is in the background or closed
        PushNotifications.addListener('pushNotificationActionPerformed',
            (action: ActionPerformed) => {
                logger.info('Push notification clicked outside app: ' + JSON.stringify(action.notification));

                const { title, body, data } = action.notification;
                const { goto, campaignId } = data as IAPPushNotificationExtras;
                tracker.track('Push Notification Opened', () => ({ title, body, goto, campaignId }));

                if (goto) {
                    history.push(goto);
                }
            }
        );

        // on android, we need a high-importance channel, so we can send
        // notifications that pop up and show on lock screen
        if (deviceInfo.platform === 'android') {
            PushNotifications.listChannels().then(result => {
                if (!result.channels?.find(c => c.id === 'high-importance')) {
                    PushNotifications.createChannel({
                        id: 'high-importance',
                        name: 'High Priority',
                        importance: 5
                    });
                }
            });
        }

        // always trigger registration on app start to be sure we get the latest token in the listener below,
        // if user has not yet granted permission (e.g. in MissionChecklist), expect this to fail
        PushNotifications.register();

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return null;
};
