import { IWildernessAreaAdvisoryProperties } from '@autopylot-internal/tiles-client';
import { SecondaryWarningAdvisory } from '../SecondaryAdvisory';
import { AdvisoryBlockIcon } from '../../components/icons/Icons';

export const WildernessAreaAdvisory = (props: (IWildernessAreaAdvisoryProperties)) => {
    return (
        <SecondaryWarningAdvisory header="Wilderness Area" advisoryType={props.advisoryType} GLOBAL_ID={props.GLOBAL_ID}  icon={AdvisoryBlockIcon} iconColor='danger'>
            <>
                <p>
                    Launching, landing, or operating within Wilderness Areas is prohibited.
                    Your mission overlaps <strong>{props.NAME}</strong>. Violations are punishable
                    by harsh fines and penalties.
                </p>
                <p>
                    <a href="https://wilderness.net/learn-about-wilderness/stewardship/drones-wilderness.php" target="_blank">Drones in Wilderness</a>
                </p>
            </>
        </SecondaryWarningAdvisory>
    );
};
