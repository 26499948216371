import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useSubscription } from '@meraki-internal/state';
import { useEffect, useState } from 'react';
import { EditableMissionState } from '../../mission/model/EditableMissionState';
import { MissionsState } from './MissionsState';

export const useEditableMissionState = (missionId: string) => {

    const missions = useInstance(MissionsState);
    useSubscription(() => missions);

    const [mission, setMission] = useState<EditableMissionState>();

    useEffect(() => {
        missions.getMissionById(missionId).then(_mission => {
            setMission(_mission);
        });
    }, [missionId, missions]);

    return mission;
};
