import { IScreenshotablePage } from '../support/screenshots/IScreenshotablePage';
import { MapPage } from './MapPage';
import { NewMissionState } from '../missions/model/NewMissionState';
import { ICoordinate } from '../support/model/ICoordinate';
import { MapViewModel } from './model/MapViewModel';
import { TileUrlProvider } from '@autopylot-internal/tiles-client';
import { IAddress } from '../support/model/IAddress';
import { OperatorState } from '../profile/OperatorState';
import { FAAVersionAlertManager } from '../app/FAAVersionAlertManager';
import { Network } from '../support/Network';
import { TrackingService } from '../support/tracking/TrackingService';
import { EnableAndBindMapForScreenshots } from './EnableAndBindMapForScreenshots';
import { UserFacingError } from '../support/UserFacingError';
import { ScreenshotHelper } from '../support/screenshots/ScreenshotHelper';
import { MissionPinAndRadiusOnMapViewModel } from './mission-pin-and-radius/MissionPinAndRadiusOnMapViewModel';

interface IMyPageProps {
    newMission?: { address: IAddress, point: ICoordinate, maxAltitude?: number };
    tilesAreStale?: boolean;
    showAlert?: boolean;
    faaIsDown?: boolean;
    attemptSave?: boolean;
    onSave?: () => void;
    mockOffline?: boolean;
}

export default class ScreenshotMapPage implements IScreenshotablePage<IMyPageProps> {
    static inject = () => [
        MapViewModel,
        FAAVersionAlertManager,
        TileUrlProvider,
        Network,
        TrackingService,
        OperatorState,
        ScreenshotHelper,
        MissionPinAndRadiusOnMapViewModel
    ];

    constructor(
        private mapViewModel: MapViewModel,
        private versionAlertManager: FAAVersionAlertManager,
        private tileUrlProvider: TileUrlProvider,
        private network: Network,
        private tracker: TrackingService,
        private operatorState: OperatorState,
        private helper: ScreenshotHelper,
        private pinAndRadius: MissionPinAndRadiusOnMapViewModel
    ) { }

    screenId = 'new-mission-step-4';

    ComponentUnderTest = MapPage;

    WrapWith = EnableAndBindMapForScreenshots;

    permutationToProps = async ({ newMission, tilesAreStale = false, faaIsDown = false, showAlert, onSave, mockOffline }: IMyPageProps) => {
        this.operatorState.setState({
            firstName: 'Freddy',
            lastName: 'Flyer',
            phone: '802-123-1234',
            email: 'freddy.flyer@gmail.com',
            certificationType: 'part-107',
            agreedToFAAPrivacyStatement: true,
            agreedToLAANCStatement: true,
            agreedToTermsOfService: true,
        });

        let mission: NewMissionState | undefined;

        if (mockOffline){
            Object.defineProperty(this.network, 'isOnline', {
                get: () => false
            });
        }

        if (tilesAreStale || faaIsDown){
            this.tileUrlProvider.getFAAStatus = async () => ({ tilesAreStale, faaIsDown, appReleases: [] });
            this.versionAlertManager.listen({ showAlert: Boolean(showAlert) });
        }

        if (newMission) {
            mission = new NewMissionState({
                onSave: async () => {
                    if (onSave){
                        onSave();
                    }
                },
                getConcurrentLAANCMissions: () => []
            });

            // TODO: friction from property injection
            mission.mapViewModel = this.mapViewModel;
            mission.operator = this.operatorState;
            mission.versionAlertManager = this.versionAlertManager;
            mission.tracker = this.tracker;
            mission.pinAndRadius = this.pinAndRadius;

            mission.setAddress(newMission.address).setCenter(newMission.point);

            if (newMission.maxAltitude) {
                mission.setMaxAltitude(newMission.maxAltitude);
            }
        }

        return { screenshotConfig: { mission, wizardPage: 4 } };
    };

    blockScreenshotTil = async ({ newMission, showAlert, attemptSave }: IMyPageProps) => {
        await this.mapViewModel.waitForIdle();

        if (newMission){
            await this.helper.waitForElement('[data-type="flight-status"]');
        }

        if (attemptSave) {
            const button: any = await this.helper.waitForElement('ion-button[data-type=create-mission-button]');
            button.click();
        }

        if (showAlert || attemptSave){
            // then we're expecting an alert, so wait til it exists
            await this.helper.waitForElement('ion-alert');
        }

        // NOTE: avoid .scrollIntoView() here, as it unexpectedly causes the floating buttons to move up, showing drawer content below them
    };

    permutations: {[k: string]: IMyPageProps} = {
        'review-and-submit': {
            newMission: { address: { street: '12025 Coyote Ridge Road', city: 'Deadwood', state: 'SD', zip: '57732' }, point: { lng: -103.64851, lat: 44.30194 }, maxAltitude: 300 },
        },

        // TODO: fix this test
        //  a - I believe the addition of <AppStatusAlerter /> added a caller to this.versionAlertManager.listen but this test assumes it is the only caller
        //  b - by the time save is called, somehow areTilesStale is reverting back to false, possibly related to 2 subscriptions (see a)

        // 'outage-tiles-are-stale-cannot-create-mission': {
        //     tilesAreStale: true,
        //     showAlert: false,
        //     newMission: { address: { street: '12025 Coyote Ridge Road', city: 'Deadwood', state: 'SD', zip: '57732' }, point: { lng: -103.64851, lat: 44.30194 }, maxAltitude: 300 },
        //     attemptSave: true
        // },
        // 'outage-faa-is-down-cannot-create-mission': {
        //     faaIsDown: true,
        //     showAlert: false,

        //     // has to be a laanc mission to get the error
        //     newMission: { address: { street: 'Covington Street', city: 'Rapid City', state: 'SD', zip: '57703' }, point: { lng:-103.14317,lat:44.060875} },
        //     attemptSave: true
        // },

        'save-failed-offline-alert': {
            newMission: { address: { street: 'Covington Street', city: 'Rapid City', state: 'SD', zip: '57703' }, point: { lng:-103.14317,lat:44.060875} },
            attemptSave: true,
            onSave: () => {
                throw new UserFacingError({
                    displayMessage: '',
                    message: 'network issue',
                    status: 400 // so it doesn't go to sentry
                });
            },
            mockOffline: true
        }
    };
}
