import React, { ReactNode, useState } from 'react';
import { IonBadge, IonButton, IonCard, IonCol, IonContent, IonFooter, IonGrid, IonModal, IonRow, IonText } from '@ionic/react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useSubscription } from '@meraki-internal/state';
import { IPaymentTerm, RevenueCatModel } from './revenue-cat/RevenueCatModel';
import { DevSettings } from '../support/DevSettings';
import { CheckmarkIcon, IIconComponent } from '../components/icons/Icons';
import { AlertPresenter } from '../app/AlertBinder';
import { TrackingService } from '../support/tracking/TrackingService';
import { PaywallDialogViewModel } from './PaywallDialogViewModel';
import { StorageProvider } from '../support/StorageProvider';
import { useBreakpoints } from '../support/hooks/useBreakpoints';
import { useAsyncEffect } from '../support/hooks/useAsyncEffect';
import { RevenueCatSDKPurchaseError } from './revenue-cat/PurchasesErrorAdapter';
import { NudgeBanner } from '../components/nudge/NudgeBanner';
import { StoreConfigs } from '../app/config/StoreConfigs';
import { Spinner } from '../components/spinner/Spinner';
import { PageHeader } from '../components/page/PageHeader';
import { useIsNative } from '../support/hooks/useIsNative';
import { PaymentTermButton } from './PaymentTermsButton';

const DialogHeading: React.FC<{fontSize: number; previouslyHadPlus?: boolean;}> = ({ fontSize, previouslyHadPlus = false }) => {
    const message = previouslyHadPlus ? <>It looks like you no longer have</> : <>Find the best time to fly with</> ;
    return (
        <h1 style={{fontWeight: 'bold', fontSize, marginTop: 0, marginBottom: 0, marginRight: 25}}>
            {message} <span style={{whiteSpace: 'nowrap'}}>AutoPylot<IonText color="primary">+</IonText></span>
        </h1>
    );
};

const SectionHeading: React.FC<{children: ReactNode}> = ({ children }) => (
    <b><p style={{marginTop: 20, marginBottom: 20, textAlign: 'center', fontSize: 18}}><b>{children}</b></p></b>
);

const CenteredText: React.FC<{children: ReactNode}> = ({ children }) => (
    <p style={{marginTop: 20, marginBottom: 20, textAlign: 'center'}}>{children}</p>
);

const StoreButton: React.FC<{imgSrc: string, altText: string, storeUrl: string}> = ({ imgSrc, altText, storeUrl }) => (
    <div style={{marginTop: 20, marginBottom: 20, textAlign: 'center'}}>
        <a href={storeUrl}>
            <img style={{maxWidth: 220}} src={imgSrc} alt={altText} />
        </a>
    </div>
);

const Benefit: React.FC<{Icon: IIconComponent, children: React.ReactNode}> = ({ Icon, children }) => (
    <div style={{display: 'flex', alignItems: 'center', gap: 20}}>
        <div style={{width: 30, textAlign: 'center'}}><Icon strokeWidth={60} size="medium" color="primary" /></div>
        <span style={{fontSize: 15, width: '100%'}}>{children}</span>
    </div>
);

const Benefits: React.FC = () => (
    <div style={{marginTop: 10, marginBottom: 10}}>
        <div style={{position: 'relative', marginBottom: -10, zIndex: 1, display: 'flex', justifyContent: 'center'}}>
            <IonBadge color="medium" style={{background: 'white', border: '1px solid var(--ion-color-medium)', color: 'var(--ion-color-medium)'}}>
                Included with AutoPylot+
            </IonBadge>
        </div>
        <IonCard style={{'--color': 'unset', margin: 0, paddingTop: 20}} className="ion-padding">
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around', height: '100%'}}>
                <Benefit Icon={CheckmarkIcon}>Unlimited flight planning weather</Benefit>
                <Benefit Icon={CheckmarkIcon}>Adjust flight plan from weather</Benefit>
                <Benefit Icon={CheckmarkIcon}>24-hour flight forecasts</Benefit>
                <Benefit Icon={CheckmarkIcon}>10-day flight forecasts</Benefit>
                <Benefit Icon={CheckmarkIcon}>Windy map layers</Benefit>
                <Benefit Icon={CheckmarkIcon}>Weather charts</Benefit>
                <Benefit Icon={CheckmarkIcon}>New AutoPylot+ features</Benefit>
            </div>
        </IonCard>
    </div>
);

export const PaywallDialog: React.FC = () => {
    const { enableWebBypassUpgrade } = useInstance(DevSettings);

    const isNative = useIsNative();

    const alert = useInstance(AlertPresenter);
    const tracking = useInstance(TrackingService);

    const revenueCat = useInstance(RevenueCatModel);
    useSubscription(() => revenueCat);

    const dialogVM = useInstance(PaywallDialogViewModel);
    useSubscription(() => dialogVM);

    const storageProvider = useInstance(StorageProvider);

    const {isLargerScreen, isBelowBreakpoint} = useBreakpoints();

    const [working, setWorking] = useState(false);

    const [previouslyHadPlus, setPreviouslyHadPlus] = useState<boolean>(false);
    useAsyncEffect(async () => {
        const hasPlusStorageKey = 'has-autopylot-plus';
        if (revenueCat.hasUpgraded()) {
            storageProvider.getAsyncBooleanProvider(hasPlusStorageKey).set(true);
            setPreviouslyHadPlus(false);
        } else {
            if (await storageProvider.getAsyncBooleanProvider(hasPlusStorageKey).get()) {
                storageProvider.getAsyncBooleanProvider(hasPlusStorageKey).remove();
                setPreviouslyHadPlus(true);
                dialogVM.open({triggerSource: 'subscription expired'});
            }
        }
    }, [revenueCat, storageProvider]);

    const { monthlyPriceString, monthlySubtext, monthlyMessage, annualPriceString, annualSubtext, annualMessage, annualDiscountBadge, buttonText, buttonTextNoTrial } = revenueCat.getOfferMeta().paywallInfo || {};

    const continueButtonText = (revenueCat.isEligibleForTrial() ? buttonText : buttonTextNoTrial);

    const upgrade = async () => {
        try {
            setWorking(true);
            tracking.track('Paywall Continued', () => ({
                term,
                buttonText: continueButtonText
            }));
            await revenueCat.upgrade(term);
            dialogVM.close();
        } catch (e: any) {
            if (e && e.errorType === 'RevenueCatSDKPurchaseError'){
                const error = e as RevenueCatSDKPurchaseError;
                if (error.cancelled){
                    // then do nothing
                }
            }
            else {
                alert.showAndLogError(e);
            }
        } finally {
            setWorking(false);
        }
    };

    const restore = async () => {
        try {
            setWorking(true);
            await revenueCat.restore();
            dialogVM.close();
        } catch (e: any) {
            if (e && e.errorType === 'RevenueCatSDKPurchaseError'){
                const error = e as RevenueCatSDKPurchaseError;
                if (error.cancelled){
                    // then do nothing
                }
            }
            else {
                alert.showAndLogError(e);
            }
        } finally {
            setWorking(false);
        }
    };

    const canUpgrade = (isNative || enableWebBypassUpgrade);
    const [term, setTerm] = useState<IPaymentTerm>('annual');
    const hasUpgraded = revenueCat.hasUpgraded();
    const combinedPriceString = `${annualPriceString}/yr or ${monthlyPriceString}/mo`;

    return (
        <IonModal
            data-id="autopylot-plus"
            isOpen={dialogVM.isOpen()}
            onWillDismiss={dialogVM.close}
        >
            <PageHeader transparent onClose={dialogVM.close} />
            <IonContent>
                <div style={{margin: '0 20px'}}>

                    <DialogHeading
                        fontSize={isBelowBreakpoint('sm') ? 26 : 30}
                        previouslyHadPlus={previouslyHadPlus}
                    />
                    {previouslyHadPlus && (
                        <NudgeBanner
                            color="secondary"
                            excludeIcon={true}
                            data-type="previous-plus-badge"
                            message={
                                <div style={{textAlign: 'center'}}>
                                    If you didn’t mean to unsubscribe or want to regain access to your AutoPylot+ features, renew your subscription!
                                </div>
                            }
                        />
                    )}

                    {hasUpgraded && (
                        <>
                            <div style={{marginTop: 40, marginBottom: 20}}>
                                <SectionHeading>You're getting all these benefits!</SectionHeading>
                            </div>

                            <Benefits />

                            <div style={{position: 'absolute',  bottom: '5%', marginLeft: -20, width: '100%', textAlign: 'center'}}>
                                <IonButton color="dark" disabled={!revenueCat.canMaybeDowngrade() || working} fill="clear" onClick={revenueCat.maybeDowngrade}>Manage Subscription</IonButton>
                            </div>
                        </>
                    )}

                    {!hasUpgraded && (
                        <IonGrid style={{ padding: 0 }}>
                            <IonRow class="ion-align-items-center ion-justify-content-between" style={{ padding: 0 }}>

                                <IonCol size="12" sizeMd="6" style={{ padding: 0, marginTop: 0 }}>

                                    {(canUpgrade) &&
                                        <>
                                            <p style={{fontSize: 14}}>Select a plan</p>

                                            <PaymentTermButton
                                                disabled={working}
                                                isActive={term === 'annual'}
                                                onClick={() => setTerm('annual')}
                                                termLabel={`Yearly Plan - ${annualPriceString}`}
                                                termDescription={annualSubtext || 'Billed annually, 7-day free trial'}
                                                discountBadge={annualDiscountBadge}
                                            />

                                            <PaymentTermButton
                                                disabled={working}
                                                isActive={term === 'monthly'}
                                                onClick={() => setTerm('monthly')}
                                                termLabel={`Monthly Plan - ${monthlyPriceString}`}
                                                termDescription={monthlySubtext || 'Billed monthly, 7-day free trial'}
                                            />

                                        </>
                                    }

                                    {!canUpgrade &&
                                        <>
                                            <SectionHeading>{combinedPriceString}!</SectionHeading>

                                            <CenteredText>Purchase on the App Store or Google Play and unlock for web. You can cancel any time.</CenteredText>

                                            <StoreButton imgSrc="assets/images/paywall/app-store-badge.svg" altText="App Store" storeUrl={StoreConfigs.ios.installURL} />
                                            <StoreButton imgSrc="assets/images/paywall/play-store-badge.svg" altText="Google Play" storeUrl={StoreConfigs.android.installURL} />
                                        </>
                                    }

                                    {isLargerScreen && revenueCat.canRestore() &&
                                        <IonButton expand="block" fill="clear" color="secondary" onClick={restore} data-id="restore">Restore Purchases</IonButton>
                                    }

                                </IonCol>

                                <IonCol size="12" sizeMd="5" style={{ padding: 0 }}>
                                    <Benefits />

                                    {!isLargerScreen && revenueCat.canRestore() &&
                                        <IonButton expand="block" fill="clear" color="secondary" onClick={restore} data-id="restore">Restore Purchases</IonButton>
                                    }
                                </IonCol>

                            </IonRow>
                        </IonGrid>
                    )}
                </div>

                {working &&
                    <Spinner centered />
                }
            </IonContent>

            <IonFooter>
                {canUpgrade && !hasUpgraded &&
                    <div style={{margin: '20px 40px 10px'}}>

                        <div style={{width: '100%', textAlign: 'center', marginTop: -10}}>
                            <IonText color="medium"><small>{term === 'annual' ? annualMessage : monthlyMessage}</small></IonText>
                        </div>

                        <IonButton
                            disabled={working}
                            expand="block"
                            fill="solid"
                            color="primary"
                            onClick={upgrade}
                            data-id="continue"
                        >
                            <span>{continueButtonText}</span>
                        </IonButton>

                        <div style={{width: '100%', textAlign: 'center', marginTop: 10}}>
                            <IonText color="medium">Cancel any time, we'll remind you</IonText>
                        </div>

                        <div style={{marginTop: -5}}>
                            <IonButton disabled={working} expand="block" fill="clear" color="secondary" href="https://www.autopylot.io/terms" target="_blank">Terms of Use</IonButton>
                        </div>

                    </div>
                }
            </IonFooter>
        </IonModal>
    );
};
