import { State } from '@meraki-internal/state';
import { TrackingService } from '../../support/tracking/TrackingService';
import { MapViewModel } from '../model/MapViewModel';
import { IApMapboxStylerLogicalLayer } from '../styles/ApMapboxStyler';
import { StorageProvider } from '../../support/StorageProvider';

export type IToggleableLayerId = 'laanc'
    | 'all'
    | 'national-security'
    | 'temporary-flight-restrictions'
    | 'special-use-airspace'
    | 'national-areas';

interface IToggleableLayer {
    layerId: IToggleableLayerId,
    stylerLayers: IApMapboxStylerLogicalLayer[];
    enabled: boolean;
}

const defaultLayers: IToggleableLayer[] = [
    {
        layerId: 'laanc',
        enabled: true,
        stylerLayers: ['airspace', 'uasfm', 'rec-flyer-fixed-sites']
    },
    {
        layerId: 'national-security',
        enabled: true,
        stylerLayers: ['nsufr', 'airspace-boundary']
    },
    {
        layerId: 'temporary-flight-restrictions',
        enabled: true,
        stylerLayers: ['temporary-flight-restrictions']
    },
    {
        layerId: 'special-use-airspace',
        enabled: true,
        stylerLayers: ['special-use-airspace', 'military-training-routes']
    },
    {
        layerId: 'national-areas',
        enabled: true,
        stylerLayers: ['national-parks', 'wilderness-areas', 'wildlife-refuges']
    }
];

type IVisibleMapLayersStateStorage = {
    [ key in IToggleableLayerId ]: boolean;
}

class VisibleMapLayersStateStorage {
    static inject = () => [StorageProvider];
    constructor(private storage: StorageProvider){}
    visibleLayers = this.storage.getJSONProvider<IVisibleMapLayersStateStorage>('MapVisibleLayers.v2', { storageType: 'localStorage' });
}

/**
 * exposes toggles for  ...defaultLayers, and an "all"
 *
 * If all is toggled off, then the radius is also turned into an "outline" mode (no fill)
 * But not if every one is turned off independently
 *
 * When one is toggled back on, the radius will fill back in.
 *
 * This is "magic" to the  user, and ideally (arguably) would be its own toggle, but more time is needed to consider the UX for it.
 *
 * "All" is off, when every toggle is off. If any toggle is on, then "all" is on.
 *
 */
export class VisibleMapLayersState extends State<{ isShowingControls: boolean }> {
    static inject = () => [
        MapViewModel,
        TrackingService,
        VisibleMapLayersStateStorage
    ];
    constructor(
        private mapViewModel: MapViewModel,
        private trackingService: TrackingService,
        private storage: VisibleMapLayersStateStorage
    ){
        super({ isShowingControls: false });

        this.layers = defaultLayers;

        // sync enabled from local storage (if set)
        if (this.storage.visibleLayers.exists()){
            const enabledMapping  = this.storage.visibleLayers.get()!;
            Object.keys(enabledMapping).forEach(key => {
                const layer = this.layers.find(l => l.layerId === key);
                if (layer){
                    layer.enabled = (enabledMapping as any)[key];
                }
            });
        }
    }

    private layers: IToggleableLayer[];

    init = () => {
        for (const layer of this.layers){
            this.updateMap(layer);
        }
    };

    getLayers = (): IToggleableLayer[] => {
        return this.layers;
    };

    isAllLayersToggleEnabled = (): boolean => {
        const anyEnabled = this.layers.some(l => l.enabled);

        return anyEnabled;
    };

    showControls = () => {
        this.trackingService.track('Opened Map Layers');

        this.setState({ isShowingControls: true });
    };

    hideControls = () => {
        this.setState({ isShowingControls: false });
    };

    toggleControls = () => {
        this.setState({ isShowingControls: !this.state.isShowingControls });
    };

    private toggleAll = () => {
        const anyEnabled = this.layers.some(l => l.enabled);

        const enabled = !anyEnabled;
        for (const layer of this.layers){
            layer.enabled = enabled;
            this.updateMap(layer);
        }

        if (!enabled){
            this.mapViewModel.hideRadiusFill();
        } else {
            this.mapViewModel.showRadiusFill();
        }
        this.syncToLocalStorage();
        this.setState({ });
    };

    private updateMap(layer: IToggleableLayer){
        for (const stylerLayer of layer.stylerLayers){
            this.mapViewModel.setLogicalLayerVisibility(stylerLayer, layer.enabled ? 'all' : 'none');
        }
    }

    private toggleOneLayer = (layerId: IToggleableLayerId) => {
        const layer = this.layers.find(l => l.layerId === layerId)!;
        layer.enabled = !layer.enabled;
        this.syncToLocalStorage();
        this.setState({});

        this.updateMap(layer);

        const anyEnabled = this.layers.some(l => l.enabled);
        if (anyEnabled){
            // if we see performance cost, we may want to track whether we need to do this or not
            // so we only do it when the radiusfill was hidden
            this.mapViewModel.showRadiusFill();
        }
    };

    toggleLayer = (layerId: IToggleableLayerId) => {
        if (layerId === 'all'){
            this.trackingService.track('Toggled All Airspace Layers', () => ({
                toggledTo: !this.isAllLayersToggleEnabled() ? 'on' : 'off'
            }));

            this.toggleAll();
        }
        else {
            this.trackingService.track('Toggled All Airspace Layers', () => ({
                layerId,
                toggledTo: !this.layers.find(l => l.layerId === layerId)?.enabled ? 'on' : 'off'
            }));

            this.toggleOneLayer(layerId);
        }
    };

    syncToLocalStorage = () => {
        const value: IVisibleMapLayersStateStorage = {} as any;
        for (const layer of this.layers){
            value[layer.layerId] = layer.enabled;
        }
        this.storage.visibleLayers.set(value);
    };

    // used by tests, doesn't sync to local storage
    forceAllOn = () => {
        for (const layer of this.layers){
            layer.enabled = true;
            this.updateMap(layer);
        }
        this.setState({ });
    };
}
