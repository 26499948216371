import { State } from '@meraki-internal/state';
import { StorageProvider } from '../../support/StorageProvider';
import { Logger } from '../../support/debug/Logger';
import { TrackingService } from '../../support/tracking/TrackingService';

interface IAnnouncementDialogProps {
    announcementKey: string;
    imagePath: string;
    onContinue: () => void;
}

export class AnnouncementDialogState extends State<IAnnouncementDialogProps & { isOpen: boolean }> {

    static inject = () => [StorageProvider, Logger, TrackingService];
    constructor(
        private storageProvider: StorageProvider,
        private logger: Logger,
        private tracking: TrackingService,
    ) {
        super({ isOpen: false, announcementKey: '', imagePath: '', onContinue: () => {} });
    }

    open = async ({ announcementKey, imagePath, onContinue }: IAnnouncementDialogProps) => {
        try {
            // ignore if another announcement is already showing
            if (this.state.isOpen) {
                return;
            }

            this.tracking.track('Announcement Opened', () => ({
                announcementKey,
            }));

            this.setState({
                announcementKey,
                imagePath,
                onContinue,
                isOpen: true
            });
        } catch (err: any) {
            this.logger.error(`AnnouncementDialogState.open() (key: ${announcementKey}) failed gracefully, with error ${err.toString()}`);
        }
    };

    openIfNotAlreadySeen = async ({ announcementKey, imagePath, onContinue }: IAnnouncementDialogProps) => {
        try {
            // ignore if another announcement is already showing
            if (this.state.isOpen) {
                return;
            }

            const storage = this.storageProvider.getAsyncStringProvider(`${announcementKey}.seen`, {
                storageType: 'localStorage',
                envNeutral: false,
                userNeutral: false,
                maxBytes: 50,
                removeOnTooBig: true,
            });

            // if already seen it, no announcement
            if (await storage.exists()){
                return;
            }

            await storage.set(new Date().toISOString());

            await this.open({ announcementKey, imagePath, onContinue });

        } catch (err: any) {
            this.logger.error(`AnnouncementDialogState.openIfNotAlreadySeen() (key: ${announcementKey}) failed gracefully, with error ${err.toString()}`);
        }
    };

    handleContinue = () => {
        this.tracking.track('Announcement Continued', () => ({
            announcementKey: this.state.announcementKey,
        }));

        this.state.onContinue();

        // close without tracking
        this.setState({ imagePath: '', onContinue: () => {}, isOpen: false });
    };

    close = () => {
        this.tracking.track('Announcement Closed', () => ({
            announcementKey: this.state.announcementKey,
        }));

        this.setState({ imagePath: '', onContinue: () => {}, isOpen: false });
    };

    isOpen = () => {
        return this.state.isOpen;
    };
}
