import { IonText } from '@ionic/react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { Color, Size } from '../components/common';
import { AirspaceGoodIcon, AirspaceNotLAANCIcon, IconVariant } from '../components/icons/Icons';
import { ICommonMissionState } from '../missions/model/ICommonMissionState';
import { MissionColorProvider } from './MissionColorProvider';
import { LastAuthorizedRadiusModel } from '../map/mission-pin-and-radius/LastAuthorizedRadiusModel';
import { PrimaryAdvisoryProvider } from './PrimaryAdvisoryProvider';

const colorToIcon: { [color: string]: React.FC<{ slot?: 'end' | 'start' | 'icon-only', size?: Size, color?: Color, variant?: IconVariant}> } = {
    'success': AirspaceGoodIcon,
    'advisory-laanc': AirspaceGoodIcon,
    'advisory-warning': AirspaceGoodIcon,
    'danger': AirspaceNotLAANCIcon
};

export const PrimaryAdvisory = ({ mission }: { mission: ICommonMissionState }) => {
    const lastAuthorizedModel = useInstance(LastAuthorizedRadiusModel);

    const missionRadius = mission.getMissionRadius() || lastAuthorizedModel.lastAuthorizedRadius;

    const provider = useInstance(PrimaryAdvisoryProvider);

    if (!missionRadius) {
        throw new Error('<PrimaryAdvisory/> was rendered for a mission with no missionRadius polygon');
    }
    const color = useInstance(MissionColorProvider).getColor(missionRadius);
    const primary = provider.getPrimaryAdvisory(mission, color);
    const Icon = primary.Icon || colorToIcon[color as string];

    return (
        <div style={{ padding: 8, display: 'flex', gap: 20, alignItems: 'center', justifyContent: 'space-between', backgroundColor: `rgba(var(--ion-color-${color}-rgb), 0.1)`, marginBottom: 10, borderTopRightRadius: 4, borderBottomRightRadius: 4 }}>
            <div>
                <h5 data-type="flight-status" data-flight-status-color={color} style={{ fontWeight: 'bold', margin: 0 }}>{primary.heading}</h5>
                <IonText color="medium"><p style={{ fontSize: 12, marginTop: 4, marginBottom: 0 }}>{primary.description}</p></IonText>
            </div>
            <div>
                <Icon color={primary.color || color} variant="filled" size="large" />
            </div>
        </div>
    );
};
