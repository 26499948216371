/**
 * This file is a fork of https://github.com/mapbox/mapbox-gl-js/blob/e29e113ff5e1f4c073f84b8cbe546006d2fb604f/src/ui/handler/touch_zoom_rotate.js
 * with insignificant changes from Flow to Typescript
 * and all other changes marked with // <Autopylot> ... </Autopylot>
 *
 * NOTE: best DX for this is to start in web mode, and use chrome on the device to go to your mac ip
 * you can then, in a 2nd chrome tab on the device, go to chrome://inspect, start logging, and see the logs
 */

import { Map } from 'mapbox-gl';

const ZOOM_THRESHOLD = 0.1;

function getTouchById(mapTouches: Array<Touch>, points: any, identifier: number) {
    for (let i = 0; i < mapTouches.length; i++) {
        if (mapTouches[i].identifier === identifier) return points[i];
    }
}

// Suppress the next click, but only if it's immediate.
function suppressClickListener(e: any) {
    e.preventDefault();
    e.stopPropagation();
    window.removeEventListener('click', suppressClickListener, true);
}
function suppressClick() {
    window.addEventListener('click', suppressClickListener, true);
    window.setTimeout(() => {
        window.removeEventListener('click', suppressClickListener, true);
    }, 0);
}

function getZoomDelta(distance: number, lastDistance: number) {
    return Math.log(distance / lastDistance) / Math.LN2;
}

class TwoTouchHandler {
    _enabled?: boolean;
    _active?: boolean;
    _firstTwoTouches?: [number, number];
    _vector?: any;
    _startVector?: any;
    _aroundCenter?: boolean;

    constructor() {
        this.reset();
    }

    reset() {
        this._active = false;
        this._firstTwoTouches = undefined;
    }

    _start(points: any) {} //eslint-disable-line
    _move(points: any, pinchAround: any, e: TouchEvent): any { return {}; } //eslint-disable-line

    touchstart(e: TouchEvent, points: any, mapTouches: Array<Touch>) {
        //console.log(e.target, e.targetTouches.length ? e.targetTouches[0].target : null);
        //log('touchstart', points, e.target.innerHTML, e.targetTouches.length ? e.targetTouches[0].target.innerHTML: undefined);
        if (this._firstTwoTouches || mapTouches.length < 2) return;

        this._firstTwoTouches = [
            mapTouches[0].identifier,
            mapTouches[1].identifier
        ];

        // implemented by child classes
        this._start([points[0], points[1]]);
    }

    touchmove(e: TouchEvent, points: any, mapTouches: Array<Touch>): any {
        const firstTouches = this._firstTwoTouches;
        if (!firstTouches) return;

        e.preventDefault();

        const [idA, idB] = firstTouches;
        const a = getTouchById(mapTouches, points, idA);
        const b = getTouchById(mapTouches, points, idB);
        if (!a || !b) return;
        const pinchAround = this._aroundCenter ? null : a.add(b).div(2);

        // implemented by child classes
        return this._move([a, b], pinchAround, e);

    }

    touchend(e: TouchEvent, points: any, mapTouches: Array<Touch>) {
        if (!this._firstTwoTouches) return;

        const [idA, idB] = this._firstTwoTouches;
        const a = getTouchById(mapTouches, points, idA);
        const b = getTouchById(mapTouches, points, idB);
        if (a && b) return;

        if (this._active) suppressClick();

        this.reset();
    }

    touchcancel() {
        this.reset();
    }

    enable(options: any) {
        this._enabled = true;
        this._aroundCenter = !!options && options.around === 'center';
    }

    disable() {
        this._enabled = false;
        this.reset();
    }

    isEnabled(): boolean {
        return this._enabled!;
    }

    isActive(): boolean {
        return this._active!;
    }
}

export class PinchZoomWithoutPanHandler extends TwoTouchHandler {

    _distance?: number;
    _startDistance?: number;
    _dragPanDisabledByMove = false;

    // <Autopylot>
    constructor(private map: Map) {
        super();
        (map as any).handlers._add('PinchZoomWithoutPanHandler', this , ['touchPan']);
    }
    // </Autopylot>

    reset() {
        // reset gets called on initializing the interaction, and when interactions are complete
        super.reset();
        this._distance = 0;
        this._startDistance = 0;

        // <Autopylot>
        // we need to enable panning again now that we're done with the pinch gesture
        if (this._dragPanDisabledByMove){ // which won't be true when reset is called early in the app lifecycle (init)
            this.map.dragPan.enable();
            this._dragPanDisabledByMove = false;
        }
        // </Autopylot>
    }

    _start(points: any) {
        this._startDistance = this._distance = points[0].dist(points[1]);

        // <Autopylot>
        // we want to disable panning so that only the pinch zoom is intepreted from the interaction
        this.map.dragPan.disable();
        this._dragPanDisabledByMove = true;
        // </Autopylot>
    }

    _move(points: any, pinchAround: any): any {
        const lastDistance = this._distance;
        this._distance = points[0].dist(points[1]);

        if (!this._active && Math.abs(getZoomDelta(this._distance!, this._startDistance!)) < ZOOM_THRESHOLD) return;
        this._active = true;

        // <Autopylot>
        // we don't return pinchAround as we don't want to change the center
        return {
            zoomDelta: getZoomDelta(this._distance!, lastDistance!),
        };
        // </Autopylot>

    }
}

