import { Device } from '../../support/Device';
import { IonContent } from '@ionic/react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { createUseStyles } from 'react-jss';
import { Color } from '../common';

// content on pages without fullWidth attribute is constrained to this max width
export const MAX_PAGE_WIDTH = 768;

// in ios (including web), Ionic adds a px to the bottom, causing a scroll bar, so it can animate
// scrolling to the bottom and bouncing back. This is distracting in web, suggesting we have a css
// bug. Ignoring it, would lead to us to not catch such a real bug. This component undoes that behavior.
const useStyles = createUseStyles({
    betterIonContent: {
        '& ::part(scroll)::before': {bottom: 0}
    }
});

const BetterWebIonContent: React.FC = ({ children, ...props }) => {
    const classes = useStyles();
    return <IonContent className={classes.betterIonContent} {...props}>{children}</IonContent>;
};

/**
 * NOTE: I did not pass through the props, consistent with our strategy to only expose what we opt-in to
 * and support.
 * a) this is a form of documentation, reducing the surface area of the entire ionic API down to what is
 *    releveant to us
 * b) when adding something, we should review all other uses to see if it too should be using that too
 */
export interface IPageContentProps {
    noScroll?: boolean;
    color?: Color;
    fullWidth?: boolean;
    onClick?: () => void;
}

export const PageContent: React.FC<IPageContentProps> = ({ noScroll, color, fullWidth, onClick, children } ) => {
    const deviceInfo = useInstance(Device);
    const Content = deviceInfo.platform === 'web' ? BetterWebIonContent : IonContent;
    const style = { '--overflow': noScroll ? 'hidden' : undefined };

    return <Content color={color} style={style} onClick={onClick}>
        <div style={{height: '100%', width: '100%', maxWidth: fullWidth ? undefined : MAX_PAGE_WIDTH, margin: 'auto', overflow: 'auto'}}>
            {children}
        </div>
    </Content>;
};
