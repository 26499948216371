import { useEffect, useRef } from 'react';
import { MapViewModel } from '../model/MapViewModel';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { AttributionActionSheet } from '../attribution/AttributionActionSheet';

export const ShowMap: React.FC<{onClick?: (e: any) => void, onLongPress?: (e: any) => void}> = ({ onClick, onLongPress }) => {
    const temporaryMapContainer = useRef(null);
    const mapViewModel = useInstance(MapViewModel);

    useEffect(() => {
        if (temporaryMapContainer.current){
            mapViewModel.showMapInDomContainer(temporaryMapContainer.current);
            mapViewModel.onClick = onClick;
            mapViewModel.onLongPress = onLongPress;
        }

        return () => {
            mapViewModel.removeMapFromDomContainer();
        };
    }, [mapViewModel, temporaryMapContainer, onClick, onLongPress]);

    return (
        <div id="show-map-container" ref={temporaryMapContainer} style={{ height: '100%', width: '100%', position: 'relative' }} onContextMenu={e => e.preventDefault()}>
            <AttributionActionSheet />
        </div>
    );
};
