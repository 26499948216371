import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useSubscription } from '@meraki-internal/state';
import { BaseMapState, IMapboxStyleId } from '../styles/BaseMapState';

export const BasemapButton: React.FC<{  imageName: string, label: string, mapboxId: IMapboxStyleId }> = ({ imageName, label, mapboxId }) => {
    const  vm = useInstance(BaseMapState);
    useSubscription(() => vm);

    const isActive = vm.getCurrentStyleId() === mapboxId;

    return (
        <div onClick={() => vm.switchBaseMap(mapboxId)} data-basemap-id={mapboxId}>
            <div style={{
                backgroundImage: `url('/assets/images/basemap/${imageName}.png')`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                boxSizing: 'content-box',
                width: 100,
                height: 91,
                borderRadius: 10,
                padding: 0,
                border: `solid 2px ${ isActive ? 'var(--ion-color-primary-shade)' : 'white' }`
            }} />
            <div style={{ textAlign: 'center', color: isActive ? 'var(--ion-color-primary-shade)' : 'var(--ion-color-step-600)', opacity: isActive ? 1 : .5, fontSize: 14, fontWeight: isActive ? 'bolder': 'normal' }}>
                {label}
            </div>
        </div>
    );
};
