import React from 'react';
import { formatLocalDate, formatLocalTimeRange } from '../../support/helpers/format';
import { ICommonMissionState } from '../model/ICommonMissionState';

export const MissionTimes: React.FC<{mission: ICommonMissionState}> = ({ mission }) => {
    return (
        <h5 style={{ whiteSpace: 'nowrap', marginTop: 3 }}>
            {formatLocalDate({ date: mission.getStartTime(), location: mission.getLocation()! })}
            &nbsp;
            {formatLocalTimeRange({ startTime: mission.getStartTime(), endTime: mission.getEndTime(), location: mission.getLocation()!, withTimeZone: true })}
        </h5>
    );
};
