// token used to register ionic App.getInfo() in ioc
// (need our own token because ionic classes don't play nice with aurelia)
export class AppInfo {

    constructor() {
        throw new Error('AppInfo must be instantiated via ioc');
    }

    name = '';
    id = '';
    version = '';
    build = '';
}
