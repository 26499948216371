import { APIClient } from '@autopylot-internal/autopylot-api-client';
import { Container } from '@meraki-internal/react-dependency-injection';
import { AutoPylotPrincipal } from '../../auth/AutoPylotPrincipal';
import { SavedChecklistsState } from '../../checklists/SavedChecklistsState';
import { RefreshTimerConfig } from '../../components/refresher/RefreshTimer';
import { ErrataState } from '../../map/ErrataState';
import { GeolocationService } from '../../map/location/GeolocationService';
import { MapViewModelConfig, MapViewModelStorage } from '../../map/model/MapViewModel';
import { BaseMapState } from '../../map/styles/BaseMapState';
import { MissionsState } from '../../missions/model/MissionsState';
import { OperatorFlagsState } from '../../profile/OperatorFlagsState';
import { WeatherServiceForecastMocker } from '../../weather/WeatherServiceForecastMocker';
import { WindyMapViewModelConfig } from '../../weather/windy/WindyMapViewModel';
import { DeviceStatusBar } from '../DeviceStatusBar';
import { DevSettings } from '../DevSettings';
import { TrackingService } from '../tracking/TrackingService';
import { HistoryViewModel } from '../../app/HistoryViewModel';
import { FeedbackAlertStorage } from '../../app/FeedbackAlertStorage';
import { StorageProvider } from '../StorageProvider';

export class ScreensContainer {
    static inject = () => [Container];
    constructor(private container: Container){}
    mount = async () => {
        // see EnvConfig
        this.container.get(StorageProvider).setUserId('screenshots');

        this.container.get(MapViewModelStorage).forceStagingMapbox.set(true);

        this.container.get(DevSettings).disableNotRealWarnings = true;

        this.container.registerInstance(MapViewModelConfig, { DISABLE_MAP: true });
        this.container.registerInstance(WindyMapViewModelConfig, { DISABLE_MAP: true });

        // disable navigation
        this.container.get(HistoryViewModel).back = () => {};
        this.container.get(HistoryViewModel).replace = () => {};
        this.container.get(HistoryViewModel).push = () => {};

        this.container.get(ErrataState).pollForErrata = async () => {
            return new Promise(resolve => {
                // never resolve to be consistent with real implementation
            });
        };

        // don't show feedback alert by default
        this.container.get(FeedbackAlertStorage).feedbackAlerts.set({ enjoyFirstMissionShown: new Date().toISOString() });

        this.container.get(WeatherServiceForecastMocker).startMocking();

        this.container.get(AutoPylotPrincipal).init({
            userId: `screens:${Date.now()}`,
            email: `screenshots@autopylot.io`,
            isAdmin: false
        });

        this.container.get(MissionsState).loadAll = async () => {};
        this.container.get(APIClient).get = () => {
            throw new Error('screenshots should not be trying to GET');
        };
        this.container.get(APIClient).post = () => {
            throw new Error('screenshots should not be trying to POST');
        };
        this.container.get(APIClient).delete = () => {
            throw new Error('screenshots should not be trying to DELETE');
        };
        this.container.get(APIClient).put = () => {
            throw new Error('screenshots should not be trying to PUT');
        };
        this.container.get(APIClient).entry = () => {
            throw new Error('screenshots should not be trying to GET entry resource');
        };

        this.container.get(BaseMapState).getCurrentBaseMapUrl = () => ({
            version: 8,
            sources: {},
            glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
            layers: [
                {
                    id: 'background',
                    type: 'background',
                    paint: { 'background-color': 'silver' }
                }
            ]
        } as any);

        this.container.get(SavedChecklistsState).load = async () => {};

        this.container.registerInstance(RefreshTimerConfig, {
            disableRefresh: true
        });

        this.container.get(GeolocationService).getLocationPermissionStatus = () => Promise.resolve('denied');

        this.container.registerInstance(OperatorFlagsState, {
            hasFlag: () => true
        });

        // this needs to happen before initializing tracking (because we need statusbar height for metrics),
        // but invoke as late as possible to allow time for safari to set env var
        await this.container.get(DeviceStatusBar).init();

        // lets not alert to sentry if meta needed only for tracking isn't available to the screenshots
        this.container.get(TrackingService).trackWithDebounce = () => {};
        this.container.get(TrackingService).track = () => {};
    };
}
