import { useInstance } from '@meraki-internal/react-dependency-injection';
import { ITriggerPaywallSource, PaywallDialogViewModel } from './PaywallDialogViewModel';
import { WindyMapModalState } from '../weather/windy/WindyMapModalState';
import { HistoryViewModel } from '../app/HistoryViewModel';
import { useEffect } from 'react';

export const PaywallTriggerHandler: React.FC = () => {

    const history = useInstance(HistoryViewModel);
    const paywall = useInstance(PaywallDialogViewModel);
    const windyModal = useInstance(WindyMapModalState);

    useEffect(() => {

        // if guest triggers paywall, they are sent to login with this query param in the returnTo url
        const paywallTrigger = (history.popSearchParam({ key: 'paywallTrigger' })) as ITriggerPaywallSource;

        if (paywallTrigger) {

            if (paywallTrigger === 'windy') {
                // windy is a special case, we want windy modal open after paywall
                windyModal.open({ skipPreview: true });

            } else {
                paywall.open({ triggerSource: paywallTrigger });
            }
        }

    }, [history, paywall, windyModal]);

    return null;
};
