import { Container } from '@meraki-internal/react-dependency-injection';
import { OperatorState } from '../profile/OperatorState';
import { IScreenshotablePage } from '../support/screenshots/IScreenshotablePage';
import { SettingsPage } from './SettingsPage';
import { AppInfo } from '../support/AppInfo';

interface IMyPageProps {
    signedIn?: boolean;
}

export default class ScreenshotSettingsPage implements IScreenshotablePage<IMyPageProps> {

    static inject = () => [OperatorState, Container];
    constructor(private operatorState: OperatorState, private container: Container) {}

    screenId = 'settings';

    // choose simple  page to reduce dependencies
    ComponentUnderTest = SettingsPage;

    permutationToProps = ({ signedIn }: IMyPageProps) => {

        // AppContainer registers AppInfo as null in web
        this.container.unregister(AppInfo);
        this.container.registerInstance(AppInfo, { version: '1.5.0' });

        if (signedIn) {
            this.operatorState.setState({ email: 'test-pilot@autopylot.io'});
        }
        return {};
    };

    permutations: { [key: string]: IMyPageProps } = {
        'guest': {
        },
        'signed-in': {
            signedIn: true
        },
    };
}
