import { IWildlifeRefugeAdvisoryProperties } from '@autopylot-internal/tiles-client';
import { SecondaryWarningAdvisory } from '../SecondaryAdvisory';
import { AdvisoryBlockIcon } from '../../components/icons/Icons';

export const WildlifeRefugeAdvisory = (props: (IWildlifeRefugeAdvisoryProperties)) => {
    return (
        <SecondaryWarningAdvisory header="Wildlife Refuge" advisoryType={props.advisoryType} GLOBAL_ID={props.GLOBAL_ID} icon={AdvisoryBlockIcon} iconColor='danger'>
            <>
                <p>
                    Launching, landing, or disturbing wildlife within Wildlife Refuges is prohibited.
                    Your mission overlaps <strong>{props.ORGNAME}</strong>. Violations are punishable
                    by harsh fines and penalties.
                </p>
                <p>
                    <a href="https://www.fws.gov/law/drones-unmanned-aircraft" target="_blank">FWS Drone Law</a>
                </p>
            </>
        </SecondaryWarningAdvisory>
    );
};
