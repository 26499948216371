import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useEffect, useState } from 'react';
import { HistoryViewModel } from '../app/HistoryViewModel';
import { AlertPresenter } from '../app/AlertBinder';
import { MissionsState } from '../missions/model/MissionsState';

export const InvalidMissionPage: React.FC = () => {
    const history = useInstance(HistoryViewModel);
    const missionsState = useInstance(MissionsState);

    const { showAlert } = useInstance(AlertPresenter);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        // need to reload before we start, otherwise we might have an
        // outdated list that doesn't include the invalid mission
        missionsState.loadRecentAndUpcoming().then(() => setLoaded(true));

        if (loaded) {
            const invalidMissions = missionsState.get().missions.filter(m => m.mustCancel());

            if (invalidMissions.length) {
                showAlert({
                    header: 'Do Not Fly',
                    message: 'Per the FAA, this mission is no longer approved and <b>you will not be able to fly</b> the mission. <b>You must cancel</b> the mission.',
                    buttons: [ 'OK' ]
                });

                invalidMissions.sort((m1, m2) => m1.getStartTime().localeCompare(m2.getStartTime()));
                const firstInvalidMission = invalidMissions[0].get();
                history.replace(`/missions/${firstInvalidMission.missionId}`);

            } else {
                showAlert({
                    header: 'No Action Required',
                    message: `You don’t currently have any missions that need to be canceled per the FAA.`,
                    buttons: [ 'OK' ]
                });

                history.replace('/missions');
            }
        }
    }, [loaded]); // eslint-disable-line react-hooks/exhaustive-deps

    return null;
};
