import moment from 'moment';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { IonItem, IonLabel, IonText } from '@ionic/react';
import { EditableMissionState } from '../mission/model/EditableMissionState';
import { useSubscription } from '@meraki-internal/state';
import { InsuranceIcon, UmbrellaIcon } from '../components/icons/Icons';
import { InsuranceState } from './InsuranceState';
import { IInsurancePolicy } from './IInsurancePolicy';
import { getLiabilityLimit } from './policyHelpers';
import { HistoryViewModel } from '../app/HistoryViewModel';
import { round } from '../support/helpers/format';
import { useRef } from 'react';

export const InsuranceButton: React.FC<{ mission: EditableMissionState }> = ({ mission }) => {
    const history = useInstance(HistoryViewModel);

    const insurance = useInstance(InsuranceState);
    useSubscription(() => insurance);

    const reloaded = useRef(false);

    let policy: IInsurancePolicy | undefined;
    if (insurance.state.loaded) {
        policy = insurance.getActivePolicy({
            startTime: mission.getStartTime(),
            endTime: mission.getEndTime()
        });

        // do a single reload if policy is pending
        if (policy?.pending && !insurance.state.loading && !reloaded.current) {
            reloaded.current = true;
            insurance.load();
        }
    }

    let iconRotation = 0;
    let label = '';
    let showCta = false;

    if (!insurance.state.loaded && insurance.state.loading) {
        label = 'Loading, please wait...';
    } else if (!insurance.state.loaded) {
        iconRotation = 180;
        label = 'Sorry, insurance unavailable right now';
    } else if (policy) {
        label = `$${round(getLiabilityLimit(policy)/1000000, 2)}M Liability | ${policy.pending ? 'Policy Pending' : 'Expires ' + moment(policy.end_date).format('M/D/YYYY')}`;
    } else {
        iconRotation = 180;
        label = mission.hasEnded() ? 'No policy found for mission' : 'Insure your flight today';
        showCta = (!mission.hasEnded());
    }

    const onClick = () => {
        if (policy) {
            history.push(`/insurance/policies/${policy.id}`);
        } else {
            history.push('/insurance');
        }
    };

    return (
        <IonItem button detail={false} onClick={onClick}>
            <IonLabel>
                <h2>Insurance</h2>
                <p>
                    <span style={{display: 'flex', alignItems: 'center', gap: 10}}>
                        <span style={{display: 'flex', alignItems: 'center', gap: 5}}>
                            <UmbrellaIcon color="primary" size="small" rotationDegrees={iconRotation} />
                            <span>{label}</span>
                        </span>
                    </span>
                </p>
            </IonLabel>
            <div slot="end">
                {showCta &&
                    <IonText slot="end" color="primary">Free Quote</IonText>
                }
                {!showCta &&
                    <InsuranceIcon slot="end" color="medium" size="medium" />
                }
            </div>
        </IonItem>
    );
};
