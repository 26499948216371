import { IToggleableLayerId } from './VisibleMapLayersState';
import { IonText, IonToggle } from '@ionic/react';

const toggleableLayerIdToTitleMap: { [key in IToggleableLayerId]: string} = {
    laanc: 'LAANC, Controlled Airspace',
    all: 'All Airspace Layers',
    'national-security': 'National Security Flight Restrictions',
    'temporary-flight-restrictions': 'Temporary Flight Restrictions',
    'special-use-airspace': 'Special Use Airspace',
    'national-areas': 'National Areas',
    // 'stadiums': 'Stadiums'
};

const toggleableLayerIdToDescriptionMap: { [key in IToggleableLayerId]: string} = {
    laanc: 'Controlled airspace where LAANC is available, inactive, or not supported.',
    all: 'All layers below.',
    'national-security': 'Airspace with National Security Flight Restrictions, including the Washington DC Flight Restriction Zone.',
    'temporary-flight-restrictions': 'Areas with TFRs: time-based airspace restrictions due to hazardous conditions, a special event, or a general warning, including the DC Special Flight Rules Area.',
    'special-use-airspace': 'Restricted, Prohibited, and Part-Time SUAs where hazardous, often military-related, air traffic or activities occur.',
    'national-areas': 'National Parks, Wilderness Areas, National Wildlife Refuges.',
    // 'stadiums': 'Sports stadiums and their corresponding 3 mile radius'
};

// TODO: this markup is ripped from SecondaryAdvisory but ran out of time to refactor to a common component
export const LayerToggle: React.FC<{ layerId:IToggleableLayerId, enabled: boolean, onToggle: () => void }> = ({ layerId, enabled, onToggle }) => {
    return (
        <div  style={{ padding: 6, display: 'flex', gap: 25, alignItems: 'flex-start', justifyContent: 'space-between', borderTopRightRadius: 4, borderBottomRightRadius: 4 }}>
            {/* TODO: better way of doing this */}
            <div>
                <h6 style={{ margin: 0 }}>{toggleableLayerIdToTitleMap[layerId]}</h6>
                <IonText color="medium">
                    <div style={{ fontSize: 12, marginTop: 4, marginBottom: 0 }}>
                        {toggleableLayerIdToDescriptionMap[layerId]}
                    </div>
                </IonText>
            </div>
            <IonToggle style={{flexShrink: 0}} data-id={layerId} slot="end" checked={enabled} onClick={() => onToggle()}/>
        </div>
    );
};
