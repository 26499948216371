import { ITourKey } from '../app/intercom/IntercomService';
import { IScreenshotablePage } from '../support/screenshots/IScreenshotablePage';
import { DiscoverPage, DiscoverPageStorage } from './DiscoverPage';

interface IMyPageProps {
    viewed?: ITourKey[]
}

export default class ScreenshotDiscoverPage implements IScreenshotablePage<IMyPageProps> {
    static inject = () => [DiscoverPageStorage];
    constructor(private storageV2: DiscoverPageStorage){}

    screenId = 'discover';

    permutationToProps = ({ viewed = [] }: IMyPageProps) => {
        const tourViewedMap = viewed.reduce((map, key) => ({...map, [key]: true}), {} as any);
        this.storageV2.views.set(tourViewedMap);
        return {};
    };

    ComponentUnderTest = DiscoverPage;

    permutations: { [key: string]: IMyPageProps } = {
        'none-viewed': {
            viewed: []
        },
        'some-viewed': {
            viewed: ['EXPLORE_AIRSPACE', 'ADD_MISSION', 'GET_LAANC_AUTH', 'MISSION_WEATHER']
        },
    };
}
