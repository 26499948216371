import { IonActionSheet } from '@ionic/react';
import { IActionMenuAction } from './ActionMenu';

// NOTE: disabled currently filters out, as opposed to showing in a disabled state
//  adding support for disabled shouldnt' be too hard, but 1-2 hours still
//   https://forum.ionicframework.com/t/is-it-possible-to-disable-buttons-of-actionsheetcontroller-can-anyone-tell-me-what-property-to-use-and-how-can-i-use-it/115299/3
// but also note that sometimes you truly want to not show (eg don't show restore mission)
//  until the mission is canceled, b/c showing it before is confusing
export const ActionSheet = ({ isOpen, onClose, actions }: { isOpen: boolean, onClose?: () => void, actions: IActionMenuAction[] }) => {
    return (
        <IonActionSheet
            isOpen={isOpen}
            onDidDismiss={onClose}
            buttons={actions
                .filter(a => !a.disabled)
                .concat({
                    text: 'Cancel',
                    role: 'cancel'
                })
            }
        />
    );
};
