import { WeatherConditionIcon } from './components/WeatherConditionIcon';
import { TemperatureGradient } from './components/TemperatureGradient';
import { CardHeader } from '../components/card/CardHeader';
import { DrawerLabel } from '../components/drawer/DrawerLabel';
import { PrecipitationIcon } from './components/PrecipitationIcon';
import { DayHeading } from './components/DayHeading';
import { CardContent } from '../components/card/CardContent';
import { Card } from '../components/card/Card';
import { IDailyForecast } from './model/IDailyForecast';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useEffect, useState } from 'react';
import { Spinner } from '../components/spinner/Spinner';
import { WeatherError } from './components/WeatherError';
import { Logger } from '../support/debug/Logger';
import { ICoordinate } from '@autopylot-internal/tiles-client';
import { CachingWeatherService } from './CachingWeatherService';

type ISO8601 = string;

interface IDailyForecastCardProps {
    link: { href: string; };

    // mission info
    startTime: ISO8601;
    location: ICoordinate;
    endTime: ISO8601;

    onForecastDayClick?: (day: ISO8601) => void;

    isDebouncing?: boolean;
}


export const DailyForecastCardV3: React.FC<IDailyForecastCardProps> = ({ link, startTime, location, endTime, onForecastDayClick, isDebouncing }) => {
    const cachingWeatherService = useInstance(CachingWeatherService);

    const [forecast, setForecast] = useState<IDailyForecast[]>();
    const [error, setError] = useState<any>();
    const [loading, setLoading] = useState(false);
    const logger = useInstance(Logger);

    useEffect(() => {
        setLoading(true);
        if (!isDebouncing) {
            cachingWeatherService.getDailyForecast({ link, startTime, endTime, location })
                .then(f => {
                    setForecast(f);
                    setError(undefined);
                })
                .catch(err => {
                    setForecast(undefined);
                    setError(err);
                    logger.error(err);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [cachingWeatherService, logger, link, startTime, endTime, location, isDebouncing]);

    const forecastState: string = error ? 'error': forecast ? 'loaded' : 'loading';

    const dataAttributes = {
        'data-type': 'weather-daily',
        'data-forecast': forecastState,
        'data-first-day': forecast ? forecast[0].forecastStart : undefined,
        'data-location': forecast ? JSON.stringify(forecast[0].location) : undefined,
        'data-status': forecast ? 'loaded' : 'loading'
    };

    const visibleForecast = forecast;

    return (
        <div {...dataAttributes} >
            <Card>

                <CardHeader>
                    <DrawerLabel text="10-Day Forecast" />
                </CardHeader>

                <CardContent>

                    <div style={{minHeight: 150, width: '100%', opacity: loading ? .5 : 1}}>

                        {error &&
                            <WeatherError error={error} />
                        }

                        {!visibleForecast && !error &&
                            <Spinner size="small" centered />
                        }

                        {visibleForecast && visibleForecast.map((day, idx) => (
                            <div key={day.forecastStart}
                                data-type="daily-forecast-day"
                                style={{display: 'flex', alignItems: 'center', width: '100%', margin: '10px 0', cursor: (onForecastDayClick ? 'pointer' : 'default')}}
                                onClick={() => {
                                    if (onForecastDayClick){
                                        // this does NOT have milliesconds but should not matter
                                        onForecastDayClick(day.forecastStart);
                                    }
                                }}
                            >

                                <div style={{width: '30%', display: 'flex', flexDirection: 'column', alignItems: 'start'}}>

                                    <DayHeading forecast={day} highlightDay={startTime} />

                                    <div style={{marginLeft: -3, display: 'flex', alignItems: 'center', gap: 2}}>
                                        <PrecipitationIcon precipitationType={day.precipitationType} size="small" color="tertiary" />
                                        <span style={{fontSize: 13, color: 'var(--ion-color-tertiary)'}}>{day.precipitationChancePct}%</span>
                                    </div>

                                </div>

                                <span style={{width: '20%'}}><WeatherConditionIcon conditionCode={day.conditionCode}  isDaylight={true} size="medium" /></span>

                                <div style={{width: '50%', display: 'flex', alignItems: 'center', gap: 10}}>
                                    <span style={{fontSize: 17}}>{day.temperatureMinF}</span>
                                    <TemperatureGradient min={day.temperatureMinF} max={day.temperatureMaxF} />
                                    <span style={{fontSize: 17, fontWeight: 'bold'}}>{day.temperatureMaxF}</span>
                                </div>

                            </div>
                        ))}

                    </div>

                </CardContent>

            </Card>
        </div>
    );
};
