import { IonButton } from '@ionic/react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { State, useSubscription } from '@meraki-internal/state';
import React, { CSSProperties, useRef } from 'react';
import { ISegmentButtonProps, SegmentedButtons } from '../../components/segmented-buttons/SegmentedButtons';

export class ExpandableSegmentedButtonsState extends State<{openButton: HTMLIonButtonElement | null}> {
    constructor() {
        super({ openButton: null });
    }
    isOpen = (button: HTMLIonButtonElement | null) => {
        return this.state.openButton && this.state.openButton === button;
    };
    setOpen = (button: HTMLIonButtonElement | null, isOpen: boolean) => {
        this.setState({ openButton: (isOpen ? button : null) });
    };
}

export const ExpandableSegmentedButtons: React.FC<ISegmentButtonProps &  {icon: JSX.Element, 'data-id': string }> = ({ options, activeValue, setActiveValue, icon, 'data-id': dataId }) => {
    const state = useInstance(ExpandableSegmentedButtonsState);
    useSubscription(() => state);

    const ref = useRef<HTMLIonButtonElement>(null);

    const wrapperStyle: CSSProperties = {
        position: 'absolute',
        bottom: 16,
        right: 65,

        // this is coupled to the content, which is currently the windy map
        // YAGNI - make it a property, but create a WindyMapControl that encapsulates a common value for all the windy controls
        // widened by 42px for android, still looks good on ios
        width: 242,

        opacity: state.isOpen(ref.current) ? 1 : 0,
        visibility: state.isOpen(ref.current) ? 'visible' : 'hidden',
        transition: 'visibility 0.3s linear,opacity 0.3s linear'
    };

    const onIconClicked = () => {
        state.setOpen(ref.current, !state.isOpen(ref.current));
    };

    const onActiveChanged = (value: string) => {
        setActiveValue(value);

        // allow time for segment to animate before hiding it
        setTimeout(() => state.setOpen(ref.current, false), 500);
    };

    return (
        <div style={{ position: 'relative'}}>
            <div style={wrapperStyle}>
                <SegmentedButtons options={options} activeValue={activeValue} setActiveValue={onActiveChanged} />
            </div>

            <IonButton ref={ref} color="light" data-id={dataId} style={{width: 60, height: 60, '--border-radius': '50%'}} onClick={onIconClicked}>
                <span slot="icon-only">
                    {icon}
                </span>
            </IonButton>
        </div>
    );
};
