import moment from 'moment';
import semver from 'compare-versions';
import { IAppRelease, TileUrlProvider } from '@autopylot-internal/tiles-client';
import { IAlertOptions } from '../AlertBinder';
import { AppInfo } from '../../support/AppInfo';
import { Device } from '../../support/Device';
import { Logger } from '../../support/debug/Logger';
import { StoreConfigs } from '../config/StoreConfigs';

export type IAppVersionStatus = 'active' | 'expiring' | 'expired';

interface IAppVersionUpdateAlerter {
    showAlert: (options: IAlertOptions) => void;
}

// this class enables our unit tests to not require importing ionic / jsx
export class AppVersionUpdateManager_AlertPlaceholder implements IAppVersionUpdateAlerter {
    constructor(){
        throw new Error('should have been swapped out in IOC');
    }
    showAlert: (options: IAlertOptions) => void;
}

export class AppVersionUpdateManagerConfig {
    blockOnExpiredWaitMS = 1000;
}

export class AppVersionUpdateManager {
    static inject = () => [
        TileUrlProvider,
        AppInfo,
        Device,
        AppVersionUpdateManager_AlertPlaceholder,
        Logger,
        AppVersionUpdateManagerConfig,
    ];
    constructor(
        private tileUrlProvider: TileUrlProvider,
        private appInfo: AppInfo | null,
        private deviceInfo: Device,
        private alert: IAppVersionUpdateAlerter,
        private logger: Logger,
        private config: AppVersionUpdateManagerConfig,
    ){}

    private buildTimeout = () => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                const error: any = new Error(`Waited ${this.config.blockOnExpiredWaitMS}. Failing gracefully by assuming not expired`);
                error.errorCode = 'timed-out';
                reject(error);
            },  this.config.blockOnExpiredWaitMS);
        });
    };

    blockOnExpired = async () => {
        try {
            await Promise.race([
                this.checkForUpdate(),
                this.buildTimeout()
            ]);

            if (this.appVersionStatus === 'expired'){
                return new Promise(resolve => {
                    // never
                });
            }
        }
        catch (err: any){
            if (err.errorCode === 'timed-out'){
                this.logger.info(err);
            }
            else {
                this.logger.error(err);
            }
        }
    };

    /**
     * If the user needs to update, this will display an alert to the user
     * even if it already did before.
     */
    maybeAlertMustUpdate = () => {
        if (this.appVersionStatus === 'expired'){
            this.alertMustUpdateApp();
        }
    };

    /**
     * If the user should update, this will display an alert to the user
     * even if it already did before.
     */
    maybeAlertShouldUpdate = () => {
        if (this.appVersionStatus === 'expiring'){
            this.alertShouldUpdateApp();
        }
    };

    maybeAlert = () => {
        this.maybeAlertMustUpdate();
        this.maybeAlertShouldUpdate();
    };

    private appVersionStatus: IAppVersionStatus = 'active';

    checkForUpdate = async () => {
        if (this.deviceInfo.platform === 'web'){
            // can't update on web
            return;
        }
        try {
            // note there is a cache so this likely isn't waiting long
            const { appReleases } = await this.tileUrlProvider.getFAAStatus();
            const appVersionStatus = this.getAppVersionStatus(appReleases);
            if (this.appVersionStatus !== appVersionStatus) {
                this._showAlert(appVersionStatus);
                this.appVersionStatus = appVersionStatus;
            }
        }
        catch (err: any){
            this.logger.error(`Failed to checkForUpdate. Failing gracefully by assuming no change. Inner Error: ${err.toString()}`);
        }
    };

    // back door for screenshots
    _showAlert = async (appVersionStatus: string) => {
        if (appVersionStatus === 'expired') {
            this.alertMustUpdateApp();
        }
        else if (appVersionStatus === 'expiring') {
            this.alertShouldUpdateApp();
        }
    };

    // active = no alert
    // expiring = cancelable update alert
    // expired = forced update alert
    getAppVersionStatus = (appReleases: IAppRelease[]): IAppVersionStatus => {
        if (!this.appInfo){
            return 'active'; // always active on web
        }

        const release = appReleases.find(r => r.version === this.appInfo!.version);

        let expires: string | undefined = undefined;
        if (release) {
            expires = release.expires;
        } else {
            // if there are expiring versions, and app version is older than all of them, consider it expired
            const expiringVersions = appReleases.map(r => r.version).filter(v => semver.validate(v));
            if (expiringVersions.length && expiringVersions.every(version => semver.compare(this.appInfo!.version, version, '<'))) {
                expires = '2000-01-01';
            }
        }

        return !expires ? 'active' : moment().isAfter(expires) ? 'expired' : 'expiring';
    };

    private alertMustUpdateApp = () => {
        if (!this.alert){
            throw new Error('tried to alert without this.alert set');
        }
        this.alert.showAlert({
            header: 'Please Update Your AutoPylot App',
            message: `To continue using the latest features and enhancements,
                you need to update to the newest version of the AutoPylot app.
            `,
            buttons: [
                {
                    text: 'Update',
                    handler: () => {
                        window.location.href = this.getStoreURL();
                        return false; // so alert is not dimissed
                    }
                }
            ]
        });
    };

    private alertShouldUpdateApp = () => {
        if (!this.alert){
            throw new Error('tried to alert without this.alert set');
        }
        this.alert.showAlert({
            header: 'Please Update Your AutoPylot App',
            message: `To continue using the latest features and enhancements,
                you'll need to update to the newest version of the AutoPylot app.
            `,
            buttons: [
                'Later',
                {
                    text: 'Update',
                    handler: () => {
                        window.location.href = this.getStoreURL();
                        return false; // so alert is not dimissed
                    }
                }
            ]
        });
    };

    private getStoreURL = () => {
        if (!this.deviceInfo){
            throw new Error('not supported');
        }
        const { platform } = this.deviceInfo;
        if (platform === 'ios') {
            return StoreConfigs.ios.installURL;
        }
        if (platform === 'android') {
            return StoreConfigs.android.installURL;
        }
        throw new Error(`platform "${platform}" does not have a store url!`);
    };
}
