import debounce from 'lodash/debounce';
import mixpanel from 'mixpanel-browser';
import { App, AppState } from '@capacitor/app';
import { EnvConfiguration } from '../../app/config/EnvConfiguration';
import { HistoryViewModel } from '../../app/HistoryViewModel';
import { Logger } from '../debug/Logger';
import { Device } from '../Device';
import { DisplayMetricsProvider } from '../DisplayMetricsProvider';
import { AppInfo } from '../AppInfo';
import { TrackingStorageProvider } from './TrackingStorageProvider';

export interface ITrackingUser {
    userId: string;
    email?: string;
    name?: string;
    isAdmin?: boolean;
    hasLicense?: boolean;
    hasTrustCert?: boolean;
}

export type ITrackingContext = {[key: string]: any};

type ITrackingDevice = {
    // AppInfo properties (not available on web)
    appVersion: string,
    appSha: string,

    platform: Device['platform'];
    osVersion: string;
    manufacturer: string;
    model: string;

    // properties from IDisplayMetrics
    // the primary thing we want is screenWidth and screenHeight, so we know we're screenshotting / testing with screen sizes our users actually use
    // everything is is more for exploration, where we might find we have made bad assumptions
    screenWidth: number;
    screenHeight: number;
    viewportWidth: number;
    viewportHeight: number;
    devicePixelRatio: number;
    statusBarHeight: number;
    navigationBarHeight: number;
};

// 'Page Viewed' intentionally omitted from this interface,
// must call startPageView(), not track('Page View')
export type ITrackingEvent =
    'Unsupported Browser' |
    'Nav Menu Opened' |
    'First App Opened' |
    'App Opened' |
    'User Signed In' |
    'Signed Out' |
    'Terms Accepted' |
    'Pilot Info Entered' |
    'Pilot Info Do Later Tapped' |
    'Licensed Prompt Answered' |
    'Address Searched' |
    'Address Selected' |
    'New Mission Button Tapped' |
    'Date/Time Set' |
    'Duration Set' |
    'Radius Set' |
    'Center Set' |
    'Altitude Set' |
    'Mission Shape Changed' |
    'Flight Checklist Expanded' |
    'Flight Checklist Scrolled' |
    'Mission Created' |
    'Authorization Viewed' |
    'Mission Renamed' |
    'Mission Notes Edited' |
    'Mission Notes Added' |
    'Mission Canceled' |
    'Mission Deleted' |
    'Mission Date Tapped' |
    'Mission Directions Requested' |
    'Profile Edited' |
    'Missions Section Opened' |
    'Map Panned' |
    'Map Zoomed' |
    'Pin Dropped' |
    'Profile | Delete' |
    'Welcome | Sign In' |
    'Terms | Sign In' |
    'Terms | Sign Out' |
    'Nav | Create Account or Sign In' |
    'Auth Options Enforced' |
    'Auth Options Dismissed' |
    'Auth Options Continued' |
    'Started Intercom Onboarding' |
    'Feedback Requested' |
    'Invite Shared' |
    'Password Added' |
    'Calendar Connected' |
    'Opened Map Layers' |
    'Toggled All Airspace Layers' |
    'Base Map Selected' |
    'Push Notification Opened' |
    'Checklist Created' |
    'Checklist Item Added' |
    'Checklist Item Edited' |
    'Checklist Item Reordered' |
    'Checklist Item Deleted' |
    'Checklist All Items Deleted' |
    'Checklist Item Toggled' |
    'Checklist Saved from Mission' |
    'Checklist Added to Mission' |
    'Checklist Renamed' |
    'Checklist Duplicated' |
    'Checklist Deleted' |
    'Feature Tour Viewed' |
    'Undo Invoked' |
    'Calendar Notify Me' |
    'Insurance Notify Me Selected' |
    'Insurance Questions Answered' |
    'Insurance Marketing State Selected' |
    'Insurance Unsupported State Notify Me Selected' |
    'Insurance | Create Account' |
    'Insurance iFrame Closed' |
    'Insurance iFrame Exit Alert Continued' |
    'Insurance Nudge Opened' |
    'Stere Application Started' |
    'Stere Application In Progress' |
    'Stere Application Completed' |
    'Stere Quotes Missing' |
    'Stere Quotes Generated' |
    'Stere Quotes Selected' |
    'Stere Checkout Declined' |
    'Stere Checkout Completed' |
    'Account Nudge Prompted' |
    'Account Nudge Continued' |
    'Opened Mission Planning Weather' |
    'Mission Planning Weather Map Opened' |
    'Mission Planning Weather Chart Opened' |
    'Weather Chart Closed' |
    'Experiments Loaded' |
    'Paywall Opened' |
    'Paywall Continued' |
    'Announcement Opened' |
    'Announcement Continued' |
    'Announcement Closed'
;

export type ITrackingPage =
    'Welcome' |
    'Terms' |
    'Setup Profile' |
    'Map' |
    'Mission Area and Time' |
    'Mission Altitude' |
    'Mission Operator' |
    'Review Mission' |
    'Mission Details' |
    'Mission Map' |
    'Mission Weather' |
    'Weather Map' |
    'Missions' |
    'Mission Checklist' |
    'Mission Notes' |
    'Mission LAANC Authorization Details' |
    'Profile' |
    'Mission Hourly Weather Chart' |
    'Insurance Coming Soon' |
    'Insurance Unsupported State' |
    'Insurance Marketing' |
    'Insurance Dashboard' |
    'Insurance iFrame' |
    'Insurance Details' |
    'Settings' |
    'Invite Friends' |
    'Connect Calendar' |
    'Discover AutoPylot' |
    'Checklist Templates' |
    'Saved Checklist' |
    'Select Checklist Templates'
;

export class TrackingService {

    static inject = () => [
        EnvConfiguration,
        Device,
        HistoryViewModel,
        Logger,
        DisplayMetricsProvider,
        AppInfo,
        TrackingStorageProvider
    ];
    constructor(
        private config: EnvConfiguration,
        private device: Device,
        private history: HistoryViewModel,
        private log: Logger,
        private displayMetricsProvider: DisplayMetricsProvider,
        private appInfo: AppInfo,
        private storage: TrackingStorageProvider
    ) {}

    private initialized: boolean = false;

    private currentPage?: ITrackingContext;

    private preInitEventsToFlush: { event: ITrackingEvent, context?: ITrackingContext }[] = [];

    private getDeviceInfo = (): ITrackingDevice => {
        const displayMetrics = this.displayMetricsProvider.getMetrics();

        return {
            appVersion: this.appInfo?.version || '',
            appSha: process.env.REACT_APP_SHA || '',
            platform: this.device.platform,
            osVersion: this.device.osVersion,
            manufacturer: this.device.manufacturer,
            model: this.device.model,
            screenWidth: displayMetrics.screen.width,
            screenHeight: displayMetrics.screen.height,
            viewportHeight: displayMetrics.viewport.height,
            viewportWidth: displayMetrics.viewport.width,
            devicePixelRatio: displayMetrics.devicePixelRatio,
            statusBarHeight: displayMetrics.statusBarHeight,
            navigationBarHeight: displayMetrics.navigationBarHeight
        };
    };

    init = ({ userId, email, name }: { userId: string, email: string, name: string }) => {
        this.log.info('TrackingService.init', { userId, email, name });

        try {
            // It's useful to see tracking locally. So when mixpanel is disabled, we still set up the
            // listeners below, but leave initialized=false so that track() just logs to the console.
            this.setupListeners();

            if (!this.config.MIXPANEL_TOKEN) {
                this.log.info(`Disabling tracker for "${this.config.ENV}" env b/c no token`);
                return;
            }

            if (this.storage.optOutOfTracking.get()){
                this.log.info('Disabling tracker b/c global opt out');
                return;
            }

            this.log.info(`Initializing tracker for "${this.config.ENV}" env`);
            this.log.info('now using local storage');
            mixpanel.init(this.config.MIXPANEL_TOKEN, { debug: false, persistence: 'localStorage' });
            this.log.info('cookie_domain', mixpanel.get_config('cookie_domain'));

            this.log.info('init -- mixpanel.get_distinct_id()', mixpanel.get_distinct_id());
            this.initialized = true;

            mixpanel.identify(userId);

            // update deviceInfo in MixPanel for a User
            this.updateUser({ name, email });

            this.flushPreInitEvents();

            // TODO: we aren't getting this for local dev, but I don't want to risk moving it to the top (before early returns)
            // while there is so much FUD tied to metrics for real users
            this.trackAppOpened();

        } catch (e: any) {
            this.log.error(new Error(`Failed to initialize tracking: ${e.message}`));
        }
    };

    private flushPreInitEvents = () => {
        for (const { event, context } of this.preInitEventsToFlush){
            this._track(event, context);
        }

        this.preInitEventsToFlush = [];
    };

    private trackAppOpened = () => {
        let has_AP_APP_OPENED_TRACKED = Boolean(window.localStorage.getItem('AP_APP_OPENED_TRACKED'));
        const hasAccessToken = Boolean(window.localStorage.getItem('ap:auth'));

        // for backwards compatibility, if they have auth, then we missed First App Opened
        // don't send it now
        if (hasAccessToken && !has_AP_APP_OPENED_TRACKED){
            has_AP_APP_OPENED_TRACKED = true;
            window.localStorage.setItem('AP_APP_OPENED_TRACKED', 'true');
        }

        if (!has_AP_APP_OPENED_TRACKED){
            this._track('First App Opened');
            window.localStorage.setItem('AP_APP_OPENED_TRACKED', 'true');
        }

        // intentionally tracking First App Opened and App Opened so queries don't have to look for both
        this._track('App Opened', { restarted: true });
    };

    private setupListeners = () => {
        // Not all pages correspond to navigation routes, so "Page Viewed" events are not fired
        // automatically by this history listener, but sent explicitly by each page as needed.
        // But since not all pages are tracked, this listener is used to terminate the current
        // page view whenever navigation is detected, e.g. if you are on missions list, then go
        // to the debug page (which is not tracked), it will end the mission list page view.
        this.history.getRawHistory().listen(async () => {
            this.endPageView();
        });

        App.addListener('appStateChange', ({ isActive }: AppState) => {
            if (!isActive) {
                // app is being sent to background, so send final page view
                this.endPageView();

            } else {
                // app is being restored to foreground
                this._track('App Opened', { restarted: false });

                // we'll be viewing the same page as before, but it won't remount
                // so refresh the timestamp to start another page view for the same page
                if (this.currentPage) {
                    this.currentPage.startTimestamp = Date.now();
                }
            }
        });
    };

    updateUser = (profile: Partial<ITrackingUser>) => {
        const deviceInfo = this.getDeviceInfo();
        this.log.info('TrackingService.updateUser', { profile, ...deviceInfo, initialized: this.initialized });
        try{
            if (this.initialized){
                const { hasLicense, hasTrustCert, name, email } = profile;
                const meta: any = { hasLicense, hasTrustCert, name, '$email': email, ...deviceInfo };
                const lastDeviceInfo = { ...deviceInfo, browserName: this.device.browserName || '', browserVersion: this.device.browserVersion || '', updatedAt: new Date().toISOString() };
                if (deviceInfo.platform === 'ios') {
                    meta.lastIOS = lastDeviceInfo;
                } else if (deviceInfo.platform === 'android') {
                    meta.lastAndroid = lastDeviceInfo;
                } else if (deviceInfo.platform === 'web') {
                    meta.lastWeb = lastDeviceInfo;
                }
                mixpanel.people.set(meta);
            }
        } catch (err: any){
            this.log.error(new Error(`Failed to updateUser: ${err.message}`));
        }
    };

    reset = () => {
        try {
            if (this.initialized) {
                this.endPageView();
                mixpanel.reset();
            }

        } catch (e: any) {
            this.log.error(new Error(`Failed to reset tracker: ${e.message}`));
        }
    };

    track = (event: ITrackingEvent, getContext?: () => ITrackingContext) => {
        try {
            const context = getContext ? getContext() : undefined;
            this._track(event, context);

        } catch (e: any) {
            this.log.error(new Error(`Failed to track event "${event}": ${e.message}`));
        }
    };

    trackWithDebounce = (event: ITrackingEvent, getContext?: () => ITrackingContext) => {
        try {
            const context = getContext ? getContext() : undefined;
            this._trackWithDebounce(event, context);

        } catch (e: any) {
            this.log.error(new Error(`Failed to track event with debounce "${event}": ${e.message}`));
        }
    };

    // Must be invoked explicitly to indicate that a page view is starting.
    // (A "page" does not need to correspond one-to-one with a url hash.)
    startPageView = (pageName: ITrackingPage, getContext?: () => ITrackingContext) => {
        try {
            this.endPageView(); // send event for previous page

            const location = window.location.hash;
            const queryParams = this.history.getCurrentSearchParamMap();
            const startTimestamp = Date.now();

            const context = getContext ? getContext() : undefined;
            this.currentPage = { ...queryParams, pageName, location, startTimestamp, ...context };

            this._track(`${pageName} Opened`,  { location, ...queryParams, ...context });

        } catch (e: any) {
            this.log.error(new Error(`Failed in startPageView for page "${pageName}": ${e.message}`));
        }
    };

    // Sends a track event (including calculated timeOnPage) at the end of a page view.
    // Invoked when startPageView() is called next, and when a navigation is detected.
    // May be invoked explicitly if not covered by above scenarios.
    // Requires that startPageView() has been previously invoked.
    endPageView = () => {
        try {
            if (this.currentPage?.startTimestamp) {
                const { startTimestamp, ...rest } = this.currentPage;
                const timeOnPage = (Date.now() - startTimestamp) / 1000;
                this._track('Page Viewed', { ...rest, timeOnPage });
                this.currentPage.startTimestamp = undefined;
            }
        } catch (e: any) {
            this.log.error(new Error(`Failed in endPageView for page "${this.currentPage?.pageName}": ${e.message}`));
        }
    };

    private _track = (event: string, context?: ITrackingContext) => {
        this.log.info(`Track "${event}"`, context);
        if (this.initialized) {
            mixpanel.track(event, { ...context, ...this.getDeviceInfo() });
        } else {
            // note that it is possible for an event to never get flushed
            // eg we redirect to auth0 before calling init
            this.preInitEventsToFlush.push({
                event: event as ITrackingEvent,
                context
            });
        }
    };

    // TODO: this should only debounce calls for same event
    private _trackWithDebounce = debounce(this._track, 500);

}
