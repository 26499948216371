import { Feature, Polygon } from '@turf/turf';
import { PinchZoomWithoutPanHandler } from '../PinchZoomWithoutPanHandler';
import { MapViewModel } from '../model/MapViewModel';
import { IRadiusProperties, MissionPinAndRadiusOnMapViewModel } from './MissionPinAndRadiusOnMapViewModel';
import mapboxgl from 'mapbox-gl';
import * as turf from '@turf/turf';
import { AlertPresenter } from '../../app/AlertBinder';

const NEW_MISSION_ZOOM = 15;

export class MissionPinAndRadiusOnMapView {
    static inject = () => [
        MissionPinAndRadiusOnMapViewModel,
        AlertPresenter
    ];
    constructor(
        private vm: MissionPinAndRadiusOnMapViewModel,
        private alert: AlertPresenter
    ){}

    private map!: mapboxgl.Map & { pinchZoomWithoutPan?: PinchZoomWithoutPanHandler };
    private mapVM!: MapViewModel;

    private missionPin?: mapboxgl.Marker;
    private isPanMovingRadius = false;

    private showPin = (radius: Feature<Polygon, IRadiusProperties>) => {
        if (!this.missionPin || (this.missionPin as any)._color !== radius.properties.color){
            if (this.missionPin){
                this.missionPin.remove();
            }

            this.missionPin = new mapboxgl.Marker({ color: radius.properties.color })
                .setLngLat(radius.properties.center).addTo(this.map);
        }
        else {
            this.missionPin.setLngLat(radius.properties.center);
        }
    };

    private hideRadius = () => {
        // NOTE: ideally we'd just remove the json from the source, but that has visual lag
        this.map.setLayoutProperty('layer:radius-fill', 'visibility', 'none');
        this.map.setLayoutProperty('layer:radius-outline', 'visibility', 'none');
    };

    private showRadius = (radius: Feature<Polygon, IRadiusProperties>) => {
        // NOTE: we are currently not debouncing this at all, we are likely updating this
        //  in some cases, more frequently than mapbox can render it
        // 1. mapbox may already handle this on their own, and so we should ensure we're solving a problem first
        // 2. if mapbox is, it might be their implementation that causes it to feel janky when showing the radius while panning

        // once it is authorized show that (which could have minor cropping)
        const radiusToShow = radius.properties.authorizedRadius ? radius.properties.authorizedRadius.getMissionRadius() : radius;
        (this.map.getSource('source:radius') as mapboxgl.GeoJSONSource).setData({
            ...radiusToShow,
            properties: radius.properties
        });

        // NOTE: assuming mapbox is no-oping when these don't change (but if we see jank, we should validate that assumption)
        this.map.setLayoutProperty('layer:radius-fill', 'visibility', 'visible');
        this.map.setLayoutProperty('layer:radius-outline', 'visibility', 'visible');
    };

    private startMovingRadiusByPanning = () => {
        this.isPanMovingRadius = true;
        const radius = this.vm.getRadius();
        if (!radius){
            throw new Error('cannot tie panning to the radius if we do not have one');
        }

        // switch to a custom pinch, that doesn't pan
        //  b/c we don't want the center to move while zooming in and out
        //  when the user might not mean to be moving the center
        this.mapVM.allowPanWhilePinchZooming(false);

        this.map.on('moveend', this.onMoveEndAuthorizeRadius);
        this.map.on('move', this.onMoveChangeMarkerCenter);
    };

    private stopMovingRadiusByPanning = () => {
        this.isPanMovingRadius = false;

        this.map.off('moveend', this.onMoveEndAuthorizeRadius);
        this.map.off('move', this.onMoveChangeMarkerCenter);

        this.mapVM.allowPanWhilePinchZooming(true);
    };

    private onMoveEndAuthorizeRadius = async () => {
        try {
            await this.vm.authorizeRadius();
        } catch (err){
            this.alert.showAndLogError(err);
        }
    };

    private paintRadiusToMapbox = () => {
        const radius = this.vm.getRadius();
        if (radius){
            this.showPinAndMaybeRadius(radius);
        }
        else {
            this.removePinAndRadius();
        }
    };

    private maybePanAndZoom = async ({ lastRadius }: { lastRadius?: Feature<Polygon, IRadiusProperties>; }) => {
        const radius = this.vm.getRadius();
        const isNewRadius = Boolean(radius && !lastRadius); // as opposed to a "move" or other "changes" to a radius

        const enablingPanningMovesRadius = this.vm.getDoesPanMoveRadius() && !this.isPanMovingRadius;

        if (isNewRadius && this.vm.getDoesPanMoveRadius()){
            // we need the center of the map to match the radius (eg maybe they were on page 2 of the wizard, and just clicked back)
            await this.mapVM.flyTo({ center: radius!.properties.center, zoom: NEW_MISSION_ZOOM });
        }
        // if we just added a radius then zoom to it
        // but not if we panning moves the map, in which case that logic owns moving the map
        // and we need to leave it alone
        else if (isNewRadius && !this.vm.getDoesPanMoveRadius()){
            Promise.resolve().then(() => this.mapVM.flyTo({
                center: radius!.properties.center,
                zoom: this.getZoomForRadius({ padding: 20 })
            }));
        }
        else if (!isNewRadius && radius && enablingPanningMovesRadius){
            await this.mapVM.flyTo({
                center: radius.properties.center,
                zoom: this.map.getZoom()
            });
        }
        else if (radius && this.getRadiusChange({ from: lastRadius?.properties.radiusMeters, to: radius.properties.radiusMeters })){
            this.maybeZoomOutForRadiusChange();
        }
    };

    private synchronizeMapPanToMissionPin = () => {
        const changed = this.vm.getDoesPanMoveRadius() !== this.isPanMovingRadius;
        if (changed && this.vm.getDoesPanMoveRadius()){
            this.startMovingRadiusByPanning();
        }
        else if (changed && !this.vm.getDoesPanMoveRadius()){
            this.stopMovingRadiusByPanning();
        }
    };

    init = (map: mapboxgl.Map, mapVM: MapViewModel) => {
        this.map = map;
        this.mapVM = mapVM;
        let lastRadius: Feature<Polygon, IRadiusProperties> | undefined;

        this.vm.subscribe(async () => {
            const radius = this.vm.getRadius();

            this.paintRadiusToMapbox();

            // async code allows multiple parallel calls into this subscriber before lastRadius is updated,
            // which breaks "view mission on map" pannning if we don't make it conditional on radius here
            if (radius) {
                await this.maybePanAndZoom({ lastRadius });
            }

            this.synchronizeMapPanToMissionPin();

            lastRadius = radius;
        });
    };

    private removePinAndRadius = () => {
        (this.map.getSource('source:radius') as mapboxgl.GeoJSONSource).setData({ type: 'FeatureCollection', features: []});
        if (this.missionPin){
            this.missionPin.remove();
            this.missionPin = undefined;
        }
    };

    private showPinAndMaybeRadius = (radius: Feature<Polygon, IRadiusProperties>) => {
        this.showPin(radius);

        if (this.vm.showPendingRadius || radius.properties.authorizedRadius){
            this.showRadius(radius);
        } else {
            this.hideRadius();
        }
    };

    private getRadiusChange = ({ from, to }: { from?: number; to?: number; }): number => {
        const fromMeters = (from || 0);
        const toMeters = (to || 0);

        return toMeters - fromMeters;
    };

    private maybeZoomOutForRadiusChange = () => {
        const radius = this.vm.getRadius();
        const [xmin, ymin, xmax, ymax] = turf.bbox(radius);

        const camera = this.map.cameraForBounds([[xmin, ymin], [xmax, ymax]], { padding: 20 })!;
        const zoom = Math.min(camera.zoom, this.map.getZoom()); // don't zoom in, only out

        // only update if we need to, to avoid flicker
        if (this.map.getZoom() !== zoom) {
            this.map.easeTo({ zoom });
        }
    };

    private onMoveChangeMarkerCenter = () => {
        this.vm.move(this.map.getCenter());
    };

    private getZoomForRadius = ({ padding = 0 }: { padding?: number } = {}) => {
        const radius = this.vm.getRadius();
        if (!radius){
            throw new Error('getZoomForRadius was called without a radius');
        }
        const [xmin, ymin, xmax, ymax] = turf.bbox(radius);

        const camera = this.map.cameraForBounds([[xmin, ymin], [xmax, ymax]], { padding })!;

        return camera.zoom;
    };
}
