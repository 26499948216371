import { useInstance } from '@meraki-internal/react-dependency-injection';
import { RoundIconButton } from '../../components/button/RoundIconButton';
import { LayersIcon } from '../../components/icons/Icons';
import { VisibleMapLayersState } from './VisibleMapLayersState';

export const ToggleLayersControlsButton: React.FC = () => {
    const vm = useInstance(VisibleMapLayersState);

    return (
        <RoundIconButton Icon={LayersIcon} data-id="layers-toggle" onClick={() => vm.showControls()} />
    );
};
