import { MapMouseEvent } from 'mapbox-gl';
import { IMapEvent } from './IMapEvent';

// maximum delay to allow between double-clicks
const DOUBLE_CLICK_DELAY_MS = 250;

// Creates a click handler that distinguishes between single and double clicks
// and invokes a different callback in each case
export class MapClickManager {

    private rapidClickCount = 0;
    private clickTime: number | undefined;
    private clickTimeout: NodeJS.Timeout | undefined;

    createHandler = ({ onSingleClick, onDoubleClick }: { onSingleClick: (e: IMapEvent) => void, onDoubleClick: (e: IMapEvent) => void }) => {

        return (e: MapMouseEvent) => {
            const prevClickTime = this.clickTime;
            this.clickTime = Date.now();

            // if there was a previous click just before this, then it's not a single click, so we
            // abort any pending single-click, and fire a double-click event every two rapid clicks
            if (prevClickTime && (this.clickTime - prevClickTime < DOUBLE_CLICK_DELAY_MS)) {
                clearTimeout(this.clickTimeout);
                this.rapidClickCount++;
                if (this.rapidClickCount % 2 === 0) {
                    onDoubleClick({ eventType: 'doubleTap', lngLat: e.lngLat });
                }
            }

            // otherwise, it is a single click, but we delay firing the event to enable the code
            // above to abort it if it detects that it's part of a double-click
            else {
                this.rapidClickCount = 1;
                this.clickTimeout = setTimeout(() => {
                    onSingleClick({ eventType: 'tap', lngLat: e.lngLat });
                }, DOUBLE_CLICK_DELAY_MS);
            }
        };

    };
}
