import { APIClient } from '@autopylot-internal/autopylot-api-client';
import { State } from '@meraki-internal/state';
import { IntercomService } from '../app/intercom/IntercomService';
import { AuthService } from '../auth/AuthService';
import { AutoPylotPrincipal } from '../auth/AutoPylotPrincipal';
import { SmartlookService } from '../support/debug/SmartlookService';
import { TrackingService } from '../support/tracking/TrackingService';
import { IOperator, IOperatorProfile } from './IOperator';
import { Logger } from '../support/debug/Logger';
import { GlobalCachingConfig } from '../GlobalCachingConfig';
import { StorageProvider } from '../support/StorageProvider';

export class OperatorStateCache {
    static inject = () => [GlobalCachingConfig, StorageProvider];
    constructor(private globalConfig: GlobalCachingConfig, private storage: StorageProvider) { }

    private cache = this.storage.getAsyncJSONProvider<IOperator>('OperatorState.v1', { storageType: 'localStorage' });

    get = () => {
        if (this.globalConfig.disabled){
            throw new Error('cache disabled');
        }
        return this.cache.get();
    };

    set = (value: IOperator) => {
        if (this.globalConfig.disabled){
            return;
        }
        this.cache.set(value);
    };
}

export class OperatorStateStorage {
    static inject = () => [StorageProvider];
    constructor(private storage: StorageProvider){}
    setupOperatorSkipped = this.storage.getBooleanProvider('setup-operator-skipped', { storageType: 'localStorage' });
}

/**
 * OperatorState is loaded in AppContainer before the app starts, and therefore will always be loaded.
 */
export class OperatorState extends State<{ setupOperatorSkipped: boolean } & IOperator> {

    static inject = () => [
        APIClient,
        TrackingService,
        IntercomService,
        AuthService,
        AutoPylotPrincipal,
        SmartlookService,
        OperatorStateCache,
        Logger,
        OperatorStateStorage
    ];

    constructor(
        private apiClient: APIClient,
        private trackingService: TrackingService,
        private intercom: IntercomService,
        private authService: AuthService,
        private principal: AutoPylotPrincipal,
        private smartlookService: SmartlookService,
        private cache: OperatorStateCache,
        private logger: Logger,
        private storageV2: OperatorStateStorage
    ) {
        super({
            setupOperatorSkipped: storageV2.setupOperatorSkipped.exists(),

            firstName: undefined,
            lastName: undefined,
            phone: undefined,
            certificationType: undefined,
            certificationNumber: undefined,
            certificationDate: undefined,
            agreedToTermsOfService: false,
            agreedToFAAPrivacyStatement: false,
            agreedToLAANCStatement: false,
            updatedTime: undefined,
            links: {}
        });
    }

    private loadFromRemote = async () => {
        const entry = await this.apiClient.entry();
        const operator = await this.apiClient.get(entry.links.operator);

        if (operator) {
            this.cache.set(operator);

            this.setState({
                ...operator
            });
        }
    };

    load = async () => {
        try {
            const value = (await this.cache.get())!;
            this.setState({
                ...value,

                // when a user transitions from guest, we don't want them to see a link to sign in
                // especially if their internet is slow (so we'll override email in the cache if it appears outdated)
                // (guest principal.email is empty string, so coalesce this to undefined)
                email: value.email || this.principal.email || undefined
            });
            Promise.resolve()
                .then(async () => {
                    await this.loadFromRemote();
                })
                .catch((err: any) => {
                    const error: any = new Error(`OperatorState.load() failed, but used cache. Inner ${err.toString()}`);
                    error.inner = error;
                    error.errorCode = error.inner.errorCode;

                    this.logger.error(error);
                });
        }
        catch (err: any){
            console.log('Operator state, cache miss', err);
            await this.loadFromRemote();
        }
    };

    getName = (): string => {
        if (this.state.firstName && this.state.lastName){
            return `${this.state.firstName} ${this.state.lastName}`;
        }
        return '';
    };

    /**
     * returns the email from the profile if there is one, otherwise from the principal.
     *
     * A user might not have an email on either, because they haven't gotten to that profile setup yet.
     */
    getEmail = (): string => {
        return this.state.email || this.principal.email || '';
    };

    save = async (updated: IOperator | IOperatorProfile) => {
        const entry = await this.apiClient.entry();

        const newState = { ...this.state, ...updated };

        const { setupOperatorSkipped, ...operator }  = newState;
        await this.apiClient.put(entry.links.operator, operator);
        this.setState(updated);

        this.cache.set(newState);

        this.updateTrackers();
    };

    updateTrackers = async () => {
        const updates = {
            userId: this.principal.userId,
            email: this.getEmail(),
            name: this.getName(),
            hasLicense: this.hasLicense(),
            hasTrustCert: this.hasTrustCert()
        };
        await Promise.all([
            this.trackingService.updateUser(updates),
            this.intercom.updateUser(updates),
            this.smartlookService.updateUser(updates)
        ]);
    };

    setIsLicensed = async (isLicensed: boolean) => {
        this.trackingService.track('Licensed Prompt Answered', () => ({ isLicensed }));

        await this.save({
            certificationType: isLicensed ? 'part-107' : 'recreational'
        });
    };

    deleteAccount = async () => {
        this.trackingService.track('Profile | Delete');

        const entry = await this.apiClient.entry();
        await this.apiClient.post(entry.links.deleteAccount, undefined);

        await this.authService.logout();
    };

    isGuest = () => {
        return !Boolean(this.getEmail());
    };

    hasLicense = (): boolean | undefined => {
        if (!this.state.certificationType) {
            return undefined; // don't know yet
        }
        return (this.state.certificationType === 'part-107');
    };

    hasTrustCert = (): boolean | undefined => {
        if (!this.state.certificationType) {
            return undefined; // don't know yet
        }
        return (this.state.certificationType === 'recreational' && Boolean(this.state.certificationNumber));
    };

    hasFullContactInfo = () => {
        const { email, firstName, lastName, phone } = this.state;
        return (email && firstName && lastName && phone);
    };

    needsSetup = () => {
        return this.needsConsent() || this.needsSetupOperator();
    };

    needsConsent = () => {
        const { agreedToTermsOfService, agreedToFAAPrivacyStatement, agreedToLAANCStatement } = this.state;
        return !agreedToTermsOfService || !agreedToFAAPrivacyStatement || !agreedToLAANCStatement;
    };

    skipSetupOperator = () => {
        this.storageV2.setupOperatorSkipped.set(true);
        this.setState({ setupOperatorSkipped: true });
    };

    needsSetupOperator = () => {
        if (this.state.setupOperatorSkipped) {
            return false;
        }
        if (this.isGuest()) {
            return false;
        }
        const { firstName, lastName, phone } = this.state;
        return (!firstName || !lastName || !phone);
    };

    waitForLoggedIn = async ({ from, returnTo }: { from: string, returnTo?: string }) => {
        const isGuest = this.isGuest();
        if (isGuest) {
            // await, return false only if user closes dialog
            await this.enforceAuthOptions({ from, returnTo });
        }
        return !isGuest;
    };

    ensureLoggedIn = ({ from, returnTo }: { from: string, returnTo?: string }) => {
        const isGuest = this.isGuest();
        if (isGuest) {
            // don't await, return false immediately leaving dialog open
            this.enforceAuthOptions({ from, returnTo });
        }
        return !isGuest;
    };

    private enforceAuthOptions = async ({ from, returnTo }: { from: string, returnTo?: string }) => {
        this.trackingService.track('Auth Options Enforced', () => ({ from }));
        await this.authService.showLoginDialog({ message: 'Create an account or sign in to continue', returnTo });
    };
}
