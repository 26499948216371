import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useEffect } from 'react';
import { AppVersionUpdateManager } from './AppVersionUpdateManager';
import { TileUrlProvider } from '@autopylot-internal/tiles-client';
import { App, AppState } from '@capacitor/app';
import { HistoryViewModel } from '../HistoryViewModel';

/**
 * Non-rendering component that starts listeners for expired app version.
 */
export const AppVersionUpdateAlerter: React.FC = () => {
    const versionAlertManager = useInstance(AppVersionUpdateManager);
    const tileUrlProvider = useInstance(TileUrlProvider);
    const history = useInstance(HistoryViewModel);

    useEffect(() => {
        // on tileUrl / app status changes
        tileUrlProvider.subscribe(() => {
            versionAlertManager.checkForUpdate();
        });

        // on foreground, remind the user to update
        App.addListener('appStateChange', ({ isActive }: AppState) => {
            if (isActive) {
                versionAlertManager.maybeAlert();
            }
        });

        // on hash change, if the user must update, and somehow got past
        // the alert, show the alert again
        history.getRawHistory().listen(async () => {
            versionAlertManager.maybeAlertMustUpdate();
        });

    }, [versionAlertManager, tileUrlProvider, history]);

    return null;
};
