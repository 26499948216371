import { useEffect } from 'react';

export const BackButtonHandler: React.FC = () => {

    useEffect(() => {
        document.addEventListener('ionBackButton', (ev: any) => {

            // priority must be higher than any of the internal handlers listed here:
            // https://ionicframework.com/docs/developing/hardware-back-button#internal-framework-handlers
            const priority = 200;

            ev.detail.register(priority, () => {
                // for now, do nothing (i.e. disable back button)
                // TODO: implement handling here that provides the desired behavior on each page
            });
        });
    });

    return null;
};
