import moment from 'moment';

export class DefaultMissionTimeCalculator{
    calculate = (): { startTime: string, durationMinutes: number} => {
        const startTime = moment().add(15, 'minutes').add(1, 'hour').startOf('hour').toISOString();
        return {
            startTime,
            durationMinutes: 60
        };
    };
}
