import { State } from '@meraki-internal/state';

export class AutoPylotPrincipal extends State {
    constructor(){
        // not storing anything in state, just using State for subscription
        // mostly b/c most consumers don't want to use this as a state, so don't make them walk into .state.email
        super({});
    }
    email = '';
    userId = '';
    isAdmin = false;

    init = ({ userId, email, isAdmin }: {userId: string, email: string, isAdmin: boolean }) => {
        this.userId = userId;
        this.email = email || '';
        this.isAdmin = isAdmin;

        this.setState({ });
    };
}
