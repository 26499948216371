import { useEffect, useState } from 'react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { Page } from '../components/page/Page';
import { MissionsState } from '../missions/model/MissionsState';
import { TrackingService } from '../support/tracking/TrackingService';
import { LocationSearchService } from './location/LocationSearchService';
import { IMapEvent } from './model/IMapEvent';
import { NewMissionState } from '../missions/model/NewMissionState';
import { ShowMap } from './components/ShowMap';
import { NewMissionMapDrawer } from './NewMissionMapDrawer';
import { NoMissionMapDrawer } from './NoMissionMapDrawer';
import { Haptics, NotificationType } from './Haptics';
import { OperatorState } from '../profile/OperatorState';
import { AlertPresenter } from '../app/AlertBinder';
import { Logger } from '../support/debug/Logger';
import { HistoryViewModel } from '../app/HistoryViewModel';
import { ToggleLayersSheet } from './toggle-layers/ToggleLayersSheet';
import { ICoordinate } from '../support/model/ICoordinate';
import { useSubscription } from '@meraki-internal/state';
import { AuthService } from '../auth/AuthService';
import { EncourageGuestToCreateAccountMarshal } from '../profile/EncourageGuestToCreateAccountMarshal';
import { NewMissionTrackingHelper } from '../mission/NewMissionTrackingHelper';
import { useBreakpoints } from '../support/hooks/useBreakpoints';
import { BufferedEventBus } from '../events/BufferedEventBus';

// I want my screenshot to be able to mock the point at which we have a location, and are now moving a mission pin
// but that is "local state" of my component
// I have 2 options
// 1. my screenshot can send a query param (testing specific code, not ideal)
// 2. move that out of local state (testing specific complexity, not ideal)

// 2 is much easier to reason about from everywhere (SUT and ScreenshotSUT)
//  BUT, it incurs complexity to SUT in that it needs to manage state that was otherwise getting GC'd
//  IOW - I can set newMission, but I need to remember to unset it all the places (which is otherwise not a concern)

// a 3rd option is to have a transient scoped state, that is not transient to the screenshots
//  BUT that is at least as complex as the query param solution

// a 4th option, is optional props to the page, duh! this is much better than the complexity of history

export interface IMapPageScreenshotConfig {
    mission?: NewMissionState;
    wizardPage?: number;
}

export const MapPage: React.FC<{screenshotConfig?: IMapPageScreenshotConfig}> = ({ screenshotConfig }) => {
    const historyVM = useInstance(HistoryViewModel);

    const dropPin = (historyVM.popSearchParam({ key: 'dropPin' }));

    const initialWizardPage = screenshotConfig?.wizardPage;

    const [newMission, setNewMission] = useState<NewMissionState | undefined>(screenshotConfig?.mission);

    const events = useInstance(BufferedEventBus);

    const tracker = useInstance(TrackingService);
    const locationSearchService = useInstance(LocationSearchService);
    const haptics = useInstance(Haptics);
    const logger = useInstance(Logger);

    const missionState = useInstance(MissionsState);
    const operator = useInstance(OperatorState);
    useSubscription(() => operator);
    const alert = useInstance(AlertPresenter);

    const authService = useInstance(AuthService);
    const guestMarshal = useInstance(EncourageGuestToCreateAccountMarshal);

    const trackingHelper = useInstance(NewMissionTrackingHelper);

    const { isLargerScreen } = useBreakpoints();
    const hideMenu = newMission && !isLargerScreen;

    const beginCreatingMission = async () => {
        try {
            const mission = missionState.beginCreatingMission();
            return mission;
        }
        catch (err: any) {
            if (err && err.errorCode === 'invalidated-missions'){
                await alert.showAlertV2({
                    header : 'New Missions Not Allowed',
                    message: err.message,
                    options: [{
                        id: 'go-to-missions',
                        label: 'Cancel Mission(s)'
                    }]
                });
                historyVM.push('/missions');
                await new Promise(resolve => {
                    // never resolves, we're redirecting
                });
            }

            throw err;
        }
    };

    useEffect(() => {
        if (dropPin) {
            // TODO DRY out code duplicated in onMapClicked below

            // dropPin is coordinates, eg "-83,45
            const latLng = dropPin.split(',');
            const coordinates: ICoordinate = { lng: Number(latLng[0]), lat: Number(latLng[1]) };

            locationSearchService.reverseLookup(coordinates).then(async location => {

                if (!location) {
                    // unfortunately they left the map over a lake or something
                    // so we can't drop a pin
                    return;
                }

                const mission = await beginCreatingMission();

                mission.setAddress(location.address);
                mission.setCenter(coordinates).then(() => {
                    // this has to happen after awaiting setCenter(), otherwise radius won't have been created, and canFly etc will be undefined
                    trackingHelper.pinDropped({
                        mission,
                        address: location?.address,
                        coordinates,
                        trigger: 'button',
                        fromNewMissionButton: true
                    });

                    events.emit('PinDropped');
                });

                setNewMission(mission);
            });
        }

    // adding beginCreatingMission would cause it to trigger every render
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dropPin, locationSearchService, trackingHelper]);

    useEffect(() => {
        if (!operator.state.certificationType){
            alert.showAlertV2({
                header: 'Are you a Part 107 Licensed Pilot?',
                message: '',
                options: [
                    { id: 'no', label: 'Not Yet'},
                    { id: 'yes', label: 'Yes'},
                ]
            })
                .then(async yesOrNo => {
                    await operator.setIsLicensed(yesOrNo === 'yes');
                })
                .catch((err: any) => {
                    logger.error(`failed in call to operator.setIsLicensed. ${err.toString()}`);
                });
        }
    }, [operator, alert, logger]);

    const onCancel = () => {
        newMission?.cancel();
        setNewMission(undefined);
    };

    const encourageOldGuestToCreateAccount = async () => {
        if (!await guestMarshal.isGuestAndShouldEncourage()){
            return;
        }

        tracker.track('Account Nudge Prompted', () => ({}));

        const res = await alert.showAlertV2({
            header: 'Thanks for checking airspace with AutoPylot!',
            message: 'Create an account for more free features!',
            options: [
                {
                    id: 'no',
                    label: 'Not Now'
                },
                {
                    id: 'yes',
                    label: 'Create Account'
                }
            ]
        });

        await guestMarshal.recordEncouraged();

        tracker.track('Account Nudge Continued', () => ({
            selected: res === 'yes' ? 'Create Account' : 'Not Now'
        }));

        if (res === 'yes'){
            tracker.track('Auth Options Enforced', () => ({
                from: 'Account Nudge'
            }));
            await authService.showLoginDialog({ message: 'Create Account' });
        }
    };

    const createMission = async ({ eventType, lngLat }: IMapEvent) => {
        // ignore if already creating mission
        if (newMission) {
            return;
        }

        encourageOldGuestToCreateAccount();

        const location = await locationSearchService.reverseLookup(lngLat);

        // if no location found (likely we're over water), abort with error vibration
        if (!location) {
            haptics.notification({ type: NotificationType.Error });
            return;
        }

        haptics.impact();

        const mission = await beginCreatingMission();
        mission.setAddress(location.address);

        mission.setCenter(lngLat)
            .then(async () => {
                trackingHelper.pinDropped({
                    mission,
                    coordinates: lngLat,
                    address: location?.address,
                    trigger: eventType,
                });

                events.emit('PinDropped');
            });

        setNewMission(mission);
    };

    return (
        <Page hideMenu={hideMenu} noScroll fullWidth>

            <ShowMap onClick={createMission} onLongPress={createMission} />

            {!newMission &&
                <NoMissionMapDrawer setNewMission={setNewMission} beginCreatingMission={beginCreatingMission} />
            }
            {newMission &&
                <NewMissionMapDrawer newMission={newMission} onCancel={onCancel} initialWizardPage={initialWizardPage} />
            }
            <ToggleLayersSheet />

        </Page>
    );
};
