import moment from 'moment';
import { IOperator } from '../../profile/IOperator';

const ONE_MINUTE = 60 * 1000;
const ONE_HOUR = 60 * 60 * 1000;

export class DelayLiveUpdateInstallServiceConfig {
    MIN_MS_HASH_CHANGE = ONE_MINUTE * 10;
    MIN_MS_FOREGROUND = ONE_HOUR * 3;
    operator?: IOperator;
}

export class DelayLiveUpdateInstallService {
    static inject = () => [DelayLiveUpdateInstallServiceConfig];
    constructor(private config: DelayLiveUpdateInstallServiceConfig){ }

    private appStartedAt?: number;
    private lastBackgroundedAt?: number;

    // mocked by tests
    clock = { now: () => Date.now() };

    onAppStart = () => {
        this.appStartedAt = this.clock.now();
        return { canInstall: false };
    };

    onBackground = () =>{
        this.lastBackgroundedAt = this.clock.now();
    };

    onForeground = () => {
        if (!this.mightBeNewUser()){
            return { canInstall: true };
        }

        if (!this.lastBackgroundedAt){
            return { canInstall: false };
        }
        const elapsed = this.clock.now() - this.lastBackgroundedAt;

        return { canInstall: elapsed >= this.config.MIN_MS_FOREGROUND };
    };

    private mightBeNewUser = (): boolean => {
        return !this.config.operator || !this.config.operator.updatedTime || this.config.operator.updatedTime > moment().add(-15, 'minutes').toISOString();
    };

    onHashChange = () => {
        if (!this.mightBeNewUser()){
            return { canInstall: true };
        }

        if (!this.appStartedAt){
            return { canInstall: false };
        }
        const elapsedSinceAppStart = this.clock.now() - this.appStartedAt;
        return {
            canInstall: elapsedSinceAppStart >= this.config.MIN_MS_HASH_CHANGE
        };
    };

    getCanInstallOnHashChangeAfter = (): string | undefined => {
        if (!this.appStartedAt){
            return undefined;
        }
        return moment(this.appStartedAt + this.config.MIN_MS_HASH_CHANGE).toISOString();
    };

    getCanInstallOnForegroundAfter = (): string | undefined => {
        if (!this.appStartedAt){
            return undefined;
        }
        return moment(this.appStartedAt + this.config.MIN_MS_FOREGROUND).toISOString();
    };
}
