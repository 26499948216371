import { DevSettings } from '../../support/DevSettings';
import { IIntercomAdapter, IIntercomEnv } from './IntercomAdapter';

const global: any = window;

export class IntercomWeb implements IIntercomAdapter {

    static inject = () => [DevSettings];
    constructor(private devSettings: DevSettings) {};

    getEnv = (): IIntercomEnv => {
        const { hostname } = window.location;
        let useProd = (['app.autopylot.io', 'app.faa-testing.autopylot.io'].includes(hostname));
        if (this.devSettings.switchIntercomEnv) {
            useProd = !useProd;
        }
        return (useProd ? 'intercom-prod' : 'intercom-staging');
    };

    init = async (config: { appId: string, userId: string, email?: string, name?: string }) => {
        // next line is js code block copied from intercom site, with variable appId inserted
        // @ts-ignore-next-line
        // eslint-disable-next-line
        (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/'+config.appId;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();

        global.Intercom('boot', {
            api_base: 'https://api-iam.intercom.io',
            app_id: config.appId,

            email: config.email,
            user_id: config.userId,
            name: config.name
        });

        // when simulating native on web, remove the launcher
        if (this.devSettings.simulateNative) {
            setTimeout(() => document.querySelector('.intercom-launcher')?.remove(), 500);
        }
    };

    updateUser = async (user: { name: string }) => {
        global.Intercom('update', user);
    };

    displayMessenger = async () => {
        global.Intercom('showMessages');

        // when simulating native on web, remove the launcher
        if (this.devSettings.simulateNative) {
            setTimeout(() => document.querySelector('.intercom-app .intercom-dfosxs')?.remove(), 500);
        }
    };

    displayMessageComposerWithInitialMessage = async  (message: string) => {
        global.Intercom('showNewMessage', message);
    };

    canDisplayCarousel = false;

    displayCarousel = async (carouselId: string) => {
        throw new Error('Intercom carousels are not supported on web');
    };

    displayArticle = async (articleId: string) => {
        global.Intercom('showArticle', articleId);
    };

    onUnreadCountChange = (listener: (unreadCount: number) => void) => {
        global.Intercom('onUnreadCountChange', listener);
    };

    setMessageVisibility = async (visible: boolean) => {
        // nothing to do, messages are always visible on web
    };
}
