import { IonButton } from '@ionic/react';
import { useState } from 'react';
import { DrawerLabel } from '../drawer/DrawerLabel';
import { DownChevronIcon, UpChevronIcon } from '../icons/Icons';
import { Card } from './Card';
import { CardContent } from './CardContent';
import { CardHeader } from './CardHeader';

export const ExpandableCard: React.FC<{heading: string}> = ({heading, children}) => {
    const [isOpen, setOpen] = useState(true);
    return (
        <Card>
            <CardHeader>
                <div style={{display: 'flex', justifyContent: 'space-between'}} onClick={() => setOpen(!isOpen)}>
                    <span style={{flexGrow: 1}}><DrawerLabel text={heading} /></span>
                    <IonButton style={{margin: '-5px -20px'}} size="small" fill="clear">
                        {!isOpen && <DownChevronIcon size="small" />}
                        {isOpen && <UpChevronIcon size="small" />}
                    </IonButton>
                </div>
            </CardHeader>
            {isOpen &&
                <CardContent>
                    {children}
                </CardContent>
            }
        </Card>
    );
};
