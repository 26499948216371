import { IScreenshotablePage } from '../support/screenshots/IScreenshotablePage';
import { MapPage } from './MapPage';
import { NewMissionState } from '../missions/model/NewMissionState';
import { MapViewModel } from './model/MapViewModel';
import { OperatorState } from '../profile/OperatorState';
import { FAAVersionAlertManager } from '../app/FAAVersionAlertManager';
import { TrackingService } from '../support/tracking/TrackingService';
import { IOperator } from '../profile/IOperator';
import { EnableAndBindMapForScreenshots } from './EnableAndBindMapForScreenshots';
import { MissionPinAndRadiusOnMapViewModel } from './mission-pin-and-radius/MissionPinAndRadiusOnMapViewModel';

interface IMyPageProps {
    operator: Partial<IOperator>
}

export default class ScreenshotMapPage implements IScreenshotablePage<IMyPageProps> {
    static inject = () => [
        MapViewModel,
        FAAVersionAlertManager,
        TrackingService,
        OperatorState,
        MissionPinAndRadiusOnMapViewModel
    ];

    constructor(
        private mapViewModel: MapViewModel,
        private versionAlertManager: FAAVersionAlertManager,
        private tracker: TrackingService,
        private operatorState: OperatorState,
        private pinAndRadius: MissionPinAndRadiusOnMapViewModel
    ) { }

    screenId = 'new-mission-step-3';

    ComponentUnderTest = MapPage;

    WrapWith = EnableAndBindMapForScreenshots;

    permutationToProps = async ({ operator }: IMyPageProps) => {
        this.operatorState.setState({
            agreedToFAAPrivacyStatement: true,
            agreedToLAANCStatement: true,
            agreedToTermsOfService: true,
            certificationType: 'recreational',
            ...operator
        });

        const mission = new NewMissionState({
            onSave: async () => {
            },
            getConcurrentLAANCMissions: () => []
        });

        mission.mapViewModel = this.mapViewModel;
        mission.operator = this.operatorState;
        mission.versionAlertManager = this.versionAlertManager;
        mission.tracker = this.tracker;
        mission.pinAndRadius = this.pinAndRadius;

        mission.setAddress({ street: '12025 Coyote Ridge Road', city: 'Deadwood', state: 'SD', zip: '57732' });
        mission.setCenter({ lng: -103.64851, lat: 44.30194 });

        return { screenshotConfig: { mission, wizardPage: 3 } };
    };

    permutations: {[k: string]: IMyPageProps} = {
        'operator-profile-blank': {
            operator: {}
        },
        'operator-profile-completed': {
            operator: { firstName: 'Freddy', lastName: 'Flyer', phone: '802-123-1234', email: 'freddy.flyer@gmail.com' }
        }
    };
}
