import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useSubscription } from '@meraki-internal/state';
import { PaywallDialogViewModel } from './PaywallDialogViewModel';
import { RevenueCatModel } from './revenue-cat/RevenueCatModel';
import { PaywallIcon } from '../components/icons/Icons';
import { IonIcon, IonItem, IonLabel, IonText } from '@ionic/react';
import { MenuViewModel } from '../menu/MenuViewModel';
import { OperatorState } from '../profile/OperatorState';
import { PaywallNudger } from './PaywallNudger';

export const PaywallMenuButton: React.FC = () => {

    const menuVM = useInstance(MenuViewModel);
    const paywall = useInstance(PaywallDialogViewModel);
    const nudger = useInstance(PaywallNudger);

    const revenueCat = useInstance(RevenueCatModel);
    useSubscription(() => revenueCat);

    const operator = useInstance(OperatorState);
    useSubscription(() => operator);

    const onClick = () => {
        if (operator.isGuest()) {
            // if a guest, show nudger before login
            nudger.open({ triggerSource: 'menu button' });
        } else {
            paywall.open({ triggerSource: 'menu button' });
        }
    };

    return (
        <IonItem data-id="paywall-menu-button" data-type={revenueCat.hasUpgraded() ? 'downgrade' : 'upgrade'} button lines="none" detail={false} onClick={menuVM.clickHandler(onClick)}>

            <div slot="start" style={{position: 'relative'}} title="AutoPylot+">
                <IonIcon
                    style={{pointerEvents: 'none', fontSize: 24, minWidth: 24}}
                    color="primary"
                    icon={PaywallIcon.raw}
                />
            </div>

            <IonLabel>
                <IonText color="primary"><b>AutoPylot+</b></IonText>
            </IonLabel>

        </IonItem>
    );
};
