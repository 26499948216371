import { useEditableMissionState } from '../../missions/model/useEditableMissionState';
import { usePageView } from '../../support/tracking/usePageView';
import { MissionNotes } from './MissionNotes';

export const MissionNotesPage: React.FC<{ missionId: string }> = ({ missionId }) => {
    usePageView('Mission Notes');

    const vm = useEditableMissionState(missionId);

    if (!vm) {
        return null;
    }

    return (
        <MissionNotes mission={vm} />
    );
};
