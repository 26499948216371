import { IonItem, IonLabel } from '@ionic/react';
import { ForwardIcon } from '../components/icons/Icons';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useEffect, useState } from 'react';
import { WindIndicator } from './components/WindIndicator';
import { ISummaryForecast } from './model/ISummaryForecast';
import { PrecipitationIcon } from './components/PrecipitationIcon';
import { WeatherConditionIcon } from './components/WeatherConditionIcon';
import { WindLabel } from './components/WindLabel';
import { Logger } from '../support/debug/Logger';
import { IHourlyForecast } from './model/IHourlyForecast';
import { CachingWeatherService } from './CachingWeatherService';
import { ICoordinate } from '@autopylot-internal/tiles-client';
import { TrackingService } from '../support/tracking/TrackingService';

type ISO8601 = string;

interface ILinkBasedWeatherButtonProps {
    link: { href: string; };

    // mission info
    startTime: ISO8601;
    location: ICoordinate;
    endTime: ISO8601;

    onClick?: () => void;
}

export const LinkBasedWeatherButton: React.FC<ILinkBasedWeatherButtonProps> = ({ link, startTime, endTime, location, onClick }) => {
    const weatherService = useInstance(CachingWeatherService);

    const [forecast, setForecast] = useState<ISummaryForecast>();
    const [forecastError, setForecastError] = useState<any>();
    const logger = useInstance(Logger);

    useEffect(() => {
        Promise.resolve().then(async () => {
            const summary = await weatherService.getSummaryForecast({
                link,
                location,
                startTime,
                endTime
            });
            setForecast(summary);
        }).catch(err => {
            setForecastError(err);
            if (err.errorCode === 'forecast.unavailable') {
                // this is expected for future missions, so don't log
                return;
            }
            logger.error(err);
        });
    }, [weatherService, link, startTime, endTime, location, logger]);

    return <WeatherButton forecast={forecast} forecastError={forecastError} onClick={onClick} />;
};

interface IWeatherButtonProps {
    forecast?: ISummaryForecast;
    forecastError?: any;
    onClick?: () => void;
}

export const WeatherButton: React.FC<IWeatherButtonProps> = ({ forecast, forecastError, onClick }) => {

    return (
        <IonItem button={Boolean(forecast)} detail={false} data-type="weather-button" onClick={() => {
            if (!forecast || !onClick){
                return;
            }
            onClick();
        }}>
            <IonLabel>
                <h2>Weather</h2>
                {forecast &&
                    <p>
                        <span style={{display: 'flex', alignItems: 'center', gap: 10}}>
                            <span style={{display: 'flex', alignItems: 'center', gap: 2}}>
                                <WeatherConditionIcon conditionCode={forecast.conditionCode} isDaylight={forecast.isDaylight} size="small" />
                                <span>{forecast.temperatureF}&deg;</span>
                            </span>

                            <span style={{display: 'flex', alignItems: 'center'}}>
                                <PrecipitationIcon precipitationType={forecast.precipitationType} color="tertiary" size="small" />
                                <span>{forecast.precipitationChancePct}%</span>
                            </span>

                            <span style={{display: 'flex', alignItems: 'center', gap: 1}}>
                                <WindIndicator direction={forecast.windDirection} size="small" />
                                <WindLabel direction={forecast.windDirection} speedMph={forecast.windSpeedMph} showHeading={true} />
                            </span>
                        </span>
                    </p>
                }
                {!forecast && !forecastError &&
                    <p>Getting weather...</p>
                }
                {forecastError &&
                    <p>{forecastError.displayMessage || 'Sorry, something went wrong'}</p>
                }
            </IonLabel>
            {forecast &&
                <ForwardIcon slot="end" color="medium" size="medium" />
            }
        </IonItem>
    );
};

interface IWeatherSummaryButtonProps {
    forecast?: IHourlyForecast;
    forecastError?: any;
    isLoading: boolean;
    onClick: () => void;
}

/**
 * Ideally we'd converge these 2 but that are as different as they are the same
 * and so at this time, it doesn't feel worth it
 *  - a wrapper div to get margin (could move this up the dom)
 *  - no bottom line (but mission details wants that)
 *  - shrink padding (but mission details wants that)
 *  - a drawer label (but use H2 for Mission Details)
 */
export const WeatherSummaryDrawerButton = ({ forecast, forecastError, isLoading, onClick }: IWeatherSummaryButtonProps) => {
    const tracking = useInstance(TrackingService);

    return (
        <IonItem lines="none" style={{
            '--padding-start': 0,
            '--padding-end': 0,
            '--inner-padding-end': 0,
            '--background': 'transparent'
        }} button={Boolean(forecast)} inlist={false} detail={false} data-type="weather-button" onClick={!forecast ? undefined : () => {
            tracking.track('Opened Mission Planning Weather');

            onClick();
        }}>
            <div style={{ fontSize: 15, opacity: isLoading ? .5 : 1 }}>
                {forecast &&
                    <>
                        <span style={{display: 'flex', alignItems: 'center', gap: 10}}>
                            <span style={{display: 'flex', alignItems: 'center', gap: 2}}>
                                <WeatherConditionIcon conditionCode={forecast.conditionCode} isDaylight={forecast.isDaylight} size="small" />
                                <span>{forecast.temperatureF}&deg;</span>
                            </span>

                            <span style={{display: 'flex', alignItems: 'center'}}>
                                <PrecipitationIcon precipitationType={forecast.precipitationType} color="tertiary" size="small" />
                                <span>{forecast.precipitationChancePct}%</span>
                            </span>

                            <span style={{display: 'flex', alignItems: 'center', gap: 1}}>
                                <WindIndicator direction={forecast.windDirection} size="small" />
                                <WindLabel direction={forecast.windDirection} speedMph={forecast.windSpeedMph} showHeading={false} />
                            </span>

                            <span style={{display: 'flex', alignItems: 'center', gap: 1}}>
                                <WindIndicator direction={forecast.windDirection} size="small" />
                                <WindLabel direction={forecast.windDirection} speedMph={forecast.windGustMph} showHeading={false} gusts />
                            </span>
                        </span>
                    </>
                }
                {!forecast && !forecastError &&
                    <p>Getting weather...</p>
                }
                {forecastError &&
                    <p>{forecastError.displayMessage || 'Sorry, something went wrong'}</p>
                }
            </div>
            {forecast &&
                <ForwardIcon slot="end" size="small" color="medium" />
            }
        </IonItem>
    );
};
