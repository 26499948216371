import { generate as generateId } from 'shortid';
import { State } from '@meraki-internal/state';
import { IChecklistSection, IChecklistItem, INotification } from './model';
import { ITrackingEvent } from '../support/tracking/TrackingService';

export interface IChecklistItemTracker {
    track: (event: ITrackingEvent, item?: IChecklistItem) => void
}

export class ChecklistSectionState extends State<IChecklistSection> {

    constructor(
        private tracker: IChecklistItemTracker,
        checklistSection: IChecklistSection
    ) {
        super(checklistSection);
    }

    addItem = (newItem: { name: string, notification?: INotification } ) => {
        const item = { ...newItem, completed: false, id: generateId() };
        this.setState({
            items: [...this.state.items, item]
        });

        this.tracker.track('Checklist Item Added', item);

        return item;
    };

    updateItem = (updatedItem: IChecklistItem) => {
        this.setState({
            items: this.state.items.map(item => {
                if (item.id === updatedItem.id) {
                    return { ...item, ...updatedItem };
                } else {
                    return item;
                }
            })
        });

        this.tracker.track('Checklist Item Edited', updatedItem);
    };

    toggleItem = (id: string) => {
        const item = this.state.items.find(i => i.id === id);
        if (item) {
            item.completed = !item.completed;
            this.setState({});

            this.tracker.track('Checklist Item Toggled', item);
        }
    };

    deleteItem = (id: string) => {
        const deletedItem = this.state.items.find(item => item.id === id);
        if (deletedItem) {
            this.setState({
                items: this.state.items.filter(item => item.id !== id)
            });

            this.tracker.track('Checklist Item Deleted', deletedItem);
        }
    };

    moveItem = (fromIndex: number, toIndex: number) => {
        const items = this.state.items;
        const moved = items.splice(fromIndex, 1);
        items.splice(toIndex, 0, moved[0]);

        this.setState({
            items
        });

        this.tracker.track('Checklist Item Reordered');
    };
}
