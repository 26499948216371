import { State } from '@meraki-internal/state';
import { BundleInfo, CapacitorUpdater, CapacitorUpdaterPlugin } from '@capgo/capacitor-updater';
import { ILiveUpdatesService } from './ILiveUpdatesService';
import { App } from '@capacitor/app';
import { EnvConfiguration } from '../../app/config/EnvConfiguration';
import { Device } from '../Device';
import { Logger } from '../debug/Logger';
import moment from 'moment';
import { HistoryStorage } from '../../app/HistoryViewModel';
import { FileSystemLogger } from '../debug/FileSystemLogger';
import { StorageProvider } from '../StorageProvider';

interface ILatestUpdateFile {
    url?: string;
    version: string;
}

type ILiveUpdateStatus = 'initializing' | 'live-update-available' | 'downloading' | 'downloaded' | 'installing' | 'up-to-date';

export class AppInfoProvider {
    static inject = () => [Device];
    constructor(private device: Device){}
    getAppInfo = async (): Promise<{ version: string; } | null> => {
        if (this.device.platform === 'web'){
            return null;
        }
        return await App.getInfo();
    };
}

export class LatestLiveUpdateVersionProvider {
    getLatestVersionAvailable = async (url: string): Promise<ILatestUpdateFile> => {
        const res = await fetch(url);
        if (res.status === 200){
            const body: ILatestUpdateFile = await res.json();
            return body;
        }
        else {
            throw new Error(`${res.status} GET ${url}`);
        }
    };
}

export class LocationProvider {
    getHash = () => window.location.hash;
}

class CapacitorUpdaterProvider {
    getUpdater = (): CapacitorUpdaterPlugin => CapacitorUpdater;
}

export class ActualSHAProvider {
    getSHA = () => process.env.REACT_APP_SHA || 'no-sha';
}


// This gets swapped out in IOC by Network
// We are not using that directly here, because it indirectly depends on tsx and causes mocha
// to fail
export class AutopylotLiveUpdatesServiceNetwork {
    constructor(){
        throw new Error('see ioc');
    }
    isOnline: boolean = true;
}

export class AutopylotLiveUpdatesService extends State<{}> implements ILiveUpdatesService {
    static inject = () => [
        EnvConfiguration,
        AppInfoProvider,
        CapacitorUpdaterProvider,
        LatestLiveUpdateVersionProvider,
        Logger,
        ActualSHAProvider,
        HistoryStorage,
        FileSystemLogger,
        StorageProvider,
        AutopylotLiveUpdatesServiceNetwork,
        LocationProvider
    ];
    constructor(
        private config: EnvConfiguration,
        private appInfoProvider: AppInfoProvider,
        capacitorUpdaterProvider: CapacitorUpdaterProvider,
        private latestLiveUpdateVersionProvider: LatestLiveUpdateVersionProvider,
        private logger: Logger,
        private actualSHAProvider: ActualSHAProvider,
        private storage: HistoryStorage,
        private fileSystemLogger: FileSystemLogger,
        private moreStorage: StorageProvider,
        private network: AutopylotLiveUpdatesServiceNetwork,
        private locationProvider: LocationProvider
    ) {
        super({});
        this.capacitorUpdater = capacitorUpdaterProvider.getUpdater();
    }
    private lastInstalledLiveUpdateVersionStorage = this.moreStorage.getAsyncStringProvider('AutopylotLiveUpdatesService.lastInstalledLiveUpdateVersion', { envNeutral: false, userNeutral: true, storageType: 'localStorage', maxBytes: 1000000, removeOnTooBig: true });

    private mustWaitForLiveUpdateOnAppStartStorage = this.moreStorage.getAsyncBooleanProvider('AutopylotLiveUpdatesService.requireLiveUpdateOnAppStart', {
        // env neutral b/c that is less complex than DevSettings trying to side effect the env we're going to
        // rather than the env we are in
        // IOW - when switching from Prod to Staging, we want staging to require a live update
        // and vice-versa
        envNeutral: true,
        userNeutral: true,
        storageType: 'localStorage',
        maxBytes: 1000000,
        removeOnTooBig: true
    });

    lastInstalledLiveUpdateVersion?: string;

    private capacitorUpdater: CapacitorUpdaterPlugin;
    private isDownloading = false;
    private isInstalling = false;

    private nativeVersion?: string;
    /**
     * Returns the native version, eg 1.6.3
     * which is what live updates get applied to
     * Since this class is only ever used on a real device it will always return a value
     */
    getNativeVersion = () => this.nativeVersion;

    private disableInstallOrDownloadUpdateIfAvailable = false;

    private _init  = async () => {
        // we have to call this within the first 10s of our app starting, or the live update
        // will be assumed bad, and will be rolled back
        await this.capacitorUpdater.notifyAppReady();
        this.capacitorUpdater.addListener('updateFailed', (e) => {
            this.logger.error(`got updateFailed ${JSON.stringify(e.bundle)}`);
        });

        this.lastInstalledLiveUpdateVersion = await this.lastInstalledLiveUpdateVersionStorage.get();

        const appInfo = await this.appInfoProvider.getAppInfo();
        if (!appInfo){
            throw new Error('WARN: AutopylotLiveUpdatesService was initialized without AppInfo, likely not on a device, which should not have been possible, see AppContainer');
        }
        this.nativeVersion = appInfo.version;

        await Promise.all([
            this.fetchBundles(),
            this.fetchLatestVersionAvailable()
        ]);

        this.logger.info('AutopylotLiveUpdatesService.init', {
            lastInstalledLiveUpdateVersion: this.lastInstalledLiveUpdateVersion,
            bundles: this.bundles,
            current: this.getCurrentBundle(),
            installedVersion: this.getInstalledVersion(),
            status: this.getLiveUpdateStatus()
        });

        // intentionally not waiting, it has error handling built in
        this.checkForErrorBundles();
    };

    /**
     * Init fails gracefully such that
     * 1. an error will be sent to sentry
     * 2. if we failed to load native version, bundles, or latestAvailable
     *    then the status will be initializing and
     *    therefore downloadUpdateIfAvailable will no op
     *    and installOrDownloadUpdateIfAvailable will no op
     *    until the next app start where it can try to init again
     *    If, the thing the failed was this.latestVersionAvailable then it likely
     *    will correct itself over time, as it will retry every hash change
     */
    init = async ({ disableInstallOrDownloadUpdateIfAvailable }: { disableInstallOrDownloadUpdateIfAvailable?: boolean;} = {}) => {
        this.disableInstallOrDownloadUpdateIfAvailable = disableInstallOrDownloadUpdateIfAvailable === true;
        try {
            await this._init();
        }
        catch (err: any){
            this.logger.error(`AutopylotLiveUpdatesService.init failed, allowing app to proceed but won't support live updates. Error: ${err.toString()}`);
        }
    };

    downloadUpdateIfAvailable = async (): Promise<void> => {
        this.logger.info('AutopylotLiveUpdatesService.downloadUpdateIfAvailable');

        if (this.disableInstallOrDownloadUpdateIfAvailable){
            return;
        }
        try{
            await this.fetchLatestVersionAvailable();

            if (this.getLiveUpdateStatus() === 'live-update-available'){
                await this.downloadLatest();
            }
        }
        catch (err: any){
            if (this.network.isOnline){
                this.logger.error(`WARNING: AutopylotLiveUpdatesService.downloadUpdateIfAvailable failed with ${err.toString()}`);
            }
            else {
                this.logger.info(`AutopylotLiveUpdatesService.downloadUpdateIfAvailable failed with ${err.toString()} while network is offline`);
            }
        }
    };

    installOrDownloadUpdateIfAvailable = async (): Promise<void> => {
        if (this.disableInstallOrDownloadUpdateIfAvailable){
            this.logger.info('AutopylotLiveUpdatesService.installOrDownloadUpdateIfAvailable', { disableInstallOrDownloadUpdateIfAvailable: this.disableInstallOrDownloadUpdateIfAvailable });
            return;
        }

        try{
            this.logger.info('AutopylotLiveUpdatesService.installOrDownloadUpdateIfAvailable', { status: this.getLiveUpdateStatus() });

            // if we have something ready to install, install it with minimal delay
            // we could call fetchLatestVersionAvailable, eg maybe it is no longer the most current
            // but this method is called at the opportune time in the UX to install and delaying
            // isn't worth it
            if (this.getLiveUpdateStatus() === 'downloaded'){
                this.storage.appStartPath.set(this.locationProvider.getHash());

                await this.fileSystemLogger.flush();

                // check to see if it is still "downloaded"
                // because it might have changed while we flushed the file system
                if (this.getLiveUpdateStatus() === 'downloaded') {
                    await this.installLatest();
                }
            }
            else {
                await this.downloadUpdateIfAvailable();
            }
        }
        catch (err: any){
            this.logger.error(`WARNING: AutopylotLiveUpdatesService.installOrDownloadUpdateIfAvailable failed with ${err.toString()}`);
        }
    };

    // Returns the full version, eg 1.6.3.de6aa22
    // If there is a live update installed, it will return that, even if running locally, in which case it is NOT
    // the actual version. If no live update is installed, it will return the actual version (the version shipped with the native build);
    getInstalledVersion = (): string => {
        const currentBundle = this.getCurrentBundle();
        return currentBundle ? currentBundle.version : this.getActualVersion();
    };

    // Returns the full version, eg 1.6.3.de6aa22
    // This uses the SHA that was harded coded into the build.
    // The only time this will be different than getInstalledVersion
    // is when running locally, because then the version of JS we're running
    // is different than the live update that is installed.
    getActualVersion = (): string => {
        return `${this.getNativeVersion() || ''}.${this.getActualShortSHA()}`;
    };

    private getActualSHA = () => this.actualSHAProvider.getSHA();
    private getActualShortSHA = () =>  this.getActualSHA().substring(0, 7);

    bundles?: BundleInfo[];

    getAvailableLiveUpdateUrl = (): string => {
        const nativeVersion = this.getNativeVersion();
        const env = this.config.ENV === 'live' ? 'production' : 'staging';
        return `https://live-updates.autopylot.io/${nativeVersion}/${env}-latest.json`;
    };

    latestVersionAvailable?: ILatestUpdateFile & { asOf: string; };

    getLiveUpdateStatus = (): ILiveUpdateStatus => {
        if (!this.latestVersionAvailable || !this.bundles){
            return 'initializing';
        }

        if (this.isInstalling){
            return 'installing';
        }

        if (this.isDownloading) {
            return 'downloading';
        }

        let latestVersionAvailable: ILatestUpdateFile | undefined = this.latestVersionAvailable;
        if (!latestVersionAvailable.url){
            // if there isn't a url, because its an "empty" live update
            // to avoid 404 caching, then there isn't a latest live update to compare to
            latestVersionAvailable = undefined;
        }

        const current = this.getCurrentBundle();
        // TODO: current should be null when native, but might not be after a native upgrade
        this.logger.info('AutopylotLiveUpdatesService.getLiveUpdateStatus', { current });
        const currentVersion = current?.version || `${this.getNativeVersion() || ''}.${(this.actualSHAProvider.getSHA()).substring(0,7)}` || ``;

        if (!latestVersionAvailable || currentVersion === this.latestVersionAvailable.version){
            return 'up-to-date';
        }

        // technically there could be more than one bundle for a single version
        // this state machine should make that verify difficult, but alas, we should handle it just in case
        const latestVersionAvailableBundles = this.bundles.filter(b => b.version === latestVersionAvailable!.version);

        // if there is one that is installed, that one should win
        let latestVersionAvailableBundle = latestVersionAvailableBundles.find(b => b.status === 'success');

        if (!latestVersionAvailableBundle){
            // if there is one ready to install, use it
            latestVersionAvailableBundle = latestVersionAvailableBundles.find(b => b.status === 'pending');
        }

        if (latestVersionAvailableBundle && latestVersionAvailableBundle.status === 'pending' && this.lastInstalledLiveUpdateVersion === latestVersionAvailableBundle.version){
            return 'up-to-date';
        }

        if (!latestVersionAvailableBundle){
            // if there is one downloading, use it
            latestVersionAvailableBundle = latestVersionAvailableBundles.find(b => b.status === 'downloading');
        }

        // if we already tried the "latest" and it failed, then we don't want to update again until there is a new live update
        if (latestVersionAvailableBundles.find(b => b.status === 'error')){
            return 'up-to-date';
        }

        if (!latestVersionAvailableBundle){
            return 'live-update-available';
        }

        if (latestVersionAvailableBundle.status === 'downloading'){
            return 'downloading';
        }

        if (latestVersionAvailableBundle.status === 'pending'){
            return 'downloaded';
        }

        // shouldn't get this far, but if we do, assume something is wrong and let them redownload it
        return 'live-update-available';
    };

    downloadLatest = async () => {
        this.logger.info('AutopylotLiveUpdatesService.downloadLatest');

        if (this.getLiveUpdateStatus() !== 'live-update-available'){
            throw new Error(`cannot download, status is ${this.getLiveUpdateStatus()}`);
        }

        if (!this.network.isOnline){
            this.logger.info('attempted downloadLatest, but skipping because offline');
            return;
        }

        await this.downloadVersion(this.latestVersionAvailable!);
    };

    private downloadVersion = async ({ url, version }: ILatestUpdateFile) => {
        if (!url){
            // then native and so nothing to download
            return;
        }
        // we are setting our flag that we are downloading so we can synchronously
        // know that a download has started, otherwise we're dependent on
        // learning it from capacitorUpdater.list which is inherently async
        // and therefore there would be a race condition where mulitiple downloads could
        // could triggered in parallel
        this.isDownloading = true;
        this.setState({ });

        try {
            this.logger.info('AutopylotLiveUpdatesService.downloadLatest downloading', { latestVersionAvailable: this.latestVersionAvailable });

            await this.capacitorUpdater.download({ url, version });

            // intentionally not waiting
            this.cleanupDeadBundles();

            await this.fetchBundles();
            this.setState({ });
        }
        catch (err: any){
            throw new Error(`CapacitorUpdater.download ${JSON.stringify(this.latestVersionAvailable)} failed with Error: ${err.toString()}`);
        }
        finally {
            this.isDownloading = false;
            this.setState({ });
        }
    };

    refreshLatestAvailableLiveUpdate = async() => {
        await this.fetchLatestVersionAvailable();
    };

    /**
     * This will show the splash screen, then initiate install of the alreaded downloaded zip file.
     *
     * It should take hundreds of ms to swap in the new web view, at which point our javascript is no longer running.
     *
     * The new version of the app, will then need to hide the splash screen.
     */
    installLatest = async () => {
        this.logger.info('AutopylotLiveUpdatesService.installLatest');

        const status = this.getLiveUpdateStatus();

        if (status !== 'downloaded'){
            throw new Error(`cannot install latest b/c it is not downloaded yet, current status is ${status}`);
        }

        await this.installBundle(this.latestVersionAvailable!.version!);
    };

    private installBundle = async (version: string) => {
        const latestAvailableBundle = (this.bundles || []).find(b => b.version === version);
        if (!latestAvailableBundle || latestAvailableBundle.status !== 'pending'){
            throw new Error(`Attempting to install version ${version} bundle ${latestAvailableBundle} but status is not "pending"`);
        }

        this.isInstalling = true;
        this.setState({ });

        // we could show the splash screen here to ensure the user can't interact with the content we're removing
        // but it is believed that is too jarring and will cause the user to either think the app is crashing
        // or to wonder why an update is happening that it didn't know about.

        try {
            // set the in memory version, just in case
            this.lastInstalledLiveUpdateVersion = latestAvailableBundle.version;

            // set the storage version, so if this installs, and then reverts, we know not to try and install it again
            this.logger.info('AutopylotLiveUpdatesService.installLatest lastInstalledLiveUpdateVersionStorage.set', { latestAvailableBundle });
            await this.lastInstalledLiveUpdateVersionStorage.set(latestAvailableBundle.version);

            this.logger.info('AutopylotLiveUpdatesService.installLatest capacitorUpdater.set', { latestAvailableBundle });
            await this.capacitorUpdater.set({ id: latestAvailableBundle.id });
            // we lose control within ?1ms?, as the new JS app starts
        }
        catch (err: any) {
            this.isInstalling = false;

            this.setState({ });
            throw err;
        }
    };

    getCurrentBundle = (): BundleInfo | undefined => {
        return (this.bundles || []).find(b => b.status === 'success');
    };

    private fetchLatestVersionAvailable = async (): Promise<void> => {
        if (!this.getNativeVersion()){
            // then we failed to init, fail gracefully
            return;
        }

        const body = await this.latestLiveUpdateVersionProvider.getLatestVersionAvailable(this.getAvailableLiveUpdateUrl());
        this.latestVersionAvailable = {
            ...body,
            asOf: new Date().toISOString()
        };
        this.setState({});
    };

    canDeleteBundle = (bundle: BundleInfo) => {
        // cannot delete the currently installed bundle
        return bundle.status !== 'success';
    };

    deleteBundle = async (bundle: BundleInfo) => {
        if (!this.canDeleteBundle(bundle)){
            throw new Error(`cannot delete bundle ${JSON.stringify(bundle)} because it is the currently installed bundle. Install another bundle first`);
        }
        await this.capacitorUpdater.delete({ id: bundle.id });
        await this.fetchBundles();
    };

    private fetchBundles = async () => {
        const result = await this.capacitorUpdater.list();
        this.bundles = result.bundles;
        this.setState({});
    };

    showManagerLink = () => true;

    /**
     * CapacitorUpdater will delete the old bundle when we install a new one.
     *
     * But it doesn't delete bundles that never get installed.
     */
    private cleanupDeadBundles = async () => {
        try {
            // lets get bundles older than 24 hours
            // b/c so we don't delete a "dead" production bundle
            // while in the staging env, only to have to download it again when we switch
            // in the same day
            const twentyFourHoursAgo = moment().add(-24, 'hours').toISOString();

            for (const bundle of (this.bundles || [])){
                if (bundle.status === 'pending' && bundle.downloaded >= twentyFourHoursAgo){
                    await this.deleteBundle(bundle);
                }
            }
            await this.fetchBundles();
        }
        catch (err: any){
            this.logger.error(`WARN: failed to clean up dead bundles. ${err.toString()}`);
        }
    };

    /**
     * I haven't actually seen bundles with status "error".
     *
     * Looking at the java source, it appears, that after a revert the bundle should have been changed to status error.
     * But when I tested this, it was still status pending
     *
     * Also, looking at the java source, it appears it should send us an event at this.capacitorUpdater.addListener('updateFailed'
     * but I didn't see the event in the logs. It isn't clear which runtime the java source is sending it to, the one that failed,
     * or the one it is reverting to. It is sending it to a queue, but I am doubtful its a queue that respects the fact that the
     * runtime might not even exist yet. I wouldn't be surprised if this feature is half baked and it is going to the one that it is
     * reverting to, before it exists, such that it never gets it.
     */
    private checkForErrorBundles = async () => {
        try {

            const errorBundles = (this.bundles || []).filter(b => b.status === 'error');
            for (const bundle of errorBundles){
                this.logger.error(`WARN: found bundle with error ${JSON.stringify(bundle)} which is likely the result of reverting a live update`);
                // don't delete it if it is the latest, or else we'll try to install it again
                if (this.latestVersionAvailable && this.latestVersionAvailable.version !== bundle.version){
                    await this.deleteBundle(bundle);
                }
            }
            await this.fetchBundles();
        }
        catch (err: any){
            this.logger.error(`WARN: failed to clean up error bundles. ${err.toString()}`);
        }
    };

    requireInstallOnNextAppStart = async () => {
        await this.mustWaitForLiveUpdateOnAppStartStorage.set(true);
    };

    mustWaitForLatestUpdate = async () => {
        const res = await this.mustWaitForLiveUpdateOnAppStartStorage.get();
        return Boolean(res);
    };

    waitForDownloadAndInstall = async () => {
        // clear the flag so this wait only happens once, even if it goes wrong
        await this.mustWaitForLiveUpdateOnAppStartStorage.remove();

        // clear the last installed version, b/c it is more likely to do harm than good
        // it is likely that we'll see the last version installed, is the "latest" version
        // that we should install, and assume it must have failed b/c if that was the last version installed
        // and it isn't the version we're on, then it must have reverted (which is wrong in this case)
        this.lastInstalledLiveUpdateVersion = undefined;

        await this.fetchLatestVersionAvailable();

        // what follows behaves slightly different than the normal live update flow in 2 distinct ways
        // 1 - we know we have the wrong code running (eg staging code running, but we're now in production)
        //     and so here, any production code installed is better than leaving it in staging code
        //     and so here, we download a prod live update, then install it (without checking to see if it is
        //     still the latest), where-as normally if we downloaded a live update, but then there was a new
        //     live update, we wouldn't install the one we just downloaded, we would abandon it, and download the
        //     newer one.
        // 2 - we might be going from live update to native (which is not possible for a normal user flow). But
        //     in this case, if there was a live update in staging, and we're switching to production where there
        //     isn't one, then we need to abandon the live update and revert back to native.

        await this.downloadVersion(this.latestVersionAvailable!);

        if (!this.latestVersionAvailable?.version){
            await CapacitorUpdater.reset();
        } else {
            await this.installBundle(this.latestVersionAvailable!.version!);
        }
    };
}
