import { APIClient } from '@autopylot-internal/autopylot-api-client';
import { State } from '@meraki-internal/state';
import { AlertPresenter } from '../app/AlertBinder';
import { TrackingService } from '../support/tracking/TrackingService';
import { UndoState } from '../undo/UndoState';
import { ChecklistState } from './ChecklistState';
import { ISavedChecklist } from './model';

interface ISavedChecklistsState {
    checklists: ChecklistState<ISavedChecklist>[];
    loaded: boolean;
}

export class SavedChecklistsState extends State<ISavedChecklistsState> {

    static inject = () => [
        APIClient,
        AlertPresenter,
        TrackingService,
        UndoState
    ];
    constructor(
        private apiClient: APIClient,
        private alertPresenter: AlertPresenter,
        private tracker: TrackingService,
        private undo: UndoState
    ) {
        super({
            checklists: [],
            loaded: false
        });
    }

    getChecklistById = async (checklistId: string): Promise<ChecklistState<ISavedChecklist>> => {
        if (!this.state.loaded) {
            await this.load();
        }

        const checklist = this.state.checklists.find(m => m.state.checklist.checklistId ===  checklistId);

        if (!checklist) {
            throw new Error(`checklist ${checklistId} wasn't found among ${this.state.checklists.length} checklists`);
        }

        return checklist;
    };

    addChecklist = async (checklist: ISavedChecklist): Promise<ISavedChecklist> => {
        const entry = await this.apiClient.entry();
        const savedChecklist = await this.apiClient.post(entry.links.savedChecklists, checklist);

        const onSave = async (updated: ISavedChecklist) => {
            await this.apiClient.put(savedChecklist, updated);
            this.setState({});
        };

        const onForceNotification = async () => {
            await this.alertPresenter.showAlertV2({ header: 'Not Supported', message: 'You can only force notifications from a mission checklist.' });
        };

        const checklistState = new ChecklistState(savedChecklist);
        checklistState.injectDependencies(this.tracker, this.alertPresenter, this.undo, { onSave, onForceNotification });

        this.setState({
            checklists: this.state.checklists?.concat(checklistState)
        });

        return savedChecklist;
    };

    removeChecklist = async (checklist: Omit<ISavedChecklist, 'sections'>) => {
        await this.apiClient.delete(checklist);

        this.setState({
            checklists: this.state.checklists.filter(c => c.state.checklist.checklistId !== checklist.checklistId)
        });
    };

    load = async () => {
        const entry = await this.apiClient.entry();
        const checklistsFromApi = await this.apiClient.get(entry.links.savedChecklists);

        const checklists = this.state.checklists;

        for (const checklistFromApi of checklistsFromApi) {
            let checklist = checklists.find(c => c.state.checklist.checklistId === checklistFromApi.checklistId);

            if (checklist) {
                checklist.setState(checklistFromApi);

            } else {
                const onSave = async (updated: ISavedChecklist) => {
                    await this.apiClient.put(checklistFromApi, updated);
                    this.setState({});
                };

                const onForceNotification = async () => {
                    await this.alertPresenter.showAlertV2({ header: 'Not Supported', message: 'You can only force notifications from a mission checklist.' });
                };

                checklist = new ChecklistState(checklistFromApi);
                checklist.injectDependencies(this.tracker, this.alertPresenter, this.undo, { onSave, onForceNotification });

                checklists.push(checklist);
            }
        };

        this.setState({ checklists, loaded: true });
    };
}
