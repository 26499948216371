import { FatSlider } from '../../components/slider/FatSlider';

export const MissionAltitudeSlider: React.FC<{max?: number, value: number, onChange: (v: number) => void}> = ({max, value, onChange}) => {
    const formatter = (v: number) => `${v}\u2008ft`; // \u2008 is narrow space
    return (
        <div style={{marginBottom: 10}}>
            <FatSlider min={0} max={max || 0} step={50} value={value} onChange={onChange} labelFormatter={formatter} />
            <div style={{textAlign: 'right', fontSize: 10, marginTop: 0, marginBottom: 5, marginRight: 20}}>Max Allowed: {max} ft</div>
        </div>
    );
};
