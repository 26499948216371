import { ISpecialUseAdvisoryProperties } from '@autopylot-internal/tiles-client';
import { SecondaryWarningAdvisory } from '../SecondaryAdvisory';

export const getName = (props: ISpecialUseAdvisoryProperties): string => {
    if (['P', 'R'].includes(props.TYPE_CODE)){
        return `${props.NAME} SUA`;
    }
    return props.NAME;
};

export const SpecialUseAdvisory = (props: ISpecialUseAdvisoryProperties) => {

    let header = 'Special Use Airspace';

    let description = (
        <>
            <p>
                Your mission overlaps <strong>{getName(props)}</strong>, and this airspace may contain more air traffic
                or fast military aircraft. Maintain extra vigilance and awareness when flying.
            </p>
            <p>
                Prior to flight, the remote pilot in command must assess local airspace and any flight restrictions.
            </p>
        </>
    );

    if (props.TYPE_CODE === 'P'){
        header = `Prohibited Special Use Airspace`;
        description = (
            <>
                <p>
                    Your mission overlaps <strong>{getName(props)}</strong>, and no person may operate a small unmanned aircraft
                    in restricted airspace unless that person has permission from the appropriate authority.
                </p>
                <p>
                    Individuals violating any of these restrictions may be subject to FAA and/or law enforcement action.
                </p>
            </>
        );
    }

    if (props.TYPE_CODE === 'R'){
        header = `Restricted Special Use Airspace`;
        description = (
            <>
                <p>
                    Your mission overlaps <strong>{getName(props)}</strong>, and no person may operate a small unmanned aircraft
                    in restricted airspace unless that person has permission from the appropriate authority.
                </p>
                <p>
                    Individuals violating any of these restrictions may be subject to FAA and/or law enforcement action.
                </p>
            </>
        );
    }

    return (
        <SecondaryWarningAdvisory header={header} advisoryType={props.advisoryType} GLOBAL_ID={props.GLOBAL_ID}>
            <>
                {description}

                {props.TIMES_OF_USE && (
                    <p>
                        Times of Use: {props.TIMES_OF_USE}
                    </p>
                )}
                {props.REMARKS && (
                    <p>
                        Remarks: {props.REMARKS}
                    </p>
                )}
                <p>
                    <a href="http://sua.faa.gov" target="_blank">http://sua.faa.gov</a>
                </p>
            </>
        </SecondaryWarningAdvisory>
    );
};
