import { IScreenshotablePage } from '../support/screenshots/IScreenshotablePage';
import { IOperatorProfile } from './IOperator';
import { OperatorState } from './OperatorState';
import { ProfilePage, ProfilePageState } from './ProfilePage';

interface IMyPageProps {
    operator: IOperatorProfile,
    isMenuOpen?: boolean;
    isEditing?: boolean;
}

/**
 * Screenshots readme
 *  - cover all permutations of visible risk
 */

export default class ScreenshotProfilePage implements IScreenshotablePage<IMyPageProps> {
    static inject = () => [OperatorState, ProfilePageState];
    constructor(private operatorState: OperatorState, private pageState: ProfilePageState) {}

    screenId = 'profile';

    ComponentUnderTest = ProfilePage;

    permutationToProps = ({ operator, isMenuOpen = false, isEditing }: IMyPageProps) => {
        this.operatorState.setState(operator);

        this.pageState.setState({ isMenuOpen });

        // we want to allow page to execuate its logic to set isEditing when it loads,
        // so use a timeout, and only do this if we have an override
        if (isEditing !== undefined) {
            setTimeout(() => this.pageState.setState({ isEditing }));
        }

        return {};
    };

    permutations: {[k: string]: IMyPageProps} = {
        'blank-profile-part-107': {
            operator: { certificationType: 'part-107' },
            // don't set isEditing because blank automatically opens in edit mode
        },
        'blank-profile-recreational': {
            operator: { certificationType: 'recreational' },
            // don't set isEditing because blank automatically opens in edit mode
        },
        'view-profile-part-107': {
            operator: { email: 'fred@autopylot.io', firstName: 'Fred', lastName: 'Flyer', phone: '8021231234',  certificationType: 'part-107', certificationNumber: '1234567', certificationDate: '2020-01-01' }
        },
        'view-profile-recreational': {
            operator: { email: 'fred@autopylot.io', firstName: 'Fred', lastName: 'Flyer', phone: '8021231234', certificationType: 'recreational', certificationNumber: 'LLAM12345678901', certificationDate: '2020-01-01' }
        },
        'menu-open': {
            operator: { email: 'fred@autopylot.io', firstName: 'Fred', lastName: 'Flyer', phone: '8021231234',  certificationType: 'part-107', certificationNumber: '1234567', certificationDate: '2020-01-01' },
            isMenuOpen: true
        },
        'edit-profile-part-107': {
            operator: { email: 'fred@autopylot.io', firstName: 'Fred', lastName: 'Flyer', phone: '8021231234',  certificationType: 'part-107', certificationNumber: '1234567', certificationDate: '2020-01-01' },
            isEditing: true
        },
        'edit-profile-recreational': {
            operator: { email: 'fred@autopylot.io', firstName: 'Fred', lastName: 'Flyer', phone: '8021231234',  certificationType: 'recreational', certificationNumber: 'LLAM12345678901', certificationDate: '2020-01-01' },
            isEditing: true
        }
    };
}
