import { useRef } from 'react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { State, useSubscription } from '@meraki-internal/state';
import { usePageView } from '../support/tracking/usePageView';
import { Field, FieldList } from '../components/field/Field';
import { TextInput } from '../components/field/TextInput';
import { FAA_NAME_PATTERN } from '../profile/ProfilePage';
import { Button } from '../components/button/Button';
import { OperatorState } from '../profile/OperatorState';
import { IOperatorProfile } from '../profile/IOperator';
import { AlertPresenter } from '../app/AlertBinder';
import { TrackingService } from '../support/tracking/TrackingService';

class SetupOperatorState extends State<{ values: IOperatorProfile, submitting: boolean }> {

    static inject = () => [OperatorState, TrackingService];
    constructor(private operatorState: OperatorState, private tracker: TrackingService) {
        super({
            values: { ...operatorState.state },
            submitting: false
        });

        operatorState.subscribe(() => {
            this.setState({
                values: { ...operatorState.state },
            });
        });
    }

    skipSetupOperator = () => {
        this.tracker.track('Pilot Info Do Later Tapped');
        this.operatorState.skipSetupOperator();
    };

    updateValues = (updates: Partial<IOperatorProfile>) => {
        this.setState({ values: {
            ...this.state.values,
            ...updates
        }});
    };

    save = async () => {
        const { firstName, lastName, phone } = this.state.values;
        await this.operatorState.save({ firstName, lastName, phone });
        this.tracker.track('Pilot Info Entered', () => ({ firstName, lastName, phone, from: 'Create Account' }));
    };
}

export const SetupOperator: React.FC = () => {
    usePageView('Setup Profile');

    const alert = useInstance(AlertPresenter);

    const pageStateInstance = useInstance(SetupOperatorState);
    const pageState = pageStateInstance.state;
    useSubscription(() => pageStateInstance);

    const formRef = useRef<HTMLFormElement>(null);

    if (!pageState?.values) {
        return null;
    }

    const onChangeHandler = (key: string) => {
        return (value: string) => {
            pageStateInstance.updateValues({ [key]: value });
        };
    };

    const onClickContinue = async () => {
        if (pageState.submitting) {
            return;
        }

        pageStateInstance.setState({ submitting: true });

        try {
            if (formRef.current?.reportValidity()) {
                await pageStateInstance.save();
            }
        } catch(e: any) {
            alert.showAndLogError(e);
        }

        pageStateInstance.setState({ submitting: false });
    };

    return (
        <div style={{margin: '80px 20px 0'}}>
            <h5>Please complete your info. The FAA requires it for LAANC Authorizations.</h5>

            <form style={{margin: '-10px -15px 20px'}} ref={formRef}>
                <FieldList>

                    <Field dataSet={{type: 'first'}} label="First Name" required>
                        <TextInput
                            value={pageState.values.firstName}
                            onChange={onChangeHandler('firstName')}
                            pattern={FAA_NAME_PATTERN}
                            maxlength={60}
                            title="Must be letters, punctuation, and whitespace"
                        />
                    </Field>

                    <Field dataSet={{type: 'last'}} label="Last Name" required>
                        <TextInput
                            value={pageState.values.lastName}
                            onChange={onChangeHandler('lastName')}
                            pattern={FAA_NAME_PATTERN}
                            maxlength={60}
                            title="Must be letters, punctuation, and whitespace"
                        />
                    </Field>

                    <Field dataSet={{type: 'phone'}} label="Phone Number" required>
                        <TextInput
                            value={pageState.values.phone}
                            onChange={onChangeHandler('phone')}
                            type="tel"
                            inputmode="tel"
                            pattern="[1-9]\d{9}"
                            maxlength={10}
                            title="Must be 10 digits, and cannot start with a zero"
                        />
                    </Field>

                </FieldList>
            </form>

            <div style={{display: 'flex', flexDirection: 'column', gap: 15, textAlign: 'center'}}>
                <Button expand="block" dataType="continue" disabled={pageState.submitting} onClick={onClickContinue}>
                    Continue
                </Button>
                <Button fill="clear" type="button" size="small" onClick={pageStateInstance.skipSetupOperator} dataType="do-this-later">
                    <b style={{fontSize: '110%'}}>Do this later</b>
                </Button>
            </div>
        </div>

    );
};
