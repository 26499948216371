import { useInstance } from '@meraki-internal/react-dependency-injection';
import { RoundIconButton } from './RoundIconButton';
import { CSSProperties } from 'react';
import { DeviceStatusBar } from '../../support/DeviceStatusBar';
import { CloseIcon } from '../icons/Icons';

export const FloatingCloseButton: React.FC<{onClick: () => void}> = ({ onClick }) => {
    const statusBar = useInstance(DeviceStatusBar);
    const statusBarHeight = statusBar.getHeight();

    // allow for status bar with notch (but don't go less than 10px)
    const top = Math.max(statusBarHeight, 10);

    const style: CSSProperties = {
        position: 'absolute',
        top,
        right: 5,
        zIndex: 1000
    };

    return (
        <div style={style}>
            <RoundIconButton
                Icon={CloseIcon}
                data-id="close-button"
                onClick={onClick}
            />
        </div>
    );
};
