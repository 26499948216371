import { Fragment } from 'react';
import { DoneIcon } from '../icons/Icons';

export interface WizardProgressBarProps {
    steps: number;
    currentStep: number
}

export const WizardProgressBar: React.FC<WizardProgressBarProps> = ({steps, currentStep}) => {
    // convert 4 -> [1,2,3,4]
    const stepArray = Array(steps).fill(true).map((_s, i) => i + 1);

    const getColor = (step: number) => {
        return (step <= currentStep ? 'success' : 'light-shade');
    };

    return (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 3}}>
            {stepArray.map((step) => (
                <Fragment key={`step-${step}`}>
                    {step > 1 &&
                        <span style={{flexGrow: 1, height: 4, backgroundColor: 'var(--ion-color-medium-tint)'}} />
                    }
                    {step < currentStep &&
                        <span style={{marginRight: -2, marginTop: 3}}>
                            <DoneIcon size="medium" variant='filled' color="success" />
                        </span>
                    }
                    {step >= currentStep &&
                        <span style={{width: 20, height: 20, backgroundColor: `var(--ion-color-${getColor(step)})`, borderRadius: 10}}>
                            {step === currentStep &&
                                <span style={{position: 'absolute', marginTop: 5, marginLeft: 5, width: 10, height: 10, backgroundColor: 'white', borderRadius: 10}} />
                            }
                        </span>
                    }
                </Fragment>
            ))}
        </div>
    );
};
