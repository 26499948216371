import { Color } from '../components/common';
import { Logger } from '../support/debug/Logger';

export const theme = {
    // duplicated from ./variables.css
    success: '#23ae09',
    successShade: '#1f9908',
    successTint: '#39b622'
};

// TODO: we should migrate theme to this
export class Theme {
    static inject = () => [Logger];
    constructor(private logger: Logger){}

    private colorMap: { [key in  Color]: string} = {
        primary: '',
        secondary: '',
        tertiary: '',
        success: '',
        warning: '',
        danger: '',
        medium: '',
        light: '',
        'advisory-warning': '',
        'advisory-laanc': '',
        insurance: ''
    };

    getColor = (color: Color) => {
        // We used to do this in the ctor but found that sometimes the color is resolving to ""
        // we expect the CSS is loaded synchronously and so don't know where the race condition is
        // this is a slight improvement in that
        //  a) it will fail "gracefully" by returning "gray" instead of crashing
        //  b) it will try again, likely resolving itself if it is a race condition
        // This change might also address the race condition because it is no longer resolving at ctor time
        if (!this.colorMap[color]){
            this.colorMap[color] = getComputedStyle(document.body).getPropertyValue(`--ion-color-${color}`).trim();
        }
        if (!this.colorMap[color]){
            this.logger.error(`getComputedStyle(document.body).getPropertyValue(--ion-color-${color}) returned ""`);
            return 'gray';
        }
        return this.colorMap[color];
    };
}
