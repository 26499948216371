import { IAdvisoryPropertiesV3 } from '@autopylot-internal/tiles-client';
import { SecondaryWarningAdvisory } from '../SecondaryAdvisory';

export interface ICompositeMTRAdvisoryProperties extends IAdvisoryPropertiesV3 {
    advisoryType: 'advise';
    source: 'military-training-routes+composite';
    routeNumbers: string[];
}

export const MilitaryTrainingRoutesAdvisory = (props: ICompositeMTRAdvisoryProperties & { near?: boolean}) => {
    return (
        <SecondaryWarningAdvisory header="Special Use Airspace" advisoryType={props.advisoryType}>
            <>
                <p>
                    Your mission {props.near ? 'is within 2 NM of': 'overlaps'} <strong>Military Training Route{props.routeNumbers.length > 1 ? 's' : ''} {props.routeNumbers.join(', ')}</strong>. This airspace may contain more air traffic
                    or fast military aircraft. Maintain extra vigilance and awareness when flying.
                </p>
                <p>
                    Prior to flight, the remote pilot in command must assess local airspace and any flight restrictions.
                </p>
                <p>
                    <a href="https://sua.faa.gov" target="_blank">https://sua.faa.gov</a>
                </p>
            </>
        </SecondaryWarningAdvisory>
    );
};
