import { INSUFRAdvisoryProperties } from '@autopylot-internal/tiles-client';
import { SecondaryWarningAdvisory } from '../SecondaryAdvisory';

export const NSUFRAdvisory = (props: INSUFRAdvisoryProperties) => {
    return (
        <SecondaryWarningAdvisory header="National Security Warning" advisoryType={props.advisoryType} GLOBAL_ID={props.GLOBAL_ID}>
            <>
                <p>
                    Your mission overlaps <strong>{props.Base || props.Facility}</strong>, and no person may operate a small unmanned aircraft
                    in restricted airspace unless that person has permission from the appropriate authority.
                </p>
                <p>
                    Individuals violating any of these restrictions may be subject to FAA and/or law enforcement action.
                </p>
                <p>
                    The POC for this airspace is <strong>{props.POC}</strong>.
                </p>
            </>
        </SecondaryWarningAdvisory>
    );
};
