import { createUseStyles } from 'react-jss';
import { WindyMapModalState } from './WindyMapModalState';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { mediaMinWidth, mediaMaxWidth } from '../../support/helpers/breakpoints';
import { DeviceStatusBar } from '../../support/DeviceStatusBar';

const useStyles = createUseStyles(() => ({
    wrapper: {
        pointerEvents: 'none',
        position: 'absolute',
        [mediaMaxWidth['sm']]: {
            marginRight: 50
        },
        [mediaMinWidth['sm']]: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
        }
    },
    content: {
        pointerEvents: 'auto',
        cursor: 'pointer',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
        marginLeft: 15,
        marginRight: 15,
        padding: 15,
        borderRadius: 10,
        backgroundColor: 'rgba(var(--ion-color-warning-rgb), 0.7)',
        color: 'white',
        fontSize: 14
    }
}));

export const WindyPaywallBanner: React.FC = () => {
    const classes = useStyles();

    // allow for status bar with notch (but don't go less than 15px)
    const statusBar = useInstance(DeviceStatusBar);
    const top = Math.max(statusBar.getHeight(), 15);

    const stateInstance = useInstance(WindyMapModalState);

    if (!stateInstance.state.countdown) {
        return null;
    }

    const onClick = () => {
        stateInstance.showPaywall();
    };

    return (
        <div className={classes.wrapper} style={{top}} onClick={onClick}>
            <div className={classes.content}>
                <div><b>Explore wind and radar maps. This free preview ends in {stateInstance.state.countdown} seconds.</b></div>
                <div>For unlimited access, <u>try AutoPylot+</u></div>
            </div>
        </div>
    );
};
