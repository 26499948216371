import * as mapboxgl from 'mapbox-gl';
import { GeolocationService } from '../location/GeolocationService';
import { IMapViewModelPlugin } from '../model/IMapViewModelPlugin';
import { MapViewModel } from '../model/MapViewModel';
import { Theme } from '../../app/ThemeVariables';
import { DevSettings } from '../../support/DevSettings';
import { Logger } from '../../support/debug/Logger';
import moment from 'moment';
import { ApMapboxStyler } from '../styles/ApMapboxStyler';

export class GPSPositionPlugin implements IMapViewModelPlugin {
    static inject = () => [
        GeolocationService,
        Theme,
        DevSettings,
        Logger,
        ApMapboxStyler
    ];
    constructor(
        private geolocation: GeolocationService,
        private theme: Theme,
        private devSettings: DevSettings,
        private logger: Logger,
        private styler: ApMapboxStyler
    ) { }

    private setGPSDotStyles = (map: mapboxgl.Map) => {
        this.styler.addSource(map, 'source:gps', {
            'type': 'geojson',
            'data': { type: 'FeatureCollection', features: []}
        });
        const radius = 9;
        const radiusOutline = 2;

        this.styler.addLayer(map, {
            id: 'layer:gps:shadow',
            source: 'source:gps',
            type: 'circle',
            paint: {
                'circle-color': '#000',
                'circle-radius': (radius + radiusOutline) * 1.2,
                'circle-blur': 1,

                // shift it downward so the shadow appears below
                'circle-translate': [0, (radius + radiusOutline) * 0.25]
            }
        });
        this.styler.addLayer(map, {
            id: 'layer:gps:dot',
            source: 'source:gps',
            type: 'circle',
            paint: {
                'circle-color': this.theme.getColor('tertiary'),
                'circle-radius': radius,
                'circle-stroke-color': '#fff',
                'circle-stroke-width': radiusOutline,
            }
        });

        if (this.devSettings.showGPSMeta){
            this.styler.addLayer(map, {
                id: 'layer:gps:meta',
                type: 'symbol',
                source: 'source:gps',
                layout: {
                    'text-field': ['get', 'meta'],
                    'text-size': 10,
                    'text-variable-anchor': ['top-left'],
                    'text-radial-offset': 1.0,
                    'text-justify': 'left',
                },
                paint: {
                    'text-halo-color': '#fff',
                    'text-halo-blur': 2,
                    'text-halo-width': 2,
                },
            });
        }
    };

    private startGPS = async (mapViewModel: MapViewModel) => {
        let hasEmitted = false;

        try {
            await this.geolocation.subscribe(pos => {

                // when we get the first gps location, fly there if the map is still in the default location
                if (!hasEmitted) {
                    hasEmitted = true;
                    if (mapViewModel.isInitialCenter()) {
                        mapViewModel.flyTo({ center: pos, zoom: 14 });
                    }
                }

                (((mapViewModel as any).map as mapboxgl.Map).getSource('source:gps') as mapboxgl.GeoJSONSource).setData({
                    type: 'Feature',
                    properties: {
                        meta: JSON.stringify({
                            ...pos,
                            at: moment(pos.at).format('hh:mm:ss.SSS'),
                            accuracy: `${Math.round(pos.accuracy)}m`
                        }, null, 2)
                    },
                    geometry: { type: 'Point', coordinates: [pos.lng, pos.lat] }
                });
            });
        } catch (err: any) {
            this.logger.error(`Failed to start GPS. Error: ${err.toString()}`);
        }
    };

    init = (mapViewModel: MapViewModel, map: mapboxgl.Map) => {
        this.setGPSDotStyles(map);

        this.startGPS(mapViewModel);
    };
}
