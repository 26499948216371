
import { useState } from 'react';
import { ILocation } from '../../support/model/ILocation';
import { LocationSearch } from './LocationSearch';

const DemoLocationSearch = () => {
    const [location, setLocation] = useState<any>();
    const onSearch = async (text: string): Promise<ILocation[]> => {
        return [
            { address: { street: '38 Thibault Parkway', city: 'Burlington', state: 'VT' }, coordinates: { lat: 44.4822, lng: -73.18971 }},
            { address: { street: '38 Thibault Lane', city: 'Tiverton', state: 'RI' }, coordinates: { lat: 41.643592, lng: -71.150215 }},
            { address: { street: '38 Thibault Drive', city: 'Colchester', state: 'VT' }, coordinates: { lat: 44.545243, lng: -73.264532 }},
            { address: { street: '38 Thibault Avenue', city: 'Winstead', state: 'CT' }, coordinates: { lat: 41.922509, lng: -73.068778 }},
            { address: { street: '38 Thibault Street', city: 'Swanton', state: 'VT' }, coordinates: { lat: 44.926705, lng: -73.119145 }},
        ].filter(l => (`${l.address.street}, ${l.address.city}, ${l.address.state}`).startsWith(text));
    };
    return (
        <>
            <LocationSearch onSearch={onSearch} onSelect={(value: any) => setLocation(value)} />
            <p>{location ? `Coordinates: ${location.coordinates.lat}, ${location.coordinates.lng}`: ''}</p>
        </>
    );
};

DemoLocationSearch.componentName = 'LocationSearch';

export default DemoLocationSearch;
