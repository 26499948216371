import { StatusBar, Style } from '@capacitor/status-bar';
import { SafeArea } from '@aashu-dubey/capacitor-statusbar-safe-area';
import { Logger } from './debug/Logger';
import { Device } from './Device';
import { App, AppState } from '@capacitor/app';

// note: "light" is equivalent to "default" on ios (defined in info.plist)
export type DeviceStatusBarStyle = 'default' | 'light' | 'dark';

export class DeviceStatusBar {

    static inject = () => [Device, Logger];
    constructor(private deviceInfo: Device, private log: Logger) {}

    private statusBarHeight: number | undefined;
    private navBarHeight: number | undefined;

    init = async (retry: number = 0): Promise<void> => {
        // on ios, the statusbar overlays the app by default, and ionic provides a variable
        if (this.deviceInfo.platform === 'ios') {
            let safeTop = getComputedStyle(document.body).getPropertyValue('--ion-safe-area-top').trim();

            // we invoke this method late in AppContainer, so safari should have set env vars by now,
            // but if not retry, then fall back to reasonable default that should work on all devices
            if (safeTop === '' || safeTop === '0px') {
                if (retry < 3) {
                    this.log.info(`css var --ion-safe-area-top is "${safeTop}", retrying...`);
                    await new Promise(res => setTimeout(res, 100));
                    return await this.init(retry + 1);
                } else {
                    this.log.error(`css var --ion-safe-area-top is "${safeTop}", falling back to default`);
                    safeTop = '50px';
                }
            }

            this.statusBarHeight = parseInt(safeTop);
            this.navBarHeight = 0;

            await StatusBar.setStyle({ style: Style.Light });
        }

        // on android we have to manually overlay the status bar, and use a separate plugin to get the height
        else if (this.deviceInfo.platform === 'android') {
            const { top, bottom } = await SafeArea.getSafeAreaInsets();
            this.statusBarHeight = top;
            this.navBarHeight = bottom;

            // fix ionic variables (ionic currently always sets this to '0px' on android)
            document.body.style.setProperty('--ion-safe-area-top', `${this.statusBarHeight}px`);
            document.body.style.setProperty('--ion-safe-area-bottom', `${this.navBarHeight}px`);

            App.addListener('appStateChange', async ({ isActive }: AppState) => {
                // on foreground
                if (isActive){
                    // ensure the status bar has a transparent background
                    // in case the OS has changed that on us, eg b/c the user switched between
                    // light and dark mode and hasn't restarted the app yet
                    setTimeout(async () => {
                        await this.applyTransparentBackgroundToAndroidStatusBar();
                    }, 100);

                    // just in case 100ms was too fast, lets try one more time
                    setTimeout(async () => {
                        await this.applyTransparentBackgroundToAndroidStatusBar();
                    }, 1000);
                }
            });

            // it seems logical to invoke applyTransparentBackgroundToAndroidStatusBar() here,
            // but we've found this causes capacitor 6 to fail to account for --ion-safe-area-bottom,
            // which pushes app content behind the navigation bar, so we wait for repaintAndroid()
            // to be invoked when the splash screen is hidden
        }

        // on web there is no status bar or nav bar
        else {
            this.statusBarHeight = 0;
            this.navBarHeight = 0;
        }
    };

    private applyTransparentBackgroundToAndroidStatusBar = async () => {
        await StatusBar.setOverlaysWebView({ overlay: true });
        await StatusBar.setStyle({ style: Style.Light });
    };

    // when the status bar state is adjusted while covered by the splash screen, on android 12+
    // it often fails to update itself visually, so this method attempts to nudge it to re-paint
    repaintAndroid = async () => {
        if (this.deviceInfo.platform === 'android') {
            await StatusBar.hide();
            setTimeout(async () => {
                await this.applyTransparentBackgroundToAndroidStatusBar();
                await StatusBar.show();
            }, 1000);
        }
    };

    getHeight = (): number => {
        if (this.statusBarHeight === undefined) {
            throw new Error('Must call init() before getHeight()');
        }
        return this.statusBarHeight!;
    };

    setStyle = async (style: DeviceStatusBarStyle) => {
        if (['ios', 'android'].includes(this.deviceInfo.platform)) {
            const capacitorStyle = {
                light: Style.Light,
                dark: Style.Dark,
                default: Style.Default
            }[style];
            await StatusBar.setStyle({ style: capacitorStyle });
        } else {
            // this isn't relevant on web, so just do nothing
        }
    };
};
