export interface IAnchorButtonProps {
    onClick: React.MouseEventHandler<HTMLAnchorElement>;
    children: any;
    'data-type'?: string;
    noDecoration?: boolean;
}

export const AnchorButton: React.FC<IAnchorButtonProps> = ({ onClick, noDecoration, children, 'data-type': dataType }) => (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a href="" data-type={dataType} style={{textDecoration: (noDecoration ? 'none' : undefined)}} onClick={e => {
        e.preventDefault();
        onClick(e);
    }}>
        {children}
    </a>
);
