import { useEffect } from 'react';
import { createGesture } from '@ionic/react';
import { Keyboard } from '@capacitor/keyboard';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { DrawerState, MAX_HEIGHT } from './DrawerState';

const GRAB_HEIGHT = 85;
const VELOCITY_THRESHOLD = 1.5;

export interface IDrawerHandleProps {
    drawerRef: any;
    onResize?: (e: { oldHeight: number, newHeight: number }) => void;
}

export const DrawerHandle: React.FC<IDrawerHandleProps>= ({ drawerRef, onResize }) => {
    const drawerState = useInstance(DrawerState);

    useEffect(() => {
        const drawer = drawerRef.current;
        const gesture = createGesture({
            el: drawer,
            gestureName: 'drawer-swipe',
            direction: 'y',
            onStart: event => {
                // only react if user grabbed in the top part of the drawer
                if (Math.abs(document.documentElement.clientHeight - event.startY - drawer.clientHeight) < GRAB_HEIGHT) {
                    drawerState.setState({ dragging: true });

                    // user will expect the keyboard to close  when they start moving the drawer
                    Keyboard.hide().catch(() => { });
                }
            },
            onMove: event => {
                if (drawerState.state.dragging) {
                    drawerState.setState({ delta: -event.deltaY });
                }
            },
            onEnd: event => {
                if (drawerState.state.dragging) {
                    drawerState.setState({ dragging: false });

                    const oldHeight = drawerState.state.height;
                    let finalHeight = oldHeight + drawerState.state.delta;

                    // if it was a fast enough swipe, drawer should go all the way up or down
                    if (Math.abs(event.velocityY) > VELOCITY_THRESHOLD) {
                        finalHeight = event.velocityY > 0 ? drawerState.state.minHeight : MAX_HEIGHT;
                    }

                    drawerState.setHeight(finalHeight);

                    if (onResize) {
                        onResize({
                            oldHeight: Math.round(oldHeight),
                            newHeight: Math.round(finalHeight)
                        });
                    }
                }
            }
        });
        gesture.enable(true);
        return () => {
            gesture.destroy();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div style={{ position: 'relative', width: '100%', height: 17, paddingTop: 6, borderTopLeftRadius: 12, borderTopRightRadius: 12, marginBottom: -1, zIndex: 100, backgroundColor: 'white' }}>
            <div style={{ width: 36, height: 5, borderRadius: 8, margin: '0 auto', backgroundColor: '#c0c0be' }} />
        </div>
    );
};
