import { AutoPylotPrincipal } from '../auth/AutoPylotPrincipal';
import { OperatorState } from '../profile/OperatorState';
import { IScreenshotablePage } from '../support/screenshots/IScreenshotablePage';
import { SetupPage, SetupPageState } from './SetupPage';

interface IMyPageProps {
    started: boolean;
    acceptedTerms?: boolean;
    showLoginDialog?: boolean;
}

/**
 * Screenshots readme
 *  - cover all permutations of visible risk
 */

export default class ScreenshotSetupPage implements IScreenshotablePage<IMyPageProps> {

    screenId = 'setup';

    ComponentUnderTest = SetupPage;

    static inject = () => [SetupPageState, OperatorState, AutoPylotPrincipal];
    constructor(private pageState: SetupPageState, private operatorState: OperatorState, private principal: AutoPylotPrincipal) {
    }

    permutationToProps = ({started, acceptedTerms, showLoginDialog}: IMyPageProps) => {
        this.pageState.setState({ started });
        if (acceptedTerms) {
            this.operatorState.setState({
                agreedToTermsOfService: true,
                agreedToFAAPrivacyStatement: true,
                agreedToLAANCStatement: true
            });
        }
        if (showLoginDialog) {
            // must be a guest to show login dialog
            this.principal.email = '';
        }
        return {};
    };

    blockScreenshotTil = async ({ showLoginDialog }: IMyPageProps) => {
        if (showLoginDialog) {
            this.operatorState.ensureLoggedIn({ from: 'Welcome' });
            await new Promise(res => setTimeout(res, 500)); // wait for dialog animation
        }
    };

    permutations: { [key: string]: IMyPageProps } = {
        'welcome': {
            started: false
        },
        'terms': {
            started: true
        },
        'operator': {
            started: true,
            acceptedTerms: true
        },
        'login': {
            started: false,
            showLoginDialog: true
        }
    };
}
