import { useState } from 'react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { Page } from '../components/page/Page';
import { HistoryViewModel } from '../app/HistoryViewModel';
import { usePageView } from '../support/tracking/usePageView';
import { Button } from '../components/button/Button';
import { IonText } from '@ionic/react';
import { CheckmarkIcon } from '../components/icons/Icons';
import { TrackingService } from '../support/tracking/TrackingService';
import { useSubscription } from '@meraki-internal/state';
import { OperatorState } from '../profile/OperatorState';
import { AnchorButton } from '../components/button/AnchorButton';
import { AuthService } from '../auth/AuthService';
import { InsuranceState } from './InsuranceState';
import { InsurancePageStorage } from './InsurancePageStorage';
import { states } from '../support/helpers/states';

const Benefit: React.FC<{text: string}> = ({ text }) => (
    <div style={{marginTop: 15, marginBottom: 15, display: 'flex', alignItems: 'center', gap: 10}}>
        <CheckmarkIcon size="small" color="success" />
        <IonText color="medium">{text}</IonText>
    </div>
);

export const InsuranceUnsupportedStatePage: React.FC = () => {
    usePageView('Insurance Unsupported State');

    const history = useInstance(HistoryViewModel);
    const tracker = useInstance(TrackingService);

    const stateCode = history.getCurrentSearchParams().get('state') || undefined;
    const stateName = states.find(s => s.code === stateCode)?.name || 'Your State';

    const storage = useInstance(InsurancePageStorage);
    const alreadyResponded = Boolean(storage.insuranceNotify.get()?.notifyMe);

    const [notifyMe, setNotifyMe] = useState(false);

    const operatorState = useInstance(OperatorState);
    useSubscription(() => operatorState);
    const isGuest = operatorState.isGuest();

    const insuranceState = useInstance(InsuranceState);
    useSubscription(() => insuranceState);

    const authService = useInstance(AuthService);

    const onClickNotifyMe = () => {
        setNotifyMe(true);
        tracker.track('Insurance Unsupported State Notify Me Selected', () => ({ state: stateCode }));
    };

    const onClickCreateAccount = async () => {
        tracker.track(`Insurance | Create Account`);
        await authService.showLoginDialog();
    };

    const heading = (alreadyResponded ? <>Sorry {stateName}!<br/>We're still working on your state</> : `Coming Soon to ${stateName}!`);
    const subHeading = (alreadyResponded ? 'Easy, affordable insurance coming soon' : 'Easy, Affordable Flight Insurance');

    return (
        <Page title="Coming Soon!" onBack={history.back} onClose={() => history.replace('/')}>
            <div style={{margin: '30px 20px', textAlign: 'center'}}>

                <h3>{heading}</h3>
                <h5>{subHeading}</h5>

                <div style={{maxWidth: 500, margin: '0 auto'}}>
                    <img src="./assets/images/insurance/man-with-drone.png" alt="Man with drone" />
                </div>

                {!alreadyResponded &&
                    <div style={{width: 'fit-content', margin: '0 auto'}}>
                        <Benefit text="Fly any time with a monthly policy" />
                        <Benefit text="Get the best value with an annual policy" />
                        <Benefit text="Flexible liability coverage" />
                    </div>
                }

                <div style={{margin: '40px 10px 0'}}>
                    {!notifyMe &&
                        <Button size="large" expand="block" disabled={notifyMe} onClick={onClickNotifyMe}>
                            Join Waitlist
                        </Button>
                    }
                    {notifyMe &&
                        <p><b>
                            <>You're on the list! </>
                            {!isGuest && <>We'll let you know when insurance is available in your state.</>}
                            {isGuest && <>Please <AnchorButton onClick={onClickCreateAccount}>Create an Account</AnchorButton> so we can let you know when insurance is available in your state.</>}
                        </b></p>
                    }
                </div>

            </div>
        </Page>
    );
};
