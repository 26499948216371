import { useEffect, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Device } from '../../support/Device';
import { useInstance } from '@meraki-internal/react-dependency-injection';

// increment array index, looping back to 0
const getNext = (index: number, length: number) => (index + 1) % length;

const useStyles = createUseStyles({
    wrapper: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100vh',
        overflow: 'hidden',
        background: 'black'
    },
    video: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    overlay: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        background: 'linear-gradient(180deg, rgba(26, 26, 86, 0.15) 0%, rgba(26, 26, 86, 0.6) 100%)'
    }
});

/**
 * Auto-plays a series of videos full screen in a loop, firing onNext(index) between videos.
 * Displays a partially opaque gradient above the video so overlaid text is easier to read.
 * To avoid flicker while loading, consumer must provide both "src" and "poster" urls.
 * The src is the video, the poster is an image of the first frame. Timeout should be set
 * slightly longer than length of longest video to force transition if a video fails to play.
 *
 * Implementation notes:
 * - Using a single <video> tag and changing the src attribute causes a noticeable flicker on ios -
 *   it shows a blank frame between unloading the old video and starting the new one. This happens
 *   even when setting the "poster" attribute to provide an image to show while loading.
 * - BUT using multiple <video> tags in an android production build installed from the Play Store,
 *   the play() method doesn't reliably work - it usually times out with the nonsensical error
 *   "Failed to load because no supported source was found".
 * - So we have two different implementations below, one for ios and the other for android/web.
 */
export const BackgroundVideo: React.FC<{srcs: string[], posters: string[], timeoutMs: number, onNext: (index: number) => void}> = ({ srcs, posters, timeoutMs, onNext }) => {
    const classes = useStyles();

    const { platform } = useInstance(Device);

    const [currentIndex, setCurrentIndex] = useState(0);

    const moveNext = () => {
        const newIndex = getNext(currentIndex, srcs.length);
        setCurrentIndex(newIndex);
        onNext(newIndex);
    };

    const timeout = useRef<NodeJS.Timeout>();

    // force transition to next video after timeout, if a video fails to play fully
    useEffect(() => {
        clearTimeout(timeout.current);
        timeout.current = setTimeout(moveNext, timeoutMs);
    }, [currentIndex]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={classes.wrapper}>
            {platform === 'ios' &&
                <MultiPlayerVideo srcs={srcs} posters={posters} currentIndex={currentIndex} moveNext={moveNext} />
            }
            {platform !== 'ios' &&
                <SinglePlayerVideo srcs={srcs} posters={posters} currentIndex={currentIndex} moveNext={moveNext} />
            }
            <div className={classes.overlay} />
        </div>
    );
};

const MultiPlayerVideo: React.FC<{srcs: string[], posters: string[], currentIndex: number, moveNext: () => void}> = ({ srcs, posters, currentIndex, moveNext }) => {
    const classes = useStyles();

    const refs = useRef<Array<HTMLVideoElement | null>>([]);

    // start next video playing, then show it
    const onEnded = async () => {
        const nextIndex = getNext(currentIndex, srcs.length);
        await refs.current[nextIndex]?.play();
        moveNext();
    };

    return (
        <>
            {srcs.map((src, index) => (
                <video
                    key={`video-${index}`}
                    ref={ref => refs.current[index] = ref}

                    // hide non-active videos by moving off-screen (setting visibility didn't work)
                    style={{left: (index === currentIndex ? 0 : '100vw')}}

                    className={classes.video}
                    src={src}
                    poster={posters[index]}
                    muted
                    playsInline
                    autoPlay={index === 0}
                    onEnded={onEnded}
                />
            ))}
        </>
    );
};

const SinglePlayerVideo: React.FC<{srcs: string[], posters: string[], currentIndex: number, moveNext: () => void}> = ({ srcs, posters, currentIndex, moveNext }) => {
    const classes = useStyles();

    const [poster, setPoster] = useState(posters[currentIndex]);

    useEffect(() => {
        setPoster(posters[currentIndex]);
    }, [currentIndex]); // eslint-disable-line react-hooks/exhaustive-deps

    // switch to next poster when video starts, to avoid old poster flicker at end of video
    const onPlaying = async () => {
        setPoster(posters[getNext(currentIndex, srcs.length)]);
    };

    return (
        <video
            className={classes.video}
            src={srcs[currentIndex]}
            poster={poster}
            muted
            playsInline
            autoPlay={true}
            onPlaying={onPlaying}
            onEnded={moveNext}
        />
    );
};
