import { Group } from '../ComponentLibrary';
import { Button } from './Button';
import { colors, sizes } from '../common';

const DemoButton: Group & React.FC = () => {
    return (
        <div>
            {colors.map(color =>
                <div key={color}>
                    <h3>Color: {color}</h3>
                    {sizes.map(size =>
                        <Button key={size} color={color} size={size}>{size}</Button>
                    )}
                    <Button color={color} size="large" fill="outline">outline</Button>
                    <Button color={color} size="large" disabled>disabled</Button>
                </div>
            )}
        </div>
    );
};

DemoButton.componentName = 'Button';

export default DemoButton;
