import { IScreenshotablePage } from '../support/screenshots/IScreenshotablePage';
import { ChecklistState } from './ChecklistState';
import { ISavedChecklist } from './model';
import { SavedChecklistsPage, SavedChecklistsPageState } from './SavedChecklistsPage';
import { SavedChecklistsState } from './SavedChecklistsState';

interface IMyPageProps {
    checklists?: ISavedChecklist[];
    isSaving?: boolean;
}

export default class ScreenshotSavedChecklistsPage implements IScreenshotablePage<IMyPageProps> {

    static inject = () => [SavedChecklistsState, SavedChecklistsPageState];
    constructor(private checklistsState: SavedChecklistsState, private pageState: SavedChecklistsPageState) { }

    screenId = 'saved-checklists';

    ComponentUnderTest = SavedChecklistsPage;

    permutationToProps = ({ checklists, isSaving }: IMyPageProps) => {
        if (checklists) {
            this.checklistsState.setState({
                loaded: true,
                checklists: checklists.map(c => new ChecklistState<ISavedChecklist>(c))
            });
        }

        if (isSaving) {
            this.pageState.startSaving();
        }

        return {};
    };

    blockScreenshotTil = async () => {

    };

    permutations: { [key: string]: IMyPageProps } = {
        'loading' : {
        },
        'none': {
            checklists: []
        },
        'some': {
            checklists: [
                { name: 'Empty Checklist', sections: [] },
                { name: 'Cell Tower Inspection', sections: [{ id: 'test', label: 'test', items: [
                    { id: '1', name: 'Item 1', completed: false },
                    { id: '2', name: 'Item 2', completed: false }
                ] }] }
            ]
        },
        'adding': {
            isSaving: true
        }
    };
}
