import { ICoordinate } from '@autopylot-internal/tiles-client';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { PreMissionWeatherState } from '../PreMissionWeatherState';
import { useSubscription } from '@meraki-internal/state';
import { useEffect } from 'react';
import { WeatherSummaryDrawerButton } from '../WeatherButton';
import { MissionCreationWeatherModal } from './MissionCreationWeatherModal';

type ISO8601 = string;

interface IPreMissionWeatherInDrawerProps {
    location: ICoordinate;
    startTime: ISO8601;
    endTime: ISO8601;
    header: React.ReactElement;
}

export const PreMissionWeatherInDrawer: React.FC<IPreMissionWeatherInDrawerProps> = ({ location, startTime, endTime, header }) => {
    const state = useInstance(PreMissionWeatherState);
    useSubscription(() => state);

    useEffect(() => {
        state.updateProps({ location, startTime, endTime });
    }, [location, startTime, endTime, state]);

    const weatherSummary = state.getWeatherSummary();

    const buildStatus = () => {
        if (state.fetchWeatherError){
            return state.fetchWeatherError.errorCode || 'error';
        }
        if (weatherSummary){
            return 'loaded';
        }
        return 'loading';
    };
    return (
        <div data-id="weather"
            data-forecast={weatherSummary?.forecastStart}
            data-location={JSON.stringify(weatherSummary?.location)}
            data-status={buildStatus()}
        >
            <WeatherSummaryDrawerButton
                {...state.getWeatherSummaryProps()}
                onClick={state.showWeatherDetails}
            />
            <MissionCreationWeatherModal header={header} />
        </div>
    );
};
