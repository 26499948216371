import * as turf from '@turf/turf';
import { round } from '../support/helpers/format';
import { Drawer } from '../components/drawer/Drawer';
import { DrawerState } from '../components/drawer/DrawerState';
import { useSubscription } from '@meraki-internal/state';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { MissionsState } from '../missions/model/MissionsState';
import { usePrevious } from '../support/hooks/usePrevious';
import { MissionRadiusSlider } from './components/MissionRadiusSlider';
import { MissionReviewAndSubmitPage } from './components/MissionReviewAndSubmitPage';
import { MissionAltitudePage } from './components/MissionAltitudePage';
import { MissionAreaAndTimePage } from './components/MissionAreaAndTimePage';
import { LocationSearchService } from './location/LocationSearchService';
import { ICoordinate } from '../support/model/ICoordinate';
import { useEffect, useState } from 'react';
import { NewMissionState } from '../missions/model/NewMissionState';
import { MapViewModel } from './model/MapViewModel';
import { MissionPinAndRadiusOnMapViewModel } from '../map/mission-pin-and-radius/MissionPinAndRadiusOnMapViewModel';
import { tryAndCatch } from '../support/helpers/tryAndCatch';
import { AlertPresenter } from '../app/AlertBinder';
import { TrackingService } from '../support/tracking/TrackingService';
import { MissionOperatorPage } from './components/MissionOperatorPage';
import { OperatorState } from '../profile/OperatorState';
import { useCached } from '../support/hooks/useCached';
import debounce from 'lodash/debounce';

// global variable used to pass values between events below
let oldCenter: ICoordinate | undefined;

export const NewMissionMapDrawer: React.FC<{ newMission: NewMissionState, onCancel: () => void, initialWizardPage?: number }> = ({ newMission, onCancel, initialWizardPage }) => {
    const alert = useInstance(AlertPresenter);
    const tracker = useInstance(TrackingService);

    const drawerState = useInstance(DrawerState);

    const [wizardPage, setWizardPage] = useState<number>(initialWizardPage || 1);
    const previousPage = usePrevious(wizardPage);

    useSubscription(() => newMission);

    const vm = useInstance(MissionsState);
    useSubscription(() => vm);

    const operatorState = useInstance(OperatorState);
    useSubscription(() => operatorState);

    const mapViewModel = useInstance(MapViewModel);

    const pinAndRadius = useInstance(MissionPinAndRadiusOnMapViewModel);

    const locationSearchService = useInstance(LocationSearchService);

    const alreadyHadFullContactInfo = useCached(() => operatorState.hasFullContactInfo());

    // update mission address when the user moves the pin on the map
    useEffect(() => {
        // only consider a change if mission moves significantly (to ignore jitter that occurs when user moves radius slider)
        const userHasMovedMap = () => {
            const center = pinAndRadius.getRadius()?.properties.center;

            if (!oldCenter || !center) {
                return false;
            }
            return round(oldCenter.lat, 10) !== round(center.lat, 10) || round(oldCenter.lng, 10) !== round(center.lng, 10);
        };

        const reverseLookup = debounce(async () => {

            const center = pinAndRadius.getRadius()?.properties.center;
            if (!center){
                return;
            }

            const location = await locationSearchService.reverseLookup(center);

            // if we got back a location, and the center hasn't changed
            if (location && pinAndRadius.getRadius()?.properties.center === center){
                newMission.setAddress(location.address);

                tracker.trackWithDebounce('Center Set', () => ({
                    center: center,
                    location: location.address,
                    milesMoved: round(turf.distance([oldCenter!.lng, oldCenter!.lat], [center.lng, center.lat], { units: 'miles' }), 2),
                    // we're no longer sending "canFly" b/c it isn't usually ready
                    // and isn't worth coupling this to the code that knows when it is ready
                }));
            }
        }, 500, { leading: false });

        const unSub = pinAndRadius.subscribe(() => {
            if (userHasMovedMap()){
                reverseLookup();
            }

            oldCenter = pinAndRadius.getRadius()?.properties.center;
        });

        return () => {
            unSub();
        };

    }, [locationSearchService, newMission, tracker, pinAndRadius]);

    useEffect(() => {
        drawerState.setHeight([475, 200, 510, 850][wizardPage - 1]);

        if (wizardPage === 1) {
            newMission.resetMaxAltitude();
            if (previousPage === 2) {
                pinAndRadius.resumeMovingMissionPin();
            }
        } else {
            // TODO: ensure we didn't regress on "shouldn't be able to continue while computing the mission"
            pinAndRadius.stopMovingMissionPin();
        }
    }, [wizardPage]); // eslint-disable-line react-hooks/exhaustive-deps

    const onBack = () => {
        if (wizardPage > 1) {
            let newPage = wizardPage - 1;

            // skip operator page if we already had contact info
            if (newPage === 3 && alreadyHadFullContactInfo) {
                newPage--;
            }

            setWizardPage(newPage);

        } else {
            // clear global for next time
            oldCenter = undefined;
            onCancel();
        }
    };

    const onNext = async () => {
        // guest must sign up before creating a mission
        const center = mapViewModel.getCenter();
        if (!operatorState.ensureLoggedIn({ from: 'Create Mission', returnTo: `${window.location.hash.substring(1)}?dropPin=${center.lng},${center.lat}` })) {
            return;
        }

        if (wizardPage < 4) {
            let newPage = wizardPage + 1;

            // skip operator page if we already had contact info
            if (newPage === 3 && alreadyHadFullContactInfo) {
                newPage++;
            }

            setWizardPage(newPage);

        } else {
            await tryAndCatch(newMission.save, alert.showAndLogError);
        }
    };

    const onChangeRadius = async (radiusFeet: number) => {
        try {
            await newMission.setRadiusFeetV2({ radiusFeet });
        } catch (err){
            alert.showAndLogError(err);
        }
    };

    const onChangingRadius = async (radiusFeet: number) => {
        pinAndRadius.adjustRadiusSize({ radiusMeters: radiusFeet * 0.3048 });
    };

    const onResize = ({ oldHeight, newHeight }: { oldHeight: number, newHeight: number }) => {
        // use setWizardPage() to get the current value of wizardPage,
        // but return same value, so there is no state change
        setWizardPage(page => {
            if (page > 0 && newHeight > oldHeight) {
                tracker.track('Flight Checklist Expanded', () => ({ oldHeight, newHeight }));
            }
            return page;
        });
    };

    const onScroll = () => {
        tracker.track('Flight Checklist Scrolled');
    };

    const header = wizardPage !== 1 ? undefined : <MissionRadiusSlider
        value={newMission.getRadiusFeet()}
        isSquare={newMission.pinAndRadius.isSquare() || false}
        onSliderMove={onChangingRadius}
        onSliderMoveEnd={onChangeRadius}
    />;

    return (
        <Drawer minHeight={180} onResize={onResize} onScroll={onScroll} header={header}>
            {wizardPage === 1 &&
                <MissionAreaAndTimePage vm={newMission} onBack={onBack} onContinue={onNext} />
            }

            {wizardPage === 2 &&
                <MissionAltitudePage vm={newMission} onBack={onBack} onContinue={onNext} />
            }

            {wizardPage === 3 &&
                <MissionOperatorPage vm={newMission} onBack={onBack} onContinue={onNext} />
            }

            {wizardPage === 4 &&
                <MissionReviewAndSubmitPage vm={newMission} onBack={onBack} onContinue={onNext} />
            }
        </Drawer>
    );
};
