import { IonAvatar, IonText } from '@ionic/react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useSubscription } from '@meraki-internal/state';
import { Button } from '../components/button/Button';
import { TrackingService } from '../support/tracking/TrackingService';
import { HistoryViewModel } from '../app/HistoryViewModel';
import { OperatorState } from '../profile/OperatorState';
import { AuthService } from '../auth/AuthService';
import { MenuViewModel } from './MenuViewModel';
import { ICertificationType } from '../profile/IOperator';
import { useBreakpoints } from '../support/hooks/useBreakpoints';

interface IHeadingsProps {
    name?: string;
    email?: string;
    certificationType?: ICertificationType;
    isGuest: boolean;
}

const Headings: React.FC<IHeadingsProps> = ({ name, email, certificationType, isGuest }) => {
    const tracker = useInstance(TrackingService);
    const authService = useInstance(AuthService);

    const onCreateAccount = async () => {
        tracker.track(`Nav | Create Account or Sign In`);
        await authService.showLoginDialog();
    };

    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <IonText style={{fontSize: 22, fontWeight: 'bold'}}>{name || (isGuest ? 'Guest' : 'Pilot')}</IonText>
            {isGuest &&
                <div style={{margin: '-12px -19px -10px'}}>
                    <Button fill="clear" onClick={onCreateAccount}>Create Account or Sign In</Button>
                </div>
            }
            {!isGuest &&
                <div style={{width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', paddingTop: 6, paddingBottom: 8}}>
                    <IonText style={{overflowWrap: 'anywhere'}} color="medium">
                        {email}
                    </IonText>
                </div>
            }
            <IonText data-id="menu-cert-type" style={{fontSize: 16, fontWeight: 'bold'}}>{certificationType === 'part-107' ? 'Part 107' : 'Recreational'}</IonText>
        </div>
    );
};

export const MenuHeader: React.FC = () => {
    const history = useInstance(HistoryViewModel);

    const { isLargerScreen } = useBreakpoints();

    const operatorState = useInstance(OperatorState);
    useSubscription(() => operatorState);

    const menuVM = useInstance(MenuViewModel);

    const onClickAvatar = operatorState.isGuest() ? undefined : menuVM.clickHandler(() => {
        history.push('/profile');
    });

    // on desktop, show avatar and left-align headings
    if (isLargerScreen) {
        return (
            <div style={{display: 'flex', alignItems: 'flex-start', gap: 10, padding: '45px 10px 30px'}}>
                <IonAvatar title="Profile" style={{cursor: 'pointer', width: 44, height: 44, border: '2px solid white', background: 'white', filter: 'drop-shadow(0px 3px 5px var(--ion-color-step-200)'}} onClick={onClickAvatar}>
                    <img style={{objectFit: 'contain', width: 40, maxWidth: 40}} src={operatorState.state.pictureUrl || './assets/icon/icon.png'} referrerPolicy="no-referrer" alt="avatar"/>
                </IonAvatar>
                <Headings
                    name={operatorState.getName()}
                    email={operatorState.getEmail()}
                    certificationType={operatorState.state.certificationType}
                    isGuest={operatorState.isGuest()}
                />
            </div>
        );
    }

    // on mobile, no avatar and center-align headings
    return (
        <div style={{textAlign: 'center', padding: '8vh 10px 5vh'}}>
            <Headings
                name={operatorState.getName()}
                email={operatorState.getEmail()}
                certificationType={operatorState.state.certificationType}
                isGuest={operatorState.isGuest()}
            />
        </div>
    );
};
