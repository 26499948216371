import { EnvConfiguration, IEnvironment } from '../../app/config/EnvConfiguration';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { Page } from '../../components/page/Page';
import { IonLabel, IonToggle } from '@ionic/react';
import { Button } from '../../components/button/Button';
import { DevSettings, DevSettingsWriter } from '../DevSettings';
import { PropsList } from './PropsList';
import { HistoryViewModel } from '../../app/HistoryViewModel';
import { Device } from '../Device';
import { AppInfo } from '../AppInfo';
import { Network } from '../Network';
import { useSubscription } from '@meraki-internal/state';
import { DisplayMetricsProvider } from '../DisplayMetricsProvider';
import { IntercomService } from '../../app/intercom/IntercomService';
import { AlertPresenter } from '../../app/AlertBinder';
import { AutopylotLiveUpdatesService } from '../live-updates/AutopylotLiveUpdatesService';
import { ILiveUpdatesService } from '../live-updates/ILiveUpdatesService';
import { Logger } from './Logger';

const nameOverride: { [name: string]: string } = {
    showNearestOperationalAwarenessPosition: 'Show Op Aware Position'
};

type IEnvPlatformCondition = (params: { env: IEnvironment,platform: 'ios' | 'android' | 'web' }) => boolean;

const enableCondition: {
    [booleanKey: string]: IEnvPlatformCondition
} = {
    // require a device, and not local
    useStagingDeployChannel: ({ platform, env }) => env !== 'local' && platform  !== 'web',
    useStaging: ({ env, platform }) => env !== 'local' && platform  !== 'web',
};

const DevSettingsList = () => {
    const settings = useInstance(DevSettings);
    const settingsWriter = useInstance(DevSettingsWriter);
    const alertPresenter = useInstance(AlertPresenter);
    const env = useInstance(EnvConfiguration).ENV;
    const platform = useInstance(Device).platform;

    const onChangeSetting = async (key: string, value: any) => {
        try{
            await settingsWriter.setSetting(key, value);
        }
        catch (err: any){
            // not logging, b/c this is internal only
            alertPresenter.showAlertV2({
                header: 'Failed',
                message: err.message
            });
        }

    };

    return (
        <ul style={{ listStyleType: 'none' }}>
            {Object.keys(settings).filter(key => typeof (settings as any)[key] === 'boolean').map(booleanKey => (
                <li key={booleanKey} style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 4}}>
                    <IonLabel style={{textTransform: 'capitalize'}}>{nameOverride[booleanKey] || booleanKey}</IonLabel>
                    <IonToggle
                        checked={(settings as any)[booleanKey]}
                        onIonChange={e => onChangeSetting(booleanKey, e.detail.checked) }
                        disabled={enableCondition[booleanKey] && !enableCondition[booleanKey]({ env, platform })}
                    />
                </li>
            ))}
        </ul>
    );
};

export const DebugPage: React.FC = () => {
    const history = useInstance(HistoryViewModel);
    const liveUpdatesService = useInstance(AutopylotLiveUpdatesService) as ILiveUpdatesService;
    const config = useInstance(EnvConfiguration);
    const displayMetrics = useInstance(DisplayMetricsProvider).getMetrics();
    const intercomService = useInstance(IntercomService);

    const logger = useInstance(Logger);

    const appInfo = useInstance(AppInfo);
    const deviceInfo = useInstance(Device);
    const network = useInstance(Network);
    useSubscription(() => network);

    const logError = () => {
        logger.error(new Error('button clicked to force error to sentry'), { at: new Date().toISOString() });
    };

    return (
        <Page title="Debug Info" onBack={() => history.back()}>
            <div style={{ padding: 20 }}>

                <h5>Environment</h5>
                <ul>
                    <li>env: {config.ENV}</li>
                    <li>web: {window.location.origin}</li>
                    <li>webSHA: {process.env.REACT_APP_SHA}</li>
                    <li>api: {config.API_BASE_URL}</li>
                    <li>intercom: {intercomService.getEnv()}</li>
                </ul>

                {appInfo &&
                    <>
                        <h5>App Info</h5>
                        <PropsList sorted info={appInfo} />
                    </>
                }

                <h5>Device Info</h5>
                <PropsList sorted info={{
                    ...deviceInfo,
                    screen: `${displayMetrics.screen.width}x${displayMetrics.screen.height}@${displayMetrics.devicePixelRatio}`,
                    viewport: `${displayMetrics.viewport.width}x${displayMetrics.viewport.height}@${displayMetrics.devicePixelRatio}`,
                    statusBarHeight: `${displayMetrics.statusBarHeight}`,
                    navigationBarHeight: `${displayMetrics.navigationBarHeight}`,
                    network: network.isOnline ? 'online': 'offline',
                }} />

                <h5>Dev Settings</h5>
                <DevSettingsList />

                <h5>Other Pages</h5>
                <ul style={{ listStyleType: 'none'}}>
                    <li><Button size="small" fill="outline" href={`${window.location.origin}?screenshots#/screens`}>Screenshot Pages</Button></li>
                    <li><Button size="small" fill="outline" href="#component-library">Component Library</Button></li>
                    <li><Button size="small" fill="outline" href="#/logs">Log Files</Button></li>
                    {liveUpdatesService.showManagerLink() && (
                        <li><Button size="small" fill="outline" href="#/live-updates-manager">Live Updates Manager</Button></li>
                    )}
                    <li><Button size="small" fill="outline" onClick={logError}>Force Sentry Error</Button></li>

                    <li><Button size="small" fill="outline" href="#/debug-paywall">Revenue Cat</Button></li>

                </ul>
            </div>
        </Page>
    );
};
