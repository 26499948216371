import { useSubscription } from '@meraki-internal/state';
import { DrawerFieldText } from '../../components/drawer/DrawerFieldText';
import { DrawerLabel } from '../../components/drawer/DrawerLabel';
import { NewMissionState } from '../../missions/model/NewMissionState';
import { MissionTimesEditor } from '../../missions/components/MissionTimesEditor';
import { FlightChecklist } from '../../flight-checklist/FlightChecklist';
import { Location } from './Location';
import { usePageView } from '../../support/tracking/usePageView';
import { DrawerButtons } from '../../components/drawer/DrawerButtons';
import { Button } from '../../components/button/Button';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { LastAuthorizedRadiusModel } from '../mission-pin-and-radius/LastAuthorizedRadiusModel';
import { FlightChecklistDrawerLabel } from '../../components/drawer/FlightChecklistDrawerLabel';
import { PreMissionWeatherInDrawer } from '../../weather/components/PreMissionWeatherInDrawer';
import { RevenueCatModel } from '../../paywall/revenue-cat/RevenueCatModel';
import { PreMissionWeatherUnlockButton } from '../../weather/components/PreMissionWeatherUnlockButton';

const MissionHeader: React.FC<{vm: NewMissionState}> = ({ vm }) => {
    return (
        <>
            <DrawerLabel text="Location" />
            <DrawerFieldText dataSet={{type: 'location'}}><Location mission={vm} /></DrawerFieldText>

            <MissionTimesEditor mission={vm} source='Mission Planning Weather' />
        </>
    );
};

export const MissionAreaAndTimePage: React.FC<{vm: NewMissionState, onBack: () => void, onContinue: () => void}> = ({ vm, onBack, onContinue }) => {
    usePageView('Mission Area and Time');

    useSubscription(() => vm);
    const lastAuthorizedModel = useInstance(LastAuthorizedRadiusModel);
    useSubscription(() => lastAuthorizedModel);

    const missionRadius = vm.getMissionRadius();

    const isReady = Boolean(missionRadius || lastAuthorizedModel.lastAuthorizedRadius);
    const disabled = (!missionRadius || missionRadius.canFly() === 'cannot-fly');

    const revenueCat = useInstance(RevenueCatModel);
    useSubscription(() => revenueCat);

    const hasPreMissionWeather = revenueCat.hasUpgraded();

    const mapCenter = vm.mapViewModel.getCenter();

    return (
        <div data-id="mission-time-drawer">
            <DrawerLabel text="Location" />
            <DrawerFieldText dataSet={{type: 'location'}}><Location mission={vm} /></DrawerFieldText>

            {vm.getLocation() &&
                <>
                    <MissionTimesEditor mission={vm} source='Mission Area and Time' />

                    <div style={{position: 'relative', marginTop: -3, zIndex: 2}}>
                        <DrawerLabel text="Weather" />
                    </div>
                    <div style={{marginTop: -10, height: 42}}>
                        {!hasPreMissionWeather &&
                            <PreMissionWeatherUnlockButton
                                returnTo={`/map?dropPin=${mapCenter.lng},${mapCenter.lat}`}
                            />
                        }
                        {hasPreMissionWeather &&
                            <PreMissionWeatherInDrawer
                                location={vm.getLocation()!}
                                startTime={vm.getStartTime()}
                                endTime={vm.getEndTime()}
                                header={<MissionHeader vm={vm} />}
                            />
                        }
                    </div>
                </>
            }

            {isReady && (
                <>
                    <FlightChecklistDrawerLabel />
                    <div style={{ opacity: !missionRadius ? .5 : 1 }}>
                        <FlightChecklist mission={vm} />
                    </div>
                </>
            )}

            <DrawerButtons>
                <Button color="secondary" fill="outline" dataType="cancel-button" onClick={onBack}>Cancel</Button>
                <Button dataType="continue-button" disabled={disabled} onClick={onContinue}>Continue</Button>
            </DrawerButtons>

        </div>
    );
};
