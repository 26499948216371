import { Haptics as CapacitorHaptics, ImpactStyle, NotificationType } from '@capacitor/haptics';

export class Haptics {
    impact = () => {
        try{
            CapacitorHaptics.impact({ style: ImpactStyle.Light });
        }
        catch(err){
            // fail gracefully
        }
    };

    notification = ({type }: { type: NotificationType }) => {
        try{
            CapacitorHaptics.notification({ type });
        }
        catch(err){
            // fail gracefully
        }
    };
};

export { NotificationType };
