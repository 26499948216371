const parsedAt = window.performance.now();

export class AppStartTimings {
    private timings: { name: string; sinceParse: number }[] = [];
    add = (name: string) => {
        this.timings.push({
            name,
            sinceParse: Math.round(window.performance.now() - parsedAt)
        });
    };

    get = (name: string): number | undefined => {
        return this.timings.find(t => t.name === name)?.sinceParse;
    };

    getAll = () => this.timings.slice(0);

    static singleton = new AppStartTimings();
}
