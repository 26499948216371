import { useEffect, useState } from 'react';
import { FileSystemLogger } from './FileSystemLogger';
import { Button } from '../../components/button/Button';
import { Share } from '@capacitor/share';
import { StandalonePage } from '../../app/StandalonePage';
import { AlertPresenter } from '../../app/AlertBinder';
import { FileUploadClient } from './FileUploadClient';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { downloadTextFile } from '../helpers/download';

export const LogsPage: React.FC = () => {
    const uploader = useInstance(FileUploadClient);
    const alert = useInstance(AlertPresenter);
    const [logs, setLogs] = useState<any[]>([]);

    const filesystemLogger = FileSystemLogger.getSingleton();

    const refreshLogs = async () => {
        const files = await filesystemLogger.getLogFiles();
        setLogs(files.map((file) => ({
            ...file,
            delete: async () => {
                alert.showAlert({
                    header: 'Delete File',
                    message: 'Permanently delete log file?',
                    buttons: [ 'Cancel', { text: 'Delete', handler: async () => {
                        await file.remove();
                        await refreshLogs();
                    } } ]
                });
            },
            export: async () => {
                try {
                    await Share.share({ url: file.uri });
                } catch(e: any) {
                    if (e.message === 'Share API not available in this browser') {
                        const content = await file.read();
                        downloadTextFile({ filename: file.name, content });
                    } else {
                        // the share api throws in normal situations, such as the user canceling,
                        // so ignore all other errors here
                    }
                }
            },
            upload: async () => {
                const logData = await file.read();
                await uploader.uploadLogFile({ filename: file.name, logData });
            }
        })));
    };

    const formatBytes = (b: number) => {
        const config: any[] = (b < 1000 ? [1, 'B'] : b < 1000000 ? [1000, 'KB'] : [1000000, 'MB']);
        return `${Math.round(b/config[0]).toLocaleString()} ${config[1]}`;
    };

    useEffect(() => {
        refreshLogs();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <StandalonePage>
            <h2>Log Files</h2>

            <div style={{marginBottom: 20}}>
                {logs.map(file => (
                    <div key={file.name} style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                        <a href={file.uri} onClick={async (e: any) => {
                            e.preventDefault();
                            await file.export();
                        }}>{file.name}</a>
                        {formatBytes(file.size)}
                        <Button size="small" minWidth={0} color="success" onClick={file.upload}>Upload</Button>
                        <Button size="small" minWidth={0} color="danger" onClick={file.delete}>Delete</Button>
                    </div>
                ))}
            </div>

            <Button size="small" minWidth={0} onClick={refreshLogs}>Refresh</Button>

        </StandalonePage>
    );
};
