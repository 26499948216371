import { useInstance } from '@meraki-internal/react-dependency-injection';
import { HistoryViewModel } from '../app/HistoryViewModel';
import { Page } from '../components/page/Page';

export const InsuranceErrorPage: React.FC = () => {
    const history = useInstance(HistoryViewModel);
    return (
        <Page title="Insurance" onBack={() => history.replace('/')}>
            <div style={{margin: 20}}>
                <p>We're sorry, insurance is currently unavailable. Please try again later.</p>
            </div>
        </Page>
    );
};
