import { StorageProvider } from '../StorageProvider';
import { FileSystemLogger } from './FileSystemLogger';
import { FileUploadClient } from './FileUploadClient';

const INITIAL_UPLOAD_DELAY_MS = (20 * 1000);
const UPLOAD_INTERVAL_MS = (15 * 60 * 1000);
const FILES_TO_KEEP = 5;

class LogFileUploaderStorage {
    static inject = () => [StorageProvider];
    constructor(private storage: StorageProvider){}
    lastFileUploadedName = this.storage.getStringProvider('ap:last-log-upload', { envNeutral: true, userNeutral: true, storageType: 'localStorage' });
}

export class LogFileUploader {

    private logger = FileSystemLogger.getSingleton();

    static inject = () => [FileUploadClient, LogFileUploaderStorage];
    constructor(private client: FileUploadClient, private storageV2: LogFileUploaderStorage) {}

    upload = async () => {
        let lastFileUploaded: string | undefined;
        if (this.storageV2.lastFileUploadedName.exists()) {
            lastFileUploaded = this.storageV2.lastFileUploadedName.get();
        }

        const files = await this.logger.getLogFiles();
        let filesToDelete = files.length - FILES_TO_KEEP;

        // assume files are sorted so we process more recent last
        for (const file of files) {

            if (lastFileUploaded && file.name.localeCompare(lastFileUploaded) < 0) {
                // already fully uploaded
                if (filesToDelete > 0) {
                    console.log(`Deleting log file ${file.name}`);
                    await file.remove();
                    filesToDelete--;
                }
            } else {
                // not uploaded (or was the most recent and may have been appended)
                console.log(`Uploading log file ${file.name} to s3`);
                const logData = await file.read();
                await this.client.uploadLogFile({ filename: file.name, logData });

                this.storageV2.lastFileUploadedName.set(file.name);
            }
        }
    };

    start = async () => {
        // perform initial upload after a few seconds (to allow time for startup logs to flush)
        setTimeout(this.upload, INITIAL_UPLOAD_DELAY_MS);

        // continue uploading every few minutes
        setInterval(this.upload, UPLOAD_INTERVAL_MS);
    };
}
