import { CardFooter } from '../../components/card/CardFooter';
import { EditableMissionState } from '../../mission/model/EditableMissionState';

export const MissionListItemFooter: React.FC<{mission: EditableMissionState}> = ({ mission }) => {

    const reminderCount = mission.getReminderCount();
    const isCanceled = mission.getMissionStatus() === 'user-canceled';

    if (mission.isNotValid()) {
        let message: string;
        if (mission.getAPLAANCStatus() === 'authorizing-failed') {
            message = (isCanceled || mission.hasEnded() ? 'Not authorized.' : 'Not authorized to fly. Please cancel.');
        } else if (isCanceled) {
            message = 'Authorization not valid. Mission canceled per FAA.';
        } else if (mission.hasEnded()) {
            message = 'Authorization not valid. Mission not canceled before it ended.';
        } else if (mission.wasUncontrolled()) {
            message = 'No longer allowed to fly mission.';
        } else {
            message = 'Not authorized to fly. Please cancel immediately.';
        }
        return (
            <CardFooter
                message={message}
                dataType="invalid-mission-warning"
                color="danger"
            />
        );
    }

    if (reminderCount > 0) {
        return (
            <CardFooter
                message={`Reminder${reminderCount > 1 ? 's' : ''} Due`}
                note={mission.hasEnded() ? 'Past Mission' : ''}
                dataType='reminder-warning'
                color="danger"
            />
        );
    }

    if (!isCanceled && !mission.hasEnded() && mission.getCanFly() === 'can-fly-with-laanc-authorization') {
        return (
            <CardFooter
                message="LAANC Pending"
                dataType="pending-mission-warning"
                color="warning"
            />
        );
    }

    if (mission.hasLAANC() && mission.getCanFly() === 'can-fly') {
        return (
            <CardFooter
                message="LAANC Authorized"
                dataType="authorized-mission-footer"
                color="success"
            />
        );
    }

    return <CardFooter />;
};
