import { useInstance } from '@meraki-internal/react-dependency-injection';
import { ReactNode, useRef } from 'react';
import { useSubscription } from '@meraki-internal/state';
import { ChartJSProvider } from './ChartJSProvider';

export const ChartTooltip: React.FC<{children: ReactNode}> = ({ children }) => {
    const chartJSProvider = useInstance(ChartJSProvider);

    if (!chartJSProvider.activeElementObserver){
        throw new Error('<ChartTooltip> was rendered before ChartJSProvider was set');
    }
    useSubscription(() => chartJSProvider.activeElementObserver!);

    const parentRef = useRef<HTMLDivElement>(null);

    // wide enough to fit longest metric
    // fixed so we can calculate its center
    const width = 135;
    const lineWidth = 8;
    const dotWidth = 10;

    // used to have a common height between parent container (that pushed the chart down)
    // and the abosuletely positioned tooltip (which moves left to right to match the active element in the chart)
    const height = 70;

    // use a ref so we remember the position when mouse leaves chart on desktop
    const coords = useRef<{x: number, y: number}>();

    if (chartJSProvider.activeElementObserver!.state.active) {
        const { x, y } = chartJSProvider.activeElementObserver!.state.active;
        coords.current = { x, y };
    }

    // follow the active point, but don't go outside chart bounds
    const getTooltipLeft = () => {
        if (!coords.current) {
            return undefined;
        }
        let newLeft = coords.current.x - (width / 2);
        if (newLeft < 0) {
            newLeft = 0;
        }
        if (parentRef.current) {
            const maxLeft = parentRef.current.clientWidth - width;
            if (maxLeft > 0 && newLeft > maxLeft) {
                newLeft = maxLeft;
            }
        }
        return newLeft;
    };

    // always render (to reserve space) but hide until we know what and where
    const opacity = (coords.current !== undefined ? 1 : 0);

    return (
        <div ref={parentRef} style={{ height, position: 'relative', pointerEvents: 'none', opacity, transition: 'opacity .25s ease-in-out' }}>

            <div style={{
                width,
                height,
                backgroundColor: `rgba(var(--ion-color-medium-rgb), 0.1)`,
                position: 'absolute',
                left: getTooltipLeft(),
                color: 'var(--ion-color-medium)',
                borderRadius: 10,
                padding: 7,
                marginLeft: 5
            }}>
                {children}
            </div>

            <div style={{
                position: 'absolute',
                left: coords.current ? coords.current.x - (lineWidth / 2) : undefined,
                top: height,
                height: coords.current ? coords.current.y : undefined,
                width: lineWidth,
                backgroundColor: `rgba(var(--ion-color-medium-rgb), 0.1)`,
            }} />

            {chartJSProvider.getChartType() === 'line' &&
                <div style={{
                    position: 'absolute',
                    left: coords.current ? coords.current.x - (dotWidth / 2) : undefined,
                    top: coords.current ? coords.current.y + height - (dotWidth / 2) : undefined,
                    width: dotWidth,
                    height: dotWidth,
                    borderRadius: (dotWidth / 2),
                    backgroundColor: '#2238FF', // match active bar in bar chart
                }} />
            }

        </div>
    );
};
