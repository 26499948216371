import { useEffect, useState } from 'react';
import { isAboveBreakpoint, isBelowBreakpoint, isLargerScreen } from '../helpers/breakpoints';

export const useBreakpoints = () => {

    const [, setSizeChangeCount] = useState(0);

    useEffect(() => {
        const increment = () => setSizeChangeCount(c => c + 1);
        window.addEventListener('resize', increment);
        return () => window.removeEventListener('resize', increment);
    }, []);

    return {
        isAboveBreakpoint,
        isBelowBreakpoint,
        isLargerScreen: isLargerScreen()
    };
};
