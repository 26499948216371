import { IonText } from '@ionic/react';

export const DrawerFieldText: React.FC<{dataSet?: {[k: string]: string}}> = ({ children, dataSet = {} }) => {
    const dataAttributes = Object.keys(dataSet).reduce((map, k) => ({...map, [`data-${k}`]: dataSet[k]}), {});
    return (
        <IonText>
            <h5 {...dataAttributes} style={{marginTop: 3, marginBottom: 10}}>{children}</h5>
        </IonText>
    );
};
