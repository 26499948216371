import { Preferences } from '@capacitor/preferences';
import { Device } from '../../support/Device';
import { UserFacingError } from '../../support/UserFacingError';
import { IIntercomEnv } from './IntercomAdapter';

interface IIntercomNativeEnvProvider {
    'intercom-app-id': string;
    'intercom-ios-api-key': string;
    'intercom-android-api-key': string;
}

const intercomNativeStagingConfig: IIntercomNativeEnvProvider = {
    // looking for prod config? capacitor.config.json
    'intercom-app-id': 'lu5y4u1q',
    'intercom-ios-api-key': 'ios_sdk-5d77d0112828ef340b6d3bb9f86b2ff15b0f3261',
    'intercom-android-api-key': 'android_sdk-14d1af80f269db6598b7b8444a4a7f3be5bf021c',
};

export class IntercomNativeEnvProvider {
    static inject = () => [Device];
    constructor(private deviceInfo: Device){}

    private env!: IIntercomEnv | undefined;

    /**
     * The native intercom env is usually going to be prod, with config from the build, from capacitor.config.json.
     *
     * We override this for staging, and we detect this by looking at Preferences early in the app lifecycle to see
     * what they were when the app started.
     *
     * This does some edgy cases though
     * 1. What if a live update happens, causing or web view to restart, even though the app has not
     * 2. What if we call window.location.reload() in our code
     * 3. What if we do an auth redirect
     *
     * These are especially edge case, in that it would only matter IF the preferences were changed, and when we change them we
     * block the user from continuing (forcing them to restart)
     *
     * So we think this solution is enough, but if we find it is not, we would want to add more java/ swift code
     *
     * Where Java writes to preferences, eg Preferences.get({ key: 'started-with:intercom-app-id' });
     */
    init = async () => {
        this.env = 'intercom-prod';
        if (['ios', 'android'].includes(this.deviceInfo.platform)){
            const { value: appId } = await Preferences.get({
                key: 'intercom-app-id',
            });
            if (appId && appId === intercomNativeStagingConfig['intercom-app-id']){
                this.env = 'intercom-staging';
            }
            else if (appId){
                await this.revertBackToBuildSettings();
                // only developers should see this, and not likely to see if other then when this story was authored where we had prod config
                // when that was temporarily supported
                throw new Error(`you had intercom-app-id ${appId} which is not expected. We removed that to revert back to what is in the build. You need to restart the app.`);
            }
        }
    };

    setEnv = async (env: 'intercom-staging' | 'intercom-prod' ): Promise<void> => {
        if (!['ios', 'android'].includes(this.deviceInfo.platform)){
            throw new UserFacingError({
                errorCode: 'cannot-switch-intercom-env',
                displayMessage: '',
                message: `cannot change intercom env in ${this.deviceInfo.platform}`
            });
        }
        if (env === 'intercom-staging'){
            await Preferences.set({
                key: 'intercom-app-id',
                value: intercomNativeStagingConfig['intercom-app-id'],
            });

            await Preferences.set({
                key: 'intercom-ios-api-key',
                value: intercomNativeStagingConfig['intercom-ios-api-key'],
            });

            await Preferences.set({
                key: 'intercom-android-api-key',
                value: intercomNativeStagingConfig['intercom-android-api-key'],
            });
        } else {
            await this.revertBackToBuildSettings();
        }
    };

    revertBackToBuildSettings = async () => {
        await Preferences.remove({ key: 'intercom-app-id' });
        await Preferences.remove({ key: 'intercom-ios-api-key' });
        await Preferences.remove({ key: 'intercom-android-api-key' });
    };

    getEnv = (): IIntercomEnv => {
        if (!this.env){
            throw new Error('IntercomNativeEnvProvider.getEnv was called before IntercomNativeEnvProvider.init');
        }
        return this.env!;
    };
}
