import { AuthorizableLAANCAdvisoryProperties, MissionFeatureCollectionFacadeAdapter } from '@autopylot-internal/tiles-client';
import moment from 'moment';
import { MissionsState } from '../missions/model/MissionsState';
import { OperatorState } from '../profile/OperatorState';
import { getWellKnownLocation } from '../support/screenshots/getWellKnownLocation';
import { IScreenshotablePage } from '../support/screenshots/IScreenshotablePage';
import { MissionAuthorizationDetailsPage } from './MissionAuthorizationDetailsPage';
import { IMission } from './model/EditableMissionState';

interface IMyPageProps {
    isLicensed: boolean;
    authorizationCount?: number;
}
export default class ScreenshotMissionAuthorizationDetailsPage implements IScreenshotablePage<IMyPageProps> {
    static inject = () => [
        MissionsState,
        MissionFeatureCollectionFacadeAdapter,
        OperatorState
    ];
    constructor(
        private missions: MissionsState,
        private mfcAdapter: MissionFeatureCollectionFacadeAdapter,
        private operatorState: OperatorState
    ) { }
    screenId = 'mission-details-authorization';

    ComponentUnderTest = MissionAuthorizationDetailsPage;

    permutationToProps = ({ isLicensed, authorizationCount = 1 }: IMyPageProps) => {
        this.operatorState.setState({
            firstName: 'Bob',
            lastName: 'Bobsalot',
            phone: '1234567890',
        });

        const missionId = String(Date.now());

        const durationMinutes = 60;
        const startTime = moment().startOf('h').add(2, 'h').toISOString();
        const endTime = moment(startTime).add(durationMinutes, 'm').toISOString();

        const location = getWellKnownLocation('laanc-aa');

        const address = location.address;
        const radiusPolygon = location.radiusPolygon;
        radiusPolygon.features[0].properties.startTime = startTime;
        radiusPolygon.features[0].properties.endTime = endTime;
        radiusPolygon.features[0].properties.isLicensed = true;

        const laancAdvisory = radiusPolygon.features.find((f: any) => f.properties.source === 'uasfm+authorizable');
        laancAdvisory.properties.operationID = 'G8R3M9S20';
        laancAdvisory.properties.laancStatus = 'authorized';
        laancAdvisory.properties.startTime = startTime;
        laancAdvisory.properties.endTime = endTime;
        laancAdvisory.properties.isLicensed = isLicensed;

        if (authorizationCount > 1){
            for (let i = 2; i <= authorizationCount; i++){
                const advisoryCopy = JSON.parse(JSON.stringify(laancAdvisory));
                advisoryCopy.properties.operationID = advisoryCopy.properties.operationID.substring(0, 8) + String(i-1);
                advisoryCopy.properties.airspaceIdent = `RA${i}`;
                advisoryCopy.properties = new AuthorizableLAANCAdvisoryProperties(advisoryCopy.properties, { initializedSource: 'user-mission-creation'});
                radiusPolygon.features.push(advisoryCopy);
            }
        }

        const missionParams: IMission = {
            missionId,
            startTime,
            status: 'active',
            maxAltitude: 400,
            durationMinutes,
            radiusPolygon: this.mfcAdapter.adaptFromPersisted(radiusPolygon),
            address: address!,
            links: {},
            version: 1
        };

        this.missions.addMissionToState(missionParams);

        return { missionId };
    };

    permutations: { [permutationId: string]: IMyPageProps; } = {
        'recreational-authorization': {
            isLicensed: false
        },
        'more-than-one-authorization': {
            isLicensed: false,
            authorizationCount: 3
        },
        'licensed-authorization': {
            isLicensed: true
        }
    };
}
