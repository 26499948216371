import { APIClient } from '@autopylot-internal/autopylot-api-client';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useRef } from 'react';

const ONE_MIN_MS = 60 * 1000;

export const DocumentLink: React.FC<{doc: any}> = ({ doc, children }) => {
    const apiClient = useInstance(APIClient);

    const url = useRef(doc.download_url);
    const loadedAt = useRef(Date.now());

    const onClick = async (e: any) => {
        e.preventDefault();

        // the download url expires after 10 mins, so if
        // 5 mins has elapsed, get a new url
        if (Date.now() - loadedAt.current > (5 * ONE_MIN_MS)) {
            const { download_url } = await apiClient.get(doc);
            url.current = download_url;
            loadedAt.current = Date.now();
        }

        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url.current;
        document.body.appendChild(a);
        a.click();
        a.remove();
    };

    return (
        /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
        <a href="#" onClick={onClick}>{children}</a>
    );
};
