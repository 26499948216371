import { IDCFRZAdvisoryProperties } from '@autopylot-internal/tiles-client';
import { SecondaryWarningAdvisory } from '../SecondaryAdvisory';

export const DCFRZAdvisory = (props: IDCFRZAdvisoryProperties) => {
    return (
        <SecondaryWarningAdvisory header="National Security Warning" advisoryType={props.advisoryType} GLOBAL_ID={props.GLOBAL_ID}>
            Flights are strictly prohibited in National Security Restricted Flights Zones. Your mission overlaps <strong>DC FRZ</strong>.
        </SecondaryWarningAdvisory>
    );
};
