import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useEffect } from 'react';
import { ITrackingPage, ITrackingContext, TrackingService } from './TrackingService';

export const usePageView = (pageName: ITrackingPage, getContext?: () => ITrackingContext) => {
    const tracker = useInstance(TrackingService);
    useEffect(() => {
        tracker.startPageView(pageName, getContext);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

};
