import { AdvisoryInfoIcon } from '../../components/icons/Icons';
import { SecondaryAdvisory } from '../SecondaryAdvisory';

export const NoTFRAdvisory = () => {
    return (
        <SecondaryAdvisory header="Temporary Flight Restrictions" advisoryType='advise' icon={AdvisoryInfoIcon} iconColor="medium">
            Your mission does not overlap any Temporary Flight Restrictions (TFRs) for this timeframe. For a list of all active and upcoming TFRs, visit <a href="https://tfr.faa.gov/tfr2/list.html" target="_blank">https://tfr.faa.gov/tfr2/list.html</a>.
        </SecondaryAdvisory>
    );
};
