import { State } from '@meraki-internal/state';
import { ICoordinate } from '@autopylot-internal/tiles-client';
import { APIClient } from '@autopylot-internal/autopylot-api-client';
import debounce from 'lodash/debounce';
import { IHourlyForecast } from './model/IHourlyForecast';
import { LocationKeyBuilder } from './LocationKeyBuilder';
import { Logger } from '../support/debug/Logger';
import { CachingWeatherService } from './CachingWeatherService';
import { ISummaryForecast } from './model/ISummaryForecast';
import moment from 'moment';
import { SummaryForecastBuilder } from './SummaryForecastBuilder';
import { getTimezone } from '../support/helpers/format';
import { TrackingService } from '../support/tracking/TrackingService';
import { HourlyWeatherChartViewModel } from './chart/HourlyWeatherChartViewModel';

type ISO8601 = string;

export class PreMissionWeatherState extends State<Record<string, never>> {
    static inject = () => [
        APIClient,
        LocationKeyBuilder,
        Logger,
        CachingWeatherService,
        SummaryForecastBuilder,
        HourlyWeatherChartViewModel,
        TrackingService
    ];
    constructor(
        private api: APIClient,
        private locationKeyBuilder: LocationKeyBuilder,
        private logger: Logger,
        private weatherService: CachingWeatherService,
        private summaryForecastBuilder: SummaryForecastBuilder,
        private chartState: HourlyWeatherChartViewModel,
        private tracker: TrackingService
    ) {
        super({});
    }

    isLoading = false;

    isViewingWeatherDetails = false;

    weatherFor?: {
        location: ICoordinate;
        startTime: ISO8601;
        endTime: ISO8601;
    };

    chartActiveTime?: ISO8601;

    forecast?: IHourlyForecast[];
    fetchWeatherError?: Error & { errorCode?: string; displayMessage?: string; };

    lastKnownWeather?: {
        weatherFor: {
            location: ICoordinate;
            startTime: ISO8601;
            endTime: ISO8601;
        };
        forecast?: IHourlyForecast[];
        summary?: ISummaryForecast;
        fetchWeatherError?: Error & { errorCode?: string; displayMessage?: string; };
    };

    private entryResouce: any;

    init = async () => {
        this.entryResouce = await this.api.entry();
    };

    updateProps = (props: { location: ICoordinate; startTime: ISO8601; endTime: ISO8601; }) => {

        this.isLoading = true;

        if (this.forecast || this.fetchWeatherError) {
            this.lastKnownWeather = {
                weatherFor: this.weatherFor!,
                fetchWeatherError: this.fetchWeatherError,
                forecast: this.forecast,
                summary: this.getWeatherSummary()
            };
        }

        this.weatherFor = props;

        this.maybeFetchWeather();
    };

    private didTimeChangeFromLastKnownWeather = () => {
        const isSame = this.weatherFor && this.lastKnownWeather && this.weatherFor.startTime === this.lastKnownWeather.weatherFor.startTime && this.weatherFor.endTime === this.lastKnownWeather.weatherFor.endTime;
        return !isSame;
    };

    private isLastKnownWeatherTooFarOut = () => {
        return this.lastKnownWeather && this.lastKnownWeather.fetchWeatherError && this.lastKnownWeather.fetchWeatherError.errorCode === 'forecast.unavailable';
    };

    private didLocationChangeEnoughToFetchWeather = () => {
        if (this.lastKnownWeather && this.lastKnownWeather.weatherFor && this.weatherFor) {
            const oldLocationKey = this.buildLocationKey(this.lastKnownWeather.weatherFor.location);
            const newLocationKey = this.buildLocationKey(this.weatherFor.location);
            return oldLocationKey !== newLocationKey;
        }
        return true;
    };

    private buildLocationKey = (location: ICoordinate): string => {
        return this.locationKeyBuilder.build(location);
    };

    private maybeFetchWeather = () => {
        // if weather doesn't exist, and the time didn't change
        // then it still won't exist
        if (!this.didTimeChangeFromLastKnownWeather() && this.isLastKnownWeatherTooFarOut()) {
            // reuse the error, it is going to be the same
            this.fetchWeatherError = this.lastKnownWeather!.fetchWeatherError;
            this.setState({});
        }
        else if (!this.didTimeChangeFromLastKnownWeather() && !this.didLocationChangeEnoughToFetchWeather()) {
            // reuse the weather, it is going to be the same
            this.forecast = this.lastKnownWeather!.forecast;
            this.setState({});
        }
        else {
            this.forecast = undefined;
            this.fetchWeatherError = undefined;
            this.setState({});

            this.fetchWeather();
        }
    };

    private fetchWeather = debounce(async () => {
        if (!this.weatherFor) {
            return;
        }

        const entry = await this.api.entry();

        const props = this.weatherFor;

        try {
            const weather = await this.weatherService.getHourlyForecast({
                link: entry.links.weatherHourly,
                ...props
            });
            this.updateWeather(weather);
        } catch (err: any) {
            if (err && err.errorCode !== 'forecast.unavailable'){
                this.logger.error(`fetchWeather failed with error ${err.toString()}`);
            }

            this.fetchWeatherError = err;

            this.setState({});
        }
    }, 500, { leading: false });

    private updateWeather = (weather: IHourlyForecast[]) => {
        if (!this.weatherFor) {
            return;
        }

        this.forecast = weather;

        this.isLoading = false;
        this.setState({});
    };

    showWeatherDetails = () => {
        this.isViewingWeatherDetails = true;
        this.setState({});
    };

    hideWeatherDetails = () => {
        this.isViewingWeatherDetails = false;
        this.setState({});
    };

    reset = () => {
        this.fetchWeatherError = undefined;
        this.forecast = undefined;
        this.lastKnownWeather = undefined;
        this.weatherFor = undefined;
        this.chartActiveTime = undefined;
        this.setState({});
    };

    getDailyLink = (): { href: string; } => {
        return this.entryResouce.links.weatherDaily;
    };

    getHourlyLink = (): { href: string; } => {
        return this.entryResouce.links.weatherHourly;
    };

    getChartLink = (): { href: string } => {
        return this.entryResouce.links.weatherChart;
    };

    getWeatherSummary = (): ISummaryForecast | undefined => {
        if (!this.weatherFor){
            return;
        }
        const forecastTarget = moment(this.weatherFor.startTime).startOf('hour').toISOString().replace('.000', '');
        const weather = (this.forecast || []).find(f => f.forecastStart === forecastTarget);
        return this.summaryForecastBuilder.build(weather);
    };

    getWeatherSummaryProps = (): { forecast?: ISummaryForecast; forecastError?: any; isLoading: boolean; } => {
        const summary = this.getWeatherSummary();
        if(summary || this.fetchWeatherError){
            return {
                forecast: summary,
                forecastError: this.fetchWeatherError,
                isLoading: false
            };
        }

        return {
            forecast: this.lastKnownWeather?.summary,
            forecastError: this.lastKnownWeather?.fetchWeatherError,
            isLoading: true
        };
    };

    getWeatherHourlyProps = (): { forecast?: IHourlyForecast[]; forecastError?: any; isLoading: boolean; startTime?: ISO8601; endTime?: ISO8601; onHourClick: (hour: ISO8601) => void } => {
        if (this.forecast){
            return {
                startTime: this.weatherFor?.startTime,
                endTime: this.weatherFor?.endTime,
                forecast: this.forecast,
                forecastError: this.fetchWeatherError,
                isLoading: false,
                onHourClick: this.onHourClick
            };
        }

        return {
            startTime: this.lastKnownWeather?.weatherFor?.startTime,
            endTime: this.lastKnownWeather?.weatherFor?.endTime,
            forecast: this.lastKnownWeather?.forecast,
            forecastError: this.lastKnownWeather?.fetchWeatherError,
            isLoading: true,
            onHourClick: this.onHourClick
        };
    };

    onHourClick = (hour: ISO8601) => {
        this.chartActiveTime = moment(hour).toISOString();
        this.setState({});

        this.tracker.track('Mission Planning Weather Chart Opened', () => ({
            from: 'HourlyForecastCard',
            startingAt: this.chartActiveTime,
            coordinates: this.weatherFor?.location
        }));
    };

    onDayClick = (day: ISO8601) => {
        const tz = getTimezone(this.weatherFor!.location);
        this.chartActiveTime = moment.tz(day, tz).startOf('d').add(12, 'h').toISOString();
        this.setState({});

        this.tracker.track('Mission Planning Weather Chart Opened', () => ({
            from: 'DailyForecastCard',
            startingAt: this.chartActiveTime,
            coordinates: this.weatherFor?.location
        }));
    };

    closeChart = () => {
        this.chartActiveTime = undefined;
        this.setState({});

        this.tracker.track('Weather Chart Closed', this.chartState.getTrackingMeta);
    };
}
