import { useEffect } from 'react';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { HistoryViewModel } from './HistoryViewModel';
import { useInstance } from '@meraki-internal/react-dependency-injection';

export const AppUrlListener: React.FC<any> = () => {
    const history = useInstance(HistoryViewModel);

    useEffect(() => {
        App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
            // example url: https://app.autopylot.io/#/missions, path = #/missions
            const path = event.url.split('.autopylot.io/').pop();
            if (path) {
                history.push(path);
            }
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return null;
};
