import { PURCHASES_ERROR_CODE, PurchasesError } from '@revenuecat/purchases-capacitor';

export class RevenueCatSDKPurchaseError extends Error {
    constructor({ message, cancelled = false, status, errorCode }: { message: string; cancelled?: boolean; status?: 400 | 500, errorCode?: string }){
        super(message);
        this.cancelled = cancelled;
        this.status = status || 500;
        this.errorCode = errorCode;
    }
    cancelled: boolean;
    status: 500 | 400 = 500;;
    errorType = 'RevenueCatSDKPurchaseError';
    errorCode?: string;
}

export class PurchasesErrorAdapter {
    // https://sdk.revenuecat.com/android/4.6.1/public/com.revenuecat.purchases/-purchases-error-code/index.html#:~:text=PurchaseNotAllowedError(3%2C%20%22The%20device,to%20make%20the%20purchase.%22)
    private errorCodesToProps: {[code: string]: Partial<RevenueCatSDKPurchaseError>} = {
        // user clicked back
        [PURCHASES_ERROR_CODE.PURCHASE_CANCELLED_ERROR]: {
            status: 400,
            cancelled: true
        },
        // cc declined
        [PURCHASES_ERROR_CODE.PURCHASE_NOT_ALLOWED_ERROR]: {
            status: 400,
            cancelled: true
        }
    };
    convert = (error: any) => {
        if (error && error.code && error.data){
            const purchasesError = error.data as PurchasesError;
            const cancelled = Boolean(purchasesError.userCancelled);
            const message = `${purchasesError.readableErrorCode}  ${purchasesError.message}. ${purchasesError.underlyingErrorMessage}`;
            const status = 500;

            const overrides = this.errorCodesToProps[purchasesError.code] || {};

            throw new RevenueCatSDKPurchaseError({
                message,
                status,
                cancelled,
                ...overrides
            });
        }
        else  {
            throw new RevenueCatSDKPurchaseError({ message: JSON.stringify(error) });
        }
    };
}
