import { WeatherConditionIcon } from './components/WeatherConditionIcon';
import { WeatherMetric } from './components/WeatherMetric';
import { PrecipitationIcon } from './components/PrecipitationIcon';
import { CloudsIcon, HumidityIcon, SunriseIcon, SunsetIcon, VisibilityIcon, WindDirectionIcon, WindGustsIcon } from '../components/icons/Icons';
import { Card } from '../components/card/Card';
import { CardContent } from '../components/card/CardContent';
import { ISummaryForecast } from './model/ISummaryForecast';
import { CardHeader } from '../components/card/CardHeader';
import { CardTitle } from '../components/card/CardTitle';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useEffect, useState } from 'react';
import { WeatherService } from './WeatherService';
import { Spinner } from '../components/spinner/Spinner';
import { WeatherError } from './components/WeatherError';
import { Logger } from '../support/debug/Logger';
import { ICoordinate } from '@autopylot-internal/tiles-client';
import { CachingWeatherService } from './CachingWeatherService';

type ISO8601 = string;

interface ISummaryForecastCardFromLinkProps {
    link: { href: string; };

    // mission info
    startTime: ISO8601;
    location: ICoordinate;
    endTime: ISO8601;
}

export const SummaryForecastCardFromLink: React.FC<ISummaryForecastCardFromLinkProps> = ({ link, startTime, endTime, location }) => {

    const cachingWeatherService = useInstance(CachingWeatherService);
    const weatherService = useInstance(WeatherService);

    const [forecast, setForecast] = useState<ISummaryForecast>();
    const [forecastError, setForecastError] = useState<any>();
    const [loading, setLoading] = useState(false);

    const logger = useInstance(Logger);

    useEffect(() => {
        Promise.resolve().then(async () => {
            setLoading(true);
            const summary = await cachingWeatherService.getSummaryForecast({ link, startTime, endTime, location});
            setForecast(summary);
            setForecastError(undefined);
        }).catch(err => {
            setForecastError(err);
            setForecast(undefined);
            logger.error(err);
        }).finally(() => {
            setLoading(false);
        });

    }, [weatherService, logger, cachingWeatherService, link, startTime, endTime, location]);

    return (
        <SummaryForecastCard
            forecast={loading ? undefined : forecast}
            forecastError={loading ? undefined : forecastError}
            isLoading={loading}
        />
    );
};

interface ISummaryForecastCardProps {
    forecast?: ISummaryForecast;
    forecastError?: any;
    isLoading: boolean;
}

export const SummaryForecastCard: React.FC<ISummaryForecastCardProps> = ({ forecast, forecastError, isLoading }) => {
    if (!forecast) {
        return (
            <div data-type="weather-mission-summary" data-forecast="not-available">
                <Card>
                    <CardContent>
                        <div style={{
                            // 170 is how big we expect the card to be once it is loaded
                            // and 40 is for the padding the card will have
                            minHeight: 170 - 40
                        }}>

                            {forecastError &&
                                <WeatherError error={forecastError} />
                            }
                            {!forecastError &&
                                <Spinner size="small" centered />
                            }
                        </div>
                    </CardContent>
                </Card>
            </div>
        );
    }

    return (
        <div
            data-type="weather-mission-summary"
            data-forecast={isLoading ? 'loading': 'loaded'}
            data-date={forecast.forecastStart}
            data-location={JSON.stringify(forecast.location)}
            data-status="loaded"
            style={{opacity: isLoading ? .5 : 1}}
        >
            <Card>
                <CardHeader noDivider>
                    <CardTitle>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <span style={{marginRight: 5}}><WeatherConditionIcon conditionCode={forecast.conditionCode} isDaylight={forecast.isDaylight} size="large" /></span>
                            <span style={{fontSize: 32, fontWeight: 'normal'}}>{forecast.temperatureF}</span>
                            <div style={{display: 'inline-flex', flexDirection: 'column', alignItems: 'center', fontWeight: 'normal'}}>
                                <span style={{fontSize: 18, lineHeight: 0.9}}>&deg;</span>
                                <span style={{fontSize: 9, lineHeight: 1.2}}>F</span>
                            </div>
                        </div>
                        <div style={{marginTop: -5, textAlign: 'center', fontSize: 12, fontWeight: 'normal'}}>
                            {forecast.conditionDescription}
                        </div>
                    </CardTitle>
                </CardHeader>

                <CardContent>
                    <div style={{margin: '-15px -25px -12px -20px', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around'}}>
                        <WeatherMetric Icon={SunriseIcon} heading={forecast.sunrise} subheading="sunrise" />
                        <WeatherMetric Icon={PrecipitationIcon} heading={`${forecast.precipitationChancePct}%`} subheading="precip" />
                        <WeatherMetric Icon={WindDirectionIcon} iconAngle={forecast.windDirection} heading={`${forecast.windSpeedMph} mph`} subheading="wind" />
                        <WeatherMetric Icon={VisibilityIcon} heading={`${forecast.visibilityMiles} mi`} subheading="visibility" />
                        <WeatherMetric Icon={SunsetIcon} heading={forecast.sunset} subheading="sunset" />
                        <WeatherMetric Icon={HumidityIcon} heading={`${forecast.humidityPct}%`} subheading="humidity" />
                        <WeatherMetric Icon={WindGustsIcon} heading={`${forecast.windGustMph} mph`} subheading="gusts" />
                        <WeatherMetric Icon={CloudsIcon} heading={`${forecast.cloudCoverPct}%`} subheading="clouds" />
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};
