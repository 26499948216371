import { MissionFeatureCollectionFacadeAdapter } from '@autopylot-internal/tiles-client';
import { AutoPylotPrincipal } from '../auth/AutoPylotPrincipal';
import { SavedChecklistsPage } from '../checklists/SavedChecklistsPage';
import { MissionsState } from '../missions/model/MissionsState';
import { IOperatorProfile } from '../profile/IOperator';
import { OperatorState } from '../profile/OperatorState';
import { getWellKnownLocation } from '../support/screenshots/getWellKnownLocation';
import { IScreenshotablePage } from '../support/screenshots/IScreenshotablePage';
import { MenuViewModel } from './MenuViewModel';

interface IMyPageProps {
    profile?: Partial<IOperatorProfile>,
    reminders?: number
}

export default class ScreenshotMenu implements IScreenshotablePage<IMyPageProps> {
    static inject = () => [MenuViewModel, AutoPylotPrincipal, OperatorState, MissionsState, MissionFeatureCollectionFacadeAdapter];
    constructor(private menu: MenuViewModel, private principal: AutoPylotPrincipal, private operatorState: OperatorState, private missions: MissionsState, private mfcAdapter: MissionFeatureCollectionFacadeAdapter) { }

    screenId = 'menu';

    // choose simple  page to reduce dependencies
    ComponentUnderTest = SavedChecklistsPage;

    permutationToProps = ({ profile, reminders }: IMyPageProps) => {
        this.menu.open();
        if (profile) {
            this.operatorState.setState({ ...this.operatorState.state, ...profile });
        }
        if (reminders) {
            const { address, radiusPolygon } = getWellKnownLocation('uncontrolled');
            for (let i = 0; i < reminders; i++) {
                this.missions.addMissionToState({
                    missionId: String(i),
                    address,
                    radiusPolygon: this.mfcAdapter.adaptFromPersisted(radiusPolygon),
                    checklist: { sections: [{ items: [{ completed: false, notification: { at: new Date().toISOString() } } ]} ]}
                } as any);
            }
        }
        return {};
    };

    blockScreenshotTil = async () => {
        // wait a lil longer than the animation takes to slide right
        await new Promise(resolve => setTimeout(resolve, 400));
    };

    permutations: { [key: string]: IMyPageProps } = {
        'simple': {
            profile: { firstName: 'Fred', lastName: 'Flyer', email: 'fred.flyer@autopylot.io' }
        },
        'long-name': {
            profile: { firstName: 'Maximillion', lastName: 'Montgomery-Montague', email: 'max.monty@autopylot.io' }
        },
        'long-email': {
            profile: { firstName: 'Testy', lastName: 'Pilot', email: 'webmaster@hostname.autopylot.io' }
        },
        'very-long-email': {
            profile: { firstName: 'Testy', lastName: 'Pilot', email: 'contact-webmaster@long-hostname.long-subdomain.autopylot.io' }
        },
        'guest-recreational': {
            profile: { certificationType: 'recreational' }
        },
        'guest-licensed': {
            profile: { certificationType: 'part-107' },
        },
        'with-mission-reminders': {
            profile: { firstName: 'Fred', lastName: 'Flyer', email: 'fred.flyer@autopylot.io' },
            reminders: 2
        }
    };
}
