import { useState } from 'react';
import { Slider } from '../../components/slider/Slider';
import { round } from '../../support/helpers/format';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { MissionPinAndRadiusOnMapViewModel } from '../mission-pin-and-radius/MissionPinAndRadiusOnMapViewModel';
import { useSubscription } from '@meraki-internal/state';

interface MissionRadiusSliderProps {
    value: number;
    isSquare: boolean;
    onSliderMove: (v: number) => void;
    onSliderMoveEnd: (v: number) => void;
}

export const MissionRadiusSlider: React.FC<MissionRadiusSliderProps>  = ({value, isSquare, onSliderMove, onSliderMoveEnd}) => {
    const [localValue, setLocalValue] = useState(value);

    const radiusVM = useInstance(MissionPinAndRadiusOnMapViewModel);
    useSubscription(() => radiusVM);

    // rounded to nearest 10ft and doubled if square
    const formattedValue = round(localValue, -1) * (isSquare ? 2 : 1);

    const onSliderChange = (v: number) => {
        onSliderMove(v);
        setLocalValue(v);
    };

    return (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 5, color: 'white'}}>

            <small style={{minWidth: 85}}>
                {isSquare ? 'Mission Width' : 'Mission Radius'}
            </small>

            <Slider min={100} max={4000} curved value={localValue} onChange={onSliderChange} onMoveEnd={onSliderMoveEnd} />

            <small style={{minWidth: 40, textAlign: 'right'}}>
                {formattedValue} ft
            </small>

        </div>
    );
};
