import { IonRange } from '@ionic/react';
import { Color } from '../common';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
    styledRange: {
        'padding-top': '8px',
        '--bar-height': '40px',
        '--bar-border-radius': '16px',
        '--knob-size': '35px',
        '&::part(pin)': {
            'font-size': '11px',
            'top': '20px',
            'transform': 'scale(1.01)',
            'z-index': '3!important'
        },
        '&::part(tick)': {
            'background': 'rgba(var(--ion-color-primary-rgb), 0.6)',
            'z-index': '1'
        },
        '&::part(tick-active)': {
            'background': 'rgba(var(--ion-color-light-rgb), 0.6)',
            'z-index': '1'
        }
    }
});

export interface FatSliderProps {
    min: number;
    max: number;
    step?: number;
    color?: Color;
    value: number;
    onChange: (value: number) => void;
    labelFormatter?: (v: number) => string;
}

export const FatSlider: React.FC<FatSliderProps> = ({ min, max, step = 1, color = 'primary', value, onChange, labelFormatter }) => {
    const classes = useStyles();
    const style = {
        '--bar-background-active': `var(--ion-color-${color})`
    };

    labelFormatter = labelFormatter || (v => String(v));

    const handleChange = (e: any) => {
        onChange(e.detail.value);
    };

    return (
        <IonRange
            style={style}
            className={classes.styledRange}
            min={min}
            max={max}
            step={step}
            snaps={true}
            ticks={step/max >= 0.05}
            value={value}
            pin={true}
            pinFormatter={labelFormatter}
            onIonChange={handleChange}
            debounce={20}
            mode="ios" // otherwise doesn't render correctly on web
            data-type="fat-slider"
        >
            {/* cover up first tick */}
            <div slot="start" style={{visibility: (value === 0 ? 'hidden' : 'visible'), zIndex: 1, position: 'relative', marginLeft: -20}}>
                <div style={{position: 'absolute', background: 'white', top: -3, left: 15, width: 1, height: 8}} />
                <div style={{position: 'absolute', background: '#6965b3', top: -3, left: 16, width: 1, height: 8}} />
            </div>

            {/* cover up last tick */}
            <div slot="end" style={{visibility: (value === max ? 'hidden' : 'visible'), zIndex: 1, position: 'relative', marginRight: -20}}>
                <div style={{position: 'absolute', background: '#e5e5e5', top: -3, right: 16, width: 1, height: 8}} />
                <div style={{position: 'absolute', background: 'white', top: -3, right: 15, width: 1, height: 8}} />
            </div>
        </IonRange>
    );
};

