import './support/debug/sentry'; // do this first so we catch all errors
import React from 'react';
import ReactDOM from 'react-dom';
import { TextZoom } from '@capacitor/text-zoom';
import { ScreenOrientation } from '@capacitor/screen-orientation';
import { Device } from '@capacitor/device';
import { AutoPylotApp } from './app/AutoPylotApp';

Device.getInfo().then(({ platform }) => {
    if (platform === 'android'){
        // don't let android text zoom change our font size
        TextZoom.set({ value:  1.0 });

        // Lock portrait orientation on android if smaller screen dimension is too small
        if (Math.min(window.innerHeight, window.innerWidth) < 600) {
            ScreenOrientation.lock({ orientation: 'portrait' });
        }
    }
});

// set the sha on window, so it is part of the deployed app, and can be scraped by screenshots
(window as any).AP_APP_SHA = process.env.REACT_APP_SHA;

ReactDOM.render(
    <React.StrictMode>
        <AutoPylotApp />
    </React.StrictMode>,
    document.getElementById('root')
);
