import { useInstance } from '@meraki-internal/react-dependency-injection';
import { EditableMissionState } from '../mission/model/EditableMissionState';
import { useSubscription } from '@meraki-internal/state';
import { InsuranceState } from './InsuranceState';
import { HistoryViewModel } from '../app/HistoryViewModel';
import { IonBadge } from '@ionic/react';
import { WarningIcon } from '../components/icons/Icons';
import { TrackingService } from '../support/tracking/TrackingService';

export const InsuranceBanner: React.FC<{ mission: EditableMissionState }> = ({ mission }) => {
    const history = useInstance(HistoryViewModel);
    const tracker = useInstance(TrackingService);

    const insurance = useInstance(InsuranceState);
    useSubscription(() => insurance);

    if (mission.hasEnded() || !insurance.state.loaded) {
        return null;
    }

    const policy = insurance.getActivePolicy({
        startTime: mission.getStartTime(),
        endTime: mission.getEndTime()
    });

    if (policy) {
        return null;
    }

    const onClick = () => {
        tracker.track('Insurance Nudge Opened', () => ({ type: 'Mission Banner'}));
        history.push('/insurance');
    };

    return (
        <div style={{cursor: 'pointer', padding: '15px 15px 0'}}>
            <IonBadge style={{'--padding-top': '8px', '--padding-bottom': '8px', '--color': 'var(--ion-text-color)', '--background': 'rgba(var(--ion-color-warning-rgb), 0.1)', width: '100%'}} onClick={onClick}>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', textAlign: 'left', gap: 10}}>
                    <div>
                        <WarningIcon variant="filled" size="large" color="warning" />
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', gap: 5, flexGrow: 1}}>
                        <div>Mission Not Protected!</div>
                        <div style={{fontWeight: 'normal'}}>Cover crashes, injuries, and loss</div>
                    </div>
                    <div style={{color: 'var(--ion-color-warning)', paddingRight: 10}}><b>Free Quote</b></div>
                </div>
            </IonBadge>
        </div>
    );
};
