import { Group } from '../ComponentLibrary';
import { colors } from '../common';
import { Slider, SliderProps } from './Slider';
import { useState } from 'react';

const SliderWithLabel = ({ label, ...props }: Partial<SliderProps> & { label: string }) => {
    const [value, setValue] = useState(props.curved ? 25 : 50);
    return (
        <div style={{display: 'flex'}} key={props.color}>
            <p style={{width: 80}}>{label}</p>
            <Slider {...props} min={0} max={100} value={value} onChange={v => setValue(v)} onMoveEnd={v => setValue(v)} />
            <p style={{width: 50, textAlign: 'right'}}>{Math.round(value)}</p>
        </div>
    );
};

const DemoSlider: Group & React.FC = () => {
    return (
        <div>
            {colors.filter(c => c !== 'light').map(color =>
                <SliderWithLabel color={color} label={color} />
            )}
            <SliderWithLabel curved label="curved" />
        </div>
    );
};

DemoSlider.componentName = 'Slider';

export default DemoSlider;
