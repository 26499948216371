import { useInstance } from '@meraki-internal/react-dependency-injection';
import { HistoryViewModel } from '../../app/HistoryViewModel';
import { Page } from '../../components/page/Page';
import { useEditableMissionState } from '../../missions/model/useEditableMissionState';
import { usePageView } from '../../support/tracking/usePageView';
import { Checklist } from '../../checklists/Checklist';
import { PageHeading } from '../../components/page/PageHeading';
import { formatLocalDate, formatLocalTimeRange } from '../../support/helpers/format';
import { ActionMenu } from '../../components/action-menu/ActionMenu';
import { SavedChecklistsState } from '../../checklists/SavedChecklistsState';
import { SaveChecklistDialog } from '../../checklists/SaveChecklistDialog';
import { AlertPresenter } from '../../app/AlertBinder';
import { useMissionChecklistState } from './useMissionChecklistState';
import { TrackingService } from '../../support/tracking/TrackingService';
import { State, useSubscription } from '@meraki-internal/state';
import { Badge } from '../../components/badge/Badge';
import { useIsNative } from '../../support/hooks/useIsNative';

export class MissionChecklistPageState extends State<{isMenuOpen: boolean, isSaving: boolean}> {
    constructor() {
        super({ isMenuOpen: false, isSaving: false });
    }
    startSaving = () => this.setState({ isSaving: true });
    stopSaving = () => this.setState({ isSaving: false });
    openMenu = () => this.setState({ isMenuOpen: true });
    closeMenu = () => this.setState({ isMenuOpen: false });
}

export const MissionChecklistPage: React.FC<{ missionId: string }> = ({ missionId }) => {
    usePageView('Mission Checklist');

    const history = useInstance(HistoryViewModel);
    const alert = useInstance(AlertPresenter);
    const tracker = useInstance(TrackingService);

    const isNative = useIsNative();

    const mission = useEditableMissionState(missionId);

    // To avoid tighly coupling missions to checklists, the mission (EditableMissionState) contains
    // the checklist as a JS object (not as State). Here we need subscribable state we can pass to
    // MissionChecklist, so we create it with this hook which uses MissionChecklistStateFactory.
    // But note this makes it necessary to mock MissionChecklistStateFactory.getInstance() in order
    // to be able to manipulate the state in ScreenshotMissionChecklistPage.
    const checklist = useMissionChecklistState(mission);

    const savedChecklists = useInstance(SavedChecklistsState);

    const pageState = useInstance(MissionChecklistPageState);
    useSubscription(() => pageState);

    if (!mission || !checklist) {
        return null;
    }

    const onCopyFromSavedChecklists = async () => {
        history.push(`/checklists/copy?toMissionId=${mission.state.missionId}`);
    };

    const onAddToSavedChecklist = async () => {
        if (!checklist.getItemCount()) {
            await alert.showAlertV2({ header: 'No Items to Save' });
            return;
        }
        pageState.startSaving();
    };

    const onDeleteAllItems = async () => {
        if (!checklist.getItemCount()) {
            await alert.showAlertV2({ header: 'No Items to Delete' });
            return;
        }

        const response = await alert.confirmDestructiveAction({
            header: 'Delete All Items?',
            message: 'All checklist items will be deleted and cannot be recovered.'
        });

        if (response === 'destroy') {
            try {
                const itemCount = checklist.getItemCount();
                await checklist.deleteAllItems();
                tracker.track('Checklist All Items Deleted', () => ({ missionId: mission.state.missionId, itemCount }));
            } catch (e) {
                alert.showAndLogError(e);
            }
        }
    };

    const onSave = async ({ name }: { name: string }) => {
        try {
            const sections = mission.state.checklist!.sections;
            await savedChecklists.addChecklist({ name, sections });
            tracker.track('Checklist Saved from Mission', () => ({ name, missionId: mission.state.missionId }));
            pageState.stopSaving();
            history.push('#/checklists/');
        } catch (e) {
            alert.showAndLogError(e);
        }
    };

    const formattedMissionDate = formatLocalDate({ date: mission.getStartTime(), location: mission.getLocation() });
    const formattedMissionTimes = formatLocalTimeRange({ startTime: mission.getStartTime(), endTime: mission.getEndTime(), location: mission.getLocation() });

    return (
        <Page title="Mission Checklist" onBack={() => history.back(mission.pages.details.path())} onAction={pageState.openMenu}>

            <PageHeading
                heading={mission.getName() || ''}
                subheading={`${formattedMissionDate} | ${formattedMissionTimes}`}
            />

            {!isNative  &&
                <div style={{textAlign: 'center', marginTop: 15}}>
                    <Badge color="warning">
                        Note that to receive reminder notifications you'll need to download our mobile app
                    </Badge>
                </div>
            }

            <Checklist vm={checklist} canCompleteItems />

            <ActionMenu trigger="action-button" isOpen={pageState.state.isMenuOpen} onClose={pageState.closeMenu} actions={[
                { text: 'Add Templates to Mission', handler: onCopyFromSavedChecklists },
                { text: 'Save as Checklist Template', handler: onAddToSavedChecklist },
                { text: 'Delete All Items', handler: onDeleteAllItems }
            ]} />

            <SaveChecklistDialog title="Save Checklist" isOpen={pageState.state.isSaving} onCancel={pageState.stopSaving} onSave={onSave} />

        </Page>
    );
};
