import { useEffect, useState } from 'react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { usePageView } from '../support/tracking/usePageView';
import { IonList, isPlatform } from '@ionic/react';
import { Page } from '../components/page/Page';
import { PageHeading } from '../components/page/PageHeading';
import { IntercomService, tourNameMap, ITourKey } from '../app/intercom/IntercomService';
import { AlertPresenter } from '../app/AlertBinder';
import { UserFacingError } from '../support/UserFacingError';
import { TrackingService } from '../support/tracking/TrackingService';
import { DiscoverListItem } from './DiscoverListItem';

import {
    MapIcon,
    PlaneIcon,
    AirspaceGoodIcon,
    DocumentIcon,
    PartlySunnyIcon,
    ListIcon,

    // CalendarIcon,
    // ShareIcon
} from '../components/icons/Icons';
import { StorageProvider } from '../support/StorageProvider';
import { useBreakpoints } from '../support/hooks/useBreakpoints';
import { Badge } from '../components/badge/Badge';

const pageName = 'Discover AutoPylot';

interface IPersistableViews {
    [key: string]: boolean;
}

export class DiscoverPageStorage {
    static inject = () => [StorageProvider];
    constructor(private storage: StorageProvider){}
    views = this.storage.getJSONProvider<IPersistableViews>('discover-autopylot-tours', { storageType: 'localStorage' });

}
export const DiscoverPage: React.FC = () => {
    usePageView(pageName);

    const tracker = useInstance(TrackingService);
    const intercom = useInstance(IntercomService);
    const alert = useInstance(AlertPresenter);
    const storageV2 = useInstance(DiscoverPageStorage);
    const [toursViewed, setToursViewed] = useState<Map<string, boolean>>(new Map());

    const { isLargerScreen } = useBreakpoints();

    // set tour view info in local storage, after creating it if it doesn't exist
    useEffect(() => {
        let views: Map<string, boolean>;
        if (!storageV2.views.exists()) {
            views = new Map();
            Object.keys(tourNameMap).forEach((value, index, array) => {
                const tourKey = array[index];
                views.set(tourKey, false);
            });
        } else {
            views = new Map(Object.entries(storageV2.views.get()!));
        }

        setToursViewed(views);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (toursViewed.size > 0) {
            storageV2.views.set(Object.fromEntries(toursViewed));
        }
    }, [toursViewed, storageV2]);

    const tourViewed = (tourKey: ITourKey) => {
        // copy to new map as state change doesn't trigger unless completely new object
        const viewed = new Map(toursViewed);
        viewed.set(tourKey, true);
        setToursViewed(viewed);
        tracker.track('Feature Tour Viewed', () => ({tour: tourNameMap[tourKey]}));
    };

    const itemClicked = async (tourKey: ITourKey) => {
        const displayMessage = `We were unable to load this feature video, please try again later.`;
        if (!intercom.canShowProductTour()) {
            alert.showAlert({
                header: 'Sorry! Something went wrong',
                message: displayMessage
            });
            // NOTE: uncomment this for local development
            // tourViewed(tourKey);
        } else {
            try{
                await intercom.showProductTour(tourKey);

                // delay so checkmark doesn't show before tour launches
                setTimeout(() => tourViewed(tourKey), 1000);

            } catch (e) {
                alert.showAndLogError(
                    new UserFacingError({
                        message: `Intercom failed to load product tour with INTERCOM_TOUR_IDS -> ${tourKey}.`,
                        displayMessage: displayMessage
                    })
                );
            }
        }
    };

    return (
        <Page title={pageName}>

            <div style={{marginTop: 15, marginBottom: 5}}>
                <PageHeading heading="Learn all you can do with AutoPylot!" />
            </div>

            {isPlatform('android') && isLargerScreen &&
                <div style={{textAlign: 'center', marginTop: 15}}>
                    <Badge color="warning">
                        Note that you'll need to rotate your tablet to portrait to view these tours
                    </Badge>
                </div>
            }

            <IonList style={{paddingLeft: 20}} data-type="discover-list">
                <DiscoverListItem
                    icon={MapIcon}
                    tourKey={'EXPLORE_AIRSPACE'}
                    clickHandler={itemClicked}
                    toursViewed={toursViewed}
                />
                <DiscoverListItem
                    icon={PlaneIcon}
                    tourKey={'ADD_MISSION'}
                    clickHandler={itemClicked}
                    toursViewed={toursViewed}
                />
                <DiscoverListItem
                    icon={AirspaceGoodIcon}
                    tourKey={'GET_LAANC_AUTH'}
                    clickHandler={itemClicked}
                    toursViewed={toursViewed}
                />
                <DiscoverListItem
                    icon={PartlySunnyIcon}
                    tourKey={'MISSION_WEATHER'}
                    clickHandler={itemClicked}
                    toursViewed={toursViewed}
                />
                <DiscoverListItem
                    icon={DocumentIcon}
                    tourKey={'MISSION_NOTES'}
                    clickHandler={itemClicked}
                    toursViewed={toursViewed}
                />
                <DiscoverListItem
                    icon={ListIcon}
                    tourKey={'MISSION_CHECKLIST'}
                    clickHandler={itemClicked}
                    toursViewed={toursViewed}
                    newFeatureKey={'mission-checklist-tour'}
                />

                {/* <DiscoverListItem
                    icon={CalendarIcon}
                    tourKey={'CONNECT_CALENDAR'}
                    clickHandler={itemClicked}
                    toursViewed={toursViewed}
                />
                <DiscoverListItem
                    icon={ShareIcon}
                    tourKey={'INVITE_PILOT'}
                    clickHandler={itemClicked}
                    toursViewed={toursViewed}
                /> */}
            </IonList>
        </Page>
    );
};
