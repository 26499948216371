import { useSubscription } from '@meraki-internal/state';
import { CancelIcon } from '../../components/icons/Icons';
import { EditableMissionState } from '../model/EditableMissionState';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { AlertPresenter } from '../../app/AlertBinder';
import { ActionMenu, IActionMenuAction } from '../../components/action-menu/ActionMenu';
import { APIClient } from '@autopylot-internal/autopylot-api-client';

export const MockAuthorizationActionSheet = ({ mission, isOpen, onClose }: { isOpen: boolean, onClose: () => void, mission: EditableMissionState }) => {
    const { showAlert } = useInstance(AlertPresenter);

    const apiClient = useInstance(APIClient);

    useSubscription(() => mission);

    const endTime = mission.getEndTime();

    const invalidate = async (url: string, params: any = {}) => {
        try {
            await apiClient.post(url, undefined, {}, params);
            showAlert({
                header: 'Sent',

                // remind the user that this is emulating an FAA action (source change)
                // so the UI won't be in the right state yet
                // we could start polling... but I like that this reminds us that the app isn't polling
                // and is otherwise misleading
                message: 'The invalidate has been triggered, wait a few seconds, then refresh',

                buttons: ['OK']
            });
        }
        catch (err) {
            console.log(err);
            showAlert({
                header: 'Failed',
                message: `Bummer - Failed with ${err}.`,
                buttons: ['OK']
            });
        }
    };

    const actions: IActionMenuAction[] = [];
    for (const auth of mission.getMissionRadius().getLAANCAuthorizeableAdvisoryFeatures()) {

        if (mission.state.links.simulateRescindAuthorization) {
            actions.push({
                text: `APY${auth.properties.operationID} (${auth.properties.getAPLAANCStatus({ endTime })}) -- Rescind`,
                icon: CancelIcon.raw,
                handler: async () => {
                    await invalidate(mission.state.links.simulateRescindAuthorization.href, {operationID: auth.properties.operationID});
                }
            });
        }

        if (mission.state.links.invalidateAuthorization) {
            actions.push({
                text: `APY${auth.properties.operationID} (${auth.properties.getAPLAANCStatus({ endTime })}) -- Invalidate`,
                icon: CancelIcon.raw,
                handler: async () => {
                    await invalidate(mission.state.links.invalidateAuthorization.href, {operationID: auth.properties.operationID});
                }
            });
        }
    }

    if (mission.state.links.invalidatedUncontrolledToRestricted) {
        actions.push({
            text: `Invalidate (emulate NSUFR)`,
            icon: CancelIcon.raw,
            handler: async () => {
                await invalidate(mission.state.links.invalidatedUncontrolledToRestricted.href);
            }
        });
    }

    if (mission.state.links.invalidatedUncontrolledToLAANC) {
        actions.push({
            text: `Invalidate (emulate LAANC)`,
            icon: CancelIcon.raw,
            handler: async () => {
                await invalidate(mission.state.links.invalidatedUncontrolledToLAANC.href);
            }
        });
    }

    return (
        <ActionMenu isOpen={isOpen} onClose={onClose} actions={actions} />
    );
};
