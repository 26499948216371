import { Group } from '../ComponentLibrary';
import { Badge } from './Badge';
import { colors } from '../common';

const DemoBadge: Group & React.FC = () => {
    return (
        <div>
            {[true, false].map(saturated =>
                <div key={String(saturated)}>
                    <h3>Saturated: {String(saturated)}</h3>
                    {colors.map(color =>
                        <div key={color}>
                            <Badge saturated={saturated} color={color}>{color}</Badge>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

DemoBadge.componentName = 'Badge';

export default DemoBadge;
