import { useInstance } from '@meraki-internal/react-dependency-injection';
import { VisibleMapLayersState } from './VisibleMapLayersState';
import { useSubscription } from '@meraki-internal/state';
import { LayerToggle } from './LayerToggle';
import { BasemapButton } from './BasemapButton';
import { ToggleSectionHeader } from './ToggleSectionHeader';

export const ToggleLayers: React.FC<{}> = () => {
    const vm = useInstance(VisibleMapLayersState);
    useSubscription(() => vm);

    if (!vm.state.isShowingControls){
        return null;
    }

    return (
        <>
            <ToggleSectionHeader label='Map' />
            <div style={{ display: 'flex', justifyContent: 'space-evenly', paddingTop: 5, paddingBottom: 10 }}>
                <BasemapButton imageName='streets' label='Street' mapboxId='streets-v12' />
                <BasemapButton imageName='satellite' label='Satellite' mapboxId='satellite-streets-v12' />
                <BasemapButton imageName='outdoors' label='Outdoors' mapboxId='outdoors-v12' />
            </div>
            <ToggleSectionHeader label='Airspace' />
            <LayerToggle layerId='all' enabled={vm.isAllLayersToggleEnabled()} onToggle={() => vm.toggleLayer('all')} />

            <hr />
            {vm.getLayers().map(({ layerId, enabled }) => (
                <LayerToggle key={layerId} layerId={layerId} enabled={enabled} onToggle={() => vm.toggleLayer(layerId)} />
            ))}

        </>
    );
};
