import { ControlledAirspaceAdvisory } from './advisories/ControlledAirspaceAdvisory';
import { DCFRZAdvisory } from './advisories/DCFRZAdvisory';
import { LAANCAdvisory } from './advisories/LAANCAdvisory';
import { NSUFRAdvisory } from './advisories/NSUFRAdvisory';
import { PartTimeNSUFRAdvisory } from './advisories/PartTimeNSUFRAdvisory';
import { SpecialUseAdvisory } from './advisories/SpecialUseAdvisory';
import { DayNightAdvisory } from './advisories/time-of-day/DayNightAdvisory';
import { NOTAMAdvisory } from './advisories/NOTAMAdvisory';
import { TFRListAdvisory } from './advisories/TFRAdvisory';
import { NoTFRAdvisory } from './advisories/NoTFRAdvisory';
import { StadiumsAdvisory } from './advisories/StadiumsAdvisory';
import { MissionFeatureCollection } from '@autopylot-internal/tiles-client';
import { UncontrolledAirspaceAdvisory } from './advisories/UncontrlledAirspaceAdvisory';
import { ConcurrentMissionsAdvisory } from './advisories/ConcurrentMissionsAdvisory';
import { NationalParkAdvisory } from './advisories/NationalParkAdvisory';
import { WildernessAreaAdvisory } from './advisories/WildernessAreaAdvisory';
import { WildlifeRefugeAdvisory } from './advisories/WildlifeRefugeAdvisory';
import { ScheduledAirspaceAdvisory } from './advisories/ScheduledAirspaceAdvisory';
import { CompositeTFRAdvisory } from './advisories/CompositeTFRAdvisory';
import { AirspaceBoundaryAdvisory } from './advisories/AirspaceBoundaryAdvisory';
import { MilitaryTrainingRoutesAdvisory } from './advisories/MilitaryTrainingRoutesAdvisory';
import { RecreationalFlyerFixedSitesAdvisory } from './advisories/RecreationalFlyerFixedSitesAdvisory';
import { OperationalAwarenessAdvisory } from './advisories/OperationalAwarenessAdvisory';

const NoOp = () => null;

const advisoryToComponentMap = {
    'nsufr': NSUFRAdvisory,
    'parttime-nsufr': PartTimeNSUFRAdvisory,
    'special-use-airspace': SpecialUseAdvisory,
    'airspace': ControlledAirspaceAdvisory,
    'airspace+class-g': UncontrolledAirspaceAdvisory,
    'dc-frz': DCFRZAdvisory,
    'stadiums': NoOp,
    'stadiums+composite': StadiumsAdvisory,
    'uasfm+airspace': NoOp,
    'uasfm+airspace+composite': LAANCAdvisory,
    'uasfm+authorizable': NoOp,
    'time-of-day': DayNightAdvisory,
    'concurrent-missions': ConcurrentMissionsAdvisory,
    'no-tfr': NoTFRAdvisory,
    'notam': NOTAMAdvisory,
    'national-parks': NationalParkAdvisory,
    'wilderness-areas': WildernessAreaAdvisory,
    'wildlife-refuges': WildlifeRefugeAdvisory,
    'tfr': TFRListAdvisory,
    'scheduled-airspace': ScheduledAirspaceAdvisory,

    // no op temporary-flight-restrictions, b/c we'll render the composite instead
    'temporary-flight-restrictions': NoOp,
    'temporary-flight-restrictions+composite': CompositeTFRAdvisory,
    'airspace-boundary': AirspaceBoundaryAdvisory,
    'military-training-routes': NoOp,
    'military-training-routes+composite': MilitaryTrainingRoutesAdvisory,
    'rec-flyer-fixed-sites': RecreationalFlyerFixedSitesAdvisory,
    'operational-awareness': OperationalAwarenessAdvisory,

};

/**
 * Why is this called FAAAdvisory? Mostly b/c naming is hard, it because at this time,  99% of what it abstracts
 * is how to render an advisory given feature data from the FAA
 */
export const FAAAdvisory = ({ source, advisory, mission }: { source: string, advisory: any, mission: MissionFeatureCollection }) => {
    const Advisory = (advisoryToComponentMap as any)[source];
    if (Advisory) {
        return (
            <div data-advisory-source={source}>
                <Advisory {...advisory} mission={mission} />
            </div>
        );
    }
    else {
        console.log(JSON.stringify(advisory));
        return null;
    }
};
