import { useEffect, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useSubscription } from '@meraki-internal/state';
import { createIframe } from 'stere-insurance-sdk';
import { Config, SectionHeaderInfo, StereInsuranceData } from 'stere-insurance-sdk/dist/types';
import { ValidPrefillQuestionIDsForDRONE, ValidReadOnlyQuestionIDsForDRONE } from 'stere-insurance-sdk/dist/questionTypes';
import { styles } from './theme';
import { tryAndCatch } from '../support/helpers/tryAndCatch';
import { Page } from '../components/page/Page';
import { InsuranceState } from './InsuranceState';
import { OperatorState } from '../profile/OperatorState';
import { EnvConfiguration } from '../app/config/EnvConfiguration';
import { HistoryViewModel } from '../app/HistoryViewModel';
import { Spinner } from '../components/spinner/Spinner';
import { DevSettings } from '../support/DevSettings';
import { Logger } from '../support/debug/Logger';
import { InsuranceErrorPage } from './InsuranceErrorPage';
import { TrackingService } from '../support/tracking/TrackingService';
import { AlertPresenter } from '../app/AlertBinder';

// Stere Documentation:
// iframe: https://app.archbee.com/public/PREVIEW-T0nrMjzEr84QRL9uOImEa
// api: https://docs.stere.io/

type IStereEventType =
    'SHOW_IFRAME' |
    'EXIT' |
    'FAILURE' |
    'STERE_JOURNEY_STARTED' |
    'STERE_PRODUCT_SELECTED' |
    'STERE_APPLICATION_STARTED' |
    'STERE_APPLICATION_IN_PROGRESS' |
    'STERE_APPLICATION_COMPLETED' |
    'STERE_QUOTES_MISSING' |
    'STERE_QUOTES_GENERATED' |
    'STERE_QUOTES_SELECTED' |
    'STERE_CHECKOUT_DECLINED' |
    'STERE_CHECKOUT_COMPLETED'
;

type IStyleKeys = 'spinnerWrapper' | 'iframeWrapper' | 'iframe';
interface IStyleProps { isShowingIframe: boolean }

const useStyles = createUseStyles<IStyleKeys, IStyleProps>({
    spinnerWrapper: {
        position: 'relative',
        height: '100%',
        width: '100%',
        display: ({isShowingIframe}) => isShowingIframe ? 'none' : ''
    },

    iframeWrapper: {
        position: 'relative',
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        backgroundColor: 'white',
        display: ({isShowingIframe}) => isShowingIframe ? '' : 'none',

        '& > iframe': {
            height: '100%',
            width: '100%',
            border: 'none',
            overflow: 'hidden',
        }
    },

    iframe: {
        color: '#000043',
        backgroundColor: 'white',
    }
});

export const InsuranceQuotePage: React.FC = () => {
    // Intentionally not using usePageView() here. Instead we're waiting to
    // track this until below, when we know we know we don't need to enforce auth.

    const alert = useInstance(AlertPresenter);
    const history = useInstance(HistoryViewModel);
    const stateCode = history.getCurrentSearchParams().get('state') || undefined;

    const log = useInstance(Logger);
    const devSettings = useInstance(DevSettings);
    const tracker = useInstance(TrackingService);

    // to simulate an error for test purposes
    const simulateErrorStep = history.popSearchParam({ key: 'simulateErrorStep' }) as SectionHeaderInfo['id'];
    const simulateError = simulateErrorStep ? { step: simulateErrorStep } : undefined;

    const [hasFailed, setHasFailed] = useState(false);
    const [isShowingIframe, setIsShowingIFrame] = useState(devSettings.alwaysShowStereFrame || false);

    const classes = useStyles({ isShowingIframe });

    const { STERE_API_KEY, STERE_ENV, STERE_DOMAIN } = useInstance(EnvConfiguration);

    const operator = useInstance(OperatorState);

    const insuranceState = useInstance(InsuranceState);
    useSubscription(() => insuranceState);

    const completedStepRef = useRef<IStereEventType>('STERE_APPLICATION_STARTED');
    const applicationDataRef = useRef<any>();

    const flattenQuote = (quote: any) => {
        const { premium, ...rest } = quote;
        return { ...rest, ...premium };
    };

    const receiveMessage = (event: { origin: string; data: any }) => {
        if (event.origin === 'http://localhost:3000' && ['react-devtools-content-script', 'react-devtools-bridge'].includes(event.data?.source)) {
            return;
        }
        else if (event.origin === `https://${STERE_DOMAIN}`) {
            log.info('Received post message event from stere', event.data);

            const type: IStereEventType = event.data.type;
            const metaData = event.data.metaData;

            if (type === 'SHOW_IFRAME') {
                if (!hasFailed) {
                    setIsShowingIFrame(true);

                    // set a body class for styling outside react (e.g. intercom launcher)
                    // (this is removed in unload function returned from useEffect below)
                    document.body.classList.add('insurance-iframe-open');
                }
            }
            else if (['EXIT', 'EXIT_ON_FAILURE'].includes(type)) {
                history.replace('/insurance');
            }
            else if (type === 'FAILURE') {
                if (!hasFailed) {
                    setHasFailed(true);
                    log.error('Stere insurance frame failed', event.data);
                }
            }
            else if (type === 'STERE_APPLICATION_STARTED') {
                tracker.track('Stere Application Started', () => metaData);
                insuranceState.setState({ hasPendingApplication: true });
            }
            else if (type === 'STERE_APPLICATION_IN_PROGRESS') {
                tracker.track('Stere Application In Progress', () => metaData);
                insuranceState.setState({ hasPendingApplication: true });
            }
            else if (type === 'STERE_APPLICATION_COMPLETED') {
                completedStepRef.current = type;
                tracker.track('Stere Application Completed', () => {
                    const { applicationData, ...rest } = metaData;
                    const eventData = { ...rest, ...applicationData };

                    // save event data so we can resend it with the "Stere Checkout Completed" event
                    applicationDataRef.current = eventData;

                    return eventData;
                });

                if (!operator.hasFullContactInfo()) {
                    const { applicant_first_name: firstName, applicant_last_name: lastName, applicant_phone_number: phone } = metaData.applicationData;
                    operator.save({ firstName, lastName, phone }).then(() => {
                        tracker.track('Pilot Info Entered', () => ({ firstName, lastName, phone, from: 'Insurance' }));
                    });
                }
            }
            else if (type === 'STERE_QUOTES_MISSING') {
                completedStepRef.current = type;
                tracker.track('Stere Quotes Missing', () => metaData);
            }
            else if (type === 'STERE_QUOTES_GENERATED') {
                completedStepRef.current = type;
                tracker.track('Stere Quotes Generated', () => {
                    const { quotes, ...rest } = metaData;
                    return { ...rest, quotes: quotes.map(flattenQuote) };
                });
            }
            else if (type === 'STERE_QUOTES_SELECTED') {
                completedStepRef.current = type;
                tracker.track('Stere Quotes Selected', () => {
                    const { selectedQuotes: [quote], ...rest } = metaData;
                    return { ...rest, ...flattenQuote(quote) };
                });
            }
            else if (type === 'STERE_CHECKOUT_DECLINED') {
                completedStepRef.current = type;
                tracker.track('Stere Checkout Declined', () => {
                    const { selectedQuotes: [quote], ...rest } = metaData;
                    return { ...rest, ...flattenQuote(quote) };
                });
            }
            else if (type === 'STERE_CHECKOUT_COMPLETED') {
                completedStepRef.current = type;
                tracker.track('Stere Checkout Completed', () => {
                    const { selectedQuotes: [quote], applicationId, payment, ...rest } = metaData;

                    // retrieve application data we saved from the "Stere Application Completed" event
                    const applicationData = (applicationDataRef.current?.applicationId === applicationId ? applicationDataRef.current : undefined);

                    return { ...applicationData, applicationId, ...rest, ...flattenQuote(quote), ...payment };
                });
                insuranceState.setState({ hasPendingApplication: false });
            }
        }
        else {
            log.info('Received unexpected post message event', { origin, data });
        }
    };

    const data: StereInsuranceData<
        ValidPrefillQuestionIDsForDRONE,
        ValidReadOnlyQuestionIDsForDRONE
    > = {
        read_only_values: {},
        prefill_values: {
            applicant_email: operator.getEmail(),
            applicant_first_name: operator.state.firstName || '',
            applicant_last_name: operator.state.lastName || '',
            applicant_phone_number: operator.state.phone || '',
            applicant_state: stateCode
        } as any // TODO remove "as any" when stere has updated typescript types
    };

    const sectionHeaderInfo: SectionHeaderInfo[] = [{
        // we skip this step
        id: 'Start',
        showTitle: false,
        showSubTitle: false
    }, {
        id: 'Application',
        showTitle: false,
        showSubTitle: false
    }, {
        id: 'Quote',
        title: 'Your custom quote is ready for review!',
        subTitle: 'Please choose Annual or Monthly coverage and review your policy'
    }, {
        id: 'Payment',
        title: 'Last step, make a payment',
        subTitle: 'Add a credit card to finalize your policy and get protected!'
    }, {
        id: 'Confirmation',
        showTitle: false,
        showSubTitle: false
    }];

    useEffect(() => {
        // guest must sign up before getting insurance
        if (!operator.ensureLoggedIn({ from: 'Insurance', returnTo: '/insurance/quote' })) {
            history.back();
            return;
        }

        const startTimestamp = Date.now();

        Promise.resolve().then(async () => {
            await tryAndCatch(insuranceState.load, log.error);

            if (insuranceState.state.loaded) {

                // now we know we're proceeding, we can track the page view
                tracker.startPageView('Insurance iFrame', () => ({
                    policyCount: insuranceState.state.policies?.length,
                    isResuming: insuranceState.state.hasPendingApplication
                }));

                const clientConfig: Config = {
                    auth: {
                        'X-API-KEY': STERE_API_KEY,
                        applicant_id: insuranceState.getSecretApplicantId(),
                        product_selection: ['DRONE'], // this is what caused the back button issue
                    },
                    data,
                    styles,
                    clientUrl: '*',
                    sectionHeaderInfo,
                    showPaymentSummaryPage: false,
                    simulateError,
                };

                createIframe(
                    'parentContainer',
                    'insuranceFrame',
                    clientConfig,
                    'us', // country
                    STERE_ENV,
                    classes.iframe
                );
            }
        });

        window.addEventListener('message', receiveMessage);

        return () => {
            tracker.track('Insurance iFrame Closed', () => ({
                timeOnPage: (Date.now() - startTimestamp) / 1000,
                lastStepCompleted: completedStepRef.current,
                hasFailed
            }));

            window.removeEventListener('message', receiveMessage);

            document.body.classList.remove('insurance-iframe-open');
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!insuranceState.state.loaded && insuranceState.state.error) {
        return <InsuranceErrorPage />;
    }

    const onClose = () => {
        alert.showAlert({
            header: 'Are you sure you want to exit your application?',
            message: 'You can always come back, but you’re so close to getting an instant quote!',
            buttons: [
                {
                    text: 'Exit',
                    handler: () => {
                        tracker.track('Insurance iFrame Exit Alert Continued', () => ({ selection: 'Exited' }));
                        history.replace('/insurance');
                    }
                },
                {
                    text: 'Get Quote',
                    handler: () => {
                        tracker.track('Insurance iFrame Exit Alert Continued', () => ({ selection: 'Stayed' }));
                    }
                }
            ]
        });
    };

    return (
        <Page title="Insurance" onClose={onClose} fullWidth>

            <div className={classes.spinnerWrapper}>
                <Spinner name="crescent" centered />
            </div>

            <div id='parentContainer' className={classes.iframeWrapper} />

        </Page>
    );
};
