import { Group } from '../ComponentLibrary';
import { colors } from '../common';
import { useState } from 'react';
import { FatSlider, FatSliderProps } from './FatSlider';

const SliderWithLabel = ({ label, ...props }: Partial<FatSliderProps> & { label: string }) => {
    const [value, setValue] = useState(50);
    return (
        <div style={{display: 'flex'}} key={props.color}>
            <p style={{width: 80}}>{label}</p>
            <FatSlider {...props} min={0} max={100} value={value} onChange={v => setValue(v)} />
            <p style={{width: 50, textAlign: 'right'}}>{Math.round(value)}</p>
        </div>
    );
};

const DemoFatSlider: Group & React.FC = () => {
    return (
        <div>
            {colors.filter(c => c !== 'light').map(color =>
                <SliderWithLabel color={color} label={color} />
            )}
            <SliderWithLabel labelFormatter={v => `${v} ft`} label="formatted" />
            <SliderWithLabel step={10} label="step=10" />
        </div>
    );
};

DemoFatSlider.componentName = 'FatSlider';

export default DemoFatSlider;
