import moment from 'moment';
import { AnnouncementDialogState } from '../components/dialog/AnnouncementDialogState';
import { BufferedEventBus } from '../events/BufferedEventBus';
import { OperatorState } from '../profile/OperatorState';
import { Clock } from '../support/Clock';
import { Device } from '../support/Device';
import { DevSettings } from '../support/DevSettings';
import { RevenueCatModel } from './revenue-cat/RevenueCatModel';
import { IAsyncSmartStorageProvider, StorageProvider } from '../support/StorageProvider';
import { ITriggerPaywallSource, PaywallDialogViewModel } from './PaywallDialogViewModel';
import { Logger } from '../support/debug/Logger';

interface IPaywallNudgerState {
    nudges: number;             // how many nudges have we given the user?
    timerStart: string;         // time app first started OR last saw paywall OR last nudge
    pinDrops: number;           // how many pin drops/address searches since timerStart
    hasPlus: boolean;           // is user currently upgraded to AutoPylot+?
    plusStartedAt?: string;     // when we first detected they had plus
    plusEndedAt?: string;       // when we first detected they did not have plus
    delayFirstNudge?: boolean;  // set true if user might have seen legacy announcement
}

export class PaywallNudgerStorage {
    private storage: IAsyncSmartStorageProvider<IPaywallNudgerState>;
    static inject = () => [StorageProvider];
    constructor(storageProvider: StorageProvider) {
        this.storage = storageProvider.getAsyncJSONProvider<IPaywallNudgerState>('paywall-nudger');
    }
    get = async () => this.storage.get();
    set = async (state: IPaywallNudgerState) => this.storage.set(state);
}

export class PaywallNudger {

    private state: IPaywallNudgerState = { hasPlus: false, nudges: 0, pinDrops: 0, timerStart: new Date().toISOString() };

    static inject = () => [
        BufferedEventBus,
        AnnouncementDialogState,
        RevenueCatModel,
        PaywallDialogViewModel,
        OperatorState,
        Device,
        DevSettings,
        Clock,
        Logger,
        PaywallNudgerStorage
    ];
    constructor(
        private events: BufferedEventBus,
        private dialogState: AnnouncementDialogState,
        private revenueCat: RevenueCatModel,
        private paywallState: PaywallDialogViewModel,
        private operator: OperatorState,
        private deviceInfo: Device,
        private devSettings: DevSettings,
        private clock: Clock,
        private log: Logger,
        private storage: PaywallNudgerStorage,
    ) {}

    start = async () => {
        try {
            // disabled on web
            if (this.deviceInfo.platform === 'web' && !this.devSettings.simulateNative) {
                return;
            }

            // need to await here to be sure we have latest state before events start firing
            const storedState = await this.storage.get();

            if (storedState) {
                this.state = storedState;
            } else {
                // when starting nudger for the first time, and user accepted terms more than an hour ago
                // set flag to delay their first nudge (in case they recently saw the old announcement)
                if (moment(this.clock.now()).diff(this.operator.state.signupTime, 'h') > 1) {
                    this.updateState({ delayFirstNudge: true });
                }
            }

            this.events.on('PaywallOpened', () => {
                this.handlePlusChange();
                this.updateState({ timerStart: new Date().toISOString() });
            });

            this.events.on('AppStarted', () => {
                this.handlePlusChange();
                this.maybeNudge();
            });

            this.events.on('AppForegrounded', () => {
                this.handlePlusChange();
                this.maybeNudge();
            });

            this.events.on('AddressSelected', () => {
                this.handlePlusChange();
                this.updateState({ pinDrops: this.state.pinDrops + 1 });
                this.maybeNudge();
            });

            this.events.on('PinDropped', () => {
                this.handlePlusChange();
                this.updateState({ pinDrops: this.state.pinDrops + 1 });
                this.maybeNudge();
            });

        } catch (e: any) {
            // don't prevent app starting
            e.message = `Failed to initialize PaywallNudger: ${e.message}`;
            this.log.error(e);
        }
    };

    // entry point from PaywallMenuButton when user is guest
    open = ({ triggerSource } : { triggerSource: ITriggerPaywallSource }) => {
        const isGuest = this.operator.isGuest();
        const announcementKey = isGuest ? 'guest-paywall-nudge-v1' : 'signed-in-paywall-nudge-v2';
        const imageFile = `${announcementKey}.svg`;

        this.dialogState.open({
            announcementKey,
            imagePath: `/assets/images/paywall/${imageFile}`,
            onContinue: () => this.paywallState.open({ triggerSource })
        });

        this.updateState({ nudges: this.state.nudges + 1, timerStart: new Date().toISOString() });
    };

    private updateState = (updates: Partial<IPaywallNudgerState>) => {
        this.state = { ...this.state, ...updates };
        this.storage.set(this.state);
    };

    private getThresholds = (nudges: number) => {
        if (!nudges) {
            return { pinDrops: 5, days: 2 };
        }
        if (nudges === 1) {
            return { pinDrops: 15, days: 5 };
        }
        if (nudges === 2) {
            return { pinDrops: 25, days: 5 };
        }
        // 50, 75, etc
        return { pinDrops: (nudges - 1) * 25, days: 7 };
    };

    private handlePlusChange = () => {
        const hasPlus = this.revenueCat.hasUpgraded();

        if (!this.state.hasPlus && hasPlus) {
            // if user just upgraded, record start time
            this.updateState({ hasPlus, plusStartedAt: new Date().toISOString() });
        } else if (this.state.hasPlus && !hasPlus) {
            // if user just downgraded, record end time, and reset state to 2nd nudge
            this.updateState({ hasPlus, plusEndedAt: new Date().toISOString(), nudges: 1, pinDrops: 0, timerStart: new Date().toISOString() });
        }
    };

    private maybeNudge = () => {
        const elapsedSeconds = moment(this.clock.now()).diff(this.state.timerStart, 'seconds');
        const dayInSeconds = (this.devSettings.rapidPaywallNudge ? 30 : 60*60*24);
        const elapsedDays = elapsedSeconds / dayInSeconds;

        // don't show if user has plus
        if (this.state.hasPlus) {
            return;
        }

        // don't show if less than 2 days since last nudge
        if ((this.state.nudges > 0 || this.state.delayFirstNudge) && elapsedDays < 2) {
            return;
        }

        // don't show if we haven't hit one of the thresholds
        const thresholds = this.getThresholds(this.state.nudges);
        if (this.state.pinDrops < thresholds.pinDrops && elapsedDays < thresholds.days) {
            return;
        }

        this.open({ triggerSource: 'nudge' });
    };
}
