import { Spinner } from './Spinner';

/**
 * Renders children with a blur filter and a spinner over it.
 * Intended to be used temporarily while waiting.
 */
export const Blur: React.FC<{active: boolean}> = ({ children, active }) => {
    const style = {filter: active ? 'blur(2px)' : '', opacity: active ? 0.3 : 1, transition: 'all 2s ease-in-out'};
    return (
        <>
            <div style={style}>{children}</div>
            {active && <Spinner centered={true} />}
        </>
    );
};
