import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useEffect } from 'react';
import { HistoryViewModel } from './HistoryViewModel';
import { MissionsState } from '../missions/model/MissionsState';

export const DefaultPage: React.FC = () => {

    const history = useInstance(HistoryViewModel);
    const missionsState = useInstance(MissionsState);

    useEffect(() => {
        /**
         * Due to 7.0.1 performance rules, which put an invalidation check in the mission creation flow.
         *
         * And since we now have caching of mission state (so we might not hit this desired redirect til your 2nd app start).
         *
         * This redirect is debateably not worth it anymore. (consistent with, it wasn't a priority to redirect in the
         *   scenario mentioned above, where the cache was wrong).
         *
         * Product is on the fence when to remove it ("ideally when we enhance invalidation UX to be on par with the emails,
         *    and deep link directly into invalidated missions")
         *
         * But we shouldn't invest further in it. Eg if we see more test flake around this, consider ripping it out over fixing the flake.
         */
        const invalidMissions = missionsState.get().missions.filter(m => m.mustCancel());

        if (invalidMissions.length) {
            history.replace(`/missions/invalid`);
        } else {
            history.replace('/map');
        }
    }, [history, missionsState]);

    return null;
};
