import { ToastOptions } from '@ionic/react';
import { HookOverlayOptions } from '@ionic/react/dist/types/hooks/HookOverlayOptions';
import { DevSettings } from '../../support/DevSettings';

export type IToastButton = { text: string,  handler: () => boolean | void | Promise<void> };

export interface IToastOptions {
    message: string;
    header?: string;
    buttons?: IToastButton[];
    duration?: number;
}

export class ToastPresenter {
    static inject = () => [DevSettings];
    constructor(private devSettings: DevSettings){}

    private isVisible = false;

    private queue: IToastOptions[] = [];

    // unusual naming on purpose, this is called once by ToastBinder and isn't part of the surface area other consumers should use
    _setToastMethods = ({ showToast }: {showToast: (options: ToastOptions & HookOverlayOptions) => Promise<void>}) => {
        this._showToast = showToast;

        // if something was already queued up, show it
        this.showNextIfPossible();
    };

    showNextIfPossible = () => {
        if (this.isVisible || this.queue.length === 0){
            return;
        }

        const options = this.queue.shift();
        this.isVisible = true;
        this._showToast!({ ...options, position: 'top', onDidDismiss: () => {

            // without this timeout, when showNextIfPossible calls _showToast
            // it silently fails, likely because its state still thinks a toast is visible
            setTimeout(() => {
                this.isVisible = false;
                this.showNextIfPossible();
            });

        } });
    };

    private _showToast?: (options: ToastOptions & HookOverlayOptions) => Promise<void>;

    /**
     * Never add your own dismiss, because showToast will add it for you when appropriate.
     */
    showToast = async (options: IToastOptions) => {
        if (!options.buttons){
            options.buttons = [];
        }

        if (this.devSettings.disableToastAutoDismiss && options.duration){
            options.duration = undefined;
        }

        if (options.buttons.length === 0 && !options.duration){
            options.buttons.push({
                // TODO: this isn't yet user facing, when it is, switch to icon (Jeremy has this in his git history somewhere (or his brain))
                text: 'X',
                handler: () => {}
            });
        }

        this.queue.push(options);

        this.showNextIfPossible();
    };
}
