import { Size } from '../../components/common';
import { WindDirectionIcon } from '../../components/icons/Icons';

export const WindIndicator: React.FC<{direction: number, size?: Size }> = ({ direction, size }) => {
    return (
        <span style={{lineHeight: 0, display: 'inline-block', transform: `rotate(${direction}deg)`}}>
            <WindDirectionIcon color="medium" size={size} />
        </span>
    );
};
