import { IonList, IonMenu, IonText } from '@ionic/react';
import { useSubscription } from '@meraki-internal/state';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { PageContent } from '../components/page/PageContent';
import { MapIcon, PlaneIcon, ChatIcon, InsuranceIcon, ListIcon, LightbulbIcon, SettingsIcon } from '../components/icons/Icons';
import { useEffect, useRef, useState } from 'react';
import { mediaNotLargerScreen } from '../support/helpers/breakpoints';
import { AlertPresenter } from '../app/AlertBinder';
import { IntercomService } from '../app/intercom/IntercomService';
import { MissionsState } from '../missions/model/MissionsState';
import { createUseStyles } from 'react-jss';
import { IfNative } from '../support/IfNative';
import { MenuViewModel } from './MenuViewModel';
import { MenuItem } from './MenuItem';
import { MenuHeader } from './MenuHeader';
import { PaywallMenuButton } from '../paywall/PaywallMenuButton';

export const MENU_COLLAPSED_WIDTH = 66; // includes shadow

type IStyleKeys = 'menu' | 'menuCollapsed' | 'menuShadow';
interface IStyleProps { animating: boolean }

const useStyles = createUseStyles<IStyleKeys, IStyleProps>({
    menu: {
        zIndex: ({animating}) => animating ? 9999 : 10001
    },
    menuCollapsed: {
        [mediaNotLargerScreen]: {
            display: 'none'
        },
        zIndex: 10000,
        display: 'flex',
        width: MENU_COLLAPSED_WIDTH - 2,
        '&::part(container)': {
            transform: 'unset',
            WebkitTransform: 'unset',
        },
        '&::part(backdrop)': {
            display: 'none'
        }
    },
    menuShadow: {
        [mediaNotLargerScreen]: {
            display: 'none'
        },
        zIndex: 9998,
        position: 'absolute',
        top: 0,
        left: 0,
        width: MENU_COLLAPSED_WIDTH - 2,
        height: '100%',
        boxShadow: '0px 0px 2px 1px var(--ion-color-step-200)'
    }
});

const MenuContent: React.FC = () => {
    const menuVM = useInstance(MenuViewModel);

    const alert = useInstance(AlertPresenter);

    const intercom = useInstance(IntercomService);
    useSubscription(() => intercom);

    const missions = useInstance(MissionsState);
    useSubscription(() => missions);

    const onClickChat = async () => {
        if (intercom.isEnabled()) {
            await intercom.displayMessenger();
        } else {
            alert.showAlert({
                header: 'Sorry! Something went wrong',
                message: 'We were unable to load the chat messenger, please try again later.'
            });
        }
    };

    return (
        <PageContent noScroll onClick={() => menuVM.toggle()}>

            <MenuHeader />

            <IonList>
                <MenuItem icon={MapIcon} title="Map" url="/map" small bold />
                <MenuItem icon={PlaneIcon} title="Missions" url="/missions" reminderCount={missions.getReminderCount()} small bold />

                <MenuItem icon={ListIcon} title="Checklists" url="/checklists" small bold />

                <MenuItem icon={InsuranceIcon} title="Drone Insurance" url="/insurance" small bold newFeatureKey="insurance" />

                <MenuDivider />

                <IfNative>
                    <MenuItem title="Discover AutoPylot" icon={LightbulbIcon} url="/discover" small />
                    <MenuItem title="Chat" icon={ChatIcon} onClick={onClickChat} small reminderCount={intercom.state.unreadCount} />
                </IfNative>

                <MenuItem title="Settings" icon={SettingsIcon} url="/settings" otherUrls={['/invite', '/calendar']} small />

                <MenuDivider />

                <PaywallMenuButton />
            </IonList>

            <div style={{position: 'absolute', bottom: 30, width: '100%', maxHeight: '10vh', textAlign: 'center'}}>
                <div><IonText color="medium">FAA-Approved</IonText></div>
                <img src="./assets/images/faa-badge-group.png" alt="FAA Approved" width={140} />
            </div>
        </PageContent>
    );
};

const MenuDivider = () => {
    return (
        <hr style={{margin: '10px 0 10px 64px', backgroundColor: '#d0d0d0'}} />
    );
};

export const Menu: React.FC<{ className?: string, outlet: string }> = ({ className, outlet }) => {
    const [animating, setAnimating] = useState(false);
    const classes = useStyles({ animating });

    const menuRef = useRef<HTMLIonMenuElement>(null);
    const menuVM = useInstance(MenuViewModel);

    useEffect(() => {
        if (menuRef && menuRef.current){
            menuVM.init(menuRef.current);
        }
    }, [menuRef, menuVM]);

    const willOpen = () => {
        setAnimating(true);
        menuVM.open();
    };

    const didOpen = () => {
        setAnimating(false);
    };

    const willClose = () => {
        setAnimating(true);
        menuVM.close();
    };

    const didClose = () => {
        setAnimating(false);
    };

    return (
        <div className={className}>
            <IonMenu className={classes.menuCollapsed} contentId={outlet} type="overlay" swipeGesture={false}>
                <MenuContent />
            </IonMenu>
            <div className={classes.menuShadow} />
            <IonMenu className={classes.menu} contentId={outlet} type="overlay" ref={menuRef} swipeGesture={false} onIonWillOpen={willOpen} onIonDidOpen={didOpen} onIonWillClose={willClose} onIonDidClose={didClose}>
                <MenuContent />
            </IonMenu>
        </div>
    );
};
