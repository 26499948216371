import debounce from 'lodash/debounce';
import { IonItem, IonLabel, IonList, IonSearchbar } from '@ionic/react';
import { useMemo, useRef, useState } from 'react';
import { ILocation } from '../../support/model/ILocation';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { AlertPresenter } from '../../app/AlertBinder';
import { TrackingService } from '../../support/tracking/TrackingService';
import { ICoordinate } from '../../support/model/ICoordinate';
import { useBreakpoints } from '../../support/hooks/useBreakpoints';

export interface LocationSearchProps {
    onSearch: (searchText: string) => Promise<ILocation[]>;
    onCoordinates?: (coordinates: ICoordinate) => void;
    onSelect?: (location?: ILocation) => void;
}

const Message: React.FC<{text: string}> = ({ text }) => (
    <p data-type="search-message" style={{fontSize: 14, marginLeft: 20}}><i>{text}</i></p>
);

export const LocationSearch: React.FC<LocationSearchProps> = ({ onSearch, onCoordinates, onSelect }) => {
    const tracker = useInstance(TrackingService);

    const { isLargerScreen } = useBreakpoints();

    const [searchText, setSearchText] = useState('');
    const [list, setList] = useState<ILocation[] | undefined>(undefined);
    const alert = useInstance(AlertPresenter);

    const lastTime = useRef(0);
    const debouncedSearch = useMemo(
        () => debounce(
            async (text: string) => {
                try{
                    const currTime = Date.now();
                    const results = await onSearch(text);

                    // don't update list if we already received a newer response
                    if (currTime > lastTime.current) {
                        lastTime.current = currTime;
                        setList(results);
                    }
                }
                catch (err) {
                    alert.showAndLogError(err);
                }
            },
            300,
            { leading: true, trailing: true, maxWait: 900 }
        ),
        [onSearch, alert]
    );

    // interpret search text like {"lat":45,"lng":-73}
    const getCoordinatesFromText = (text: string) => {
        try {
            if (text.startsWith('{')) {
                const { lat, lng } = JSON.parse(text);
                if (!isNaN(lat) && lat >= -90 && lat <= 90 && !isNaN(lng) && lng >= -180 && lng <= 180) {
                    return { lat: Number(lat), lng: Number(lng) };
                }
            }
        } catch (e) {
        }
        return undefined;
    };

    // highlights in bold any words in "text" found in "address"
    const highlightWords = (address: string, text: string) => {
        try {
            const searchWords = text.replace(/,/g, '').split(' ');
            const output = address.replace(new RegExp(`(${searchWords.join('|')})`, 'gi'), '<b>$1</b>');
            return <span dangerouslySetInnerHTML={{__html: output}} />;
        } catch (e) {
            // if something goes wrong, just return address unhighlighted
            // (unusual characters in the search text can create an invalid regexp)
            return address;
        }
    };

    const formatLocation = (location: ILocation): string => {
        const { address: { name, street, city, state } } = location;
        // don't return parts we don't have
        return [name, street, city, state].filter(p => Boolean(p)).join(', ');
    };

    const onInput = async (e: any) => {
        const text: string = e.target.value || '';
        setSearchText(text);

        if (onCoordinates) {
            const coordinates = getCoordinatesFromText(text);
            if (coordinates) {
                console.log('Detected lat/lng json in search box', coordinates);
                onCoordinates(coordinates);
                return;
            }
        }

        debouncedSearch(text);
    };

    const onClickItem = (location: any) => {
        setSearchText('');
        setList(undefined);
        if (onSelect) {
            onSelect(location);
        }
    };

    const onClear = () => {
        if (onSelect) {
            onSelect(undefined);
        }
    };

    const onBlur = () => {
        if (searchText) {
            tracker.track('Address Searched', () => ({ searchText }));
        }
    };

    const style = {
        paddingLeft: 0,
        paddingRight: 0,
        marginBottom: 5,
        '--background': isLargerScreen ? 'white' : undefined,
        '--box-shadow': isLargerScreen ? 'rgba(0, 0, 0, 0.14) 0px 2px 2px 0px' : undefined,
        '--icon-color': 'var(--ion-color-primary',
        '--border-radius': '10px'
    };

    return (
        <div className="smartlook-show" data-type="location-search" style={{position: 'relative'}}>
            <IonSearchbar
                style={style}
                value={searchText}
                onIonInput={onInput}
                onIonBlur={onBlur}
                placeholder="Where do you want to fly?"
                autocomplete="street-address"
                onIonClear={onClear}
            >
            </IonSearchbar>
            {searchText && !list &&
                <Message text="Continue typing to see a list of addresses..." />
            }
            {list && searchText && !list.length &&
                <Message text="No matching addresses found" />
            }
            {list && list.length > 0 &&
                <div style={{position: 'absolute', top: 60, left: 0, zIndex: 50, width: '100%'}}>
                    <IonList style={{width: '100%', height: '100%'}}>
                        {list.map((location, i) => (
                            <IonItem style={{fontSize: 14}} key={i} onClick={() => onClickItem(location)}>
                                <IonLabel>
                                    {highlightWords(formatLocation(location), searchText)}
                                </IonLabel>
                            </IonItem>
                        ))}
                    </IonList>
                </div>
            }
        </div>
    );
};
