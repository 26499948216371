import { useSubscription } from '@meraki-internal/state';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { DrawerLabel } from '../../components/drawer/DrawerLabel';
import { DrawerFieldText } from '../../components/drawer/DrawerFieldText';
import { Location } from './Location';
import { MissionTimes } from '../../missions/components/MissionTimes';
import { MissionTimesEditor } from '../../missions/components/MissionTimesEditor';
import { EditableMissionState } from '../../mission/model/EditableMissionState';
import { MapViewModel } from '../model/MapViewModel';
import { FlightChecklist } from '../../flight-checklist/FlightChecklist';
import { RefreshTimer } from '../../components/refresher/RefreshTimer';
import { DrawerButtons } from '../../components/drawer/DrawerButtons';
import { Button } from '../../components/button/Button';
import { FlightChecklistDrawerLabel } from '../../components/drawer/FlightChecklistDrawerLabel';
import { HistoryViewModel } from '../../app/HistoryViewModel';
import { useBreakpoints } from '../../support/hooks/useBreakpoints';

// TODO: can reload map on view mission map page
// TODO: rename MissionSummaryMapDrawer
export const ViewMissionOnMapDrawer = ({ mission: vm, isEditingTimes = false }: { mission: EditableMissionState, isEditingTimes?: boolean }) => {
    const history = useInstance(HistoryViewModel);

    useSubscription(() => vm);
    const mapViewModel = useInstance(MapViewModel);
    useSubscription(() => mapViewModel);

    // need padding if drawer on side
    const { isLargerScreen } = useBreakpoints();
    const paddingTop = (isLargerScreen ? 50 : undefined);

    return (
        <div data-id="view-mission-drawer" style={{paddingTop}}>

            <RefreshTimer getIntervalMs={vm.getAutoRefreshIntervalMs} handler={vm.refresh} />

            <DrawerLabel text="Location" />
            <DrawerFieldText dataSet={{type: 'location'}}><Location mission={vm} /></DrawerFieldText>

            <DrawerLabel text="Flight Info" />
            {isEditingTimes &&
                <MissionTimesEditor mission={vm} source='Mission Area and Time' />
            }
            {!isEditingTimes &&
                <MissionTimes mission={vm} />
            }

            <DrawerLabel text="Max Altitude" />
            <DrawerFieldText dataSet={{type: 'altitude'}}>{vm.getMaxAltitude()}&nbsp;ft</DrawerFieldText>

            <FlightChecklistDrawerLabel />
            <FlightChecklist mission={vm} />

            <DrawerButtons>
                <Button color="secondary" fill="outline" dataType="back-button" onClick={() => history.back(vm.pages.details.path())}>Back</Button>
            </DrawerButtons>
        </div>
    );
};
