import { IonSegment, IonSegmentButton } from '@ionic/react';

export const SegmentInput: React.FC<{value?: string, onChange?: (value: string) => void}> = ({ value, onChange, children }) => {
    const handleChange = (e: any) => {
        if (onChange) {
            onChange(e.detail.value);
        }
    };

    return (
        <IonSegment style={{marginTop: 5, marginBottom: 10}} value={value} onIonChange={handleChange}>
            {children}
        </IonSegment>
    );
};

export const SegmentButton: React.FC<{ value?: string }> = ({ value, children }) => {
    return (
        <IonSegmentButton value={value}>
            <div style={{paddingTop: 5, paddingBottom: 5}}>
                {children}
            </div>
        </IonSegmentButton>
    );
};
