import { DrawerFieldText } from '../../components/drawer/DrawerFieldText';
import { DrawerLabel } from '../../components/drawer/DrawerLabel';
import { NewMissionState } from '../../missions/model/NewMissionState';
import { FlightChecklist } from '../../flight-checklist/FlightChecklist';
import { Location } from './Location';
import { MissionTimes } from '../../missions/components/MissionTimes';
import { usePageView } from '../../support/tracking/usePageView';
import { DrawerButtons } from '../../components/drawer/DrawerButtons';
import { Button } from '../../components/button/Button';
import { useSubscription } from '@meraki-internal/state';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { LastAuthorizedRadiusModel } from '../mission-pin-and-radius/LastAuthorizedRadiusModel';
import { FlightChecklistDrawerLabel } from '../../components/drawer/FlightChecklistDrawerLabel';

export const MissionReviewAndSubmitPage: React.FC<{vm: NewMissionState, onBack: () => void, onContinue: () => Promise<void>}> = ({ vm, onBack, onContinue }) => {
    usePageView('Review Mission');

    useSubscription(() => vm);
    const lastAuthorizedModel = useInstance(LastAuthorizedRadiusModel);
    useSubscription(() => lastAuthorizedModel);

    const missionRadius = vm.getMissionRadius();

    const disabled = (missionRadius?.canFly() === 'cannot-fly');
    const continueLabel = (missionRadius?.canFly() === 'can-fly' ? 'Save' : 'Submit');

    return (
        <div data-id="mission-review-drawer">
            <h4>Review Info and Checklist</h4>

            <DrawerLabel text="Location" />
            <DrawerFieldText dataSet={{type: 'location'}}><Location mission={vm} /></DrawerFieldText>

            <DrawerLabel text="Flight Info" />
            <MissionTimes mission={vm} />

            {missionRadius &&
                <>
                    <DrawerLabel text="Max Altitude" />
                    <DrawerFieldText dataSet={{type: 'altitude'}}>{vm.state.maxAltitude || 0}&nbsp;ft</DrawerFieldText>

                    <FlightChecklistDrawerLabel />
                    <FlightChecklist mission={vm} />

                    <DrawerButtons>
                        <Button color="secondary" fill="outline" dataType="cancel-button" onClick={onBack}>Back</Button>
                        <Button dataType="create-mission-button" disabled={disabled} onClick={onContinue}>{continueLabel}</Button>
                    </DrawerButtons>
                </>
            }
        </div>
    );
};
