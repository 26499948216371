import { MissionFeatureCollection } from '@autopylot-internal/tiles-client';
import { State } from '@meraki-internal/state';
import { MissionPinAndRadiusOnMapViewModel } from './MissionPinAndRadiusOnMapViewModel';

export class LastAuthorizedRadiusModel extends State<Record<string, never>> {
    lastAuthorizedRadius: MissionFeatureCollection | undefined;

    static inject = () => [MissionPinAndRadiusOnMapViewModel];
    constructor(private pinAndRadius: MissionPinAndRadiusOnMapViewModel){
        super({});
        this.pinAndRadius.subscribe(() => {
            if (!this.pinAndRadius.getRadius()){
                this.lastAuthorizedRadius = undefined;
                this.setState({ });
            }
            else if (this.pinAndRadius.getAuthorizedRadius()){
                this.lastAuthorizedRadius = this.pinAndRadius.getAuthorizedRadius();
                this.setState({ });
            }
        });
    }
}
