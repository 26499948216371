import React, { CSSProperties, useEffect, useRef } from 'react';
import { IonContent } from '@ionic/react';
import { createUseStyles } from 'react-jss';
import { useSubscription } from '@meraki-internal/state';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { MapViewModel } from '../../map/model/MapViewModel';
import { useBreakpoints } from '../../support/hooks/useBreakpoints';
import { DrawerHandle } from './DrawerHandle';
import { DrawerState } from './DrawerState';
import { AttachedControls } from './AttachedControls';

export const SIDE_DRAWER_WIDTH = 400;

const useStyles = createUseStyles({
    scrollingContent: {
        '--padding-top': 0,
        '&::part(scroll)::before': { bottom: 0 },
        '&::part(background)': { transition: 'background-color 0.75s' }
    }
});

interface IScrollingContentProps {
    scrollEvents: boolean;
    onIonScrollEnd: () => void;
    scrollY: boolean;
    className: string;
    background?: string;
};

const ScrollingContent: React.FC<IScrollingContentProps> = ({ className, background, children, ...props }) => {
    const classes = useStyles();
    return <IonContent className={`${className || ''} ${classes.scrollingContent}`} style={{'--background': background}} {...props}>{children}</IonContent>;
};

export interface DrawerProps {
    minHeight: number;
    initialHeight?: number;
    header?: any;
    onResize?: (e: { oldHeight: number, newHeight: number }) => void;
    onScroll?: () => void;
};

export const Drawer: React.FC<DrawerProps> = ({ minHeight, initialHeight, header, onResize, onScroll, children }) => {
    const { isLargerScreen: sideDrawer } = useBreakpoints();

    const drawerRef = useRef<any>();

    const drawerState = useInstance(DrawerState);
    useSubscription(() => drawerState);

    const mapVM = useInstance(MapViewModel);
    useSubscription(() => mapVM);

    useEffect(() => {
        if (minHeight && drawerState.state) {
            drawerState.setMinHeight(minHeight);
            drawerState.setHeight(initialHeight || minHeight);
        }
    }, [drawerState, minHeight]); // eslint-disable-line react-hooks/exhaustive-deps

    if (!drawerState.state) {
        return null;
    }

    const onScrollEnd = () => {
        if (onScroll) {
            onScroll();
        }
    };

    let drawerStyle: CSSProperties = {};
    let headerStyle: CSSProperties = {};

    if (sideDrawer) {
        drawerStyle = {
            position: 'absolute',
            width: `${SIDE_DRAWER_WIDTH}px`,
            height: '100%',
            top: 0,
            left: 0,
            zIndex: 999999,
            maxHeight: drawerState.isMinimized() ? 90 : '100%',
            transition: 'max-height 0.5s cubic-bezier(.11,.98,.58,1.07)'
        };
        headerStyle = {
            background: 'linear-gradient(rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0.4))',
            padding: 'calc(var(--ion-safe-area-top) + 10px) 20px 25px',
            marginBottom: -15
        };
    } else {
        drawerStyle = {
            position: 'fixed',
            width: '100%',
            height: '100%',
            bottom: 0,
            zIndex: 999999,
            borderTopLeftRadius: 12,
            borderTopRightRadius: 12,
            boxShadow: '0px 0px 4px 1px rgb(0 0 0 / 15%)',
            maxHeight: drawerState.state.height + drawerState.state.delta,
            transition: 'max-height 0.5s cubic-bezier(.11,.98,.58,1.07)'
        };
        headerStyle = {
            backgroundColor: `rgba(0, 0, 0, 0.3)`,
            padding: '5px 20px 20px',
            marginBottom: -15,
            borderTopLeftRadius: 12,
            borderTopRightRadius: 12
        };
    }

    const minimizedSideDrawer = sideDrawer && drawerState.isMinimized();

    // side drawer is transparent when minimized
    const background = minimizedSideDrawer ? 'rgba(255,255,255,0)' : 'rgba(255,255,255,1)';

    return (
        <>
            <div ref={drawerRef} style={drawerStyle}>

                <AttachedControls />

                {header &&
                    <div style={headerStyle}>
                        {header}
                    </div>
                }

                {!sideDrawer &&
                    <DrawerHandle drawerRef={drawerRef} onResize={onResize} />
                }

                <ScrollingContent background={background} scrollEvents={!drawerState.state.dragging} onIonScrollEnd={onScrollEnd} scrollY={!drawerState.state.dragging && !minimizedSideDrawer} className='ion-padding'>
                    <div style={{marginTop: sideDrawer ? 'calc(var(--ion-safe-area-top) + 10px)' : undefined, marginBottom: (header ? 55 : 25)}}>
                        {children}
                    </div>
                </ScrollingContent>
            </div>
        </>
    );
};
