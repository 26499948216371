import { IScreenshotablePage } from '../support/screenshots/IScreenshotablePage';
import { MapPage } from './MapPage';
import { NewMissionState } from '../missions/model/NewMissionState';
import { ICoordinate } from '../support/model/ICoordinate';
import { MapViewModel } from './model/MapViewModel';
import { IAddress } from '../support/model/IAddress';
import { OperatorState } from '../profile/OperatorState';
import { FAAVersionAlertManager } from '../app/FAAVersionAlertManager';
import { TrackingService } from '../support/tracking/TrackingService';
import { EnableAndBindMapForScreenshots } from './EnableAndBindMapForScreenshots';
import { MissionPinAndRadiusOnMapViewModel } from './mission-pin-and-radius/MissionPinAndRadiusOnMapViewModel';

interface IMyPageProps {
    newMission?: { address: IAddress, point: ICoordinate, maxAltitude?: number };
}

export default class ScreenshotMapPage implements IScreenshotablePage<IMyPageProps> {
    static inject = () => [
        MapViewModel,
        FAAVersionAlertManager,
        TrackingService,
        OperatorState,
        MissionPinAndRadiusOnMapViewModel
    ];

    constructor(
        private mapViewModel: MapViewModel,
        private versionAlertManager: FAAVersionAlertManager,
        private tracker: TrackingService,
        private operatorState: OperatorState,
        private pinAndRadius: MissionPinAndRadiusOnMapViewModel
    ) { }

    screenId = 'new-mission-step-2';

    ComponentUnderTest = MapPage;

    WrapWith = EnableAndBindMapForScreenshots;

    permutationToProps = async ({ newMission }: IMyPageProps) => {
        this.operatorState.setState({
            firstName: 'Freddy',
            lastName: 'Flyer',
            phone: '802-123-1234',
            email: 'freddy.flyer@gmail.com',
            certificationType: 'part-107',
            agreedToFAAPrivacyStatement: true,
            agreedToLAANCStatement: true,
            agreedToTermsOfService: true,
        });
        let mission: NewMissionState | undefined;

        if (newMission) {
            mission = new NewMissionState({
                onSave: async () => {
                },
                getConcurrentLAANCMissions: () => []
            });

            // TODO: friction from property injection
            mission.mapViewModel = this.mapViewModel;
            mission.operator = this.operatorState;
            mission.versionAlertManager = this.versionAlertManager;
            mission.tracker = this.tracker;
            mission.pinAndRadius = this.pinAndRadius;

            mission.setAddress(newMission.address).setCenter(newMission.point);

            if (newMission.maxAltitude) {
                mission.setMaxAltitude(newMission.maxAltitude);
            }
        }

        return { screenshotConfig: { mission, wizardPage: 2 } };
    };

    permutations: {[key: string]: IMyPageProps} = {
        'mission-altitude-zero': {
            newMission: { address: { street: '12025 Coyote Ridge Road', city: 'Deadwood', state: 'SD', zip: '57732' }, point: { lng: -103.64851, lat: 44.30194 } }
        },
        'mission-altitude-halfway': {
            newMission: { address: { street: '12025 Coyote Ridge Road', city: 'Deadwood', state: 'SD', zip: '57732' }, point: { lng: -103.64851, lat: 44.30194 }, maxAltitude: 200 },
        },
        'mission-altitude-max': {
            newMission: { address: { street: '12025 Coyote Ridge Road', city: 'Deadwood', state: 'SD', zip: '57732' }, point: { lng: -103.64851, lat: 44.30194 }, maxAltitude: 400 },
        }
    };
}
