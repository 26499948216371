
import { MissionFeatureCollection } from '@autopylot-internal/tiles-client';
import { ITFRAdvisoryProperties } from '@autopylot-internal/tiles-client';
import { SecondaryWarningAdvisory } from '../SecondaryAdvisory';
import { SingleTFRAdvisory, TFRLink, TFRValidLine } from './SingleTFRAdvisory';

export interface ICompositeTFRAdvisoryProperties {
    source: 'temporary-flight-restrictions+composite';
    advisoryType: 'block' | 'advise';
    tfrs: ITFRAdvisoryProperties[];
}

export const CompositeTFRAdvisory = (props: ICompositeTFRAdvisoryProperties & { mission: MissionFeatureCollection } ) => {
    const anyBlock = props.tfrs.some(tfr => tfr.advisoryType === 'block');
    if (anyBlock){
        return <CompositeBlockTFRAdvisory {...props} />;
    }

    return <CompositeAdviseTFRAdvisory {...props}  />;

};


export const CompositeBlockTFRAdvisory = (props: ICompositeTFRAdvisoryProperties & { mission: MissionFeatureCollection } ) => {
    if (props.tfrs.length === 1){
        return <SingleTFRAdvisory {...{ ...props.tfrs[0], mission: props.mission }} />;
    }

    return (
        <SecondaryWarningAdvisory header="Temporary Flight Restriction" advisoryType={'block'} GLOBAL_ID={props.tfrs[0].GLOBAL_ID}>
            <>
                Your mission overlaps the following TFRs:
                <ul>
                    {props.tfrs.map(tfr => (
                        <li key={tfr.number}><TFRLink {...tfr} />: <TFRValidLine {...tfr} mission={props.mission} /></li>
                    ))}
                </ul>
                <p>
                    No person may operate a small unmanned aircraft in restricted airspace
                    unless that person has permission from the appropriate authority.
                </p>
                <p>
                    Individuals violating any of these restrictions may be subject to FAA and/or law enforcement action.
                </p>
            </>
        </SecondaryWarningAdvisory>
    );
};

export const CompositeAdviseTFRAdvisory = (props: ICompositeTFRAdvisoryProperties & { mission: MissionFeatureCollection } ) => {
    if (props.tfrs.length === 1){
        return <SingleTFRAdvisory {...{ ...props.tfrs[0], mission: props.mission }} />;
    }

    return (
        <SecondaryWarningAdvisory header="Temporary Flight Restriction" advisoryType={'advise'} GLOBAL_ID={props.tfrs[0].GLOBAL_ID}>
            <>
                The following Temporary Flight Restrictions may impact your mission:
                <ul>
                    {props.tfrs.map(tfr => (
                        <li key={tfr.number}>{tfr.isPointTFR ? 'Is near ': 'Overlaps '}<TFRLink {...tfr} />: <TFRValidLine {...tfr} mission={props.mission} /></li>
                    ))}
                </ul>
            </>
        </SecondaryWarningAdvisory>
    );
};
