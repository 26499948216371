import { MouseEventHandler } from 'react';

export class MenuViewModel  {

    private el: HTMLIonMenuElement | undefined;

    init = (el: HTMLIonMenuElement) => {
        this.el = el;
    };

    open = () => {
        if (this.el) {
            this.el.open();
        }
    };

    close = async () => {
        if (this.el) {
            const isOpen = await this.el.isOpen();
            if (isOpen){
                this.el.close();
            }
        }
    };

    toggle = async () => {
        if (this.el) {
            const isOpen = await this.el.isOpen();
            if (isOpen){
                this.el.close();
            } else {
                this.el.open();
            }
        }
    };

    clickHandler = (onClick?: () => void): MouseEventHandler => {
        return async event => {
            // stop PageContent getting click event and expanding menu
            if (event) {
                event.stopPropagation();
            }
            // close menu and wait for animation to complete
            await this.close();
            await new Promise(res => setTimeout(res, 300));
            // finally, fire the click handler
            if (onClick) {
                onClick();
            }
        };
    };
}
