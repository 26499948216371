import { State } from '@meraki-internal/state';
import { CustomerInfo, INTRO_ELIGIBILITY_STATUS, LOG_LEVEL, PACKAGE_TYPE, Purchases, PurchasesOfferings, PurchasesPackage } from '@revenuecat/purchases-capacitor';
import { Device, DeviceInfo } from '@capacitor/device';
import { ENTITLEMENTS, IPaymentTerm } from './RevenueCatModel';
import { IFreeTierLimits } from './IOfferMeta';
import { EnvConfiguration } from '../../app/config/EnvConfiguration';
import { AutoPylotPrincipal } from '../../auth/AutoPylotPrincipal';
import { Logger } from '../../support/debug/Logger';
import { PurchasesErrorAdapter } from './PurchasesErrorAdapter';

export class RevenueCatSDKModel extends State<Record<string, never>> {
    static inject = () => [
        EnvConfiguration,
        AutoPylotPrincipal,
        Logger,
        PurchasesErrorAdapter
    ];
    constructor(
        private config: EnvConfiguration,
        private principal: AutoPylotPrincipal,
        private logger: Logger,
        private errorAdapter: PurchasesErrorAdapter
    ){
        super({});
    }
    private isInitialized = false;

    private getApiKey = (): string => {
        if (this.deviceInfo.platform === 'android'){
            return this.config.REVENUE_CAT_ANDROID_PUBLIC_KEY;
        }
        if (this.deviceInfo.platform === 'ios'){
            return this.config.REVENUE_CAT_IOS_PUBLIC_KEY;
        }
        throw new Error(`${this.deviceInfo.platform} is not supported`);
    };

    init = async () => {
        this.logger.info('RevenueCatSDKModel.init-ing');

        this.deviceInfo = await Device.getInfo();
        if (this.deviceInfo.platform  === 'web'){
            this.logger.info(`cannot init sdk on platform ${this.deviceInfo.platform}`);
            return;
        }

        // change this to DEBUG when wanting to drive more logs to the OS logs
        // does not seem to increase logs we see from JS
        await Purchases.setLogLevel({ level: LOG_LEVEL.INFO });

        const apiKey = this.getApiKey();

        if (!apiKey){
            this.logger.info(`cannot init, no key in config`);
            return;
        }

        try {
            await Purchases.configure({
                apiKey,
                appUserID: this.principal.userId,
                shouldShowInAppMessagesAutomatically: false
            });

            await Purchases.setEmail({ email: this.principal.email });

            await this.fetchSubscriber();
            await this.fetchOffers();

            if (!this.hasUpgradeOffer()){
                this.logger.error(`WARN: user ${this.principal.userId} cannot upgrade on ${this.deviceInfo.platform} b/c we did not get an offer from revenuecat.`);
            }

            this.isInitialized = true;
            this.setState({ });

            this.logger.info('RevenueCatSDKModel.init-ing', this.getDiagnostics().diagnostics);
        }
        catch (err: any){
            this.logger.error(`Error: RevenueCatSDKModel tried to initialize and failed. Got ${err.toString()} ${JSON.stringify(err)}`);
        }

    };

    // guaranteed to be set during init
    private deviceInfo!: DeviceInfo;

    // might not be set (eg if not android)
    private lastCustomerInfo?: CustomerInfo;
    private lastOffers?: PurchasesOfferings;
    private eligibleForTrial = false;

    private fetchSubscriber = async () => {
        try{
            const { customerInfo } = await Purchases.getCustomerInfo();
            this.lastCustomerInfo = customerInfo;
            this.setState({ });
        }
        catch (err: any){
            // need to find out what props I can key into to find the 429
            // ideally there is supposed to be a header with Retry-After
            // that tells me how long to back off for
            this.logger.info('wtf', err);
            this.logger.info(`wtf ${JSON.stringify(err)}`);
            throw err;
        }
    };

    private fetchOffers = async () => {
        this.lastOffers = await Purchases.getOfferings();
        this.eligibleForTrial = await this.checkTrialEligibility();
        this.setState({ });
    };

    private checkTrialEligibility = async () => {
        const availablePackages = this.lastOffers?.current?.availablePackages;
        if (!availablePackages) {
            return false;
        }
        if (this.deviceInfo.platform === 'ios'){
            // checkTrialOrIntroductoryPriceEligibility is only available on iOS
            const productIdentifiers = availablePackages.map(availablePackage => availablePackage.product.identifier);
            const introEligibilityStatuses = await Purchases.checkTrialOrIntroductoryPriceEligibility({ productIdentifiers });
            const ineligibleStatuses = [INTRO_ELIGIBILITY_STATUS.INTRO_ELIGIBILITY_STATUS_INELIGIBLE, INTRO_ELIGIBILITY_STATUS.INTRO_ELIGIBILITY_STATUS_UNKNOWN];
            return productIdentifiers.every(id => !ineligibleStatuses.includes(introEligibilityStatuses[id].status));
        } else { // android
            // a user on Android is eligible if one of the subscription options has a freePhase entry
            for (const availablePackage of availablePackages) {
                for (const subscriptionOption of availablePackage.product.subscriptionOptions || []) {
                    if (subscriptionOption.freePhase && subscriptionOption.freePhase.price.amountMicros === 0) {
                        return true;
                    }
                }
            }
            return false;
        }
    };

    private purchasePackage = async (aPackage: PurchasesPackage) => {
        try {
            this.logger.info(`RevenueCatSDKModel.purchasePackage-ing`, aPackage);
            await Purchases.purchasePackage({ aPackage });
            this.logger.info(`RevenueCatSDKModel.purchasePackage-ed`, aPackage);
        }
        catch (err: any){
            try {
                this.logger.info(`RevenueCatSDKModel.purchasePackage-failed`, { err, jsonError: JSON.stringify(err)});
            }
            catch {
                // do nothing
            }

            throw this.errorAdapter.convert(err);
        }
    };

    canMakeRealPayment = () => {
        if (!this.isInitialized){
            return false;
        }
        if (!this.hasUpgradeOffer()){
            return false;
        }
        return true;
    };

    upgrade = async (term: IPaymentTerm) => {
        await this.purchasePackage(this.getUpgradePackage(term));
    };

    restore = async () => {
        try {
            this.logger.info(`RevenueCatSDKModel.restore-ing`);
            await Purchases.restorePurchases();
            this.logger.info(`RevenueCatSDKModel.restore-ed `);
        }
        catch (err: any){
            try {
                this.logger.info(`RevenueCatSDKModel.restore-failed`, { err, jsonError: JSON.stringify(err)});
            }
            catch {
                // do nothing
            }
            throw this.errorAdapter.convert(err);
        }
    };

    getFreeTierLimits = (): IFreeTierLimits | undefined => {
        const limits: any = this.lastOffers?.current?.metadata?.freeTierLimits;

        if (!limits){
            return undefined;
        }

        // I am being slightly defensive, b/c this is content managed by product
        // but I am not being overly defensive (eg is 0 expected, what about -1)
        // b/c if they have the right type, then they probably set it to what they want it to be
        // intentonally not adding sensible defaults b/c this shouldn't happen
        // that is handled upstream (and I won't want to duplicate)
        // and we'll be bubbling this to sentry, so it should be temporary
        for (const key of Object.keys(limits)) {
            if (typeof limits[key] !== 'number') {
                return undefined;
            }
        }

        return limits as any as IFreeTierLimits;
    };

    private hasUpgraded = () => {
        return this.getEntitlements().includes(ENTITLEMENTS.PLUS);
    };

    hasUpgradeOffer = (term: IPaymentTerm = 'monthly') => {
        return Boolean(this.getUpgradePackage(term));
    };

    isEligibleForTrial = () => {
        return this.eligibleForTrial;
    };

    private getUpgradePackage = (term: IPaymentTerm) => {
        const packages = this.lastOffers?.current?.availablePackages || [];

        const packageType = term === 'monthly' ? PACKAGE_TYPE.MONTHLY : PACKAGE_TYPE.ANNUAL;

        const packageForTerm = packages.find(p => p.packageType === packageType);
        if (!packageForTerm){
            throw new Error(`attempted to find package type ${packageType} for term ${term} but found none. Found "${packages.map(p => p.packageType).join(', ')}"`);
        }
        return packageForTerm;
    };

    private getEntitlements = (): string[] => Object.keys(this.lastCustomerInfo?.entitlements.active || {});

    getDiagnostics = () => {

        let diagnostics: { [label: string]: any } = {
            isInitialized: this.isInitialized,
        };

        if (this.isInitialized){
            diagnostics = {
                ...diagnostics,
                publicAPIKey: this.getApiKey(),
                hasUpgraded: this.hasUpgraded(),
                hasUpgradeOffer: this.hasUpgradeOffer(),
                isEligibleForTrial: this.isEligibleForTrial(),
                customerInfo: this.lastCustomerInfo,
                offers: this.lastOffers,
                canMakeRealPayment: this.canMakeRealPayment(),
                freeTierLimits: this.getFreeTierLimits() || '(none)',
            };
        }

        return {
            diagnostics,
            diagnosticsMethods: {
                fetchSubscriber: this.fetchSubscriber,
                fetchOffers: this.fetchOffers,
                purchasePackage: this.purchasePackage
            }
        };
    };
};
