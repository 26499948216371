import React, { ErrorInfo } from 'react';
import { IonApp, isPlatform, setupIonicReact } from '@ionic/react';
import { AutoPylotTheme } from './AutoPylotTheme';
import { AppContainer, getErrorSafeContainer } from './AppContainer';
import { AppRouter } from './AppRouter';
import { ErrorPage } from './ErrorPage';
import { FatalApplicationErrorState } from './FatalApplicationErrorState';
import { Subscribe } from '@meraki-internal/state';
import { AlertBinder } from './AlertBinder';
import { ToastBinder } from '../components/toast/ToastBinder';
import { DialogBinder } from '../components/dialog/DialogBinder';
import { Logger } from '../support/debug/Logger';
import { Undo } from '../undo/Undo';
import { BackButtonHandler } from './BackButtonHandler';
import { DismissSplashScreen } from './DismissSplashScreen';
import { PaywallTriggerHandler } from '../paywall/PaywallTriggerHandler';

setupIonicReact({
    swipeBackEnabled: false,

    // on web use ios theme, otherwise let ionic decide
    mode: isPlatform('desktop') ? 'ios' : undefined
});

export class AutoPylotApp extends React.Component {
    state = {
        error: null
    };

    static getDerivedStateFromError() {
        // NOTE: We are not setting state here as prescribed in the docs, but instead setting it in
        // componentDidCatch() below because a non-static method can check if error state is already
        // set, and avoid the real error getting overwritten by a bogus error when react remounts
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        try {
            // ignore if we get catch a second error - this is from react unsuccessfully attempting
            // to remount the app, and contains a misleading message and useless stack trace
            if (this.state.error) {
                return;
            }

            const log = getErrorSafeContainer().get(Logger);

            log.error(error, errorInfo);
            this.setState({ error });

        } catch (e: any) {
            console.warn(`Failed to log error in AutoPylotApp.componentDidCatch. ${e.message}`);
            console.error(e);
        }
    }

    render() {
        const { error } = this.state;

        if (error) {
            return <ErrorPage error={error} />;
        }

        return (
            <Subscribe to={() => FatalApplicationErrorState.singleton}>
                {({ error: fatalError }) => (
                    <>
                        {fatalError && (
                            <DismissSplashScreen>
                                <ErrorPage error={fatalError} />
                            </DismissSplashScreen>
                        )}
                        {!fatalError && (
                            <IonApp>
                                <AutoPylotTheme />
                                <AlertBinder />
                                <AppContainer>
                                    <BackButtonHandler />
                                    <Undo />
                                    <ToastBinder />
                                    <DialogBinder />
                                    <PaywallTriggerHandler />
                                    <AppRouter />
                                </AppContainer >
                            </IonApp>
                        )}
                    </>
                )}
            </Subscribe>
        );
    }
}
