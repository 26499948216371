import { IAddress } from '../../support/model/IAddress';

// nowrap between city and state ensures state doesn't end up on a line by itself
export const Address: React.FC<{ address: IAddress }> = ({ address }) => {

    if (!address.city) {
        return <span>(Unknown Address)</span>;
    }

    return (
        <>
            {address.name && (
                <span>{address.name}, </span>
            )}
            {address.street && (
                <span>{address.street}, </span>
            )}
            <span style={{ whiteSpace: 'nowrap' }}>{address.city}, {address.state}</span>
        </>
    );
};
