import { useInstance } from '@meraki-internal/react-dependency-injection';
import { State, useSubscription } from '@meraki-internal/state';
import { ActionMenu } from '../../components/action-menu/ActionMenu';
import { EnvConfiguration } from '../../app/config/EnvConfiguration';

export class AttributionActionSheetState extends State<{isOpen: boolean}> {
    constructor() {
        super({ isOpen: false });
    }
    open = () => {
        this.setState({ isOpen: true });
    };
    close = () => {
        this.setState({ isOpen: false });
    };
}

export const AttributionActionSheet: React.FC = () => {
    const actionSheetState = useInstance(AttributionActionSheetState);
    useSubscription(() => actionSheetState);
    const config = useInstance(EnvConfiguration);

    const actions = [
        {
            text: '© Mapbox',
            handler: async () => {
                window.open('https://www.mapbox.com/about/maps/');
            }
        },
        {
            text: '© OpenStreetMap',
            handler: async () => {
                window.open('https://www.openstreetmap.org/about/');
            }
        },
        {
            text: 'Improve this Map',
            handler: async () => {
                window.open(`https://apps.mapbox.com/feedback/?owner=mapbox&id=streets-v11&access_token=${config.MAPBOX_TOKEN}`);
            }
        }
    ];

    return (
        <ActionMenu trigger="map-attribution-button" isOpen={actionSheetState.state.isOpen} onClose={actionSheetState.close} actions={actions} />
    );
};
