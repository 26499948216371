import { useBreakpoints } from '../../support/hooks/useBreakpoints';
import { ActionSheet } from './ActionSheet';
import { PopoverMenu } from './PopoverMenu';

export interface IActionMenuAction {
    text?: string;
    icon?: string;
    role?: 'cancel' | 'destructive';
    handler?: () => boolean | void | Promise<boolean | void>;
    disabled?: boolean;
}

export interface IActionMenuProps {
    actions: IActionMenuAction[];
    isOpen: boolean;
    onClose?: () => void;
    trigger?: string;
}

/**
 * ActionMenu renders as an ActionSheet on phones, and as a PopoverMenu on wider screens.
 */
export const ActionMenu: React.FC<IActionMenuProps> = (props) => {
    const { isAboveBreakpoint } = useBreakpoints();
    if (isAboveBreakpoint('sm')) {
        return (
            <PopoverMenu {...props} />
        );
    } else {
        return (
            <ActionSheet {...props} />
        );
    }
};
