import { IonItem, IonLabel } from '@ionic/react';
import { ForwardIcon } from '../../components/icons/Icons';
import { EditableMissionState } from '../model/EditableMissionState';

export const MissionNotesButton: React.FC<{ mission: EditableMissionState }> = ({ mission }) => {
    return (
        <IonItem button detail={false} onClick={() => mission.pages.notes.go()} data-type="notes-button">
            <IonLabel>
                <h2>Notes</h2>
                {mission.state.notesHtml && (
                    <>
                        <p
                            data-id="details-notes"
                            style={{ maxHeight: '160px', fontSize: 'smaller', overflow: 'visible', whiteSpace: 'normal' }}
                            dangerouslySetInnerHTML={{ __html: mission.state.notesHtml || '' }}
                        />
                    </>
                )}
            </IonLabel>
            <ForwardIcon slot="end" color="medium" size="medium" />
        </IonItem>
    );
};
