/**
 * This helper simply implements a basic try...catch using the supplied delegate and exception handler.
 * It's intended to be used where reducing a try/catch block to a single line of code improves readability.
 * NOTE: If the delegate is async, remember to await tryAndCatch(...).
 *
 * For example, this:
 *   try {
 *     await doSomething();
 *   } catch (error: any) {
 *     showError(error);
 *   }
 *
 * can becomes this:
 *   await tryAndCatch(doSomething, showError);
 */
export const tryAndCatch = async (delegate: () => void, exceptionHandler: (e: any) => void) => {
    try {
        await delegate();
    } catch (e: any) {
        await exceptionHandler(e);
    }
};
