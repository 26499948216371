import { useState } from 'react';
import { DurationPicker } from './DurationPicker';

const DemoDurationPicker = () => {
    const [duration, setDuration] = useState(90);

    return (
        <DurationPicker
            durationMinutes={duration}
            onChange={value => setDuration(value)}
        />
    );
};

DemoDurationPicker.componentName = 'DurationPicker';

export default DemoDurationPicker;
