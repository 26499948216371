import { StorageProvider } from '../support/StorageProvider';

export class LocalStorageLastCodeVerifierProvider {
    static inject = () => [StorageProvider];
    constructor(private storageV2: StorageProvider){}
    store = ({ code_verifier }: { code_verifier: string }) => {
        this.code_verifier.set(code_verifier);
    };

    getLastVerifier = (): { code_verifier?: string } => {
        const code_verifier = this.code_verifier.get();
        return { code_verifier };
    };

    private code_verifier = this.storageV2.getStringProvider('PKCE.code_verifier', { userNeutral: true, storageType: 'localStorage' } );
}
