import { ILAANCAdvisoryProperties, IPersistableLAANCStatus, MissionFeatureCollectionFacadeAdapter } from '@autopylot-internal/tiles-client';
import moment from 'moment';
import { HistoryViewModel } from '../app/HistoryViewModel';
import { MissionsState } from '../missions/model/MissionsState';
import { IScreenshotablePage } from '../support/screenshots/IScreenshotablePage';
import { MissionMapPage } from './MissionMapPage';
import { MapViewModel } from './model/MapViewModel';
import { INationalParkAdvisoryProperties } from '@autopylot-internal/tiles-client/dist/model/advisories/INationalParkAdvisoryProperties';
import { getWellKnownLocation } from '../support/screenshots/getWellKnownLocation';
import { EnableAndBindMapForScreenshots } from './EnableAndBindMapForScreenshots';
import { IScreenshotHint } from '../support/screenshots/ScreensPage';
import { ScreenshotHelper } from '../support/screenshots/ScreenshotHelper';

interface IMyPageProps {
    onMissionsState?: (missions: MissionsState) => void;
    wellKnownLocation?: string;
    canEditTimes?: boolean;
    isEditingStart?: boolean;
    isEditingDuration?: boolean;
    zoom?: number;
    drawerOpen?: boolean;
    startTime?: string;
    durationHours?: number;
    laancStatus?: IPersistableLAANCStatus;
    concurrentMissions?: number;
    isLicensed?: boolean;
    openToggles?: boolean;
    hint?: IScreenshotHint;
}

export default class ScreenshotMissionMapPage implements IScreenshotablePage<IMyPageProps> {
    static inject = () => [
        MissionsState,
        MapViewModel,
        HistoryViewModel,
        MissionFeatureCollectionFacadeAdapter,
        ScreenshotHelper
    ];
    constructor(
        private missions: MissionsState,
        private mapViewModel: MapViewModel,
        private historyVM: HistoryViewModel,
        private mfcAdapter: MissionFeatureCollectionFacadeAdapter,
        private helper: ScreenshotHelper
    ) { }

    screenId = 'mission-map';

    ComponentUnderTest = MissionMapPage;

    WrapWith = EnableAndBindMapForScreenshots;

    permutationToProps = ({ wellKnownLocation = 'uncontrolled', canEditTimes, drawerOpen, startTime, durationHours = 1, laancStatus, concurrentMissions = 0, onMissionsState, isLicensed }: IMyPageProps) => {
        if (canEditTimes) {
            this.historyVM.addSearchParam({key: 'editTimes', value: 'true'});
        }

        startTime = startTime || moment().startOf('h').add(2, 'h').toISOString();
        const endTime = moment(startTime).add(durationHours, 'hours').toISOString();

        const { address, radiusPolygon } = getWellKnownLocation(wellKnownLocation);

        radiusPolygon.features[0].properties.startTime = startTime;
        radiusPolygon.features[0].properties.endTime = endTime;
        radiusPolygon.features[0].properties.isLicensed = isLicensed;

        if (laancStatus) {
            const laancAdvisory = radiusPolygon.features.find((f: any) => f.properties.source === 'uasfm+authorizable');
            laancAdvisory.properties.operationID = 'G8R3M9S20';
            laancAdvisory.properties.laancStatus = laancStatus;
            laancAdvisory.properties.laancStatusHistory.push({ status: laancStatus, timestamp: new Date().toISOString() }); // likely incomplete, but close enough
        }

        let missionId;
        for (let i = 0; i <= concurrentMissions; i++) {
            missionId = String(Date.now() + i);
            this.missions.addMissionToState({
                missionId,
                status: 'active',
                startTime,
                durationMinutes: durationHours * 60,
                address,
                radiusPolygon: this.mfcAdapter.adaptFromPersisted(radiusPolygon),
                maxAltitude: 300,
                links: {},
                version: 1,
            });
        }

        if (onMissionsState){
            onMissionsState(this.missions);
        }

        return { missionId, initialDrawerHeight: drawerOpen ? 1000 : undefined };
    };

    blockScreenshotTil = async ({ isEditingStart, isEditingDuration, openToggles, zoom }: IMyPageProps) => {
        await this.mapViewModel.waitForIdle();

        if (zoom){
            await this.mapViewModel.setZoom(zoom);
            await this.mapViewModel.waitForIdle();
        }

        if (isEditingStart) {
            const picker: any = await this.helper.waitForElement('[data-type=date-time-picker]');
            picker.click();
        }

        if (isEditingDuration) {
            const picker: any = await this.helper.waitForElement('[data-type=duration-picker]');
            picker.click();
        }

        if (openToggles){
            const toggle: any = await this.helper.waitForElement('ion-button[data-id=layers-toggle]');
            toggle.click();
        }
    };

    permutations: { [key: string]: IMyPageProps} = {
        'view-mission-location-uncontrolled': {},
        'view-mission-location-uncontrolled-z-12': {
            zoom: 12,
            hint: { text: 'last zoom where you can see radius' }
        },
        'view-mission-location-uncontrolled-z-11': {
            zoom: 11.9,
            hint: { text: 'no longer see radius' }
        },
        'view-mission-location-uncontrolled-z-9': {
            zoom: 9,
            hint: { text: 'last zoom where you can see UASFM' }
        },
        'view-mission-location-uncontrolled-z-8': {
            zoom: 8.9,
            hint: { text: 'switches to purple' }
        },
        'view-mission-location-laanc': {
            wellKnownLocation: 'laanc-aa'
        },
        'view-mission-location-not-laanc': {
            wellKnownLocation: 'controlled'
        },
        // 'can-edit-times': {
        //     canEditTimes: true
        // },
        // 'editing-start': {
        //     canEditTimes: true,
        //     isEditingStart: true
        // },
        // 'editing-duration': {
        //     canEditTimes: true,
        //     isEditingDuration: true
        // },
        'view-max-radius': {
            wellKnownLocation: 'max-radius'
        },
        'view-advisories-allowed-sua': {
            drawerOpen: true,
            wellKnownLocation: 'allowed-sua'
        },
        'view-advisories-controlled': {
            drawerOpen: true,
            wellKnownLocation: 'controlled'
        },
        'view-advisories-dc-frz': {
            drawerOpen: true,
            wellKnownLocation: 'dc-frz'
        },
        'view-advisories-e2-weather': {
            drawerOpen: true,
            wellKnownLocation: 'e2-weather'
        },
        'view-advisories-licensed-inactive': {
            drawerOpen: true,

            // this test isn't about further-coordination, just using that as a starting point, see onMissionsState
            wellKnownLocation: 'further-coordination',

            isLicensed: true,

            onMissionsState: (missions: MissionsState) => {
                const uasfm = missions.state.missions[0].getMissionRadius().getAdvisories().filter(a => a.source === 'uasfm+airspace')[0] as ILAANCAdvisoryProperties;
                uasfm.advisoryType = 'block';
                uasfm.blockReasons = ['laanc-inactive'];
                uasfm.UASFM_Enabled = [];
            }
        },
        'view-advisories-national-park': {
            drawerOpen: true,

            // this test isn't about further-coordination, just using that as a starting point, see onMissionsState
            wellKnownLocation: 'further-coordination',

            onMissionsState: (missions: MissionsState) => {
                const uasfm = missions.state.missions[0].getMissionRadius().getAdvisories().filter(a => a.source === 'uasfm+airspace')[0] as ILAANCAdvisoryProperties;
                const park: INationalParkAdvisoryProperties = uasfm as unknown as INationalParkAdvisoryProperties;
                park.source = 'national-parks';
                park.advisoryType = 'advise';
                park.UNIT_NAME = 'Yellowstone National Park';
                park.UNIT_TYPE = 'National Park';
            }
        },
        'view-advisories-national-park-and-laanc': {
            drawerOpen: true,
            wellKnownLocation: 'laanc-aa',
            onMissionsState: (missions: MissionsState) => {
                const park: INationalParkAdvisoryProperties = {} as unknown as INationalParkAdvisoryProperties;
                park.source = 'national-parks';
                park.advisoryType = 'advise';
                park.UNIT_NAME = 'Yellowstone National Park';
                park.UNIT_TYPE = 'National Park';
                missions.state.missions[0].getMissionRadius().features.push({
                    type: 'Feature',
                    properties: park,
                    geometry: {
                        type: 'Polygon',
                        coordinates: []
                    }
                });
            }
        },
        'view-advisories-national-memorial': {
            drawerOpen: true,

            // this test isn't about further-coordination, just using that as a starting point, see onMissionsState
            wellKnownLocation: 'further-coordination',

            onMissionsState: (missions: MissionsState) => {
                const uasfm = missions.state.missions[0].getMissionRadius().getAdvisories().filter(a => a.source === 'uasfm+airspace')[0] as ILAANCAdvisoryProperties;
                const park: INationalParkAdvisoryProperties = uasfm as unknown as INationalParkAdvisoryProperties;
                park.source = 'national-parks';
                park.advisoryType = 'advise';
                park.UNIT_NAME = 'Mount Rushmore National Memorial';
                park.UNIT_TYPE = 'National Memorial';
            }
        },
        'view-advisories-recreational-inactive': {
            drawerOpen: true,

            // this test isn't about further-coordination, just using that as a starting point, see onMissionsState
            wellKnownLocation: 'further-coordination',

            isLicensed: false,

            onMissionsState: (missions: MissionsState) => {
                const uasfm = missions.state.missions[0].getMissionRadius().getAdvisories().filter(a => a.source === 'uasfm+airspace')[0] as ILAANCAdvisoryProperties;
                uasfm.advisoryType = 'block';
                uasfm.blockReasons = ['laanc-inactive'];
                uasfm.UASFM_Enabled = [];
            }
        },
        'view-advisories-recreational-active-for-licensed': {
            drawerOpen: true,

            // this test isn't about further-coordination, just using that as a starting point, see onMissionsState
            wellKnownLocation: 'further-coordination',

            isLicensed: false,

            onMissionsState: (missions: MissionsState) => {
                const uasfm = missions.state.missions[0].getMissionRadius().getAdvisories().filter(a => a.source === 'uasfm+airspace')[0] as ILAANCAdvisoryProperties;
                uasfm.advisoryType = 'block';
                uasfm.blockReasons = ['laanc-not-44809-aa'];
                uasfm.UASFM_Enabled = ['107-AA'];
            }
        },
        'view-advisories-laanc-pending': {
            drawerOpen: true,
            wellKnownLocation: 'laanc-aa',
            laancStatus: 'authorizing'
        },
        'view-advisories-laanc-approved-day': {
            drawerOpen: true,
            wellKnownLocation: 'laanc-aa',
            laancStatus: 'authorized',
            startTime: moment.tz('us/mountain').hour(13).startOf('hour').toISOString()
        },
        'view-advisories-laanc-approved-night': {
            drawerOpen: true,
            wellKnownLocation: 'laanc-aa',
            laancStatus: 'authorized',
            startTime: moment.tz('us/mountain').hour(23).startOf('hour').toISOString()
        },
        'view-advisories-laanc-canceled': {
            drawerOpen: true,
            wellKnownLocation: 'laanc-aa',
            laancStatus: 'user-canceled'
        },
        'view-advisories-laanc-rescinded': {
            drawerOpen: true,
            wellKnownLocation: 'laanc-aa',
            laancStatus: 'rescinded' },
        'view-advisories-laanc-rescind-acked': {
            drawerOpen: true,
            wellKnownLocation: 'laanc-aa',
            laancStatus: 'rescind-acked'
        },
        'view-advisories-laanc-invalidated': {
            drawerOpen: true,
            wellKnownLocation: 'laanc-aa',
            laancStatus: 'invalidated'
        },
        'view-advisories-laanc-invalidate-acked': {
            drawerOpen: true,
            wellKnownLocation: 'laanc-aa',
            laancStatus: 'invalidated-acked'
        },
        'view-advisories-nsufr': {
            drawerOpen: true,
            wellKnownLocation: 'nsufr'
        },
        'view-advisories-restricted-sua': {
            drawerOpen: true,
            wellKnownLocation: 'restricted-sua'
        },
        'view-advisories-uncontrolled': {
            drawerOpen: true,
            wellKnownLocation: 'uncontrolled'
        },
        'view-advisories-stadiums': {
            drawerOpen: true,
            wellKnownLocation: 'stadium'
        },
        'view-advisories-concurrent': {
            drawerOpen: true,
            wellKnownLocation: 'laanc-aa',
            laancStatus: 'authorized',
            concurrentMissions: 1
        },
        'view-advisories-recreational-night': {
            drawerOpen: true,
            wellKnownLocation: 'uncontrolled',
            startTime: moment().tz('America/New_York').startOf('hour').hour(22).toISOString()
        },
        'view-advisories-recreational-night-and-civil-twilight': {
            drawerOpen: true,
            wellKnownLocation: 'uncontrolled',
            startTime: moment().tz('America/New_York').startOf('hour').hour(19).toISOString()
        },
        'view-advisories-recreational-civil-twilight': {
            drawerOpen: true,
            wellKnownLocation: 'uncontrolled',
            startTime: moment().tz('America/New_York').startOf('hour').hour(20).toISOString()
        },
        'view-advisories-recreational-civil-twilight-long-day': {
            drawerOpen: true,
            wellKnownLocation: 'uncontrolled',
            startTime: moment().month(12).tz('America/New_York').startOf('hour').hour(7).toISOString(),
            durationHours: 10
        },
        'view-advisories-recreational-long-day': {
            drawerOpen: true,
            wellKnownLocation: 'uncontrolled',
            startTime: moment().month(12).tz('America/New_York').startOf('hour').hour(7).toISOString(),
            durationHours: 12
        },
        'view-advisories-day': {
            drawerOpen: true,
            wellKnownLocation: 'uncontrolled',
            startTime: moment().tz('America/New_York').startOf('hour').hour(9).toISOString()
        },
        'view-advisories-licensed-twilight': {
            drawerOpen: true,
            wellKnownLocation: 'uncontrolled',
            startTime: moment().tz('America/New_York').startOf('hour').hour(20).toISOString(),
            isLicensed: true
        },
        'view-advisories-licensed-night': {
            drawerOpen: true,
            wellKnownLocation: 'uncontrolled',
            startTime: moment().tz('America/New_York').startOf('hour').hour(22).toISOString(),
            isLicensed: true
        },
        'view-advisories-licensed-night-and-civil-twilight': {
            drawerOpen: true,
            wellKnownLocation: 'uncontrolled',
            startTime: moment().tz('America/New_York').startOf('hour').hour(19).toISOString(),
            isLicensed: true
        },
        'view-advisories-licensed-long-day': {
            drawerOpen: true,
            wellKnownLocation: 'uncontrolled',
            startTime: moment().month(12).tz('America/New_York').startOf('hour').hour(7).toISOString(),
            durationHours: 12,
            isLicensed: true
        },
        'view-advisories-licensed-over-night': {
            drawerOpen: true,
            wellKnownLocation: 'uncontrolled',
            startTime: moment().tz('America/New_York').startOf('hour').hour(17).toISOString(),
            durationHours: 12,
            isLicensed: true
        },
        'map-toggles': {
            openToggles: true
        }
    };
}
