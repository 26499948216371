import { useState } from 'react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { Page } from '../components/page/Page';
import { HistoryViewModel } from '../app/HistoryViewModel';
import { usePageView } from '../support/tracking/usePageView';
import { Button } from '../components/button/Button';
import { TrackingService } from '../support/tracking/TrackingService';
import { EnvConfiguration } from '../app/config/EnvConfiguration';
import { OperatorState } from '../profile/OperatorState';
import { ArticleCTA } from '../app/intercom/ArticleCTA';
import { Device } from '../support/Device';
import { AlertPresenter } from '../app/AlertBinder';
import { useSubscription } from '@meraki-internal/state';

export const CalendarPage: React.FC = () => {
    usePageView('Connect Calendar');

    const history = useInstance(HistoryViewModel);
    const tracker = useInstance(TrackingService);

    const config = useInstance(EnvConfiguration);
    const operatorState = useInstance(OperatorState);
    useSubscription(() => operatorState);

    const deviceInfo = useInstance(Device);
    const alert = useInstance(AlertPresenter);
    const [done, setDone] = useState(false);

    const canConnect = Boolean(operatorState.state.links!.calendar);

    const connectCalendar = async () => {
        const url = `${config.API_BASE_URL}${operatorState.state.links!.calendar.href}`;
        console.log('ical link', url);
        tracker.track('Calendar Connected');
        window.open(url);
    };

    const notifyMe = async () => {
        tracker.track('Calendar Notify Me');
        setDone(true);
        await alert.showAlertV2({ header: 'Thanks!', message: `We’ll let you know when this is available.` });
    };

    return (
        <Page title="Connect Calendar" onBack={() => history.back('/settings')}>
            <div style={{margin: '30px 25px'}}>

                {deviceInfo.platform !== 'android' &&
                    <>
                        <p style={{marginBottom: 30}}>
                            Tap the button below to open your calendar app, which will
                            walk you through adding a view-only calendar of your missions.
                        </p>

                        <Button expand="block" disabled={!canConnect} onClick={connectCalendar}>
                            Connect Mission Calendar
                        </Button>

                        <p style={{marginTop: 30}}>
                            Want to learn more first? <ArticleCTA articleKey='CONNECT_MISSION_CALENDAR'>Get help here</ArticleCTA>
                        </p>
                    </>
                }

                {deviceInfo.platform === 'android' &&
                    <>
                        <h3 style={{textAlign: 'center'}}>Coming Soon!</h3>

                        <p style={{marginBottom: 30}}>
                            You'll be able to add a view-only calendar of your missions to your calendar app.
                        </p>

                        <Button expand="block" disabled={done} onClick={notifyMe}>
                            Notify Me
                        </Button>
                    </>
                }

            </div>
        </Page>
    );
};
