export const getHeading = (direction: number) => {
    const index = Math.round(direction / 22.5) % 16;
    return ['N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSW', 'SW', 'WSW', 'W', 'WNW', 'NW', 'NNW'][index];
};

export const WindLabel: React.FC<{direction: number, speedMph: number, showHeading?: boolean, showUnits?: boolean, gusts?: boolean }> = ({ direction, speedMph, showHeading = false, showUnits = true, gusts }) => {
    return (
        <span>
            {speedMph}{showUnits ? ' mph' : ''}{gusts ? ' gusts' : ''} {showHeading && getHeading(direction)}
        </span>
    );
};
