import { IonRange } from '@ionic/react';
import { Color } from '../common';

export interface SliderProps {
    min: number;
    max: number;
    curved?: boolean;
    color?: Color;
    value: number;
    onChange: (value: number) => void;
    onMoveEnd: (value: number) => void;
}

let lastMoveEnd: number;

export const Slider: React.FC<SliderProps> = ({ min, max, curved, color = 'primary', value, onChange, onMoveEnd }) => {

    const style = {
        '--height': '10px',
        '--bar-height': '5px',
        '--bar-border-radius': '4px',
        '--bar-background': `var(--ion-color-light)`,
        '--knob-size': '20px'
    };

    // functions to translate linear values to and from a curved response (so that the value increments faster
    // the further you get to the right, allowing more precise adjustment at the lower end of the range)
    const curve = (v: number) => ((v - min) * (max - min)) ** 0.5 + min;
    const uncurve = (v: number) => ((v - min) ** 2) / (max - min) + min;

    const sliderValue = curved ? curve(value) : value;

    const handleChange = (e: any) => {
        if (lastMoveEnd && Date.now() - lastMoveEnd < 300){
            // example onIonKnobMoveEnd followed by onIonChange
            // T13:55:03.505Z
            // T13:55:03.534Z
            console.log('ignoring');
            return;
        }
        onChange(curved ? uncurve(e.detail.value) : e.detail.value);
    };

    const handleMoveEnd = (e: any) => {
        lastMoveEnd = Date.now();

        onMoveEnd(curved ? uncurve(e.detail.value) : e.detail.value);
    };

    return (
        <IonRange
            style={style}
            color={color}
            min={min}
            max={max}
            value={sliderValue}
            onIonChange={handleChange}
            onIonKnobMoveEnd={handleMoveEnd}
        />
    );
};
