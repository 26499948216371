import { useInstance } from '@meraki-internal/react-dependency-injection';
import { WeatherConditionIcon } from '../components/WeatherConditionIcon';
import { ChartTooltip } from './ChartTooltip';
import { HourlyWeatherChartViewModel, IMetricCode } from './HourlyWeatherChartViewModel';
import { useSubscription } from '@meraki-internal/state';
import { initCap, unCamelCase } from '../../support/helpers/format';
import { IHourlyForecast } from '../model/IHourlyForecast';
import { getHeading } from '../components/WindLabel';

interface IComponentProps {
    active: IHourlyForecast;
    metricCode: IMetricCode;
}

const MetricIcon: React.FC<IComponentProps> = ({ active, metricCode }) => {
    if (['windSpeed', 'windGusts'].includes(metricCode)) {
        return null;
    } else {
        return <WeatherConditionIcon size="medium" conditionCode={active.conditionCode} isDaylight={active.isDaylight} />;
    }
};

const MetricValue: React.FC<IComponentProps> = ({ active, metricCode }) => {
    if (metricCode === 'temperature') {
        return <>{active.temperatureF}º<span style={{ fontSize: 'x-small' }}>F</span></>;
    }
    if (metricCode === 'humidity') {
        return <>{active.humidityPct}<small>%</small></>;
    }
    if (metricCode === 'precipChance') {
        return <>{active.precipitationChancePct}<small>%</small></>;
    }
    if (metricCode === 'precipAmount') {
        return <>{active.precipitationAmountInches}<small> in</small></>;
    }
    if (metricCode === 'windSpeed') {
        return <>{active.windSpeedMph}<small> mph {getHeading(active.windDirection)}</small></>;
    }
    if (metricCode === 'windGusts') {
        return <>{active.windGustMph}<small> mph</small></>;
    }
    if (metricCode === 'cloudCover') {
        return <>{active.cloudCoverPct}<small>%</small></>;
    }
    if (metricCode === 'visibility') {
        return <>{active.visibilityMiles}<small> mi</small></>;
    }
    return null;
};

const MetricSubText: React.FC<IComponentProps> = ({ active, metricCode }) => {
    if (metricCode === 'temperature') {
        return <>{unCamelCase(active.conditionCode)}</>;
    }
    if (metricCode === 'humidity') {
        return <>{`Dewpoint ${active.temperatureDewPointF}ºF`}</>;
    }
    if (metricCode === 'precipAmount' || metricCode === 'precipChance') {
        return <>{unCamelCase(initCap(active.precipitationType))}</>;
    }
    if (metricCode === 'windSpeed') {
        return <>{`Gusts ${active.windGustMph} mph`}</>;
    }
    return null;
};

export const ChartMetricTooltip: React.FC = () => {

    const vm = useInstance(HourlyWeatherChartViewModel);
    useSubscription(() => vm);

    const active = vm.getAllDataForActiveHour();
    const metricCode = vm.state.selectedMetricCode;

    if (!active) {
        return null;
    }

    return (
        <ChartTooltip>
            <p style={{ margin: 0, padding: 0, fontSize: 13 }}>
                {vm.formatTime(active.forecastStart, 'ha, ddd MMM D')}
            </p>

            <p style={{ margin: 0, padding: 0, display: 'flex', alignItems: 'center', gap: 7 }}>
                <MetricIcon active={active} metricCode={metricCode} />
                <span style={{ fontSize: 20, color: 'var(--ion-color-light-contrast)' }}>
                    <MetricValue active={active} metricCode={metricCode} />
                </span>
            </p>

            <p style={{ fontSize: 13, margin: 0, padding: 0 }}>
                <MetricSubText active={active} metricCode={metricCode} />
            </p>
        </ChartTooltip>
    );
};
