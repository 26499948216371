import moment from 'moment';
import { IScreenshotablePage } from '../support/screenshots/IScreenshotablePage';
import { SavedChecklistPage, SavedChecklistPageState } from './SavedChecklistPage';
import { SavedChecklistsState } from './SavedChecklistsState';
import { ChecklistState } from './ChecklistState';
import { IChecklistItem, IChecklistSection, ISavedChecklist } from './model';
import { HistoryViewModel } from '../app/HistoryViewModel';

interface IMyPageProps {
    sections?: IChecklistSection[];
    editingItem?: IChecklistItem;
    settingNotification?: boolean;
    menuOpen?: boolean;
    renaming?: boolean;
    duplicating?: boolean;
}

export default class ScreenshotSavedChecklistPage implements IScreenshotablePage<IMyPageProps> {

    static inject = () => [SavedChecklistsState, SavedChecklistPageState, HistoryViewModel];
    constructor(private checklistsState: SavedChecklistsState, private pageState: SavedChecklistPageState, private history: HistoryViewModel) {}

    screenId = 'saved-checklist';

    ComponentUnderTest = SavedChecklistPage;

    permutationToProps = ({ sections, editingItem, settingNotification, menuOpen, renaming, duplicating }: IMyPageProps) => {
        const checklistId = '1';

        if (!sections) {
            sections = [
                {id: 'pre-flight', label: 'Preflight', items: []},
                {id: 'on-site', label: 'On Site', items: []},
                {id: 'post-flight', label: 'Postflight', items: []}
            ];
        }

        const checklistState = new ChecklistState<ISavedChecklist>({
            checklistId,
            name: 'Cell Tower Inspection',
            sections
        });

        this.checklistsState.setState({
            loaded: true,
            checklists: [ checklistState ]
        });

        if (editingItem) {
            checklistState.startEditing({ section: {} as any, item: editingItem });
        } else if (settingNotification) {
            checklistState.startEditing({ section: checklistState.getSections()[0] });
            setTimeout(() => checklistState.openNotificationLookup(), 100);
        } else if (menuOpen) {
            this.pageState.openMenu();
        } else if (renaming) {
            this.pageState.startRenaming();
        } else if (duplicating) {
            this.pageState.startDuplicating();
        }

        return { checklistId };
    };

    blockScreenshotTil = async () => {

    };

    permutations: { [key: string]: IMyPageProps } = {
        'empty' : {
        },
        'with-items' : {
            sections: [
                {id: 'pre-flight', label: 'Preflight', items: [
                    {id: '123', name: 'Check weather', notification: {label: '1 day before', offset: -1, offsetUnit: 'days', offsetFrom: 'startTime'}, completed: false},
                    {id: '456', name: 'Check airspace', completed: false}
                ]},
                {id: 'on-site', label: 'On Site', items: [
                    {id: '789', name: 'Check equipment', completed: false}
                ]},
                {id: 'post-flight', label: 'Postflight', items: [
                    {id: 'ABC', name: 'Send invoice', notification: {label: '1 day after', offset: 1, offsetUnit: 'days', offsetFrom: 'endTime', at: moment().add(1, 'd').toISOString()}, completed: false}
                ]},
            ]
        },
        'adding' : {
            editingItem: { id: '', name: '', completed: false }
        },
        'editing' : {
            sections: [
                {id: 'pre-flight', label: 'Preflight', items: [
                    {id: '123', name: 'Check weather', notification: {label: '1 day before', offset: -1, offsetUnit: 'days', offsetFrom: 'startTime'}, completed: false},
                ]},
                {id: 'on-site', label: 'On Site', items: []},
                {id: 'post-flight', label: 'Postflight', items: []},
            ],
            editingItem: { id: '123', name: 'Check weather', notification: {label: '1 day before', offset: -1, offsetUnit: 'days', offsetFrom: 'startTime'}, completed: false }
        },
        'setting-notification': {
            settingNotification: true
        },
        'menu-open': {
            menuOpen: true
        },
        'renaming': {
            renaming: true
        },
        'duplicating': {
            duplicating: true
        }
    };
}
