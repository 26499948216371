import { useInstance } from '@meraki-internal/react-dependency-injection';
import { AnchorButton } from '../../components/button/AnchorButton';
import { IArticleKey, IntercomService } from './IntercomService';

export const ArticleCTA: React.FC<{ articleKey: IArticleKey}> = ({ articleKey, children }) => {
    const intercom = useInstance(IntercomService);

    const onClick= async () => {
        await intercom.displayArticle(articleKey);
    };

    return (
        <AnchorButton onClick={onClick}>{children}</AnchorButton>
    );
};
