import { Drawer } from '../components/drawer/Drawer';
import { DrawerState } from '../components/drawer/DrawerState';
import { ILocation } from '../support/model/ILocation';
import { useSubscription } from '@meraki-internal/state';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { MissionsState } from '../missions/model/MissionsState';
import { LocationSearchPanel } from './location/LocationSearchPanel';
import { LocationSearchService } from './location/LocationSearchService';
import { useEffect } from 'react';
import { NewMissionState } from '../missions/model/NewMissionState';
import { TrackingService } from '../support/tracking/TrackingService';
import { ICoordinate } from '../support/model/ICoordinate';
import { FeedbackAlert } from '../app/FeedbackAlert';
import { MissionPinAndRadiusOnMapViewModel } from './mission-pin-and-radius/MissionPinAndRadiusOnMapViewModel';
import { useBreakpoints } from '../support/hooks/useBreakpoints';
import { ClearPreMissionWeatherState } from '../weather/components/ClearPreMissionWeatherState';
import { NewMissionTrackingHelper } from '../mission/NewMissionTrackingHelper';
import { BufferedEventBus } from '../events/BufferedEventBus';

export const NoMissionMapDrawer: React.FC<{setNewMission: (mission: NewMissionState) => void, beginCreatingMission: () => Promise<NewMissionState>}> = ({ setNewMission, beginCreatingMission }) => {
    const tracker = useInstance(TrackingService);

    const { isLargerScreen } = useBreakpoints();

    const drawerState = useInstance(DrawerState);
    const locationSearchService = useInstance(LocationSearchService);

    const vm = useInstance(MissionsState);
    useSubscription(() => vm);

    const pinAndRadiusViewModel = useInstance(MissionPinAndRadiusOnMapViewModel);
    const trackingHelper = useInstance(NewMissionTrackingHelper);

    const events = useInstance(BufferedEventBus);

    useEffect(() => {
        // on mount we want a blank map, until an address is selected
        pinAndRadiusViewModel.remove();
    }, [pinAndRadiusViewModel]);

    const onAddressSelected = async (location?: ILocation) => {
        if (location) {
            const mission = await beginCreatingMission();
            mission.setAddress(location.address);

            mission.setCenter(location.coordinates).then(() => {
                // this has to happen after awaiting setCenter(), otherwise radius won't have been created, and canFly etc will be undefined
                trackingHelper.addressSelected({
                    address: location.address,
                    coordinates: location.coordinates,
                    mission: mission
                });

                events.emit('AddressSelected');
            });

            setNewMission(mission);
        } else {
            // user clicked the "X" to clear the search
            collapseDrawer();
        }
    };

    const onClick = () => {
        // on phone, expand/collapse based on clicking
        if (!isLargerScreen) {
            expandDrawer();
        }
    };

    const onChange = (text: any) => {
        // on desktop, expand/collapse based on typing
        if (isLargerScreen) {
            if (text) {
                expandDrawer();
            } else {
                collapseDrawer();
            }
        }
    };

    const expandDrawer = () => {
        // expand enough to show all results (hard-coded to 5 in LocationService),
        // but no more than that, to ensure the cursor is not pushed off the top
        // when the keyboard appears (this problem observed on iphone 11 pro)
        drawerState.setHeight(325);
    };

    const collapseDrawer = () => {
        drawerState.setHeight(0);
    };

    const onScroll = () => {
        tracker.track('Flight Checklist Scrolled');
    };

    // intended for use by devs, coordinates must be entered as json {"lat":45,"lng":-73}
    // use coordinates entered to drop a pin at that location
    const onCoordinatesEntered = async (coordinates: ICoordinate) => {
        const mission = await beginCreatingMission();
        mission.setCenter(coordinates);
        const location = await locationSearchService.reverseLookup(coordinates);
        if (location) {
            mission.setAddress(location.address);
        }
        setNewMission(mission);
    };

    // different margin for side drawer
    const marginTop = (isLargerScreen ? 0 : 5);

    return (
        <>
            <FeedbackAlert />
            <ClearPreMissionWeatherState />

            <Drawer minHeight={110} onScroll={onScroll}>
                <div data-id="location-search-drawer" onClick={onClick} style={{marginTop}}>
                    <LocationSearchPanel onChange={onChange} onSelect={onAddressSelected} onCoordinates={onCoordinatesEntered} />
                </div>
            </Drawer>
        </>
    );
};
