import { useState } from 'react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { InsuranceState } from './InsuranceState';
import { useSubscription } from '@meraki-internal/state';
import { IonButton, IonIcon } from '@ionic/react';
import { umbrella } from 'ionicons/icons';
import { createUseStyles } from 'react-jss';
import { StatePicker } from '../components/picker/StatePicker';
import { ForwardIcon } from '../components/icons/Icons';
import { TrackingService } from '../support/tracking/TrackingService';
import { HistoryViewModel } from '../app/HistoryViewModel';

const useStyles = createUseStyles(() => ({
    quoteButton: {
        height: 100,
        '--padding-start': '0px',
        '--padding-end': '0px'
    },
    quoteButtonWithStatePicker: {
        '--padding-start': '0px',
        '--padding-end': '0px',

        // remove margin so button fills containing div
        margin: 0,

        // add padding to prevent button shadow showing below containing div
        paddingTop: 2,
        paddingBottom: 2
    }
}));

const StartArrow: React.FC<{label?: string}> = ({ label = '' }) => (
    <div style={{
        minWidth: label ? 80 : 55,
        height: 40,
        paddingLeft: label ? 40 : 15,
        paddingRight: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: 10,
        backgroundColor: 'var(--ion-color-insurance)',
        color: 'white',
        borderTopLeftRadius: 20,
        borderBottomLeftRadius: 20
    }}>
        <b>{label}</b>
        <ForwardIcon size="medium" />
    </div>
);

export const NewPolicyButton: React.FC = () => {
    const classes = useStyles();

    const tracker = useInstance(TrackingService);
    const history = useInstance(HistoryViewModel);

    const insuranceState = useInstance(InsuranceState);
    useSubscription(() => insuranceState);

    const [stateCode, setStateCode] = useState<string>();

    const supportedStates = insuranceState.getSupportedStates();
    const policies = insuranceState.getPolicies();
    const hasPendingApplication = insuranceState.hasPendingApplication();
    const hasPolicies = (policies.length > 0);

    const onContinue = () => {
        const isSupportedState = supportedStates.includes(stateCode || '');
        tracker.track('Insurance Marketing State Selected', () => ({ state: stateCode, isSupportedState }));
        history.push(isSupportedState ? `/insurance/quote?state=${stateCode}` : `/insurance/unsupported?state=${stateCode}`);
    };

    return (
        <div style={{margin: '20px 15px'}}>

            {!hasPendingApplication && !hasPolicies &&
                <div style={{backgroundColor: 'var(--ion-color-secondary)', borderRadius: 10, display: 'flex', alignItems: 'center'}}>

                    <div style={{minWidth: 0, flexGrow: 1, textAlign: 'center'}}>
                        <StatePicker placeholder="Pick Home State" color="light" size="large" excludeTerritories stateCode={stateCode} onChange={setStateCode} />
                    </div>

                    <IonButton data-id="new-policy-button" size="large" color="secondary" className={classes.quoteButtonWithStatePicker} disabled={!stateCode} onClick={onContinue}>
                        <StartArrow label="Go" />
                    </IonButton>

                </div>
            }

            {(hasPendingApplication || hasPolicies) &&
                <IonButton data-id="new-policy-button" size="large" color="secondary" className={classes.quoteButton} expand="block" routerLink="/insurance/quote">

                    <div style={{display: 'flex', alignItems: 'center', gap: 10, width: '100%'}}>

                        <div style={{width: 50, marginLeft: 10, flexShrink: 0, flexGrow: 0}}>
                            <IonIcon icon={umbrella} color="light" style={{fontSize: 35, transform: hasPendingApplication ? 'rotate(180deg)' : undefined}} />
                        </div>

                        <div style={{display: 'flex', flexDirection: 'column', flexShrink: 1, flexGrow: 1, alignItems: 'start', whiteSpace: 'pre-wrap', textAlign: 'left'}}>
                            <h3 style={{marginTop: 0, marginBottom: 5}}><b>{hasPendingApplication ? 'Finalize Quote' : 'Start a New Policy'}</b></h3>
                            <small style={{lineHeight: 1, color: 'var(--ion-color-step-300)'}}>{hasPendingApplication ? 'Complete your quote and get instant coverage' : 'Explore more coverage options'}</small>
                        </div>

                        <StartArrow />

                    </div>

                </IonButton>
            }
        </div>
    );
};
