import moment from 'moment';
import { MissionsState } from '../missions/model/MissionsState';
import { IScreenshotablePage } from '../support/screenshots/IScreenshotablePage';
import { MissionFeatureCollectionFacadeAdapter } from '@autopylot-internal/tiles-client';
import { getWellKnownLocation } from '../support/screenshots/getWellKnownLocation';
import { MissionWeatherPage } from './MissionWeatherPage';
import { IMission } from '../mission/model/EditableMissionState';
import { IScreenshotHint } from '../support/screenshots/ScreensPage';
import { WeatherServiceForecastMocker } from './WeatherServiceForecastMocker';

interface IMyPageProps {
    // mission startTime defaults to start of next hour
    startTime?: string;

    // forecast start time, defaults to now
    forecastStartTime?: string;

    durationHours?: number;
    hint?: IScreenshotHint;
}

export default class ScreenshotMissionWeatherPage implements IScreenshotablePage<IMyPageProps> {
    static inject = () => [
        MissionsState,
        MissionFeatureCollectionFacadeAdapter,
        WeatherServiceForecastMocker
    ];
    constructor(
        private missions: MissionsState,
        private mfcAdapter: MissionFeatureCollectionFacadeAdapter,
        private mockWeatherService: WeatherServiceForecastMocker
    ) { }

    screenId = 'mission-weather';

    ComponentUnderTest = MissionWeatherPage;

    permutationToProps = (props: IMyPageProps) => {
        let { startTime, forecastStartTime }  = props;
        const { durationHours = 1 }  = props;

        const missionId = String(Date.now());
        startTime = startTime || moment().startOf('h').add(1, 'h').toISOString();
        const endTime = moment(startTime).add(durationHours, 'h').toISOString();

        forecastStartTime = moment().startOf('h').toISOString();

        const location = getWellKnownLocation('uncontrolled');

        this.mockWeatherService.forecastingStartsAt(forecastStartTime);

        const address = location.address;
        const radiusPolygon = location.radiusPolygon;
        radiusPolygon.features[0].properties.startTime = startTime;
        radiusPolygon.features[0].properties.endTime = endTime;
        radiusPolygon.features[0].properties.isLicensed = true;

        const missionParams: IMission = {
            missionId,
            startTime,
            status: 'active',
            durationMinutes: durationHours * 60,
            radiusPolygon: this.mfcAdapter.adaptFromPersisted(radiusPolygon),
            address: address,
            maxAltitude: 400,
            links: {},
            version: 1
        };

        this.missions.addMissionToState(missionParams);

        return { missionId };
    };

    blockScreenshotTil = async (params: IMyPageProps) => {

    };

    // TODO: missionStartTime and forecastStart (and possibly end)
    // need to be disambiguated for these scenarios to make sense (add the value they are after)
    permutations: { [key: string]: IMyPageProps } = {
        'later-today': {
            startTime: moment.tz('us/eastern').startOf('hour').add(2, 'h').toISOString(),
            hint: { text: 'Hourly: start of current hour should be highlighted. Daily: today is highlighted' }
        },
        'tomorrow': {
            forecastStartTime: moment.tz('us/eastern').startOf('hour').toISOString(),
            startTime: moment.tz('us/eastern').startOf('day').add(1, 'day').toISOString(),
            hint: {
                text: 'Daily should start today but tomorrow is highlighted. '
            }
        },
        '3-hour-mission': {
            startTime: moment.tz('us/eastern').startOf('hour').add(1, 'h').toISOString(),
            durationHours: 3,
            hint: { text: '3 hours should be highlighted starting with the next hour'}
        },
        '10-hours-into-12-hour-mission': {
            startTime: moment.tz('us/eastern').startOf('hour').add(-10, 'h').toISOString(),
            durationHours: 12,
            hint: { text: `first hour is highlighted and 12 hour are highlighted (but you can't see them all)`}
            // (consider as future enhancement shifting summary and scrolling hourly)
        },

        // a mission that spans more than one day
        //  - we need to mock "now" which isn't worth it at this time

        '7-days-out': {
            startTime: moment.tz('us/eastern').startOf('hour').add(7, 'd').toISOString(),
            hint: { text: 'starts today, current day is highlighted but below the fold'}
        },

        '11-days-out': {
            startTime: moment.tz('us/eastern').startOf('hour').add(10, 'd').toISOString(),
            hint: { text: `all cards communicate that you can't get the forecast yet`}
        },

        // 'completed-1-min-ago-which-was-yesterday'
        //     - edge case, requires emulating now
    };
}
