import { IonIcon, IonItem, IonLabel } from '@ionic/react';
import { useLocation } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { ReminderBadge } from '../components/badge/ReminderBadge';
import { NewFeatureBadge } from '../components/badge/NewFeatureBadge';
import { MenuViewModel } from './MenuViewModel';

const useStyles = createUseStyles({
    menuItem: {
        '& > ion-icon.md[slot]': {
            marginTop: 6,
            marginBottom: 6
        }
    }
});

interface MenuItemProps {
    title: string;
    small?: boolean;
    bold?: boolean;
    url?: string;
    otherUrls?: string[];
    reminderCount?: number;
    icon?: any;
    onClick?: () => void;
    className?: string;
    newFeatureKey?: string
};

export const MenuItem: React.FC<MenuItemProps> = ({ title, small = false, bold = false, url, otherUrls = [], reminderCount = 0, icon, onClick, className, newFeatureKey }) => {
    const maxHeight = (small ? 40 : undefined);
    const minHeight = (small ? undefined : 55);
    const fontWeight = (small && !bold ? undefined : 'bold');
    const fontSize = (small ? 18 : 20);

    const classes = useStyles();

    const menuVM = useInstance(MenuViewModel);

    const location = useLocation();
    const isSelected = ([url, ...otherUrls].some(path => location.pathname?.startsWith(path!)));

    return (
        <IonItem
            button
            style={{maxHeight, '--min-height': `${minHeight}px`, '--background': isSelected ? 'rgba(var(--ion-color-primary-rgb), 0.2)' : 'inherit'}}
            className={`${className || ''} ${classes.menuItem} ${isSelected ? 'selected' : ''}`}
            routerLink={url}
            routerDirection="none"
            lines="none"
            detail={false}
            onClick={menuVM.clickHandler(onClick)}
        >
            <div slot="start" style={{position: 'relative'}} title={title}>
                <IonIcon
                    style={{pointerEvents: 'none', fontSize: 24, minWidth: 24}}
                    color={isSelected ? 'primary' : 'medium'}
                    icon={icon?.raw}
                />
                {reminderCount > 0 &&
                    <div style={{position: 'absolute', top: -2, right: -7}}>
                        <ReminderBadge size="small" count={reminderCount} />
                    </div>
                }
            </div>

            <IonLabel style={{fontSize, fontWeight}}>{title}</IonLabel>

            {newFeatureKey &&
                <NewFeatureBadge slot="end" featureKey={newFeatureKey} />
            }
        </IonItem>
    );
};
