import { Container } from '@meraki-internal/react-dependency-injection';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { CSSProperties, useEffect, useState } from 'react';
import { Page } from '../../components/page/Page';
import { IScreenshotablePage } from './IScreenshotablePage';

export interface IScreenshotHint {
    text: string;
}

const ScreenshotHint: React.FC<{ position: 'top-right' | 'bottom-right'}> = ({ children, position }) => {
    const style: CSSProperties = {
        position: 'fixed',
        right: 0,
        background: '#F3E779',
        fontSize: 5,
        width: 75,
        zIndex: 1100
    };
    if (position === 'top-right'){
        style.top = 0;
    } else {
        style.bottom = 0;
    }
    return (
        <div style={style}>
            {children}
        </div>
    );
};

const requireContext = (require as any).context('../../', true, /\/Screenshot.*\.tsx$/);
const keys = requireContext.keys();
// NOTE: can filter out files that start with Screenshot if we have to, but easier to avoid
// .filter((moduleId: string) => moduleId !== './support/screenshots/ScreenshotsPage.tsx');

const pagesCtors: IScreenshotablePage<any>[] = keys.map((moduleId: string) => requireContext(moduleId))
    .map((mod: any) => mod.default);

/**
 * To add another screenshottable page, implement IScreenshotablePage like ScreenshotMissionDetailsPage
 * Its name must start with Screenshot and end with .tsx to get picked up by our import above
 */

const ScreenPermutationsIndex = ({ permutations, screenId }: any) => (
    <ul id="permutations">
        {Object.keys(permutations).reduce((groups: any[], permId) => {
            // eslint-disable-next-line prefer-const
            let [groupId, ...rest] = permId.split(':');
            if (rest.length === 0) {
                groupId = 'none';
            }
            let group = groups.find(g => g.groupId === groupId);
            if (!group) {
                group = {
                    groupId,
                    permutations: []
                };
                groups.push(group);
            }

            group.permutations.push({
                permIdLabel: rest.length > 0 ? rest.join('') : permId,
                permId
            });

            return groups;

        }, []).map(group => {
            const permutationsList = group.permutations.map(({ permId, permIdLabel }: any) => (
                <li key={permId}>
                    <a href={`?screenshots&ts=${Math.random()}#/screens/${screenId}/${permId}`}>{permIdLabel}</a>
                </li>
            ));
            if (group.groupId === 'none') {
                return permutationsList;
            }
            return (
                <li key={group.groupId}>
                    {group.groupId}
                    <ul>
                        {permutationsList}
                    </ul>
                </li>
            );
        })}
    </ul>
);

// TODO: rename
const ScreensIndexIndex = ({ screens }: any) => (
    <ul id="screens">
        {Object.keys(screens).sort((s1, s2) => s1.localeCompare(s2)).map(screenId => (
            <li key={screenId}>
                <h2><a style={{color: 'black'}} href={`?screenshots&ts=${Math.random()}#/screens/${screenId}`}>{screenId}</a></h2>
                <ScreenPermutationsIndex permutations={(screens as any)[screenId].permutations} screenId={screenId} />
            </li>
        ))}
    </ul>
);

async function waitForAllImagesToFinishLoading() {
    let anyStillLoading = true;
    while (anyStillLoading) {
        anyStillLoading = Array.prototype.slice.call(document.querySelectorAll('img'))
            .some(imgEl => !imgEl.complete);
        if (anyStillLoading){
            await new Promise(resolve => setTimeout(resolve, 100));
        }
    }
}

const buildComponent = (ComponentUnderTest: any, permutationToProps: any, blockScreenshotTil: any) => {
    return (permutationProps: any) => {
        const [compProps, setCompProps] = useState<any>();
        const [isReadyForScreenshot, setIsReadyForScreenshot] = useState(false);
        useEffect(() => {
            Promise.resolve()
                .then(async () => {
                    const _props = await permutationToProps(permutationProps);
                    setCompProps(_props);
                })
                .then(async () => {
                    let start = Date.now();
                    console.log('waiting for images to finish loading');
                    await waitForAllImagesToFinishLoading();
                    console.log(Date.now() - start, 'ms waited for images to finish loading');


                    if (blockScreenshotTil) {
                        start = Date.now();
                        console.log('waiting blockScreenshotTil');

                        await blockScreenshotTil(permutationProps);
                        console.log(Date.now() - start, 'ms waited blockScreenshotTil');
                    }
                    console.log('is ready for screenshot');
                    setIsReadyForScreenshot(true);
                });
            // disabled because permutationProps is a different reference every time
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [setCompProps]);
        if (!compProps) {
            return null;
        }
        return (
            <div style={{height: '100%'}} data-screenshot-ready={isReadyForScreenshot}>
                <ComponentUnderTest {...compProps} />
                {permutationProps.hint && permutationProps.hint.text && (
                    <ScreenshotHint position="top-right">
                        {permutationProps.hint.text}
                    </ScreenshotHint>
                )}
            </div>
        );

    };
};

export const ScreensPage = ({ screenId, permutation }: { screenId?: keyof typeof screens, permutation?: string }) => {
    const [screens, setScreens] = useState<any>();
    const container = useInstance(Container);
    useEffect(() => {
        const pages = pagesCtors.map(ctor => container.get(ctor)).filter((page: IScreenshotablePage<any>) => !page.disabled);
        const _screens: any = {};
        for (const { screenId: _screenId, Component, ComponentUnderTest, permutationToProps, permutations, blockScreenshotTil, WrapWith } of pages) {
            const SUTComponent = Component || buildComponent(ComponentUnderTest, permutationToProps, blockScreenshotTil);
            let FinalComponent = SUTComponent;
            if (WrapWith){
                FinalComponent = (permutationProps: any) => {
                    return (
                        <WrapWith>
                            <SUTComponent {...permutationProps} />
                        </WrapWith>
                    );
                };
            }

            _screens[_screenId] = {
                Component: FinalComponent,
                permutations
            };
        }

        setScreens(_screens);
    }, [setScreens, container]);

    if (!screens) {
        return null;
    }

    if (!screenId) {
        return (
            <Page title="Screenshot Pages">
                <ScreensIndexIndex screens={screens} />
            </Page>
        );
    }

    const screen = screens[screenId];
    if (screen && permutation) {
        const permutationProps = (screen as any).permutations[permutation];
        const ScreenComponent = (screen as any).Component;

        if (permutationProps) {
            return <ScreenComponent {...permutationProps} />;
        }
    }

    return (
        <Page>
            <div style={{paddingLeft: 20}}>
                <h2>{screenId}</h2>
                {permutation &&
                    <p>Permutation <strong>{permutation}</strong> is not yet implemented. Try one of these:</p>
                }
                {screen && (
                    <ScreenPermutationsIndex permutations={screen.permutations} screenId={screenId} />
                )}
                {!screen && (
                    <ScreensIndexIndex />
                )}
            </div>
        </Page>

    );
};
