import { IonButton } from '@ionic/react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { DeviceStatusBar } from '../../support/DeviceStatusBar';
import { TrackingService } from '../../support/tracking/TrackingService';
import { ReminderBadge } from '../badge/ReminderBadge';
import { RoundIconButton } from './RoundIconButton';
import { MenuIcon } from '../icons/Icons';
import { MenuViewModel } from '../../menu/MenuViewModel';

export const MenuButton: React.FC<{ onTop?: boolean, showReminderBadge?: boolean }> = ({ onTop, showReminderBadge }) => {
    const vm = useInstance(MenuViewModel);

    const tracker = useInstance(TrackingService);
    const statusBar = useInstance(DeviceStatusBar);
    const statusBarHeight = statusBar.getHeight();

    // allow for status bar with notch (but don't go less than 10px)
    const top = Math.max(statusBarHeight, 10);

    const onMenuButtonClicked = () => {
        vm.open();
        tracker.track('Nav Menu Opened');
    };

    let buttonStyle: any = {
    };
    let badgeStyle: any = {
        position: 'absolute',
        top: 5,
        left: 29,
        pointerEvents: 'none'
    };

    if (onTop) {
        buttonStyle = {
            ...buttonStyle,
            position: 'absolute',
            left: 5,
            top: top,
            zIndex: 1000
        };
        badgeStyle = {
            ...badgeStyle,
            top: top + 5,
            left: 40,
            zIndex: 1001
        };
    }

    return (
        <>
            <div style={buttonStyle}>
                {onTop &&
                    <RoundIconButton data-id="menu-button" Icon={MenuIcon} iconColor="medium" onClick={onMenuButtonClicked} />
                }
                {!onTop &&
                    <IonButton data-id="menu-button" style={{width: 50}} onClick={onMenuButtonClicked}><MenuIcon color="medium" slot="icon-only" /></IonButton>
                }
            </div>
            {showReminderBadge &&
                <div style={badgeStyle}>
                    <ReminderBadge size="small" />
                </div>
            }
        </>
    );
};
