import { IonLabel, IonSegment, IonSegmentButton } from '@ionic/react';

export interface ISegmentButtonProps {
    options: { value: string, label: string }[];
    activeValue: string;
    setActiveValue: (v: string) => void;
}

export const SegmentedButtons: React.FC<ISegmentButtonProps> = ({ options, activeValue, setActiveValue }) => {

    const segmentStyle = {
        borderRadius: 20,
        background: 'rgba(var(--ion-color-secondary-rgb), 0.4)'
    };

    const segmentButtonStyle = {
        '--border-radius': '20px',
        '--indicator-color': 'var(--ion-color-primary)',
        '--color': 'white',
        '--color-checked': 'white',
        textTransform: 'capitalize'
    };

    // There seems to be a bug in IonSegment, that causes it not to re-render when activeValue changes;
    // the workaround is to (redundantly) set key = activeValue
    const key = activeValue;

    return (
        <IonSegment key={key} value={activeValue} onIonChange={e => setActiveValue(e.detail.value!)} style={segmentStyle}>
            {options.map(({ value, label }) => (
                <IonSegmentButton value={value} style={segmentButtonStyle}  key={value}>
                    <IonLabel>{label}</IonLabel>
                </IonSegmentButton>
            ))}
        </IonSegment>
    );
};
