import { Group } from '../ComponentLibrary';
import { AnchorButton } from './AnchorButton';

const DemoButton: Group & React.FC = () => {
    return (
        <div>
            <span>Has onClick event like a button, but renders as an inline link. </span>
            <AnchorButton onClick={() => alert('clicked')}>Click me!</AnchorButton>

        </div>
    );
};

DemoButton.componentName = 'AnchorButton';

export default DemoButton;
