import { IonPage } from '@ionic/react';
import { PageContent } from '../components/page/PageContent';
import { useSubscription } from '@meraki-internal/state';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useEffect } from 'react';
import { DeviceStatusBar } from '../support/DeviceStatusBar';
import { MenuButton } from '../components/button/MenuButton';
import { FloatingBackButton } from '../components/button/FloatingBackButton';
import { Device } from '../support/Device';
import { MissionsState } from '../missions/model/MissionsState';
import { PageState } from '../components/page/Page';
import { PageHeader } from '../components/page/PageHeader';
import { IntercomService } from './intercom/IntercomService';
import { Menu, MENU_COLLAPSED_WIDTH } from '../menu/Menu';
import { useBreakpoints } from '../support/hooks/useBreakpoints';

/**
 * GlobalPage is responsible for producing the PageHeader. We want to render this only once, to reduce
 * flicker on page transitions. We achieve this by placing it in AppRouter, outside the router outlet.
 * (Note that it cannot be moved higher in the hierarchy, or the drawer will be on top of the menu).
 * Each page is then wrapped in the Page component (above), which simply updates the state used here.
 */
export const GlobalPage: React.FC = ({ children }) => {
    const pageState = useInstance(PageState);
    const statusBar = useInstance(DeviceStatusBar);
    const deviceInfo = useInstance(Device);

    useSubscription(() => pageState);
    const { title, hideMenu, onBack, backLabel, onAction, actionLabel, onClose, fullWidth } = pageState.state;

    const missions = useInstance(MissionsState);
    useSubscription(() => missions);

    const intercom = useInstance(IntercomService);
    useSubscription(() => intercom);

    const { isLargerScreen } = useBreakpoints();

    const showReminderBadge = (missions.getReminderCount() > 0 || intercom.getUnreadCount() > 0);

    // start showing intercom messages once first page has loaded
    useEffect(() => {
        setTimeout(() => intercom.setMessageVisibility(true));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // ensure status bar text on physical device is a contrasting color
    useEffect(() => {
        statusBar.setStyle('light');
        document.title = title ? `AutoPylot | ${title}` : 'AutoPylot';
    }, [title]); // eslint-disable-line react-hooks/exhaustive-deps

    // make room for permanent menu on desktop
    const marginLeft = (isLargerScreen && !hideMenu ? MENU_COLLAPSED_WIDTH : undefined);

    return (
        <>
            {/* NOTE: Menu is highly coupled to IonRouterOutlet and so we shouldn't remove / use
                something other than IonRouterOutlet. Unless we refactor Menu to not use IonMenu
                which is likely not worth it.
            */}
            {!hideMenu &&
                <Menu outlet="main-outlet" />
            }

            <IonPage data-platform={deviceInfo.platform} style={{marginLeft}}>
                {title &&
                    <PageHeader fullWidth={fullWidth} title={title} onBack={onBack} backLabel={backLabel} onAction={onAction} actionLabel={actionLabel} onClose={onClose} showReminderBadge={showReminderBadge} />
                }
                {!title && onBack &&
                    <FloatingBackButton onClick={onBack} />
                }
                {!title && !onBack && !hideMenu && !isLargerScreen &&
                    <MenuButton onTop showReminderBadge={showReminderBadge} />
                }
                <PageContent noScroll>
                    {children}
                </PageContent>
            </IonPage>
        </>
    );
};
