import { ISO8601DateTimeString } from '@autopylot-internal/tiles-client';
import moment from 'moment';
import 'moment-timezone';
import tzLookup from 'tz-lookup';
import { ICoordinate } from '../model/ICoordinate';
import coordinatesFormatter from 'formatcoords';

// return timezone id (e.g. 'America/New_York') for given lat/lng
export const getTimezone = (location: ICoordinate) => {
    const { lat, lng } = location;
    return tzLookup(lat, lng);
};

// return "Today", "Yesterday", "Tomorrow", or format like "Mon May 23" for all other dates
export const formatLocalDay = ({ date, location }: { date: string, location: ICoordinate }) => {
    const tz = getTimezone(location);
    const dateMoment = moment(date).tz(tz).startOf('d');
    const today = moment().tz(tz).startOf('d');
    const diff = dateMoment.diff(today, 'd');
    return (diff === 0 ? 'today' : diff === 1 ? 'tomorrow' : diff === -1 ? 'yesterday' : dateMoment.format('ddd MMM D'));
};

// return local formatted date (e.g. "Mon Nov 17, 2021")
export const formatLocalDate = ({ date, location, includeYear }: { date: string, location: ICoordinate, includeYear?: boolean }) => {
    const tz = getTimezone(location);
    return moment(date).tz(tz).format(`ddd MMM D${includeYear ? ', YYYY' : ''}`);
};

// return local formatted date without month (e.g. "Mon 17")
export const formatLocalDateShort = ({ date, location }: { date: string, location: ICoordinate }) => {
    const tz = getTimezone(location);
    return moment(date).tz(tz).format('ddd D');
};

// return local formatted time optionally with timezone (e.g. "9:30 am EDT")
export const formatLocalTime = ({ time, location, withTimeZone = false }: { time: string, location: ICoordinate, withTimeZone?: boolean }) => {
    const format = 'h:mm a' + (withTimeZone ? ' zz' : '');
    const tz = getTimezone(location);
    return moment(time).tz(tz).format(format);
};

// return local formatted time as hour only (e.g. "9 am")
export const formatLocalHour = ({ time, location, withTimeZone = false }: { time: string, location: ICoordinate, withTimeZone?: boolean }) => {
    const format = 'ha' + (withTimeZone ? ' zz' : '');
    const tz = getTimezone(location);
    return moment(time).tz(tz).format(format);
};

// return local formatted time with date (e.g. 4/5/22 3:14 am EST)
export const formatLocalDayAndTime = ({ location, time, withTimeZone = false }: { location: ICoordinate, time: ISO8601DateTimeString, withTimeZone?: boolean }): string => {
    const format = 'M/D/YYYY h:mm a' + (withTimeZone ? ' zz' : '');
    const tz = getTimezone(location);
    return moment(time).tz(tz).format(format);
};

// return local formatted time range with timezone (e.g. "9:30 am - 10:30 am EDT")
export const formatLocalTimeRange = ({ startTime, endTime, location, withTimeZone = false }: { startTime: string, endTime: string, location: ICoordinate, withTimeZone?: boolean }) => {
    const start = formatLocalTime({ time: startTime, location });
    const end = formatLocalTime({ time: endTime, location, withTimeZone });
    return `${start} - ${end}`;
};

export const formatDuration = (durationMinutes: number) => {
    const duration = moment.duration(durationMinutes, 'minutes');
    const hours = duration.hours();
    const minutes = duration.minutes();
    let output = '';
    if (hours) {
        output += `${hours} h `;
    }
    if (minutes) {
        output += `${minutes} m `;
    }
    return output.trim();
};

export const formatPhone = (phone: string) => {
    if (phone.length === 10) {
        return `${phone.substring(0, 3)}-${phone.substring(3, 6)}-${phone.substring(6)}`;
    }
    return phone; // not recognized US format
};

export const formatCoordinates = ({ lat, lng }: ICoordinate) => {
    return coordinatesFormatter(lat, lng).format('ddX', { latLonSeparator: ', ', decimalPlaces: 3 });
};

export const round = (value: number, dp: number): number => {
    const divisor = 10 ** dp;
    return Math.round(value * divisor) / divisor;
};

// converts "HelloWorld" to "Hello World"
export const unCamelCase = (str: string) => {
    return str
        // insert a space between lower & upper
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        // space before last upper in a sequence followed by lower
        .replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1 $2$3');
};

export const initCap = (s: string) => {
    return s[0].toUpperCase() + s.substring(1);
};
