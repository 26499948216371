import { MapViewModel } from '../model/MapViewModel';
import { getState } from '../../support/helpers/states';
import { IAddress } from '../../support/model/IAddress';
import { ILocation } from '../../support/model/ILocation';
import { ICoordinate } from '../../support/model/ICoordinate';
import { EnvConfiguration } from '../../app/config/EnvConfiguration';

const placeTypeParsers: { [key: string]: (place_name: string) => IAddress} = {
    'country': (addressText: string = ''): IAddress => {
        return { city: '', state: '', country: (addressText || '').trim() };
    },
    'address': (addressText: string = ''): IAddress => {
        // eg 12025 Catamount Drive, Leicester, Vermont 05733, United States
        const parts = addressText.split(',');
        const country = (parts.pop() || '').trim();
        const stateZip = (parts.pop() || '').trim();
        const city = (parts.pop() || '').trim();
        const street = parts.join(',').trim();

        const stateZipParts = stateZip.split(' ');
        const zip = (stateZipParts.pop() || '').trim();
        const stateName = stateZipParts.join(' ').trim();
        const state = (getState(stateName)?.code || '');

        return { street, city, state, zip, country };
    },
    'place': (addressText: string = ''): IAddress => {
        // eg Leicester, Vermont, United States
        const [city, state, country] = addressText.split(',');

        const stateCode = getState(state)?.code || '';

        return {
            city: (city || '').trim(),
            state : stateCode,
            country: (country || '').trim()
        };
    },
    'poi': (addressText: string = ''): IAddress => {
        // eg McDonald's, 720 Cleveland St, Rapid City, South Dakota 57701, United States
        const [name, ...rest] = addressText.split(',');
        const address = placeTypeParsers.address(rest.join(','));
        return {
            ...address,
            name: name.trim()
        };
    }
};

export class LocationSearchService {
    static inject = () => [MapViewModel, EnvConfiguration];
    constructor(private map: MapViewModel, private config: EnvConfiguration){}

    private parseAddress = ({ place_name, place_type } : { place_name: string, place_type: string[] }): IAddress => {
        if (place_type.includes('poi')){
            return placeTypeParsers.poi(place_name);
        }
        else if (place_type.includes('place')){
            return placeTypeParsers.place(place_name);
        }
        else if (place_type.includes('country')){
            return placeTypeParsers.country(place_name);
        }
        else {
            return placeTypeParsers.address(place_name);
        }
    };

    reverseLookup = async ({lng, lat}: ICoordinate) => {
        const params = new URLSearchParams();
        params.set('access_token', this.config.MAPBOX_TOKEN);
        params.set('country', 'us');
        params.set('limit', '1');
        params.set('types', 'place,poi,address,country');
        params.set('worldview', 'us');

        const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?${params.toString()}`;

        const result = await fetch(url);
        const { features } = await result.json();

        if (!features?.length) {
            console.log('No features returned by mapbox', {lat, lng});
            return undefined;
        }

        const { center, place_name, place_type } = features[0];
        const address = this.parseAddress({ place_name, place_type });

        // ignore if not US
        if (address.country !== 'United States') {
            console.log(`mapbox returned other country: "${place_name}"`, {lat, lng});
            return undefined;
        }

        return {
            address,
            coordinates: {
                lng: center[0],
                lat: center[1]
            }
        };
    };

    search = async ({ searchText, matchesToReturn }: { searchText: string, matchesToReturn: number }): Promise<ILocation[]> => {
        if (!searchText) {
            return [];
        }

        const params = new URLSearchParams();
        params.set('access_token', this.config.MAPBOX_TOKEN);
        params.set('country', 'us');

        // mapbox allows values up to 10
        params.set('limit', String(matchesToReturn));

        params.set('types', 'place,poi,address');

        params.set('worldview', 'us');

        if (!this.map.isInitialCenter()){
            // Bias the search results to the wherever the user last left the center of the map
            const mapCenter = this.map.getCenter();
            params.set('proximity', `${mapCenter.lng},${mapCenter.lat}`);
        } else {
            // If this is the first search by a new user who has denied location permission, we don't have a useful map center.
            // So for this edge case, bias towards whatever location mapbox can infer from the ip address. This is likely inaccurate
            // and we have FUD about whether it's working as expected (because we have seen different results in staging v prod).
            // So note that in app tests, we avoid hitting this path by panning the map a bit before each test.
            params.set('proximity', `ip`);
        }

        const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${searchText}.json?${params.toString()}`;

        const result = await fetch(url);
        const { features } = await result.json();

        if (!features?.length) {
            console.log('No features returned by mapbox', {searchText});
            return [];
        }

        return features.map(({ center, place_name, place_type }: { center: number[], place_name: string, place_type: string[] }) => {
            return {
                address: this.parseAddress({ place_name, place_type }),
                coordinates: {
                    lng: center[0],
                    lat: center[1]
                }
            };
        });
    };
}
