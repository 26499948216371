import { State, useSubscription } from '@meraki-internal/state';
import { NewMissionState } from '../../missions/model/NewMissionState';
import { usePageView } from '../../support/tracking/usePageView';
import { Field, FieldList } from '../../components/field/Field';
import { TextInput } from '../../components/field/TextInput';
import { FAA_NAME_PATTERN } from '../../profile/ProfilePage';
import { DrawerButtons } from '../../components/drawer/DrawerButtons';
import { Button } from '../../components/button/Button';
import { OperatorState } from '../../profile/OperatorState';
import { IOperatorProfile } from '../../profile/IOperator';
import { AlertPresenter } from '../../app/AlertBinder';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useRef } from 'react';
import { TrackingService } from '../../support/tracking/TrackingService';
import { DrawerLabel } from '../../components/drawer/DrawerLabel';

class MissionOperatorPageState extends State<{ values: IOperatorProfile, submitting: boolean }> {

    static inject = () => [OperatorState, TrackingService];
    constructor(private operatorState: OperatorState, private tracker: TrackingService) {
        super({
            values: { ...operatorState.state },
            submitting: false
        });

        operatorState.subscribe(() => {
            this.setState({
                values: { ...operatorState.state },
            });
        });
    }

    updateValues = (updates: Partial<IOperatorProfile>) => {
        this.setState({ values: {
            ...this.state.values,
            ...updates
        }});
    };

    save = async () => {
        const { firstName, lastName, phone } = this.state.values;
        await this.operatorState.save({ firstName, lastName, phone });
        this.tracker.track('Pilot Info Entered', () => ({ firstName, lastName, phone, from: 'Create Mission' }));
    };
}

export const MissionOperatorPage: React.FC<{vm: NewMissionState, onBack: () => void, onContinue: () => void}> = ({ vm, onBack, onContinue }) => {
    usePageView('Mission Operator');

    useSubscription(() => vm);

    const alert = useInstance(AlertPresenter);

    const pageStateInstance = useInstance(MissionOperatorPageState);
    const pageState = pageStateInstance.state;
    useSubscription(() => pageStateInstance);

    const formRef = useRef<HTMLFormElement>(null);

    if (!pageState?.values) {
        return null;
    }

    const onChangeHandler = (key: string) => {
        return (value: string) => {
            pageStateInstance.updateValues({ [key]: value });
        };
    };

    const onClickContinueButton = async () => {
        if (pageState.submitting) {
            return;
        }

        pageStateInstance.setState({ submitting: true });

        try {
            if (formRef.current?.reportValidity()) {
                await pageStateInstance.save();
                onContinue();
            }
        } catch(e: any) {
            alert.showAndLogError(e);
        }

        pageStateInstance.setState({ submitting: false });
    };

    return (
        <div data-id="mission-operator-drawer">

            <h5>Please enter your info so we can create your first mission!</h5>

            <DrawerLabel text="Pilot Info" />

            {/* TODO: create component (box with colored left border) */}
            <div style={{borderTop: '1px solid rgba(var(--ion-color-primary-rgb), 0.15)', borderLeft: '5px solid var(--ion-color-primary)', borderTopLeftRadius: 2, borderBottomLeftRadius: 2, marginTop: 10}}>

                <form ref={formRef}>
                    <FieldList>

                        <Field dataSet={{type: 'first'}} label="First Name" required>
                            <TextInput
                                value={pageState.values.firstName}
                                onChange={onChangeHandler('firstName')}
                                pattern={FAA_NAME_PATTERN}
                                maxlength={60}
                                title="Must be letters, punctuation, and whitespace"
                            />
                        </Field>

                        <Field dataSet={{type: 'last'}} label="Last Name" required>
                            <TextInput
                                value={pageState.values.lastName}
                                onChange={onChangeHandler('lastName')}
                                pattern={FAA_NAME_PATTERN}
                                maxlength={60}
                                title="Must be letters, punctuation, and whitespace"
                            />
                        </Field>

                        <Field dataSet={{type: 'phone'}} label="Phone Number" required>
                            <TextInput
                                value={pageState.values.phone}
                                onChange={onChangeHandler('phone')}
                                type="tel"
                                inputmode="tel"
                                pattern="[1-9]\d{9}"
                                maxlength={10}
                                title="Must be 10 digits, and cannot start with a zero"
                            />
                        </Field>

                    </FieldList>
                </form>

            </div>

            <DrawerButtons>
                <Button color="secondary" fill="outline" dataType="cancel-button" onClick={onBack}>Back</Button>
                <Button dataType="continue-button" disabled={pageState.submitting} onClick={onClickContinueButton}>Continue</Button>
            </DrawerButtons>
        </div>
    );
};
