import { useEffect } from 'react';
import { IonText } from '@ionic/react';
import { Page } from '../components/page/Page';
import { TrackingService } from '../support/tracking/TrackingService';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { InsuranceState } from './InsuranceState';
import { useSubscription } from '@meraki-internal/state';
import { InsurancePolicyList } from './InsurancePolicyList';
import { NewPolicyButton } from './NewPolicyButton';
import { InsuranceErrorPage } from './InsuranceErrorPage';
import { Spinner } from '../components/spinner/Spinner';
import { Refresher } from '../components/refresher/Refresher';
import { useRefresher } from '../support/hooks/useRefresher';
import { RefreshTimer } from '../components/refresher/RefreshTimer';
import { Logger } from '../support/debug/Logger';
import { tryAndCatch } from '../support/helpers/tryAndCatch';
import { IInsurancePolicy } from './IInsurancePolicy';
import { getPendingPolicyRefreshIntervalMs } from './policyHelpers';
import { DoneIcon } from '../components/icons/Icons';

const Benefit: React.FC<{text: string}> = ({ text }) => (
    <div style={{marginTop: 10, marginBottom: 10, display: 'flex', alignItems: 'center', gap: 10, fontSize: 14}}>
        <DoneIcon variant="filled" size="medium" color="insurance" />
        <b>{text}</b>
    </div>
);

export const InsuranceLandingPage: React.FC = () => {
    const tracker = useInstance(TrackingService);
    const log = useInstance(Logger);

    const insuranceState = useInstance(InsuranceState);
    useSubscription(() => insuranceState);

    const { onRefresh, isRefreshing } = useRefresher(insuranceState.load);

    useEffect(() => {
        Promise.resolve().then(async () => {
            await tryAndCatch(insuranceState.load, log.error);
            if (insuranceState.state.loaded) {
                const pageName = (insuranceState.state.policies!.length ? 'Insurance Dashboard' : 'Insurance Marketing');
                tracker.startPageView(pageName);
            }
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!insuranceState.state.loaded && insuranceState.state.error) {
        return <InsuranceErrorPage />;
    }

    let policies: IInsurancePolicy[] = [];
    let pageTitle = 'Insurance';

    if (insuranceState.state.loaded) {
        policies = insuranceState.getPolicies();
        pageTitle = (policies.length ? 'Insurance Dashboard' : 'Get a Quote');
    }

    const getRefreshIntervalMs = () => {
        const intervals = policies.map(getPendingPolicyRefreshIntervalMs);
        return Math.min(...intervals);
    };

    return (
        <>
            {/* image needs to be outside page so it doesn't move when page is pulled down */}
            {insuranceState.state.loaded && policies.length === 0 &&
                <img style={{zIndex: 1, position: 'absolute', height: 120, top: 0, right: 0}} src="./assets/images/insurance/flying-drone.png" alt="Flying drone" />
            }

            <Page title={pageTitle}>

                <Refresher onRefresh={onRefresh} />

                {policies.some(p => p.pending) &&
                    <RefreshTimer getIntervalMs={getRefreshIntervalMs} handler={insuranceState.load} />
                }

                {insuranceState.state.loaded &&
                    <>
                        {policies.length === 0 &&
                            <div style={{margin: '90px 20px 0'}}>
                                <h2 style={{marginBottom: 0}}><b>Protect your passion</b></h2>
                                <h2 style={{marginTop: 0}}><b>Get <IonText color="primary">drone insurance.</IonText></b></h2>

                                <p style={{marginTop: 10}}>Get quick and affordable drone coverage in less than 7 minutes.</p>

                                <div style={{marginTop: 25, marginBottom: 50}}>
                                    <Benefit text="Get up to $10 Million in liability" />
                                    <Benefit text="Add hull coverage for drones and equipment" />
                                    <Benefit text="Easily access your policy and docs any time!" />
                                </div>

                                <div>
                                    {!insuranceState.hasPendingApplication() &&
                                        <div style={{fontSize: 16, textAlign: 'center'}}><b>Simply select your state and get a free quote</b></div>
                                    }

                                    <div style={{fontSize: 16, textAlign: 'center'}}><b>No commitment or credit card required!</b></div>
                                </div>
                            </div>
                        }

                        <NewPolicyButton />

                        <InsurancePolicyList policies={policies} />

                        <div style={{margin: '50px 20px', lineHeight: 0.75}}>
                            <small><i>
                                Insurance coverage is offered through Brokery, LLC ("Brokery"), a licensed insurance producer.
                                All insurance-related information is provided by Brokery and does not in any way alter or amend
                                the terms, conditions, or exclusions of any insurance policy. Insurance coverage is not available
                                in all jurisdictions or to all customers. The above does not in any way constitute an endorsement
                                or referral of Brokery's products or services.
                            </i></small>
                        </div>
                    </>
                }

                {insuranceState.state.loading && !isRefreshing &&
                    <Spinner name="crescent" centered />
                }

            </Page>
        </>
    );
};
