export type IBreakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

// Duplicated from ionic framework:
// https://github.com/ionic-team/ionic-framework/blob/main/core/src/utils/media.ts
const breakpoints: {[key in IBreakpoint]: number} = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200
};

export const mediaMinWidth: {[key in IBreakpoint]: string} = {
    xs: `@media (min-width: ${breakpoints.xs}px)`,
    sm: `@media (min-width: ${breakpoints.sm}px)`,
    md: `@media (min-width: ${breakpoints.md}px)`,
    lg: `@media (min-width: ${breakpoints.lg}px)`,
    xl: `@media (min-width: ${breakpoints.xl}px)`
};

export const mediaMaxWidth: {[key in IBreakpoint]: string} = {
    xs: `@media (max-width: ${breakpoints.xs-1}px)`,
    sm: `@media (max-width: ${breakpoints.sm-1}px)`,
    md: `@media (max-width: ${breakpoints.md-1}px)`,
    lg: `@media (max-width: ${breakpoints.lg-1}px)`,
    xl: `@media (max-width: ${breakpoints.xl-1}px)`
};

// true if screen width is equal to or greater than breakpoint
export const isAboveBreakpoint = (breakpoint: IBreakpoint) => {
    if ((window as any).matchMedia) {
        const mediaQuery = mediaMinWidth[breakpoint].substring(7);
        return window.matchMedia(mediaQuery).matches;
    }
    return false;
};

// true if screen width is less than breakpoint
export const isBelowBreakpoint = (breakpoint: IBreakpoint) => {
    if ((window as any).matchMedia) {
        const mediaQuery = mediaMaxWidth[breakpoint].substring(7);
        return window.matchMedia(mediaQuery).matches;
    }
    return false;
};

// breakpoint for drawer on left etc
const largerScreenBreakpoint = 'lg';

// media query satisfied if screen is big enough for drawer on left etc
export const mediaLargerScreen = mediaMinWidth[largerScreenBreakpoint];

// media query satisfied if screen is NOT big enough for drawer on left etc
export const mediaNotLargerScreen = mediaMaxWidth[largerScreenBreakpoint];

// true if screen is big enough for drawer on left etc
export const isLargerScreen = () => {
    return isAboveBreakpoint(largerScreenBreakpoint);
};

/**
 * This isn't using isAboveBreakpoint b/c that requires evaluating css for each size til it
 * finds the one that is satisfied which is unnecessarily expensive, especially when this
 * can run in a tight loop while a user is resizing their browser
 */
export const getBreakPoint = (): IBreakpoint => {
    let lastSatisfiedBreakpoint: IBreakpoint | undefined;

    const width = window.innerWidth;

    for(const breakpoint of Object.keys(breakpoints) as IBreakpoint[] ){
        const minWidth = breakpoints[breakpoint];
        if (width >= minWidth){
            lastSatisfiedBreakpoint = breakpoint;
        }
        else {
            return lastSatisfiedBreakpoint || 'xl';
        }
    }

    return 'xl';
};
