import { ICoordinate, ISO8601DateTimeString } from '@autopylot-internal/tiles-client';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useSubscription } from '@meraki-internal/state';
import { useEffect, useState } from 'react';
import { WeatherError } from '../components/WeatherError';
import { ShowWindyMap } from './ShowWindyMap';
import { WindyMapViewModel } from './WindyMapViewModel';

interface IWindyMissionParams{
    center: ICoordinate;
    zoom?: number;
    radiusMeters?: number;
    radiusColor?: string;
    radiusShape?: 'circle' | 'square';
    startTime: ISO8601DateTimeString;
}
export const WindyMissionMap: React.FC<IWindyMissionParams> = ({ center, zoom = 10, radiusMeters, radiusColor, radiusShape, startTime }) => {
    const mapViewModel = useInstance(WindyMapViewModel);
    useSubscription(() => mapViewModel);

    const [error, setError] = useState<any>();

    useEffect(() => {
        try {
            mapViewModel.reset({
                center,
                zoom,
                radiusMeters,
                radiusColor,
                radiusShape,
                startTime
            });
        } catch (e) {
            setError(e);
        }
    }, [center, zoom, radiusMeters, radiusColor, radiusShape, startTime, mapViewModel]);

    return (
        <div  id="windy-full" style={{position: 'relative', width: '100%', height: '100%'}}>
            {error &&
                <WeatherError error={error} />

            }
            {!error &&
                <ShowWindyMap />
            }
        </div>
    );
};
