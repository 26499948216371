import { State } from '@meraki-internal/state';

export const MAX_HEIGHT = 9999; // number larger than the tallest screen

const MIN_MIN_HEIGHT = 110;
const MAX_HEIGHT_PCT = 90;

export class DrawerState extends State<{ minHeight: number, height: number, delta: number, dragging: boolean }> {
    constructor() {
        super({ minHeight: MIN_MIN_HEIGHT, height: MIN_MIN_HEIGHT, delta: 0, dragging: false });
    }
    setFullHeight = () => {
        this.setHeight(MAX_HEIGHT);
    };
    setHeight = (height: number) => {
        // make sure drawer stays within its bounds
        const { minHeight } = this.state;
        const maxHeight = document.documentElement.clientHeight * MAX_HEIGHT_PCT / 100;
        this.setState({ delta: 0, dragging: false, height: Math.min(Math.max(height, minHeight), maxHeight) });
    };
    setMinHeight = (minHeight: number) => {
        // make sure drawer stays within its bounds
        const validMinHeight = Math.max(minHeight, MIN_MIN_HEIGHT);
        this.setState({ minHeight: validMinHeight });
        if (this.state.height < validMinHeight) {
            this.setHeight(validMinHeight);
        }
    };
    isMinimized = () => {
        return (this.state.height + this.state.delta <= MIN_MIN_HEIGHT);
    };
}
