import { IAirspaceBoundaryProperties, IAirspaceProperties, IMilitaryTrainingRouteProperties, INSUFRProperties, INationalParkProperties, IRecreationalFixedFlyerSiteProperties, ITFRProperties, IWildernessAreaProperties, IWildlifeRefugeProperties, MissionFeatureCollection, INearbyAdvisoryProperties, ISpecialUseAdvisoryProperties, IAirportProperties } from '@autopylot-internal/tiles-client';
import { SecondaryWarningAdvisory } from '../SecondaryAdvisory';
import mapboxgl from 'mapbox-gl';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { DevSettings } from '../../support/DevSettings';
import { DirectionIndicator } from '../../weather/components/DirectionIndicator';
import { Logger } from '../../support/debug/Logger';
import { MapViewModel } from '../../map/model/MapViewModel';
import { airspaceBoundaryNameOverride } from './AirspaceBoundaryAdvisory';
import { getName as getSpecialUseAirspaceName } from './SpecialUseAdvisory';
import { AdvisoryProvider } from '../AdvisoryProvider';
import { MilitaryTrainingRoutesAdvisory } from './MilitaryTrainingRoutesAdvisory';

const airportTypeCodeToLabel: { [key: string]: string} = {
    'HP': 'Heliport',
    'AD': 'Airport',
    'SP': 'Seaport',
    'UL': 'Ultra Light',
    'GL': 'Glider Port',
    'BP': 'Balloon Port'
};

// TODO: ideally, we'd get all these types from @autopylot-internal/tiles
const sourceToRenderer = {
    'military-training-routes': (a: IMilitaryTrainingRouteProperties) => <span>Military Training Route {a.IDENT}</span>,
    'nsufr': (a: INSUFRProperties) => <span>NSUFR {a.Base || a.Facility}</span>,
    'parttime-nsufr': (a: INSUFRProperties) => <span>NSUFR {a.Base || a.Facility}</span>,
    'temporary-flight-restrictions': (a: ITFRProperties) => <span>TFR {a.number}</span>,
    'airspace-boundary': (a: IAirspaceBoundaryProperties) => {
        const name = airspaceBoundaryNameOverride[a.GLOBAL_ID] || a.NAME;
        return (
            <span> {name}</span>
        );
    },
    'uasfm+authorizable': (uasfm: any) => <span>LAANC {uasfm.airspaceIdent} Class {uasfm.airspaceClasses.join(', ')}</span> ,
    'airspace': (a: IAirspaceProperties) => <span>{a.NAME}</span>,
    // 'airspace-info': (a: IAirspaceProperties) => <span>{a.NAME}</span>,
    'special-use-airspace': (a: ISpecialUseAdvisoryProperties) => <span>{getSpecialUseAirspaceName(a)}</span>,
    'national-parks': (a: INationalParkProperties) => <span>{a.UNIT_NAME}</span>,
    'wilderness-areas': (a: IWildernessAreaProperties) => <span>{a.NAME}</span>,
    'wildlife-refuges': (a: IWildlifeRefugeProperties) => <span>{a.ORGNAME}</span>,
    'rec-flyer-fixed-sites': (a: IRecreationalFixedFlyerSiteProperties) => <span>{a.SITE_NAME}</span>,
    'airports': (a: IAirportProperties) => {
        const airportType = airportTypeCodeToLabel[a.TYPE_CODE] || '';
        const showIdent = a.TYPE_CODE === 'AD' && Boolean(a.IDENT);
        return (
            <span>{airportType}: {a.NAME} {showIdent ? `(${a.IDENT})`: ''}</span>
        );
    },
};

const Miles: React.FC<{ meters: number; }> = ({ meters }) => {
    const miles = meters/1609;

    const formattedMiles = miles.toFixed(1);
    return (
        <span style={{ marginLeft: 10 }}>{formattedMiles}mi</span>
    );
};

const Direction: React.FC<{ direction: number; show?: boolean; }> = ({ direction, show }) => {
    return (
        <span style={{ visibility: show ? 'visible' : 'hidden', marginLeft: 10 }}>
            <DirectionIndicator direction={direction} />
        </span>
    );
};

let activeMarker: { marker: mapboxgl.Marker; advisoryGlobalId?: string; }  | undefined = undefined;

class AccidentalUnsupportedAdvisoryLogger {
    static inject = () => [Logger];
    constructor(private logger: Logger){}

    private alreadyLogged: string[] = [];
    log = (sources: string[]) => {
        for (const source of sources){
            if (!this.alreadyLogged.includes(source)){
                this.alreadyLogged.push(source);
                this.logger.error(`WARN: OperationalAwarenessAdvisory - has no renderer for source ${source}. Either add it, or add to a whitelist.`);
            }
        }
    };
}


export const OperationalAwarenessAdvisory = (props: INearbyAdvisoryProperties & { mission: MissionFeatureCollection } ) => {
    const accidentalUnsupportedAdvisoryLogger = useInstance(AccidentalUnsupportedAdvisoryLogger);
    const devSettings = useInstance(DevSettings);
    const mapViewModel = useInstance(MapViewModel);
    const advisoryProvider = useInstance(AdvisoryProvider);

    const advisories = (props.advisories)
        .filter(a =>  ![
            'scheduled-airspace', // we already tell them there is an airspace nearby, not currently worth telling them it might be scheduled
            'airspace-info', // we're rendering laanc and airspace instead
            'stadiums', // we render stadiums using a separate advisory, in part b/c that requirement is 3NM and this requirement is 2NM
        ].includes(a.properties.source))
        .sort((a, b) => a.properties.distanceMeters - b.properties.distanceMeters);

    const advisoriesWeCanRender = advisories.filter(a => (sourceToRenderer as any)[a.properties.source]);

    const advisoriesWeCannotRender = advisories.filter(a => !(sourceToRenderer as any)[a.properties.source]).map(a => a.properties.source);

    accidentalUnsupportedAdvisoryLogger.log(advisoriesWeCannotRender);

    const advisoriesToRender = advisoriesWeCanRender
        .sort((a, b) => a.properties.distanceMeters - b.properties.distanceMeters)
        .filter(a => {
            // show all the ones more 0m away, b/c it can't be redundant with the mission
            // (we normalize anything within the mission polygon to 0 distanceMeters)
            if (a.properties.distanceMeters > 0){
                return true;
            }

            // never show some at distance 0
            const advisoriesNeverShow = ['airspace-info'];
            if (advisoriesNeverShow.includes(a.properties.source)){
                return false;
            }

            const isMissionBlocked = props.mission.canFly() === 'cannot-fly';
            if (isMissionBlocked){
                // if blocked, then already shown
                let isSecondaryAdvisoryShown = a.properties.advisoryType === 'block';

                // if advise we show when blocked, then already shown
                isSecondaryAdvisoryShown = isSecondaryAdvisoryShown || advisoryProvider.getAdviseSourcesToShowWhenBlocked().includes(a.properties.source);

                // filter to only those that we aren't already showing, eg DC SFUR
                return !isSecondaryAdvisoryShown;
            }

            // then not blocked
            // most advisories are shown when not blocked, so filter to only those that are never shown as Secondary Advisory
            const advisoriesNeverSecondary = ['airports'];
            return advisoriesNeverSecondary.includes(a.properties.source);

        });
    if (advisoriesToRender.length === 0){
        return null;
    }

    const nearbyMTRs = advisoriesToRender.filter(a => a.properties.source === 'military-training-routes');

    const compositeMTRAdvisories = advisoryProvider.buildCompositeMTRAdvisoryArray(nearbyMTRs.map(a => a.properties));
    const s = advisoriesToRender.length === 1 ? '' : 's';
    const are = advisoriesToRender.length === 1 ? 'is' : 'are';
    const them = advisoriesToRender.length ===1 ? 'it' : 'them';
    return (
        <>
            {compositeMTRAdvisories.map((a, i) => <MilitaryTrainingRoutesAdvisory near key={i} {...a} />)}

            <SecondaryWarningAdvisory header="Operational Awareness" advisoryType={props.advisoryType}>
                <>
                    There {are} {advisoriesToRender.length} potential hazard{s} within about 2 miles of your flight plan. Please review {them} before operation.
                    {advisoriesToRender.map((a, i: number) => {
                        const renderer = (sourceToRenderer as any)[a.properties.source];


                        const addMarker = () => {
                            if (!devSettings.showNearestOperationalAwarenessPosition){
                                return;
                            }
                            const isMarkerAlreadyShowingAdvisoryById = activeMarker && activeMarker.advisoryGlobalId === a.properties.GLOBAL_ID;
                            const isMarkerAtAdvisoryPosition = activeMarker && activeMarker.marker.getLngLat().lng === a.properties.nearestPoint.geometry.coordinates[0] && activeMarker.marker.getLngLat().lat === a.properties.nearestPoint.geometry.coordinates[1];

                            // cannot guarantee all advisories have a GLOBAL_ID, so use position as a backup
                            // but don't only use position that way two advisories with the same position, don't act like they are the same advisory
                            const isAdvisoryAlreadyShowingPosition = activeMarker && activeMarker.advisoryGlobalId ? isMarkerAlreadyShowingAdvisoryById : isMarkerAtAdvisoryPosition;

                            // then just remove it (toggling it off)
                            if (activeMarker && isAdvisoryAlreadyShowingPosition){
                                activeMarker.marker.remove();
                                activeMarker = undefined;
                                return;
                            }

                            if (activeMarker){
                                activeMarker.marker.remove();
                                activeMarker = undefined;
                            }

                            const [lng, lat] = a.properties.nearestPoint.geometry.coordinates;

                            activeMarker = {
                                advisoryGlobalId: a.properties.GLOBAL_ID,
                                marker: new mapboxgl.Marker()
                                    .setLngLat({ lng, lat })
                                    // this is an internal tool, so not worth adding the complexity into MapViewModel
                                    .addTo((mapViewModel as any).map)
                            };
                        };

                        return (
                            <div key={i} onClick={addMarker} style={{
                                marginTop: 4,
                                display: 'flex',

                                // use the margin on the right that is usually for the secondary advisory icon
                                // this assumes there was enough content above, so we're below the icon vertically, not stacked under neath it
                                marginRight: -70
                            }}>
                                <div style={{
                                    flexGrow: 2,
                                }}>
                                    {renderer(a.properties)}
                                </div>
                                <div style={{ width: 80, flexShrink: 0 }}>
                                    <Miles meters={a.properties.distanceMeters} />
                                    <Direction direction={a.properties.direction} show={a.properties.distanceMeters > 0} />
                                </div>
                            </div>
                        );
                    })}
                </>
            </SecondaryWarningAdvisory>
        </>
    );
};
