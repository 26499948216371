import { APIClient } from '@autopylot-internal/autopylot-api-client';
import { Device } from '../Device';

export class FileUploadClient {
    static inject = () => [APIClient, Device];
    constructor(private apiClient: APIClient, private device: any) {}

    uploadLogFile = async ({ filename, logData }: { filename: string, logData: string }) => {
        const { uuid } = this.device;

        const { links } = await this.apiClient.entry();
        const { uploadUrl } = await this.apiClient.post(links.logUploadUrl, { filename, deviceId: uuid });

        await window.fetch(uploadUrl, {
            method: 'PUT',
            body: logData,
            headers: { 'content-type': 'text/plain' }
        });

        return { uploadUrl };
    };
}
