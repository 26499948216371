import { useInstance } from '@meraki-internal/react-dependency-injection';
import { DailyForecastCardV3 } from './DailyForecastCard';
import { SummaryForecastCardFromLink } from './SummaryForecastCard';
import { HistoryViewModel } from '../app/HistoryViewModel';
import { useEditableMissionState } from '../missions/model/useEditableMissionState';
import { HourlyForecastCardFromLink } from './HourlyForecastCard';
import { Page } from '../components/page/Page';
import { formatLocalDate, formatLocalTimeRange, getTimezone } from '../support/helpers/format';
import { PageHeading } from '../components/page/PageHeading';
import { WeatherAttribution } from './components/WeatherAttribution';
import { Refresher, RefresherEvent } from '../components/refresher/Refresher';
import { useState } from 'react';
import { WeatherMapPreviewCard } from './WeatherMapPreviewCard';
import { usePageView } from '../support/tracking/usePageView';
import moment from 'moment';

type ISO8601 = string;

export const MissionWeatherPage: React.FC<{missionId: string}> = ({ missionId }) => {
    usePageView('Mission Weather');

    const history = useInstance(HistoryViewModel);

    const mission = useEditableMissionState(missionId);

    const [refreshedAt, setRefreshedAt] = useState(Date.now());

    if (!mission) {
        return null;
    }

    const formattedMissionDate = formatLocalDate({ date: mission.getStartTime(), location: mission.getLocation() });
    const formattedMissionTimes = formatLocalTimeRange({ startTime: mission.getStartTime(), endTime: mission.getEndTime(), location: mission.getLocation() });

    const onRefresh = async (event: RefresherEvent) => {
        // trigger refresh of panels below
        setRefreshedAt(Date.now());

        // stop the spinner after 1s
        setTimeout(event.detail.complete, 1000);
    };

    const missionWeatherProps = {
        location: mission.getLocation(),
        startTime: mission.getStartTime(),
        endTime: mission.getEndTime()
    };

    const onClickDay = (day: ISO8601) => {
        mission.pages.hourlyWeather.go({
            startingAt: moment.tz(day, getTimezone(mission.getLocation())).hour(12).startOf('hour').toISOString(),
            from: 'DailyForecastCard'
        });
    };

    const onClickHour = (hour: ISO8601) => {
        mission.pages.hourlyWeather.go({ startingAt: hour, from: 'HourlyForecastCard' });
    };

    return (
        <Page title="Mission Weather" onBack={() => history.back(mission.pages.details.path())}>

            <Refresher onRefresh={onRefresh} />

            <PageHeading
                heading={`${mission.state.address.city}, ${mission.state.address.state}`}
                subheading={`${formattedMissionDate} | ${formattedMissionTimes}`}
            />

            <div key={refreshedAt}>
                <SummaryForecastCardFromLink link={mission.state.links.weatherHourly} {...missionWeatherProps} />
                <HourlyForecastCardFromLink link={mission.state.links.weatherHourly} {...missionWeatherProps} onHourClick={onClickHour} />
                <WeatherMapPreviewCard mission={mission} />
                <DailyForecastCardV3 link={mission.state.links.weatherDaily} {...missionWeatherProps} onForecastDayClick={onClickDay} />
            </div>

            <WeatherAttribution />

        </Page>
    );
};
