import moment from 'moment';
import { MissionsState } from '../missions/model/MissionsState';
import { IScreenshotablePage } from '../support/screenshots/IScreenshotablePage';
import { MissionFeatureCollectionFacadeAdapter } from '@autopylot-internal/tiles-client';
import { getWellKnownLocation } from '../support/screenshots/getWellKnownLocation';
import { WeatherMapPage } from './WeatherMapPage';
import { IMission } from '../mission/model/EditableMissionState';
import { IWindyLayer, IWindyWindAltitude, WindyMapViewModel, WindyMapViewModelConfig } from './windy/WindyMapViewModel';
import { BindWindyMapViewModel } from './windy/BindWindyMapViewModel';
import { useEffect, useState } from 'react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { IScreenshotHint } from '../support/screenshots/ScreensPage';
import { ScreenshotHelper } from '../support/screenshots/ScreenshotHelper';

const waitForAnimation = async () => {
    await new Promise(res => setTimeout(res, 500));
};

interface IMyPageProps {
    startTime?: string;
    durationHours?: number;
    layer?: IWindyLayer;
    windLevel?: IWindyWindAltitude;
    windAltitudeSelectorOpen?: boolean;
    windOrRainSelectorOpen?: boolean;
    hint?: IScreenshotHint;
    zoom?: number;
}

const EnableWindyMap: React.FC<{ }> = ({ children }) => {
    const [isReady, setIsReady] = useState(false);
    const config = useInstance(WindyMapViewModelConfig);

    useEffect(() => {
        config.DISABLE_MAP = false;
        setIsReady(true);
    }, [config, setIsReady]);

    return isReady ? (<>{children}</>) : null;
};

// not all WindyMapViewModel methods are guarded for isReady
// only those used by WeatherMapPage's page load
// so we need to wait til its ready before permutationToProps
const WaitTilWindyIsReady: React.FC = ({ children }) => {
    const windyMapViewModel = useInstance(WindyMapViewModel);

    const [ isReady, setIsReady ] = useState(false);

    useEffect(() => {
        windyMapViewModel.isReady.then(() => setIsReady(true));
    });

    return isReady ? <>{children}</> : null;
};

export const EnableAndBindWindyMapForScreenshots : React.FC = ({ children }) => (
    <EnableWindyMap>
        <BindWindyMapViewModel />
        <WaitTilWindyIsReady>
            {children}
        </WaitTilWindyIsReady>
    </EnableWindyMap>
);
export default class ScreenshotWeatherMapPage implements IScreenshotablePage<IMyPageProps> {

    static inject = () => [
        MissionsState,
        MissionFeatureCollectionFacadeAdapter,
        WindyMapViewModel,
        ScreenshotHelper
    ];
    constructor(
        private missions: MissionsState,
        private mfcAdapter: MissionFeatureCollectionFacadeAdapter,
        private mapViewModel: WindyMapViewModel,
        private helper: ScreenshotHelper
    ) { }

    screenId = 'weather-map';

    ComponentUnderTest = WeatherMapPage;

    WrapWith = EnableAndBindWindyMapForScreenshots;

    permutationToProps = ({ startTime, durationHours = 1, layer = 'wind', windLevel, zoom }: IMyPageProps) => {

        const missionId = String(Date.now());

        startTime = startTime || moment().startOf('h').add(1, 'h').toISOString();
        const endTime = moment(startTime).add(durationHours, 'h').toISOString();

        const location = getWellKnownLocation('uncontrolled');

        const address = location.address;
        const radiusPolygon = location.radiusPolygon;
        radiusPolygon.features[0].properties.startTime = startTime;
        radiusPolygon.features[0].properties.endTime = endTime;
        radiusPolygon.features[0].properties.isLicensed = true;

        const missionParams: IMission = {
            missionId,
            startTime,
            status: 'active',
            durationMinutes: durationHours * 60,
            radiusPolygon: this.mfcAdapter.adaptFromPersisted(radiusPolygon),
            address: address,
            maxAltitude: 400,
            links: {},
            version: 1
        };

        this.missions.addMissionToState(missionParams);

        // WeatherMapPage onMount will wait til windy is ready to reset
        // which we know it is ready b/c WrapWith = EnableAndBindWindyMapForScreenshots
        // so we need to wait a tick so it can reset before we set things
        const afterResetPromise = Promise.resolve().then(async () => {
            await new Promise(resolve => setTimeout(resolve));
        });

        if (layer ) {
            afterResetPromise.then(() => this.mapViewModel.setLayer(layer));
        }

        if (windLevel) {
            afterResetPromise.then(() => this.mapViewModel.setWindAltitude(windLevel));
        }

        if (zoom){
            afterResetPromise.then(() => this.mapViewModel.setZoom(zoom));
        }

        return { missionId };
    };

    blockScreenshotTil = async (params: IMyPageProps) => {
        if (params.windAltitudeSelectorOpen) {
            const el: any = await this.helper.waitForElement('[data-id=wind-altitude]');
            el.click();

            await waitForAnimation();
        }
        else if (params.windOrRainSelectorOpen) {
            const el: any = await this.helper.waitForElement('[data-id=wind-or-rain]');
            el.click();

            await waitForAnimation();
        }
    };

    permutations: { [key: string]: IMyPageProps } = {
        'today': {
            startTime: moment().startOf('hour').add(1, 'h').toISOString(),
        },
        '10-days-out': {
            startTime: moment().startOf('hour').add(9, 'd').toISOString(),
            hint: { text: 'should get a windy map' }
        },
        '11-days-out': {
            // should not be available
            startTime: moment().startOf('hour').add(10, 'd').toISOString(),
            hint: { text: 'too far out to get a windy map' }
        },

        'wind-level-100m': {
            windLevel: '100m',
            hint: { text: `wind altitude control should show 300ft and we should see wind`}
        },

        'wind-altitude-selector-open': {
            windAltitudeSelectorOpen: true
        },

        'wind-or-rain-selector-open': {
            windOrRainSelectorOpen: true
        },

        'precipitation': {
            layer: 'rain',
            zoom: 5,
            hint: { text: 'Rain should be active in the button. Wind Altitude control should not be visible. Footer should be in not mph. And map should be pockets or rain, not wind vectors.' }
        }
    };
}
