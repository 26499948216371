import { useEffect } from 'react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { AlertPresenter } from '../app/AlertBinder';
import { AuthErrorState, AuthService } from './AuthService';
import { OperatorState } from '../profile/OperatorState';

export const AuthErrorAlerter: React.FC = () => {

    const alert = useInstance(AlertPresenter);

    const authService = useInstance(AuthService);
    const errorState = useInstance(AuthErrorState);
    const operatorState = useInstance(OperatorState);

    useEffect(() => {
        if (errorState.hasError()) {
            errorState.clearError();
            const isGuest = operatorState.isGuest();
            alert.showAlert({
                header: `Sorry, that didn't work!`,
                message: !isGuest ? 'If you want to sign in as a different user, please sign out first.' : 'Please try signing in again.',
                buttons: !isGuest ? [{text: 'Sign Out', handler: authService.logout}, 'Ignore'] : ['OK']
            });
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return null;
};
