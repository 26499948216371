import { useInstance } from '@meraki-internal/react-dependency-injection';
import { IntercomService } from './IntercomService';
import { TrackingService } from '../../support/tracking/TrackingService';
import { useEffect, useState } from 'react';
import { StorageProvider } from '../../support/StorageProvider';

class OnboardingTourStorage {
    static inject = () => [StorageProvider];
    constructor(private storage: StorageProvider){}
    wasOnboardingShown = this.storage.getBooleanProvider('ap:onboarding-shown', { storageType: 'localStorage' });
}

export const useOnboardingTour = () => {

    const intercom = useInstance(IntercomService);
    const storageV2 = useInstance(OnboardingTourStorage);
    const tracker = useInstance(TrackingService);

    const [done, setDone] = useState(false);

    useEffect(() => {

        if (intercom.canShowProductTour() && !storageV2.wasOnboardingShown.exists()) {

            // intercom blocks react, so use a timeout to give underlying page a chance to render
            // (otherwise previous page may still be visible after tour closes)
            setTimeout(() => {
                intercom.showProductTour('ONBOARDING');
                tracker.track('Started Intercom Onboarding');
                storageV2.wasOnboardingShown.set(true);

                // need another timeout, because on android we get here before the tour is visible
                setTimeout(() => setDone(true), 1000);
            }, 50);

        } else {
            setDone(true);
        }

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return done;
};
