export const PageHeading: React.FC<{heading: string, subheading?: string}> = ({ heading, subheading }) => {
    return (
        <div style={{padding: '10px 20px 0', textAlign: 'center'}} data--type>
            <div style={{fontSize: 16, fontWeight: 'bold', margin: 0}} data-type="page-heading">
                {heading}
            </div>
            <small data-type="page-sub-heading">
                {subheading}
            </small>
        </div>
    );
};
