import { ICanFly } from '@autopylot-internal/tiles-client';
import { IonCard, IonCardContent, IonItem } from '@ionic/react';
import { ReminderBadge } from '../../components/badge/ReminderBadge';
import { CardHeader } from '../../components/card/CardHeader';
import { TimeIcon, AirspaceGoodIcon, AirspaceLAANCIcon, AirspaceNotLAANCIcon, NotificationIcon } from '../../components/icons/Icons';
import { EditableMissionState } from '../../mission/model/EditableMissionState';
import { Location } from '../../map/components/Location';
import { MissionDaySpan } from './MissionDaySpan';
import { MissionListItemFooter } from './MissionListItemFooter';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
    cardContent: {
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 0
    },

    cardBody: {
        paddingBottom: 18
    }
});

const AirspaceIcon = ({canFly, isCanceled}: {canFly: ICanFly, isCanceled: boolean }) => {
    if (canFly === 'can-fly'){
        const color = isCanceled ? 'medium' : 'success';
        return <AirspaceGoodIcon size="large" color={color} variant="outline" />;
    } else if (canFly === 'can-fly-with-laanc-authorization'){
        const color = isCanceled ? 'medium' : 'warning';
        return <AirspaceLAANCIcon size="large" color={color} variant="outline" />;
    } else if (canFly === 'cannot-fly') {
        return <AirspaceNotLAANCIcon size="large" color="danger" variant="outline" />;
    }
    throw new Error('not supported');
};

export const MissionListItem = ({ mission }: { mission: EditableMissionState }) => {
    const classes = useStyles();
    const canFly = mission.getCanFly();
    const isCanceled = mission.getMissionStatus() === 'user-canceled';
    const reminderCount = mission.getReminderCount();

    return (
        <IonCard data-id={mission.state.missionId} button onClick={() => mission.pages.details.go()}>
            <CardHeader>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                        <TimeIcon />
                        <span style={{ fontWeight: 'bold' }}>{mission.getFormattedTimes()}</span>
                    </span>
                    <MissionDaySpan mission={mission} />
                </div>
            </CardHeader>

            <IonCardContent className={classes.cardContent}>
                <IonItem lines="none" className={classes.cardBody}>
                    <div>
                        <h2 style={{ fontSize: 20, color: 'var(--ion-text-color)', fontWeight: 'bold' }}>{mission.getName()}</h2>
                        <div style={{ lineHeight: 1.25, marginTop: 10, fontSize: 14, overflow: 'hidden' }}>
                            <Location mission={mission} />
                        </div>
                    </div>
                    <div slot="end" style={{position: 'relative'}}>
                        {reminderCount > 0 &&
                            <>
                                <NotificationIcon color="medium" size="large" />
                                <div style={{position: 'absolute', top: -4, right: 0}}>
                                    <ReminderBadge size="small" count={reminderCount} />
                                </div>
                            </>
                        }
                        {!reminderCount &&
                            <AirspaceIcon canFly={canFly} isCanceled={isCanceled} />
                        }
                    </div>
                </IonItem>

                <MissionListItemFooter mission={mission} />

            </IonCardContent>
        </IonCard>
    );
};
