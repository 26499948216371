export const weatherConditions = [
    'Blizzard',
    'BlowingDust',
    'BlowingSnow',
    'Breezy',
    'Clear',
    'Cloudy',
    'Drizzle',
    'Flurries',
    'Foggy',
    'FreezingDrizzle',
    'FreezingRain',
    'Frigid',
    'Hail',
    'Haze',
    'HeavyRain',
    'HeavySnow',
    'Hot',
    'Hurricane',
    'IsolatedThunderstorms',
    'IsolatedThunderstorm', // deprecated by apple
    'MostlyClear',
    'MostlyCloudy',
    'PartlyClear', // deprecated by apple
    'PartlyCloudy',
    'Rain',
    'ScatteredThunderstorms',
    'Sleet',
    'Smoky',
    'Snow',
    'StrongStorms',
    'SunFlurries',
    'SunShowers',
    'Thunderstorms',
    'TropicalStorm',
    'Windy',
    'WintryMix'
] as const;

export type IWeatherCondition = typeof weatherConditions[number];
