import { IonText } from '@ionic/react';
import { Button } from '../components/button/Button';
import { IDialogBaseProps } from '../components/dialog/DialogPresenter';
import { FloatingBackButton } from '../components/button/FloatingBackButton';
import { FloatingCloseButton } from '../components/button/FloatingCloseButton';
import { ContentWithFooter } from '../components/page/ContentWithFooter';
import { AuthService } from './AuthService';
import { EmailIcon, FacebookIcon, GoogleIcon, AppleIcon } from '../components/icons/Icons';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { DevSettings } from '../support/DevSettings';
import { TrackingService } from '../support/tracking/TrackingService';
import { Device } from '../support/Device';
import { AutoPylotPrincipal } from './AutoPylotPrincipal';
import { useBreakpoints } from '../support/hooks/useBreakpoints';

export type LoginAction = { key: string, Icon: any, text: string,  handler: () => Promise<boolean> };

export interface ILoginDialogPresenter {
    showLoginDialog: (params: { message?: string, returnTo?: string }) => Promise<void>
}

export const LoginDialog: React.FC<IDialogBaseProps & { message?: string, returnTo?: string }> = ({ onDismiss, message = 'Create Account or Sign In', returnTo }) => {
    const { isLargerScreen } = useBreakpoints();

    const authService = useInstance(AuthService);
    const deviceInfo = useInstance(Device);
    const devSettings = useInstance(DevSettings);
    const tracker = useInstance(TrackingService);
    const principal = useInstance(AutoPylotPrincipal);

    if (principal.email){
        // prevent the dialog from showing, to prevent downstream issue when trying to exchange their "guest" access token for a email access token
        throw new Error(`Attempting to show the login dialog for a user that is not a guest. ${JSON.stringify({ userId: principal.userId, email: principal.email })}.`);
    }

    const actions: LoginAction[] = [
        {
            key: 'google',
            text: 'Continue with Google',
            Icon: GoogleIcon,
            handler: async () => {
                return await authService.login({ connection: 'google-oauth2', returnTo });
            }
        },
        {
            key: 'email',
            text: 'Continue with Email',
            Icon: EmailIcon,
            handler: async () => {
                return await authService.login({ connection: 'email', returnTo });
            }
        }
    ];

    if (deviceInfo.platform !== 'android') {
        actions.unshift({
            key: 'apple',
            text: 'Continue with Apple',
            Icon: AppleIcon,
            handler: async () => {
                return await authService.login({ connection: 'apple', returnTo });
            }
        });
    }

    if (devSettings.enableFacebookLogin) {
        actions.unshift(
            {
                key: 'facebook',
                text: 'Continue with Facebook',
                Icon: FacebookIcon,
                handler: async () => {
                    return await authService.login({ connection: 'facebook', returnTo });
                }
            }
        );
    }
    const onAction = async ({ key, handler }: { key: string, handler: () => Promise<boolean> }) => {
        tracker.track('Auth Options Continued', () => ({ with: key }));

        // theoretically, the handler resolves with a boolean indicating whether the login was successful, but
        // currently all handlers reload the app after redirecting, resolving only if the user aborted the flow
        // (by failing to consent or clicking cancel), so simply awaiting here is all we need to do
        await handler();
    };

    return (
        <>
            {!isLargerScreen &&
                <FloatingBackButton onClick={onDismiss} />
            }
            {isLargerScreen &&
                <FloatingCloseButton onClick={onDismiss} />
            }

            <ContentWithFooter data-id="login-dialog">

                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    <img src="./assets/images/logo-name-vert.png" alt="AutoPylot logo" height="130px" />
                </div>

                <div style={{display: 'flex', flexDirection: 'column', gap: 15, textAlign: 'center'}}>

                    <IonText color="dark"><b>{message}</b></IonText>

                    {actions.map(({ key, Icon, text, handler }) => (
                        <Button dataType={`continue-${key}`} key={text} fill="outline" expand="block" onClick={() => onAction({ key, handler })}>
                            <span style={{position: 'absolute', left: 15}}><Icon color="dark" size="medium" /></span>
                            <b>{text}</b>
                        </Button>
                    ))}
                </div>

            </ContentWithFooter>
        </>
    );
};
