import { IonButton } from '@ionic/react';
import { MouseEventHandler, useEffect, useState } from 'react';
import { Color, Size, Fill } from '../common';
import { ButtonSpinner } from '../spinner/ButtonSpinner';
import { useBreakpoints } from '../../support/hooks/useBreakpoints';

export interface ButtonProps {
    id?: string;
    color?: Color;
    size?: Size;
    fill?: Fill;
    minWidth?: number;
    expand?: 'block';
    disabled?: boolean;
    href?: string;
    type?: 'button' | 'reset' | 'submit';
    dataType?: string;
    dataSet?: {[k: string]: string};
    onClick?: MouseEventHandler;
}

export const Button: React.FC<ButtonProps> = ({ id, color = 'primary', size = 'medium', minWidth, fill, disabled = false, expand, onClick, href, type = 'button', dataType, dataSet = {}, children }) => {
    const { isLargerScreen } = useBreakpoints();

    // limit full-width buttons on wider screens so they don't look ridiculous
    let textAlign: 'center' | undefined;
    if (expand && minWidth === undefined && isLargerScreen) {
        expand = undefined;
        minWidth = 300;
        textAlign = 'center';
    }

    // buttons smaller than this are not pleasing to the eye
    if (minWidth === undefined) {
        minWidth = 120;
    }

    const [mounted, setMounted] = useState(false);
    const [working, setWorking] = useState(false);

    // support dataType for backward compatibility
    if (dataType) {
        dataSet.type = dataType;
    }

    // build data attributes, e.g. dataSet={id:'test'} -> data-id="test"
    const dataAttributes = Object.keys(dataSet).reduce((map, k) => ({...map, [`data-${k}`]: dataSet[k]}), {});

    const ionSize = (size === 'small' ? 'small' : size === 'large' ? 'large' : 'default');

    // reduce opacity from ionic default when disabled to make it more obvious
    const opacity = (disabled || working ? 0.3 : 'inherit');

    // spinner needs to be a contrasting color to the button
    const spinnerColor: Color = (color === 'medium' || color === 'light' || fill === 'outline' || fill === 'clear' ? 'primary' : 'light');

    // note: for internal routes, don't set href on IonButton, it causes very weird drawer behavior where sometimes it
    // acts like it's open, but renders like it's not, and becomes generally unresponsive
    let routerLink;
    if (href && !href.startsWith('http')) {
        routerLink = href;
        href = undefined;
        if (routerLink && routerLink.startsWith('#')) {
            routerLink = routerLink.substr(1);
        }
    }

    useEffect(() => {
        setMounted(true);
        return () => setMounted(false);
    }, []);

    const handleClick = async (event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => {
        if (onClick && !working) {
            setWorking(true);

            await onClick(event);

            // the onClick handler might have unmounted the page, so check we're still mounted
            // before updating state to avoid a react warning in the console
            if (mounted) {
                setWorking(false);
            }
        }
    };

    return (
        <div style={{position: 'relative', borderRadius: 10, border: 0, padding: 0, margin: 0, textAlign}}>
            {working &&
                <ButtonSpinner color={spinnerColor} />
            }
            <IonButton id={id} expand={expand} style={{minWidth, opacity: opacity}} onClick={handleClick} color={color} size={ionSize} fill={fill} data-href={href} disabled={disabled || working} routerLink={routerLink} routerDirection="none" href={href} type={type} {...dataAttributes}>
                {children}
            </IonButton>
        </div>
    );
};
