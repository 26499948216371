import { State, useSubscription } from '@meraki-internal/state';
import { StandalonePage } from './StandalonePage';
import { Spinner } from '../components/spinner/Spinner';
import { HistoryStorage } from './HistoryViewModel';
import { StorageProvider } from '../support/StorageProvider';

const storage = new HistoryStorage(new StorageProvider());

export const LoadingPage: React.FC<{ canDisableMetrics?: boolean, logs?: State<{msgs: string[], show: boolean}> }> = ({ canDisableMetrics = false, logs }) => {
    useSubscription(() => logs || new State({}));

    // if there is an app start path in local storage, this means the app is
    // reloading (presumably after installing an update), in which case
    // we just want a spinner only (so the pause feels less invasive)
    const reloading = storage.appStartPath.exists();

    return (
        <StandalonePage>

            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 20, justifyContent: 'center', position: 'relative', height: '85%'}}>

                {!reloading &&
                    <img src="./assets/images/logo.png" alt="AutoPylot logo" height="60px" />
                }

                <Spinner size="small" name="dots" />

            </div>

            {logs?.state.show &&
                <pre style={{position: 'fixed', zIndex: -100, top: 0, bottom: 0}}>
                    {logs.state.msgs.join('\n')}
                </pre>
            }

        </StandalonePage>
    );
};
