import { IonCard } from '@ionic/react';

export interface ICardProps {
    padded?: boolean;
}

export const Card: React.FC<ICardProps> = ({ padded, children }) => {
    // By default, text inside a <Card> is lighter gray, unsetting this reverts it to default text color
    const style = { '--color': 'unset'};

    return (
        <IonCard style={style} className={padded ? 'ion-padding': undefined}>
            {children}
        </IonCard>
    );
};
