import { Size } from '../common';

export const ReminderBadge: React.FC<{count?: number, size?: Size, slot?: string}> = ({ count, size = 'medium', slot }) => {

    const sizeIndex = ['small', 'medium', 'large'].indexOf(size);

    const pixels = [15, 20, 24][sizeIndex];
    const fontSize = [12, 14, 16][sizeIndex];
    const lineHeight = [1.3, 1.5, 1.6][sizeIndex];
    const padding = [3, 5, 6][sizeIndex];

    return (
        <div slot={slot} style={{
            backgroundColor: 'var(--ion-color-danger-shade)',
            color: 'white',
            textAlign: 'center',
            borderRadius: pixels / 2,
            fontSize,
            minWidth: pixels,
            height: pixels,
            lineHeight,
            paddingLeft: padding,
            paddingRight: padding,
        }}>{count || ''}</div>
    );
};
