import { LayersIcon } from '../../components/icons/Icons';
import { ExpandableSegmentedButtons } from '../../components/segmented-buttons/ExpandableSegmentedButtons';
import { IWindyLayer } from './WindyMapViewModel';

export const LayerSelector: React.FC<{layer: IWindyLayer, setLayer: (layer: IWindyLayer) => void, segmentStyle?: any}> = ({ layer, setLayer}) => {
    return (
        <ExpandableSegmentedButtons
            options={[{ value: 'wind', label: 'Wind'}, { value: 'rain', label: 'Rain' }]}
            activeValue={layer}
            setActiveValue={value => setLayer(value as IWindyLayer)}
            data-id="wind-or-rain"
            icon={
                <>
                    <LayersIcon size="medium" color="primary" />
                    <span style={{ fontSize: 10, display: 'block', color: 'var(--ion-color-primary)', fontWeight: 'bold' }}>
                        {layer === 'wind' ? 'Wind' : 'Rain'}
                    </span>
                </>
            }
        />
    );
};
