import React from 'react';
import { IonButton } from '@ionic/react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { CloudsIcon, HumidityIcon, RainAmountIcon, RaindropIcon, SnowAmountIcon, SnowflakeIcon, TemperatureIcon, VisibilityIcon, WindDirectionIcon, WindGustsIcon } from '../../components/icons/Icons';
import { WindLabel } from '../components/WindLabel';
import { IHourlyForecast } from '../model/IHourlyForecast';
import { HourlyWeatherChartViewModel, IMetricCode } from './HourlyWeatherChartViewModel';

const ChartMetric: React.FC<{metricCode: IMetricCode, icon: any, iconRotation?: number, value: any, units?: string, label: string, activeColor: string, active?: boolean}> = ({ metricCode, icon: Icon, iconRotation = 0, label, value, units, activeColor, active }) => {
    const fill = active ? undefined : 'outline';
    const bgColor = active ? activeColor : 'light';
    const textColor = active ? 'light' : 'medium';
    const borderColor = 'var(--ion-color-step-100)';
    const iconVariant = active ? 'inverse' : 'filled';

    const vm = useInstance(HourlyWeatherChartViewModel);

    return (
        <div style={{padding: 5, width: '50%'}}>
            <IonButton size="large" expand="block" fill={fill} style={{'--background': bgColor, '--background-activated': bgColor, '--border-color': borderColor}} onClick={() => vm.changeMetric(metricCode)}>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginLeft: -20, gap: 10}}>
                    <div>
                        <Icon variant={iconVariant} size="large" rotationDegrees={iconRotation} />
                    </div>
                    <div style={{fontSize: '85%', color: `var(--ion-color-${textColor})`, display: 'flex', flexDirection: 'column', alignItems: 'start', flexGrow: 1}}>
                        <span>
                            <b>{value}</b>
                            {units ? <span style={{fontWeight: 'bold', fontSize: 'smaller'}}>{units}</span> : ''}
                        </span>
                        <small>{label}</small>
                    </div>
                </div>
            </IonButton>
        </div>
    );
};

export const ChartMetrics: React.FC<{metricCode: IMetricCode, forecast: IHourlyForecast}> = ({ metricCode,forecast }) => {
    const windLabel = <WindLabel direction={forecast.windDirection} speedMph={forecast.windSpeedMph} showHeading />;
    return (
        <div style={{display: 'flex', flexWrap: 'wrap'}}>
            <ChartMetric metricCode="temperature" label="temperature" activeColor="#5768D1" value={<>{forecast.temperatureF}&deg;</>} icon={TemperatureIcon} active={metricCode === 'temperature'} />
            <ChartMetric metricCode="humidity" label="humidity" activeColor="#5768D1" value={<>{forecast.humidityPct}</>} units="%" icon={HumidityIcon} active={metricCode === 'humidity'} />
            <ChartMetric metricCode="precipChance" label="chance precip" activeColor="#50A1F5" value={`${forecast.precipitationChancePct}`} units='%' icon={forecast.precipitationType === 'snow' ? SnowflakeIcon : RaindropIcon} active={metricCode === 'precipChance'} />
            <ChartMetric metricCode="precipAmount" label="precip amount" activeColor="#50A1F5" value={`${forecast.precipitationAmountInches}`} units=' in' icon={forecast.precipitationType === 'snow' ? SnowAmountIcon : RainAmountIcon} active={metricCode === 'precipAmount'} />
            <ChartMetric metricCode="windSpeed" label="wind" activeColor="#9971EA" value={windLabel} units="" icon={WindDirectionIcon} iconRotation={forecast.windDirection} active={metricCode === 'windSpeed'} />
            <ChartMetric metricCode="windGusts" label="gusts" activeColor="#9971EA" value={`${forecast.windGustMph}`} units=' mph' icon={WindGustsIcon} active={metricCode === 'windGusts'} />
            <ChartMetric metricCode="cloudCover" label="clouds" activeColor="#50A1F5" value={`${forecast.cloudCoverPct}`} units='%' icon={CloudsIcon} active={metricCode === 'cloudCover'} />
            <ChartMetric metricCode="visibility" label="visibility" activeColor="#5768D1" value={`${forecast.visibilityMiles}`} units=' mi' icon={VisibilityIcon} active={metricCode === 'visibility'} />
        </div>
    );
};
