import { App, AppState } from '@capacitor/app';
import { BufferedEventBus } from './BufferedEventBus';

export class AppEventEmitter {

    static inject = () => [BufferedEventBus];
    constructor(private events: BufferedEventBus) {}

    start = () => {

        this.events.emit('AppStarted');

        App.addListener('appStateChange', ({ isActive }: AppState) => {
            if (isActive) {
                this.events.emit('AppForegrounded');
            }
        });
    };
}
