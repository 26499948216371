import { ICanFly, ISO8601DateTimeString, MissionFeatureCollection } from '@autopylot-internal/tiles-client';
import { State } from '@meraki-internal/state';
import moment from 'moment';
import { AlertPresenter } from '../../app/AlertBinder';
import { FAAVersionAlertManager } from '../../app/FAAVersionAlertManager';
import { HistoryViewModel } from '../../app/HistoryViewModel';
import { IPath, PagePathsBuilder } from '../../app/PagePathsBuilder';
import { MapViewModel } from '../../map/model/MapViewModel';
import { OperatorState } from '../../profile/OperatorState';
import { formatLocalDate, formatLocalTime, formatLocalDay, formatLocalTimeRange, formatCoordinates } from '../../support/helpers/format';
import { IAddress } from '../../support/model/IAddress';
import { UserFacingError } from '../../support/UserFacingError';
import { TrackingService } from '../../support/tracking/TrackingService';
import { IMissionChecklist } from '../checklist/IMissionChecklist';
import { IChecklistItem } from '../../checklists/model';
import { MissionPinAndRadiusOnMapViewModel } from '../../map/mission-pin-and-radius/MissionPinAndRadiusOnMapViewModel';

export type IMissionStatus = 'active' | 'user-canceled' | 'completed';

/**
 * This contains the temporal draft of a creating missions. This is what we must provide
 * to MissionsState when creating a new mission.
 */
export interface INewMission {
    name?: string;
    status: IMissionStatus;
    address: IAddress;
    radiusPolygon: MissionFeatureCollection;
    startTime: string;
    durationMinutes: number;
    maxAltitude: number;
    notesHtml?: string;
}

/**
 * This interface is what we get back from the API after creating a new mission
 * or when requesting the list of all the user's missions.
 */
export interface IMission extends INewMission {
    missionId: string;
    version: number;
    links: { [key: string]: { href: string } };
    isReal?: boolean;
    checklist?: IMissionChecklist;
}

// TODO: not getting value from this
export interface IEditableMissionState {
    isEditingName: boolean;

    // TODO: can refactor all this to an enum
    isShowingTimeActions: boolean;
    isShowingOtherActions: boolean;

    // updated when user pulls down to refresh page,
    // or page refreshes itself on an interval
    // used to trigger refresh of weather
    refreshedAt: number;
}

export interface IMissionCallbacks {
    onNameChanged: (mission: IMission) => Promise<void>;
    onStatusChanged: (mission: IMission) => Promise<void>;
    onNotesHtmlChanged: (mission: IMission) => Promise<void>;
    onDeleted: (mission: IMission) => Promise<void>;
    onRefresh: (mission: IMission) => Promise<void>;
    getConcurrentLAANCMissions: (mission: Partial<IMission>) => IMission[];
}

const buildState = (mission: IMission) => ({
    ...mission,
    name: mission.name || '',
    status: mission.status || 'active',
    isEditingName: false,
    isShowingTimeActions: false,
    isShowingOtherActions: false,
    durationMinutes: mission.durationMinutes || 60,
    startTime: mission.startTime || moment().add(1, 'hour').startOf('hour').toISOString(),
    refreshedAt: Date.now()
});

export class EditableMissionState extends State<IMission & IEditableMissionState> {
    // property injection by MissionsState
    mapViewModel!: MapViewModel;
    history!: HistoryViewModel;
    _paths!: PagePathsBuilder;
    alertPresenter!: AlertPresenter;
    operator!: OperatorState;
    versionAlertManager!: FAAVersionAlertManager;
    tracker!: TrackingService;
    pinAndRadius!: MissionPinAndRadiusOnMapViewModel;

    constructor(mission: IMission, private callbacks: IMissionCallbacks) {
        super(buildState(mission));
    }

    private getTrackingMeta = () => {
        return {
            missionId: this.state.missionId,
            address: this.state.address,
            startTime: this.state.startTime,
            durationMinutes: this.state.durationMinutes,
            maxAltitude: this.state.maxAltitude
        };
    };

    isNew = () => false;

    refreshState = (mission: IMission) => {
        this.setState(buildState(mission));
    };

    getMissionRadius = (): MissionFeatureCollection => {
        return this.state.radiusPolygon;
    };

    getRelevantAuthorizations = () => {
        // since this is only shown after a mission is created, at which point authorizations should at least be "authorizing"
        // we aren't expecting any authorizations to be "intitialized". If they are there, it is because of a GIS change
        // which currently requires the mission to be cancelled (and therefore aren't relevant authorizations to the user).
        const endTime = this.getEndTime();
        return this.getMissionRadius().getLAANCAuthorizeableAdvisoryFeatures().map(feature => feature.properties)
            .filter(a => a.getAPLAANCStatus({ endTime }) !== 'initialized');
    };

    getCanFly = (): ICanFly => {
        return this.getMissionRadius().canFly();
    };

    // returns undefined if not LAANC, otherwise returns apLAANCStatus
    getAPLAANCStatus = () => {
        return this.getMissionRadius().getLAANCCompositeAdvisory()?.apLAANCStatus;
    };

    hasLAANC = () => {
        return Boolean(this.getMissionRadius().getLAANCCompositeAdvisory());
    };

    isFullyAuthorized = () => {
        return this.getAPLAANCStatus() === 'authorized';
    };

    isRescinded = () => {
        return this.getAPLAANCStatus() === 'rescinded' && this.getMissionStatus() !== 'user-canceled';
    };

    // return true if mission was previously uncontrolled, but has been invalidated
    wasUncontrolled = () => {
        const composite = this.getMissionRadius()?.getLAANCCompositeAdvisory();
        return (this.getCanFly() === 'cannot-fly' && (!composite || composite.apLAANCStatus === 'initialized'));
    };

    // return true if mission should be considered "not valid" in mission list
    isNotValid = () => {
        const status = this.getAPLAANCStatus() || '';
        return (['authorizing-failed', 'rescinded', 'rescind-acked', 'invalidated', 'invalidated-acked'].includes(status) || this.wasUncontrolled());
    };

    // return true if mission is in the future, not canceled, and you cannot fly it
    shouldCancel = () => {
        const missionState = this.getMissionStatus();
        return missionState === 'active' && this.getMissionRadius().canFly() === 'cannot-fly';
    };

    // return true if mission is in the future, not canceled, was authorized, but now you cannot fly it
    mustCancel = () => {
        return this.shouldCancel() && this.getAPLAANCStatus() !== 'authorizing-failed';
    };

    getAddress = () => {
        return this.state.address?.city ? this.state.address : undefined;
    };

    getLocation = () => {
        return this.getMissionRadius().getMissionCenter();
    };

    getStartTime = () => {
        return this.state.startTime!;
    };

    setStartTime = async (startTime: string) => {
        throw new Error('not supported');
        // const oldStartTime = this.state.startTime;
        // this.setState({ startTime });

        // try {
        //     await this.events.onChanged(this.asSerializable());
        // } catch (e) {
        //     // reset state before throwing
        //     this.setState({ startTime: oldStartTime })
        //     throw e;;
        // }
    };

    hasEnded = () => {
        return moment().isAfter(moment(this.getEndTime()));
    };

    getEndTime = () => {
        return moment(this.getStartTime()).add(this.state.durationMinutes, 'minutes').toISOString();
    };

    getFormattedDay = () => formatLocalDay({
        date: this.getStartTime(),
        location: this.getLocation()
    });

    getFormattedDate = () => formatLocalDate({
        date: this.getStartTime(),
        location: this.getLocation(),
        includeYear: true
    });

    getFormattedStartTime = () => formatLocalTime({
        time: this.getStartTime(),
        location: this.getLocation()
    });

    getFormattedTimes = () => formatLocalTimeRange({
        startTime: this.getStartTime(),
        endTime: this.getEndTime(),
        location: this.getLocation(),
        withTimeZone: true
    });

    getDurationMinutes = () => {
        return this.state.durationMinutes;
    };

    setDurationMinutes = async (durationMinutes: number) => {
        throw new Error('not supported');
        // const oldDuration = this.state.durationMinutes;
        // this.setState({ durationMinutes });

        // try {
        //     await this.callbacks.onChanged(this.asSerializable());
        // } catch (e) {
        //     // reset state before throwing
        //     this.setState({ durationMinutes: oldDuration });
        //     throw e;
        // }
    };

    startEditingName = () => {
        this.setState({ isEditingName: true });
    };

    setName = async (name: string) => {
        if (name !== this.state.name) {
            try {
                const oldName = this.getName();
                await this.callbacks.onNameChanged({ ...this.state, name });
                this.tracker.track('Mission Renamed', () => ({ oldName, newName: name }));
            } finally {
                // reset state before throwing
                this.setState({ isEditingName: false });
            }
        }

        this.setState({ isEditingName: false });
    };

    saveNotesHtml = async (notesHtml: string) => {
        if (notesHtml !== this.state.notesHtml){
            await this.callbacks.onNotesHtmlChanged({ ...this.state, notesHtml });
        }
    };

    getName = () => {
        return this.state.name || this.getDefaultName();
    };

    private getDefaultName = () => {
        const {name, street, city, state } = this.state.address;
        if (name){
            return [name, street].filter(p => Boolean(p)).join(', ');
        }
        if (street) {
            return street;
        }
        if (city) {
            return [city, state].join(', ');
        }
        return formatCoordinates(this.getLocation());
    };

    getMaxAltitude = () => {
        return this.state.maxAltitude;
    };

    hideTimeActions = () => {
        this.setState({ isShowingTimeActions: false });
    };

    showTimeActions = () => {
        throw new Error('not supported');
        // we will eventually support editing your start time and duration
        // but not supporting it now
        // so we don't have to deal with editing could have put your mission into a part time NSUFR
        // or editing could have changed your LAANC authorizations
        // this.setState({ isShowingTimeActions: true });
    };

    showMissionLocationOnMap = () => {
        this.pinAndRadius.showMissionPinAndRadius(this.state.radiusPolygon);
    };

    saveNewMissionLocation = async () => {
        throw new Error('not supported');
        // const radiusPolygon = this.mapViewModel.stopMovingMissionPin();

        // const oldPolygon = this.state.radiusPolygon;

        // this.setState({
        //     radiusPolygon,
        //     isEditingLocationOnMap: false
        // });

        // this.pages.viewMap.go();

        // try {
        //     await this.events.onChanged(this.asSerializable());
        // } catch (e) {
        //     // revert to old state before throwing
        //     this.setState({ radiusPolygon: oldPolygon });
        //     this.cancelEditingMissionLocation();
        //     throw e;
        // }
    };

    showOtherActions = () => {
        this.setState({
            isShowingOtherActions: true
        });
    };

    hideOtherActions = () => {
        this.setState({
            isShowingOtherActions: false
        });
    };

    cancelMission = async () => {
        if (this.hasLAANC() && this.versionAlertManager.isFAADown()){
            throw new UserFacingError({
                displayMessage: 'LAANC System Outage / System unavailable, please try again later',
                status: 409 // so not a 500, and not go to sentry
            });
        }

        if (this.hasLAANC() && this.hasEnded()) {
            // we actually need to let you cancel, b/c of invalidated / rescinded
            // but are putting it off til we can talk to FAA about the inconsistent rules
            this.alertPresenter.showAlert({
                header: 'Cannot Cancel Mission',
                message: (this.wasUncontrolled() ? 'Sorry, this mission cannot be canceled.' : 'Sorry, since this mission required LAANC authorization and its start time has already passed, it cannot be canceled.'),
                buttons: ['OK']
            });
            return;
        }

        if (this.hasLAANC() && !this.shouldCancel()) {
            const result = await this.alertPresenter.confirmCancelAction({
                message: 'LAANC Authorization(s) for the planned flight area will be canceled, and you will be required to resubmit for LAANC if you wish to fly here in the future.',
                header: 'Cancel Mission?',
                cancelNoun: 'Mission'
            });
            if (result === 'cancel'){
                return;
            }
        } else if (!this.shouldCancel()) {
            const result = await this.alertPresenter.confirmCancelAction({
                message: 'This mission will be canceled. You can reactivate it before the start time of the mission.',
                header: 'Cancel Mission?',
                cancelNoun: 'Mission'
            });
            if (result === 'cancel'){
                return;
            }
        } else {
            // the only remaining case is shouldCancel and they have already been alerted, so show nothing here
        }

        // for tracking, get laanc status just before canceling
        const laancStatus = this.getAPLAANCStatus();

        await this.callbacks.onStatusChanged({ ...this.state, status: 'user-canceled' });

        this.tracker.track('Mission Canceled', () => ({ laancStatus, ...this.getTrackingMeta() }));
    };

    reactivateMission = async () => {
        if (this.hasLAANC()) {
            this.alertPresenter.showAlert({
                header: 'Cannot Reactivate Mission',
                message: (this.wasUncontrolled() ? 'Sorry, this mission cannot be reactivated.' : 'Sorry, since this mission required LAANC authorization, it cannot be reactivated.'),
                buttons: [  'OK' ]
            });
            return;
        }

        await this.callbacks.onStatusChanged({ ...this.state, status: 'active' });
    };

    deleteMission = async () => {
        if (this.hasLAANC()) {
            this.alertPresenter.showAlert({
                header: 'Cannot Delete Mission',
                message: (this.wasUncontrolled() ? 'Sorry, this mission cannot be deleted.' : 'Sorry, since this mission required LAANC authorization, it cannot be deleted.'),
                buttons: [ 'OK' ]
            });
            return;
        } else {
            const destroyOrCancel = await this.alertPresenter.confirmDestructiveAction({
                header: 'Delete Mission?',
                message: 'This mission will be permanently deleted.'
            });

            if (destroyOrCancel !== 'destroy'){
                return;
            }
        }

        await this.callbacks.onDeleted(this.state);

        this.tracker.track('Mission Deleted', this.getTrackingMeta);

        this.pages.list.replace();
    };

    getMissionStatus = () => {
        let status = this.state.status;
        if (status === 'active' && moment(this.getEndTime()).valueOf() < Date.now()) {
            status = 'completed';
        }
        return status;
    };

    setChecklist = (checklist: IMissionChecklist) => {
        this.setState({ checklist });
    };

    getReminderCount = () => {
        if (!this.state.checklist || this.getMissionStatus() === 'user-canceled' || this.isNotValid()) {
            return 0;
        }
        const hasOverdueNotification = (i: IChecklistItem) => !i.completed && i.notification && moment(i.notification.at).isBefore(moment());
        return this.state.checklist.sections.reduce(
            (total, s) => total + s.items.reduce(
                (itemTotal, i) => itemTotal + (hasOverdueNotification(i) ? 1 : 0),
                0
            ),
            0
        );
    };

    getConcurrentLAANCMissions = () => {
        return this.callbacks.getConcurrentLAANCMissions(this.state);
    };

    refresh = async () => {
        await this.callbacks.onRefresh(this.state);
        this.setState({ refreshedAt: Date.now() });
    };

    /**
     * Refresh interval:
     * - On page load: every 10 secs
     * - 1 min after page load: every 1 min
     * - 10 mins after page load: every 10 mins
     * - 1 hr before mission start: every 1 min
     * - 1 hr after mission end: every 10 mins
     */
    getAutoRefreshIntervalMs = (elapsedMs: number) => {
        if (elapsedMs < 60000) {
            return 10000; // 10s
        }
        if (elapsedMs < 600000) {
            return 60000; // 1m
        }
        const hoursUntilStart = -moment().diff(this.getStartTime(), 'h');
        const hoursUntilEnd = -moment().diff(this.getEndTime(), 'h');
        if (hoursUntilStart < 1 && hoursUntilEnd > -1) {
            return 60000; // 1m
        } else {
            return 600000; // 10m
        }
    };

    pages!: {
        details: IPath;
        viewMap: IPath<{source: 'menu' | 'button' | 'airspace'}>;
        editTimes: IPath;
        list: IPath;
        weather: IPath;
        notes: IPath;
        checklist: IPath;
        laancAuthorization: IPath;
        weatherMap: IPath;
        hourlyWeather: IPath<{ startingAt: ISO8601DateTimeString, from: 'HourlyForecastCard' | 'DailyForecastCard' }>
    };

    onInjected = () => {
        this.pages = {
            details: this._paths.build(() => `#/missions/${this.state.missionId}`),
            viewMap: this._paths.build((context) => `#/missions/${this.state.missionId}/map?source=${context?.source || ''}`),
            editTimes: this._paths.build(() => `#/missions/${this.state.missionId}/map?editTimes`),
            weather: this._paths.build(() => `#/missions/${this.state.missionId}/weather`),
            list: this._paths.build(() => '#/missions'),
            notes: this._paths.build(() => `#/missions/${this.state.missionId}/notes`),
            checklist: this._paths.build(() => `#/missions/${this.state.missionId}/checklist`),
            laancAuthorization: this._paths.build(() => `#/missions/${this.state.missionId}/laanc-authorization`),
            weatherMap: this._paths.build(() => `#/missions/${this.state.missionId}/weather/map`),
            hourlyWeather: this._paths.build((context) => `#/missions/${this.state.missionId}/weather/hourly?startingAt=${context?.startingAt || ''}&from=${context?.from || ''}`)
        };
    };
};
