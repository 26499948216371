import { useInstance } from '@meraki-internal/react-dependency-injection';
import { LocationSearch } from '../../components/search/LocationSearch';
import { ICoordinate } from '../../support/model/ICoordinate';
import { ILocation } from '../../support/model/ILocation';
import { usePageView } from '../../support/tracking/usePageView';
import { LocationSearchService } from './LocationSearchService';
import { useBreakpoints } from '../../support/hooks/useBreakpoints';

export interface LocationSearchPanelProps {
    onSelect: (location?: ILocation) => void;
    onChange: (searchText: string) => void;
    onCoordinates: (coordinates: ICoordinate) => void;
}

export const LocationSearchPanel: React.FC<LocationSearchPanelProps> = ({ onSelect, onChange, onCoordinates }) => {
    usePageView('Map');

    const locationSearchService = useInstance(LocationSearchService);

    const { isLargerScreen } = useBreakpoints();

    const matchesToReturn = !isLargerScreen ? 5 : 10;

    const onSearch = async (searchText: string): Promise<ILocation[]> => {
        onChange(searchText);
        return await locationSearchService.search({ searchText, matchesToReturn });
    };

    return (
        <LocationSearch onSearch={onSearch} onSelect={onSelect} onCoordinates={onCoordinates} />
    );
};
