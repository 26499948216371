import { IonText } from '@ionic/react';
import { EditableMissionState } from '../../mission/model/EditableMissionState';
import { initCap } from '../../support/helpers/format';

export const MissionDaySpan = ({ mission }: { mission: EditableMissionState }) => {
    const dayString = mission.getFormattedDay();
    const missionStatus = mission.getMissionStatus();

    const style = (missionStatus === 'user-canceled' ? { textDecoration: 'line-through' } : undefined);

    return (
        <IonText style={style}>
            {initCap(dayString)}
        </IonText>
    );
};
