import { LaunchReview } from '@awesome-cordova-plugins/launch-review';
import { Device } from '../support/Device';
import { Logger } from '../support/debug/Logger';
import { AlertPresenter } from './AlertBinder';

export class ReviewLauncher {

    static inject = () => [AlertPresenter, Device, Logger];
    constructor(private alert: AlertPresenter, private device: Device, private logger: Logger) {}

    isSupported = () => {
        return (['ios', 'android'].includes(this.device.platform));
    };

    openRatingDialog = () => {
        if (!this.isSupported()) {
            this.alert.showAlert({ header: 'Not Supported', message: 'Only available in the mobile app.' });
        }

        // on ios pre-10.3, rating is not supported, so launch review instead
        if (!LaunchReview.isRatingSupported()) {
            LaunchReview.launch();
            return;
        }

        // NOTE: must invoke .subscribe() or the ratings dialog won't show at all!
        LaunchReview.rating().subscribe({ error: (err) => {
            this.logger.error(`Failed opening rating dialog: ${err}`);
        }});
    };

    openAppStorePage = () => {
        if (!this.isSupported()) {
            this.alert.showAlert({ header: 'Not Supported', message: 'Only available in the mobile app.' });
        }

        LaunchReview.launch();
    };
}
