import { IIntercomAdapter, IIntercomEnv } from './IntercomAdapter';
import { isPlatform } from '@ionic/react';
import { State } from '@meraki-internal/state';
import { Logger } from '../../support/debug/Logger';
import { EnvConfiguration } from '../config/EnvConfiguration';
import { IntercomInitDeputy } from './IntercomInitDeputy';
import { IntercomAdapterFactory } from './IntercomAdapter';
import { TrackingStorageProvider } from '../../support/tracking/TrackingStorageProvider';

// LOCAL DX flags
const ALLOW_LOCAL_DEV_AGAINST_INTERCOM_PROD = false;

export type ITourKey =
    'ONBOARDING' |
    'EXPLORE_AIRSPACE' |
    'ADD_MISSION' |
    'GET_LAANC_AUTH' |
    'MISSION_WEATHER' |
    'MISSION_NOTES' |
    'MISSION_CHECKLIST' |
    'CONNECT_CALENDAR' |
    'INVITE_PILOT';

export type IArticleKey =
    'CONNECT_MISSION_CALENDAR';

export const tourNameMap: { [key in ITourKey]: string } = {
    'ONBOARDING': 'Onboarding',
    'EXPLORE_AIRSPACE': 'Explore Airspace',
    'ADD_MISSION': 'Add a Mission',
    'GET_LAANC_AUTH': 'Get a LAANC Authorization',
    'MISSION_WEATHER': 'Check Mission Weather',
    'MISSION_NOTES': 'Add Mission Notes',
    'MISSION_CHECKLIST': 'Add a Checklist',
    'CONNECT_CALENDAR': 'Connect Your Calendar',
    'INVITE_PILOT': 'Invite a Fellow Pilot'
};

type ITourIdConfig = string | { ios: string, android: string };

interface IIntercomServiceConfig {
    INTERCOM_APP_ID: string;
    INTERCOM_TOUR_IDS?: { [key in ITourKey]: ITourIdConfig };
    INTERCOM_ARTICLE_IDS: { [key in IArticleKey]: string }
};

const envToConfig: { [env in IIntercomEnv]: IIntercomServiceConfig } = {
    'intercom-prod': {
        INTERCOM_APP_ID: 'jtvziasa',
        INTERCOM_TOUR_IDS: {
            ONBOARDING: { ios: '27567235', android: '35855291'},
            EXPLORE_AIRSPACE: '29592946',
            ADD_MISSION: '29781151',
            GET_LAANC_AUTH: '29781077',
            MISSION_WEATHER: '29781147',
            MISSION_NOTES: '29781805',
            MISSION_CHECKLIST: '30225598',
            CONNECT_CALENDAR: '',
            INVITE_PILOT: ''
        },
        INTERCOM_ARTICLE_IDS: {
            CONNECT_MISSION_CALENDAR : '6913312'
        }
    },
    'intercom-staging': {
        INTERCOM_APP_ID: 'lu5y4u1q',
        INTERCOM_TOUR_IDS: {
            ONBOARDING: { ios: '26904464', android: '33374564'},
            EXPLORE_AIRSPACE: '29928796',
            ADD_MISSION: '29928727',
            GET_LAANC_AUTH: '29928809',
            MISSION_WEATHER: '29928754',
            MISSION_NOTES: '28374189',
            MISSION_CHECKLIST: '30201769',
            CONNECT_CALENDAR: '',
            INVITE_PILOT: ''
        },
        INTERCOM_ARTICLE_IDS: {
            CONNECT_MISSION_CALENDAR: '6935838'
        }
    }
};

export interface IIntercomState {
    unreadCount: number;
}

export class IntercomService extends State<IIntercomState> {

    private intercom: IIntercomAdapter;

    static inject = () => [Logger, EnvConfiguration, IntercomAdapterFactory, TrackingStorageProvider, IntercomInitDeputy];
    constructor(
        private log: Logger,
        private envConfiguration: EnvConfiguration,
        private adapterFactory: IntercomAdapterFactory,
        private trackingStorage: TrackingStorageProvider,
        private intercomInitDeputy: IntercomInitDeputy
    ) {
        super({ unreadCount: 0 });
        this.intercom = this.adapterFactory.createInstance();
    }

    private config!: IIntercomServiceConfig;

    private enabled = false;

    private assertEnabled = () => {
        if (!this.enabled) {
            throw new Error('Intercom is not enabled');
        }
    };

    isEnabled = () => {
        return this.enabled;
    };

    getEnv = (): IIntercomEnv => {
        return this.intercom.getEnv();
    };

    init = async (user: { userId: string, name: string, email: string }) => {
        this.log.info('IntercomService.init', user);

        try {
            const intercomEnv = this.intercom.getEnv();

            const { canInit, message } = this.intercomInitDeputy.canInit({
                appEnv: this.envConfiguration.ENV,
                intercomEnv,
                ALLOW_LOCAL_DEV_AGAINST_INTERCOM_PROD,
                optOutOfTracking: this.trackingStorage.optOutOfTracking.get()
            });

            if (!canInit){
                this.log.info(`Skipping Intercom init: ${message}`);
                return;
            }

            this.config = envToConfig[intercomEnv];

            this.log.info('Initializing Intercom...', user);

            await this.intercom.init({
                appId: this.config.INTERCOM_APP_ID,
                ...user
            });

            this.intercom.onUnreadCountChange(unreadCount => {
                this.setState({ unreadCount });
            });

            this.enabled = true;

        } catch (e: any) {
            if (typeof e === 'string') {
                this.log.error(new Error(`Failed to initialize Intercom. ${e}`));
            } else {
                e.message = `Failed to initialize Intercom. ${e.message}`;
                this.log.error(e);
            }
        }
    };

    updateUser = async ({ name, email }: { name: string, email: string }) => {
        const updates: { name: string, customAttributes: any, email?: string } = { name, customAttributes: {} };
        if (email) {
            updates.email = email;
        }
        this.log.info('IntercomService.updateUser', { updates, enabled: this.enabled });

        if (this.enabled) {
            try {
                await this.intercom.updateUser(updates);
            }
            catch (err: any) {
                this.log.error(`failed to call Intercom.updateUser. ${err.toString()}`);
            }
        }
    };

    displayMessenger = async () => {
        this.assertEnabled();

        await this.intercom.displayMessenger();
    };

    displayMessageComposer = async ({ message }: { message?: string } = {}) => {
        this.assertEnabled();

        await this.intercom.displayMessageComposerWithInitialMessage(message || '');
    };

    canShowProductTour = () => {
        return this.enabled && this.intercom.canDisplayCarousel;
    };

    showProductTour = async (tourKey: ITourKey) => {
        this.assertEnabled();

        const tourConfig = this.config.INTERCOM_TOUR_IDS![tourKey];

        let carouselId: string;
        if (typeof tourConfig !== 'string'){
            carouselId = tourConfig[isPlatform('ios') ? 'ios': 'android'];
        } else {
            carouselId = tourConfig;
        }

        await this.intercom.displayCarousel(carouselId);
    };

    displayArticle = async (articleKey: IArticleKey) => {
        this.assertEnabled();

        const articleId = this.config.INTERCOM_ARTICLE_IDS![articleKey];
        if (!articleId) {
            throw new Error(`article ${articleKey} is not mapped. Available articles are ${Object.keys(this.config.INTERCOM_ARTICLE_IDS).join(', ')}`);
        }
        await this.intercom.displayArticle(articleId);
    };

    setMessageVisibility = async (visible: boolean) => {
        if (this.enabled) {
            await this.intercom.setMessageVisibility(visible);
        }
    };

    getUnreadCount = () => {
        if (this.enabled) {
            return this.state.unreadCount;
        } else {
            return 0;
        }
    };
}
