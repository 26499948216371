import { Color } from '../common';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
    cardFooter: {
        fontSize: 11,
        width: '100%',
        height: '100%',
        padding: '5px 20px',
        lineHeight: 'normal'
    },

    // align message left and note right
    cardFooterInner: {
        width: '100%',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    }
});

export const CardFooter: React.FC<{message?: string, note?: string, color?: Color, dataType?: string}> = ({ message = '', note = '', color = 'danger', dataType }) => {
    const classes = useStyles();
    return (
        <>
            {(message || note) &&
                <div
                    data-type={dataType}
                    className={classes.cardFooter}
                    style={{backgroundColor: `rgba(var(--ion-color-${color}-rgb), 0.1)`, color: `var(--ion-color-${color})`}}
                >
                    <span className={classes.cardFooterInner}>
                        <span>{message}</span>
                        <span>{note}</span>
                    </span>
                </div>
            }
        </>
    );
};
