import { HistoryViewModel } from './HistoryViewModel';

export interface IPath<T = void> {
    path: (context?: T) => string;
    go: (context?: T) => void;
    replace: (context?: T) => void;
}

export class PagePathsBuilder {
    static inject = () => [HistoryViewModel];
    constructor(private history: HistoryViewModel) { }
    build = <T>(pathDelegate: (context?: T) => string): IPath<T> => {
        return {
            path: (context?: T) => pathDelegate(context),
            go: (context?: T) => this.history.push(pathDelegate(context)),
            replace: (context?: T) => this.history.replace(pathDelegate(context))
        };
    };
};
