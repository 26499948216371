import moment from 'moment';
import { APIClient } from '@autopylot-internal/autopylot-api-client';
import { formatLocalDay, getTimezone } from '../support/helpers/format';
import { IDailyForecast } from './model/IDailyForecast';
import { IHourlyForecast } from './model/IHourlyForecast';
import { UserFacingError } from '../support/UserFacingError';
import { eventually } from '../support/helpers/eventually';
import { ICoordinate } from '@autopylot-internal/tiles-client';

type ISO8601 = string;

export class WeatherService {

    static inject = () => [APIClient];
    constructor(private apiClient: APIClient) {}

    checkForecastAvailableV2 = ({ startTime, location }: { startTime: ISO8601; location: ICoordinate; }) => {
        const timezone = getTimezone(location);
        const availableOn = moment.tz(startTime, timezone).subtract(9, 'days').startOf('day');

        if (moment().isBefore(availableOn)) {
            throw new UserFacingError({
                status: 400,
                errorCode: 'forecast.unavailable',
                message: 'Forecast unavailable',
                displayMessage: `Forecast will be available ${formatLocalDay({ date: availableOn.toISOString(), location })}`
            });
        }
    };

    getHourlyForecastV2 = async ({ link, startTime, endTime, location }: { link: { href: string; }; startTime: ISO8601; location: ICoordinate; endTime: ISO8601; }): Promise<IHourlyForecast[]> => {
        this.checkForecastAvailableV2({ location, startTime });

        return await this._fetchWeatherWithRetryV2({ link, startTime, endTime, location });
    };

    getDailyForecastV2 = async ({ link, startTime, endTime, location }: { link: { href: string; }; startTime: ISO8601; location: ICoordinate; endTime: ISO8601; }): Promise<IDailyForecast[]> => {
        this.checkForecastAvailableV2({ startTime, location });

        return await this._fetchWeatherWithRetryV2({ link, startTime, endTime, location });
    };

    getChartForecast = async ({ link, startTime, location }: { link: { href: string; }; startTime: ISO8601; location: ICoordinate; }) => {
        this.checkForecastAvailableV2({ location, startTime });
        return await this._fetchWeatherWithRetryV2({ link, startTime, endTime: '', location });
    };

    // this is explitly public b/c it is used by WeatherServiceMocker, and so if we break the
    // interface between the two (again) we will know
    public _fetchWeatherWithRetryV2 = async ({ link, startTime, endTime, location }: { link: { href: string; }; startTime: ISO8601; location: ICoordinate; endTime: ISO8601; }) => {
        return await eventually(
            () => this.apiClient.get(link, undefined, { startTime, endTime, lat: location.lat, lng: location.lng }, { allowCaching: true }),
            60000, // total time to wait
            1000 // retry interval
        );
    };
}
