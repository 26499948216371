/* eslint-disable indent */

import mapboxgl, { GeoJSONSourceOptions, Map } from 'mapbox-gl';
import { MapboxVectorWithMockCreator } from '../MapboxVectorWithMockCreator';
import { TileUrlProvider, TimesheetMaterializer } from '@autopylot-internal/tiles-client';
import { ApMapboxStylerTimeState } from './ApMapboxStylerTimeState';
import { ErrataState } from '../ErrataState';
import { Logger } from '../../support/debug/Logger';
import { DevSettings } from '../../support/DevSettings';

// TODO: rip this out and helper methods tempSetAdviseColor, etc
const adviseColor = window.localStorage.getItem('temp_advise_color') || '#ECE425';
const adviseOpacity = Number(window.localStorage.getItem('temp_advise_opacity') || .1);
const adviseMinZoom = 9;

type IMapboxFilter = any[] | false;

/**
 * mapbox gl js expressions are hard to read and write. Even harder when you need follow normal
 * whitespace rules. This class is meant to encapsulate all the mapboxgl style expressions, and
 * as much as practical only that... since indent rules are disabled here
 */

const blockOpacity = .1;

/**
 * ApMapboxStyler has the difficult job of visualizing the data into mapbox layers.
 *
 * There are lots of things it has to manage in doing that, for example
 *  - maybe it needs 3 layers, one for a line, one for fill, and one for labels
 *  - getting them to stack visually in the correct order
 *  - minimizing jankiness
 *  - errata layer for each layer
 *
 * To maintain that encapsulation, while also being extensible, so that higher
 * abstractions, like VisibleMapLayersState, can manage what layers are visible
 * we have a concept of IApMapboxStylerLogicalLayer (logical layers, as deemed by this class)
 *
 * These are meant to be the same logical unit as we have in tiles, and so the names match.
 *
 * Futher grouping (eg National Areas) is done up the abstraction.
 *
 * This allows VisibleMapLayersState to reason about its user facing `laanc` layer as the IApMapboxStylerLogicalLayer
 * logical layers uasfm and airspace, without needing to reason about
 *  the fact that uasfm is really 3 layers, times 3 (for normal, errata, and mock), and what filters go with it.
 *
 * This allows VisibleMapLayersState to reason about special-use-airspace, without needing to know that is spread across
 * 4 layers (advise fill, advise line, block fill, block line) times 3 (for normal, errata, and mock)
 *
 * Another major goal of this abstraction, is encapsulation. For example, today we do not guarantee the visual stacking order of blocking SUA and TFR.
 * They are in the same mapbox layer, so the order of the feature is driving. If we needed to guarantee the order, we'd need to split them into
 * separate mapbox layers, but we could do so without needing to change VisibleMapLayersState b/c that is encapsulated here.
 */
export type IApMapboxStylerLogicalLayer = 'uasfm'
    | 'airspace'
    | 'nsufr' // also includes parttime-nsufr and dc-frz, but they could be split out if we end up wanting that;
    | 'temporary-flight-restrictions'
    | 'national-parks'
    | 'wilderness-areas'
    | 'wildlife-refuges'
    | 'special-use-airspace'
    | 'airspace-boundary'
    | 'military-training-routes'
    | 'rec-flyer-fixed-sites';

export type IApMapboxStylerLogicalLayerVisibility = 'all' | 'advise-only' | 'block-only' | 'none';

export class ApMapboxStyler {
    static inject = () => [
        TileUrlProvider,
        MapboxVectorWithMockCreator,
        ApMapboxStylerTimeState,
        TimesheetMaterializer,
        ErrataState,
        Logger,
        DevSettings
    ];
    constructor(
        private tileUrlProvider: TileUrlProvider,
        private layerCreator: MapboxVectorWithMockCreator,
        private mapTime: ApMapboxStylerTimeState,
        private timesheetMaterializer: TimesheetMaterializer,
        private errataState: ErrataState,
        private logger: Logger,
        private devSettings: DevSettings
    ) { }

    getAllLayerIds = (): string[] => {
        // TODO: ideally refactor to make this less of a foot gun
        return [
            ...this.layerCreator.getLayerIds('layer:airspace:uasfm:fill'),
            ...this.layerCreator.getLayerIds('layer:airspace:uasfm:label'),
            ...this.layerCreator.getLayerIds('layer:airspace:uasfm:line:laanc'),
            ...this.layerCreator.getLayerIds('layer:airspace:fill'),
            ...this.layerCreator.getLayerIds('layer:airspace:line'),
            ...this.layerCreator.getLayerIds('layer:airspace:other-blocking:fill'),
            ...this.layerCreator.getLayerIds('layer:airspace:other-blocking:line'),
            ...this.layerCreator.getLayerIds('layer:airspace:advise:fill'),
            ...this.layerCreator.getLayerIds('layer:airspace:advise:line'),
        ];
    };

    // they all start as none, so that MapVisibleLayers can drive which are visible
    private logicalLayerVisibility : { [key in IApMapboxStylerLogicalLayer]: IApMapboxStylerLogicalLayerVisibility } = {
        uasfm: 'none',
        airspace: 'none',
        nsufr: 'none',
        'temporary-flight-restrictions': 'none',
        'national-parks': 'none',
        'wilderness-areas': 'none',
        'wildlife-refuges': 'none',
        'special-use-airspace': 'none',
        'airspace-boundary': 'none',
        'military-training-routes': 'none',
        'rec-flyer-fixed-sites': 'none',
    };

    private logicalLayersToMapboxLayersMapping: { [key in IApMapboxStylerLogicalLayer]: { layerCreatorLayerId: string, filterFunction: () => any } []} = {
        uasfm: [
            { layerCreatorLayerId: 'layer:airspace:uasfm:fill', filterFunction: () => this.buildUASFMAboveZeroFilter() },
            { layerCreatorLayerId: 'layer:airspace:uasfm:label', filterFunction: () => this.buildUASFMFilter() },
            { layerCreatorLayerId: 'layer:airspace:uasfm:line:laanc', filterFunction: () => this.buildUASFMAboveZeroFilter() },
        ],
        airspace: [
            { layerCreatorLayerId: 'layer:airspace:fill', filterFunction: () => this.buildAirspaceFilter() },
            { layerCreatorLayerId: 'layer:airspace:line', filterFunction: () => this.buildAirspaceFilter() },
        ],
        nsufr: [
            { layerCreatorLayerId: 'layer:airspace:other-blocking:fill', filterFunction: () => this.buildOtherBlockedExcludingErrataFilter() },
            { layerCreatorLayerId: 'layer:airspace:other-blocking:line', filterFunction: () => this.buildOtherBlockedExcludingErrataFilter() },
        ],
        'temporary-flight-restrictions': [
            { layerCreatorLayerId: 'layer:airspace:other-blocking:fill', filterFunction: () => this.buildOtherBlockedExcludingErrataFilter() },
            { layerCreatorLayerId: 'layer:airspace:other-blocking:line', filterFunction: () => this.buildOtherBlockedExcludingErrataFilter() },
            { layerCreatorLayerId: 'layer:airspace:advise:fill', filterFunction: () => this.buildAdviseFilter() },
            { layerCreatorLayerId: 'layer:airspace:advise:line', filterFunction: () => this.buildAdviseFilter() },
        ],
        'national-parks': [
            { layerCreatorLayerId: 'layer:airspace:other-blocking:fill', filterFunction: () => this.buildOtherBlockedExcludingErrataFilter() },
            { layerCreatorLayerId: 'layer:airspace:other-blocking:line', filterFunction: () => this.buildOtherBlockedExcludingErrataFilter() },
        ],
        'wilderness-areas': [
            { layerCreatorLayerId: 'layer:airspace:other-blocking:fill', filterFunction: () => this.buildOtherBlockedExcludingErrataFilter() },
            { layerCreatorLayerId: 'layer:airspace:other-blocking:line', filterFunction: () => this.buildOtherBlockedExcludingErrataFilter() },
        ],
        'wildlife-refuges': [
            { layerCreatorLayerId: 'layer:airspace:other-blocking:fill', filterFunction: () => this.buildOtherBlockedExcludingErrataFilter() },
            { layerCreatorLayerId: 'layer:airspace:other-blocking:line', filterFunction: () => this.buildOtherBlockedExcludingErrataFilter() },
        ],
        'special-use-airspace': [
            { layerCreatorLayerId: 'layer:airspace:advise:fill', filterFunction: () => this.buildAdviseFilter() },
            { layerCreatorLayerId: 'layer:airspace:advise:line', filterFunction: () => this.buildAdviseFilter() },
            { layerCreatorLayerId: 'layer:airspace:other-blocking:fill', filterFunction: () => this.buildOtherBlockedExcludingErrataFilter() },
            { layerCreatorLayerId: 'layer:airspace:other-blocking:line', filterFunction: () => this.buildOtherBlockedExcludingErrataFilter() },
        ],
        'airspace-boundary': [
            { layerCreatorLayerId: 'layer:airspace:other-blocking:fill', filterFunction: () => this.buildOtherBlockedExcludingErrataFilter() },
            { layerCreatorLayerId: 'layer:airspace:other-blocking:line', filterFunction: () => this.buildOtherBlockedExcludingErrataFilter() },
            { layerCreatorLayerId: 'layer:airspace:advise:fill', filterFunction: () => this.buildAdviseFilter() },
            { layerCreatorLayerId: 'layer:airspace:advise:line', filterFunction: () => this.buildAdviseFilter() },
        ],
        'military-training-routes': [
            { layerCreatorLayerId: 'layer:airspace:advise:fill', filterFunction: () => this.buildAdviseFilter() },
            { layerCreatorLayerId: 'layer:airspace:advise:line', filterFunction: () => this.buildAdviseFilter() },
        ],
        'rec-flyer-fixed-sites': [
            { layerCreatorLayerId: 'layer:airspace:advise:fill', filterFunction: () => this.buildAdviseFilter() },
            { layerCreatorLayerId: 'layer:airspace:advise:line', filterFunction: () => this.buildAdviseFilter() },
        ],
    };

    renderTimeout: number | undefined = undefined;

    style = async (map: mapboxgl.Map) => {
        await this.addAirspace(map);

        // add radius after airspace so that radius is on top


        // FUD - this was moved before the await to avoid a "race condition"
        // that can result in the error "Cannot read properties of undefined (reading 'setData')"
        // in MapViewModel.setPinAndRadius()
        // which would happen when it calls
        //   map.getSource('source:radius') as mapboxgl.GeoJSONSource).setData
        // but that is sync ready after invoking this function, so I can't see how there could be a race condition
        // or setting this first, then awaiting airspace would help
        this.addRadiusSourceAndLayer(map);

        this.mapTime.subscribe(() => {
            this.updateTimeFilters(map);
            this.updateScheduledAirspaceFeatures(map);
        });

        this.errataState.pollForErrata();
        this.errataState.subscribe(() => {
            // update filters, with latest globalIds to remove
            this.updateTimeFilters(map);

            this.layerCreator.setErrata({ errataState: this.errataState,  map });
        });

        map.on('render', () => {
            // When base map is changed by BaseMapProvider.nextBaseMap we may see a render while the style is in a
            // unready state, we can safely ignore those b/c more renders are coming once it loads
            if (!map.isStyleLoaded()){
                return;
            }
            try{
                this.updateScheduledAirspaceFeatures(map);
            }
            catch (err: any){
                console.log(err, JSON.stringify(err));
                // impact is maybe there were new feature, and maybe they didn't get airspace schedule
                // logic applied, but as soon as they pan the map, causing a new render, it will likely be back to good
                this.logger.error(`ApMapboxStyler got an errror. It observed a Mapbox render got an error calling updateScheduledAirspaceFeatures. Error: ${err.toString()}`);
            }
        });
    };

    updateScheduledAirspaceFeatures = (map: mapboxgl.Map) => {
        // NOTE: we're querying rendered features over source features because it is way faster! (like 10ms vs 100ms)
        //  this has us coupled to the layer id (which is a concern of this same class, so not too far away)
        //  we only query one of the layers the feature is in (the changes effect all the layers the feature is in,
        //      we had FUD this might be a copy specific to the layer)

        const features = map.queryRenderedFeatures({ layers: ['layer:airspace:fill', 'errata:layer:airspace:fill', 'layer:airspace:uasfm:fill', 'errata:layer:airspace:uasfm:fill'] } as any);

        // a schedule will be repeated on many UASFM features, so lets cache the answer for reuse
        // for this invocation of updateScheduledAirspaceFeatures. We aren't caching longer, because it might create too much memory pressure
        // and is redundant with checking the check to see if the feature already has the answer below
        const scheduleCache: { scheduleJSON: string, isActive: boolean}[] = [];

        for (const feature of features.filter(f => f.properties && f.properties.activeDuring)){
            const featureIdentifier = { id: feature.id, source: 'source:airspace', sourceLayer: 'geojsonLayer'};

            const { startTime, endTime } = this.mapTime.state;

            const missionBasis = JSON.stringify({ startTime, endTime });

            // check to see if the feature already has an answer (for the current mission start / end time)
            const oldState = map.getFeatureState(featureIdentifier);
            if (!oldState || oldState.isActiveMissionBasis !== missionBasis){

                const cachedAnswer = scheduleCache.find(s => s.scheduleJSON === feature.properties!.activeDuring);

                let isActive = false;
                if (!cachedAnswer){
                    const firstActiveTime = this.timesheetMaterializer.materializeGenerator({ windowToMaterialize: { startTime, endTime }, timesheets: JSON.parse(feature.properties!.activeDuring)}).next().value;
                    isActive = Boolean(firstActiveTime);
                    scheduleCache.push({ scheduleJSON: feature.properties!.activeDuring, isActive });
                } else {
                    isActive = cachedAnswer.isActive;
                }


                map.setFeatureState(featureIdentifier, { isActive, isActiveMissionBasis: missionBasis });
            }
        }
    };

    private updateTimeFilters = (map: mapboxgl.Map) => {
        this.layerCreator.setFilter({
            map,
            layer: 'layer:airspace:other-blocking:fill',
            filter: this.buildOtherBlockedExcludingErrataFilter()
        });

        this.layerCreator.setFilter({
            map,
            layer: 'layer:airspace:other-blocking:line',
            filter: this.buildOtherBlockedFilter()
        });

        this.layerCreator.setFilter({
            map,
            layer: 'layer:airspace:advise:fill',
            filter: this.buildAdviseFilter()
        });
        this.layerCreator.setFilter({
            map,
            layer: 'layer:airspace:advise:line',
            filter: this.buildAdviseFilter()
        });
    };

    private buildAirspaceFilter = () => {
        if (this.logicalLayerVisibility.airspace === 'all'){
            return ['==', 'airspace', ['get', 'source']];
        }
        else {
            return false;
        }
    };

    private buildUASFMAboveZeroFilter = (): IMapboxFilter => {
        if (this.logicalLayerVisibility.uasfm === 'all'){
            return [
                'all',
                    ['==', 'uasfm+airspace', ['get', 'source']],
                    ['>', ['get', 'CEILING'], 0],
                    ['==', ['get', 'UASFM_LAANC'], true]
            ];
        }
        else {
            return false;
        }
    };

    private buildUASFMFilter = (): IMapboxFilter => {
        if (this.logicalLayerVisibility.uasfm === 'none'){
            return false;
        }
        return ['==', 'uasfm+airspace', ['get', 'source']];
    };

    private addAirspace = async (map: Map) => {
        const url = await this.tileUrlProvider.getAirspaceTileUrl();

        const {sources, layers } = this.layerCreator.create({
            map,
            id: 'airspace',
            source: {
                tiles: [url],
                'minzoom': 6,
                'maxzoom': 12
            },
            layers: [
                {
                    id: 'advise:fill',
                    type: 'fill',
                    minzoom: adviseMinZoom,
                    'filter': this.buildAdviseFilter(),
                    'paint': {
                        'fill-color': adviseColor,
                        'fill-opacity': adviseOpacity
                    }
                },
                {
                    id: 'advise:line',
                    type: 'line',
                    minzoom: adviseMinZoom,
                    'filter': this.buildAdviseFilter(),
                    'paint': {
                        'line-color': adviseColor,
                        'line-width': [
                            'interpolate',
                            // NOTE: not sure what this 2 is for
                            ['exponential', 2],
                            ['zoom'],
                            // exponentially interpolate from zoom 11 (1 px wide) to zoom 17 (4 px wide)
                            11, .5,
                            17, 2
                        ]
                    }
                },
                {
                    type: 'fill',
                    // TODO: false is valid, why doesn't mapbox know that ;)
                    filter: this.buildAirspaceFilter() as any,
                    paint: {
                        'fill-color': [
                            'step',
                            ['zoom'],
                            '#7C1C8D', 9,
                            '#F60726'
                        ],
                        'fill-opacity': [
                            'case',
                                ['==', false, ['feature-state', 'isActive']], 0,
                                blockOpacity
                        ]
                    }
                },
                {
                    type: 'line',
                    'filter': this.buildAirspaceFilter() as any,
                    'paint': {
                        'line-color': [
                            'step',
                            ['zoom'],
                            '#7C1C8D', 9,
                            '#F60726'
                        ],
                        'line-width': [
                            'interpolate',
                            // NOTE: not sure what this 2 is for
                            ['exponential', 2],
                            ['zoom'],
                            // exponentially interpolate from zoom 11 (1 px wide) to zoom 17 (4 px wide)
                            11, .5,
                            17, 2
                        ],
                        'line-opacity': [
                            'case',
                                ['==', false, ['feature-state', 'isActive']], 0,
                                1
                        ]
                    }
                },
                {
                    id: 'uasfm:fill',
                    type: 'fill',
                    'filter': this.buildUASFMAboveZeroFilter() as any,
                    'paint': {
                        'fill-color': [
                            'match',
                            ['get', 'CEILING'],
                            0, '#F60726',
                            '#FD9104'

                        ],
                        'fill-opacity': [
                            'case',
                                ['==', false, ['feature-state', 'isActive']], 0,
                                0.2
                        ]
                    },
                    minzoom: 9
                },
                {
                    id: 'uasfm:label',
                    type: 'symbol',
                    filter: this.buildUASFMFilter() as any,
                    layout: {
                        'text-field': ['format',
                            ['get', 'CEILING'],
                            '\''
                        ],
                        'text-size': [
                            'interpolate',
                            ['linear'],
                            ['zoom'],
                            12, 10,
                            17, 24
                        ],
                    },
                    paint: {
                        'text-halo-color': '#fff',
                        'text-halo-blur': 2,
                        'text-halo-width': 2,
                        'text-opacity': [
                            'case',
                                ['==', false, ['feature-state', 'isActive']], 0,
                                1
                        ]
                    },
                    minzoom: 12
                },
                {
                    // we are only rendering the orange LAANC lines so that they "win"
                    // otherwise we end up with a visual fight where orange and red grids join
                    id: 'uasfm:line:laanc',
                    type: 'line',
                    'filter': this.buildUASFMAboveZeroFilter() as any,
                    'paint': {
                        'line-color': '#FD9104',
                        'line-width': [
                            'interpolate',
                            // NOTE: not sure what this 2 is for
                            ['exponential', 2],
                            ['zoom'],
                            // exponentially interpolate from zoom 11 (1 px wide) to zoom 17 (4 px wide)
                            11, 1,
                            17, 4
                        ],
                        'line-opacity': [
                            'case',
                                ['==', false, ['feature-state', 'isActive']], 0,
                                1
                        ]
                    },
                    minzoom: 9
                },
                {
                    id: 'other-blocking:fill',
                    type: 'fill',
                    'filter': this.buildOtherBlockedExcludingErrataFilter(),
                    'paint': {
                        'fill-color': [
                            'step',
                            ['zoom'],
                            '#7C1C8D', 9,
                            '#F60726'
                        ],
                        'fill-opacity': blockOpacity
                    }
                },
                {
                    id: 'other-blocking:line',
                    type: 'line',
                    'filter': this.buildOtherBlockedExcludingErrataFilter(),
                    'paint': {
                        'line-color': [
                            'step',
                            ['zoom'],
                            '#7C1C8D', 9,
                            '#F60726'
                        ],
                        'line-width': [
                            'interpolate',
                            // NOTE: not sure what this 2 is for
                            ['exponential', 2],
                            ['zoom'],
                            // exponentially interpolate from zoom 11 (1 px wide) to zoom 17 (4 px wide)
                            11, .5,
                            17, 2
                        ]
                    }
                },
                // TODO: how far zoomed out can you see it?
                // TODO: size (at 9, and 17)
                // TODO: what icons? ( for each type )
                // TODO: color
                // TODO: stacking
                // TODO: toggles
                {
                    id: 'airports:icon',
                    type: 'symbol',
                    filter: ['all',
                        ['==', 'airports', ['get', 'source']],
                        ['==', true, ['literal', this.devSettings.previewAirports]],
                    ],
                    'layout': {
                        'icon-image': 'heliport2',
                        'icon-size': [
                            'interpolate',
                            ['linear'],
                            ['zoom'],
                            9, .05,
                            17, .25
                        ]
                    },
                    minzoom: 9
                }
            ]
        });

        map.loadImage('https://cdn-icons-png.flaticon.com/512/93/93059.png', (error, image) => {
            if (error) throw error;
            map.addImage('heliport2', image!);
        });

        this.layerIds = [...this.layerIds, ...layers ];
        this.sourceIds = [...this.sourceIds, ...sources];

        this.tileUrlProvider.subscribe(async () => {
            const latestUrl = await this.tileUrlProvider.getAirspaceTileUrl();
            (map.getSource('source:airspace') as any).setTiles([latestUrl]);
        });
    };

    private buildAdviseFilter = () => {
        // TODO: errata
        return [
            'any',
            // TODO: these are also going to get a special top level advisory
            // how can we DRY out the logic of which show on the map and get that
                ['all',
                    // TODO: use advisoryType instead (like TFR)
                    ['==', 'special-use-airspace', ['get', 'source']],
                    ['!',
                        ['any',
                            ['==', 'P', ['get', 'TYPE_CODE']],
                            ['==', 'R', ['get', 'TYPE_CODE']],
                        ],
                    ],
                    ['==', ['literal', ['all', 'advise-only'].includes(this.logicalLayerVisibility['special-use-airspace'])], true]
                ],
                ['all',
                    ['==', 'temporary-flight-restrictions', ['get', 'source']],
                    // is advise TFR
                    ['==', 'advise', ['get', 'advisoryType']],
                    // and toggled on
                    ['==', ['literal', ['all', 'advise-only'].includes(this.logicalLayerVisibility['temporary-flight-restrictions'])], true],
                     // and it is active
                    ['all',
                     // if it is permanent or started before our end
                     // if the TFR started before our end
                     ['<', ['get', 'effectiveStart'], ['get', 'endTime', ['literal', this.mapTime.state]]],
                     // and the TFR didn't end before our start
                     ['any',
                         ['==', ['get', 'effectiveEnd'], 'PERM'],
                         ['>', ['get', 'effectiveEnd'], ['get', 'startTime', ['literal', this.mapTime.state]]],
                     ],

                     // and it is not one that is duplicated by source: airspace-boundary dc-sfra
                     // this will be dead code once we no longer return those TFRs
                     ['!',
                        ['any',
                            ['==', '0/3939', ['get', 'number']],
                            ['==', '1/1155', ['get', 'number']],
                            ['==', '9/1811', ['get', 'number']],
                            ['==', '9/1812', ['get', 'number']],
                        ],
                     ]

                 ],
                ],
                ['all',
                    ['==', 'airspace-boundary', ['get', 'source']],
                    ['==', true, ['get', 'adviseOnly']],
                    ['==', ['literal', ['all', 'advise-only'].includes(this.logicalLayerVisibility['airspace-boundary'])], true]
                ],
                ['all',
                    ['==', 'military-training-routes', ['get', 'source']],
                    ['==', ['literal', ['all', 'advise-only'].includes(this.logicalLayerVisibility['military-training-routes'])], true]
                ],
                ['all',
                    ['==', 'rec-flyer-fixed-sites', ['get', 'source']],
                    ['==', ['literal', ['all', 'advise-only'].includes(this.logicalLayerVisibility['rec-flyer-fixed-sites'])], true]
                ],
        ];
    };

    /**
     * Builds a mapbox expression for Blocked features (but omitting features that have been removed by errata)
     *
     * Because they will be shown in an errata layer
     */
    private buildOtherBlockedExcludingErrataFilter = () => {
        return this.buildOtherBlockedFilter();
    };

    private buildOtherBlockedFilter = () => {
        return [
            'any',
                ['all',
                    ['==', 'nsufr', ['get', 'source']],
                    ['==', ['literal', ['all', 'block-only'].includes(this.logicalLayerVisibility.nsufr)], true]
                ],
                ['all',
                    ['==', 'parttime-nsufr', ['get', 'source']],
                    // if is is active between our start and end
                    // ... if it started before our end and didn't end before our start
                    ['all',
                        // if the NSUFR started before our end
                        ['<', ['get', 'ACTIVETIME'], ['get', 'endTime', ['literal', this.mapTime.state]]],
                        // and the NSUFR didn't end before our start
                        ['>', ['get', 'ENDTIME'], ['get', 'startTime', ['literal', this.mapTime.state]]],
                    ],
                    ['==', ['literal', ['all', 'block-only'].includes(this.logicalLayerVisibility.nsufr)], true]
                ],

                ['all',
                    ['==', 'temporary-flight-restrictions', ['get', 'source']],
                    // is blocking TFR
                    ['==', 'block', ['get', 'advisoryType']],
                    // and it is active
                    ['all',
                        // if it is permanent or started before our end
                        // if the TFR started before our end
                        ['<', ['get', 'effectiveStart'], ['get', 'endTime', ['literal', this.mapTime.state]]],
                        // and the TFR didn't end before our start
                        ['any',
                            ['==', ['get', 'effectiveEnd'], 'PERM'],
                            ['>', ['get', 'effectiveEnd'], ['get', 'startTime', ['literal', this.mapTime.state]]],
                        ]
                    ],
                    ['==', ['literal', ['all', 'block-only'].includes(this.logicalLayerVisibility['temporary-flight-restrictions'])], true]
                ],

                ['all',
                    ['==', 'special-use-airspace', ['get', 'source']],
                    ['any',
                        ['==', 'P', ['get', 'TYPE_CODE']],
                        ['==', 'R', ['get', 'TYPE_CODE']],
                    ],
                    ['==', ['literal', ['all', 'block-only'].includes(this.logicalLayerVisibility['special-use-airspace'])], true]
                ],

                ['all',
                    ['==', 'wilderness-areas', ['get', 'source']],
                    ['==', ['literal', ['all', 'advise-only'].includes(this.logicalLayerVisibility['wilderness-areas'])], true]
                ],
                ['all',
                    ['==', 'wildlife-refuges', ['get', 'source']],
                    ['==', ['literal', ['all', 'advise-only'].includes(this.logicalLayerVisibility['wildlife-refuges'])], true]
                ],
                ['all',
                    ['==', 'national-parks', ['get', 'source']],
                    ['==', ['literal', ['all', 'advise-only'].includes(this.logicalLayerVisibility['national-parks'])], true]
                ],
                ['all',
                    ['==', 'airspace-boundary', ['get', 'source']],
                    ['==', ['literal', ['all', 'block-only'].includes(this.logicalLayerVisibility['airspace-boundary'])], true],

                    // don't include DC SFRA
                    ['!', ['==', true, ['get', 'adviseOnly']]],

                ],
            ]; // </any>
    };

    private addRadiusSourceAndLayer = (map: mapboxgl.Map) => {
        const options: GeoJSONSourceOptions = {
            // buffer: 2,
            // tolerance: 1,
        };
        this.addSource(map, 'source:radius', {
            'type': 'geojson',
            'data': { type: 'FeatureCollection', features: [] },
            ...options
        });

        this.addLayer(map, {
            'id': 'layer:radius-fill',
            'type': 'fill',
            'source': 'source:radius',
            'layout': {},
            'minzoom': 12,
            'paint': {
                'fill-color': ['get', 'color'],
                'fill-opacity': 0.3
            }
        });
        this.addLayer(map, {
            'id': 'layer:radius-outline',
            'type': 'line',
            'source': 'source:radius',
            'minzoom': 12,
            'layout': {},
            'paint': {
                'line-color': '#fff',
                'line-width': [
                    'interpolate',
                    // NOTE: not sure what this 2 is for
                    ['exponential', 2],
                    ['zoom'],
                    // exponentially interpolate from zoom 11 (1 px wide) to zoom 17 (4 px wide)
                    11, 1.5,
                    17, 5

                    // NOTE this makes it stay about 3 ?meters? wide
                    // that isn't what we want... but I kept it cause we might ;)
                    // 10, ['*', 3, ['^', 2, -6]],
                    // 24, ['*', 3, ['^', 2, 8]]
                ]
            }
        });
    };

    private sourceIds: string[] = [];

    addSource = (map: mapboxgl.Map, sourceId: string, source: mapboxgl.AnySourceData) => {
        this.sourceIds.push(sourceId);
        map.addSource(sourceId, source);
    };

    private layerIds: string[] = [];

    addLayer = (map: mapboxgl.Map, layer: mapboxgl.AnyLayer) => {
        this.layerIds.push(layer.id);
        map.addLayer(layer);
    };

    getSources = (map: mapboxgl.Map): mapboxgl.Sources => {
        const currentSources = map.getStyle().sources;
        const ourSources: mapboxgl.Sources = {};

        for (const sourceId of this.sourceIds ){
            ourSources[sourceId] = currentSources[sourceId];
        }

        return ourSources;
    };

    getLayers = (map: mapboxgl.Map): mapboxgl.AnyLayer[] => {
        const currentStyle = map.getStyle();
        return currentStyle.layers.filter(l => this.layerIds.includes(l.id));
    };

    tempSetAdviseColor = (color: string) => {
        window.localStorage.setItem('temp_advise_color', color);
        window.location.reload();
    };
    tempSetAdviseOpacity = (opacity: number) => {
        window.localStorage.setItem('temp_advise_opacity', String(opacity));
        window.location.reload();
    };
    tempSetAdviseMinZoom = (minZoom: number) => {
        window.localStorage.setItem('temp_advise_min_zoom', String(minZoom));
        window.location.reload();
    };

    setLogicalLayerVisibility = (logicalLayer: IApMapboxStylerLogicalLayer, visibility: IApMapboxStylerLogicalLayerVisibility, map: mapboxgl.Map) => {
        const layers = this.logicalLayersToMapboxLayersMapping[logicalLayer];
        for (const { layerCreatorLayerId, filterFunction } of layers){
            // TODO: uasfm block-only, advise-only not supported

            this.logicalLayerVisibility[logicalLayer] = visibility;

            this.layerCreator.setFilter({
                map,
                layer: layerCreatorLayerId,
                filter : filterFunction()
            });
        }
    };

    showRadiusFill = ({ map }: { map: mapboxgl.Map }) => {
        map.setFilter('layer:radius-fill', undefined);
    };

    hideRadiusFill = ({ map }: { map: mapboxgl.Map }) => {
        map.setFilter('layer:radius-fill', false);
    };
}
