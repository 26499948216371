import * as mapboxgl from 'mapbox-gl';
import * as turf from '@turf/turf';
import { State } from '@meraki-internal/state';
import 'mapbox-gl/dist/mapbox-gl.css';
import { ICoordinate } from '../../support/model/ICoordinate';
import { ApMapboxStyler, IApMapboxStylerLogicalLayer, IApMapboxStylerLogicalLayerVisibility } from '../styles/ApMapboxStyler';
import { MockAirspaceProvider } from './MockAirspaceProvider';
import { PinchZoomWithoutPanHandler } from '../PinchZoomWithoutPanHandler';
import { TrackingService } from '../../support/tracking/TrackingService';
import { round } from '../../support/helpers/format';
import { AttributionControl } from '../attribution/AttributionControl';
import { BaseMapState } from '../styles/BaseMapState';
import { IMapEvent } from './IMapEvent';
import { MapClickManager } from './MapClickManager';
import { EnvConfiguration } from '../../app/config/EnvConfiguration';
import { StorageProvider } from '../../support/StorageProvider';
import { MapViewModelCompositePlugin } from './MapViewModelCompositePlugin';
import { MissionPinAndRadiusOnMapView } from '../mission-pin-and-radius/MissionPinAndRadiusOnMapView';
import { IBreakpoint, getBreakPoint, isLargerScreen } from '../../support/helpers/breakpoints';
import { SIDE_DRAWER_WIDTH } from '../../components/drawer/Drawer';
import { Logger } from '../../support/debug/Logger';

const breakPointsToMinZoom: { [key in IBreakpoint]: number } = {
    xs: 3,
    sm: 3.5,
    md: 4,
    lg: 4.25,
    xl: 4.5
};

// We initialize the map with this value, because setting it lower causes tests to fail for reasons we don't understand,
// but it gets updated based on the breakpoints above in ResizeObserver before the map renders
const INITIAL_MIN_ZOOM = 6;

// defaults for a new user
const INITIAL_ZOOM = 6.75;
const INITIAL_CENTER = { lng: -97.95, lat: 39.25 };

/**
 * References:
 *  - mapbox built in icons https://labs.mapbox.com/maki-icons/
 *  - can change image colors https://stackoverflow.com/questions/33838802/mapbox-gl-change-icon-color
 */

export class MapViewModelConfig {
    // used by screenhots
    DISABLE_MAP?: boolean;
}

export class MapViewModelStorage {
    static inject = () => [StorageProvider];
    constructor(private storage: StorageProvider){}

    centerAndZoom = this.storage.getJSONProvider<{ center: ICoordinate, zoom: number }>('ap:center-and-zoom-v2', { storageType: 'localStorage' });

    forceStagingMapbox = this.storage.getBooleanProvider('ap:force-mapbox-staging', { userNeutral: true, envNeutral: true, storageType: 'localStorage' });
}

/**
 * MapViewModel is meant to encapsulate all things Mapbox.
 *
 * our payable unit is "map-loads" https://docs.mapbox.com/help/glossary/map-loads/#
 *  - if you want the network tab, you'll see a call to GET .../session, which is a side effect of initializing our map
 *      IOW - we don't see it if we don't initialize our map
 *  - our token is also on our .../style.json and other requests
 *  - so here is what I infer
 *    - mapbox will create a new session once we init our map
 *    - if we background and foreground, the map should not re-init, and therefore that is NOT a new session
 *    - unless it has been 12 hours
 */
export class MapViewModel extends State<{}> {
    static inject = () => [
        Logger,
        ApMapboxStyler,
        MockAirspaceProvider,
        TrackingService,
        AttributionControl,
        BaseMapState,
        MapClickManager,
        MapViewModelConfig,
        EnvConfiguration,
        MapViewModelStorage,
        MapViewModelCompositePlugin,
        MissionPinAndRadiusOnMapView
    ];

    constructor(
        private logger: Logger,
        private styler: ApMapboxStyler,
        private mockAirspace: MockAirspaceProvider,
        private tracker: TrackingService,
        private attributionControl: AttributionControl,
        private baseMapProvider: BaseMapState,
        private clickManager: MapClickManager,
        private config: MapViewModelConfig,
        envConfig: EnvConfiguration,
        private storageV2: MapViewModelStorage,
        private compositePlugin: MapViewModelCompositePlugin,
        private missionPinAndRadiusView: MissionPinAndRadiusOnMapView
    ) {
        super({});
        (mapboxgl as any).accessToken = envConfig.MAPBOX_TOKEN;
    }

    isDisabled = () => {
        return this.config.DISABLE_MAP === true;
    };

    private map!: mapboxgl.Map & { pinchZoomWithoutPan?: PinchZoomWithoutPanHandler };
    private mapEl!: HTMLElement;
    permanentMapContainerEl!: HTMLElement;

    // mapbox does not support "long press" event out of the box, so simulate it ourselves
    private longPressTimeout: any;
    private handleMapLongPress = (handler: (e: IMapEvent) => void) => {
        this.map.on('touchstart', (e) => {
            clearTimeout(this.longPressTimeout);
            this.longPressTimeout = setTimeout(() => {
                handler({ eventType: 'longPress', lngLat: e.lngLat });
            }, 750);
        });
        ['click', 'touchend', 'touchcancel', 'touchmove', 'pointerdrag', 'pointermove', 'moveend', 'gesturestart', 'gesturechange', 'gestureend'].map(e => this.map.on(e, () => {
            clearTimeout(this.longPressTimeout);
        }));
    };
    private lastCalculatedMinZoom?: number;

    private updateMinZoom = () => {
        const minZoom = this.calculateMinZoom();
        if (this.lastCalculatedMinZoom !== minZoom){
            this.lastCalculatedMinZoom = minZoom;
            this.map.setMinZoom(minZoom);
        }
    };

    private calculateMinZoom = () => {
        const breakpoint = getBreakPoint();
        return breakPointsToMinZoom[breakpoint || 'xl'];
    };

    private _resolveIsInitializedPromise!: () => void;
    private _isInitializedPromise = new Promise<void>(resolve => {
        this._resolveIsInitializedPromise = resolve;
    });

    asyncInitialized = () => {
        return this._isInitializedPromise;
    };

    isIdle = false;

    waitForIdle = async () => {
        // they are asking because something might have changed so wait a tick just in case
        // map cannot see the change yet (to avoid false positive)
        await new Promise(resolve => setTimeout(resolve));

        while (!this.isIdle) {
            await new Promise(resolve => setTimeout(resolve, 100));
        }
    };

    // public attributes to enable consumer to provide handlers
    // (only need a single subscription for now)
    onClick?: (e: IMapEvent) => void;
    onLongPress?: (e: IMapEvent) => void;

    // offset map to account for drawer (which is bottom or side depending on screen size)
    private offsetMapForDrawer = () => {
        const sideDrawer = isLargerScreen();
        const verticalOffset = (sideDrawer ? 0 : SIDE_DRAWER_WIDTH);
        const horizontalOffset = (sideDrawer ? SIDE_DRAWER_WIDTH : 0);

        this.mapEl.style.top = `-${verticalOffset}px`;
        this.mapEl.style.bottom = '0';
        this.mapEl.style.right = `-${horizontalOffset}px`;
        this.mapEl.style.left = '0';
    };

    initMap = async (el: HTMLElement) => {
        this.permanentMapContainerEl = el;

        this.mapEl = document.createElement('div');
        this.permanentMapContainerEl.appendChild(this.mapEl);

        // NOTE: we are not styling in react on purpose
        // <BindMapViewModel/> is responsible for permanentMapContainerEl
        // and <ShowMap /> is responsible for our temporary container while you can see the map
        // MapViewModel (this) is responsible for all things mapbox, to include this div
        this.mapEl.id = 'ap-mapbox-view';
        this.mapEl.style.position = 'absolute';

        this.mapEl.style.zIndex = '0'; // so mapbox attribution doesn't show up on top of IonContent

        this.offsetMapForDrawer();

        // defaults for a new user
        let center = INITIAL_CENTER;
        let zoom = INITIAL_ZOOM;

        if (this.storageV2.centerAndZoom.exists()) {
            ({ center, zoom } = this.storageV2.centerAndZoom.get()!);
        }

        this.map = new mapboxgl.Map({
            container: this.mapEl,
            style: this.baseMapProvider.getCurrentBaseMapUrl(),

            center,
            zoom,

            dragRotate: false,
            touchPitch: false,
            scrollZoom: true, // defaults to { around: undefined }, zoom in on cursor (not map center)

            // this disables pinch to zoom and rotate, but we want ping to zoom,
            // so see map.touchZoomRotate.disableRotation below
            // touchZoomRotate: false

            maxZoom: 17, // at 17 we can see address numbers on buildings, not expecting it is worth allowing you to zoom past that
            minZoom: INITIAL_MIN_ZOOM, // this default gets immediately updated in ResizeObserver before map renders

            attributionControl: false // we add our own custom attribution control below
        });

        this.baseMapProvider.map = this.map;

        this.map.addControl(
            this.attributionControl,
            'bottom-left'
        );

        this.mockAirspace.map = this.map;

        this.map.on('render', () => {
            this.isIdle = false;
        });

        this.map.on('idle', () => {
            this.isIdle = true;
        });

        this.map.pinchZoomWithoutPan = new PinchZoomWithoutPanHandler(this.map);

        this.map.touchZoomRotate.disableRotation();

        // NOTE: long term this probably won't fly... eg map may never be loaded b/c offline or intermittent connection
        //   also this is going to delay app start
        //   then.. we will need to make nearly every call within MapViewModel await on this...
        //   which adds complexity, hence not doing it yet
        await new Promise((resolve => {
            const callback = () => {
                resolve(undefined);
                this.map.off('load', callback);
            };
            this.map.on('load', callback);
        }));

        new ResizeObserver(() => {
            this.offsetMapForDrawer();
            this.map.resize();
            this.updateMinZoom();
        }).observe(this.mapEl);

        await this.styler.style(this.map);

        let persistCenterAndZoomTimeoutId: any;

        const persistCenterAndZoom = () => {
            if (persistCenterAndZoomTimeoutId) {
                clearTimeout(persistCenterAndZoomTimeoutId);
            }

            persistCenterAndZoomTimeoutId = setTimeout(() => {
                clearTimeout(persistCenterAndZoomTimeoutId);
                this.storageV2.centerAndZoom.set({
                    zoom: this.map.getZoom(),
                    center: this.map.getCenter()
                });
            }, 1000);
        };

        let mapBeforeMove: { center: ICoordinate, zoom: number } | undefined;

        this.map.on('movestart', (e: any) => {
            // checking for originalEvent ensures this was a user-triggered event, not during map initialization
            if (e.originalEvent) {
                // save the state of the map before moving, so we can compare in the onMoveEndTrack event below
                mapBeforeMove = { center: this.map.getCenter(), zoom: this.map.getZoom() };
            }
        });

        this.map.on('moveend', (e: any) => {
            if (!this.isInitialCenter()) {
                persistCenterAndZoom();
            }

            // checking for originalEvent ensures this was a user-triggered event, not during map initialization
            if (e.originalEvent && mapBeforeMove) {
                // compare map before and after move to determine whether to send zoom/pan tracking events
                const mapAfterMove = { center: this.map.getCenter(), zoom: this.map.getZoom() };

                if (mapAfterMove.zoom !== mapBeforeMove.zoom) {
                    this.tracker.trackWithDebounce('Map Zoomed', () => ({
                        zoomBefore: round(mapBeforeMove!.zoom, 2),
                        zoomAfter: round(mapAfterMove.zoom, 2)
                    }));

                } else if (mapAfterMove.center.lat !== mapBeforeMove.center.lat || mapAfterMove.center.lng !== mapBeforeMove.center.lng) {
                    // check pinchZoomWithoutPan so we only send this event when NOT editing a mission (when editing, we send a "Center Set" event instead)
                    if (!this.map.pinchZoomWithoutPan?.isEnabled()) {
                        this.tracker.trackWithDebounce('Map Panned', () => ({
                            centerBefore: mapBeforeMove!.center,
                            centerAfter: mapAfterMove.center,
                            milesMoved: round(turf.distance([mapBeforeMove!.center.lng, mapBeforeMove!.center.lat], [mapAfterMove.center.lng, mapAfterMove.center.lat], { units: 'miles' }), 2)
                        }));
                    }
                }
                mapBeforeMove = undefined;
            }
        });

        this.map.on('zoomend', () => {
            if (!this.isInitialCenter()) {
                persistCenterAndZoom();
            }
        });

        this.handleMapLongPress((e) => {
            if (this.onLongPress) {
                this.onLongPress(e);
            }
        });

        // By default, mapbox zooms on double-click and fires one "click" event for either a single or a double click.
        // (A "dblclick" event is also documented, but is never fired from our map for unknown reasons.) We want to
        // retain the zoom behavior on double-click, but also drop a pin on single-click if the click is not part of a
        // double-click. We achieve this by disabling the built-in zoom on double-click, which causes mapbox to fire a
        // "click" event on every click (regardless of the click speed). We then pass all these "click" events to our
        // MapClickManager class, which decides whether to interpret as single or double clicks, and we then implement
        // handlers for both below.

        this.map.doubleClickZoom.disable();

        this.map.on('click', this.clickManager.createHandler({
            onSingleClick: e => {
                // trigger handler for dropping a pin, which is provided by MapPage
                if (this.onClick) {
                    this.onClick(e);
                }
            },
            onDoubleClick: () => {
                // increase zoom by 1, animating the map to the new zoom
                // (this does not fire the "moveEnd" event, so we need to invoke tracker)
                const zoomBefore = round(this.map.getZoom(), 2);
                const zoomAfter = Math.min(zoomBefore + 1, this.map.getMaxZoom());
                if (zoomBefore !== zoomAfter) {
                    this.map.zoomTo(zoomAfter);
                    this.tracker.trackWithDebounce('Map Zoomed', () => ({ zoomBefore, zoomAfter, doubleTapped: true }));
                }
            }
        }));

        this.map.on('error', ({ error = {} }: any) => {
            this.logger.error(new Error(`Mapbox error: ${error.message}`), error);
        });

        (window as any).mapVM = this;

        this.compositePlugin.init(this, this.map);

        this._resolveIsInitializedPromise();

        this.missionPinAndRadiusView.init(this.map, this);
    };

    flyTo = async ({ center, zoom, duration = 500 }: { center: ICoordinate, zoom: number, duration?: number }) => {
        this.map.flyTo({ center, zoom, curve: 1, duration });

        // allow time for animation to complete
        await new Promise(res => setTimeout(res, duration));

        // set center/zoom again (in case animation didn't fully complete)
        this.setCenter(center);
        this.setZoom(zoom);
    };

    setCenter = (center: ICoordinate) => {
        this.map.setCenter(center);
        return this;
    };

    getCenter = () : ICoordinate => {
        return this.map.getCenter();
    };

    isInitialCenter = (): boolean => {
        return JSON.stringify(this.getCenter()) === JSON.stringify(INITIAL_CENTER);
    };

    getZoom = () => {
        return this.map.getZoom();
    };

    setZoom = (zoom: number) => {
        this.map.setZoom(zoom);
        return this;
    };

    allowPanWhilePinchZooming = (allowed: boolean) => {
        if (!allowed){
            // switch to a custom pinch, that doesn't pan
            // b/c we don't want the center to move while zooming in and out
            // when the user might not mean to be moving the center

            this.map.touchZoomRotate.disable();
            this.map.pinchZoomWithoutPan!.enable({ });
            // even though we enable dragPan below, we don't disable it here
            // pinchZoomWithoutPan does that on its own internally

            // for desktop
            this.map.scrollZoom.disable();
            this.map.scrollZoom.enable({ around: 'center' }); // zoom in on map center, not cursor

        } else {
            // switch back to normal pinch so they can more easily navigate the map, panning while zooming
            this.map.pinchZoomWithoutPan!.disable();

            // pinch zoom toggles dragPan and so to reduce FUD that it might have left it disabled, lets enable it
            this.map.dragPan.enable();

            this.map.touchZoomRotate.enable();
            this.map.touchZoomRotate.disableRotation();

            // for desktop
            this.map.scrollZoom.disable();
            this.map.scrollZoom.enable({ around: undefined }); // zoom in on cursor, not map center
        }
    };

    showMapInDomContainer = async (temporaryParentContainer: HTMLElement) => {
        temporaryParentContainer.appendChild(this.mapEl);
    };

    removeMapFromDomContainer = () => {
        this.permanentMapContainerEl.appendChild(this.mapEl);
    };

    setLogicalLayerVisibility = (logicalLayer: IApMapboxStylerLogicalLayer, visibility: IApMapboxStylerLogicalLayerVisibility) => {
        this.styler.setLogicalLayerVisibility(logicalLayer, visibility, this.map);
    };

    /**
     * there may or may not be a radius in the "source" but we can hide / show the layer anyway
     *
     * that way if one is added to the source, it will show / hide consistent with the toggle
     */
    hideRadiusFill = () => {
        this.styler.hideRadiusFill({ map: this.map });
    };

    /**
     * there may or may not be a radius in the "source" but we can hide / show the layer anyway
     *
     * that way if one is added to the source, it will show / hide consistent with the toggle
     */
    showRadiusFill = () => {
        this.styler.showRadiusFill({ map: this.map });
    };

    /**
     * This is only used by tests, to encapsulate knowledge of which layers to get. This encapsulates a lot
     * Eg a single source may have multiple layers (eg for fill and line)
     *   but even that then needs to be multipled by `mock:${source}` and `errata:${source}` to get them all.
     *
     * This intentionally isn't exposing Mapbox filtering as it is less intutive than simply accessing the MapboxGeoJSONFeature[].
     *
     * But in tests, we should do the filtering in SUT, to avoid streaming a ton of weather over the webdriver socker
     *  do this
     *      const features = await browser.execute(() => IOC.get('MapViewModel').getRenderedFeatures().filter(...))
     *  not this
     *      const features = await browser.execute(() => IOC.get('MapViewModel').getRenderedFeatures()
     *      features.filter(...)
     *
     * This has documented quirks of Mapbox GL JS queryRenderedFeatures, of note
     *  - you can get back more than one feature where you might expect one
     *   - because it was split by mapbox's internal tiling system
     *   - because it is in more than one layer
     *  - the features you get back may be cropped
     *   - because it was split by mapbox's internal tiling tiling system
     */
    getRenderedFeatures = (): mapboxgl.MapboxGeoJSONFeature[] => {
        // TODO: why doesn't mapbox know its own type?
        return this.map.queryRenderedFeatures({ layers: this.styler.getAllLayerIds() } as any);
    };
}
