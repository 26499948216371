export interface IStoreConfig {
    installURL: string;
    subscribeURL: string;
}

export class StoreConfigs {

    static ios: IStoreConfig = {
        installURL: 'https://apps.apple.com/us/app/autopylot-for-drones/id1612739014',
        subscribeURL: 'https://apps.apple.com/account/subscriptions',
    };

    static android: IStoreConfig = {
        installURL: 'https://play.google.com/store/apps/details?id=io.autopylot.app_v2',
        subscribeURL: 'https://play.google.com/store/account/subscriptions?package=io.autopylot.app_v2',
    };
};
