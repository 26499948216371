import { IAggregateLAANCStatus, ICanFly } from '@autopylot-internal/tiles-client';
import { Badge } from '../../components/badge/Badge';
import { Color } from '../../components/common';
import { IonText } from '@ionic/react';

export const FlightStatusBadge = ({ canFly, laancStatus }: { canFly: ICanFly, laancStatus: IAggregateLAANCStatus | undefined }) => {

    let color: Color = 'danger';
    let heading = 'Do Not Fly';

    if (canFly === 'can-fly'){
        heading = (laancStatus === 'authorized' ? 'LAANC Authorized' : 'Clear to Fly');
        color = 'success';
    } else if (canFly === 'can-fly-with-laanc-authorization'){
        heading = 'LAANC Pending';
        color = 'warning';
    }

    return (
        <Badge color={color}>
            <IonText color={color}>
                <span data-type="flight-status">{heading}</span>
            </IonText>
        </Badge>
    );
};
