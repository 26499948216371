import { useEffect, useState } from 'react';
import { IonItem, IonModal } from '@ionic/react';
import { Field } from '../components/field/Field';
import { TextInput } from '../components/field/TextInput';
import { PageHeader } from '../components/page/PageHeader';

export interface SaveChecklistDialogProps {
    isOpen: boolean;
    onSave: ({ name }: { name: string }) => Promise<void>;
    onCancel: () => void;
    title?: string;
    initialName?: string;
}

export const SaveChecklistDialog: React.FC<SaveChecklistDialogProps> = ({ isOpen, onSave, onCancel, title = 'Save Checklist', initialName = '' }) => {

    const [name, setName] = useState(initialName);

    useEffect(() => {
        setName(initialName);
    }, [initialName, isOpen]);

    const handleSave = async () => {
        if (name) {
            await onSave({ name });
        }
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        await handleSave();
    };

    return (
        <IonModal isOpen={isOpen}>
            <PageHeader title={title} backLabel="Cancel" onBack={onCancel} actionLabel="Save" onAction={name ? handleSave : undefined} />
            <IonItem lines="none" style={{paddingTop: 16}}>
                <form onSubmit={handleSubmit} style={{width: '100%'}}>
                    <Field dataSet={{type: 'checklist-name'}} label="Checklist Name">
                        <TextInput value={name} onChange={setName} autofocus />
                    </Field>
                </form>
            </IonItem>
        </IonModal>
    );
};
