import moment from 'moment';
import { APIClient } from '@autopylot-internal/autopylot-api-client';
import { State } from '@meraki-internal/state';
import { IInsurancePolicy } from './IInsurancePolicy';
import { getLiabilityLimit, getTimezonesByState } from './policyHelpers';
import { AutoPylotPrincipal } from '../auth/AutoPylotPrincipal';
import { DevSettings } from '../support/DevSettings';

export interface IInsuranceState {
    loaded: boolean; // has loaded at least once?
    loading: boolean; // is currently loading?
    error?: any;
    secretApplicantId?: string;
    policies?: IInsurancePolicy[];
    hasPendingApplication?: boolean;
    supportedStates?: string[];
}

export class InsuranceState extends State<IInsuranceState> {

    static inject = () => [APIClient, AutoPylotPrincipal, DevSettings];
    constructor(private apiClient: APIClient, private principal: AutoPylotPrincipal, private devSettings: DevSettings) {
        super({
            loaded: false,
            loading: false
        });
    }

    load = async ({ refreshing }: { refreshing: boolean } = { refreshing: false }) => {
        try {
            this.setState({
                loading: true,
                error: undefined
            });
            const entry = await this.apiClient.entry();
            const insurance = await this.apiClient.get(entry.links.insurance);
            this.setState({
                ...insurance,
                loaded: true,
                loading: false
            });
        } catch (error: any) {
            error.message = `Failed to load insurance: ${error.message}`;
            this.setState({
                loading: false,
                error
            });
            throw error;
        }
    };

    getSupportedStates = () => {
        if (!this.state.loaded) {
            throw new Error('must call load() first');
        }
        return this.state.supportedStates!;
    };

    getSecretApplicantId = () => {
        if (!this.state.loaded) {
            throw new Error('must call load() first');
        }
        return this.state.secretApplicantId!;
    };

    hasPendingApplication = () => {
        if (!this.state.loaded) {
            throw new Error('must call load() first');
        }
        return this.state.hasPendingApplication!;
    };

    getPolicies = () => {
        if (!this.state.loaded) {
            throw new Error('must call load() first');
        }
        return this.state.policies!;
    };

    getPolicyById = (id: string) => {
        if (!this.state.loaded) {
            throw new Error('must call load() first');
        }
        return this.state.policies!.find(p => p.id === id);
    };

    getActivePolicy = ({ startTime, endTime }: { startTime: string, endTime: string }) => {
        if (!this.state.loaded) {
            throw new Error('must call load() first');
        }

        // if pilot has more than one active policy, prioritize by:
        // 1. pending (non-pending first)
        // 2. liability (highest first)
        // 3. end date (furthest out first)
        const prioritizedPolicies = [...this.state.policies!];
        prioritizedPolicies.sort((p1, p2) => {
            if (p1.pending && !p2.pending) {
                return -1;
            }
            if (!p1.pending && p2.pending) {
                return 1;
            }
            const l1 = getLiabilityLimit(p1);
            const l2 = getLiabilityLimit(p2);
            if (l1 < l2) {
                return -1;
            }
            if (l1 > l2) {
                return 1;
            }
            return p1.end_date.localeCompare(p2.end_date);
        });

        // find a policy that is active between startTime and endTime for all timezones in its state
        // (this is as precise as we can be without the lat/lng from a verified address)
        const activePolicy = prioritizedPolicies?.find(policy => {
            const timezones = getTimezonesByState(policy.params.applicant_state);
            for (const tz of timezones) {
                const policyStart = moment.tz(policy.start_date, tz).add(1, 'minute');
                const policyEnd = moment.tz(policy.end_date, tz).add(1, 'minute');
                if (moment(startTime).isBefore(policyStart) || moment(endTime).isAfter(policyEnd)) {
                    return false;
                }
            }
            return true;
        });

        return activePolicy;
    };
}
