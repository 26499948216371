import { useInstance } from '@meraki-internal/react-dependency-injection';
import { AlertPresenter } from '../../app/AlertBinder';
import { useEffect, useState, useRef } from 'react';
import ReactQuill from 'react-quill';
import { useKeyboardState } from '@ionic/react-hooks/keyboard';
import Quill from 'quill';
import { Page } from '../../components/page/Page';
import { HistoryViewModel } from '../../app/HistoryViewModel';
import { EditableMissionState } from '../model/EditableMissionState';
import { useSubscription } from '@meraki-internal/state';
import { PlainClipboard } from './PlainClipboard';
import { HtmlScrubber } from './HtmlScrubber';
import { MissionNotesEventsTracker } from './MissionNotesEventsTracker';

// at some point we may want to copy quill.snow.css into MissionNotes.css and keep the parts we want
// so we don't have to reason about both style sheets
import 'react-quill/dist/quill.snow.css';
import './MissionNotes.css';
import { WrapWithCardOnLargerScreen } from '../../components/card/WrapWithCardOnLargerScreen';

const betterError = (error: any) => {
    if (error && error.displayMessage) {
        // this intentionally is trying to improve the message with minimal assumptions
        // eg, if limit changes from 10000 to 10001, it won't be formatted as nicely, but
        // it will still work
        error.displayMessage = error.displayMessage
            .replace('Notes html: String', 'Notes')
            .replace('10000', '10,000')
            .replace('character(s)', 'characters');
    }

    return error;
};

Quill.register('modules/clipboard', PlainClipboard, true);

export const MissionNotes: React.FC<{ mission: EditableMissionState }> = ({ mission }) => {
    const history = useInstance(HistoryViewModel);
    const scrubber = useInstance(HtmlScrubber);

    useSubscription(() => mission);

    const [notesHtml, setNotesHtml] = useState<string>();
    const { isOpen } = useKeyboardState();
    const alert = useInstance(AlertPresenter);
    const tracking = useInstance(MissionNotesEventsTracker);

    const quillRef = useRef<ReactQuill & { apCanBlur?: boolean }>(null);

    useEffect(() => {
        if (quillRef.current) {
            // on selection change is called when quill is first focused (and subsequently)
            // so flag that we don't want to allow blur once it has gained focus
            quillRef.current.editor?.on('selection-change', () => {
                if (quillRef.current) {
                    quillRef.current.apCanBlur = false;
                }
            });

            // when trying to blur, refocus (don't allow blur) unless it has been flagged to allow
            // (by saving it)
            quillRef.current.editor?.root.addEventListener('focusout', (e: any) => {
                if (quillRef.current) {
                    if (!quillRef.current.apCanBlur){
                    // need to allow the focusout to finish, then can focus
                    // ideally we'd prevent the blur instead but I wasn't able to get that to work
                        setTimeout(() => {
                            if (quillRef.current) {
                                quillRef.current.focus();
                            }
                        });
                    }
                }
            });

            if (!mission.state.notesHtml) {
                setTimeout(() => {
                    if (quillRef.current) {
                        quillRef.current.focus();
                    }
                }, 100); // need to give quill a chance to render before we focus it
            }
        }
    // react thinks first dep should be simply quillRef, but this doesn't trigger useEffect when quillRef.current changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quillRef?.current, mission]);

    useEffect(() => {
        setNotesHtml(mission.state.notesHtml || '');
    }, [mission]);

    if (notesHtml === undefined) {
        return null;
    }

    const isDirty = notesHtml !== (mission.state.notesHtml || '');

    const onSave = isDirty ? async () => {
        try {
            tracking.track({ oldNotesHtml: mission.state.notesHtml || '', newNotesHtml: notesHtml });
            await mission.saveNotesHtml(notesHtml);

            if (quillRef.current){
                quillRef.current.apCanBlur = true;
                quillRef.current.blur();
            }
        }
        catch (_error) {
            alert.showAndLogError(betterError(_error));
        }

    } : undefined;

    const modules = {
        toolbar: [
            // [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline'],
            //,'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            // [{'indent': '-1'}, {'indent': '+1'}],
            // ['link'], // the UX for link editing isn't good enough on Mobile (https://merakisolutionsinc.slack.com/archives/C02S2G6H3FZ/p1667241659801999?thread_ts=1667241539.052649&cid=C02S2G6H3FZ)
            // ['image'] // embeds an inline image, I didn't test on device, but likely works fine... just need a store for bigger notes payload
            // ['clean'] // "removes the formatting button" ... I couldn't figure out what this does
        ]
    };

    const onBack = () => {
        if (!isDirty){
            history.back(mission.pages.details.path());
        } else {
            setNotesHtml(mission.state.notesHtml || '');

            if (quillRef.current){
                quillRef.current.apCanBlur = true;
                quillRef.current.blur();
            }
        }
    };

    return (
        <Page title="Mission Notes" onBack={onBack} backLabel={isDirty ? 'Cancel' : undefined} actionLabel="Save" onAction={onSave}>
            <div className={isOpen ? 'keyboard-open' : 'keyboard-closed'}>
                <WrapWithCardOnLargerScreen>
                    <ReactQuill
                        theme="snow"
                        ref={quillRef}
                        value={notesHtml}
                        onChange={changedNotesHtml => {
                            setNotesHtml(scrubber.scrub(changedNotesHtml));
                        }}
                        placeholder="Add notes to your mission!"
                        modules={modules}
                    />
                </WrapWithCardOnLargerScreen>
            </div>
        </Page>
    );
};
