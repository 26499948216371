import { IonCard, IonCardContent } from '@ionic/react';
import { NewMissionButton } from './NewMissionButton';

export const NoMissionsYetCard = () => {
    return (
        <IonCard>
            <IonCardContent>
                <h2 style={{marginBottom: 20}}>
                    Looks like you don't have any upcoming missions - let's create one!
                </h2>

                <NewMissionButton />
            </IonCardContent>
        </IonCard>
    );
};
