import moment from 'moment';
import suncalc from 'suncalc';
import { IHourlyForecast } from './model/IHourlyForecast';
import { formatLocalTime } from '../support/helpers/format';
import { ISummaryForecast } from './model/ISummaryForecast';

export class SummaryForecastBuilder {

    private getConditionDescription = ({ conditionCode, isDaylight }: { conditionCode: any, isDaylight: boolean }) => {
        // add spaces between words
        let description = conditionCode.replace(/([a-z](?=[A-Z]))/g, '$1 ');
        // during the day, show "sunny" instead of "clear"
        if (isDaylight) {
            description = description.replace('Clear', 'Sunny');
        }
        return description;
    };

    build = (forecast?: IHourlyForecast): ISummaryForecast | undefined => {
        if (!forecast) {
            return undefined;
        }

        const conditionDescription = this.getConditionDescription(forecast);

        const { lat, lng } = forecast.location;

        const times = suncalc.getTimes(moment(forecast.forecastStart).toDate(), lat, lng);
        const sunrise = formatLocalTime({ time: moment(times.sunrise).toISOString(), location: {lat, lng} });
        const sunset = formatLocalTime({ time: moment(times.sunset).toISOString(), location: {lat, lng} });

        return {
            ...forecast,
            conditionDescription,
            sunrise,
            sunset
        };
    };
}
