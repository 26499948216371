import { IAdvisoryPropertiesV3, IRecreationalFixedFlyerSiteProperties } from '@autopylot-internal/tiles-client';
import { SecondaryWarningAdvisory } from '../SecondaryAdvisory';


const buildBetterClubFinderUrl = (props: any): string => {
    const query = new URLSearchParams();
    query.set('city', props.CITY);
    query.set('state', props.STATE);

    // this is tightly coupled to the distances supported by the form we're prefilling
    query.set('distance[distance][from]', String(17.02686));

    query.set('distance[value]', `${props.LATITUDE},${props.LONGITUDE}`);
    return `https://www.modelaircraft.org/club-finder?${query.toString()}`;
};

export const RecreationalFlyerFixedSitesAdvisory = (props: (IRecreationalFixedFlyerSiteProperties & IAdvisoryPropertiesV3)) => {


    let poc = props.POC || '';
    const isPOCALink = poc.startsWith('http');
    if (isPOCALink && poc.startsWith('https://www.modelaircraft.org/club-finder')){
        poc = buildBetterClubFinderUrl(props);
    }

    return (
        <SecondaryWarningAdvisory header="Recreational Flyer Site" advisoryType={props.advisoryType} GLOBAL_ID={props.GLOBAL_ID}>
            <>
                <p>
                    Your mission overlaps <strong>{props.SITE_NAME}</strong>.
                    Flight operations at a Recreational Flyer Fixed Site within controlled airspace must adhere
                    to the site’s operating limitations and safety guidelines, which are available from the fixed
                    site sponsor.
                </p>

                {!isPOCALink && (
                    <p>
                        The POC for this airspace is <strong>{poc}</strong>.
                    </p>
                )}
                {isPOCALink && (
                    <p>
                        To find the POC <a href={poc} target='_blank'>check here</a>.
                    </p>
                )}

                <p>
                    <a href="https://www.faa.gov/uas/recreational_flyers" target="_blank">Recreational Flyers & Community-Based Organizations</a>
                </p>
            </>
        </SecondaryWarningAdvisory>
    );
};
