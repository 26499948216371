import { Device } from './Device';

export interface IDisplayMetrics {
    screen: { width: number; height: number; } // height * devicePixelRatio can still be off by a few due to rounding
    viewport: { width: number; height: number; }
    devicePixelRatio: number;
    statusBarHeight: number;
    navigationBarHeight: number;
}

export interface IStatusBarHeightProvider {
    getHeight: () => number;
}

export class StatusBarHeightProviderPlaceholder implements IStatusBarHeightProvider  {
    constructor(){
        throw new Error('ioc HeightProviderPlaceholder should have been swapped out for DeviceStatusBar');
    }
    getHeight = () => {
        throw new Error('not implemented');
    };
}

/**
 * We may find we want to do some caching, but I expect we don't use this method enough to warrant that.
 * I didn't cache because we want this metrics to uncover bad assumptions. Eg our app is made smaller
 * by another app and os feature, we don't want to send the wrong metric (by computing only at parse  or app start time);
 */
export class DisplayMetricsProvider {
    static inject = () => [Device, StatusBarHeightProviderPlaceholder];

    constructor(private device: Device, private statusBar: StatusBarHeightProviderPlaceholder){}

    getMetrics = (): IDisplayMetrics => {
        return {
            screen: {
                width: window.screen.width,
                height: window.screen.height,
            },
            viewport: {
                width: window.innerWidth,
                height: window.innerHeight
            },
            devicePixelRatio: window.devicePixelRatio,
            statusBarHeight: this.statusBar.getHeight(),
            navigationBarHeight: this.device.platform === 'android' ? window.screen.height - window.innerHeight : 0
        };
    };

}
