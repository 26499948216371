interface  UserFacingErrorProps{
    displayMessage: string;
    message?: string;
    errorCode?: string;
    displayHeader?: string;
    okLabel?: string;
    links?: { label: string; href: string;   }[];
    status?: number;
}

export class UserFacingError extends Error {
    constructor({ displayMessage, message, errorCode, displayHeader, okLabel, links = [], status }: UserFacingErrorProps){
        super(message || displayMessage);
        this.displayMessage = displayMessage;
        this.errorCode = errorCode;
        this.displayHeader = displayHeader;
        this.okLabel = okLabel;
        this.links = links;
        this.status = status;
    }
    errorType = 'UserFacingError';
    displayMessage: any;
    errorCode?: string;
    displayHeader?: string;
    okLabel?: string;
    links: { label: string; href: string; }[];
    status?: number;
}
