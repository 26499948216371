import { IonText } from '@ionic/react';

export const DrawerLabel: React.FC<{text: string, disabled?: boolean}> = ({text, disabled}) => {

    const color = disabled ? 'rgba(var(--ion-color-medium-rgb), 0.5)' : 'var(--ion-color-medium)';

    return (
        <IonText style={{color, fontSize: 10, textTransform: 'uppercase', fontWeight: 'bold'}}>
            {text}
        </IonText>
    );
};
