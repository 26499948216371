import { ICoordinate } from '@autopylot-internal/tiles-client';
import { WeatherService } from './WeatherService';
import { IDailyForecast } from './model/IDailyForecast';
import { IHourlyForecast } from './model/IHourlyForecast';
import { ISummaryForecast } from './model/ISummaryForecast';
import moment from 'moment';
import { Logger } from '../support/debug/Logger';
import { LocationKeyBuilder } from './LocationKeyBuilder';
import { SummaryForecastBuilder } from './SummaryForecastBuilder';

type ISO8601 = string;

interface IForecastProps {
    link: { href: string; };
    startTime: ISO8601;
    location: ICoordinate;
    endTime: ISO8601;
}

export class CachingWeatherService {
    static inject = () => [WeatherService, LocationKeyBuilder, SummaryForecastBuilder, Logger];
    constructor(private weatherService: WeatherService, private locationKeyBuilder: LocationKeyBuilder, private summaryForecastBuilder: SummaryForecastBuilder, private log: Logger){}

    // return true if locations close enough (and in same tz) that we don't need to fetch new weather
    private isSameWeatherLocation = (oldLocation?: ICoordinate, newLocation?: ICoordinate): boolean => {
        if (!oldLocation || !newLocation) {
            return false;
        }

        return this.locationKeyBuilder.build(oldLocation) === this.locationKeyBuilder.build(newLocation);
    };

    private sameTime = (time1: { startTime: ISO8601; endTime: ISO8601; }, time2?: { startTime: ISO8601; endTime: ISO8601; }): boolean => {
        if (!time1 || !time2){
            return false;
        }

        return time1.startTime === time2.startTime && time1.endTime === time2.endTime;
    };

    private cachedDailyForecast?: {
        weatherFor: {
            startTime: ISO8601;
            endTime: ISO8601;
            location: ICoordinate;
        }
        forecast: IDailyForecast[];
    };

    getDailyForecast = async ({ link, startTime, endTime, location }: IForecastProps): Promise<IDailyForecast[]> => {
        if (this.isSameWeatherLocation(location, this.cachedDailyForecast?.weatherFor.location) && this.sameTime({ startTime, endTime}, this.cachedDailyForecast?.weatherFor)){
            return this.cachedDailyForecast!.forecast;
        }

        const forecast = await this.weatherService.getDailyForecastV2({ link, startTime, endTime, location });
        this.cachedDailyForecast = {
            weatherFor: {
                startTime, endTime, location
            },
            forecast
        };
        return forecast;
    };

    private cachedHourlyForecast?: {
        weatherFor: {
            startTime: ISO8601;
            endTime: ISO8601;
            location: ICoordinate;
        }
        forecast: IHourlyForecast[];
    };

    private cachedHourlyForecastPromise?: {
        weatherFor: {
            startTime: ISO8601;
            endTime: ISO8601;
            location: ICoordinate;
        }
        promise: Promise<IHourlyForecast[]>;
    };

    getHourlyForecast = async ({ link, startTime, endTime, location }: IForecastProps): Promise<IHourlyForecast[]> => {

        if (this.isSameWeatherLocation(location, this.cachedHourlyForecast?.weatherFor.location) && this.sameTime({ startTime, endTime}, this.cachedHourlyForecast?.weatherFor)){
            return this.cachedHourlyForecast!.forecast;
        }

        if (this.isSameWeatherLocation(location, this.cachedHourlyForecastPromise?.weatherFor.location) && this.sameTime({ startTime, endTime }, this.cachedHourlyForecastPromise?.weatherFor)){
            return this.cachedHourlyForecastPromise!.promise;
        }

        this.cachedHourlyForecastPromise = {
            weatherFor: { location, startTime, endTime },
            promise: Promise.resolve().then(async () => {
                const forecast = await this.weatherService.getHourlyForecastV2({ link, startTime, endTime, location });
                this.cachedHourlyForecast = {
                    weatherFor: {
                        startTime, endTime, location
                    },
                    forecast
                };
                return forecast;
            })
        };

        this.cachedHourlyForecastPromise.promise.then(() => {
            this.cachedHourlyForecastPromise = undefined;
        }, () => {
            this.cachedHourlyForecastPromise = undefined;
        });

        return this.cachedHourlyForecastPromise.promise;
    };

    getSummaryForecast = async ({ link, location, startTime, endTime }: IForecastProps): Promise<ISummaryForecast> => {
        const hourly = await this.getHourlyForecast({ link, location, startTime, endTime });
        const summaryTarget = moment(startTime).startOf('hour').toISOString().replace('.000', '');
        let summaryHour = hourly.find(h =>  h.forecastStart === summaryTarget);
        if (!summaryHour){
            this.log.error(`could not find summary hour so failed over to first hour.`, { props: {link, location, startTime, endTime}, summaryTarget, hours: hourly.map(h => h.forecastStart).join(', ') });
            summaryHour = hourly[0];
        }

        return this.summaryForecastBuilder.build(summaryHour)!;
    };
};
