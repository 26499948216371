import { IPartTimeNSUFRAdvisoryProperties, MissionFeatureCollection } from '@autopylot-internal/tiles-client';
import { NoWrap } from '../../components/typography/NoWrap';
import { formatLocalDayAndTime } from '../../support/helpers/format';
import { SecondaryWarningAdvisory } from '../SecondaryAdvisory';

export const PartTimeNSUFRAdvisory = (props: IPartTimeNSUFRAdvisoryProperties & { mission: MissionFeatureCollection } ) => {
    return (
        <SecondaryWarningAdvisory header="Part Time National Security Warning" advisoryType={props.advisoryType} GLOBAL_ID={props.GLOBAL_ID}>
            <>
                Flights are strictly prohibited in National Security Restricted Flights Zones. Your mission overlaps <strong>{props.Base || props.Facility}</strong>. This restriction applies from <strong><NoWrap>{formatLocalDayAndTime({ time: props.ACTIVETIME, location: props.mission.getMissionCenter() })}</NoWrap> to <NoWrap>{formatLocalDayAndTime({ time: props.ENDTIME, location: props.mission.getMissionCenter() })}</NoWrap></strong>. The POC for this airspace is <strong>{props.POC}</strong>.
            </>
        </SecondaryWarningAdvisory>
    );
};
