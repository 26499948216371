import { StorageProvider } from '../support/StorageProvider';

export interface ISavedState {
    enjoyFirstMissionShown?: string;
}

export class FeedbackAlertStorage {
    static inject = () => [StorageProvider];
    constructor(private storage: StorageProvider){}
    feedbackAlerts = this.storage.getJSONProvider<ISavedState>('ap:feedback-alerts', { storageType: 'localStorage' });
}
