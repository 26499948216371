import { Map } from 'mapbox-gl';
import { IMapViewModelPlugin } from './IMapViewModelPlugin';
import { MapViewModel } from './MapViewModel';
import { GPSPositionPlugin } from '../location/GPSPositionPlugin';

export class MapViewModelCompositePlugin implements IMapViewModelPlugin {
    static inject = () => [GPSPositionPlugin];
    constructor(...plugins: IMapViewModelPlugin[]){
        this.plugins = plugins;
    }

    private plugins: IMapViewModelPlugin[];

    init = (mapViewModel: MapViewModel, map: Map) => {
        for (const plugin of this.plugins){
            plugin.init(mapViewModel, map);
        }
    };

}
