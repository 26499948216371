import { IonInput } from '@ionic/react';
import { FieldInputProps } from './Field';
import moment from 'moment';

export interface DateFieldProps extends FieldInputProps {
    value?: string;
    onChange?: (value?: string) => void;
}

export const DateInput: React.FC<DateFieldProps> = ({ readonly, bordered, required, value, onChange }) => {

    const inputType = (readonly && !value ? undefined : 'date');
    const inputStyle = bordered ? { maxHeight: 35 } : undefined;

    const dateValue = value ? moment(value).format('YYYY-MM-DD') : '';

    const handleChange = (e: any) => {
        if (onChange && !readonly) {
            const date = e.detail.value ? moment(e.detail.value).toISOString() : undefined;
            onChange(date);
        }
    };

    return (
        <IonInput className="smartlook-show" style={inputStyle} placeholder="" value={dateValue} type={inputType} onIonChange={handleChange} readonly={readonly} required={required} />
    );
};
