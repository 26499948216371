import { locationOutline, compassSharp } from 'ionicons/icons';
import { useState } from 'react';
import { Group } from '../ComponentLibrary';
import { ActionMenu } from './ActionMenu';

const DemoActionMenu: Group & React.FC = () => {
    const [isOpen, setIsOpen] = useState(true);
    return (
        <ActionMenu
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            actions={[
                {
                    text: 'Directions',
                    icon: compassSharp,
                    handler: () => {
                        alert(`Directions`);
                    }
                },
                {
                    text: 'View Mission on Map',
                    icon: locationOutline,
                    handler: () => {
                        alert(`View Mission on Map`);
                    }
                }
            ]}
        />
    );
};
DemoActionMenu.componentName = 'ActionMenu';

export default DemoActionMenu;
