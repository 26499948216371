import moment from 'moment';
import { useIonPicker } from '@ionic/react';
import { useBreakpoints } from '../../support/hooks/useBreakpoints';
import { DownChevronIcon } from '../../components/icons/Icons';
import { formatLocalDate, formatLocalTime, getTimezone } from '../../support/helpers/format';
import { ICoordinate } from '../../support/model/ICoordinate';
import { Button } from '../button/Button';

// build a list of dates with mission date selected
const buildDateList = (selectedValue: string, fromMoment: any, toMoment: any) => {
    const options: {text: string, value: string, selected: boolean}[] = [];
    while(!fromMoment.isAfter(toMoment)) {
        const text = fromMoment.format('ddd MMM, D');
        const value = fromMoment.format('YYYY-MM-DD');
        options.push({ text, value, selected: value === selectedValue });
        fromMoment.add(1, 'd');
    }
    return options;
};

// build list of hours (1 - 12)
const buildHourList = (selectedValue: number) => {
    return Array.from(Array(12).keys()).map(i => {
        const hour = (i === 0 ? 12 : i);
        return { text: String(hour), value: hour, selected: selectedValue === hour };
    });
};

// build list of minutes in 5-minute increments (00 - 55)
const buildMinuteList = (selectedValue: number) => {
    const INCREMENT = 5;

    return Array.from(Array(12).keys()).map(i => {
        const minute = i * INCREMENT;

        // selectedValue should already be on an increment boundary, but if not, match with nearest
        const selected = selectedValue > (minute - INCREMENT/2) && selectedValue < (minute + INCREMENT/2);

        return { text: String(minute).padStart(2, '0'), value: minute, selected };
    });
};

// build AM/PM list
const buildAPList = (selectedValue: string) => {
    return [
        { text: 'AM', value: 'AM', selected: selectedValue === 'AM' },
        { text: 'PM', value: 'PM', selected: selectedValue === 'PM' }
    ];
};

const buildDateTimePicker = (dateTime: string, timezone: string, fromDate: string, toDate: string, animated: boolean, onChange: (value: string) => void) => {
    const localStartTime = moment(dateTime).tz(timezone);
    const fromMoment = moment(fromDate).tz(timezone).startOf('d');
    const toMoment = moment(toDate).tz(timezone).startOf('d');

    // these all need to be regenerated every time the picker is presented, or the UX glitches out
    const dateList = buildDateList(localStartTime.format('YYYY-MM-DD'), fromMoment, toMoment); // e.g. '2022-03-01'
    const hourList = buildHourList(Number(localStartTime.format('h'))); // 1 - 12
    const minuteList = buildMinuteList(localStartTime.minute()); // 0 - 55
    const apList = buildAPList(localStartTime.format('A')); // 'AM' or 'PM'

    return {
        columns: [
            {
                name: 'date',
                columnWidth: '160px',
                options: dateList,
                selectedIndex: dateList.findIndex(d => d.selected)
            },
            {
                name: 'hour',
                columnWidth: '30px',
                options: hourList,
                selectedIndex: hourList.findIndex(h => h.selected)
            },
            {
                name: 'min',
                columnWidth: '30px',
                options: minuteList,
                selectedIndex: minuteList.findIndex(m => m.selected)
            },
            {
                name: 'ap',
                columnWidth: '60px',
                options: apList,
                selectedIndex: apList.findIndex(ap => ap.selected)
            }
        ],
        buttons: [
            {
                text: 'Cancel',
                cssClass: 'picker-cancel'
            },
            {
                text: 'Done',
                cssClass: 'picker-done',
                handler: (selected: any) => {

                    // build string moment can parse in format "2022-03-20 16:30"
                    const { date, hour, min, ap } = selected;
                    const h0 = hour.value === 12 ? 0 : hour.value;
                    const hour24 = String(h0 + (ap.value === 'PM' ? 12 : 0)).padStart(2, '0');
                    const dateTimeString = `${date.value} ${hour24}:${min.text}`;

                    const result = moment.tz(dateTimeString, timezone);
                    onChange(result.toISOString());
                }
            }
        ],
        animated
    };
};

export const DateTimePicker: React.FC<{ dateTime: string, location: ICoordinate, fromDate?: string, toDate?: string, onChange: (dateTime: string) => void, disabled?: boolean }> = ({ dateTime, location, fromDate, toDate, onChange, disabled }) => {
    const [present] = useIonPicker();

    const { isLargerScreen } = useBreakpoints();

    if (!fromDate) {
        fromDate = moment().subtract(6, 'm').startOf('d').toISOString();
    }

    if (!toDate) {
        toDate = moment().add(6, 'm').startOf('d').toISOString();
    }

    // make sure we show selected values based on the time zone of the location
    const timezone = getTimezone(location);

    return (
        <Button disabled={disabled} minWidth={0} fill="clear" dataSet={{type: 'date-time-picker', tz: timezone}} onClick={() => present(buildDateTimePicker(dateTime, timezone, fromDate!, toDate!, !isLargerScreen, onChange))}>
            <b>{formatLocalDate({ date: dateTime, location })} {formatLocalTime({ time: dateTime, location, withTimeZone: true })}</b><DownChevronIcon slot="end" size="small" />
        </Button>
    );
};
