import { createUseStyles } from 'react-jss';
import { IonButton, IonText } from '@ionic/react';
import { Badge } from '../components/badge/Badge';
import { CheckmarkIcon } from '../components/icons/Icons';

type IStyleKeys = 'button';

interface IStyleProps {
    isActive?: boolean;
}

const useStyles = createUseStyles<IStyleKeys, IStyleProps>({
    button: {
        height: 'unset',
        textAlign: 'left',
        borderRadius: 10,
        boxShadow: '0 4px 16px rgba(0, 0, 0, 0.12)',
        '--background-activated-opacity': 0.2,
        '--border-color': ({isActive}) => isActive ? 'primary' : 'var(--ion-color-step-150)',
        '--padding-start': '20px',
        '--padding-end': '20px',
        '--padding-top': '20px',
        '--padding-bottom': '20px',
        '&:hover': {
            opacity: 1
        },
        '&::part(native)': {
            borderWidth: 2
        }
    }
});

export const PaymentTermButton: React.FC<{ disabled: boolean; isActive: boolean; onClick: () => void; termLabel: string; termDescription: string; discountBadge?: string; }> = ({ disabled, isActive, onClick, termLabel, termDescription, discountBadge }) => {
    const classes = useStyles({ isActive });
    return (
        <div style={{marginBottom: 15}}>
            <IonButton
                disabled={disabled}
                expand="block"
                fill="outline"
                className={`ion-text-wrap ${classes.button}`}
                onClick={onClick}
            >
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 10, width: '100%', marginRight: 20}}>
                    {discountBadge && <div style={{marginTop: -10, marginLeft: -5}}><Badge size="small" color="success">{discountBadge}</Badge></div>}
                    <IonText style={{color: 'var(--ion-text-color)', fontSize: 18}}><b>{termLabel}</b></IonText>
                    <IonText color="medium" style={{fontSize: 14}}><b>{termDescription}</b></IonText>
                </div>

                <div slot="end" style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexShrink: 0,
                    width: 32, height: 32, borderRadius: '50%',
                    backgroundColor: isActive ? 'var(--ion-color-primary)' : 'transparent',
                }}>
                    {isActive && (
                        <CheckmarkIcon color="light" size="small" strokeWidth={100} />
                    )}
                </div>
            </IonButton>
        </div>
    );
};
