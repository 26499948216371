import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useEffect } from 'react';
import { EditableMissionState } from '../../mission/model/EditableMissionState';
import { WeatherError } from '../components/WeatherError';
import { ShowWindyMap } from './ShowWindyMap';
import { WindyMapViewModel } from './WindyMapViewModel';

export const WindyMissionMapPreview: React.FC<{ mission: EditableMissionState, error: any, setError: (e: any) => void}> = ({ mission, error, setError }) => {
    const mapViewModel = useInstance(WindyMapViewModel);

    useEffect(() => {
        try {
            mapViewModel.reset({
                center: mission.getLocation(),
                radiusMeters: mission.getMissionRadius().getMissionRadius().properties.radiusMeters,
                startTime: mission.getStartTime(),
                zoom: 8,
                hideMarkers: true
            });
        } catch (e) {
            setError(e);
        }

    // disabled b/c gotoMissionWeatherMap will be different every render, and risk isn't worth addressing
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    // We don't want this map to be interactable (should not be able to pan, zoom, etc).
    // So we fully cover the map with a transparent div overlay.

    return (
        <>
            <div id="windy-preview-overlay" style={{position: 'absolute', width: '100%', height: '100%', zIndex: 1}} />

            <div id="windy-preview" style={{height: 140, width: '100%'}}>
                {error &&
                    <WeatherError error={error} />
                }
                {!error &&
                    <ShowWindyMap />
                }
            </div>
        </>
    );
};
