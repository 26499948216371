import { IonBadge } from '@ionic/react';
import { Color } from '../common';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
    badge: {
        fontSize: 14,
        maxWidth: '100%',
        whiteSpace: 'normal',
        textAlign: 'start',
        lineHeight: '1.25'
    }
});

interface BadgeProps {
    color?: Color;
    saturated?: boolean;
    size?: 'small' | 'medium';
}

export const Badge: React.FC<BadgeProps> = ({ color, saturated, size, children }) => {
    const classes = useStyles();
    const backgroundStyle = saturated ? `var(--ion-color-${color})` : `rgba(var(--ion-color-${color}-rgb), 0.1)`;
    const colorStyle = saturated ? `var(--ion-color-${color}-contrast)` : `var(--ion-color-${color})`;
    const paddingStyle = (size === 'small' ? '2px 8px' : '6px 12px');

    return (
        <IonBadge className={classes.badge} style={{padding: paddingStyle, background: backgroundStyle, color: colorStyle}}>
            {children}
        </IonBadge>
    );
};
