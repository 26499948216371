import { IonText } from '@ionic/react';
import { EditableMissionState } from '../model/EditableMissionState';
import { AuthorizableLAANCAdvisoryProperties } from '@autopylot-internal/tiles-client';
import { formatLocalDayAndTime, formatPhone } from '../../support/helpers/format';
import { IOperator } from '../../profile/IOperator';
import { AuthorizationNotRealWarning } from './AuthorizationNotRealWarning';
import { DevSettings } from '../../support/DevSettings';
import { WrapWithCardOnLargerScreen } from '../../components/card/WrapWithCardOnLargerScreen';

const interpolateFAAIntoHtml = ({ rawText, operator, authorization, getFormattedTime, altitude }: { rawText: string, operator: IOperator, authorization: AuthorizableLAANCAdvisoryProperties, getFormattedTime: (time: string) => string, altitude: number }) => {
    // warning: altitude being passed in, is coming from the mission and ideally would instead would come from the authorization
    // which will be necessary when we support changing authorizations
    // eg I have an authorization for 400 even though my mission is now 300, b/c it isn't
    // worth changing the authorization I already had approved at 400

    const operatorNameHtml = `<b><span style={{whiteSpace: 'nowrap'}}>${operator.firstName} ${operator.lastName}</span></b>`;
    const phoneNumberHtml = `<b><span style={{whiteSpace: 'nowrap'}}>${formatPhone(operator.phone!)}</span></b>`;

    return rawText
        .replace(
            '[LAANC Reference Code / FAA Facility Code, Start Date & Time - End Date & Time, Max Alt num ft]',
            `<b>APY${authorization.operationID} / ${authorization.airspaceIdent}, <span style={{whiteSpace: 'nowrap'}}>${getFormattedTime(authorization.startTime)}</span> – <span style={{whiteSpace: 'nowrap'}}>${getFormattedTime(authorization.endTime)}</span>, Max Alt ${altitude}&nbsp;ft</b>`
        )
        .split('[Name of operator]').join(operatorNameHtml)
        .split('[name of operator]').join(operatorNameHtml)
        .split('1,000 feet').join('1,000&nbsp;feet')
        .split('[contact phone number]').join(phoneNumberHtml);
};

const RecreationalAuthorization = ({ authorization, getFormattedTime, operator, altitude }: { authorization: AuthorizableLAANCAdvisoryProperties, getFormattedTime: (time: string) => string, operator: IOperator, altitude: number }) => {
    // warning: only manual changes were
    // 1) changed - to a - that is expected in code, so that IDE didn't complain
    // 2) moved num ft]: to first line so it is easier to do substitution on
    const rawText = `
    [LAANC Reference Code / FAA Facility Code, Start Date & Time - End Date & Time, Max Alt num ft]:
    In accordance with 49 U.S.C. § 44809(a)(5), your operation is authorized within the
    designated airspace and timeframe constraints. Altitude limits are absolute values above ground
    level which shall not be added to the height of any structures. This Authorization is subject to
    cancellation at any time upon notice by the FAA Administrator or his/her authorized
    representative. This Authorization does not constitute a waiver of any State law or local
    ordinance. [Name of operator] is the person designated as responsible for the overall safety of
    UAS operations under this Authorization. During UAS operations for on-site
    communication/recall, [name of operator] shall be continuously available for direct contact at
    [contact phone number] by Air Traffic. [Name of operator] is responsible to check the airspace
    in which the UAS will be operated and comply with all restrictions that may be present in
    accordance with § 44809(a)(5), such as restricted and prohibited airspace, temporary flight
    restrictions, etc. This authorization is subject to the following conditions: (1) operations are not
    authorized in Class E surface area airspace when there is a weather ceiling less than 1,000 feet
    AGL; (2) if the UAS loses communications or loses its GPS signal, it must return to a
    predetermined location within the operating area and land; and (3) the person manipulating the
    controls of the UAS must abort the flight in the event of unpredicted obstacles or emergencies.
    This certificate shall be presented for inspection upon the request of any authorized representative
    of the Federal Aviation Administration, or of any State or municipal official charged with the duty
    of enforcing local laws or regulations.`;

    const __html = interpolateFAAIntoHtml({
        rawText,
        authorization,
        getFormattedTime,
        operator,
        altitude
    });

    return (
        <p>
            <small dangerouslySetInnerHTML={{ __html }} />
        </p>
    );
};

const LicensedAuthorization = ({ authorization, getFormattedTime, operator, altitude }: { authorization: AuthorizableLAANCAdvisoryProperties, getFormattedTime: (time: string) => string, operator: IOperator, altitude: number }) => {
    // warning: only manual changes were
    // 1) changed - to a - that is expected in code, so that IDE didn't complain
    // 2) moved num ft]: to first line so it is easier to do substitution on
    const rawText = `
    [LAANC Reference Code / FAA Facility Code, Start Date & Time - End Date & Time, Max Alt num ft]:
    In accordance with Title 14 CFR Part 107.41, your operation is authorized within the
    designated airspace and timeframe constraints. Altitude limits are absolute values above ground
    level which shall not be added to the height of any structures. This Authorization is subject to
    cancellation at any time upon notice by the FAA Administrator or his/her authorized
    representative. This Authorization does not constitute a waiver of any State law or local
    ordinance. [Name of operator] is the person designated as responsible for the overall safety of
    UAS operations under this Authorization. During UAS operations for on-site
    communication/recall, [name of operator] shall be continuously available for direct contact at
    [contact phone number] by Air Traffic. Remote pilots are responsible to check the airspace they
    are operating in and comply with all restrictions that may be present in accordance with 14 CFR
    107.45 and 107.49 (a)(2), such as restricted and Prohibited Airspace, Temporary Flight
    Restrictions, etc. Remote pilots are also responsible for complying with the operating
    requirements in 14 CFR 107.29(a) when operating at night. Operations are not authorized in
    Class E airspace when there is a weather ceiling less than 1,000 feet AGL. If the UAS loses
    communications or loses its GPS signal, it must return to a predetermined location within the
    operating area and land. The remote pilot in command must abort the flight in the event of
    unpredicted obstacles or emergencies.
    This certificate shall be presented for inspection upon the request of any authorized representative
    of the Federal Aviation Administration, or of any State or municipal official charged with the duty
    of enforcing local laws or regulations.`;


    const __html = interpolateFAAIntoHtml({
        rawText,
        authorization,
        getFormattedTime,
        operator,
        altitude
    });

    return (
        <p>
            <small dangerouslySetInnerHTML={{ __html }} />
        </p>
    );
};

const Authorization = ({ authorization, getFormattedTime, operator, altitude }: { authorization: AuthorizableLAANCAdvisoryProperties, getFormattedTime: (time: string) => string, operator: IOperator, altitude: number }) => {
    if (authorization.isLicensed) {
        return <LicensedAuthorization {...{ authorization, getFormattedTime, operator, altitude }} />;
    } else {
        return <RecreationalAuthorization {...{ authorization, getFormattedTime, operator, altitude }} />;
    }
};

export const AuthorizationDetails: React.FC<{ authorizations: AuthorizableLAANCAdvisoryProperties[], operator: IOperator, mission: EditableMissionState, devSettings: DevSettings }> = ({ authorizations, operator, mission, devSettings }) => {
    const location = mission.getLocation();
    const getFormattedTime = (time: string) => formatLocalDayAndTime({ location, time, withTimeZone: true });

    return (
        <WrapWithCardOnLargerScreen>
            <div style={{ padding: '10px 20px' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginRight: -20 }}>
                    <h5>LAANC Authorization Details</h5>
                </div>

                {!mission.state.isReal && !devSettings.disableNotRealWarnings &&
                    <AuthorizationNotRealWarning />
                }

                <IonText>
                    {authorizations.map(auth => {
                        return (
                            <Authorization key={auth.operationID} authorization={auth} getFormattedTime={getFormattedTime} operator={operator} altitude={mission.getMaxAltitude()} />
                        );
                    })}
                    {devSettings.showLAANCAuthDebug && (
                        <>
                            <hr />
                            <h6>Debug JSON:</h6>
                            <small><pre style={{ paddingBottom: 20 }}>
                                {JSON.stringify(authorizations, null, 2)}
                            </pre></small>
                        </>
                    )}
                </IonText>
            </div>
        </WrapWithCardOnLargerScreen>
    );
};
