import moment from 'moment';
import { State } from '@meraki-internal/state';
import { HistoryViewModel } from '../../app/HistoryViewModel';
import { PagePathsBuilder } from '../../app/PagePathsBuilder';
import { MapViewModel } from '../../map/model/MapViewModel';
import { MissionPinAndRadiusOnMapViewModel } from '../../map/mission-pin-and-radius/MissionPinAndRadiusOnMapViewModel';
import { IMission, INewMission } from '../../mission/model/EditableMissionState';
import { ICoordinate } from '../../support/model/ICoordinate';
import { IAddress } from '../../support/model/IAddress';
import { AlertPresenter } from '../../app/AlertBinder';
import { OperatorState } from '../../profile/OperatorState';
import { FAAVersionAlertManager } from '../../app/FAAVersionAlertManager';
import { UserFacingError } from '../../support/UserFacingError';
import { TrackingService } from '../../support/tracking/TrackingService';
import { DefaultMissionTimeCalculator } from './DefaultMissionTimeCalculator';
import { ICanFly } from '@autopylot-internal/tiles-client';

const FEET_PER_METER = 3.28084;

interface INewMissionCallbacks {
    onSave: (newMission: INewMission) => Promise<void>;
    getConcurrentLAANCMissions: (newMission: Partial<IMission>) => IMission[];
}

export type ITimeChangeSource = 'Mission Planning Weather' | 'Mission Area and Time';

interface IMissionStateProps {
    address: IAddress;
    startTime: string;
    durationMinutes: number;
    radiusFeet: number;
    maxAltitude: number;
}

export class NewMissionState extends State<IMissionStateProps> {
    // property injection by MissionsState
    mapViewModel!: MapViewModel;
    history!: HistoryViewModel;
    _paths!: PagePathsBuilder;
    alertPresenter!: AlertPresenter;
    operator!: OperatorState;
    versionAlertManager!: FAAVersionAlertManager;
    tracker!: TrackingService;
    pinAndRadius!: MissionPinAndRadiusOnMapViewModel;

    constructor(private callbacks: INewMissionCallbacks) {
        // NOTE: not using IOC because this class uses property injection, with EditableMissionState and so it isn't worth it for this dependency
        // if DefaultMissionTimeCalculator becomes stateful or has its own dependencies, then it will be worth it
        const { startTime, durationMinutes } = new DefaultMissionTimeCalculator().calculate();

        super({
            address: { street: '', city: '', state: '' },
            startTime,
            durationMinutes,
            radiusFeet: 500,
            maxAltitude: 0
        });
    }

    isNew = () => true;

    setAddress = (address: IAddress) => {
        this.setState({ address });
        return this;
    };

    getAddress = () => {
        return this.state.address?.city ? this.state.address : undefined;
    };

    setCenter = async (center: ICoordinate) => {
        const operator = this.operator.state;

        await this.pinAndRadius.start({
            center,
            radiusMeters: this.getRadiusMeters(),
            startTime: this.getStartTime(),
            endTime: this.getEndTime(),
            isLicensed: (operator.certificationType === 'part-107')
        });

        return this;
    };

    getRadiusFeet = () => {
        return this.state.radiusFeet;
    };

    getRadiusMeters = () => {
        return this.getRadiusFeet() / FEET_PER_METER;
    };

    setRadiusFeet = async (radiusFeet: number) => {
        return this.setRadiusFeetV2({ radiusFeet });
    };

    setRadiusFeetV2 = async ({ radiusFeet }: {radiusFeet: number; }) => {
        // set in increments of 10
        const roundedRadiusFeet = Math.round(radiusFeet/10) * 10;

        // NOTE: radiusFeet is duplicated here and on MissionPinAndRadiusOnMapViewModel
        //  we should consider switching to letting it live there as source of truth, like center
        this.setState({ radiusFeet: roundedRadiusFeet });
        this.pinAndRadius.adjustRadiusSize({ radiusMeters: this.getRadiusMeters() });
        await this.pinAndRadius.authorizeRadius();

        this.tracker.trackWithDebounce('Radius Set', () => ({ radiusFeet: roundedRadiusFeet, ...this.getFlightStatusTrackingMeta() }));

        return this;
    };

    getMaxAltitude = () => {
        return this.state.maxAltitude;
    };

    setMaxAltitude = (maxAltitude: number) => {
        if (maxAltitude < 0 || maxAltitude > 400) {
            throw new Error('Max Altitude must be in the range 0 to 400.');
        }

        this.tracker.trackWithDebounce('Altitude Set', () => ({ maxAltitude, ...this.getFlightStatusTrackingMeta() }));

        this.setState({ maxAltitude });
    };

    resetMaxAltitude = () => {
        this.setState({ maxAltitude: 0 });
    };

    private synchronizeMissionTimeToMap = async () => {
        await this.pinAndRadius.adjustTime({
            startTime: this.getStartTime(),
            endTime: this.getEndTime(),
        });
    };

    getMissionStatus = () => undefined;

    getStartTime = () => {
        return this.state.startTime;
    };

    getEndTime = () => {
        return moment(this.getStartTime()).add(this.getDurationMinutes(), 'minutes').toISOString();
    };

    setStartTime = async (startTime: string, source: ITimeChangeSource = 'Mission Area and Time') => {
        this.setState({ startTime });

        await this.synchronizeMissionTimeToMap();

        this.tracker.track('Date/Time Set', () => ({
            startTime, ...this.getFlightStatusTrackingMeta(),
            minutesInAdvance: moment(startTime).diff(moment(), 'minutes'),
            from: source
        }));

        return this;
    };

    getDurationMinutes = () => {
        return this.state.durationMinutes;
    };

    setDurationMinutes = async (durationMinutes: number, source: ITimeChangeSource = 'Mission Area and Time' ) => {
        this.setState({ durationMinutes });

        await this.synchronizeMissionTimeToMap();

        this.tracker.track('Duration Set', () => ({
            durationMinutes, ...this.getFlightStatusTrackingMeta(),
            from: source
        }));

        return this;
    };

    getLocation = (): ICoordinate | undefined => {
        return this.pinAndRadius.getRadius()?.properties.center;
    };

    getMissionRadius = ()  => {
        return this.pinAndRadius.getAuthorizedRadius();
    };

    getConcurrentLAANCMissions = () => {
        return this.callbacks.getConcurrentLAANCMissions(this.state);
    };

    wasUncontrolled = () => {
        return false;
    };

    save = async () => {
        if (this.versionAlertManager.areTilesStale()) {
            throw new UserFacingError({ displayMessage: 'System Outage / System unavailable, please try again later'});
        }
        if (this.versionAlertManager.isFAADown() && this.getMissionRadius()!.getLAANCAuthorizeableAdvisoryFeatures().length > 0) {
            throw new UserFacingError({ displayMessage: 'LAANC System Outage / System unavailable, please try again later'});
        }

        const radiusPolygon = this.pinAndRadius.getRadius()?.properties.authorizedRadius;
        if (!radiusPolygon) {
            // NOTE: edge case, but it can happen if they save before we get an answer
            throw new Error('attempting to save without a radius, which should not happen');
        }

        await this.callbacks.onSave({
            status: 'active',
            address: this.state.address,
            startTime: this.state.startTime,
            durationMinutes: this.state.durationMinutes,
            maxAltitude: this.state.maxAltitude,
            radiusPolygon
        });

        this.tracker.track('Mission Created', () => ({
            address: this.state.address,
            center: this.getLocation(),
            radiusFeet: this.getRadiusFeet(),
            missionShape: this.pinAndRadius.isSquare() ? 'square' : 'circle',
            startTime: this.state.startTime,
            durationMinutes: this.state.durationMinutes,
            maxAltitude: this.state.maxAltitude,
            minutesInAdvance: moment(this.state.startTime).diff(moment(), 'minutes'),
            ...this.getFlightStatusTrackingMeta()
        }));
    };

    cancel = () => {
        this.pinAndRadius.remove();
    };

    onInjected = () => {
        this.pinAndRadius.subscribe(() => {
            // if the radius changed, then we should let our subscribers know
            // since this.getMissionRadius() is attempting to encapsulate pinAndRadius
            this.setState({ });
        });
    };

    getFlightStatusTrackingMeta = (): { canFly: ICanFly | 'pending'; blockReasons: string; } => {
        const mfc = this.pinAndRadius.getAuthorizedRadius();

        if (!mfc){
            return { canFly: 'pending', blockReasons: '' };
        }
        const advisories = mfc.getAdvisories();
        const reasons = advisories.filter(a => a.advisoryType === 'block').map((a: any) => a.blockReasons?.[0] || a.source) as string[];
        const uniqueReasons = Array.from(new Set(reasons));
        return { canFly: mfc.canFly(), blockReasons: uniqueReasons.join(', ') };
    };
};
