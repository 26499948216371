export const StandalonePage: React.FC<{showLogo?: boolean}> = ({showLogo = false, children}) => (

    <div style={{ fontFamily: 'avenir-next, sans-serif', color: '#212B36', position: 'absolute', top: 0, width: '100%', height: '100%', overflowY: 'scroll' }}>

        {showLogo &&
            <div style={{ position: 'fixed', width: '100%', height: 105, padding: 20, paddingTop: 60, zIndex: 100, textAlign: 'center' }}>
                <a href="https://app.autopylot.io">
                    <img src="./assets/images/logo-name-horiz.png" alt="AutoPylot" style={{ maxWidth: 200, display: 'inline' }} />
                </a>
            </div>
        }

        <div style={{ height: '100%', margin: 20, marginTop: showLogo ? 130 : 20 }}>
            {children}
        </div>

    </div>
);
