import { RoundButton } from './RoundButton';
import { useClipboard } from '../../support/hooks/useClipboard';
import { CheckmarkIcon, CopyIcon } from '../icons/Icons';

export const CopyButton: React.FC<{
    value: string;
    size?: 'small' | undefined;
    trackingEvent?: string;
}> = ({ value, size, trackingEvent }) => {

    const [copyToClipboard, justCopied] = useClipboard();

    const onCopyClicked = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        copyToClipboard({ value });
    };

    return (
        <RoundButton
            size={size}
            onClick={(e: React.MouseEvent) => onCopyClicked(e)}
        >
            {!justCopied && (
                <CopyIcon color="medium" />
            )}
            {justCopied && (
                <CheckmarkIcon color="success" />
            )}
        </RoundButton>
    );
};
