import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useSubscription } from '@meraki-internal/state';
import { HourlyWeatherChartViewModel } from './HourlyWeatherChartViewModel';

export const ChartDaysPicker: React.FC<{}> = () => {
    const vm = useInstance(HourlyWeatherChartViewModel);
    useSubscription(() => vm);

    return (
        <ul style={{ listStyleType: 'none', display: 'flex', margin: 0, padding: 5, flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'space-between', color: `var(--ion-color-medium)` }}>
            {vm.getChartDays().map(day => {

                const isActiveDay = vm.isActiveDay(day);
                const isForecastDay = vm.isForecastDay(day);

                return (
                    <li key={day.toISOString()}
                        data-type="ChartDaysPicker-day"
                        style={{ textAlign: 'center', cursor: 'pointer' }}
                        onClick={() => vm.setActiveDay(day)}
                    >
                        <p style={{ margin: 0, padding: 0, fontSize: 'smaller' }}>
                            {day.format('ddd').substring(0, 1)}
                        </p>
                        <p style={{
                            margin: 0,
                            padding: 5,
                            width: 30,
                            textAlign: 'center',
                            color: isActiveDay || isForecastDay ? 'white': undefined,
                            backgroundColor: isForecastDay ? 'var(--ion-color-primary)': isActiveDay ? 'var(--ion-color-medium)' : undefined,
                            fontWeight: isActiveDay || isForecastDay ? 'bold' : undefined,
                            borderRadius: '50%',
                            whiteSpace: 'nowrap'
                        }}>
                            {day.format('D')}
                        </p>
                    </li>
                );
            })}
        </ul>
    );
};
