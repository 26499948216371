import { Chart, ChartType } from 'chart.js';
import { ChartJSActiveElementObserver } from './ChartJSActiveElementObserver';

export class ChartJSProvider {
    private chart?: Chart;
    activeElementObserver?: ChartJSActiveElementObserver;

    setChart = (chart: Chart) => {
        this.chart = chart;
        this.activeElementObserver = new ChartJSActiveElementObserver(chart).startObserving();
    };

    disposeChart = () => {
        this.activeElementObserver!.dispose();
        this.activeElementObserver = undefined;

        this.chart!.destroy();
        this.chart = undefined;
    };

    getChart = () => this.chart!;

    getChartType = (): ChartType => this.chart?.getDatasetMeta(0).type as ChartType;

    getCanvas = () => this.chart!.canvas;
};
