import { createUseStyles } from 'react-jss';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { IonAccordion, IonAccordionGroup, IonCard, IonCardContent, IonItem, IonLabel, IonList } from '@ionic/react';
import { CardHeader } from '../components/card/CardHeader';
import { ForwardIcon, TimeIcon } from '../components/icons/Icons';
import { formatPeriod, getLiabilityLimit, getPolicyStatus, getPolicyColor, getPolicyType } from './policyHelpers';
import { HistoryViewModel } from '../app/HistoryViewModel';
import { CardFooter } from '../components/card/CardFooter';
import { InitiateClaimButton } from './InitiateClaimButton';
import { IInsurancePolicy } from './IInsurancePolicy';

const splitPolicies = (policies: IInsurancePolicy[]) => {
    const active = [];
    const scheduled = [];
    const expired = [];
    for (const policy of policies) {
        const status = getPolicyStatus(policy);
        if (status === 'Active') {
            active.push(policy);
        } else if (status === 'Scheduled') {
            scheduled.push(policy);
        } else {
            expired.push(policy);
        }
    }
    return {
        active: active.sort((p1, p2) => p1.start_date.localeCompare(p2.start_date)),
        scheduled: scheduled.sort((p1, p2) => p1.start_date.localeCompare(p2.start_date)),
        expired: expired.sort((p1, p2) => p2.start_date.localeCompare(p1.start_date))
    };
};

const useStyles = createUseStyles({
    cardContent: {
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 0
    },

    cardBody: {
        paddingBottom: 18
    }
});

const InsurancePolicyCard: React.FC<{ policy: IInsurancePolicy }> = ({ policy }) => {

    const classes = useStyles();
    const history = useInstance(HistoryViewModel);

    return (
        <IonCard key={policy.id} data-id={policy.id} button onClick={() => history.push(`/insurance/policies/${policy.id}`)}>
            <CardHeader>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                        <TimeIcon />
                        <span style={{ fontWeight: 'bold' }}>Policy Period: {formatPeriod(policy)}</span>
                    </span>
                </div>
            </CardHeader>

            <IonCardContent className={classes.cardContent}>
                <IonItem lines="none" className={classes.cardBody}>
                    <div>
                        <h2 style={{ fontSize: 20, color: 'var(--ion-text-color)', fontWeight: 'bold' }}>{getPolicyType(policy)} Policy</h2>
                        <div style={{ lineHeight: 1.25, marginTop: 10, fontSize: 14, overflow: 'hidden' }}>
                            <p>Policy Number: {policy.policy_number}</p>
                            <p>Liability Limit: ${getLiabilityLimit(policy).toLocaleString()}</p>
                        </div>
                    </div>
                    <div slot="end" style={{ position: 'relative' }}>
                        <ForwardIcon />
                    </div>
                </IonItem>

                <CardFooter
                    message={`Status: ${policy.pending ? 'Pending' : getPolicyStatus(policy)}`}
                    color={getPolicyColor(policy)}
                />

            </IonCardContent>
        </IonCard>
    );
};

export const InsurancePolicyList: React.FC<{policies: IInsurancePolicy[]}> = ({ policies }) => {

    const { active, scheduled, expired } = splitPolicies(policies);
    const expanded = scheduled.length ? ['Scheduled'] : expired.length ? ['Expired'] : [];

    const canClaim = active.filter(a => !a.pending).length > 0 || expired.length > 0;

    return (
        <>
            {active.length > 0 && (
                <>
                    {active.map(policy => (
                        <InsurancePolicyCard key={policy.id} policy={policy} />
                    ))}
                </>
            )}

            <IonAccordionGroup multiple value={expanded} style={{marginBottom: 20}}>

                {scheduled.length > 0 && (
                    <IonAccordion value="Scheduled">
                        <IonItem slot="header">
                            <IonLabel>Scheduled</IonLabel>
                        </IonItem>
                        <IonList slot="content">
                            {scheduled.map(policy => (
                                <InsurancePolicyCard key={policy.id} policy={policy} />
                            ))}
                        </IonList>
                    </IonAccordion>
                )}

                {expired.length > 0 && (
                    <IonAccordion value="Expired">
                        <IonItem slot="header">
                            <IonLabel>Expired</IonLabel>
                        </IonItem>
                        <IonList slot="content">
                            {expired.map(policy => (
                                <InsurancePolicyCard key={policy.id} policy={policy} />
                            ))}
                        </IonList>
                    </IonAccordion>
                )}

            </IonAccordionGroup>

            {canClaim &&
                <div style={{ margin: 20}}>
                    <InitiateClaimButton />
                </div>
            }
        </>
    );
};
