import { useEffect } from 'react';
import moment from 'moment';
import { IonAccordion, IonAccordionGroup, IonItem, IonLabel, IonList } from '@ionic/react';
import { useSubscription } from '@meraki-internal/state';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { EditableMissionState } from '../../mission/model/EditableMissionState';
import { IMissionListSection, MissionsState } from '../model/MissionsState';
import { MissionListItem } from './MissionListItem';
import { NoMissionsYetCard } from './NoMissionsYetCard';
import { Refresher, RefresherEvent } from '../../components/refresher/Refresher';
import { AlertPresenter } from '../../app/AlertBinder';
import { TrackingService } from '../../support/tracking/TrackingService';
import { NewMissionButton } from './NewMissionButton';
import { Spinner } from '../../components/spinner/Spinner';
import { RefreshTimer } from '../../components/refresher/RefreshTimer';
import { DrawerLabel } from '../../components/drawer/DrawerLabel';

// TODO: split out
const splitMissions = (missions: EditableMissionState[]) => {
    const needsAttention = [];
    const invalid = [];
    const past = [];
    const future = [];
    const canceled = [];

    for (const mission of missions) {
        const status = mission.getMissionStatus();
        if (mission.shouldCancel() || mission.getReminderCount() > 0) {
            needsAttention.push(mission);
        } else if (mission.isNotValid()) {
            invalid.push(mission);
        } else if (status === 'user-canceled') {
            canceled.push(mission);
        } else if (mission.hasEnded()) {
            past.push(mission);
        } else {
            future.push(mission);
        }
    }
    return { needsAttention, past, future, canceled, invalid };
};

const missionSorts = {
    futureIsLast: (a: EditableMissionState, b: EditableMissionState) => {

        const aEpoch = moment(a.getStartTime()).valueOf();
        const bEpoch = moment(b.getStartTime()).valueOf();

        return aEpoch - bEpoch;
    },
    pastIsLast: (a: EditableMissionState, b: EditableMissionState) => {

        const aEpoch = moment(a.getStartTime()).valueOf();
        const bEpoch = moment(b.getStartTime()).valueOf();

        return bEpoch - aEpoch;
    },
};

const LoadingSpinner: React.FC<{missions: MissionsState}> = ({ missions }) => {
    if (missions.state.isFullyLoaded) {
        return null;
    }
    return (
        <div style={{textAlign: 'center', marginBottom: 15}}>
            <Spinner size="small" />
        </div>
    );
};

const SectionLabel: React.FC<{text: string}> = ({ text }) => (
    <div style={{margin: '15px 20px -8px'}}>
        <DrawerLabel text={text} />
    </div>
);

export const MissionList = ({ initialAccordionValue }: { initialAccordionValue?: IMissionListSection}) => {
    const missions = useInstance(MissionsState);
    useSubscription(() => missions);

    useEffect(() => {
        // initialAaccordionValue set only by screenshot
        if (initialAccordionValue) {
            missions.setState({ expandedSection: initialAccordionValue });
        }
    }, [missions, initialAccordionValue]);

    useEffect(() => {
        // kick off a full reload when the mission list mounts
        // this will ensure that the expandable sections are rendered
        // (and add/remove/update any missions changed on another device)
        // while still keeping app start fast (since it only loads past 7 days)
        missions.loadAll();
    }, [missions]);

    const alert = useInstance(AlertPresenter);
    const tracker = useInstance(TrackingService);

    const { needsAttention, past, future, canceled, invalid } = splitMissions(missions.state.missions);

    const onRefresh = async (event: RefresherEvent) => {
        try{
            await missions.loadAll();
        } catch (err){
            alert.showAndLogError(err);
        }

        // stop the spinner
        event.detail.complete();
    };

    const onSectionSelected = (event: any) => {
        missions.setState({ expandedSection: event.detail.value });

        if (event.detail.value) {
            tracker.track('Missions Section Opened', () => ({ section: event.detail.value }));
        }
    };

    return (
        <>
            <Refresher onRefresh={onRefresh} />

            {future.length > 0 &&
                <RefreshTimer getIntervalMs={future[0].getAutoRefreshIntervalMs} handler={missions.loadRecentAndUpcoming} />
            }

            {future.length === 0 && needsAttention.length === 0 && (
                <NoMissionsYetCard />
            )}

            {(future.length > 0 || needsAttention.length > 0) && (
                <div style={{margin: 16}}>
                    <NewMissionButton />
                </div>
            )}

            <div data-type="needs-attention">
                {needsAttention.length > 0 &&
                    <SectionLabel text="Needs Attention" />
                }
                {needsAttention.sort(missionSorts.futureIsLast).map(mission => (
                    <MissionListItem key={mission.state.missionId} mission={mission} />
                ))}
            </div>

            <div data-type="upcoming-missions">
                {future.length > 0 &&
                    <SectionLabel text="Upcoming" />
                }
                {future.sort(missionSorts.futureIsLast).map(mission => (
                    <MissionListItem key={mission.state.missionId} mission={mission} />
                ))}
            </div>

            <IonAccordionGroup style={{marginBottom: 20}} value={missions.state.expandedSection} onIonChange={onSectionSelected}>
                {past.length > 0 && (
                    <IonAccordion value="past">
                        <IonItem slot="header">
                            <IonLabel>Completed</IonLabel>
                        </IonItem>
                        <IonList slot="content">
                            {past.sort(missionSorts.pastIsLast).map(mission => (
                                <MissionListItem key={mission.state.missionId} mission={mission} />
                            ))}
                            <LoadingSpinner missions={missions} />
                        </IonList>
                    </IonAccordion>
                )}
                {canceled.length > 0 && (
                    <IonAccordion value="canceled">
                        <IonItem slot="header">
                            <IonLabel>Canceled</IonLabel>
                        </IonItem>
                        <IonList slot="content">
                            {canceled.sort(missionSorts.pastIsLast).map(mission => (
                                <MissionListItem key={mission.state.missionId} mission={mission} />
                            ))}
                            <LoadingSpinner missions={missions} />
                        </IonList>
                    </IonAccordion>
                )}
                {invalid.length > 0 && (
                    <IonAccordion value="invalid">
                        <IonItem slot="header">
                            <IonLabel>Not Valid</IonLabel>
                        </IonItem>
                        <IonList slot="content">
                            {invalid.sort(missionSorts.pastIsLast).map(mission => (
                                <MissionListItem key={mission.state.missionId} mission={mission} />
                            ))}
                            <LoadingSpinner missions={missions} />
                        </IonList>
                    </IonAccordion>
                )}
            </IonAccordionGroup>
        </>
    );
};
