import { Geolocation } from '@capacitor/geolocation';
import { Device } from '../../support/Device';
import { ICoordinate } from '../../support/model/ICoordinate';
import { IStateCallback, State } from '@meraki-internal/state';
import moment from 'moment';
import { Logger } from '../../support/debug/Logger';

type IGPSCoordinate = ICoordinate & { at: string, accuracy: number };

class WatchState extends State<IGPSCoordinate> {
    constructor(){
        // it is expecting a IGPSCoordinate even though undefined is supported
        // so working around that
        super(undefined as unknown as IGPSCoordinate);
    }
}

export class GeolocationService {

    static inject = () => [Device, Logger];
    constructor(private device: Device, private logger: Logger) {}

    private watchState = new WatchState();
    private watchId: string | undefined;

    // returns device lat/lng from watch state if watch is running,
    // otherwise makes a direct request, which may prompt for permission
    getCurrentCoordinates = async (): Promise<ICoordinate> => {

        if (this.watchState.state){
            return this.watchState.state;
        }

        const location = await this.getCoordinates();

        // on native, the watch starts as soon as permission is granted (see subscribe() method below),
        // but on web it might have started too soon, we restart it now we know we have permission
        if (this.device.platform === 'web') {
            this.startOrRestartWatch();
        }

        return location;
    };

    // Start the watch, and emit to provided callback function
    subscribe = async (callback: IStateCallback<IGPSCoordinate>) => {

        // on native devices, we wait here for user to grant permission (via intercom) before starting the watch
        if (['ios', 'android'].includes(this.device.platform)) {
            await this.waitForLocationPermissionGranted();
        }

        // on web we don't have intercom, and safari/firefox won't tell us if permission was granted/denied for current session only,
        // so we go ahead and start the watch immediately, which prompts the user for permission if necessary
        await this.startOrRestartWatch();

        this.watchState.subscribe(callback);
    };

    private startOrRestartWatch = async () => {
        const watchId = await Geolocation.watchPosition({
            enableHighAccuracy: true,

            // NOTE: the android implementation of @capacitor/geolocation
            //       makes these settings mostly moot
            //       as it uses an interval, which is hard coded to 10s
            //       see ../../../patches where we patch it to 1s
            // NOTE: the settings are primarily tuned for android
            //       and are working for fine for iOS, if we tune them for iOS
            //       be prepared to vary them by platform

            // only use a cached position for up to half a second
            maximumAge: 500,

            // NOTE: on Android increasing this to 2000 causes the avg time between updates to
            //       go from about every 1s to every 20s
            // NOTE: we may want to "hack" GeoLocation.java to use this input
            //       instead of the hard coded value we have patched, but due to the weird
            //       behavior observed above, we're holding off til we invest in this further.
            //       Also, b/c this was meant to be a 1 point story (so to limit scope).
            timeout: 1000

        }, (pos) => {
            if (pos){
                this.watchState.setState({
                    lat: pos.coords.latitude,
                    lng: pos.coords.longitude,
                    at: moment(pos.timestamp).toISOString(true),
                    accuracy: pos.coords.accuracy
                });
            } else {
                this.logger.info(`Geolocation.watchPosition emitted a falsy position, ignoring it`);
            }
        });

        // if a watch was already running, stop it
        if (this.watchId) {
            Geolocation.clearWatch({ id: this.watchId });
        }

        this.watchId = watchId;
    };

    // Returns string indicating whether user has granted permission to access device location
    // (note this returns "prompt" on safari/firefox web if granted/denied for current session only)
    getLocationPermissionStatus = async (): Promise<'granted' | 'denied' | 'prompt' | 'prompt-with-rationale' | 'disabled'> => {
        try {
            const result = await Geolocation.checkPermissions();
            return result.location;
        } catch (e: any) {
            if (e.message === 'Location services are not enabled') {
                return 'disabled';
            }
            throw e;
        }
    };

    // Poll permission status indefinitely until it is "granted", then return
    // (note this will never resolve on safari/firefox web if granted/denied for current session only)
    private waitForLocationPermissionGranted = async (): Promise<void> => {
        while (true){
            try {
                const result = await this.getLocationPermissionStatus();
                if (result === 'granted'){
                    return;
                }
                await new Promise(resolve => setTimeout(resolve, 1000));
            }
            catch (err: any){
                this.logger.info(`waitForLocationPermissionGranted -> getLocationPermissionStatus failed with ${err.toString()}`);
            }
        }
    };

    // Make a direct request for the current coordinates
    private getCoordinates = async () => {

        // we found we need to set enableHighAccuracy on android to get the u/x we expected,
        // but on ios this seemed to make it much slower (like 10+ seconds) in some cases
        const options = (this.device.platform === 'android' ? { enableHighAccuracy: true } : undefined);

        const location = await Geolocation.getCurrentPosition(options);
        const { latitude, longitude } = location.coords;
        return { lat: latitude, lng: longitude };
    };
}
