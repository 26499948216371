import { CSSProperties } from 'react';

// Expects children to be two divs, the first is the body, the second the footer.
// Expands body to fill full page, leaving adequate room top and bottom.
export const ContentWithFooter: React.FC<{'data-id'?: string}> = ({ children, 'data-id': dataId }) => {

    const style: CSSProperties = {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        gap: 20,
        height: '100%',
        padding: 25,
        overflow: 'hidden'
    };

    return (
        <div data-id={dataId} style={style}>
            {children}
        </div>
    );
};
