import React, { useState } from 'react';
import { Button } from '../components/button/Button';
import { ButtonSpinner } from '../components/spinner/ButtonSpinner';
import { useOnboardingTour } from '../app/intercom/useOnboardingTour';
import { usePageView } from '../support/tracking/usePageView';
import { ContentWithFooter } from '../components/page/ContentWithFooter';

export const Terms: React.FC<{acceptTerms: () => Promise<void>, signIn?: (fromPage: 'Terms') => Promise<void>, signOut?: () => Promise<void>}> = ({ acceptTerms, signIn, signOut }) => {
    usePageView('Terms');

    // show the intercom onboarding tour (immediately before terms) if we haven't shown it already
    const tourDone = useOnboardingTour();

    const [submitting, setSubmitting] = useState(false);

    const onSubmit = async (event: any) => {
        event.preventDefault();
        if (submitting) {
            return;
        }
        setSubmitting(true);
        await acceptTerms();
    };

    // show a blank page until the tour is launched,
    // to avoid showing a flicker of terms page before
    if (!tourDone) {
        return null;
    }

    return (
        <div style={{width: '100%', height: '100%', maxWidth: 400, maxHeight: 1000, margin: 'auto'}}>
            <ContentWithFooter>

                <div>
                    <div style={{textAlign: 'center'}}>
                        <img src="./assets/images/logo.png" alt="AutoPylot logo" height="52px" style={{marginBottom: 25}} />
                        <h1>Terms of Service</h1>
                        <h5>Please review to continue</h5>
                    </div>
                    <ul style={{margin: '50px 10px'}}>
                        <li style={{margin: '20px 0'}}>
                            <a href="https://www.autopylot.io/terms" target="_blank">AutoPylot Terms of Service</a>
                        </li>
                        <li style={{margin: '20px 0'}}>
                            <a href="https://www.faa.gov/uas/programs_partnerships/data_exchange/privacy_statement" target="_blank">FAA Privacy Statement</a>
                        </li>
                        <li style={{margin: '20px 0'}}>
                            <a href="https://www.autopylot.io/faa" target="_blank">LAANC Association Statement</a>
                        </li>
                    </ul>
                </div>

                <div style={{display: 'flex', flexDirection: 'column', gap: 15, textAlign: 'center'}}>
                    <form data-id="terms-form" onSubmit={onSubmit}>
                        <Button expand="block" type="submit" dataType="continue" disabled={submitting}>
                            {submitting && <ButtonSpinner />}
                            <b>Accept Terms</b>
                        </Button>
                    </form>
                    {signIn &&
                        <Button fill="clear" type="button" size="small" onClick={() => signIn('Terms')} dataType="sign-in">
                            <b style={{fontSize: '110%'}}>Already have an account? Sign In</b>
                        </Button>
                    }
                    {signOut &&
                        <Button fill="clear" type="button" size="small" onClick={() => signOut()} dataType="sign-out">
                            <b style={{fontSize: '110%'}}>Cancel</b>
                        </Button>
                    }
                </div>

            </ContentWithFooter>
        </div>
    );
};
