import { Network } from '../support/Network';
import { IScreenshotablePage } from '../support/screenshots/IScreenshotablePage';
import { ErrorPage } from './ErrorPage';

interface IMyPageProps {
    mockOffline?: boolean;
}

export default class ScreenshotErrorPage implements IScreenshotablePage<IMyPageProps> {
    static inject = () => [Network];
    constructor(private network: Network){}

    screenId = 'error';

    permutationToProps = ({ mockOffline }: IMyPageProps) => {
        const error = new Error('test');
        if (mockOffline){
            Object.defineProperty(this.network, 'isOnline', {
                get: () => false
            });
        }
        return { error };
    };

    ComponentUnderTest = ErrorPage;

    permutations = {
        'offline': {
            mockOffline: true
        },
    };
}
