import { INationalParkAdvisoryProperties } from '@autopylot-internal/tiles-client';
import { SecondaryWarningAdvisory } from '../SecondaryAdvisory';
import { AdvisoryBlockIcon } from '../../components/icons/Icons';

export const NationalParkAdvisory = (props: (INationalParkAdvisoryProperties)) => {
    return (
        <SecondaryWarningAdvisory header="National Park Service" advisoryType={props.advisoryType} GLOBAL_ID={props.GLOBAL_ID} iconColor='danger' icon={AdvisoryBlockIcon}>
            <>
                <p>
                    Your mission overlaps <strong>{props.UNIT_NAME}</strong>. Check with the National Park Service prior to flight.
                </p>
                <p>
                    Prior to flight, the remote pilot in command must assess the local airspace along with any other takeoff, landing, and flight restrictions.
                </p>
                <p>
                    <a href="https://www.nps.gov/orgs/aviationprogram/upload/unmanned-aircraft-in-national-parks.pdf" target="_blank">Drones and National Parks</a>
                </p>
            </>
        </SecondaryWarningAdvisory>
    );
};
