import { IconComponentProps, RaindropIcon, SnowflakeIcon } from '../../components/icons/Icons';
import { IPrecipitationType } from '../model/IPrecipitationType';

export const PrecipitationIcon: React.FC<IconComponentProps & {precipitationType: IPrecipitationType}> = ({ precipitationType, ...props }) => {
    if (precipitationType === 'snow') {
        return (
            <SnowflakeIcon {...props} />
        );
    }
    return (
        <RaindropIcon {...props} />
    );
};
