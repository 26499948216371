import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useSubscription } from '@meraki-internal/state';
import { NativeSettings, AndroidSettings, IOSSettings } from 'capacitor-native-settings';
import { AlertPresenter } from '../../app/AlertBinder';
import { RoundIconButton } from '../../components/button/RoundIconButton';
import { LocateMeIcon } from '../../components/icons/Icons';
import { Device } from '../../support/Device';
import { MapViewModel } from '../model/MapViewModel';
import { GeolocationService } from './GeolocationService';
import { MissionPinAndRadiusOnMapViewModel } from '../mission-pin-and-radius/MissionPinAndRadiusOnMapViewModel';
import { Logger } from '../../support/debug/Logger';

export const LocateMeButton: React.FC = () => {
    const { platform } = useInstance(Device);

    const mapVM = useInstance(MapViewModel);
    useSubscription(() => mapVM);

    const pinAndRadiusVM = useInstance(MissionPinAndRadiusOnMapViewModel);
    useSubscription(() => pinAndRadiusVM);

    const geolocationService = useInstance(GeolocationService);
    const alert = useInstance(AlertPresenter);
    const log = useInstance(Logger);

    const locateMe = async () => {
        try {
            const coords = await geolocationService.getCurrentCoordinates();
            mapVM.flyTo({ center: coords, zoom: 14 });
        } catch (e: any) {
            log.info(`Unable to get current location ${e.message}`);

            // defaults (for web)
            let subHeader = 'Please enable Location access.';
            let message = 'Please set the Location permission to "Allow" in your browser Site Settings, then reload this page.';
            let buttons: any[] = ['OK'];

            if (platform === 'ios') {
                subHeader = 'Please enable Location access in the Settings app.';
                message = 'Tap "Open Settings" below, then tap Location and select "While Using the App".';
            } else if (platform === 'android') {
                subHeader = 'Please enable Location access in Settings.';
                message = 'Tap "Open Settings" below, tap Permissions, then tap Location and select "Allow only while using the app".';
            }

            if (['ios', 'android'].includes(platform)) {
                buttons = [
                    'No Thanks',
                    {
                        text: 'Open Settings',
                        handler: () => {
                            NativeSettings.open({ optionIOS: IOSSettings.App, optionAndroid: AndroidSettings.ApplicationDetails });
                        }
                    }
                ];
            }

            alert.showAlert({
                header: 'Location Access Denied',
                subHeader,
                message,
                leftAligned: true,
                buttons
            });
        }
    };

    // don't show locate me button after dropping the pin
    // b/c it could be very far from where your mission was, and there isn't an easy way to undo
    // and go back to where your mission center was (it is more likely to do harm, than help)
    if (!pinAndRadiusVM.getRadius()){
        return (
            <RoundIconButton Icon={LocateMeIcon} onClick={locateMe} />
        );
    } else {
        return null;
    }
};
