import { useState } from 'react';
import { DateTimePicker } from './DateTimePicker';

const DemoDateTimePicker = () => {
    const [dateTime, setDateTime] = useState('2022-03-10T16:30:00.000');

    return (
        <DateTimePicker
            dateTime={dateTime}
            location={{ lat: 45, lng: -60 }}
            onChange={value => setDateTime(value)}
        />
    );
};

DemoDateTimePicker.componentName = 'DateTimePicker';

export default DemoDateTimePicker;
