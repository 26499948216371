interface FloatingButtonBarProps {
    height?: any;
    width?: any;
    horizontalOffset?: number;
    singleButton?: boolean;
}

// Renders a "sticky" div that floats at the bottom of the screen. It also reserves space for the bar by rendering
// a relatively-sized div of the same height. This ensures that the bottom part of the content below can be scrolled
// out from underneath the floating buttons, and is not obscured by them.
export const FloatingButtonBar: React.FC<FloatingButtonBarProps> = ({ height = 74, width = '100%', horizontalOffset = 0, singleButton, children }) => {

    // if this component is on another "fixed" component with margin (e.g. Drawer)
    // then horizontalOffset must be passed to position FloatButtonBar horizontally correctly

    return (
        <>
            <div style={{position: 'relative', height}} />
            <div style={{boxShadow: '0px 0px 4px 1px rgb(0 0 0 / 15%)', backgroundColor: 'white', position: 'fixed', height, bottom: 0, width, zIndex: 300, margin: `0 ${horizontalOffset}px` }}>
                <div style={{position: 'relative', width: '100%', height: '100%', top: singleButton ? -9 : -5}}>
                    <div style={{ position: 'relative', top: '50%', transform: 'translateY(-50%)', display: singleButton ? 'block' : 'flex', justifyContent: 'space-between', marginTop: 0,marginLeft: 16, marginRight: 16 }}>
                        {children}
                    </div>
                </div>
            </div>
        </>
    );
};
