import { useEffect, useState } from 'react';

// same as useEffect(), but if async delegate rejects it will be caught by global error handler
// (NOTE: does not support returning an onUnmount handler)
export const useAsyncEffect = (delegate: () => Promise<void>, dependencies: React.DependencyList) => {
    const [, setError] = useState();
    useEffect(() => {
        (async () => {
            try {
                await delegate();
            } catch (e: any) {
                setError(() => {
                    throw e;
                });
            }
        })();
    }, dependencies); // eslint-disable-line react-hooks/exhaustive-deps

};
