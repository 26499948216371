import { IonRefresher, IonRefresherContent } from '@ionic/react';

export interface RefresherEventDetail {
    complete(): void;
}

export type RefresherEvent = CustomEvent<RefresherEventDetail>;

export interface RefresherProps {
    onRefresh: (event: RefresherEvent) => void;
}

export const Refresher: React.FC<RefresherProps> = ({ onRefresh }) => (
    <IonRefresher slot="fixed" onIonRefresh={onRefresh}>
        <IonRefresherContent pullingIcon={null} />
    </IonRefresher>
);
