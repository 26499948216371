import { State } from '@meraki-internal/state';

interface Undo {
    message: string;
    handler: () => Promise<void> | undefined;
    shownAt: number | undefined; // timestamp
}

export class UndoState extends State<Undo> {

    show = ({ message, handler }: { message: string, handler: () => Promise<void> }) => {
        this.setState({
            message,
            handler,
            shownAt: Date.now()
        });
    };

    clear = () => {
        this.setState({ message: '', handler: undefined, shownAt: undefined });
    };

}
