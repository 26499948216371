import { useInstance } from '@meraki-internal/react-dependency-injection';
import { IonIcon } from '@ionic/react';
import { Size } from '../../components/common';
import { fontSizeSetter } from '../../components/icons/Icons';
import { IWeatherCondition } from '../model/IWeatherCondition';
import { Logger } from '../../support/debug/Logger';

interface IIconDefinition {
    icon: string;
    hasDayNight?: boolean;
};

export const WeatherConditionIcon: React.FC<{conditionCode: IWeatherCondition, isDaylight: boolean, size?: Size}> = ({conditionCode, isDaylight, size = 'small'}) => {
    const log = useInstance(Logger);

    const iconMap: {[key in IWeatherCondition]: IIconDefinition} = {
        Blizzard: { icon: 'HeavySnow' },
        BlowingDust: { icon: 'Windy' },
        BlowingSnow: { icon: 'Snow' },
        Breezy: { icon: 'Windy' },
        Clear: { icon: 'Clear', hasDayNight: true },
        Cloudy: { icon: 'Cloudy' },
        Drizzle: { icon: 'Drizzle' },
        Flurries: { icon: 'Flurries' },
        Foggy: { icon: 'Foggy' },
        FreezingDrizzle: { icon: 'FreezingDrizzle' },
        FreezingRain: { icon: 'FreezingRain' },
        Frigid: { icon: 'Frigid' },
        Hail: { icon: 'Hail' },
        Haze: { icon: 'Haze' },
        HeavyRain: { icon: 'HeavyRain' },
        HeavySnow: { icon: 'HeavySnow' },
        Hot: { icon: 'Hot' },
        Hurricane: { icon: 'Windy'  },
        IsolatedThunderstorms: { icon: 'Thunderstorms' },
        IsolatedThunderstorm: { icon: 'Thunderstorms' }, // depreated by apple
        MostlyClear: { icon: 'MostlyClear', hasDayNight: true },
        MostlyCloudy: { icon: 'MostlyCloudy', hasDayNight: true },
        PartlyClear: { icon: 'PartlyCloudy', hasDayNight: true }, // deprecated by apple
        PartlyCloudy: { icon: 'PartlyCloudy', hasDayNight: true },
        Rain: { icon: 'Rain' },
        ScatteredThunderstorms: { icon: 'Thunderstorms' },
        Sleet: { icon: 'Sleet' },
        Smoky: { icon: 'Smoky' },
        Snow: { icon: 'Snow' },
        StrongStorms: { icon: 'Thunderstorms' },
        SunFlurries: { icon: 'SunFlurries' },
        SunShowers: { icon: 'SunShowers' },
        Thunderstorms: { icon: 'Thunderstorms' },
        TropicalStorm: { icon: 'Thunderstorms' },
        Windy: { icon: 'Windy' },
        WintryMix: { icon: 'WintryMix' }
    };

    const definition = iconMap[conditionCode] || { icon: 'Blank' };

    const { icon, hasDayNight } = definition;
    const suffix = (!hasDayNight ? '' : isDaylight ? '_Day' : '_Night');

    if (icon === 'Blank') {
        log.error(`Unrecognized weather conditionCode: ${conditionCode}`);
    }

    return (
        <IonIcon src={`../../assets/icon/weather-condition/${icon}${suffix}.svg`} ref={fontSizeSetter(size)} />
    );
};
