import { ICommonMissionState } from '../../missions/model/ICommonMissionState';
import { Address } from '../../missions/components/Address';
import { Coordinates } from '../../missions/components/Coordinates';

export const Location: React.FC<{mission: ICommonMissionState}> = ({ mission }) => {
    const address = mission.getAddress();
    if (address) {
        return <Address address={address}/>;
    } else {
        return <Coordinates coordinates={mission.getLocation()}/>;
    }
};
