import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useSubscription } from '@meraki-internal/state';
import { DrawerState } from './DrawerState';
import { LocateMeButton } from '../../map/location/LocateMeButton';
import { ZoomInButton } from '../../map/components/ZoomInButton';
import { ZoomOutButton } from '../../map/components/ZoomOutButton';
import { ToggleLayersControlsButton } from '../../map/toggle-layers/ToggleLayersControlsButton';
import { useBreakpoints } from '../../support/hooks/useBreakpoints';
import { CSSProperties } from 'react';
import { ToggleRadiusShape } from '../../map/components/ToggleRadiusShape';
import { useIsNative } from '../../support/hooks/useIsNative';
import { WindyMapButton } from '../../weather/windy/WindyMapButton';

export const AttachedControls: React.FC = () => {

    const drawerState = useInstance(DrawerState);
    useSubscription(() => drawerState);

    const { isLargerScreen } = useBreakpoints();
    const sideDrawer = isLargerScreen;

    const isNative = useIsNative();

    let style: CSSProperties = {};

    if (sideDrawer) {
        style = {
            position: 'fixed',
            height: 0,
            bottom: isNative ? 20 : 80, // make room for intercom launcher on web
            right: 17,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            textAlign: 'center'
        };
    } else {
        style = {
            position: 'absolute',
            height: 0,
            top: -4,
            right: 5,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            textAlign: 'center'
        };
    }

    // TODO: remove coupling to map by providing buttons in drawer prop
    return (
        <div style={style}>
            <LocateMeButton />
            <ToggleRadiusShape />
            <WindyMapButton />
            <ToggleLayersControlsButton />
            {isLargerScreen &&
                <>
                    <ZoomInButton />
                    <ZoomOutButton />
                </>
            }
        </div>
    );
};
