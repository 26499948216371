import moment from 'moment';
import { State } from '@meraki-internal/state';
import { ICoordinate } from '../../support/model/ICoordinate';
import { PaywallDialogViewModel } from '../../paywall/PaywallDialogViewModel';
import { IAsyncSmartStorageProvider, StorageProvider } from '../../support/StorageProvider';
import { RevenueCatModel } from '../../paywall/revenue-cat/RevenueCatModel';
import { TrackingService } from '../../support/tracking/TrackingService';
import { ApMapboxStylerTimeState } from '../../map/styles/ApMapboxStylerTimeState';
import { MapViewModel } from '../../map/model/MapViewModel';
import { MissionPinAndRadiusOnMapViewModel } from '../../map/mission-pin-and-radius/MissionPinAndRadiusOnMapViewModel';
import { WindyMapViewModel } from './WindyMapViewModel';

export const PAYWALL_COUNTDOWN_SECS = 15;
export const PAYWALL_PREVIEW_LIMIT = 3;
export const PAYWALL_RESET_DAYS = 30;

interface IWindyMapModalStorage {
    recentUsage: string[];
}

interface IWindyMapModalState {
    open: boolean;
    startTime?: string;
    center?: ICoordinate;
    zoom?: number;
    radiusMeters?: number;
    radiusColor?: string;
    radiusShape?: 'circle' | 'square';
    countdown: number;
}

export class WindyMapModalState extends State<IWindyMapModalState> {

    private storage: IAsyncSmartStorageProvider<IWindyMapModalStorage>;
    private countdownInterval: any;

    static inject = () => [
        PaywallDialogViewModel,
        RevenueCatModel,
        WindyMapViewModel,
        MapViewModel,
        ApMapboxStylerTimeState,
        MissionPinAndRadiusOnMapViewModel,
        TrackingService,
        StorageProvider,
    ];
    constructor(
        private paywall: PaywallDialogViewModel,
        private revenueCat: RevenueCatModel,
        private windyMap: WindyMapViewModel,
        private map: MapViewModel,
        private mapTime: ApMapboxStylerTimeState,
        private radius: MissionPinAndRadiusOnMapViewModel,
        private tracker: TrackingService,
        storageProvider: StorageProvider
    ) {
        super({ open: false, countdown: 0 });
        this.storage = storageProvider.getAsyncJSONProvider<IWindyMapModalStorage>('windy-modal');
    }

    private resetUsage = () => {
        this.storage.set({ recentUsage: [] });
    };

    private updateUsage = async () => {
        const { recentUsage = [] } = (await this.storage.get() || {});
        const updatedUsage = [...recentUsage.filter(u => u > moment().subtract(PAYWALL_RESET_DAYS, 'd').toISOString()), moment().toISOString()];
        this.storage.set({ recentUsage: updatedUsage });
        return updatedUsage;
    };

    private startCountdown = (callback: () => void) => {
        let countdown = PAYWALL_COUNTDOWN_SECS + 1;
        this.countdownInterval = setInterval(() => {
            countdown--;
            this.setState({ countdown });
            if (countdown < 1) {
                this.stopCountdown();
                callback();
            }
        }, 1000);
    };

    private stopCountdown = () => {
        clearInterval(this.countdownInterval);
        this.setState({ countdown: 0 });
    };

    showPaywall = async () => {
        const { center, radiusMeters } = this.state;
        const pinDropParam = center && radiusMeters ? `?dropPin=${center.lng},${center.lat}` : '';

        const upgraded = await this.paywall.requirePurchase({
            triggerSource: 'windy',
            returnTo: `/map${pinDropParam}`
        });

        if (upgraded) {
            this.resetUsage();
            this.stopCountdown();
        }

        return upgraded;
    };

    isAvailable = () => {
        return this.windyMap.isForecastAvailable({ dateTime: this.mapTime.state.startTime, location: this.map.getCenter() });
    };

    open = async ({ skipPreview = false } = {}) => {
        let upgraded = this.revenueCat.hasUpgraded();

        if (upgraded) {
            this.resetUsage();

        } else {
            const { windyPreviewsPerMonth = PAYWALL_PREVIEW_LIMIT } = this.revenueCat.getFreeTierLimits();
            const updatedUsage = await this.updateUsage();

            if (skipPreview || updatedUsage.length > windyPreviewsPerMonth) {
                upgraded = await this.showPaywall();
                if (!upgraded) {
                    return;
                }
            }

            if (!upgraded) {
                this.startCountdown(async () => {
                    if (this.state.open) {
                        upgraded = await this.showPaywall();
                        if (!upgraded) {
                            this.close();
                            return;
                        }
                    }
                });
            }
        }

        // when invoked by PaywallTriggerHandler, we can get here before the map is initialized
        await this.map.asyncInitialized();

        const radiusProps = this.radius.getRadius()?.properties;

        this.setState({
            open: true,
            startTime: this.mapTime.state.startTime,
            center: this.map.getCenter(),
            zoom: this.map.getZoom(),
            radiusMeters: radiusProps?.radiusMeters,
            radiusColor: radiusProps?.color,
            radiusShape: radiusProps?.isSquare ? 'square' : 'circle'
        });

        const hasPin = Boolean(radiusProps?.radiusMeters);
        this.tracker.track('Mission Planning Weather Map Opened', () => ({ hasPin }));
    };

    close = () => {

        this.stopCountdown();

        this.setState({
            open: false
        });

    };
}
