import Quill from 'quill';

// goal was to paste without formatting... found this https://github.com/quilljs/quill/issues/1184#issuecomment-891859468
// it is targeting 2.0 while we're on 1.3.7 but it appears to work with the exception of scrolling into view (which isn't a priority (yet))
const Clipboard = Quill.import('modules/clipboard');
const Delta = Quill.import('delta');

export class PlainClipboard extends Clipboard {
    onPaste(e: any) {
        e.preventDefault();
        const range = this.quill.getSelection();
        const text = e.clipboardData.getData('text/plain');
        const delta = new Delta()
            .retain(range.index)
            .delete(range.length)
            .insert(text);
        const index = text.length + range.index;
        const length = 0;
        this.quill.updateContents(delta, 'silent');
        this.quill.setSelection(index, length, 'silent');
        this.quill.scrollIntoView();
    }
}
