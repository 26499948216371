import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useEffect } from 'react';
import { PreMissionWeatherState } from '../PreMissionWeatherState';

export const ClearPreMissionWeatherState: React.FC = () => {
    const state = useInstance(PreMissionWeatherState);
    useEffect(() => {
        state.reset();
    },  [state]);
    return null;
};
