import { IonItem, IonLabel } from '@ionic/react';
import moment from 'moment';
import { DoneIcon, ForwardIcon, NotificationIcon } from '../../components/icons/Icons';
import { EditableMissionState } from '../model/EditableMissionState';
import { useSubscription } from '@meraki-internal/state';

export const MissionChecklistButton: React.FC<{ mission: EditableMissionState }> = ({ mission }) => {

    useSubscription(() => mission);

    let totalItems = 0;
    let completedItems = 0;
    let overdueItems = 0;

    if (mission.state.checklist) {
        for (const section of mission.state.checklist.sections) {
            totalItems += section.items.length;
            completedItems += section.items.filter(i => i.completed).length;
            overdueItems += section.items.filter(i => !i.completed && i.notification && moment(i.notification.at).isBefore(moment())).length;
        }
    }

    return (
        <IonItem button detail={false} onClick={() => mission.pages.checklist.go()} data-type="checklist-button">
            <IonLabel>
                <h2>Checklist</h2>
                {totalItems > 0 &&
                    <p style={{display: 'flex', alignItems: 'center', gap: 10}}>
                        <span style={{display: 'flex', alignItems: 'center', gap: 3}}>
                            <DoneIcon color="primary" size="small" />
                            <span>{completedItems}/{totalItems} items</span>
                        </span>
                        {overdueItems > 0 &&
                            <span style={{display: 'flex', alignItems: 'center', gap: 3}}>
                                <NotificationIcon color="danger" size="small" />
                                <span>{overdueItems} reminder{overdueItems !== 1 ? 's' : ''}</span>
                            </span>
                        }
                    </p>
                }
            </IonLabel>
            <ForwardIcon slot="end" color="medium" size="medium" />
        </IonItem>
    );
};
