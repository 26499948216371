import moment from 'moment';
import { APIClient } from '@autopylot-internal/autopylot-api-client';
import { MissionFeatureCollectionFacadeAdapter, PersistableMissionFeatureCollection } from '@autopylot-internal/tiles-client';
import { State } from '@meraki-internal/state';
import { AlertPresenter } from '../../app/AlertBinder';
import { HistoryViewModel } from '../../app/HistoryViewModel';
import { PagePathsBuilder } from '../../app/PagePathsBuilder';
import { MapViewModel } from '../../map/model/MapViewModel';
import { EditableMissionState, INewMission, IMission, IMissionCallbacks } from '../../mission/model/EditableMissionState';
import { IPersistableMission } from './IPersistableMission';
import { NewMissionState } from './NewMissionState';
import { OperatorState } from '../../profile/OperatorState';
import { FAAVersionAlertManager } from '../../app/FAAVersionAlertManager';
import { TrackingService } from '../../support/tracking/TrackingService';
import { UserFacingError } from '../../support/UserFacingError';
import { Logger } from '../../support/debug/Logger';
import { GlobalCachingConfig } from '../../GlobalCachingConfig';
import { StorageProvider } from '../../support/StorageProvider';
import { MissionPinAndRadiusOnMapViewModel } from '../../map/mission-pin-and-radius/MissionPinAndRadiusOnMapViewModel';

const FEET_PER_METER = 3.28084;

// for android the limit is Java String ~1.4 MB)
// and unlimited in iOS (other than iOS TV https://stackoverflow.com/questions/7510123/is-there-any-limit-in-storing-values-in-nsuserdefaults)
// at 20KB per mission (best guess), means we can hold 50 future missions before busting the cache
const MAX_CACHE_SIZE = /* 1 MB */ 1 * 1024 * 1024;

export type IMissionListSection = 'past' | 'canceled' | 'invalid';

interface IMissionsCache {
    missions: IPersistableMission[];
}

export class MissionsStateCache {
    static inject = () => [GlobalCachingConfig, StorageProvider];
    constructor(private globalConfig: GlobalCachingConfig, private storage: StorageProvider) { }

    private cache = this.storage.getAsyncJSONProvider<IMissionsCache>('MissionsState.v1', { maxBytes: MAX_CACHE_SIZE, removeOnTooBig: true });

    get = () => {
        if (this.globalConfig.disabled){
            throw new Error('caching is disabled');
        }
        return this.cache.get();
    };

    set = async (value: IMissionsCache) => {
        if (this.globalConfig.disabled){
            return;
        }
        this.cache.set(value);
    };

    removeMissionFromCache = async ({ missionId }: { missionId: string }) => {
        try{
            let { missions } = (await this.get())!;
            missions = missions.filter(m => m.missionId !== missionId);
            this.set({ missions });
        }
        catch (err: any){

        }
    };

    upsertMissionInCache = async (updated: IPersistableMission) => {
        try{
            let { missions } = (await this.get())!;
            missions = missions.filter(m => m.missionId !== updated.missionId).concat(updated);
            this.set({ missions });
        }
        catch (err: any){

        }
    };
}

interface IMissionsState {
    isFullyLoaded: boolean;
    missions: EditableMissionState[];
    expandedSection?: IMissionListSection;
}

export class MissionsState extends State<IMissionsState> {

    static inject = () => [
        MapViewModel,
        HistoryViewModel,
        PagePathsBuilder,
        APIClient,
        MissionFeatureCollectionFacadeAdapter,
        AlertPresenter,
        OperatorState,
        FAAVersionAlertManager,
        TrackingService,
        MissionsStateCache,
        Logger,
        MissionPinAndRadiusOnMapViewModel
    ];
    constructor(
        private mapViewModel: MapViewModel,
        private history: HistoryViewModel,
        private pagePaths: PagePathsBuilder,
        private apiClient: APIClient,
        private missionFeatureCollectionFacadeAdapter: MissionFeatureCollectionFacadeAdapter,
        private alertPresenter: AlertPresenter,
        private operator: OperatorState,
        private versionAlertManager: FAAVersionAlertManager,
        private tracker: TrackingService,
        private cache: MissionsStateCache,
        private logger: Logger,
        private pinAndRadius: MissionPinAndRadiusOnMapViewModel
    ) {
        super({
            isFullyLoaded: false,
            missions: []
        });
    }

    private missionCallbacks: IMissionCallbacks = {
        onNameChanged: async (_mission: IMission) => {
            const updated = await this.apiClient.patch(_mission.links.self, { name: _mission.name, version: _mission.version }, { accept: 'application/vnd.autopylot.mission.v2+json' });

            await this.refreshMissionState(updated);

            this.setState({});
        },

        onNotesHtmlChanged: async (_mission: IMission) => {
            const updated = await this.apiClient.patch(_mission.links.self, { notesHtml: _mission.notesHtml, version: _mission.version }, { accept: 'application/vnd.autopylot.mission.v2+json' });

            await this.refreshMissionState(updated);
            this.setState({});
        },

        onStatusChanged: async (_mission: IMission) => {
            const updated = await this.apiClient.patch(_mission.links.self, { status: _mission.status, version: _mission.version }, { accept: 'application/vnd.autopylot.mission.v2+json' });

            await this.refreshMissionState(updated);
            this.setState({});
        },

        onDeleted: async (_mission: IMission) => {
            await this.apiClient.delete(_mission);

            this.removeMissionFromState(_mission);
        },

        onRefresh: async (_mission: IMission) => {
            const mission = await this.apiClient.get(_mission.links.self, 'application/vnd.autopylot.mission.v2+json');

            this.cache.upsertMissionInCache(mission);

            this.deserializeIntoState([mission]);
        },

        getConcurrentLAANCMissions: (_mission: Partial<IMission>) => {
            const { startTime, durationMinutes } = _mission;
            const startMoment = moment(startTime);
            const endMoment = moment(startTime).add(durationMinutes, 'minutes');
            return this.state.missions
                .filter(m => {
                    if (m.state.missionId === _mission.missionId) {
                        return false;
                    }
                    if (!m.getMissionRadius()?.getLAANCCompositeAdvisory()) {
                        return false;
                    }
                    if (m.getMissionStatus() !== 'active') {
                        return false;
                    }
                    if (startMoment.isSameOrAfter(m.getEndTime()) || endMoment.isSameOrBefore(m.getStartTime())) {
                        return false;
                    }
                    return true;
                })
                .map(m => m.state);
        }
    };

    private injectDependencies = (mission: NewMissionState | EditableMissionState) => {
        mission.mapViewModel = this.mapViewModel;
        mission.history = this.history;
        mission._paths = this.pagePaths;
        mission.alertPresenter = this.alertPresenter;
        mission.operator = this.operator;
        mission.versionAlertManager = this.versionAlertManager;
        mission.tracker = this.tracker;
        mission.pinAndRadius = this.pinAndRadius;
        mission.onInjected();
    };

    getMissionById = async (missionId: string): Promise<EditableMissionState> => {
        let mission = this.state.missions.find(m => m.state.missionId ===  missionId);

        if (!mission && !this.state.isFullyLoaded) {
            await this.loadAll();
            mission = this.state.missions.find(m => m.state.missionId ===  missionId);
        }

        if (!mission) {
            // TODO: 404
            throw new Error(`mission ${missionId} wasn't found among ${this.state.missions.length} missions`);
        }

        return mission;
    };

    getReminderCount = () => {
        return this.state.missions.reduce((total, m) => total + (m.getReminderCount() ? 1 : 0), 0);
    };

    hasPendingInvalidatedMissions = () => {
        return this.state.missions.some(m => m.getMissionStatus() === 'active' && m.getAPLAANCStatus() !== 'authorizing-failed' && m.getCanFly() === 'cannot-fly');
    };

    beginCreatingMission = () => {
        if (this.hasPendingInvalidatedMissions()){
            throw new UserFacingError({
                errorCode: 'invalidated-missions',
                displayMessage: 'There are mission(s) that must be canceled before you can create any new missions, per the FAA'
            });
        }

        const onSave = async (_new: INewMission) => {
            const entry = await this.apiClient.entry();
            const center = _new.radiusPolygon.getMissionCenter();
            const radiusMeters = _new.radiusPolygon.getMissionRadius().properties.radiusMeters;
            const _mission = await this.apiClient.post(entry.links.missions, {
                ..._new,
                radiusPolygon: undefined,
                center,
                radiusFeet: Math.round(radiusMeters * FEET_PER_METER),
                isSquare: _new.radiusPolygon.getMissionRadius().properties.isSquare
            }, { accept: 'application/vnd.autopylot.mission.v2+json' });

            this.cache.upsertMissionInCache(_mission);

            _mission.radiusPolygon = this.missionFeatureCollectionFacadeAdapter.adapt(new PersistableMissionFeatureCollection(_mission.radiusPolygon));

            const mission = this.addMissionToState(_mission);

            this.history.push(`#/missions/${mission.state.missionId}?alert=true`);
        };

        const { getConcurrentLAANCMissions } = this.missionCallbacks;

        const newMission = new NewMissionState({ onSave, getConcurrentLAANCMissions });
        this.injectDependencies(newMission);

        return newMission;
    };

    addMissionToState = (_mission: IMission) => {
        const mission = new EditableMissionState(_mission, this.missionCallbacks);
        this.injectDependencies(mission);

        this.setState({
            missions: this.state.missions?.concat(mission)
        });

        return mission;
    };

    removeMissionFromState = (_mission: IMission) => {
        this.setState({
            missions: this.state.missions.filter(m => m.state.startTime !== _mission.startTime)
        });
        this.cache.removeMissionFromCache(_mission);
    };

    refreshMissionState = async (_updated: IPersistableMission) => {
        const { radiusPolygon, ...rest } = _updated;

        const mission = await this.getMissionById(_updated.missionId);

        if (!mission) {
            throw new Error(`missionId ${_updated.missionId} not found`);
        }

        this.cache.upsertMissionInCache(_updated);

        mission.setState({
            radiusPolygon: this.missionFeatureCollectionFacadeAdapter.adaptFromPersisted(radiusPolygon),
            ...rest
        });
    };

    // load or reload all missions
    loadAll = async () => {
        const entry = await this.apiClient.entry();
        const _missions = await this.apiClient.get(entry.links.missions, 'application/vnd.autopylot.mission.v2+json');
        this.deserializeIntoState(_missions, true);
    };

    // load or reload only recent and upcoming missions
    private loadRecentAndUpcomingFromRemote = async () => {

        // on app start and timed refreshes, we load upcoming plus 7 days of past missions, so that:
        // - the status is updated on missions that have completed since the last refresh
        // - accordion sections are partially loaded when they are first expanded (a nicer U/X)
        const daysInPast = 7;

        const entry = await this.apiClient.entry();
        const _missions = await this.apiClient.get(entry.links.missions, 'application/vnd.autopylot.mission.v2+json', { daysInPast });

        this.cache.set({ missions: _missions });

        this.deserializeIntoState(_missions);
    };
    loadRecentAndUpcoming = async () => {
        try{
            const { missions } = (await this.cache.get())!;
            this.deserializeIntoState(missions);
            Promise.resolve()
                .then(async () => {
                    await this.loadRecentAndUpcomingFromRemote();
                })
                .catch((err: any) => {
                    const error: any = new Error(`MissionsState.loadRecentAndUpcoming() failed, but used cache. <Inner>${err.toString()}</Inner>`);
                    error.inner = error;
                    error.errorCode = error.inner.errorCode;

                    this.logger.error(error);
                });

        } catch (err: any){
            console.log('Mission state, cache miss', err);
            await this.loadRecentAndUpcomingFromRemote();
        }
    };

    // Update list state, and mission state of all missions in the list
    private deserializeIntoState = (serializable: IPersistableMission[], includesAllMissions?: boolean) => {

        // if reloading all missions, start with an empty array so we drop any that
        // have been deleted in another session (missing from serializable)
        const missions = (includesAllMissions ? [] : this.state.missions);

        for (const m of serializable) {
            // turn the serialized radiusPolygon into the rich MissionFeatureCollectionFacade
            const radiusPolygon = this.missionFeatureCollectionFacadeAdapter.adapt(new PersistableMissionFeatureCollection(m.radiusPolygon));

            // since this can be called multiple times, detect existing mission state and update in place, to avoid orphans
            let mission = this.state.missions.find(e => e.state.missionId === m.missionId);

            if (mission) {
                mission.refreshState({
                    ...m,
                    radiusPolygon
                });
                if (includesAllMissions) {
                    missions.push(mission);
                }
            } else {
                mission = new EditableMissionState({
                    ...m,
                    radiusPolygon
                }, this.missionCallbacks);
                this.injectDependencies(mission);
                missions.push(mission);
            }
        };

        this.setState({
            missions,
            isFullyLoaded: (this.state.isFullyLoaded || includesAllMissions)
        });
    };

    pages = {
        new: this.pagePaths.build<{dropPin?: string}>((context) => `#/map${context?.dropPin ? `?dropPin=${context.dropPin}` : ''}`)
    };
}
