import { useIonPicker } from '@ionic/react';
import { useBreakpoints } from '../../support/hooks/useBreakpoints';
import { DownChevronIcon } from '../icons/Icons';
import { Button } from '../button/Button';
import { states } from '../../support/helpers/states';
import { Color, Size } from '../common';

const buildPicker = (stateCode: string | undefined, excludeTerritories: boolean, animated: boolean, onChange: (newStateCode: string) => void) => {
    // this must be regenerated every time the picker is presented, or the UX glitches out
    const stateList = states
        .filter(state => !excludeTerritories || !state.isTerritory)
        .map(state => ({ text: state.name, value: state.code, selected: (state.code === stateCode)}));

    return {
        columns: [
            {
                name: 'state',
                options: stateList,
                selectedIndex: stateList.findIndex(s => s.selected)
            },
        ],
        buttons: [
            {
                text: 'Cancel',
                cssClass: 'picker-cancel'
            },
            {
                text: 'Done',
                cssClass: 'picker-done',
                handler: (selected: any) => {
                    const { state } = selected;
                    onChange(state.value);
                }
            }
        ],
        animated
    };
};

interface IStatePickerProps {
    size?: Size;
    color?: Color;
    excludeTerritories?: boolean;
    placeholder?: string;
    stateCode?: string;
    onChange: (stateCode?: string) => void;
}

export const StatePicker: React.FC<IStatePickerProps> = ({ size, color, excludeTerritories = false, placeholder = 'Home State', stateCode, onChange }) => {
    const [present] = useIonPicker();

    const { isLargerScreen } = useBreakpoints();

    const selectedState = states.find(s => s.code === stateCode);

    return (
        <Button color={color} minWidth={0} size={size} fill="clear" dataType="state-picker" onClick={() => present(buildPicker(stateCode, excludeTerritories, !isLargerScreen, onChange))}>
            <b style={{whiteSpace: 'pre-wrap'}}>{selectedState ? selectedState.name : placeholder}</b><DownChevronIcon slot="end" size="small" />
        </Button>
    );
};
