import * as mapboxgl from 'mapbox-gl';
import { ApMapboxStyler } from './ApMapboxStyler';
import { TrackingService } from '../../support/tracking/TrackingService';
import { State } from '@meraki-internal/state';
import { StorageProvider } from '../../support/StorageProvider';

export type IMapboxStyleId = 'streets-v12' | 'satellite-streets-v12' | 'outdoors-v12';
// 'light-v11',
// 'dark-v11',
// 'satellite-v9',
// 'navigation-day-v1',
// 'navigation-night-v1',

class BaseMapStorage {
    static inject = () => [StorageProvider];
    constructor(private storage: StorageProvider){}
    activeStyleId = this.storage.getStringProvider('basemap.styleId', { storageType: 'localStorage' });
}

export class BaseMapState extends State<{}> {
    static inject = () => [
        ApMapboxStyler,
        TrackingService,
        BaseMapStorage
    ];

    constructor(
        private styler: ApMapboxStyler,
        private tracker: TrackingService,
        private storageV2: BaseMapStorage
    ){
        super({});
        this.activeStyleId = this.storageV2.activeStyleId.exists() ? this.storageV2.activeStyleId.get() as IMapboxStyleId : 'streets-v12';
    }

    map!: mapboxgl.Map;

    private activeStyleId: IMapboxStyleId;

    getCurrentBaseMapUrl = (): string => {
        return `mapbox://styles/mapbox/${this.activeStyleId}`;
    };

    switchBaseMap = async (styleId: IMapboxStyleId): Promise<void> => {
        // inspired by https://github.com/mapbox/mapbox-gl-js/issues/4006#issuecomment-772462907
        // if we simply call .setStyle()
        // then our sources and layers are lost
        // so this function aims to reconcile the new style with the sources and layers we've added
        const fromBaseMap = this.activeStyleId;
        this.activeStyleId = styleId;

        this.tracker.track('Base Map Selected', () => ({
            fromBaseMap,
            toBaseMap: styleId
        }));

        const newStyle = await fetch(`https://api.mapbox.com/styles/v1/mapbox/${styleId}?access_token=${mapboxgl.accessToken}`).then(res => res.json());
        newStyle.sources = {
            ...this.styler.getSources(this.map),
            ...newStyle.sources
        };

        newStyle.layers = [
            ...newStyle.layers,
            ...this.styler.getLayers(this.map)
        ];

        this.map.setStyle(newStyle, { diff: true });
        this.storageV2.activeStyleId.set(styleId);
        this.setState({ });
    };

    getCurrentStyleId = (): IMapboxStyleId => {
        return this.activeStyleId;
    };
}
