import { ActionMenu } from '../../components/action-menu/ActionMenu';
import { TimeIcon } from '../../components/icons/Icons';
import { EditableMissionState } from '../model/EditableMissionState';

export const MissionTimeActionSheet = ({ mission }: { mission: EditableMissionState}) => {

    // for a canceled mission, we don't want to allow editing, which leaves nothing on the menu, so don't show it
    const isOpen =mission.state.isShowingTimeActions && mission.getMissionStatus() !== 'user-canceled';

    return (
        <ActionMenu trigger="action-button" isOpen={isOpen}
            onClose={mission.hideTimeActions}
            actions={[
                {
                    text: 'Edit Mission Date/Times',
                    icon: TimeIcon.raw,
                    handler: () => {
                        mission.pages.editTimes.go();
                    }
                }
            ]}
        />
    );
};
