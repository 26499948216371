import { IonButton, IonButtons, IonHeader, IonTitle, IonToolbar } from '@ionic/react';
import { useBreakpoints } from '../../support/hooks/useBreakpoints';
import { MenuButton } from '../button/MenuButton';
import { BackIcon, ActionsIcon, CloseIcon } from '../icons/Icons';
import { createUseStyles } from 'react-jss';
import { MAX_PAGE_WIDTH } from './PageContent';

const useStyles = createUseStyles({
    titleBar: {
        '&.md': {
            left: 0,
            top: 0,
            position: 'absolute',
            width: '100%',
            height: '100%',
            textAlign: 'center'
        }
    }
});

export interface IPageHeaderProps {
    title?: string;
    onBack?: () => void;
    backLabel?: string;
    onAction?: () => void;
    actionLabel?: string;
    onClose?: () => void;
    showReminderBadge?: boolean;
    fullWidth?: boolean;
    transparent?: boolean;
}

export const PageHeader: React.FC<IPageHeaderProps> = ({ title, onBack, backLabel, onAction, actionLabel, onClose, showReminderBadge, fullWidth, transparent}) => {
    const classes = useStyles();

    const { isLargerScreen } = useBreakpoints();

    // our longest title was getting truncated with an ellipsis with the default padding of 90px
    const titlePadding = 70;

    if (onClose && onAction) {
        throw new Error('Cannot set both onClose and onAction');
    }

    // if page is not full width, add padding so toolbar matches content
    const toolbarStyle = fullWidth ? undefined : {
        '--padding-start': `calc(50% - ${MAX_PAGE_WIDTH/2-10}px)`,
        '--padding-end': `calc(50% - ${MAX_PAGE_WIDTH/2-10}px)`
    };

    return (
        <IonHeader className={transparent ? 'ion-no-border' : undefined}>
            <IonToolbar color="white" style={toolbarStyle}>
                <IonButtons slot="start">
                    {!onBack && !onClose && !isLargerScreen &&
                        <MenuButton showReminderBadge={showReminderBadge} />
                    }
                    {onBack && (
                        <IonButton data-id="back-button" shape="round" color="medium" onClick={() => onBack()}>
                            {backLabel &&
                                <span style={{color: 'var(--ion-color-step-750'}}>{backLabel}</span>
                            }
                            {!backLabel &&
                                <BackIcon slot="icon-only" size="medium" />
                            }
                        </IonButton>
                    )}
                </IonButtons>
                <IonTitle color="dark" className={classes.titleBar} style={{paddingLeft: titlePadding, paddingRight: titlePadding}}>{title || ''}</IonTitle>
                <IonButtons slot="end">
                    {(onAction || actionLabel) && (
                        <IonButton id="action-button" shape="round" color="medium" disabled={!onAction} data-id="action-button" onClick={onAction ? () => onAction() : undefined}>
                            {actionLabel &&
                                <span style={{color: 'var(--ion-color-step-750'}}>{actionLabel}</span>
                            }
                            {!actionLabel &&
                                <span data-id="menu-icon" slot="icon-only"><ActionsIcon size="medium" /></span>
                            }
                        </IonButton>
                    )}
                    {onClose && (
                        <IonButton data-id="close-button" shape="round" color="medium" onClick={() => onClose()}>
                            <CloseIcon slot="icon-only" size="medium" />
                        </IonButton>
                    )}
                </IonButtons>
            </IonToolbar>
        </IonHeader>
    );
};
