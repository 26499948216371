import { DrawerLabel } from '../../components/drawer/DrawerLabel';
import { NewMissionState } from '../../missions/model/NewMissionState';
import { FlightChecklist } from '../../flight-checklist/FlightChecklist';
import { MissionAltitudeSlider } from './MissionAltitudeSlider';
import { usePageView } from '../../support/tracking/usePageView';
import { DrawerButtons } from '../../components/drawer/DrawerButtons';
import { Button } from '../../components/button/Button';
import { useSubscription } from '@meraki-internal/state';
import { FlightChecklistDrawerLabel } from '../../components/drawer/FlightChecklistDrawerLabel';

export const MissionAltitudePage: React.FC<{vm: NewMissionState, onBack: () => void, onContinue: () => void}> = ({ vm, onBack, onContinue }) => {
    usePageView('Mission Altitude');

    useSubscription(() => vm);

    const missionRadius = vm.getMissionRadius();
    if (!missionRadius) {
        return null;
    }

    const disabled = (missionRadius?.canFly() === 'cannot-fly' || vm.state.maxAltitude === 0);

    return (
        <div data-id="mission-altitude-drawer">
            <DrawerLabel text="Mission Altitude" />
            <MissionAltitudeSlider max={missionRadius.getMaxAltitude()} value={vm.getMaxAltitude()} onChange={v => vm.setMaxAltitude(v)} />

            <div style={{marginTop: 20}}>
                <FlightChecklistDrawerLabel />
                <FlightChecklist mission={vm} />

                <DrawerButtons>
                    <Button color="secondary" fill="outline" dataType="cancel-button" onClick={onBack}>Back</Button>
                    <Button dataType="continue-button" disabled={disabled} onClick={onContinue}>Continue</Button>
                </DrawerButtons>
            </div>
        </div>
    );
};
