import React, { useEffect, useState } from 'react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { MapViewModel } from './model/MapViewModel';
import { LoadingPage } from '../app/LoadingPage';
import { AppStartTimings } from '../support/debug/AppStartTimings';

export const RenderChildrenWhenMapsLoaded: React.FC = ({ children }) => {
    const [isReady, setIsReady] = useState(false);

    const mapboxMapViewModel = useInstance(MapViewModel);
    const timings = useInstance(AppStartTimings);

    useEffect(() => {
        timings.add('<RenderChildrenWhenMapsLoaded />');
        if (mapboxMapViewModel.isDisabled()){
            setIsReady(true);
            return;
        }

        // what about windy?
        // Originally we assumed we wanted to wait for windy to load here, but we realized this meant
        // if windy failed to load, the app would hang on a white screen at startup, and be unusuable.
        // So instead we check windy.isReady when we need to use it.

        mapboxMapViewModel.asyncInitialized().then(() => {
            setIsReady(true);
            timings.add('<RenderChildrenWhenMapsLoaded /> done');
        });

        // disabling b/c we only want to run this once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!isReady) {
        return <LoadingPage />;
    }

    return (<>{children}</>);
};
