import { useInstance } from '@meraki-internal/react-dependency-injection';
import { Page } from '../components/page/Page';
import { HistoryViewModel } from '../app/HistoryViewModel';
import { useEffect, useState } from 'react';
import { usePageView } from '../support/tracking/usePageView';
import { Button } from '../components/button/Button';
import { Share } from '@capacitor/share';
import { TrackingService } from '../support/tracking/TrackingService';

export const InvitePage: React.FC = () => {
    usePageView('Invite Friends');

    const history = useInstance(HistoryViewModel);
    const tracker = useInstance(TrackingService);

    const [canShare, setCanShare] = useState(false);

    useEffect(() => {
        Share.canShare().then((result: any) => {
            setCanShare(result.value);
        });
    }, []);

    const share = async () => {
        try {
            const title = 'Check out AutoPylot!';
            const text = 'Check out AutoPylot, an app for drone pilots! It really simplifies airspace and lets you organize your flights.';
            const url = 'https://autopylot.io';
            const { activityType } = await Share.share({ title, text, url });

            tracker.track('Invite Shared', () => ({ activityType }));

        } catch (e: any) {
            // If the user cancelled sharing, don't throw
            if (e.toString().toLowerCase().includes('share canceled')) {
                return;
            }
            throw e;
        }
    };

    return (
        <Page title="Invite Friends" onBack={() => history.back('/settings')}>
            <div style={{margin: '30px 25px'}}>
                <p style={{marginBottom: 30}}>Let your drone pilot friends know about how AutoPylot really simplifies airspace and lets you organize your flights!</p>
                <Button expand="block" disabled={!canShare} onClick={share}>Share Download Link</Button>
            </div>
        </Page>
    );
};
