import { ICoordinate, ISO8601DateTimeString } from '@autopylot-internal/tiles-client';
import moment from 'moment';
import { Map as ILeafletMap } from 'leaflet';
import { LatLngExpression, MarkerOptions, Marker, CircleOptions, Circle, LatLng, LatLngBoundsExpression, PolylineOptions, Rectangle, DivIcon, DivIconOptions } from 'leaflet';
import { formatLocalDay, getTimezone } from '../../support/helpers/format';
import { UserFacingError } from '../../support/UserFacingError';
import { State } from '@meraki-internal/state';

// we can't easily export the entire leaflet API as a type, so add this as needed
interface ILeafletGlobal {
    divIcon(options: DivIconOptions): DivIcon;
    marker(latlng: LatLngExpression, options?: MarkerOptions): Marker;
    circle(latlng: LatLngExpression, options?: CircleOptions): Circle;
    rectangle(bounds: LatLngBoundsExpression, options?: PolylineOptions): Rectangle;
    latLng(latLng: LatLngExpression): LatLng;
    DomUtil: { addClass(el: HTMLElement, name: string): void };
}

// this isn't a complete interface, just what we've used so far
interface IWindyApi {
    map: ILeafletMap;
    store: {
        set: (key: 'timestamp' | 'overlay' | 'level' | 'particlesAnim', value: any) => boolean,
        get: (key: 'overlay' | 'level') => any
    }
}

interface IResetParams {
    center: ICoordinate;
    radiusMeters?: number;
    radiusColor?: string;
    radiusShape?: 'circle' | 'square';
    startTime: ISO8601DateTimeString;
    zoom?: number;
    hideMarkers?: boolean;
}

export class WindyMapViewModelConfig {
    DISABLE_MAP?: boolean;
}

export type IWindyLayer = 'snowcover' | 'wind' | 'temp' | 'pressure' | 'clouds' | 'lclouds' | 'mclouds' | 'hclouds' | 'rh' | 'gust' | 'cbase' | 'cape' | 'dewpoint' | 'rain' | 'visibility' | 'deg0' | 'cloudtop' | 'thunder' | 'snowAccu' | 'rainAccu' | 'ptype' | 'sst' | 'gustAccu' | 'ccl';

export type IWindyWindAltitude = 'surface' | '100m';

export class WindyMapViewModel extends State<{layer: IWindyLayer}> {
    static inject = () => [WindyMapViewModelConfig];

    constructor(private config: WindyMapViewModelConfig){
        super({ layer: 'wind' });

        this.isReady = new Promise(resolve => {
            this.resolveIsReady = resolve;
        });
    }

    isDisabled = () => {
        return this.config.DISABLE_MAP === true;
    };

    isReady: Promise<void>;
    private resolveIsReady!: ()=>void;

    // these do not get set until <BindWindyMapViewModel/> calls setLeaflet(),
    // so check isReady has resolved before attempting to access them
    private leaflet?: ILeafletGlobal;
    private windy?: IWindyApi;
    private mapEl?: Node;
    private permanentMapContainerEl?: Node;

    // these are set by reset and cleared when unmounted from a page
    private missionMarker: Marker | undefined;
    private missionRadiusMarker: Circle | Rectangle | undefined;

    getLayer = (): IWindyLayer => {
        // windy.store.get('overlay') seems to return a stale value,
        // so use our own state for this attribute
        return this.state.layer;
    };

    setLayer = (layer: IWindyLayer) => {
        this.windy!.store.set('overlay', layer);
        this.windy!.store.set('particlesAnim', layer === 'wind' ? 'on' : 'off');
        this.setState({ layer });
    };

    getWindAltitude = (): IWindyWindAltitude => {
        return this.windy!.store.get('level');
    };

    setWindAltitude = (level: IWindyWindAltitude) => {
        this.windy!.store.set('level', level);
        this.setState({});
    };

    isForecastAvailable = ({ dateTime, location }: { dateTime: string, location: ICoordinate }) => {
        try {
            this.checkForecastAvailable({ dateTime, location });
            return true;
        }
        catch (e) {
            return false;
        }
    };

    checkForecastAvailable = ({ dateTime, location }: { dateTime: string, location: ICoordinate }) => {
        const timezone = getTimezone(location);
        const offsetDays = moment.tz(dateTime, timezone).startOf('d').diff(moment.tz(timezone).startOf('d'), 'd');

        if (offsetDays < 0 || offsetDays > 9) {

            let displayMessage: string;
            if (offsetDays < 0) {
                displayMessage = 'Not available for missions in the past';
            } else {
                const availableOn = moment.tz(dateTime, timezone).subtract(9, 'days').startOf('day');
                displayMessage = `Forecast will be available ${formatLocalDay({ date: availableOn.toISOString(), location })}`;
            }

            throw new UserFacingError({
                status: 400,
                errorCode: 'forecast.unavailable',
                message: 'Forecast unavailable',
                displayMessage
            });
        }
    };

    reset = ({ center, radiusMeters, radiusColor = '#23ae09', radiusShape = 'circle', startTime, zoom = 10, hideMarkers = false }: IResetParams) => {
        this.checkForecastAvailable({ dateTime: startTime, location: center });

        this.isReady.then(() => {

            // now that we know windy is ready, we can access the private properties above using !. syntax

            this.windy!.map.panTo([center.lat, center.lng]);

            this.removeMarkers();

            if (!hideMarkers && radiusMeters) {

                // add the marker pin (use svg so we can set the color)
                // iconSize adjusted so it's centered where we want it
                const icon = this.leaflet!.divIcon({
                    iconSize: [27,70],
                    html: `
                        <svg display="block" height="41px" width="27px" viewBox="0 0 27 41" xmlns="http://www.w3.org/2000/svg">
                        <defs><radialGradient id="shadowGradient"><stop offset="10%" stop-opacity="0.4"></stop><stop offset="100%" stop-opacity="0.05"></stop></radialGradient></defs>
                        <ellipse cx="13.5" cy="34.8" rx="10.5" ry="5.25" fill="url(#shadowGradient)"></ellipse>
                        <path fill="${radiusColor}" d="M27,13.5C27,19.07 20.25,27 14.75,34.5C14.02,35.5 12.98,35.5 12.25,34.5C6.75,27 0,19.22 0,13.5C0,6.04 6.04,0 13.5,0C20.96,0 27,6.04 27,13.5Z"></path>
                        <path opacity="0.25" d="M13.5,0C6.04,0 0,6.04 0,13.5C0,19.22 6.75,27 12.25,34.5C13,35.52 14.02,35.5 14.75,34.5C20.25,27 27,19.07 27,13.5C27,6.04 20.96,0 13.5,0ZM13.5,1C20.42,1 26,6.58 26,13.5C26,15.9 24.5,19.18 22.22,22.74C19.95,26.3 16.71,30.14 13.94,33.91C13.74,34.18 13.61,34.32 13.5,34.44C13.39,34.32 13.26,34.18 13.06,33.91C10.28,30.13 7.41,26.31 5.02,22.77C2.62,19.23 1,15.95 1,13.5C1,6.58 6.58,1 13.5,1Z"></path>
                        <circle fill="white" cx="13.5" cy="13.5" r="5.5"></circle>
                        </svg>
                    `
                });
                this.missionMarker = this.leaflet!.marker([center.lat, center.lng], { icon }).addTo(this.windy!.map);

                // add a circle/square for the radius (though mostly imperceptible at the zoom that is relevant for weather)
                if (radiusShape === 'circle') {
                    const circle = this.leaflet!.circle([center.lat, center.lng], { color: 'white', weight: 1, fillColor: radiusColor, fillOpacity: 0.3, radius: radiusMeters });
                    this.missionRadiusMarker = circle.addTo(this.windy!.map);
                } else {
                    const square = this.leaflet!.rectangle(this.leaflet!.latLng(center).toBounds(radiusMeters * 2), { color: 'white', weight: 1, fillColor: radiusColor, fillOpacity: 0.3 });
                    this.missionRadiusMarker = square.addTo(this.windy!.map);
                }
            }

            this.windy!.map.setZoom(zoom);

            this.windy!.store.set('timestamp', moment(startTime).valueOf());

            this.windy!.store.set('overlay', 'wind');
            this.windy!.store.set('particlesAnim', 'on');
            this.windy!.store.set('level', 'surface');

            this.setState({ layer: 'wind' });
        });
    };

    setLeaflet = ({ leafletGlobal, el, windy }: { leafletGlobal: ILeafletGlobal, el: HTMLElement, windy: IWindyApi }) => {
        this.leaflet = leafletGlobal;
        this.windy = windy;
        this.mapEl = el;
        this.permanentMapContainerEl = el.parentNode!;

        new ResizeObserver(() => {
            this.windy!.map.invalidateSize(true);
        }).observe(this.mapEl as Element);

        this.resolveIsReady();
    };

    setZoom = (zoom: number) => {
        this.windy!.map.setZoom(zoom);
        this.setState({ });
    };

    showMapInDomContainer = async (temporaryParentContainer: HTMLElement) => {
        this.isReady.then(() => {

            // now that we know windy is ready, we can access the private properties above using !. syntax

            temporaryParentContainer.appendChild(this.mapEl!);
        });
    };

    private removeMarkers = () => {
        if (this.missionMarker){
            this.missionMarker.remove();
        }
        if (this.missionRadiusMarker){
            this.missionRadiusMarker.remove();
        }
    };

    removeMapFromDomContainer = () => {
        this.isReady.then(() => {

            // now that we know windy is ready, we can access the private properties above using !. syntax

            this.permanentMapContainerEl!.appendChild(this.mapEl!);

            this.removeMarkers();
        });
    };
}
