import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { useEffect, useState } from 'react';
import { Group } from '../components/ComponentLibrary';

const ColorAsText = ({ colorVar }: { colorVar: string }) => {
    const [hex, setHex] = useState('');
    useEffect(() => {
        const _hex = getComputedStyle(document.body).getPropertyValue(colorVar);
        setHex(_hex);
    }, [setHex, colorVar]);

    return <span>{hex}</span>;
};

const ColorCol = ({ colorVar }: { colorVar: string }) => (
    <IonCol style={{ backgroundColor: `var(${colorVar})`, color: 'grey' }}>
        <span>{colorVar}</span>
        <br />
        <ColorAsText colorVar={colorVar} />
    </IonCol>
);

const colors = ['primary', 'secondary', 'tertiary', 'success', 'warning', 'danger', 'medium', 'light', 'advisory-warning', 'advisory-laanc', 'insurance'];
// TODO: don't demo colors we don't yet use / support

const DemoColorPallete: Group & React.FC = () => {
    return (
        <>
            <IonGrid>
                {colors.map(color => (
                    <>
                        <h1>{color}</h1>
                        <IonRow key={color}>
                            <ColorCol colorVar={`--ion-color-${color}`} />
                            <ColorCol colorVar={`--ion-color-${color}-contrast`} />
                            <ColorCol colorVar={`--ion-color-${color}-shade`} />
                            <ColorCol colorVar={`--ion-color-${color}-tint`} />
                            {['success','warning', 'danger'].includes(color) && (
                                <ColorCol colorVar={`--ion-color-${color}-map`} />
                            )}
                        </IonRow>
                    </>
                ))}

            </IonGrid>
        </>
    );
};

DemoColorPallete.componentName = 'Color Palette';

export default DemoColorPallete;
