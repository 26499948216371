import { APIClient } from '@autopylot-internal/autopylot-api-client';
import { AlertPresenter } from '../../app/AlertBinder';
import { TrackingService } from '../../support/tracking/TrackingService';
import { EditableMissionState } from '../model/EditableMissionState';
import { IChecklistItem } from '../../checklists/model';
import { IMissionChecklist } from './IMissionChecklist';
import { ChecklistState } from '../../checklists/ChecklistState';
import { UndoState } from '../../undo/UndoState';

export class MissionChecklistStateFactory {

    static inject = () => [
        APIClient,
        AlertPresenter,
        TrackingService,
        UndoState
    ];
    constructor(
        private apiClient: APIClient,
        private alert: AlertPresenter,
        private tracker: TrackingService,
        private undo: UndoState
    ) {}

    // map of all previously requested instances
    private instanceMap: {[key: string]: ChecklistState<IMissionChecklist>} = {};

    getInstance = (mission: EditableMissionState) => {

        // if instance for this mission already in map, return it
        const existing = this.instanceMap[mission.state.missionId];
        if (existing) {
            return existing;
        }

        const callbacks = {
            // to reduce coupling, we don't want the mission to be responsible for saving the checklist,
            // but the link we need is in the mission, so we create a save method here and pass it into
            // the MissionChecklistState constructor
            onSave: async (updatedChecklist: IMissionChecklist): Promise<IMissionChecklist> => {
                if (!mission.state.checklist) {
                    this.tracker.track('Checklist Created', () => ({ missionId: mission.state.missionId }));
                }
                mission.setChecklist(updatedChecklist);
                return await this.apiClient.put(mission.state.links.checklist, updatedChecklist);
            },

            onForceNotification: async (item: IChecklistItem) => {
                const { id: itemId } = item;
                await this.apiClient.post(mission.state.links.forceChecklistItemNotification, undefined, undefined, { itemId });
            }
        };

        let checklist = mission.state.checklist;

        if (!checklist) {
            checklist = {
                missionId: mission.state.missionId,
                sections: []
            };
        }

        const instance =  new ChecklistState(checklist);
        instance.injectDependencies(this.tracker, this.alert, this.undo, callbacks);

        // add to map so next request gets same instance
        this.instanceMap[mission.state.missionId] = instance;

        return instance;
    };
}
