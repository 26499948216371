import { useEffect } from 'react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { MissionsState } from '../missions/model/MissionsState';
import { TrackingService } from '../support/tracking/TrackingService';
import { AlertPresenter } from './AlertBinder';
import { IntercomService } from './intercom/IntercomService';
import { FeedbackAlertStorage } from './FeedbackAlertStorage';
import { ReviewLauncher } from './ReviewLauncher';

export const FeedbackAlert: React.FC = () => {

    const alert = useInstance(AlertPresenter);
    const storageV2 = useInstance(FeedbackAlertStorage);

    const missionsState = useInstance(MissionsState);
    const missionCount = missionsState.state.missions.length;

    const intercom = useInstance(IntercomService);
    const tracker = useInstance(TrackingService);

    const reviewLauncher = useInstance(ReviewLauncher);

    const { enjoyFirstMissionShown } = storageV2.feedbackAlerts.exists() ? storageV2.feedbackAlerts.get()! : { enjoyFirstMissionShown: undefined };

    useEffect(() => {
        if (!enjoyFirstMissionShown && missionCount === 1) {

            storageV2.feedbackAlerts.set({ enjoyFirstMissionShown: new Date().toISOString() });

            alert.showAlert({
                header: `We'd Love to Know`,
                message: 'Did you enjoy creating a mission with AutoPylot?',
                buttons: [
                    {
                        text: 'Not Really',
                        handler: () => {
                            tracker.track('Feedback Requested', () => ({ question: 'enjoyFirstMission', response: 'No' }));
                            if (intercom.isEnabled()) {
                                intercom.displayMessageComposer({ message: `I didn’t really enjoy creating a mission in AutoPylot, this is what I think you need to improve:` });
                            }
                        }
                    },
                    {
                        text: 'Yes',
                        handler: async () => {
                            tracker.track('Feedback Requested', () => ({ question: 'enjoyFirstMission', response: 'Yes' }));
                            if (reviewLauncher.isSupported()) {
                                reviewLauncher.openRatingDialog();
                            }
                        }
                    }
                ]
            });
        }

    }, [alert, enjoyFirstMissionShown, intercom, missionCount, reviewLauncher, storageV2, tracker]);

    return null;
};
