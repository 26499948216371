import moment from 'moment';
import { State } from '@meraki-internal/state';
import { ISO8601DateTimeString } from '@autopylot-internal/tiles-client';
import { WeatherService } from '../WeatherService';
import { IHourlyForecast } from '../model/IHourlyForecast';
import { ICoordinate } from '../../support/model/ICoordinate';
import { getTimezone } from '../../support/helpers/format';

export type IMetricCode =
    'temperature' |
    'humidity' |
    'precipAmount' |
    'precipChance' |
    'windSpeed' |
    'windGusts' |
    'visibility' |
    'cloudCover';

export interface IMetricConfig {
    label: string;
    attribute: keyof IHourlyForecast;
    chartType: 'line' | 'bar';
    isPercentage?: boolean;
    startAtZero?: boolean;
    axisPrecision?: number;
}

export const METRIC_CONFIGS: {[metricCode in IMetricCode]: IMetricConfig} = {
    'temperature' : {
        label: 'Temperature',
        attribute: 'temperatureF',
        chartType: 'line',
        axisPrecision: 10
    },
    'humidity' : {
        label: 'Humidity',
        attribute: 'humidityPct',
        chartType: 'bar',
        isPercentage: true
    },
    'precipAmount' : {
        label: 'Precipitation Amount',
        attribute: 'precipitationAmountInches',
        chartType: 'bar',
        startAtZero: true,
        axisPrecision: 0.2
    },
    'precipChance' : {
        label: 'Chance of Precipitation',
        attribute: 'precipitationChancePct',
        chartType: 'bar',
        isPercentage: true
    },
    'windSpeed' : {
        label: 'Wind',
        attribute: 'windSpeedMph',
        chartType: 'line',
        startAtZero: true,
        axisPrecision: 5
    },
    'windGusts' : {
        label: 'Wind Gusts',
        attribute: 'windGustMph',
        chartType: 'line',
        startAtZero: true,
        axisPrecision: 5
    },
    'cloudCover' : {
        label: 'Cloud Cover',
        attribute: 'cloudCoverPct',
        chartType: 'bar',
        isPercentage: true
    },
    'visibility' : {
        label: 'Visibility',
        attribute: 'visibilityMiles',
        chartType: 'bar',
        startAtZero: true,
        axisPrecision: 5
    }
};

export interface IHourlyWeatherChartViewModelProps {

    startTime: ISO8601DateTimeString;
    location: ICoordinate;
    timezone: string; // eg America/New_York
    link: { href: string };

    // the active day and time that the chart should be focused on
    activeTime: ISO8601DateTimeString;

    // all hourly weather relevant to the forecast (eg 24 hours times 10 days)
    allWeather?: IHourlyForecast[];

    // the hourly weather for the day (from activeTime)
    activeDayWeather?: IHourlyForecast[];

    // selected chart metric, e.g. temperature, wind
    selectedMetricCode: IMetricCode;

    // for tracking event when window closes
    metricsViewed: Set<IMetricCode>;
    daysViewed: Set<string>;
}

// TODO: can I do this with a factory
export class HourlyWeatherChartViewModel extends State<IHourlyWeatherChartViewModelProps> {
    static inject = () => [WeatherService];
    constructor(private weatherService: WeatherService) {

        super({
            // these get set in init, and the lifecycle makes it impractical
            // for down stream methods to get called before that
            // so easier on our consumers if we mark that as not optinal, but need this hack here
            // to make ctor happy
            startTime: undefined as any,
            activeTime: undefined as any,
            location: undefined as any,
            timezone: undefined as any,
            link: undefined as any,
            selectedMetricCode: 'temperature',
            metricsViewed: undefined as any,
            daysViewed: undefined as any
        });
    }

    init = async ({ startTime, activeTime, location, link }: { startTime: ISO8601DateTimeString, activeTime: ISO8601DateTimeString, location: ICoordinate, link: { href: string } }) => {
        const timezone = getTimezone(location);
        const activeDay = moment.tz(activeTime, timezone).format('YYYY-MM-DD');

        this.setState({
            activeTime: moment(activeTime).toISOString(),
            startTime: moment(startTime).toISOString(),
            location,
            timezone: timezone,
            link,
            activeDayWeather: undefined,
            allWeather: undefined,
            selectedMetricCode: 'temperature',
            metricsViewed: new Set<IMetricCode>(['temperature']),
            daysViewed: new Set<string>([activeDay])
        });

        // NOTE: when we add support for web (and therefore url hacking)
        // we may want to add support for handling "it isn't ready yet"
        // but on app, this is super edge case b/c there is no way to get here if the weather
        // hasn't loaded yet. Other edge case is if the system is behind by 24 hours.
        await this.get10DaysWeather();
    };

    refresh = () => {
        return this.get10DaysWeather();
    };

    private get10DaysWeather = async () => {
        const { startTime, location, link } = this.state;

        const allWeather = await this.weatherService.getChartForecast({ startTime, location, link });
        this.setState({ allWeather });

        this.setDaysWeather();
    };

    formatTime = (time: ISO8601DateTimeString, format: string): string => {
        return moment.tz(time, this.state.timezone).format(format);
    };

    private setDaysWeather = async () => {
        if (!this.state.allWeather) {
            throw new Error('you cannot call setDaysWeather until allWeather has been set');
        }
        const activeStartTime = moment.tz(this.state.activeTime, this.state.timezone!).startOf('day').toISOString();
        const activeEndTime = moment.tz(this.state.activeTime, this.state.timezone!).add(1, 'day').startOf('day').toISOString();

        const daysWeather = this.state.allWeather.filter(w => w.forecastStart >= activeStartTime && w.forecastStart <= activeEndTime);
        this.setState({ activeDayWeather: daysWeather });
    };

    setActiveDay = async (activeDay: moment.Moment): Promise<void> => {
        const currentHour = moment.tz(this.state.activeTime, this.state.timezone).hour();

        this.setState({
            activeTime: activeDay.clone().hour(currentHour).startOf('hour').toISOString(),
            activeDayWeather: undefined
        });

        await this.setDaysWeather();

        // add to array for tracking when window closes
        this.state.daysViewed.add(activeDay.format('YYYY-MM-DD'));
    };

    changeMetric = (metricCode: IMetricCode) => {
        const oldMetricCode = this.state.selectedMetricCode;
        if (metricCode === oldMetricCode) {
            return;
        }

        this.setState({ selectedMetricCode: metricCode });

        // add to array for tracking when window closes
        this.state.metricsViewed.add(metricCode);
    };

    getChartType = () => {
        return METRIC_CONFIGS[this.state.selectedMetricCode].chartType;
    };

    getMetricLabel = () => {
        return METRIC_CONFIGS[this.state.selectedMetricCode].label;
    };

    getMetricData = () => {
        const attribute = METRIC_CONFIGS[this.state.selectedMetricCode].attribute;
        return this.state.activeDayWeather?.map(f => f[attribute]) as number[];
    };

    getMetricRange = (metricCode: IMetricCode) => {
        const config = METRIC_CONFIGS[metricCode];
        if (config.isPercentage) {
            return { min: 0, max: 100 };
        }
        const allData = this.state.allWeather?.map(f => f[config.attribute]) as number[];
        if (!allData) {
            return { min: 0, max: 1 };
        }

        let min = config.startAtZero ? 0 : Math.min(...allData);
        let max = Math.max(...allData);

        // expand y axis to next unit of precision in each direction
        // example { min: 17, max: 36 } axisPrecision = 5 => {min: 15, max: 40}
        if (config.axisPrecision) {
            const roundTo = (value: number, unit: number) => Math.round(value / unit) * unit;
            min = roundTo(Math.max(min - config.axisPrecision, 0), config.axisPrecision);
            max = roundTo(max + config.axisPrecision/2, config.axisPrecision);
        }

        return { min, max };
    };

    setActiveTime = (activeTime: ISO8601DateTimeString): void => {
        this.setState({ activeTime: moment(activeTime).toISOString() });
    };

    isActiveDay = (day: moment.Moment): boolean => {
        const format = 'YYYY-MM-DD';
        return Boolean(this.state.activeTime) && moment.tz(this.state.activeTime, this.state.timezone).format(format) === day.format(format);
    };

    isForecastDay = (day: moment.Moment): boolean => {
        const format = 'YYYY-MM-DD';
        return Boolean(this.state.startTime) && moment.tz(this.state.startTime, this.state.timezone).format(format) === day.format(format);
    };

    getChartDays = (): moment.Moment[] => {
        const chartDays: ISO8601DateTimeString[] = [];
        if (!this.state.allWeather) {
            return [];
        }
        for (const { forecastStart } of this.state.allWeather) {
            const day = moment.tz(forecastStart, this.state.timezone).startOf('day').toISOString();
            if (!chartDays.includes(day)) {
                chartDays.push(day);
            }
        }

        return chartDays.map(time => moment.tz(time, this.state.timezone));
    };

    getAllDataForActiveHour = (): IHourlyForecast | undefined => {
        if (!this.state.activeDayWeather) {
            return undefined;
        }
        return this.state.activeDayWeather!.find(w => moment(w.forecastStart).toISOString() === this.state.activeTime);
    };

    getActiveIndex = (): number => {
        if (!this.state.activeDayWeather) {
            return -1;
        }
        const activeHour = this.state.activeDayWeather!.find(w => moment(w.forecastStart).toISOString() === this.state.activeTime);
        if (!activeHour) {
            return -1;
        }
        return this.state.activeDayWeather!.indexOf(activeHour);
    };

    // used by "Weather Chart Closed" event
    getTrackingMeta = () => ({
        metricsViewed: Array.from(this.state.metricsViewed),
        daysViewed: this.state.daysViewed.size
    });
}
