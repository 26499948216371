import { useEffect, useState } from 'react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useSubscription } from '@meraki-internal/state';
import { SavedChecklistsState } from '../../checklists/SavedChecklistsState';
import { ISavedChecklist } from '../../checklists/model';
import { ChecklistState } from '../../checklists/ChecklistState';

export const useSavedChecklistState = (checklistId: string) => {

    const checklists = useInstance(SavedChecklistsState);
    useSubscription(() => checklists);

    const [checklist, setChecklist] = useState<ChecklistState<ISavedChecklist>>();

    useEffect(() => {
        checklists.getChecklistById(checklistId).then(_checklist => {
            setChecklist(_checklist);
        });
    }, [checklistId, checklists]);

    return checklist;
};
