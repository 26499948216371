import { ITimeOfDayAdvisoryProperties, MissionFeatureCollection, MissionFeatureCollectionFacade } from '@autopylot-internal/tiles-client';
import { Color } from '../components/common';

const notSpecialAdvisories = [
    // class g doesn't require special attention
    'airspace+class-g',

    // time of day we handle specially b/c night is special, but day isn't
    'time-of-day',

    // we show this if there are no active TFRs
    'no-tfr',

    // notam doesn't require special attention
    'notam',

    // laanc is handled first class, and is more special than "special" ;)
    'uasfm+airspace', 'uasfm+authorizable', 'uasfm+airspace+composite',

    'operational-awareness',

    'rec-flyer-fixed-sites' // if entirely within this, it should be Good to Fly
];

export class MissionColorProvider {

    private hasAnySpecialAdvisories = (mission: MissionFeatureCollectionFacade): boolean => {
        const advisories = mission.getAdvisories();

        const hasAny = advisories.some(a => !notSpecialAdvisories.includes(a.source));

        const isDayOnly = (advisories.find(a => a.source === 'time-of-day') as ITimeOfDayAdvisoryProperties).relevantTimesOfDay.every(({ timeOfDay }) => timeOfDay === 'day');

        return hasAny || !isDayOnly;
    };

    getColor = (mission: MissionFeatureCollectionFacade): Color => {

        if (mission.canFly() === 'can-fly' && !this.hasAnySpecialAdvisories(mission)) {
            return 'success';
        }
        else if (mission.canFly() === 'can-fly'){
            return 'advisory-warning';
        }
        else if (mission.canFly() === 'can-fly-with-laanc-authorization') {
            return 'advisory-laanc';
        }
        else if (this.onlyBlockReasonIsLAANDAndAirspace(mission)){
            return 'advisory-warning';
        }
        else {
            return 'danger';
        }
    };
    private onlyBlockReasonIsLAANDAndAirspace = (radius: MissionFeatureCollection) => {
        const laancAndAirspaceSources = ['uasfm+airspace', 'uasfm+airspace', 'airspace', 'uasfm+airspace+composite'];

        return radius.getAdvisories().some(a => a.advisoryType === 'block') &&
            radius.getAdvisories().filter(a => a.advisoryType === 'block')
                .every(a => laancAndAirspaceSources.includes(a.source));
    };
}
