import { MissionChecklistPage, MissionChecklistPageState } from './MissionChecklistPage';
import { IScreenshotablePage } from '../../support/screenshots/IScreenshotablePage';
import { MissionsState } from '../../missions/model/MissionsState';
import { IChecklistSection, IChecklistItem } from '../../checklists/model';
import { MissionChecklistStateFactory } from './MissionChecklistStateFactory';
import { getWellKnownLocation } from '../../support/screenshots/getWellKnownLocation';
import { MissionFeatureCollectionFacadeAdapter } from '@autopylot-internal/tiles-client';
import moment from 'moment';
import { ScreenshotHelper } from '../../support/screenshots/ScreenshotHelper';

interface IMyPageProps {
    sections?: IChecklistSection[],
    editingItem?: IChecklistItem,
    settingNotification?: 'pre-flight' | 'on-site' | 'post-flight',
    menuOpen?: boolean,
    saving?: boolean
}

export default class ScreenshotMissionNotesPage implements IScreenshotablePage<IMyPageProps> {

    static inject = () => [
        MissionChecklistPageState,
        MissionsState,
        MissionChecklistStateFactory,
        MissionFeatureCollectionFacadeAdapter,
        ScreenshotHelper
    ];
    constructor(
        private pageState: MissionChecklistPageState,
        private missionsState: MissionsState,
        private stateFactory: MissionChecklistStateFactory,
        private mfcAdapter: MissionFeatureCollectionFacadeAdapter,
        private helper: ScreenshotHelper
    ) { }

    screenId = 'mission-checklist';

    ComponentUnderTest = MissionChecklistPage;

    permutationToProps = ({ sections, editingItem, settingNotification: settingNotificationForSection, menuOpen, saving }: IMyPageProps) => {
        const missionId = '1';

        const { address, radiusPolygon } = getWellKnownLocation('uncontrolled');

        if (!sections) {
            sections = [
                {id: 'pre-flight', label: 'Preflight', items: []},
                {id: 'on-site', label: 'On Site', items: []},
                {id: 'post-flight', label: 'Postflight', items: []}
            ];
        }

        // WIP on getting this stable for screenshots
        // next up will be anything that uses relative time (eg reminders / overdue)
        const startTime = moment.tz('us/eastern').year(2023).month('april').date(20).hour(9).startOf('hour').toISOString();

        const mission = this.missionsState.addMissionToState({
            missionId,
            status: 'active',
            startTime,
            durationMinutes: 60,
            maxAltitude: 100,
            address,
            radiusPolygon: this.mfcAdapter.adaptFromPersisted(radiusPolygon),
            checklist: sections ? { missionId, sections } : undefined
        } as any);

        // consider refactoring state classes to avoid needing to mock the factory
        const wrappedGetInstance = this.stateFactory.getInstance;
        (this.stateFactory as any).getInstance = () => {
            const state = wrappedGetInstance(mission);
            if (editingItem) {
                state.startEditing({ section: {} as any, item: editingItem });
            } else if (settingNotificationForSection) {
                state.startEditing({ section: state.getSections().find(s => s.state.id === settingNotificationForSection)! });

                // have to wait for startEditing to open, or else it will end up on top
                setTimeout(() => state.openNotificationLookup(), 150);
            } else if (menuOpen) {
                this.pageState.openMenu();
            } else if (saving) {
                this.pageState.startSaving();
            }
            return state;
        };

        return { missionId };
    };

    blockScreenshotTil = async ({ settingNotification }: IMyPageProps) => {
        if (settingNotification){
            await this.helper.waitForElement('.action-sheet-wrapper');
            // wait for animation
            await new Promise(resolve => setTimeout(resolve, 750));
        }
    };

    permutations: { [key: string]: IMyPageProps } = {
        'empty' : {
        },
        'with-items' : {
            sections: [
                {id: 'pre-flight', label: 'Preflight', items: [
                    {id: '123', name: 'Check weather', notification: {label: '1 day before', offset: -1, offsetUnit: 'days', offsetFrom: 'startTime'}, completed: true},
                    {id: '456', name: 'Check airspace', completed: false}
                ]},
                {id: 'on-site', label: 'On Site', items: [
                    {id: '789', name: 'Check equipment', completed: false}
                ]},
                {id: 'post-flight', label: 'Postflight', items: [
                    {id: 'ABC', name: 'Send invoice', notification: {label: '1 day after', offset: 1, offsetUnit: 'days', offsetFrom: 'endTime', at: moment().add(1, 'd').toISOString()}, completed: false}
                ]},
            ]
        },
        'adding' : {
            editingItem: { id: '', name: '', completed: false }
        },
        'editing' : {
            sections: [
                {id: 'pre-flight', label: 'Preflight', items: [
                    {id: '123', name: 'Check weather', notification: {label: '1 day before', offset: -1, offsetUnit: 'days', offsetFrom: 'startTime'}, completed: false},
                ]},
                {id: 'on-site', label: 'On Site', items: []},
                {id: 'post-flight', label: 'Postflight', items: []},
            ],
            editingItem: { id: '123', name: 'Check weather', notification: {label: '1 day before', offset: -1, offsetUnit: 'days', offsetFrom: 'startTime'}, completed: false }
        },
        'setting-notification-preflight': {
            settingNotification: 'pre-flight'
        },
        'setting-notification-on-site': {
            settingNotification: 'on-site'
        },
        'setting-notification-post-flight': {
            settingNotification: 'post-flight'
        },
        'menu-open': {
            menuOpen: true
        },
        'adding-to-saved-checklist': {
            sections: [
                {id: 'pre-flight', label: 'Preflight', items: [
                    {id: '123', name: 'Check weather', notification: {label: '1 day before', offset: -1, offsetUnit: 'days', offsetFrom: 'startTime'}, completed: false},
                ]},
                {id: 'on-site', label: 'On Site', items: []},
                {id: 'post-flight', label: 'Postflight', items: []},
            ],
            saving: true
        }
    };
}
