import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import moment from 'moment';

export interface ILogFile {
    name: string;
    directory: Directory;
    path: string;
    size: number;
    uri: string;
    read: () => Promise<string>;
    remove: () => Promise<void>;
}

// This is a singleton, to ensure there is no contention for the file system, and that log entries get written in the right sequence.
export class FileSystemLogger {
    private static singleton: FileSystemLogger;

    // this is the only way to create an instance, and will always return the same instance
    static getSingleton = () => {
        if (!this.singleton) {
            this.singleton = new FileSystemLogger();
        }
        return this.singleton;
    };

    // prevent this being instantiated externally
    private constructor() {
        if (FileSystemLogger.singleton){
            throw new Error('something is instantiating a 2nd instance of FileSystemLogger');
        }
    }

    private folderConfig = {
        directory: Directory.Data,
        path: 'logs'
    };

    private logMessageQueue: string[] = [];
    private flushLogsInterval: any;
    private flushingPromise?: Promise<void>;
    private flushLogsBatchSize = 100;
    private flushLogsIntervalMS = 5000;
    private isInitialized = false;

    private isFlushing = () => Boolean(this.flushingPromise);

    getLogFiles = async () => {
        if (!this.isInitialized) {
            await this.initialize();
        }

        const { directory, path: folderPath } = this.folderConfig;
        const { files } = await Filesystem.readdir(this.folderConfig);

        if (files.some(f => !f.name)) {
            // throw detailed error message to try to troubleshoot why file name is sometimes undefined
            throw new Error(`invalid response from Filesystem.readdir: ${JSON.stringify(files)}`);
        }

        return files.sort((f1, f2) => f1.name.localeCompare(f2.name)).map(fileInfo => {
            const  { name, uri, size } = fileInfo;
            const path = `${folderPath}/${name}`;
            const fileConfig = { directory, path };
            const read = () => Filesystem.readFile({ ...fileConfig, encoding: Encoding.ASCII }).then(r => r.data);
            const remove = () => Filesystem.deleteFile(fileConfig);
            return { name, directory, path, size, uri, read, remove } as ILogFile;
        });
    };

    private initialize = async () => {
        try {
            await Filesystem.mkdir(this.folderConfig);
            this.isInitialized = true;
        } catch (e: any) {
            if (!e.message.includes('already exist') && !e.message.includes('Directory exists')) {
                console.error(`Failed to create logs directory, logging won't work`, e);
            }
        }
    };

    flush = async () => {
        try {
            if (this.isFlushing()) {
                await this.flushingPromise;
            }
            await this.flushLogs();
        } catch (err: any) {
            console.log('failed to flush', err);
        }
    };

    private flushLogs = async () => {
        if (this.logMessageQueue.length === 0) {
            return;
        }

        if (!this.isInitialized) {
            await this.initialize();
        }

        const messagesRemoved = this.logMessageQueue.splice(0, this.flushLogsBatchSize);

        try {
            const messagesToWrite = [...messagesRemoved];
            const data = `${messagesToWrite.join('\n')}\n`;
            const { directory, path } = this.folderConfig;
            const file = `${moment().format('YYYY-MM-DD')}.log`;
            await Filesystem.appendFile({ directory, path: `${path}/${file}`, data, encoding: Encoding.ASCII});

        } catch (e: any) {
            // Put them back on the queue to try again next time
            this.logMessageQueue = [...messagesRemoved, ...this.logMessageQueue];

            console.error('Failed to flush logs', e);
            this.log({ message: 'Failed to flush logs', error: e, level: 'ERROR' });
        }
    };

    private enqueueFlushLogs = () => {
        if (this.flushLogsInterval) {
            return;
        }

        this.flushLogsInterval = setInterval(() => {
            // Skip if currently flushing, we'll try again at the next interval
            if (this.isFlushing()) {
                return;
            }

            this.flushingPromise = Promise.resolve().then(async () => {
                try {
                    await this.flushLogs();

                    if (this.logMessageQueue.length === 0) {
                        clearInterval(this.flushLogsInterval);
                        this.flushLogsInterval = undefined;
                    }

                } catch (e) {
                    // do nothing
                }
                this.flushingPromise = undefined;
            });

        }, this.flushLogsIntervalMS);
    };

    log = ({ error, message, extras, level = 'INFO' }: { error?: any, message?: string, extras?: any, level?: string }) => {
        try {
            if (error) {
                level = 'ERROR';
                message = error.toString();
                extras = extras || {};
                extras.stack = getStackTrace(error);
            }
            const formattedMessage = `[${new Date().toISOString()}] ${level} ${message}${extras ? ` | ${JSON.stringify(extras)}` : ''}`;
            this.logMessageQueue.push(formattedMessage);
            this.enqueueFlushLogs();
        } catch (e: any) {
            console.error('Failed to log message to file system', e);
        }
    };

}

function getStackTrace(e: any) {
    if (e.stack) {
        return e.stack;
    }
    try {
        throw e;
    } catch (thrownError: any) {
        return thrownError.stack;
    }
}
