import moment from 'moment';
import { IAsyncSmartStorageProvider, StorageProvider } from '../support/StorageProvider';
import { OperatorState } from './OperatorState';
import { Clock } from '../support/Clock';

export class EncourageGuestToCreateAccountMarshal {
    static inject = () => [StorageProvider, OperatorState, Clock];
    constructor(storageProvider: StorageProvider, private operator: OperatorState, private clock: Clock){
        this.storage = storageProvider.getAsyncStringProvider('EncourageGuestToCreateAccountMarshal.lastEncouragedISO8601', {
            envNeutral: false,
            userNeutral: false,
            maxBytes: 1000,
            removeOnTooBig: true,
            storageType: '@capacitor/preferences'
        });
    }
    private storage: IAsyncSmartStorageProvider<string>;
    isGuestAndShouldEncourage = async (): Promise<boolean> => {
        // never encourage for now
        return false;

        // if (!this.operator.isGuest()){
        //     return false;
        // }

        // const last = await this.storage.get();
        // if (!last){
        //     // then after 24 hours
        //     return this.clock.now() > moment(this.operator.state.updatedTime).add(24, 'hours').valueOf();
        // }

        // // otherwise, after 15 days
        // return this.clock.now() > moment(last).add(15, 'days').valueOf();
    };
    recordEncouraged = async () => {
        this.storage.set(moment(this.clock.now()).toISOString());
    };
}
