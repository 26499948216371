import moment from 'moment';
import { State } from '@meraki-internal/state';
import { APIClient } from '@autopylot-internal/autopylot-api-client';
import { ENTITLEMENTS } from './RevenueCatModel';
import { Device, DeviceInfo } from '@capacitor/device';
import { App, AppState } from '@capacitor/app';
import { IOfferMeta } from './IOfferMeta';
import { Logger } from '../../support/debug/Logger';
import { StoreConfigs } from '../../app/config/StoreConfigs';

// subscription should be cancelled within 3m
// tried 3:15 and that wasn't long enough
const FOUR_MINUTES_IN_MS = 1000 * 60 * 4;

const ONE_HOUR_MS = 1000 * 60 * 60;

interface IEntitlementApiResponse {
    entitlement: string;
    store: 'promotional' | 'play_store' | 'app_store';
    isSandbox?: boolean;
    expires_date?: string;
    product_identifier?: string;
    links: {
        // will exist if we can revoke it (a promotional entitlement and permission)
        self?: { href: string };
    }
}
interface IEntitlementsApiResponse {
    entitlements: IEntitlementApiResponse[];

    offerMeta: IOfferMeta;

    links: {
        self: {
            href: string;

            // will contain POST if can grant e_plus
            actions: string[]
        }
    }
}

export class RevenueCatAPIModel extends State<Record<string, never>> {
    static inject = () => [
        APIClient,
        Logger
    ];
    constructor(
        private apiClient: APIClient,
        private logger: Logger
    ){
        super({});
    }
    // won't exist, even after init, if a viewer
    private entitlementsApiResponse?: IEntitlementsApiResponse;
    private deviceInfo!: DeviceInfo;

    canBypassPayments = () => {
        return Boolean(this.entitlementsApiResponse?.links.self.actions.includes('POST_e_plus'));
    };

    hasUpgraded = () => Boolean(this.findEntitlement());

    init = async () => {
        this.logger.info('RevenueCatAPIModel.init-ing');
        await this.fetchEntitlements();
        this.deviceInfo = await Device.getInfo();
        this.refreshOnForeground();
        this.logger.info('RevenueCatAPIModel.init-ed', this.getDiagnostics().diagnostics);
    };

    private refreshOnForeground = () => {
        App.addListener('appStateChange', ({ isActive: isForegrounding}: AppState) => {
            if (isForegrounding){
                this.fetchEntitlements().catch((err: any) => {
                    this.logger.error(`WARN failed to fetch entitlements while foregrounding. ${err.toString()}`);
                });

                setTimeout(() => {
                    this.fetchEntitlements().catch((err: any) => {
                        this.logger.error(`WARN failed to fetch entitlements while foregrounding. ${err.toString()}`);
                    });
                }, FOUR_MINUTES_IN_MS);
            }
        });
    };

    private refreshOnExpiredTimeoutId: NodeJS.Timeout | undefined = undefined;
    private refreshOnExpiresDate?: string;

    private checkForRefreshOnExpired = () => {

        if (this.refreshOnExpiredTimeoutId){
            clearTimeout(this.refreshOnExpiredTimeoutId);
            this.refreshOnExpiresDate = undefined;
        }

        const expires_date = this.entitlementsApiResponse?.entitlements.find(e => e.entitlement === ENTITLEMENTS.PLUS)?.expires_date;

        if (expires_date){
            const msTilExpires = moment(expires_date).valueOf() - Date.now();
            if (msTilExpires < ONE_HOUR_MS) {
                this.refreshOnExpiredTimeoutId = setTimeout(() => {
                    this.refreshOnExpiredTimeoutId = undefined;
                    this.refreshOnExpiresDate = undefined;
                    this.fetchEntitlements();
                }, msTilExpires + 1000);
                this.refreshOnExpiresDate = expires_date;
            }
        }
    };

    fetchEntitlements = async () => {
        const entry = await this.apiClient.entry();

        // nothing to check if there is no link (eg b/c a viewer)
        if (!entry.links.entitlements){
            return;
        }

        this.entitlementsApiResponse = await this.apiClient.get(entry.links.entitlements);
        this.checkForRefreshOnExpired();
        this.setState({});
    };

    upgrade = async () => {
        if (this.hasUpgraded()){
            throw new Error('cannot upgrade, user has already upgraded');
        }
        if (!this.canBypassPayments()){
            throw new Error('cannot bypass payments');
        }

        const entry = await this.apiClient.entry();

        await this.apiClient.post(entry.links.entitlements, { entitlement: ENTITLEMENTS.PLUS });

        await this.fetchEntitlements();
    };

    canDowngrade = () => {
        if (!this.hasUpgraded()){
            return false;
        }

        if (this.hasSelfLink()){
            return true;
        }

        if (this.getManagementLink()){
            return true;
        }

        return false;
    };

    private hasSelfLink = () => {
        return Boolean(this.findEntitlement()?.links.self);
    };

    private getManagementLink = (): string | undefined => {
        const entitlement = this.findEntitlement();

        if (entitlement?.store === 'play_store'){
            return `${StoreConfigs.android.subscribeURL}&sku=${entitlement.product_identifier}`;
        }

        if (entitlement?.store === 'app_store'){
            // https://developer.apple.com/documentation/storekit/in-app_purchase/original_api_for_in-app_purchase/subscriptions_and_offers/handling_subscriptions_billing
            // haven't yet seen a way to deeplink in, like we do in android
            // when running against App Store (sandbox)
            // this link won't help, and you actually need to go to Settings > App Store > Sandbox (manage) > Subscriptions
            return StoreConfigs.ios.subscribeURL;
        }

        return undefined;
    };

    private findEntitlement = () => {
        return (this.entitlementsApiResponse?.entitlements || []).find(e => e.entitlement === ENTITLEMENTS.PLUS);
    };

    maybeDowngrade = async () => {
        const managementLink = this.getManagementLink();
        if (this.hasSelfLink()){
            await this.downgradeWithSelfLink();
        }
        else if (managementLink){
            window.open(managementLink);
        }
        else {
            throw new Error('downgrade was called without a self link or a management link, maybe .canDowngrade() was not called first');
        }
    };

    private downgradeWithSelfLink = async () => {
        const entitlement = this.findEntitlement();
        if (!entitlement || !entitlement.links.self){
            throw new Error('downgradeWithSelfLink was called when there is no entitlement or self link');
        }

        await this.apiClient.delete(entitlement.links.self, {});

        await this.fetchEntitlements();
    };

    getDiagnostics = () => {
        const diagnostics: { [label: string]: any } = {
            'hasUpgraded': this.hasUpgraded(),
            entitlements: (this.entitlementsApiResponse?.entitlements || []).map(e => e.entitlement),
            entitlementsApiResponse: this.entitlementsApiResponse,
            canBypassPayments: this.canBypassPayments(),
            offerMeta: this.getOfferMeta(),
            refreshOnExpiresDate: this.refreshOnExpiresDate ? `${this.refreshOnExpiresDate} (${moment(this.refreshOnExpiresDate).format('h:mm:ss a Z')})`: undefined,
            canMaybeDowngrade: this.canDowngrade()
        };

        return {
            diagnostics,
            diagnosticsMethods: {
                refresh: () => this.fetchEntitlements()
            }
        };
    };

    getOfferMeta = () => {
        return this.entitlementsApiResponse?.offerMeta || {} as IOfferMeta;
    };
}
