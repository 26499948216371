import { AdvisoryInfoIcon } from '../../components/icons/Icons';
import { SecondaryAdvisory } from '../SecondaryAdvisory';

// we no longer use this advisory, but keeping it around, b/c I could see using it if we know we're too far out of sync
export const TFRListAdvisory = () => {
    return (
        <SecondaryAdvisory header="Temporary Flight Restrictions" advisoryType='advise' icon={AdvisoryInfoIcon} iconColor="medium">
            Operator is responsible for reviewing and complying with all temporary flight restrictions (TFRs) at <a href="https://tfr.faa.gov/tfr2/list.html" target="_blank">https://tfr.faa.gov/tfr2/list.html</a>.
        </SecondaryAdvisory>
    );
};
