import { IonCardHeader } from '@ionic/react';

export const CardHeader: React.FC<{noDivider?: boolean}> = ({ children, noDivider }) => {
    const borderBottom = (noDivider ? undefined : '1px solid #eee');
    return (
        <IonCardHeader style={{ borderBottom, paddingTop: 8, paddingBottom: 8, marginBottom: 15 }}>
            {children}
        </IonCardHeader>
    );
};
