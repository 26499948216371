/* eslint-disable indent */

import { IAuthorityErrata, TileUrlProvider } from '@autopylot-internal/tiles-client';
import { State } from '@meraki-internal/state';
import { Feature } from '@turf/turf';
import { IAuthorizableGeometry, IAuthorizableProperties } from '@autopylot-internal/tiles-client';
import { Logger } from '../support/debug/Logger';

// we poll every minute even though it has a 5m cache
// we do this b/c it does little harm (we just hit the local http cache most of the time)
// and then we end up with a worst case of 6m til we see the new errata (5m for the cache
// to bust and 1m for our polling)
const POLL_EVERY_MS = 1 /* min*/ * 60 * 1000;

export class ErrataState extends State<{}> {
    static inject = () => [TileUrlProvider, Logger];
    constructor(private tileUrlProvider: TileUrlProvider, private log: Logger){
        super({});
    }

    private currentErrata: IAuthorityErrata | undefined;

    private syncLocalErrataFromRemote = async () => {
        try{
            const errataUrls = await this.tileUrlProvider.getAuthorityErrataUrls();

            const errataResponses = await Promise.all(
                errataUrls.map(errataUrl => fetch(errataUrl).then(async res => {
                    if (res.ok){
                        const individualErrata = await res.json();
                        return individualErrata as IAuthorityErrata;
                    } else {
                        throw new Error(`failed to GET ${errataUrl}. Got ${res.status}`);
                    }
                }))
            );

            const errata: IAuthorityErrata = {
                'airspace-authority': errataResponses[0]['airspace-authority'],
                adds: [],
                changes: [],
                removes: [],
                errataFor: {},
            };
            for (const errataResponse of errataResponses){
                errata.adds = [...errata.adds, ...errataResponse.adds];
                errata.changes = [...errata.changes, ...errataResponse.changes];
                errata.removes = [...errata.removes, ...errataResponse.removes];
                errata.errataFor = {
                    ...errata.errataFor,
                    ...errataResponse.errataFor
                };
            }

            if (!this.currentErrata || JSON.stringify(this.currentErrata) !== JSON.stringify(errata)){
                this.currentErrata = errata;
                this.setState({});
            }
        }
        catch(err){
            const wrappedError = new Error(`failed to fetch errata, will try again in 1 minute. Inner Error: ${(err as any).toString()}`);

            // copy over the error code, in case it is fetch.failed which sentry knows to ignore
            (wrappedError as any).errorCode = (err as any).errorCode;

            this.log.error(wrappedError);
        }
    };

    pollForErrata = async () => {
        while (true){
            await this.syncLocalErrataFromRemote();
            await new Promise(resolve => setTimeout(resolve, POLL_EVERY_MS));
        }
    };

    getRemoveIds = (): string[] => {
        if (!this.currentErrata){
            return [];
        }
        return this.currentErrata.removes.map(r => r.GLOBAL_ID).concat(this.currentErrata.changes.map(f => f.properties!.GLOBAL_ID));
    };

    getErrataFeatures = (): Feature<IAuthorizableGeometry, IAuthorizableProperties>[] => {
        if (!this.currentErrata){
            return [];
        }
        return this.currentErrata.adds.concat(this.currentErrata.changes);
    };
}
