export class RelativeURL {

    pathname: string;

    searchParams: URLSearchParams;

    constructor(url?: string) {
        const absoluteUrl = new URL(url || window.location.hash.substring(1), 'http://example.com');
        this.pathname = absoluteUrl.pathname;
        this.searchParams = absoluteUrl.searchParams;
    }

    toString = () => {
        return `${this.pathname}?${this.searchParams.toString()}`;
    };
}
