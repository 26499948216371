import { APIClient } from '@autopylot-internal/autopylot-api-client';
import { CachingWeatherService } from '../weather/CachingWeatherService';
import { TrackingService } from '../support/tracking/TrackingService';
import { NewMissionState } from '../missions/model/NewMissionState';
import { ISummaryForecast } from '../weather/model/ISummaryForecast';
import { getHeading } from '../weather/components/WindLabel';
import { ICoordinate } from '@autopylot-internal/tiles-client';
import { IAddress } from '../support/model/IAddress';

export class NewMissionTrackingHelper {
    static inject = () => [
        APIClient,
        CachingWeatherService,
        TrackingService,
    ];
    constructor(
        private api: APIClient,
        private weatherService: CachingWeatherService,
        private tracker: TrackingService,
    ) { }

    private tryToGetWeather = async (mission: NewMissionState): Promise<ISummaryForecast | undefined> => {
        try {
            const response = await Promise.race([
                Promise.resolve().then(async () => {
                    const entry = await this.api.entry();
                    const weather = await this.weatherService.getSummaryForecast({
                        link: entry.links.weatherHourly,
                        location: mission.getLocation()!,
                        startTime: mission.getStartTime(),
                        endTime: mission.getEndTime()
                    });
                    return weather;
                }),
                Promise.resolve().then(async () => {
                    await new Promise(resolve => setTimeout(resolve, 5000));
                    throw new Error('timed out');
                })
            ]);

            return response;
        }
        catch (err: any) {
            return undefined;
        }
    };

    private buildWeatherMeta = (weather?: ISummaryForecast) => {
        if (!weather) {
            return {
                weather: 'more than [5]s to load'
            };
        }

        return {
            weather: weather.conditionCode,
            precip: `${weather.precipitationChancePct}%`,
            wind: `${weather.windSpeedMph}mph ${getHeading(weather.windDirection)}`,
            gusts: `${weather.windGustMph}mph`
        };
    };

    pinDropped = async ({ mission, coordinates, address, trigger, fromNewMissionButton }: { mission: NewMissionState; coordinates: ICoordinate; address?: IAddress; trigger: string; fromNewMissionButton?: boolean; }) => {
        // this has to happen after awaiting setCenter(), otherwise radius won't have been created, and canFly etc will be undefined

        const weather = await this.tryToGetWeather(mission);

        this.tracker.track('Pin Dropped', () => ({
            coordinates,
            location: address,
            trigger,
            fromNewMissionButton,
            ...mission.getFlightStatusTrackingMeta(),
            weather: this.buildWeatherMeta(weather)
        }));
    };

    addressSelected = async ({ address, coordinates, mission }: { address: IAddress; coordinates: ICoordinate; mission: NewMissionState; }) => {
        const weather = await this.tryToGetWeather(mission);
        this.tracker.track('Address Selected', () => ({
            location: address,
            coordinates,
            ...mission.getFlightStatusTrackingMeta(),
            weather: this.buildWeatherMeta(weather)
        }));
    };
}
