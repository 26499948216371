import { createUseStyles } from 'react-jss';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { PreMissionWeatherState } from '../PreMissionWeatherState';
import { useSubscription } from '@meraki-internal/state';
import { IonContent, IonModal } from '@ionic/react';
import { PageHeader } from '../../components/page/PageHeader';
import { SummaryForecastCard } from '../SummaryForecastCard';
import { HourlyForecastCard } from '../HourlyForecastCard';
import { DailyForecastCardV3 } from '../DailyForecastCard';
import { WeatherAttribution } from './WeatherAttribution';
import { mediaLargerScreen } from '../../support/helpers/breakpoints';
import { SIDE_DRAWER_WIDTH } from '../../components/drawer/Drawer';
import { useBreakpoints } from '../../support/hooks/useBreakpoints';
import { MENU_COLLAPSED_WIDTH } from '../../menu/Menu';
import { useUnmount } from '../../support/hooks/useUnmount';
import { HourlyWeatherChart } from '../chart/HourlyWeatherChart';
import { HourlyWeatherChartViewModel } from '../chart/HourlyWeatherChartViewModel';

const useStyles = createUseStyles(({
    modal: {
        [mediaLargerScreen]: {
            // size modal to cover side drawer
            '--width': `${SIDE_DRAWER_WIDTH}px`,
            '--border-radius': '0px',

            // setting width in addition to --width seems redundant,
            // but we found by trial and error that the following are
            // also needed to force the modal to the side
            width: `${SIDE_DRAWER_WIDTH}px`,
            left: MENU_COLLAPSED_WIDTH,
            right: 'unset',
            display: 'block',

            // setting --height to 100% causes div.modal-shadow to
            // intercept all mouse events; this is a workaround
            '--height': 'auto',
            '&::part(content)': {
                height: '100%'
            },

            // enable interraction with the map
            '&::part(backdrop)': {
                display: 'none'
            }
        }
    }
}));

interface IMissionCreationWeatherModalProps {
    header: React.ReactElement;
}

export const MissionCreationWeatherModal: React.FC<IMissionCreationWeatherModalProps> = ({ header }) => {
    const classes = useStyles();

    const weatherState = useInstance(PreMissionWeatherState);
    useSubscription(() => weatherState);

    const chartState = useInstance(HourlyWeatherChartViewModel);
    useSubscription(() => chartState);

    const { isLargerScreen } = useBreakpoints();

    // close modal on unmount so it starts closed next time
    useUnmount(weatherState.hideWeatherDetails);

    const returnToPreviousPage = () => {
        if (weatherState.chartActiveTime) {
            weatherState.closeChart();
        } else {
            weatherState.hideWeatherDetails();
        }
    };

    // we want either back or close, never both
    const showCloseButton = (isLargerScreen && !weatherState.chartActiveTime);
    const onBack = showCloseButton ? undefined : returnToPreviousPage;
    const onClose = showCloseButton ? returnToPreviousPage : undefined;

    // when we're showing the chart, put the metric label in the modal title
    const title = (weatherState.chartActiveTime ? chartState.getMetricLabel() : '');

    return (
        <IonModal isOpen={weatherState.isViewingWeatherDetails} onDidDismiss={() => weatherState.hideWeatherDetails()} data-type="weather" className={classes.modal}>
            <PageHeader transparent title={title} onBack={onBack} onClose={onClose} />
            <IonContent>

                {weatherState.chartActiveTime &&
                    <HourlyWeatherChart
                        activeTime={weatherState.chartActiveTime}
                        startTime={weatherState.weatherFor!.startTime}
                        location={weatherState.weatherFor!.location}
                        link={weatherState.getChartLink()}
                    />
                }

                {!weatherState.chartActiveTime &&
                    <>
                        <div style={{
                            // 16 to match IonCard
                            margin: '0 16px'
                        }}>
                            {header}
                        </div>

                        <div style={{marginTop: -12}}>
                            <SummaryForecastCard
                                {...weatherState.getWeatherSummaryProps()}
                            />
                            <HourlyForecastCard
                                {...weatherState.getWeatherHourlyProps()}
                            />
                            <DailyForecastCardV3
                                link={weatherState.getDailyLink()}
                                isDebouncing={weatherState.getWeatherHourlyProps().isLoading}
                                {...weatherState.weatherFor!}
                                onForecastDayClick={weatherState.onDayClick}
                            />
                            <WeatherAttribution />
                        </div>
                    </>
                }
            </IonContent>
        </IonModal>
    );
};
