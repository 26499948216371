import { Container } from '@meraki-internal/react-dependency-injection';
import { Device } from '../../support/Device';
import { IntercomNative } from './IntercomNative';
import { IntercomWeb } from './IntercomWeb';

export type IIntercomEnv = 'intercom-prod' | 'intercom-staging';

export interface IIntercomAdapter {
    getEnv: () => IIntercomEnv;
    init: (config: { appId: string, userId: string, email?: string, name?: string }) => Promise<void>;
    updateUser: (user: { name: string }) => Promise<void>;
    displayMessenger: () => Promise<void>;
    displayMessageComposerWithInitialMessage: (message: string) => Promise<void>;
    canDisplayCarousel: boolean;
    displayCarousel: (carouselId: string) => Promise<void>;
    displayArticle: (articleId: string) => Promise<void>;
    onUnreadCountChange: (listener: (unreadCount: number) => void) => void;
    setMessageVisibility: (visible: boolean) => Promise<void>;
}

export class IntercomAdapterFactory {

    static inject = () => [Device, Container];
    constructor(private deviceInfo: Device, private container: Container) {
    }

    createInstance = (): IIntercomAdapter => {
        const isNative = (['ios', 'android'].includes(this.deviceInfo.platform));
        let adapter: IIntercomAdapter;
        if (isNative) {
            adapter = this.container.get(IntercomNative);
        } else {
            adapter = this.container.get(IntercomWeb);
        }
        return adapter;
    };
}
