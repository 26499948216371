import { generate as generateId } from 'shortid';
import { State } from '@meraki-internal/state';
import { AlertPresenter } from '../app/AlertBinder';
import { ITrackingEvent, TrackingService } from '../support/tracking/TrackingService';
import { UserFacingError } from '../support/UserFacingError';
import { UndoState } from '../undo/UndoState';
import { ChecklistSectionState } from './ChecklistSectionState';
import { IChecklist, IChecklistItem, ISavedChecklist } from './model';

const NEW_ITEM = { id: '', name: '', completed: false };

interface IChecklistCallbacks<T extends IChecklist> {
    onSave: (checklist: T) => Promise<T | void>,
    onForceNotification: (item: IChecklistItem) => Promise<void>
}

interface IChecklistStateProps<T extends IChecklist> {
    checklist: Omit<T, 'sections'>;
    editingSection?: ChecklistSectionState;
    editingItem?: IChecklistItem & { isDirty?: boolean };
    notificationLookupOpen: boolean;
}

export class ChecklistState<T extends IChecklist> extends State<IChecklistStateProps<T>> {

    // set via property injection
    private tracker!: TrackingService;
    private alert!: AlertPresenter;
    private undo!: UndoState;
    private callbacks!: IChecklistCallbacks<T>;

    // property injection
    injectDependencies = (tracker: TrackingService, alert: AlertPresenter, undo: UndoState, callbacks: IChecklistCallbacks<T>) => {
        this.tracker = tracker;
        this.alert = alert;
        this.undo = undo;
        this.callbacks = callbacks;
    };

    private sections: ChecklistSectionState[] = [];

    constructor({ sections, ...restOfChecklist }: T) {
        super({
            checklist: restOfChecklist,
            editingSection: undefined,
            editingItem: undefined,
            notificationLookupOpen: false
        });

        if (!sections.length) {
            sections.push(
                {id: 'pre-flight', label: 'Preflight', items: []},
                {id: 'on-site', label: 'On Site', items: []},
                {id: 'post-flight', label: 'Postflight', items: []}
            );
        }

        sections.forEach(section => {

            // wrap tracker to add metadata
            const itemTracker = {
                track: (event: ITrackingEvent, item?: IChecklistItem) => {
                    const { links, ...checklist } = this.state.checklist;
                    this.tracker.track(event, () => ({
                        ...checklist,
                        sectionId: section.id,
                        item
                    }));
                }
            };

            const sectionState = new ChecklistSectionState(itemTracker, section);
            sectionState.subscribe(async () => {

                if (!this.sections.includes(sectionState)) {
                    // on initial call, add to collection
                    this.sections.push(sectionState);

                } else {
                    // subsequent calls indicate an update, so save to api
                    try {
                        // can't undo after we've saved something else
                        this.undo.clear();

                        const updatedChecklist = this.getChecklist();
                        const savedChecklist = await this.callbacks.onSave(updatedChecklist);

                        // if api returned an updated checklist, update the notifications in state
                        // (mutate in place because we are in a subcription, and we fire setState below)
                        if (savedChecklist) {
                            for (const _section of this.sections) {
                                const savedSection = savedChecklist.sections.find(s => s.id === _section.state.id);
                                for (const _item of _section.state.items) {
                                    const savedItem = savedSection?.items.find(i => i.id === _item.id);
                                    if (savedItem?.notification) {
                                        _item.notification = savedItem.notification;
                                    }
                                }
                            }
                        }

                        // notify our own subscribers
                        this.setState({});

                    } catch (e: any) {
                        // e might be a 400 status, so throw a new error that will always get logged
                        this.alert.showAndLogError(new UserFacingError({
                            message: 'Checklist save failed: ' + e.message,
                            displayMessage: `We were unable to save your checklist. We've been notified about this problem, and are looking into it.` })
                        );
                    }
                }
            });
        });
    }

    getChecklist = (): T => {
        return {
            ...this.state.checklist,
            sections: this.sections.map(section => ({
                ...section.state,
                items: section.state.items.map(item => ({ ...item }))
            }))
        } as T;
    };

    copyItems = async (fromChecklists: ChecklistState<ISavedChecklist>[]): Promise<number> => {
        let copiedCount = 0;

        const thisChecklist = this.getChecklist();
        for (const fromChecklist of fromChecklists) {
            for (const fromSection of fromChecklist.getSections()) {
                const toSection = thisChecklist.sections.find(s => s.id === fromSection.state.id);
                if (toSection) {
                    const itemsWithUniqueIds = fromSection.state.items.map(i => ({ ...i, id: generateId() }));
                    toSection.items.push(...itemsWithUniqueIds);
                    copiedCount += itemsWithUniqueIds.length;
                }
            }
        }

        if (copiedCount) {
            await this.updateChecklist(thisChecklist);
        }

        return copiedCount;
    };

    updateChecklist = async (updatedChecklist: T) => {
        // can't undo after we've saved something else
        this.undo.clear();

        // save first so we put updated notifications into state
        const savedChecklist = await this.callbacks.onSave(updatedChecklist);

        const { sections, ...restOfChecklist } = savedChecklist || updatedChecklist;

        // mutate section state in place so we don't trigger subscriptions and cause re-saving
        sections.forEach(section => {
            const stateSection = this.sections.find(s => s.state.id === section.id);
            if (stateSection) {
                stateSection.state = section;
            }
        });

        this.setState({
            checklist: restOfChecklist
        });
    };

    updateName = async (name: string) => {
        // can't undo after we've saved something else
        this.undo.clear();

        this.setState({ checklist: { ...this.state.checklist, name } });
        await this.callbacks.onSave(this.getChecklist());
    };

    getSections = () => {
        return this.sections;
    };

    getItemCount = () => {
        return this.sections.reduce((t, s) => t + s.state.items.length, 0);
    };

    openNotificationLookup = () => {
        this.setState({ notificationLookupOpen: true });
    };

    closeNotificationLookup = () => {
        this.setState({ notificationLookupOpen: false });
    };

    isNotificationLookupOpen = () => this.state.notificationLookupOpen;

    startEditing = ({ section, item }: { section: ChecklistSectionState, item?: IChecklistItem}) => {
        this.setState({
            editingSection: section,
            editingItem: item || NEW_ITEM
        });
    };

    updateItem = (updates: Partial<IChecklistItem>) => {
        if (this.state.editingItem) {
            this.setState({ editingItem: { ...this.state.editingItem, ...updates, isDirty: true } });
        }
    };

    cancelEditing = () => {
        this.setState({ editingSection: undefined, editingItem: undefined });
    };

    doneEditing = (): IChecklistItem | undefined => {
        if (this.state.editingSection && this.state.editingItem?.name) {

            // eslint-disable-next-line prefer-const
            let { isDirty, ...editedItem }  = this.state.editingItem;

            if (editedItem.id) {
                // if editing an existing item, save changes and close drawer
                this.state.editingSection.updateItem(editedItem);
                this.setState({ editingSection: undefined, editingItem: undefined });

            } else {
                // if adding a new item, save changes, reset form, and leave drawer open
                editedItem = this.state.editingSection.addItem(editedItem);
                this.setState({ editingItem: NEW_ITEM });
            }

            return editedItem;
        }
    };

    deleteAllItems = async () => {
        const checklist = { ...this.getChecklist() };
        await this.updateChecklist({
            ...checklist,
            sections: checklist.sections.map(s => ({ ...s, items: [] }))
        });
    };

    onForceNotification = async (item: IChecklistItem) => {
        await this.callbacks.onForceNotification!(item);
    };
}
