import { Group } from '../ComponentLibrary';
import { WizardProgressBar } from './WizardProgressBar';

const DemoWizardProgressBar: Group & React.FC = () => {
    return (
        <div>
            <div style={{marginTop: 20}}>
                <WizardProgressBar steps={3} currentStep={1} />
            </div>
            <div style={{marginTop: 20}}>
                <WizardProgressBar steps={4} currentStep={2} />
            </div>
            <div style={{marginTop: 20}}>
                <WizardProgressBar steps={3} currentStep={3} />
            </div>
        </div>
    );
};

DemoWizardProgressBar.componentName = 'WizardProgressBar';

export default DemoWizardProgressBar;
