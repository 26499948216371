import { App, AppState } from '@capacitor/app';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useEffect } from 'react';
import { HistoryViewModel } from '../../app/HistoryViewModel';
import { AutopylotLiveUpdatesService } from './AutopylotLiveUpdatesService';
import { DelayLiveUpdateInstallService } from './DelayLiveUpdateInstallService';
import { ILiveUpdatesService } from './ILiveUpdatesService';
import { Logger } from '../debug/Logger';
import { AlertPresenter } from '../../app/AlertBinder';

class LiveUpdatePathValidator {
    static inject = () => [HistoryViewModel];
    constructor(private history: HistoryViewModel){

    }
    canInstall = () => {
        if (this.history.getCurrentLocation().pathname.startsWith('/oauth')) {
            return false;
        }
        return true;
    };
}

class LiveUpdatesListenerHelper {
    static inject = () => [
        DelayLiveUpdateInstallService,
        Logger,
        HistoryViewModel,
        AutopylotLiveUpdatesService,
        LiveUpdatePathValidator,
        AlertPresenter
    ];

    constructor(
        private delayer: DelayLiveUpdateInstallService,
        private logger: Logger,
        private history: HistoryViewModel,
        private service: ILiveUpdatesService,
        private pathValidator: LiveUpdatePathValidator,
        private alert: AlertPresenter
    ){}
    mount = () => {
        // when user foregrounds the app, download update if available, or
        // if inactive for a long time, install downloaded update if available
        App.addListener('appStateChange', async ({ isActive }: AppState) => {

            if (!isActive) {
                this.delayer.onBackground();
            } else {
                const { canInstall } = this.delayer.onForeground();
                this.logger.info('LiveUpdatesListener.appStateChange', {canInstall});

                if (canInstall) {
                    await this.attemptInstall();
                } else {
                    await this.service.downloadUpdateIfAvailable();
                }
            }
        });

        // when user navigates, install downloaded update if available
        this.history.listen(async () => {

            // don't install immediately after app start, to ensure a new user
            // perceives a smooth and fast app start
            const { canInstall } = this.delayer.onHashChange();

            this.logger.info('LiveUpdatesListener.hashChange', {canInstall});

            if (canInstall){
                await this.attemptInstall();
            }

        });

        this.maybeInstallOnAppStart();
    };

    private maybeInstallOnAppStart = () => {
        Promise.resolve().then(async () => {
            const mustWaitForLatestUpdate = await this.service.mustWaitForLatestUpdate();

            // should only happen for internal users, when switching envs
            if (mustWaitForLatestUpdate){
                this.logger.info('LiveUpdatesListener.maybeInstallOnAppStart detected mustWaitForLatestUpdate');
                this.alert.showAlertV2({ header: 'Environment switched', message: 'Installing latest live update for this env' });

                this.logger.info('LiveUpdatesListener.maybeInstallOnAppStart waitForDownloadAndInstall');
                await this.service.waitForDownloadAndInstall();
                this.logger.info('LiveUpdatesListener.maybeInstallOnAppStart waitForDownloadAndInstall complete');

                // not expecting this to happen, but it can in an edge case
                // eg switch to staging, then to prod, then back to staging
                // if prod error'd out such that it got stuck on staging code
                // then when we got back to staging, there is nothing to install
                return;
            }

            const { canInstall } = this.delayer.onForeground();
            if (canInstall){
                await this.attemptInstall();
            }
        }).catch(err => {
            this.logger.info('LiveUpdatesListener.maybeInstallOnAppStart failed with error', err);
        });
    };

    private attemptInstall = async () => {
        // don't install during auth flow, or we'll lose the token!
        // (note this relies on the placeholder route in AppRouter)
        if (!this.pathValidator.canInstall()) {
            this.logger.info(`LiveUpdatesListener cannot install b/c of path ${this.history.getCurrentLocation().pathname}`);
            return;
        }
        await this.service.installOrDownloadUpdateIfAvailable();
    };
}

// ensures new ionic live updates are installed in near-real-time
export const LiveUpdatesListener: React.FC  = () => {

    const helper = useInstance(LiveUpdatesListenerHelper);

    useEffect(() => {
        helper.mount();
    }, [helper]);

    return null;
};
