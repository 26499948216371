import { State } from '@meraki-internal/state';
import { RevenueCatAPIModel } from './revenue-cat/RevenueCatAPIModel';
import { RevenueCatModel } from './revenue-cat/RevenueCatModel';
import { TrackingService } from '../support/tracking/TrackingService';
import { OperatorState } from '../profile/OperatorState';
import { RelativeURL } from '../support/RelativeURL';
import { BufferedEventBus } from '../events/BufferedEventBus';

export type ITriggerPaywallSource = 'menu button' | 'weather summary' | 'subscription expired' | 'nudge' | 'windy';

export class PaywallDialogViewModel extends State<Record<string, never>> {

    private _isOpen = false;

    static inject = () => [RevenueCatModel, TrackingService, OperatorState, BufferedEventBus];
    constructor(private revenueCat: RevenueCatAPIModel, private tracking: TrackingService, private operatorState: OperatorState, private events: BufferedEventBus) {
        super({ });
    }

    private buildUrl = ({ triggerSource, returnTo }: { triggerSource: ITriggerPaywallSource, returnTo?: string }) => {
        const url = new RelativeURL(returnTo); // defaults to current page
        url.searchParams.set('paywallTrigger', triggerSource);
        return url.toString();
    };

    isOpen = () => this._isOpen;

    open = ({ triggerSource, returnTo }: { triggerSource: ITriggerPaywallSource, returnTo?: string  }) => {

        // guest must sign up before accessing paywall
        if (!this.operatorState.ensureLoggedIn({ from: 'Paywall', returnTo: this.buildUrl({ triggerSource, returnTo }) })) {
            return;
        }

        // if we're already subscribed, this doesn't trigger a mixpanel event
        if (!this.revenueCat.hasUpgraded()) {
            this.tracking.track('Paywall Opened', () => ({
                from: triggerSource,
            }));
        }

        this.events.emit('PaywallOpened');

        this._isOpen = true;
        this.setState({ });
    };

    close = () => {
        this._isOpen = false;
        this.setState({ });
    };

    requirePurchase = async ({ triggerSource, returnTo }: { triggerSource: ITriggerPaywallSource, returnTo?: string  }): Promise<boolean> => {
        if (this.revenueCat.hasUpgraded()) {
            return true;
        }

        // if guest, open login dialog, and wait for it to close before proceeding
        const loggedIn = await this.operatorState.waitForLoggedIn({ from: 'Paywall', returnTo: this.buildUrl({ triggerSource, returnTo }) });
        if (!loggedIn) {
            return false;
        }

        return new Promise((resolve, reject) => {
            try {
                this.open({ triggerSource, returnTo });

                // check in case paywall dialog didn't open
                if (!this.isOpen()) {
                    resolve(false);
                    return;
                }

                const unsubscribe = this.subscribe(() => {
                    if (!this.isOpen()) {
                        unsubscribe();
                        resolve(this.revenueCat.hasUpgraded());
                    }
                });
            } catch (e) {
                reject(e);
            }
        });
    };
}
