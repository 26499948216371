import { History as IHistory, createHashHistory, LocationListener, LocationState } from 'history';
import { Logger } from '../support/debug/Logger';
import { StorageProvider } from '../support/StorageProvider';

/**
 * NOTE: if we ever want to "hook" into all history changes, our proxy methods are not
 *          where to do it. Instead hook into / subscrive to this.history  for that.
 *          eg our proxy methods won't know about a <a href="#/cool" /> click
 */

export class HistoryStorage {
    static inject = () => [StorageProvider];
    constructor(private storage: StorageProvider) {}

    appStartPath = this.storage.getStringProvider('APP_START_PATH', { userNeutral: true, envNeutral: true, storageType: 'localStorage' });
    previousHash = this.storage.getStringProvider('previous-hash', { envNeutral: false, userNeutral: true, storageType: 'localStorage' });
}

export class HistoryViewModel {
    history: IHistory<unknown>;

    private initialLength = 0;
    private currentLength = 0;

    static inject = () => [Logger];
    constructor(private log: Logger) {

        this.history = createHashHistory();
        this.initialLength = this.history.length;
        this.currentLength = this.history.length;

        this.history.listen((loc, action) => {
            if (action === 'PUSH') {
                this.currentLength++;
            }
            if (action === 'POP') {
                // rapid page reloads during test setup can cause bogus events to be
                // received, so don't let a POP push us back past the initial length
                if (this.currentLength > this.initialLength) {
                    this.currentLength--;
                }
            }
            log.info('Navigated', loc);
        });
    }

    private stripHash = (path: string) => {
        // remove # if passed in
        // easy mistake since you need in <a href="#/some-path" />
        // but can't have it here where it IS a has history, eg history.push('/some-path')
        if (path[0] === '#'){
            return path.substring(1);
        }
        return path;
    };

    getRawHistory = () => this.history;

    push = (path: string) => {
        this.history.push(this.stripHash(path));
    };

    replace = (path: string) => {
        this.history.replace(this.stripHash(path));
    };

    back = (fallbackPath?: string) => {
        if (this.currentLength <= this.initialLength) {
            // the app may have started from a deep link to a page that has a back button,
            // in which case the user may trigger history.back() when there is no hisory,
            // but we don't want to go back to a page outside the app, so in this case
            // just replace the current page with the root path (to return to the map)
            this.replace(fallbackPath || '/');
        } else {
            this.history.goBack();
        }
    };

    // returns [[k1: v1], [k2, v2]]
    getCurrentSearchParams = (): URLSearchParams => {
        return new URLSearchParams(this.history.location.search || '?');
    };

    // returns {k1: v2, k2: v2}
    getCurrentSearchParamMap = (): {[key: string]: string} => {
        return Array.from(this.getCurrentSearchParams().entries()).reduce((m, [k, v]) => ({ ...m, [k]: v }), {});
    };

    addSearchParam = ({ key, value }: { key: string, value: string }) => {
        const params = this.getCurrentSearchParams();
        params.set(key, value);
        const hashWithoutSearch = this.history.location.hash.split('?')[0];
        const withNewSearch = `${hashWithoutSearch}?${params.toString()}`;
        this.push(withNewSearch);
    };

    getCurrentLocation = () => {
        return this.history.location;
    };

    listen = (listener: LocationListener<LocationState>) => this.history.listen(listener);


    // Gets the value of a query param, then silently removes it from the url without triggering a
    // router state change (so that react will allow this method to be invoked during render).
    //
    // WARNING: This intentionally bypasses the router by calling replaceState() instead of this.replace().
    // But of course this couples us to knowledge of hash router internals, and causes the router to be out
    // of sync with the browser url.
    //
    // NOTE: getCurrentSearchParams() below returns what the router believes, so if popSearchParam()
    // is invoked repeatedly on multiple renders of the same page, it will always return the original
    // param value even though it is no longer visible in the url. This is currently necessary because
    // we are invoking this in the render loop.
    //
    // TODO: To avoid the issues mentioned above, we should revisit this strategy.
    popSearchParam = ({ key }: { key: string }) => {
        const params = this.getCurrentSearchParams();
        const value = params.get(key);

        if (params.has(key)) {
            params.delete(key);
            const rest = params.toString();
            const qs = rest ? `?${rest}` : '';
            const path = this.history.location.pathname;
            window.history.replaceState(null, '', `${window.location.search}#${path}${qs}`);
        }

        return value === null ? undefined : value;
    };

}
