import { IAdvisoryPropertiesV3, IAirspaceBoundaryProperties } from '@autopylot-internal/tiles-client';
import { SecondaryWarningAdvisory } from '../SecondaryAdvisory';

const HUDSON_RIVER_SFRA_1 = 'boundary-FDB960CD-A898-4925-BA66-E16023E1B0A4';
const HUDSON_RIVER_SFRA_2 = 'boundary-E3D27153-C525-4A48-A097-8BB3A6BF6F44';
const HUDSON_RIVER_SFRA_3 = 'boundary-B0F0E774-F1E2-40FD-828B-510218D10589';
const DC_SFRA = 'boundary-3E455E59-067D-4EB6-BE5A-2A96C41F9BEE';
const DC_FRZ = 'boundary-4719D105-C935-4EB7-B31D-6D42BB1450FE';

export const isDCFRZ = (a: IAdvisoryPropertiesV3) => {
    return a.source === 'airspace-boundary' && (a as IAirspaceBoundaryProperties & IAdvisoryPropertiesV3).GLOBAL_ID === DC_FRZ;
};

const HUDSON_RIVER_SFRA_IDS = [HUDSON_RIVER_SFRA_1, HUDSON_RIVER_SFRA_2, HUDSON_RIVER_SFRA_3];

export const airspaceBoundaryNameOverride: {
    [globalId: string]: string;
} = {
    [HUDSON_RIVER_SFRA_1]: 'Hudson River and East River Exclusion SFRA',
    [HUDSON_RIVER_SFRA_2]: 'Hudson River and East River Exclusion SFRA',
    [HUDSON_RIVER_SFRA_3]: 'Hudson River and East River Exclusion SFRA',
    [DC_SFRA]: 'Washington D.C. SFRA',
    [DC_FRZ]: 'DC FRZ',
};

export const AirspaceBoundaryAdvisory = (props: IAirspaceBoundaryProperties & IAdvisoryPropertiesV3) => {

    // we only import TYPE_CODE === SATA
    // so this is dark code for when we bring in future TYPE_CODEs
    const header = props.TYPE_CODE === 'SATA'
        ? 'Special Flight Rules Area'
        : 'National Security Warning';

    return (
        <SecondaryWarningAdvisory header={header} advisoryType={props.advisoryType} GLOBAL_ID={props.GLOBAL_ID}>
            {HUDSON_RIVER_SFRA_IDS.includes(props.GLOBAL_ID) ? (
                <>
                    <p>
                        Your mission overlaps <b>{airspaceBoundaryNameOverride[props.GLOBAL_ID]}</b>, and no person may operate a small unmanned aircraft in restricted airspace
                        unless that person has permission from the appropriate authority.
                    </p>
                    <p>
                        Individuals violating any of these restrictions may be subject to FAA and/or law enforcement action.
                    </p>
                    <p>
                        <a href="https://view.officeapps.live.com/op/view.aspx?src=https%3A%2F%2Fwww.faa.gov%2Fsites%2Ffaa.gov%2Ffiles%2Fuas%2Fgetting_started%2Ftemporary_flight_restrictions%2FUAS-SGI_waiver_approval_request_form.docx&wdOrigin=BROWSELINK" target="_blank">
                            FAA Request Form for Expedited SGI Waiver or Authorization for UAS Operation
                        </a>
                    </p>
                </>
            ) : props.GLOBAL_ID === DC_SFRA ? (
                <>
                    <p>
                        Your mission overlaps <b>{airspaceBoundaryNameOverride[props.GLOBAL_ID]}</b> and flying a drone for recreational or non-recreational use between
                        15 and 30 miles from Washington D.C. is only allowed under specific operating conditions.
                    </p>
                    <p>
                        Individuals violating any of these restrictions may be subject to FAA and/or law enforcement action.
                    </p>
                    <p>
                        <a href="https://www.faa.gov/uas/resources/community_engagement/no_drone_zone/dc" target="_blank">
                            DC Area Prohibited & Restricted Airspace
                        </a>
                    </p>
                </>
            ) : props.GLOBAL_ID === DC_FRZ ? (
                <>
                    <p>
                        Your mission overlaps <b>{airspaceBoundaryNameOverride[props.GLOBAL_ID]}</b>, and no person may operate a small unmanned aircraft in restricted airspace
                        unless that person has permission from the appropriate authority.
                    </p>
                    <p>
                        Individuals violating any of these restrictions may be subject to FAA and/or law enforcement action.
                    </p>
                    <p>
                        <a href="https://www.faa.gov/uas/resources/community_engagement/no_drone_zone/dc" target="_blank">
                            DC Area Prohibited & Restricted Airspace
                        </a>
                    </p>
                </>
            ) : (
                <>
                    <p>
                        Your mission overlaps <b>{props.NAME}</b>, and no person may operate a small unmanned aircraft in restricted airspace
                        unless that person has permission from the appropriate authority.
                    </p>
                    <p>
                        Individuals violating any of these restrictions may be subject to FAA and/or law enforcement action.
                    </p>
                </>
            )}
        </SecondaryWarningAdvisory>
    );
};
