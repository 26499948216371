import { IEnvironment } from '../config/EnvConfiguration';
import { IIntercomEnv } from './IntercomAdapter';

export interface ICanInitFactors {
    appEnv: IEnvironment;
    intercomEnv: IIntercomEnv;
    optOutOfTracking?: boolean;
    ALLOW_LOCAL_DEV_AGAINST_INTERCOM_PROD?: boolean;
}

export class IntercomInitDeputy {
    canInit = (factors: ICanInitFactors): { canInit: boolean, message?: string} => {
        let cannotInitMessage: string | undefined = undefined;

        if (factors.optOutOfTracking) {
            cannotInitMessage = `cannot initialize intercom because optOutOfTracking flag is set`;
        }

        else if (factors.appEnv === 'local' && factors.intercomEnv === 'intercom-prod' && !factors.ALLOW_LOCAL_DEV_AGAINST_INTERCOM_PROD){
            cannotInitMessage = `cannot initialize env ${factors.appEnv} with intercom env ${factors.intercomEnv} when ALLOW_LOCAL_DEV_AGAINST_INTERCOM_PROD is ${factors.ALLOW_LOCAL_DEV_AGAINST_INTERCOM_PROD}`;
        }

        return { canInit: !cannotInitMessage, message: cannotInitMessage };
    };
}
