import { IonSpinner, SpinnerTypes } from '@ionic/react';
import { Color, Size } from '../common';

export interface SpinnerProps {
    size?: Size;
    centered?: boolean;
    color?: Color;
    name?: SpinnerTypes
}

export const Spinner: React.FC<SpinnerProps> = ({ size = 'medium', centered = false, color = 'primary', name = 'lines-sharp'  }) => {
    const scale = size === 'large' ? 3 : size === 'small' ? 1 : 2;
    const style = centered ? {position: 'absolute' as any, top: '40%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 100} : undefined;

    return (
        <div style={style}>
            <IonSpinner name={name} color={color} style={{transform: `scale(${scale})`}}/>
        </div>
    );
};
