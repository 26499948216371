import { Smartlook } from '@awesome-cordova-plugins/smartlook';
import { EnvConfiguration } from '../../app/config/EnvConfiguration';
import { Device } from '../Device';
import { Logger } from './Logger';
import { TrackingStorageProvider } from '../tracking/TrackingStorageProvider';

export class SmartlookService {
    private env: string | undefined;

    static inject = () => [EnvConfiguration, Logger, Device, TrackingStorageProvider];
    constructor(private config: EnvConfiguration, private log: Logger, private deviceInfo: Device, private storage: TrackingStorageProvider) {}

    private enabled = false;
    init = async ({ userId, isAdmin, name, email }: { userId: string, isAdmin: boolean, name: string, email: string  }) => {
        this.log.info('SmartlookService.init', { userId, email, name, isAdmin });

        try {
            if (!['ios', 'android'].includes(this.deviceInfo.platform)) {
                this.log.info(`Skipping smartlook init on platform "${this.deviceInfo.platform}"`);
                return;
            }

            const isRecording = await Smartlook.isRecording();
            if (isRecording) {
                this.log.info('Ending existing smartlook recording');
                Smartlook.stop();
            }

            if (this.storage.optOutOfTracking.get()){
                this.log.info(`Skipping smartlook init b/c tracking is globally opted out`);
                return;
            }

            if (!this.config.SMARTLOOK_API_KEY || process.env.NODE_ENV !== 'production') {
                this.log.info(`Skipping smartlook init for "${this.config.ENV}" env`);
                return;
            }

            if (this.env && this.env !== this.config.ENV) {
                // smartlook is unable to switch api keys once a recording has started
                // so after switching envs, user must restart app to continue recording
                // (NOTE: this was discovered in api 1.0, we're now using 2.0 and have not verified it's still an issue)
                this.log.info(`Skipping smartlook init after switching env from "${this.env}" to "${this.config.ENV}"`);
                return;
            }

            if (isAdmin) {
                this.log.info(`Skipping smartlook init for admin user`);
                return;
            }

            this.log.info(`Initializing smartlook for "${this.config.ENV}" env`);

            this.env = this.config.ENV;

            Smartlook.setProjectKey({ key: this.config.SMARTLOOK_API_KEY });
            Smartlook.openNewUser();
            Smartlook.openNewSession();
            Smartlook.setUserIdentifier({ identifier: userId });
            Smartlook.setUserName({ name });
            Smartlook.setUserEmail({ email });
            Smartlook.start();

            this.enabled = true;

        } catch (e) {
            this.log.error('Failed to initialize smartlook', e);
        }
    };

    // we aren't sure this works, but by trying we'll find out :)
    updateUser = ({ userId, email, name }: { userId: string, email: string, name: string }) => {
        this.log.info('SmartlookService.updateUser', { userId, email, name, enabled: this.enabled });

        if (this.enabled){
            try{
                Smartlook.setUserIdentifier({ identifier: userId });
                Smartlook.setUserName({ name });
                Smartlook.setUserEmail({ email });
            }
            catch(error: any){
                this.log.error(`failed to call Smartlook.setUserIdentifier. Got Error: ${error.toString()}`, { userId, name, email });
            }
        }
    };
}
