import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useEffect, useState } from 'react';
import { BindMapViewModel } from './components/BindMapViewModel';
import { MapViewModelConfig } from './model/MapViewModel';
import { RenderChildrenWhenMapsLoaded } from './RenderChildrenWhenMapsLoaded';

const EnableMap: React.FC<{ }> = ({ children }) => {
    const [isReady, setIsReady] = useState(false);
    const config = useInstance(MapViewModelConfig);

    useEffect(() => {
        config.DISABLE_MAP = false;
        setIsReady(true);
    }, [config, setIsReady]);

    return isReady ? (<>{children}</>) : null;
};

export const EnableAndBindMapForScreenshots : React.FC = ({ children }) => (
    <EnableMap>
        <BindMapViewModel />
        <RenderChildrenWhenMapsLoaded>
            {children}
        </RenderChildrenWhenMapsLoaded>
    </EnableMap>
);
