import { APIClient } from '@autopylot-internal/autopylot-api-client';
import { Logger } from '../support/debug/Logger';
import { TrackingService } from '../support/tracking/TrackingService';
import { State } from '@meraki-internal/state';
import { AutoPylotPrincipal } from '../auth/AutoPylotPrincipal';

// const expectedOperatorFlags = [
//     // new flag here
//     // 'myNewFlag'
// ] as const;

/**
 * How do I add a flag to the "system"?
 *
 *      The api is not opinionated about the flags you save, so there is no setup needed to add a flag.
 *      Just add it to the expectedOperatorFlags above. No users will have the flag yet, but the app
 *      now recognizes it.
 *
 *      However, if you want to write app e2e tests for functionality behind a flag, it will be easiest to
 *      also add the flag to OperatorFlagManager in the api so that it is automatically set for test users.
  *
 * How do I add a flag to a user?
 *
 *      Easiest way is from the dev tools, while logged in as an admin user.
 *
 *           await IOC.get('OperatorFlagsState').addFlagToUser({ userId: 'some-user-id', flag: 'some-flag' })
 *
 */

// type IOperatorFlags = typeof expectedOperatorFlags[number];

export class OperatorFlagsStateConfig{
    /**
     * Enable new users to start faster (better first impression) by not checking for flags
     */
    skipLoad = false;
}

/**
 * This isn't an actual State yet (extends State<{}>)
 * because it is entirely dark.
 *
 * When we use this next, we may want to avoid having this in the critical path of app start.
 * Instead using caching, and side effecting on subscription.
 *
 */
export class OperatorFlagsState extends State<Record<string, never>> {
    static inject = () => [
        APIClient,
        Logger,
        TrackingService,
        OperatorFlagsStateConfig,
        AutoPylotPrincipal
    ];
    constructor(
        private api: APIClient,
        private logger: Logger,
        private tracking: TrackingService,
        private config: OperatorFlagsStateConfig,
        private principal: AutoPylotPrincipal
    ) {
        super({});
    }

    private flags: { [key: string]: boolean } | undefined;

    private loadFromRemote = async () => {
        const entry = await this.api.entry();
        this.flags = await this.api.get(entry.links.operatorFlags);
        this.logger.info('OperatorFlagsState.load', this.flags);
        const experiments = this.getExperiments();
        this.logger.info('OperatorFlagsState experiments', experiments);
        if (experiments.length > 0){
            this.tracking.track('Experiments Loaded', () => ({
                Experiments: experiments
            }));
        }
        this.setState({ });

    };

    getExperiments = (): { flag: string; experiment: string}[] => {
        if (!this.flags) {
            throw new Error(`OperatorFlagsState.getExperiments() was called before OperatorFlagsState.load() has completed`);
        }

        const flags = this.flags as any;

        const experiments: { flag: string; experiment: string}[] = [];

        for (const metaKey of Object.keys(flags).filter(k => k.endsWith('Meta'))){
            const meta = flags[metaKey];
            const flagKey = metaKey.substring(0, metaKey.length - 4);
            if (flags[flagKey] && meta.experiment){
                experiments.push({ flag: flagKey, experiment: meta.experiment });
            }
        }

        return experiments;
    };

    load = async () => {
        if (this.config.skipLoad){
            this.flags = this.flags || {};
        } else {
            await this.loadFromRemote();
        }
    };

    /**
     * Returns true if the operator has the flag set to true
     * @param name
     */
    hasFlag = (name: string): boolean => {
        if (!this.flags) {
            throw new Error(`OperatorFlagsState.hasFlag() was called before OperatorFlagsState.load() has completed`);
        }
        return this.flags![name] || false;
    };

    /**
     * This method is for internal user, from the dev tools, while logged in as an admin user
     * It still depends on proper authorization and is therefore not a security risk.
     */
    addFlagToUser = async ({ userId, flag }: { userId?: string, flag?: string } = {}) => {
        if (!userId || !flag) {
            throw new Error(`both userId and flag are required, expecting .addFlagToUser({ userId: 'some-user', flag: 'some-flag'})`);
        }

        // if (!expectedOperatorFlags.includes(flag)) {
        //     console.log(`WARNING: you provided the flag ${flag} which is not one of ${expectedOperatorFlags.join(', ')}. Any flag is supported, and this flag enum is not meant to be exhaustive, so the operation is allowed, but consider if you had a typo.`);
        // }

        await this.api.patch(`/users/${userId}/flags`, { [flag]: true });
        this.flags = this.flags || {};
        this.flags[flag] = true;
        this.setState({});

    };

    addFlag = async (flag: string) => {
        await this.addFlagToUser({
            userId: this.principal.userId,
            flag
        });
    };

    removeFlagFromUser = async ({ userId, flag }: { userId?: string, flag?: string } = {}) => {
        if (!userId || !flag) {
            throw new Error(`both userId and flag are required, expecting .addFlagToUser({ userId: 'some-user', flag: 'some-flag'})`);
        }

        // if (!expectedOperatorFlags.includes(flag)) {
        //     console.log(`WARNING: you provided the flag ${flag} which is not one of ${expectedOperatorFlags.join(', ')}. Any flag is supported, and this flag enum is not meant to be exhaustive, so the operation is allowed, but consider if you had a typo.`);
        // }

        await this.api.patch(`/users/${userId}/flags`, { [flag]: false });
        this.flags = this.flags || {};
        delete this.flags[flag];
        this.setState({});
    };
}
