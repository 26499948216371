import { StorageProvider } from '../support/StorageProvider';

export type IPurchased = 'Skywatch' | 'BWI' | 'DroneInsurance.com' | 'Other' | 'None';
export type IInterestedIn = 'Hourly' | 'Monthly' | 'Annual';

interface IInsurancePageStateProps {
    notifyMe: boolean;
    purchased: IPurchased[];
    interestedIn: IInterestedIn[];
    submitted: boolean;
}

/**
 * This class provides access to the legacy settings stored by
 * the former "Insurance Coming Soon" page
 */
export class InsurancePageStorage {
    static inject = () => [StorageProvider];
    constructor(private storage: StorageProvider){}
    insuranceNotify = this.storage.getJSONProvider<IInsurancePageStateProps>('insurance-notify', { storageType: 'localStorage' });
}
