import { getConfig } from '@ionic/react';
import { UAParser } from 'ua-parser-js';
import { Container, DependencyContainerContext } from '@meraki-internal/react-dependency-injection';
import { EnvConfiguration, getEnvironment, getConfiguration } from './config/EnvConfiguration';
import { AutoPylotPrincipal } from '../auth/AutoPylotPrincipal';
import { AuthService, AuthServiceLoginDialogPresenter } from '../auth/AuthService';
import { HTTPClientConfig, MemoryLastCodeVerifierProvider } from '@autopylot-internal/autopylot-api-client';
import jwt_decode from 'jwt-decode';
import { Device as IonicDevice } from '@capacitor/device';
import { App as IonicApp } from '@capacitor/app';
import { AppInfo } from '../support/AppInfo';
import { Device } from '../support/Device';
import { MissionsState, MissionsStateCache } from '../missions/model/MissionsState';
import { ScreensContainer } from '../support/screenshots/ScreensContainer';
import { TileUrlProvider, TileUrlProviderConfig, AuthorizeMissionPolygonFacadeMockAirspaceProvider, TileUrlProviderLogger, TilesClientLogger } from '@autopylot-internal/tiles-client';
import { DevSettings } from '../support/DevSettings';
import { MockAirspaceProvider } from '../map/model/MockAirspaceProvider';
import { State } from '@meraki-internal/state';
import { FatalApplicationErrorState } from './FatalApplicationErrorState';
import { OperatorState, OperatorStateCache, OperatorStateStorage } from '../profile/OperatorState';
import { setSentryContext } from '../support/debug/sentry';
import { Logger } from '../support/debug/Logger';
import { LogFileUploader } from '../support/debug/LogFileUploader';
import { SmartlookService } from '../support/debug/SmartlookService';
import { TrackingService } from '../support/tracking/TrackingService';
import { Network } from '../support/Network';
import { FileSystemLogger } from '../support/debug/FileSystemLogger';
import { LoadingPage } from './LoadingPage';
import { AuthenticationClientConfig, AuthenticationClientRedirecter } from '@autopylot-internal/autopylot-api-client';
import { BrowserAuthenticationClientRedirecter } from '../auth/BrowserAuthenticationClientRedirecter';
import { LocalStorageLastCodeVerifierProvider } from '../auth/LocalStorageLastCodeVerifierProvider';
import { AdminCommands } from '../support/debug/AdminCommands';
import { DeviceStatusBar } from '../support/DeviceStatusBar';
import { IntercomService } from './intercom/IntercomService';
import { IntercomNativeEnvProvider } from './intercom/IntercomNativeEnvProvider';
import { DialogPresenter } from '../components/dialog/DialogPresenter';
import { PreferencesStorageAdapter, StorageProvider } from '../support/StorageProvider';
import { MapViewModelStorage } from '../map/model/MapViewModel';
import { AppStartTimings } from '../support/debug/AppStartTimings';
import { FeedbackAlertStorage } from './FeedbackAlertStorage';
import { StatusBarHeightProviderPlaceholder } from '../support/DisplayMetricsProvider';
import { AutopylotLiveUpdatesService, AutopylotLiveUpdatesServiceNetwork } from '../support/live-updates/AutopylotLiveUpdatesService';
import { WebLiveUpdatesService } from '../support/live-updates/WebLiveUpdatesService';
import { DelayLiveUpdateInstallService, DelayLiveUpdateInstallServiceConfig } from '../support/live-updates/DelayLiveUpdateInstallService';
import { ILiveUpdatesService } from '../support/live-updates/ILiveUpdatesService';
import { AlertPresenter } from './AlertBinder';
import { MenuViewModel } from '../menu/MenuViewModel';
import { AppVersionUpdateManager, AppVersionUpdateManager_AlertPlaceholder } from './app-updates/AppVersionUpdateManager';
import { InsuranceState } from '../insurance/InsuranceState';
import { PreMissionWeatherState } from '../weather/PreMissionWeatherState';
import { OperatorFlagsStateConfig } from '../profile/OperatorFlagsState';
import { RevenueCatModel } from '../paywall/revenue-cat/RevenueCatModel';
import { AppEventEmitter } from '../events/AppEventEmitter';
import { PaywallNudger, PaywallNudgerStorage } from '../paywall/PaywallNudger';

const ONE_SECOND = 1000;

const logs = new State<{msgs: string[], show: boolean}>({msgs: [], show: false});
const clearLogs = () => logs.setState({msgs: []});
const showLogs = () => logs.setState({show: true});

let errorSafeContainer: Container | undefined;

/**
 * This should be near-zero risk... eg no http requests, no deserialization (without catch blocks)
 */
export const configureContainerWithErrorSafeDependencies = (container: Container): Container => {
    errorSafeContainer = container;

    container.registerInstance(FileSystemLogger, FileSystemLogger.getSingleton());

    // AuthService has unit tests, which cannot depend on JSX (which we don't
    //  want to teach mocha to understand).
    // so it depends on AuthServiceLoginDialogPresenter which is not a real implementation
    // it is solely for IOC here, where we we inject DialogPresenter in its place
    container.registerAlias(DialogPresenter, AuthServiceLoginDialogPresenter);

    container.registerAlias(DeviceStatusBar, StatusBarHeightProviderPlaceholder);

    container.registerAlias(Network, AutopylotLiveUpdatesServiceNetwork);

    container.registerAlias(AlertPresenter, AppVersionUpdateManager_AlertPlaceholder);

    container.registerAlias(Logger, TileUrlProviderLogger);
    container.registerAlias(Logger, TilesClientLogger);

    container.registerAlias(MockAirspaceProvider, AuthorizeMissionPolygonFacadeMockAirspaceProvider);

    // forcing these early in app start, so that e2e tests
    // can access them
    container.get(OperatorStateStorage);
    container.get(FeedbackAlertStorage);
    container.get(PaywallNudgerStorage);

    return container;
};

export const getErrorSafeContainer = (): Container => {
    if (!errorSafeContainer){
        configureContainerWithErrorSafeDependencies(new Container());
    }

    return errorSafeContainer!;
};

export class AppContainer extends DependencyContainerContext {

    renderLoading() {
        return <LoadingPage canDisableMetrics logs={logs} /> as any;
    }

    /**
     * This is awaited from componentDidMount in the base class DependencyContainerContext.
     * WARNING: Because this is async, errors thrown from here are silently swallowed by React.
     * So we must catch and handle them manually, or the app will white screen.
     */
    async containerMounted(container: Container) {
        try {
            AppStartTimings.singleton.add('mounting container');
            container.registerInstance(AppStartTimings, AppStartTimings.singleton);
            await this.configureContainer(container);
        } catch (e: any) {
            // ignore if we get catch a second error - this is from react unsuccessfully attempting
            // to remount the app, and contains a misleading message and useless stack trace
            if (FatalApplicationErrorState.singleton.state.error) {
                return; // no-op and ignore this
            }

            container.get(Logger).error(e);
            FatalApplicationErrorState.singleton.throwError(e);
        }
    }

    async configureContainer(container: Container) {
        // there are likely more dependencies that can be registered here
        // where risk of failure is near-zero, I only migrated what I needed to
        configureContainerWithErrorSafeDependencies(container);

        const log = container.get(Logger);

        log.info(`SHA is ${process.env.REACT_APP_SHA}`);

        // write logs to this page
        clearLogs();
        log.listen(msg => logs.setState({ msgs: [...logs.state.msgs, msg] }));

        log.info('Starting app initialization...');

        // determine if we are offline or not early, so consumers are more likely to get the right answer
        // NOTE: this assumes true until it gets an answer, which is good enough for the current use case
        // of error handling, but we will want to iterate on it more when that isn't good enough, eg when
        // it is used by app start. eg we could await here, or we could expose a another method on network
        // like .getAsync() which could wait when necessary instead of falling back to true
        container.get(Network).startListening();

        const devSettings = this.configureDevSettings(container);
        const config = this.setupEnvConfig({ container, devSettings });

        // uncomment when wanting to use a non-active version
        // const urlProvider = container.get(TileUrlProvider);
        // urlProvider.getAirspaceAuthorityTile = async ({ x, y, z }: any) => {
        //     return 'https://tiles.autopylot.io/airspace-authority/2023-11-28T20-32-02-267Z/{z}/{x}-{y}--airspace-authority.geo.json?v=1'
        //         .replace('{z}', String(z))
        //         .replace('{x}', String(x))
        //         .replace('{y}', String(y));
        // };
        // urlProvider.getAirspaceTileUrl = async () => 'https://tiles.autopylot.io/ap-airspace-mvt/2023-11-28T22-13-16-407Z/{z}/{x}-{y}--ap-airspace-mvt.mvt.pbf?v=1';

        const deviceInfo = await this.configureDeviceInfo(container);
        await this.configureAppInfo({ container, deviceInfo });

        await container.get(AppVersionUpdateManager).blockOnExpired();

        if (container.get(Device).platform === 'web'){
            container.registerAlias(WebLiveUpdatesService, AutopylotLiveUpdatesService);
        }

        log.info('Loading IntercomNativeEnvProvider.init ...');
        // figure out what intercom env we "started" in
        await container.get(IntercomNativeEnvProvider).init();
        log.info(container.get(IntercomNativeEnvProvider).getEnv());

        container.registerInstance(AuthenticationClientConfig, { baseURL: config.API_BASE_URL });

        if (window.location.search.startsWith('?screenshots')){
            container.get(ScreensContainer).mount();
            return;
        }

        // check for auto-updates (if on a device and not running locally)
        // intentionally not waiting, it will install at next route change on its own
        if (devSettings.reduceLiveUpdatesDelay){
            container.get(DelayLiveUpdateInstallServiceConfig).MIN_MS_HASH_CHANGE = 10 * ONE_SECOND;
        }
        container.get(DelayLiveUpdateInstallService).onAppStart();
        const liveUpdatesService = container.get(AutopylotLiveUpdatesService) as ILiveUpdatesService;

        liveUpdatesService.init({
            disableInstallOrDownloadUpdateIfAvailable: container.get(EnvConfiguration).ENV === 'local'
        }).then(() => liveUpdatesService.downloadUpdateIfAvailable());

        container.registerAlias(BrowserAuthenticationClientRedirecter, AuthenticationClientRedirecter);
        container.registerAlias(LocalStorageLastCodeVerifierProvider, MemoryLastCodeVerifierProvider);

        const timings = container.get(AppStartTimings);

        // If not logged in, send user to login
        log.info('Getting credentials...');
        const authService = container.get(AuthService);
        timings.add('authenticating');
        const { accessToken, isNewGuest } = await authService.getToken();
        timings.add('authenticated');

        log.info('Initializing principal...');
        const { userId, email, isAdmin } = jwt_decode(accessToken!) as any;
        const user = { userId, email, isAdmin };
        log.info(JSON.stringify({ userId, email, isAdmin }));

        container.get(AutoPylotPrincipal).init(user);

        container.get(StorageProvider).setUserId(userId);

        setSentryContext({ user, appInfo: container.get(AppInfo), env: config.ENV });

        if (isNewGuest){
            // assume a guest has no missions
            container.get(MissionsStateCache).set({ missions: [] });

            // assume a guest has an empty profile
            container.get(OperatorStateCache).set({
                agreedToTermsOfService: false,
                agreedToFAAPrivacyStatement: false,
                agreedToLAANCStatement: false,
            });

            container.get(OperatorFlagsStateConfig).skipLoad = true;
        }

        // Should do this as early as possible; if anything depends on HTTPClient such
        // that HTTPClientConfig is created before this is called, we'll be in a bad state
        log.info('Configuring HTTP client...');

        const httpClientConfig: HTTPClientConfig = {
            baseURL: config.API_BASE_URL,
            on401Callback: authService.on401Callback,
            accessToken
        };

        container.registerInstance(HTTPClientConfig, httpClientConfig);
        if (container.get(HTTPClientConfig) !== httpClientConfig) {
            throw new Error(`HTTPClientConfig already registered! ${JSON.stringify(container.get(HTTPClientConfig))}`);
        }

        // TODO: if revenue cat is down our app won't start, ideally we'd be more resilient
        // eg if revenue cat is down, then use the last known state, or assume not premium if there isn't one
        await container.get(RevenueCatModel).init();

        // Loading MissionsState will load the entry resource, which will trigger on401Callback if our token is old.
        // This also has the side effect of priming the entry cache.
        log.info('Loading profile and missions...');
        timings.add('MissionsState.loadRecentAndUpcoming() and OperatorState.load()');
        await Promise.all([
            container.get(MissionsState).loadRecentAndUpcoming(),
            container.get(OperatorState).load(),
        ]);
        timings.add('MissionsState.loadRecentAndUpcoming() and OperatorState.load() [done]');

        // DelayLiveUpdateInstallServiceConfig may need to initialize before OperatorState
        // so lets keep it out to date, now that we have one
        container.get(OperatorState).subscribe(() => {
            container.get(DelayLiveUpdateInstallServiceConfig).operator = container.get(OperatorState).state;
        });

        // kick off loading insurance, but don't slow down app start by waiting for it
        // (everywhere that uses insurance waits for it to complete loading)
        log.info('Loading insurance...');
        container.get(InsuranceState).load();

        log.info('Initializing listeners...');
        container.get(TileUrlProvider).pollForActiveVersionChanges();
        container.get(LogFileUploader).start();

        // this needs to happen before initializing tracking (because we need statusbar height for metrics),
        // but invoke as late as possible to allow time for safari to set env var
        log.info('Initializing statusbar...');
        timings.add('DeviceStatusBar.init()');
        await container.get(DeviceStatusBar).init();
        timings.add('DeviceStatusBar.init() [done]');
        log.info('App initialization complete!');

        container.get(AppEventEmitter).start();
        await container.get(PaywallNudger).start();

        await this.initializeTracking(container);

        // pre-load classes into container that we want to be able to access from window.IOC
        container.get(AdminCommands);

        // property injection for things about to mount
        container.get(AlertPresenter).menu = container.get(MenuViewModel);

        // register for DX (eg to inspect what is in preferences)
        container.get(PreferencesStorageAdapter);

        await container.get(PreMissionWeatherState).init();

        timings.add('container mounted');
    }

    private configureAppInfo = async ({container, deviceInfo }: { container: Container; deviceInfo: Device;  }) => {
        const log = container.get(Logger);
        log.info('Loading app info...');
        if (['ios', 'android'].includes(deviceInfo.platform)) {
            const appInfo = await IonicApp.getInfo();
            container.registerInstance(AppInfo, appInfo);
        } else {
            // not supported on web
            container.registerInstance(AppInfo, null);
        }
        log.info(JSON.stringify(container.get(AppInfo)));
    };

    private configureDeviceInfo = async (container: Container) => {
        const log = container.get(Logger);
        log.info('Loading IonicDevice.getInfo ...');
        const deviceInfo = await IonicDevice.getInfo();
        log.info('Loading IonicDevice.getId ...');
        const { identifier } = await IonicDevice.getId();
        log.info('Loading UAParser.getBrowser ...');
        const { name: browserName, version: browserVersion } = container.get(UAParser).getBrowser();
        const mode = getConfig()?.get('mode') || 'md';
        const device: Device = { uuid: identifier, ...deviceInfo, mode, browserName, browserVersion };
        container.registerInstance(Device, device);
        log.info(JSON.stringify(container.get(Device)));
        return device;
    };

    private configureDevSettings = (container: Container) => {
        const log = container.get(Logger);
        log.info('Loading dev settings...');
        const devSettings = container.get(DevSettings);
        log.info(JSON.stringify(devSettings));
        if (devSettings.showLogsDuringStartup) {
            showLogs();
        }

        return devSettings;
    };

    private setupEnvConfig = ({ container, devSettings }: { container: Container; devSettings: DevSettings ;}) => {
        const log = container.get(Logger);
        log.info('Loading environment config...');
        const env = getEnvironment(devSettings);

        const forceMapboxToUseStaging = container.get(MapViewModelStorage).forceStagingMapbox.get();

        const config: EnvConfiguration = getConfiguration({ env, forceMapboxToUseStaging });
        container.registerInstance(EnvConfiguration, config);

        container.registerInstance(TileUrlProviderConfig, config);

        log.info(JSON.stringify(config));

        const storageProvider = container.get(StorageProvider);

        /**
         * Login to faa-testing from the app is no longer supported, b/c we no longer load OperatorFlagsState which was driving it.
         * We now have the faa-testing web environment, but if we need to get it working again from the app, here is our plan:
         * a. Refactor so that state is on AuthMappings instead of in flags (so that we don't need flags for this)
         * b. Refactor so that we can exchange a production token with faa-testing in the auth mapping / access_token
         *      for a faa-testing token (the faa-testing env should be able to take the prod token and exchange it for an faa-testing token)
         *      only coupling to prod should be prod secret to trust the prod token
         * c. So that... we don't need browser redirect, and so we don't need our redirect hack for Android
         *      On Android, we threw away code where we were using JS Redirect, but with an <a> instead so that the user interacted
         *      Otherwise, Android would never fire the event to let our app know it was redirecting back into our app (without any user
         *      interaction)
         */
        storageProvider.setEnv(config.ENV === 'live' ? 'prod' : config.ENV === 'faa-testing' ? 'faa-testing' : 'staging');

        return config;
    };

    private initializeTracking = async (container: Container) => {
        const operator = container.get(OperatorState);
        const { userId, isAdmin } = container.get(AutoPylotPrincipal);

        const trackingProfile = {
            userId,
            isAdmin,
            email: operator.getEmail(),
            name: operator.getName()
        };

        await Promise.all([
            container.get(SmartlookService).init(trackingProfile),
            container.get(TrackingService).init(trackingProfile),
            container.get(IntercomService).init(trackingProfile)
        ]);
    };
}
