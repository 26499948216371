import { MissionFeatureCollection } from '@autopylot-internal/tiles-client';
import { ITimeOfDayAdvisoryProperties, ITimeOfDay, ITimeOfDayEnum } from '@autopylot-internal/tiles-client';
import { AdvisoryCompleteIcon } from '../../../components/icons/Icons';
import { NoWrap } from '../../../components/typography/NoWrap';
import { formatLocalTime } from '../../../support/helpers/format';
import { SecondaryAdvisory, SecondaryWarningAdvisory } from '../../SecondaryAdvisory';

type ITimeOfDayLabelMap = { [key in ITimeOfDayEnum]: string };

const recreationalBlockedTimeOfDayToLabel: ITimeOfDayLabelMap = {
    'day': '[filtered]',
    'night': 'Night is from',
    'civil-twilight': '[filtered]'
};

const licensedTimeOfDayLabels: ITimeOfDayLabelMap = {
    'day': '[filtered]',
    'night': 'Lights are required at night from',
    'civil-twilight': 'Lights are required during civil twilight from'
};

const recreationalTimeOfDayLabels: ITimeOfDayLabelMap = {
    'day': '[filtered-day]',
    'night': 'Lights are required at night from',
    'civil-twilight': 'Lights are required during civil twilight from'
};

const NightAndTwilightTimes = ({ relevantTimesOfDay, mission, prefix = '', timeOfDayLabelMap }: { relevantTimesOfDay: ITimeOfDay[], mission: MissionFeatureCollection, prefix?: string, timeOfDayLabelMap: ITimeOfDayLabelMap }) => {
    const location = mission.getMissionCenter();
    const getFormattedTime = (time: string) => formatLocalTime({ location, time });

    const nightAndTwilight = relevantTimesOfDay.filter(p => p.timeOfDay !== 'day');
    return (
        <span>
            <span>{prefix}</span>
            {nightAndTwilight.map((time, i) => (
                <span key={i}> {timeOfDayLabelMap[time.timeOfDay]} <b><NoWrap>{getFormattedTime(time.startTime)}</NoWrap></b> to <b><NoWrap>{getFormattedTime(time.endTime)}</NoWrap></b>.</span>
            ))}
        </span>
    );
};

export const DayNightAdvisory = (props: ITimeOfDayAdvisoryProperties & { mission: MissionFeatureCollection }) => {


    const location = props.mission.getMissionCenter();
    const getFormattedTime = (time: string) => formatLocalTime({ location, time });

    // DEPRECATED: recreational, at night, until it applies to everyone
    if (props.advisoryType === 'block') {
        return (
            <SecondaryWarningAdvisory header="Rules for Nighttime Operations" advisoryType="block">
                <span>Flights are prohibited during nighttime hours for Recreational Pilots.</span>
                <NightAndTwilightTimes
                    mission={props.mission}
                    relevantTimesOfDay={props.relevantTimesOfDay.filter(p => p.timeOfDay === 'night')}
                    timeOfDayLabelMap={recreationalBlockedTimeOfDayToLabel}
                />
            </SecondaryWarningAdvisory>
        );
    }

    // if only during the day...
    if (props.relevantTimesOfDay.every(r => r.timeOfDay === 'day')) {
        const daylight = props.relevantTimesOfDay[0];
        return (
            <SecondaryAdvisory header="Rules for Daylight Operations" advisoryType="advise" icon={AdvisoryCompleteIcon} iconColor="success">
                Cleared to fly during daylight hours. Daylight is from {getFormattedTime(daylight.startTime)} to {getFormattedTime(daylight.endTime)}
            </SecondaryAdvisory>
        );
    }

    const { isLicensed } = props.mission.getMissionRadius().properties;

    if (isLicensed && props.relevantTimesOfDay.some(r => r.timeOfDay === 'night')) {
        return (
            <SecondaryWarningAdvisory header="Rules for Nighttime Operations" advisoryType="advise">
                <span>Nighttime flights may only be conducted by operators who have completed the required FAA training/testing. Nighttime flights require anti-collision lighting to be activated, visible for at least 3 statute miles, and have a flash rate sufficient to avoid a collision.</span>
                <NightAndTwilightTimes mission={props.mission} relevantTimesOfDay={props.relevantTimesOfDay} timeOfDayLabelMap={licensedTimeOfDayLabels} />
            </SecondaryWarningAdvisory>
        );
    }
    // recreational, includes night
    else if (!isLicensed && props.relevantTimesOfDay.some(r => r.timeOfDay === 'night')){
        return (
            <SecondaryWarningAdvisory header="Rules for Nighttime Operations" advisoryType="advise">
                <span>For nighttime flights, sUAS should be lighted with anticollision lighting visible for at least 3 statute miles that has a flash rate sufficient to avoid a collision.</span>
                <NightAndTwilightTimes mission={props.mission} relevantTimesOfDay={props.relevantTimesOfDay} timeOfDayLabelMap={licensedTimeOfDayLabels} />
                <span> <a href="https://www.faa.gov/documentLibrary/media/Advisory_Circular/AC_91-57C_FAA_Revised.pdf" target="_blank">FAA Guidance for Recreational Operations at Night</a>.</span>
            </SecondaryWarningAdvisory>
        );
    }

    // civil twilight (no night), licensed, or recreational
    return (
        <SecondaryWarningAdvisory header="Rules for Civil Twilight Operations" advisoryType="advise">
            Flights during civil twilight require anti-collision lighting to be activated, visible for at least 3 statute miles, and have a flash rate sufficient to avoid a collision.
            <NightAndTwilightTimes mission={props.mission} relevantTimesOfDay={props.relevantTimesOfDay} timeOfDayLabelMap={recreationalTimeOfDayLabels} />
        </SecondaryWarningAdvisory>

    );
};
