import { useInstance } from '@meraki-internal/react-dependency-injection';
import { HistoryViewModel } from '../../app/HistoryViewModel';
import { Page } from '../../components/page/Page';
import { usePageView } from '../../support/tracking/usePageView';
import { HourlyWeatherChart } from './HourlyWeatherChart';
import { HourlyWeatherChartViewModel } from './HourlyWeatherChartViewModel';
import { useEditableMissionState } from '../../missions/model/useEditableMissionState';
import { useSubscription } from '@meraki-internal/state';
import { useUnmount } from '../../support/hooks/useUnmount';
import { TrackingService } from '../../support/tracking/TrackingService';

export const HourlyWeatherChartPage: React.FC<{ missionId: string, disableChartAnimations?: boolean }> = ({ missionId, disableChartAnimations }) => {

    const history = useInstance(HistoryViewModel);
    const tracker = useInstance(TrackingService);

    const mission = useEditableMissionState(missionId);

    const chartState = useInstance(HourlyWeatherChartViewModel);
    useSubscription(() => chartState);

    usePageView('Mission Hourly Weather Chart', () => ({
        from: history.getCurrentSearchParams().get('from') || ''
    }));

    useUnmount(() => {
        tracker.track('Weather Chart Closed', chartState.getTrackingMeta);
    });

    if (!mission) {
        return null;
    }

    return (
        <Page title={chartState.getMetricLabel()} onBack={() => history.back(`/missions/${missionId}/weather`)}>
            <HourlyWeatherChart
                activeTime={history.getCurrentSearchParamMap().startingAt}
                startTime={mission.getStartTime()}
                location={mission.getLocation()}
                link={mission.state.links.weatherForChart}
                disableChartAnimations={disableChartAnimations}
            />
        </Page>
    );
};
