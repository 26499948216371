import { FC, useState, useEffect } from 'react';
import moment from 'moment';
import { ISO8601DateTimeString } from '@autopylot-internal/tiles-client';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { StorageProvider } from '../../support/StorageProvider';

type IExpirationStore = {[featureKey: string]: ISO8601DateTimeString};
export interface NewFeatureBadgeProps {
    featureKey: string,
    label?: string,
    lifespanDays?: number,
    slot?: string,
    forceExpired?: boolean
}

class NewFeatureBadgeStorage {
    static inject = () => [StorageProvider];
    constructor(private storage:  StorageProvider){}
    newFeatureBadges = this.storage.getJSONProvider<IExpirationStore>('new-feature-badges', { storageType: 'localStorage' });
}

export const NewFeatureBadge: FC<NewFeatureBadgeProps> = ({ featureKey, label = 'new', lifespanDays = 3, slot = 'end', forceExpired = false }) => {
    const storageV2 = useInstance(NewFeatureBadgeStorage);
    const [isDisplayed, setIsDisplayed] = useState(false);

    // if local storage item doesn't exist, create it
    useEffect(() => {
        if (!storageV2.newFeatureBadges.exists()) {
            storageV2.newFeatureBadges.set({});
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // if lifespanKey doesn't exist, set it. otherwise, check for expiration to hide the badge
    useEffect(() => {
        const now = moment().valueOf();

        // TODO: this is brittle being coupled to the useEffect above, it should be moved in there
        // to embrace the coupling, but not going to ATM b/c already in the midst of a major refactor

        const expirationStore = storageV2.newFeatureBadges.get()!;
        let expiration = expirationStore[featureKey];

        // if lifespanKey value doesn't exist, set it
        if (!expiration) {
            expiration = moment(now).add(lifespanDays, 'd').toISOString();
            storageV2.newFeatureBadges.set({ ...expirationStore, [featureKey]: expiration });
        }

        // if not expired, show the badge
        if (moment(expiration).valueOf() >= now) {
            setIsDisplayed(true);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (forceExpired) {
            const now = moment().valueOf();

            // TODO: this is brittle being coupled to the useEffect above, it should be moved in there
            // to embrace the coupling, but not going to ATM b/c already in the midst of a major refactor

            const expirationStore = storageV2.newFeatureBadges.get()!;
            expirationStore[featureKey] = moment(now).subtract(1, 'd').toISOString();
            storageV2.newFeatureBadges.set(expirationStore);
            setIsDisplayed(false);
        }
    }, [forceExpired, featureKey, storageV2]);

    return (
        <div slot={slot} style={{
            display: isDisplayed ? 'block' : 'none',
            padding: `4px 6px`,
            marginLeft: 0,
            borderRadius: 5,
            fontSize: 'smaller',
            textTransform: 'uppercase',
            color: 'var(--ion-color-success)',
            backgroundColor: 'rgba(var(--ion-color-success-rgb), 0.1)'
        }}>{label}</div>
    );
};
