import { AlertPresenter, IAlertOptions } from '../../app/AlertBinder';
import { EditableMissionState } from '../model/EditableMissionState';
import { DevSettings } from '../../support/DevSettings';

export class LAANCAlert {

    static inject = () => [AlertPresenter, DevSettings];
    constructor(private alert: AlertPresenter, private devSettings: DevSettings) {}

    showAlert = ({ message, mission, ...otherOptions }: IAlertOptions & { mission: EditableMissionState}) => {

        if (!mission.state.isReal && !this.devSettings.disableNotRealWarnings) {
            message = `${message}<br/><br/><i>DISCLAIMER: These authorizations are simulated, and do not provide FAA approval to fly.</i>`;
        }

        return this.alert.showAlert({ message, ...otherOptions });
    };
};
