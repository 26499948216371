import { IAdvisoryPropertiesV3, IAirspaceBoundaryProperties, ICompositeLAANCAdvisoryProperties, MissionFeatureCollection } from '@autopylot-internal/tiles-client';
import { SecondaryAdvisory, SecondaryLAANCAdvisory } from '../SecondaryAdvisory';
import { AdvisoryCompleteIcon } from '../../components/icons/Icons';

const getAirspaceNames = (mission: MissionFeatureCollection) => {
    return mission.getAdvisoryFeatures()
        .filter(f => f.properties.source === 'uasfm+airspace')
        .map(f => (f.properties as any).NAME)
        .filter((v, i, a) => a.indexOf(v) === i) // unique
        .filter(Boolean);
};

export const LAANCAdvisory = (props: ICompositeLAANCAdvisoryProperties & { mission: MissionFeatureCollection }) => {
    const laancStatus = props.mission.getLAANCCompositeAdvisory()?.apLAANCStatus;

    // TODO: eliminate this mess (perhaps by setting a different blockReason in CompositeLAANCAdvisoryProvider)
    const hadGisChange = props.mission.getLAANCAuthorizeableAdvisoryFeatures().find(f => f.properties.getLAANCStatusHistory()[0].statusMeta.initializedSource === 'faa-gis-change');

    const isInScheduledAirspace = props.mission.getAdvisories().some(a => a.source === 'scheduled-airspace');

    const airspaceNames = getAirspaceNames(props.mission);

    if (props.advisoryType === 'advise') {
        const anyE2 = (props as any).AP_CLASSES.some((c: string) => c === 'E2');

        const fullyAuthorized = (laancStatus === 'authorized');

        return (
            <>
                {fullyAuthorized && (
                    <SecondaryLAANCAdvisory header="LAANC Authorization Approved" advisoryType={props.advisoryType} icon={AdvisoryCompleteIcon} iconColor={'success'} >
                        LAANC Authorization approved up to <strong>{props.CEILING}&nbsp;ft.</strong>
                    </SecondaryLAANCAdvisory>
                )}
                {!fullyAuthorized && (
                    <SecondaryLAANCAdvisory header="LAANC Auto Approval Active" advisoryType={props.advisoryType} icon={AdvisoryCompleteIcon}>
                        You must have airspace authorization from the FAA. Automatic Approval for LAANC Authorization is available
                        in airspace{airspaceNames.length > 1 ? 's' : ''} <strong>{airspaceNames.join(', ')}</strong> up to <strong>{props.CEILING}&nbsp;ft</strong>.
                        {isInScheduledAirspace && (
                            <span> This timeframe overlaps <strong>scheduled airspace</strong>; outside the active schedule, it is uncontrolled Class G airspace.</span>
                        )}
                    </SecondaryLAANCAdvisory>
                )}

                {anyE2 && (
                    <SecondaryLAANCAdvisory header="Class E Weather Warning" advisoryType={props.advisoryType}>
                        LAANC Authorization is invalid when the weather ceiling is less than <strong>1,000&nbsp;ft AGL</strong>.
                    </SecondaryLAANCAdvisory>
                )}
            </>
        );

    } else if (laancStatus === 'initialized' && hadGisChange) {
        // indicates that a gis change caused mission to go from uncontrolled to requiring laanc
        // but we don't know more than this, so don't show any secondary advisories
        return null;

    } else if (['authorizing-failed'].includes(laancStatus!)) {
        return (
            <SecondaryAdvisory header="Authorization Not Valid" advisoryType='block' iconColor='danger'>
                We failed to obtain LAANC Authorization for this mission.
            </SecondaryAdvisory>
        );

    } else if (['rescinded', 'invalidated'].includes(laancStatus!)) {
        return (
            <SecondaryAdvisory header="Authorization Not Valid" advisoryType='block' iconColor='danger'>
                Per the FAA, LAANC Authorization for this mission is no longer valid.
            </SecondaryAdvisory>
        );

    } else if (['rescind-acked', 'invalidated-acked'].includes(laancStatus!)) {
        return (
            <SecondaryAdvisory header="Authorization Canceled" advisoryType='block' iconColor='danger'>
                You canceled this mission because LAANC Authorization for the mission is no longer valid, per the FAA.
            </SecondaryAdvisory>
        );

    } else if (laancStatus === 'user-canceled') {
        return (
            <SecondaryAdvisory header="Authorization Canceled" advisoryType='block' iconColor='danger'>
                You have canceled this mission, so LAANC Authorization for the mission has also been canceled.
            </SecondaryAdvisory>
        );

    } else {
        return (
            <LAANCInactiveAdvisory mission={props.mission} airspaceNames={airspaceNames} />
        );
    }

};

const isNSUFR = (a: IAdvisoryPropertiesV3) => a.advisoryType === 'block' && (['nsufr', 'parttime-nsufr'].includes(a.source));
const isBlockingSpecialAirTrafficRule = (a: IAdvisoryPropertiesV3) => a.advisoryType === 'block'
    && a.source === 'airspace-boundary'
    // we only import SATA, but to be extra careful in case we add others in the future
    && (a as IAirspaceBoundaryProperties & IAdvisoryPropertiesV3).TYPE_CODE === 'SATA';

export const LAANCInactiveAdvisory = ({ mission, airspaceNames }: { mission: MissionFeatureCollection, airspaceNames: string[] }) => {
    const advisories = mission.getAdvisories();

    const isInScheduledAirspace = advisories.some(a => a.source === 'scheduled-airspace');
    const omitLinks = advisories.some(a => isNSUFR(a) || isBlockingSpecialAirTrafficRule(a));

    return (
        <SecondaryLAANCAdvisory header="LAANC Auto Approval Inactive" advisoryType={'block'}>
            Your mission overlaps an area of <strong>{airspaceNames.join(', ')}</strong> Controlled Airspace
            that does not offer Auto Approval for LAANC Authorizations{isInScheduledAirspace ? <strong> for this timeframe</strong> : ''}.

            {!omitLinks &&
                <>
                    <span> You may be able to get authorization using the FAA waiver process.</span>
                    <span> For more info please visit: <a href="https://www.faa.gov/uas/programs_partnerships/data_exchange/laanc_facilities/" target="_blank">LAANC Airports</a> and <a href="https://faadronezone.faa.gov" target="_blank">Waiver Process</a>.</span>
                </>
            }

            <p>
                Prior to flight, the remote pilot in command must assess local airspace and any flight restrictions.
            </p>
        </SecondaryLAANCAdvisory>
    );
};
