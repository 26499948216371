import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useEffect } from 'react';
import { WindyMapViewModel } from './WindyMapViewModel';

/**
 * Windy is very opinionated about their API being a singleton, which depends on a single instance of leaflet, attached to a single dom node with id windy
 * that can only be `init`d once
 * https://community.windy.com/post/122427
 *
 * To work around this, we use the same strategy as we do for mapbox-gl-js.
 * 1. High in our ReactDOM, before we render the Router / Pages, we bind a singleton of windy using <WindyMapViewModelBoundContainer> which sits on top of <BindWindyMapViewModel />
 *      - this also blocks children, so they can't get WindyMapViewModel from IOC til it is ready
 *      - it binds the leaflet dom element to a hidden part of the DOM that is always safe (won't get deleted by react)
 * 2. Then where we want to show the map, we use <ShowMap />
 *     - this grabs the leaflet dom element and moves it to wherever ShowMap is
 *     - on unmount, it puts it back
 */

export const BindWindyMapViewModel: React.FC = () => {

    const windyMapViewModel = useInstance(WindyMapViewModel);

    useEffect(() => {
        if (windyMapViewModel.isDisabled()){
            // professional is limited to 10k sessions per day
            // we don't want to tip that over taking screenshots, that don't even need windy
            return;
        }

        (window as any).windyInit({
            key: 'AmNJ6fJAe9pTjIdyakm9Oz85wo8wJjuZ',
        }, (api: any) => {

            api.store.set('hourFormat', '12h');

            api.overlays.rain.setMetric('in');
            api.overlays.wind.setMetric('mph');

            windyMapViewModel.setLeaflet({
                leafletGlobal: (window as any).L,
                windy: api as any,
                el: document.querySelector('#windy')!
            });
        });
        // disabled b/c we only want to do this once, no matter what
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div id="permanent-windy-map-container" style={{visibility: 'hidden', height: 0}}>
            {/* This parent div is necessary since <div id="windy"> gets moved around the dom,
            so we need this stable div to put it back to when it isn't part of an active page.
            It needs to be hidden to be sure it doesn't show if windy fails to initialize.
            We tried display:none, but the windy controls didn't render fully. So we're using
            visibility:hidden with height:0 instead. */}

            {/* id must be windy, that is how windyInit finds it. Appears to be the only way. */}
            <div id="windy" style={{ position: 'relative', top: 0, left: 0, width: '100%', height: '100%' }} />
        </div>
    );
};
