import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useEffect, useState } from 'react';
import { ChecklistState } from '../../checklists/ChecklistState';
import { EditableMissionState } from '../model/EditableMissionState';
import { IMissionChecklist } from './IMissionChecklist';
import { MissionChecklistStateFactory } from './MissionChecklistStateFactory';

export const useMissionChecklistState = (mission: EditableMissionState | undefined) => {
    const factory = useInstance(MissionChecklistStateFactory);

    const [checklist, setChecklist] = useState<ChecklistState<IMissionChecklist>>();

    useEffect(() => {
        if (mission) {
            setChecklist(factory.getInstance(mission));
        }
    }, [factory, mission]);

    return checklist;
};
