import { LAANCAlert } from './LAANCAlert';
import { EditableMissionState } from '../model/EditableMissionState';

export class AuthStatusAlert {

    static inject = () => [LAANCAlert];
    constructor(private alert: LAANCAlert) {}

    showAlert = (mission: EditableMissionState) => {
        let header: string = '', message: string = '';

        if (mission.hasLAANC()) {
            if (mission.isFullyAuthorized()) {
                header = 'Mission Authorized!';
                message = 'Your request was approved by the FAA and you may fly with these plans. Review your mission details and follow all advisories for your flight.';
            }

            else if (mission.isNotValid()) {
                header = 'Mission Not Approved';
                message = `We're sorry, we couldn’t get LAANC authorization from the FAA for your flight plan. Please cancel this mission. It may still be possible to get an authorization by recreating the mission with a slightly smaller flight area.`;
            }

            else {
                header = 'Mission Pending Approval';
                message = 'We were not yet able to get LAANC authorization from the FAA. We will retry for up to 10 minutes. Pull down on the mision to check if it has been approved.';
            }
        }

        else if (mission.getCanFly() === 'can-fly') {
            header = 'Clear to Fly this Mission!';
            message = 'No approval was needed, you may fly with these plans. Review your mission details and follow all advisories for your flight.';
        }

        else {
            throw Error(`Invalid flight status "${mission.getCanFly()}" for useAuthStatusAlert`);
        }

        this.alert.showAlert({
            header,
            message,
            mission,
            buttons: [ 'Continue' ]
        });
    };
};
