import { State } from '@meraki-internal/state';
import { Theme } from '../../app/ThemeVariables';
import { AuthorizeMissionFacade, ISO8601DateTimeString, MissionFeatureCollection, MissionFeatureCollectionFacadeAdapter } from '@autopylot-internal/tiles-client';
import { MissionColorProvider } from '../../flight-checklist/MissionColorProvider';
import { Feature, Polygon } from '@turf/turf';
import * as turf from '@turf/turf';
import { ICoordinate } from '../../support/model/ICoordinate';
import { ApMapboxStylerTimeState } from '../styles/ApMapboxStylerTimeState';
import { TrackingService } from '../../support/tracking/TrackingService';

export interface IRadiusProperties {
    center: ICoordinate;
    radiusMeters: number;
    color: string; // hex color
    startTime: string;
    endTime: string;
    isLicensed: boolean;
    authorizedRadius?: MissionFeatureCollection;
    isSquare?: boolean;
}

export class MissionPinAndRadiusOnMapViewModel extends State<Record<string, never>>{
    static inject = () => [
        Theme,
        AuthorizeMissionFacade,
        MissionFeatureCollectionFacadeAdapter,
        MissionColorProvider,
        ApMapboxStylerTimeState,
        TrackingService
    ];
    constructor(
        private theme: Theme,
        private authorizeMissionProvider: AuthorizeMissionFacade,
        private missionAdapter: MissionFeatureCollectionFacadeAdapter,
        private missionColorProvider: MissionColorProvider,
        private timeState: ApMapboxStylerTimeState,
        private tracker: TrackingService
    ){
        super({ });

        this.subscribe(() => {
            if (this.radius){
                this.timeState.setTime({
                    startTime: this.radius.properties.startTime,
                    endTime: this.radius.properties.endTime
                });
            }
            else {
                this.timeState.reset();
            }
        });
    }

    getRadius = (): Feature<Polygon, IRadiusProperties> | undefined => {
        return this.radius;
    };

    isSquare = () => {
        return this.radius?.properties.isSquare;
    };

    showPendingRadius = true;

    private radius?: Feature<Polygon, IRadiusProperties>;
    private panMovesRadius = false;
    getDoesPanMoveRadius = () => this.panMovesRadius;

    stopMovingMissionPin = () => {
        this.panMovesRadius = false;
        this.setState({ });
    };

    resumeMovingMissionPin = () => {
        this.panMovesRadius = true;
        this.setState({ });
    };

    start = async (props: {center: ICoordinate; radiusMeters: number; startTime: string; endTime: string; isLicensed: boolean;}) => {

        this.showPendingRadius = true;
        this.panMovesRadius = true;

        this.buildAndSetRadiusFromCenter(props);

        this.setState({ });

        await this.authorizeRadius();
    };

    toggleSquareOrCircle = () => {
        if (this.radius && this.panMovesRadius){
            this.buildAndSetRadiusFromCenter({
                ...this.radius.properties,
                isSquare: !this.radius.properties.isSquare
            });

            // TODO: error handling?
            this.authorizeRadius();

            this.tracker.track('Mission Shape Changed', () => ({
                missionShape: this.isSquare() ? 'square' : 'circle',
            }));
        }
    };

    showMissionPinAndRadius = (mission: MissionFeatureCollection) => {
        // if anything else was showing, remove it
        this.remove();

        const radius = mission.getMissionRadius();

        this.radius = {
            type: 'Feature',
            geometry: mission.getMissionRadius().geometry,
            properties: {
                ...radius.properties,
                color: this.theme.getColor(this.missionColorProvider.getColor(mission)),
                authorizedRadius: mission
            }
        };
        this.setState({ });
    };

    private buildAndSetRadiusFromCenter = (properties: Omit<IRadiusProperties, 'color'>) => {
        const { center, radiusMeters, isSquare } = properties;

        const { lng, lat } = center;
        let radius = turf.circle([lng, lat], radiusMeters, {
            units: 'meters',
            properties: {
                ...properties,
                color: this.theme.getColor('primary'),
                authorizedRadius: undefined
            }
        });

        if (isSquare){
            const bbox = turf.bbox(radius);
            radius = turf.bboxPolygon(bbox, { properties: radius.properties });
        }

        this.radius = radius;


        this.setState({ });
    };

    move = (center: ICoordinate) => {
        const metersMoved = this.getMetersMoved({ oldCenter: this.radius!.properties.center, newCenter: center });
        if (metersMoved < 1){ // eg a minor difference as camera zooms
            // then don't bother
            return;
        }

        this.showPendingRadius = false;

        this.buildAndSetRadiusFromCenter({
            ...this.radius!.properties,
            center
        });
    };

    private getMetersMoved = ({ oldCenter, newCenter }: { oldCenter: ICoordinate; newCenter: ICoordinate; }) => {
        const fromCoord = turf.point([oldCenter.lng, oldCenter.lat]);
        const toCoord = turf.point([newCenter.lng, newCenter.lat]);
        return turf.distance(fromCoord, toCoord, { units: 'meters'});
    };

    adjustRadiusSize = ({ radiusMeters }: { radiusMeters: number; }) => {
        this.showPendingRadius = true;

        this.buildAndSetRadiusFromCenter({
            ...this.radius!.properties,
            radiusMeters
        });
    };

    authorizeRadius = async () => {
        this.showPendingRadius = true;
        this.setState({});

        const radius = this.getRadius();
        if (!radius){
            throw new Error('no radius');
        }
        if (this.radius?.properties.authorizedRadius){
            // then it is already authorized
            return;
        }

        try{
            const persistable = await this.authorizeMissionProvider.subdivide({
                ...radius.properties,
                source: 'mission-radius'
            }, { initializedSource: 'user-mission-creation' });

            // TLDR: safe to use `radius`, not safe to use `this.getRadius()`
            // NOTE: at this point the radius at this.getRadius() may have changed
            // but we handle that by ignoring this.getRadius() and instead using the radius
            // we have clojure over above. By updating it, if it still the active one, the UI
            // will update, if it is not, the UI won't notice this out dated authorization response

            const authorizedRadius = this.missionAdapter.adapt(persistable);

            radius.properties.color = this.theme.getColor(this.missionColorProvider.getColor(authorizedRadius));
            radius.properties.authorizedRadius = authorizedRadius;

            this.setState({ });
        }
        catch (err:any){
            if (err && err.name === 'AxiosError' && err.response.status === 404) {
                // then probably outside the US, so lets do nothing
                // UI will show last authorized radius, but with opacity suggesting it is stale
                // and you won't be able to continue
                // this was a NTH, so not considering doing more at this time
            } else {
                throw err;
            }
        }

    };

    getAuthorizedRadius = (): MissionFeatureCollection | undefined => {
        return this.radius?.properties.authorizedRadius;
    };

    remove = () => {
        this.panMovesRadius = false;
        this.radius = undefined;
        this.showPendingRadius = false;
        this.setState({ });

        // NOTE: we don't need to worry that we might be in the middle of authorizing, because that implementation
        // changes the radius it has clojure over, and won't re-set this.radius
    };

    adjustTime = async ({ startTime, endTime }: { startTime: ISO8601DateTimeString; endTime: ISO8601DateTimeString; }) => {
        this.buildAndSetRadiusFromCenter({
            ...this.radius!.properties,
            startTime,
            endTime
        });

        await this.authorizeRadius();
    };
}
