import { createUseStyles } from 'react-jss';
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonItem, IonLabel, IonList, isPlatform } from '@ionic/react';
import { Button } from '../../components/button/Button';
import { useSubscription } from '@meraki-internal/state';
import { MissionTimeActionSheet } from '../action-sheets/MissionTimeActionSheet';
import { AddressDirectionsIcon, ForwardIcon } from '../../components/icons/Icons';
import { EditableMissionState } from '../model/EditableMissionState';
import { MissionOtherActionSheet } from '../action-sheets/MissionOtherActionSheet';
import { MissionStatusBadge } from './MissionStatusBadge';
import { Location } from '../../map/components/Location';
import { FlightStatusBadge } from './FlightStatusBadge';
import { ICanFly } from '@autopylot-internal/tiles-client';
import { TextInput } from '../../components/field/TextInput';
import { Color } from '../../components/common';
import { tryAndCatch } from '../../support/helpers/tryAndCatch';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { AlertPresenter } from '../../app/AlertBinder';
import { Badge } from '../../components/badge/Badge';
import { MockAuthorizationActionSheetButton } from './MockAuthorizationActionSheetButton';
import { AuthorizationsButton } from './AuthorizationsButton';
import { useEffect } from 'react';
import { TrackingService } from '../../support/tracking/TrackingService';
import { LinkBasedWeatherButton } from '../../weather/WeatherButton';
import { MissionNotesButton } from '../notes/MissionNotesButton';
import { RefreshTimer } from '../../components/refresher/RefreshTimer';
import { MissionChecklistButton } from '../checklist/MissionChecklistButton';
import { InsuranceButton } from '../../insurance/InsuranceButton';
import { InsuranceBanner } from '../../insurance/InsuranceBanner';

const useStyles = createUseStyles({
    details: {
        '& ion-card-title': {
            fontSize: 24
        },
        '& ion-item': {
            marginLeft: -15,
            marginRight: -15,
            '& h2': {
                fontSize: 18
            },
            '& ion-icon': {
                fontSize: '32px!important'
            }
        }
    }
});

const colorByCanFly: { [canFly in ICanFly]: Color } = {
    'can-fly': 'success',
    'can-fly-with-laanc-authorization': 'warning',
    'cannot-fly': 'danger'
};

export const MissionDetails = ({ mission, cancelOnMount }: { mission: EditableMissionState, cancelOnMount?: boolean }) => {
    const classes = useStyles();
    const tracker = useInstance(TrackingService);

    const { showAndLogError, showAlert } = useInstance(AlertPresenter);

    useSubscription(() => mission);

    const saveName = async (name: string) => {
        await tryAndCatch(() => mission.setName(name), (e: any) => {
            if (e.status === 409) {
                showAlert({ header: 'Cannot Save Changes', message: 'There was a conflict with updating your authorization. Please pull down on the page to refresh, then try again.' });
            } else {
                showAndLogError(e);
            }
        });
    };

    const onCancelMission = async () => {
        await tryAndCatch(mission.cancelMission, showAndLogError);
    };

    // for screenshotting only
    useEffect(() => {
        if (cancelOnMount) {
            onCancelMission();
        }

        // disabled b/c onCancelMission is created every render
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cancelOnMount]);

    // TODO perhaps some of this belongs inside tile client
    const authorizations = mission.getMissionRadius().getLAANCAuthorizeableAdvisoryFeatures().map(feature => feature.properties);

    const airspaces = Array.from(new Set(authorizations.map(authorization => {
        const { airspaceIdent, airspaceClasses } = authorization;
        return `${airspaceIdent} | ${airspaceClasses || [].join('/')}`;
    })));

    const canFly = mission.getCanFly();
    const missionStatus = mission.getMissionStatus();
    const laancStatus = mission.getAPLAANCStatus();

    const laancIconColor = missionStatus === 'user-canceled' ? 'medium' : colorByCanFly[canFly];
    const shouldShowMissionStatus = missionStatus === 'user-canceled' || mission.getCanFly() !== 'cannot-fly';
    const shouldShowFlightStatus = missionStatus === 'active' || mission.getCanFly() === 'cannot-fly';

    const showDirections = () => {
        const address = mission.getAddress();
        const coordinates = mission.getLocation();
        let locationText: string | undefined;
        if (address) {
            const { name, street, city, state, zip } = address;
            locationText = [name, street, city, state, zip].filter(p => Boolean(p)).join(', ');
        } else {
            const { lat, lng } = coordinates;
            locationText = `${lat},${lng}`;
        }
        tracker.track('Mission Directions Requested', () => ({ address, coordinates }));
        if (isPlatform('ios')) {
            window.location.href = `maps://?daddr=${locationText}`;
        } else {
            // give the button component a fraction of time to reset itself from the ripple / selected state
            setTimeout(() => {
                window.open(`https://www.google.com/maps/dir/?api=1&destination=${locationText}`, '_blank');
            }, 200);
        }
    };

    const onRefreshTimer = async () => {
        if (!mission.state.isEditingName) {
            await mission.refresh();
        }
    };

    return (
        <>
            <InsuranceBanner mission={mission} />

            <IonCard className={classes.details}>

                <RefreshTimer getIntervalMs={mission.getAutoRefreshIntervalMs} handler={onRefreshTimer} />

                <IonCardHeader>
                    <IonCardTitle>
                        {mission.state.isEditingName && (
                            // tweak the margins so input exactly matches the positioning of the label shown when not editing
                            <form style={{ marginRight: 8, marginBottom: -10, textAlign: 'center' }} onSubmit={e => {
                                e.preventDefault();
                                const name = (e.target as any).querySelector('input').value;
                                saveName(name);
                            }}>
                                <TextInput autofocus value={mission.state.name} onBlur={value => {
                                    saveName(value);
                                }} />
                            </form>

                        )}
                        {!mission.state.isEditingName && (
                            <p style={{ textAlign: 'center', margin: 10, padding: 0 }} onClick={() => mission.startEditingName()}>
                                {mission.getName()}
                            </p>
                        )}
                    </IonCardTitle>

                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10, marginTop: 20 }}>
                        {(shouldShowMissionStatus) && (
                            <MissionStatusBadge missionStatus={missionStatus} laancStatus={laancStatus} />
                        )}
                        {shouldShowFlightStatus && (
                            <FlightStatusBadge canFly={canFly} laancStatus={laancStatus} />
                        )}
                    </div>
                </IonCardHeader>
                <IonCardContent>
                    <IonList>
                        <IonItem detail={false}>
                            <IonLabel>
                                <h2>{mission.getFormattedDate()}</h2>
                                <p>{mission.getFormattedTimes()}</p>
                            </IonLabel>
                        </IonItem>
                        <IonItem button detail={false} onClick={showDirections}>
                            <IonLabel>
                                <h2>Location</h2>
                                <p><Location mission={mission} /></p>
                            </IonLabel>
                            <AddressDirectionsIcon slot="end" color="medium" size="medium" />
                        </IonItem>

                        <InsuranceButton mission={mission} />

                        <MissionChecklistButton mission={mission} />

                        <MissionNotesButton mission={mission} />

                        <LinkBasedWeatherButton
                            key={mission.state.refreshedAt}
                            link={mission.state.links.weatherHourly}
                            location={mission.getLocation()}
                            startTime={mission.getStartTime()}
                            endTime={mission.getEndTime()}
                            onClick={() => mission.pages.weather.go() }
                        />

                        <IonItem button detail={false} onClick={() => {
                            mission.pages.viewMap.go({ source: 'airspace' });
                        }}>
                            <IonLabel>
                                <h2>Airspace</h2>
                                <p>{airspaces.join(', ')}</p>
                            </IonLabel>
                            <ForwardIcon slot="end" color="medium" size="medium" />
                        </IonItem>
                        <AuthorizationsButton mission={mission} laancIconColor={laancIconColor} />
                        <MockAuthorizationActionSheetButton mission={mission} />
                    </IonList>

                    {/* NOTE: this should still show as rescinded, until it has ended
                                BUT we're waiting to take action until we can discuss with FAA

                        NOTE:   a mission is invalid if it is active (not canceled or completed)
                                and you cannot fly. The only appropriate action to take is to cancel the mission.
                                Since you cannot create a mission, that you cannot fly, this can only happen based on the following scenarios
                                -  one or more, or all authorizations could have been rescinded by the FAA
                                - one or more, or all authorizations could have been invalidated by FAA GIS changes (eg part time NSUFR)
                                - one or more laanc authorizations could now be inactive, or ceiling 0, or no longer allowed for your license level (107-AA vs 44809-AA)
                                - one or more laanc authorizations could now have a ceiling that is less that was authorized
                                - an area that was previosly uncontrolled, could now require laanc
                                - an area that was uncontrolled, could now be restricted (eg part time NSUFR)
                    */}
                    {mission.shouldCancel() && !mission.hasEnded() &&
                        <div style={{ textAlign: 'center' }}>
                            <div data-type="warning-panel" style={{ marginTop: 10, marginBottom: 10 }}>
                                <Badge color="danger" saturated={true}>
                                    <span style={{ fontWeight: 'normal' }}>
                                        {mission.wasUncontrolled() ? (
                                            <>No longer allowed to fly mission.</>
                                        ) : mission.getAPLAANCStatus() === 'authorizing-failed' ? (
                                            <>LAANC authorization failed. Please cancel this mission. It may still be possible to get an authorization by recreating the mission with a slightly smaller flight area.</>
                                        ) : (
                                            <><b>Action Required:</b> This mission is no longer approved and the FAA requires that you <b>cancel this mission immediately.</b></>
                                        )}
                                    </span>
                                </Badge>
                            </div>
                            <Button fill="outline" dataType="cancel-mission" onClick={onCancelMission}>Cancel Mission</Button>
                        </div>
                    }

                    {(!mission.shouldCancel() || mission.hasEnded()) &&
                        <div style={{ textAlign: 'center', marginTop: '50px' }}>
                            <Button color="secondary" fill="outline" dataType="view-mission" href={mission.pages.viewMap.path({ source: 'button' })}>View Mission on Map</Button>
                        </div>
                    }

                    <MissionTimeActionSheet mission={mission} />
                    <MissionOtherActionSheet mission={mission} />
                </IonCardContent>
            </IonCard>
        </>
    );
};
