import { useRef } from 'react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { IonInput, IonItem, IonModal } from '@ionic/react';
import { UpArrowIcon, NotificationIcon } from '../components/icons/Icons';
import { useSubscription } from '@meraki-internal/state';
import { useBreakpoints } from '../support/hooks/useBreakpoints';
import { Button } from '../components/button/Button';
import { useIsNative } from '../support/hooks/useIsNative';
import { IActionMenuProps, IActionMenuAction } from '../components/action-menu/ActionMenu';
import { ActionSheet } from '../components/action-menu/ActionSheet';
import { PopoverMenu } from '../components/action-menu/PopoverMenu';
import { ExpandableCard } from '../components/card/ExpandableCard';
import { ChecklistSection } from './ChecklistSection';
import { IChecklistItem, INotification } from './model';
import { ChecklistState } from './ChecklistState';
import { PushNotificationService } from '../push-notifications/PushNotificationService';

// Scroll checklist item to center of the screen
const scrollIntoView = (item: IChecklistItem) => {
    setTimeout(() => {
        const el = document.querySelector(`ion-item[data-id=item-${item.id}]`);
        el?.scrollIntoView({ block: 'center' });
    }, 200);
};

// Adds whitespace at bottom of page when adding an item, to ensure we can
// scroll the new item into view even when it's in the bottom section
const Spacer: React.FC<{isAdding: boolean}> = ({ isAdding }) => {
    return isAdding ? <div style={{height: '30%'}} /> : null;
};

const NotificationMenu: React.FC<IActionMenuProps> = props => {
    const isNative = useIsNative();
    if (isNative) {
        return <ActionSheet {...props} />;
    } else {
        return <PopoverMenu {...props} />;
    }
};

export const Checklist: React.FC<{vm: ChecklistState<any>, canCompleteItems?: boolean}> = ({ vm, canCompleteItems = false }) => {
    useSubscription(() => vm);

    const { isLargerScreen } = useBreakpoints();

    const pushNotificationService = useInstance(PushNotificationService);

    const inputRef = useRef<HTMLIonInputElement>(null);

    // item being added/edited, or undefined if not editing
    const editingItem = vm.state.editingItem;

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        const isNew = (!editingItem?.id);
        const item = vm.doneEditing();

        // if adding a new item, scroll so it's visible above drawer
        if (isNew && item) {
            scrollIntoView(item);
        }

        // request permission for notifications when user saves first notification
        if (editingItem?.notification) {
            await pushNotificationService.requestPermissionIfNotYetRequested();
        }
    };

    const handleNotificationCancel = () => {
        setTimeout(() => inputRef.current?.setFocus()); // force keyboard to stay open
        vm.closeNotificationLookup();
    };

    const buildNotificationAction = (notification?: INotification) => {
        return { text: notification?.label || 'None', handler: () => vm.updateItem({ notification }) };
    };

    const getNotificationActions = () => {
        let actions: IActionMenuAction[] = [];
        const sectionId = vm.state.editingSection?.state?.id;
        if (sectionId === 'pre-flight') {
            actions = [
                buildNotificationAction({ label: '1 week before', offset: -7, offsetUnit: 'days', offsetFrom: 'startTime' }),
                buildNotificationAction({ label: '1 day before', offset: -1, offsetUnit: 'days', offsetFrom: 'startTime' }),
                buildNotificationAction({ label: '1 hour before', offset: -1, offsetUnit: 'hours', offsetFrom: 'startTime' }),
                buildNotificationAction({ label: '30 minutes before', offset: -30, offsetUnit: 'minutes', offsetFrom: 'startTime' }),
                buildNotificationAction(undefined)
            ];
        }
        if (sectionId === 'on-site') {
            actions = [
                buildNotificationAction({ label: '30 minutes before', offset: -30, offsetUnit: 'minutes', offsetFrom: 'startTime' }),
                buildNotificationAction({ label: 'At time of mission', offset: 0, offsetUnit: 'minutes', offsetFrom: 'startTime' }),
                buildNotificationAction(undefined)
            ];
        }
        if (sectionId === 'post-flight') {
            actions = [
                buildNotificationAction({ label: '30 minutes after', offset: 30, offsetUnit: 'minutes', offsetFrom: 'endTime' }),
                buildNotificationAction({ label: '1 hour after', offset: 1, offsetUnit: 'hours', offsetFrom: 'endTime' }),
                buildNotificationAction({ label: '1 day after', offset: 1, offsetUnit: 'days', offsetFrom: 'endTime' }),
                buildNotificationAction({ label: '1 week after', offset: 7, offsetUnit: 'days', offsetFrom: 'endTime' }),
                buildNotificationAction(undefined)
            ];
        }
        return actions;
    };

    // restrict max width so ionic doesn't expand drawer under menu
    // when tablet keyboard opens in landscape mode
    const drawerStyle = isLargerScreen ? { '--max-width': '75%' } : undefined;

    return (
        <>
            {vm.getSections().map(section => (
                <div key={section.state.id} data-type={section.state.id}>
                    <ExpandableCard heading={section.state.label}>
                        <ChecklistSection canCompleteItems={canCompleteItems} vm={section}
                            onAdd={() => {
                                vm.startEditing({ section });
                            }}
                            onEdit={(item: IChecklistItem) => {
                                vm.startEditing({ section, item });
                            }}
                            onForceNotification={(item: IChecklistItem) => {
                                vm.onForceNotification(item);
                            }}
                        />
                    </ExpandableCard>
                </div>
            ))}

            <Spacer isAdding={Boolean(editingItem && !editingItem.id)} />

            <IonModal data-type="checklist-drawer" style={drawerStyle} isOpen={Boolean(editingItem)} initialBreakpoint={0.3} breakpoints={[0, 0.3]} onIonModalDidPresent={() => inputRef.current?.setFocus()} onIonModalWillDismiss={vm.cancelEditing}>
                <form onSubmit={handleSubmit}>
                    <div style={{marginTop: 15}}>
                        <IonItem style={{marginRight: 20}}>
                            <IonInput
                                data-type="item-name"
                                ref={inputRef} value={editingItem?.name}
                                placeholder="Checklist item name"
                                style={{caretColor: vm.isNotificationLookupOpen() || !editingItem ? 'transparent' : undefined}}
                                onIonBlur={() => inputRef.current?.setFocus()}
                                onIonChange={e => vm.updateItem({ name: e.detail.value || '' })}
                                autofocus
                            />
                        </IonItem>
                    </div>
                    <div style={{margin: '10px 20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                        <Button dataType="item-notification" onClick={vm.openNotificationLookup} size="small" minWidth={0} color={editingItem?.notification ? 'tertiary' : 'medium'} fill={editingItem?.notification ? 'clear' : 'outline'}><NotificationIcon slot="start" />
                            <span>{!editingItem?.notification ? 'Reminder' : editingItem.notification.label}</span>
                        </Button>
                        <Button dataType="save-button" type="submit" disabled={!editingItem?.name || !editingItem?.isDirty} size="small" minWidth={0}>
                            {editingItem?.id && <span>Save</span>}
                            {!editingItem?.id && <UpArrowIcon />}
                        </Button>
                    </div>
                </form>
            </IonModal>

            <NotificationMenu data-type="notification-lookup" onClose={handleNotificationCancel} isOpen={vm.isNotificationLookupOpen()} actions={getNotificationActions()} />
        </>
    );
};
