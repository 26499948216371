import { FC, useEffect, useState } from 'react';
import useMeasure from 'react-use-measure';
import { IonIcon, IonItem, IonList, IonPopover, IonText } from '@ionic/react';
import { createUseStyles } from 'react-jss';
import { IActionMenuProps } from './ActionMenu';

const useStyles = createUseStyles({
    popover: {
        '&::part(content)': {
            width: 'auto',
            minWidth: 200,
            maxWidth: 650
        }
    }
});

// NOTE: trigger is the id of the button/element that launched the menu. Unfortunately
// setting both trigger and isOpen on IonPopover causes the browser to freeze up.
// So we're managing the positioning of the menu manually in the useEffect below.
export const PopoverMenu: FC<IActionMenuProps> = ({ actions, isOpen, onClose, trigger }) => {
    const classes = useStyles();

    const [menuRef, menuBounds] = useMeasure();
    const [offsetStyles, setOffsetStyles] = useState<any>();

    useEffect(() => {
        if (trigger && isOpen && menuBounds) {
            const triggerElement = document.getElementById(trigger);
            if (triggerElement) {
                const triggerBounds = triggerElement.getBoundingClientRect();

                // calculate offsets needed to align menu with trigger element...
                // - horizontally: right-align with trigger without going too close to left of container (handled by edgeOffsetX)
                // - vertically: below trigger, or above if too close to bottom of container (handled by edgeOffsetY)
                const edgePadding = 20;
                const edgeOffsetX = (triggerBounds.right - menuBounds.width < edgePadding ? menuBounds.width - triggerBounds.right + edgePadding : 0);
                const edgeOffsetY = (window.innerHeight - triggerBounds.bottom - menuBounds.height < edgePadding ? menuBounds.height + triggerBounds.height : 0);
                const offsetX = triggerBounds.right - window.innerWidth/2 - menuBounds.width/2 + edgeOffsetX;
                const offsetY = triggerBounds.bottom - window.innerHeight/2 + menuBounds.height/2 - edgeOffsetY;
                setOffsetStyles({ '--offset-x': `${offsetX}px`, '--offset-y': `${offsetY}px` });
            }
        }
    }, [trigger, isOpen, menuBounds]);

    const hasIcons = actions.some(a => a.icon);

    const onSelect = (handler?: () => void) => {
        if (onClose) {
            onClose();
        }
        if (handler) {
            // need timeout to allow popover to close
            // before handler can navigate to another page
            setTimeout(handler);
        }
    };

    return (
        <IonPopover arrow={false} style={offsetStyles} className={classes.popover} isOpen={isOpen} onDidDismiss={onClose}>
            <IonList ref={menuRef} lines="full" style={{paddingTop: 0, paddingBottom: 0}}>
                {actions.filter(a => !a.disabled).map(({ text, icon, role, handler }, idx) => (
                    <IonItem
                        button
                        detail={false}
                        key={`${text}-${idx}`}
                        onClick={() => onSelect(handler)}
                    >
                        {hasIcons &&
                            <IonIcon slot="start" icon={icon} color={role === 'destructive' ? 'danger' : 'medium'} />
                        }
                        <IonText style={{paddingRight: 30}} color={role === 'destructive' ? 'danger' : undefined}>{text}</IonText>
                    </IonItem>
                ))}
            </IonList>
        </IonPopover>
    );
};
