import { useInstance } from '@meraki-internal/react-dependency-injection';
import { Device } from '../Device';
import { DevSettings } from '../DevSettings';

// Returns true if native app, or web with simulateNative dev setting;
// otherwise returns false
export const useIsNative = () => {

    const deviceInfo = useInstance(Device);
    const devSettings = useInstance(DevSettings);

    return devSettings.simulateNative || deviceInfo.platform !== 'web';
};
