import { MissionFeatureCollectionFacadeAdapter } from '@autopylot-internal/tiles-client';
import moment from 'moment';
import { IMission } from '../mission/model/EditableMissionState';
import { MissionsState } from '../missions/model/MissionsState';
import { getWellKnownLocation } from '../support/screenshots/getWellKnownLocation';
import { IScreenshotablePage } from '../support/screenshots/IScreenshotablePage';
import { HourlyWeatherChartPage } from './chart/HourlyWeatherChartPage';
import { MockWeatherService } from './chart/MockWeatherService';
import { WeatherService } from './WeatherService';

interface IMyPageProps {
}

export default class ScreenshotHourlyWeatherChartPage implements IScreenshotablePage<IMyPageProps> {
    static inject = () => [MissionsState, MissionFeatureCollectionFacadeAdapter, WeatherService, MockWeatherService];
    constructor(private missions: MissionsState, private mfcAdapter: MissionFeatureCollectionFacadeAdapter, private weatherService: WeatherService, private mockWeatherService: MockWeatherService) { }
    screenId = 'weather-hourly';

    ComponentUnderTest = HourlyWeatherChartPage;

    permutationToProps = () => {
        const missionId = String(Date.now());

        const durationMinutes = 60;
        const startTime = moment().startOf('h').add(2, 'h').toISOString();
        const endTime = moment(startTime).add(durationMinutes, 'm').toISOString();

        const location = getWellKnownLocation('uncontrolled');

        const address = location.address;
        const radiusPolygon = location.radiusPolygon;
        radiusPolygon.features[0].properties.startTime = startTime;
        radiusPolygon.features[0].properties.endTime = endTime;
        radiusPolygon.features[0].properties.isLicensed = true;

        const missionParams: IMission = {
            missionId,
            startTime,
            durationMinutes,
            status: 'active',
            radiusPolygon: this.mfcAdapter.adaptFromPersisted(radiusPolygon),
            address: address!,
            maxAltitude: 400,
            notesHtml: '',
            links: {},
            version: 1
        };

        this.missions.addMissionToState(missionParams);

        // TODO: we will need to move the dates in the hard coded data to some context we pass into this fn
        this.mockWeatherService.useWellKnownWeather('cold');

        this.weatherService.getChartForecast = this.mockWeatherService.getChartForecast;

        return { missionId, disableChartAnimations: true };
    };

    blockScreenshotTil = async (params: IMyPageProps) => {
        await new Promise(resolve => setTimeout(resolve, 1000));
    };

    permutations: { [key: string]: IMyPageProps } = {
        // TODO: add params, as we add more permutations
        // TODO: get this working again
        'cold-in-sd': {}
    };
}
