import { MissionNotesPage } from './MissionNotesPage';
import { IScreenshotablePage } from '../../support/screenshots/IScreenshotablePage';
import { MissionsState } from '../../missions/model/MissionsState';

interface IMyPageProps {
    notesHtml: string;
}

export default class ScreenshotMissionNotesPage implements IScreenshotablePage<IMyPageProps> {
    static inject = () => [MissionsState];
    constructor(private missionsState: MissionsState) { }
    screenId = 'mission-notes';

    ComponentUnderTest = MissionNotesPage;

    permutationToProps = ({ notesHtml }: IMyPageProps) => {
        const missionId = '1';
        this.missionsState.addMissionToState({
            missionId,
            notesHtml
        } as any);

        return { missionId };
    };

    blockScreenshotTil = async () => {

    };


    permutations: { [key: string]: IMyPageProps } = {
        'no-notes' : {
            notesHtml: ''
        },
        'short-notes' : {
            notesHtml: 'Watch for <strong>low lines</strong>'
        },
        'simple-notes' : {
            notesHtml: 'Watch for <strong>low lines</strong>. Shot list: <ul><li>front, back, sides of barn, top down view</li><li>interior fly through</li></ul>'
        },
        'very-long-notes' : {
            notesHtml: getVeryLongNotes()
        },
    };
}


function getVeryLongNotes(){
    return `
<p>
Vanisher gladiator sugarman cypher gorillaman gargoyle? Songbird batroc punisher meltdown quasimodo avengers calypso mauler longshot exiles hammerhead bushwacker. Lightspeed madrox pepperpotts, barnes gorgon dracula manwolf romulus she-hulk dust jimmywoo. Dugan silversurfer shang-chi genis-vell captainmarvel phyla-vell octopus diablo constrictor nuke? Corsair menace romanoff odin praxagora doom hankpym msmarvel! Blastaar blackcat changeling lockjaw surge vanisher shield prodigy tonystark kingcobra spider-man darkhawk? Dust speedball lilandra celestials cerebro. Abomb madrox msmarvel, frankenstein nightmare kitty firebrand warpath thunderbolts crystal goliath purpleman. Redwing lilith gladiator ezekiel blonsky angel batroc.
</p>
<p>
Northstar powerman ronan satana clea. Kraven mastermind screwball pyro vanisher howard ezekiel mephisto vision madrox, lightspeed shadowcat! Rhodes echo arnim, moltenman masque moonknight zodiak whirlwind. Jeangrey ironman nitro calypso azazel xavier tchalla swordsman exiles goliath bishop abomination captainmarvel! Lockheed marrow avalanche miracleman nickfury strange scarletspider beyonder bucky rhodes praxagora shocker sif. Osborn blackwidow leech yellowjacket stature ultron smasher spider-man stryfe. Emmafrost crystal ricochet jameson dust magik northstar aurora scarletwitch whirlwind, drax lockheed. Nova genis-vell angel hobgoblin wasp gladiator pixie blackknight. Moleculeman morlocks star-lord angela suestorm excalibur invaders longshot mentallo jimmywoo firestar penance. Scourge, alpha-flight natasha hammerhead. Drax amora ricochet, logan johnnystorm angela scrambler korath shiva. Maverick centurions scourge spider-woman abomination korg pryde patch caretaker nighthawk? Mistyknight centurions swordsman moonknight demogoblin.
</p>
<p>
Ironman annihilus steverogers deathlok jackal, sprite x-factor spot. Jackpot madthinker greengoblin sunfire sentry. Fisk medusa marrow blob tombstone hulkling emmafrost tonystark! Janefoster hellion wiccan bluemarvel hellcat kree quentinquire kaine jubilee warbird demogoblin dust. Msmarvel grandmaster lifeguard barnes zodiak scourge hobgoblin. Patch toxin hankpym ink warmachine ff magus zemo blastaar? Msmarvel.
</p>
<p>
Kaine toro spider-girl x-force, zola wasp! Ironfist wonderman elektra manwolf loki snowbird strange komodo braddock bloodstone. Lilandra shaman tchalla daredevil uatu foggy yellowjacket scorpion angela taskmaster. Snowbird quasar madrox blackwidow jigsaw toro hyperion banner. Redskull kitty smasher lukecage reaper tinkerer callisto blastaar zodiak domino moleman; phyla-vell nightmare. Purpleman x-force jubilee onslaught shang-chi magneto, xavier mongoose. Barnes goliath hulkling exodus reaper wiccan gargoyle colossus. Klaw forge gargoyle braddock yellowjacket swordsman arcade hammerhead flatman onslaught, exodus mrnegative blonsky. Typhoidmary jeangrey husk shadowcat. Surge aurora shang-chi morph dynamo manthing betaray blackwidow ares elektra. Eternals bug sugarman armadillo wolverine sasquatch mentallo invaders. Xavier star-lord shocker colossus powerman jocasta nitro kronos contessa? Emmafrost cannonball chameleon firebird cyber deathlok ronan surge shadowcat dust hawkeye. Blackcat captainamerica?
</p>
<ul>
<li>
Thor moleman sunspot sasquatch madripoor avengers cable flatman dracula mactaggert jubilee captainmarvel feral crusher squirrel-girl puck spider-man sasquatch lightspeed sunspot taskmaster omega macgargan scrambler bishop meltdown toad lizard kraven arachne.
</li>
<li>
Havok nuke kraven namor falcon bob grandmaster howard kingpin cardiac morbius avalanche goliath wolfsbane stature phalanx paladin husk faustus feral x-51 silversurfer nightcrawler norrinradd ronan moonstone arcade warpath miracleman sabretooth.
</li>
<li>
Toad moonknight quentinquire whiplash jarvis ricochet blackheart daken marrow namor surge lukecage chimera gorillaman grimreaper dazzler lizard skrulls skrulls ultron dormammu arachne tombstone redskull onslaught excalibur mordo groot mephisto excalibur.
</li>
<li>
Calypso ilyana phalanx madripoor madripoor captainbritain hankpym speedball abomination menace satana corsair flatman sunspot sauron gorillaman whirlwind spider-woman blade madrox x-force, unus scorpion lilandra unionjack namor greengoblin abomb phyla-vell x-force?
</li>
<li>
Hammer pryde defenders mrnegative redhulk enchantress gladiator amora nuke constrictor dormammu stryfe runaways jimmywoo drax warbird mayparker nightmare odin shaman boomerang shadowcat moleculeman kraven gwen jackpot klaw inhumans shadowcat mauler!
</li>
</ul>
<p>
Steverogers dynamo jarvis grimreaper hercules spider-girl snowbird runaways abomb destiny microchip blackheart araña captainamerica boomerang strange omega songbird jocasta titania lilith callisto msmarvel morlun wilsonfisk rocketraccoon madripoor aurora amora barnes.
</p>
<p>
X-force skrulls giantman sunfire vision illuminati sage runaways tigra janefoster apocalypse unionjack klaw quasimodo caretaker lightspeed morbius sabretooth benreilly whirlwind hercules wonderman danvers redwing kronos toro warmachine ka-zar proteus moleman.
</p>
<p>
Warbound korg medusa bob forge rhino zola spider-woman bloodstone whirlwind ghostrider zola corsair magus wrecker warlock bug ultron hammer magik johnnyblaze blade faustus hobgoblin grimreaper feral loki deathlok stryker asgardian.
</p>
<p>
Hepzibah moonstone scrambler korg cuckoos madripoor medusa ironpatriot titania kree empath groot banshee shard mauler, enchantress abomb johnnyblaze spot morbius wong lukecage electro thing maelstrom barton benreilly spectrum karma uatu.
</p>
<p>
Captainbritain sauron ronan piledriver sabretooth maestro whitetiger apocalypse mistyknight ronan lilandra hulkling hawkeye silversurfer barnes humantorch maverick mysterio firestar sunfire demogoblin jackpot countess ghostrider paladin janefoster hyperion cuckoos rand satana.
</p>
<ul>
<li>
Thor moleman sunspot sasquatch madripoor avengers cable flatman dracula mactaggert jubilee captainmarvel feral crusher squirrel-girl puck spider-man sasquatch lightspeed sunspot taskmaster omega macgargan scrambler bishop meltdown toad lizard kraven arachne.
</li>
<li>
Havok nuke kraven namor falcon bob grandmaster howard kingpin cardiac morbius avalanche goliath wolfsbane stature phalanx paladin husk faustus feral x-51 silversurfer nightcrawler norrinradd ronan moonstone arcade warpath miracleman sabretooth.
</li>
<li>
Toad moonknight quentinquire whiplash jarvis ricochet blackheart daken marrow namor surge lukecage chimera gorillaman grimreaper dazzler lizard skrulls skrulls ultron dormammu arachne tombstone redskull onslaught excalibur mordo groot mephisto excalibur.
</li>
<li>
Calypso ilyana phalanx madripoor madripoor captainbritain hankpym speedball abomination menace satana corsair flatman sunspot sauron gorillaman whirlwind spider-woman blade madrox x-force, unus scorpion lilandra unionjack namor greengoblin abomb phyla-vell x-force?
</li>
<li>
Hammer pryde defenders mrnegative redhulk enchantress gladiator amora nuke constrictor dormammu stryfe runaways jimmywoo drax warbird mayparker nightmare odin shaman boomerang shadowcat moleculeman kraven gwen jackpot klaw inhumans shadowcat mauler!
</li>
</ul>
<p>
Ricochet octopus bishop deathstrike drax rogue osborn blackwidow jigsaw blonsky. Humantorch ironman sandman romulus shard. Countess wrecker psylocke ilyana ka-zar avalanche ego wrecker bluemarvel ironman maximus husk powerman. Greengoblin gorgon beast jeangrey. Zola mentor reaper redwing proteus atlas jocasta. Octopus jeangrey tchalla lightspeed lilandra. Lilith dracula blackpanther blink jocasta. Tombstone silvermane smasher ghostrider star-lord lifeguard suestorm dumdum xavier hellion korg romulus. Callisto avalanche sandman steverogers. Batroc rocketraccoon parker blink norrinradd moleculeman moonknight? Crystal titania mauler fisk spider-girl invaders flatman greengoblin loki maestro. Excalibur psylocke aurora toro puck illuminati sprite loki spider-ham. Xavier wasp benreilly banshee moltenman scourge jackpot stick boomboom, barnes caliban. Prism zemo juggernaut banner tonystark dugan kree jackpot, klaw hercules countess? Feral arcana korg quicksilver shang-chi inhumans penance omega nova maverick gwen. Annihilus odin jeangrey mayparker lockheed bob wildchild kitty. Arcana bloodstone jocasta leech captainamerica moonstone. Kingcobra moondragon eternals nuke parker pyro kronos faustus. Bishop flatman namora doom giantman rhino squirrel-girl nightmare archangel firebrand. Purpleman mimic janefoster satana polaris. Zodiak celestials invaders modok omega foggy arachne lilith. Redskull warpath warpath ka-zar! Bluemarvel barnes cardiac.
</p>

    `;
}
