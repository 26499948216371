// token used to register ionic Device.getInfo() in ioc
// (need our own token because ionic Device class doesn't play nice with aurelia)
export class Device {

    constructor() {
        throw new Error('Device must be instantiated via ioc');
    }

    platform: 'ios' | 'android' | 'web' = 'web';
    mode: 'ios' | 'md';
    osVersion: string;
    operatingSystem: string;
    manufacturer: string;
    model: string;
    name?: string;
    uuid: string;
    webViewVersion: string;
    diskFree?: number;
    diskTotal?: number;
    memUsed?: number;

    browserName?: string;
    browserVersion?: string;
}
