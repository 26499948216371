export async function eventually<T>(delegate: () => Promise<T>, maxMS = 1000, pollMS = 250): Promise<T> {

    const start = Date.now();
    let lastErr: any;
    let backoffCount = 0;

    while(Date.now() - start < maxMS) {
        try {
            return await delegate();
        } catch(err) {
            lastErr = err;
            // eslint-disable-next-line no-loop-func
            await new Promise(resolve => setTimeout(resolve, pollMS * (2 ** backoffCount++)));
        }
    }

    throw lastErr;
}
