import { useSubscription } from '@meraki-internal/state';
import { ForwardIcon } from '../../components/icons/Icons';
import { EditableMissionState } from '../model/EditableMissionState';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { AlertPresenter } from '../../app/AlertBinder';
import { OperatorState } from '../../profile/OperatorState';
import { ILAANCStatus } from '@autopylot-internal/tiles-client';
import { IonItem, IonLabel } from '@ionic/react';
import { Color } from '../../components/common';
import { TrackingService } from '../../support/tracking/TrackingService';

export const AuthorizationsButton = ({ mission, laancIconColor }: { mission: EditableMissionState, laancIconColor: Color }) => {
    const tracker = useInstance(TrackingService);

    const operatorState = useInstance(OperatorState);
    useSubscription(() => operatorState);
    useSubscription(() => mission);

    const { showAlert } = useInstance(AlertPresenter);

    const relevantAuthorizations = mission.getRelevantAuthorizations();

    const canFly = mission.getCanFly();

    const onClickAuthorization = () => {
        // get overall laanc status for mission
        const laancStatus = mission.getAPLAANCStatus();

        tracker.track('Authorization Viewed', () => ({ canFly, laancStatus }));

        if (laancStatus === 'authorized') {
            mission.pages.laancAuthorization.go();
        } else if (['authorization-failed'].includes(laancStatus!)) {
            showAlert({
                header: 'LAANC Authorization Not Valid',
                message: 'We failed to obtain LAANC Authorization for this mission.',
            });
        } else if (['rescinded', 'invalidated'].includes(laancStatus!)) {
            showAlert({
                header: 'LAANC Authorization Not Valid',
                message: 'Per the FAA, LAANC Authorization for this mission is no longer valid.',
            });
        } else if (['user-canceled'].includes(laancStatus!)) {
            showAlert({
                header: 'LAANC Authorization Canceled',
                message: 'You have canceled this mission, so LAANC Authorization for the mission has also been canceled.',
            });
        } else if (['rescind-acked', 'invalidated-acked'].includes(laancStatus!)) {
            showAlert({
                header: 'LAANC Authorization Canceled',
                message: 'You canceled this mission because LAANC Authorization for the mission is no longer valid, per the FAA.',
            });
        } else if (laancStatus === 'authorizing-failed') {
            showAlert({
                header: 'Mission Not Approved',
                message: `LAANC Authorization for this mission failed.`
            });
        } else {
            showAlert({
                header: 'LAANC Authorization Pending',
                message: 'We were not yet able to get LAANC authorization from the FAA. We will retry for up to 10 minutes. Pull down on the mision to check if it has been approved.'
            });
        }
    };

    if (relevantAuthorizations.length === 0){
        return null;
    }

    const authStatuses = relevantAuthorizations.map(authorization => {
        const { getAPLAANCStatus, operationID, airspaceIdent } = authorization;
        const status = getAPLAANCStatus({ endTime: mission.getEndTime() });
        const statusLabel = laancStatusLabels[status];
        return `${statusLabel}: APY${operationID} (${airspaceIdent})`;
    });
    return (
        <IonItem button detail={false} onClick={onClickAuthorization} data-id="authorizations-button">
            <IonLabel style={{marginRight: 0}}>
                <h2>Authorization</h2>
                {authStatuses.map((authStatus, i) => (
                    <p key={i}>{authStatus}</p>
                ))}
            </IonLabel>
            <ForwardIcon slot="end" color="medium" size="medium" />

        </IonItem>
    );
};


const laancStatusLabels: { [status in ILAANCStatus]: string } = {
    'initialized': 'Pending',
    'authorizing': 'Pending',
    'authorizing-failed': 'Not Valid',
    'authorized': 'Approved',
    'rescinded': 'Not Valid',
    'rescind-acked': 'Canceled per FAA',
    'invalidated': 'Not Valid',
    'invalidated-acked': 'Canceled per FAA',
    'never-authorized': 'Canceled',
    'user-canceled': 'Canceled',
    'user-closed': 'Closed',
    'user-deleted': 'Deleted'
};
