import { IonModal } from '@ionic/react';
import { Button } from '../button/Button';
import { PageContent } from '../page/PageContent';
import { PageHeader } from '../page/PageHeader';
import { PageFooter } from '../page/PageFooter';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useSubscription } from '@meraki-internal/state';
import { AnnouncementDialogState } from './AnnouncementDialogState';

export const AnnouncementDialog: React.FC = () => {

    const dialogState = useInstance(AnnouncementDialogState);
    useSubscription(() => dialogState);

    const onDismiss = (e: any) => {
        // invoke close(), but only if the user clicked on the backdrop (or hit the escape key)
        // (this avoids invoking handler twice when close button clicked, or close() is invoked directly)
        if (e.detail.role === 'backdrop') {
            dialogState.close();
        }
    };

    return (
        <IonModal isOpen={dialogState.isOpen()} onWillDismiss={onDismiss}>

            <PageHeader transparent onClose={dialogState.close} />

            <PageContent noScroll>
                <div style={{paddingTop: 10, maxWidth: 400, width: '100%', margin: 'auto'}}>
                    <img src={dialogState.state.imagePath} alt="Announcement" />
                </div>
            </PageContent>

            <PageFooter>
                <hr style={{ backgroundColor: '#eee' }} />
                <div style={{margin: 30}}>
                    <Button expand="block" onClick={dialogState.handleContinue}>Continue</Button>
                </div>
            </PageFooter>

        </IonModal>
    );
};
