import React from 'react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { State, useSubscription } from '@meraki-internal/state';
import { Page } from '../components/page/Page';
import { OperatorState } from '../profile/OperatorState';
import { AlertPresenter } from '../app/AlertBinder';
import { TrackingService } from '../support/tracking/TrackingService';
import { AuthService } from '../auth/AuthService';
import { Welcome } from './Welcome';
import { Terms } from './Terms';
import { SetupOperator } from './SetupOperator';

export class SetupPageState extends State<{started: boolean}> {
    constructor() {
        super({ started: false });
    }
}

type ISetupPage = 'Welcome' | 'Terms' | 'Profile';

export const SetupPage: React.FC = () => {
    const tracker = useInstance(TrackingService);

    const alert = useInstance(AlertPresenter);

    const operatorState = useInstance(OperatorState);
    useSubscription(() => operatorState);

    const pageState = useInstance(SetupPageState);
    useSubscription(() => pageState);

    const authService = useInstance(AuthService);

    const acceptTerms = async () => {
        tracker.track('Terms Accepted');
        try{
            await operatorState.save({ agreedToTermsOfService: true, agreedToFAAPrivacyStatement: true, agreedToLAANCStatement: true });
            // forceRender();
        }
        catch(e: any) {
            alert.showAndLogError(e);
        }
    };

    const getStarted = () => {
        pageState.setState({ started: true });
    };

    const signIn = async (fromPage: 'Welcome' | 'Terms') => {
        tracker.track(`${fromPage} | Sign In`);
        await authService.showLoginDialog();
    };

    const signOut = async () => {
        tracker.track(`Terms | Sign Out`);
        await authService.logout();
    };

    const isGuest = operatorState.isGuest();
    const page: ISetupPage = isGuest && !pageState.state.started ? 'Welcome' : operatorState.needsConsent() ? 'Terms' : 'Profile';

    return (
        <Page noScroll hideMenu>
            {page === 'Welcome' &&
                <Welcome getStarted={getStarted} signIn={signIn} />
            }

            {page === 'Terms' &&
                <Terms acceptTerms={acceptTerms} signIn={isGuest ? signIn : undefined} signOut={!isGuest ? signOut : undefined} />
            }

            {page === 'Profile' &&
                <SetupOperator />
            }
        </Page>
    );
};
