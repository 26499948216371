import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Button } from './button/Button';
import { IonCol, IonGrid, IonRow, IonText } from '@ionic/react';
import { Page } from './page/Page';
import { HistoryViewModel } from '../app/HistoryViewModel';
import { useInstance } from '@meraki-internal/react-dependency-injection';

export interface Group extends React.FC {
    componentName: string;
};
const requireContext = (require as any).context('../', true, /\/Demo.*\.tsx$/);

export const groups: Group[] = requireContext.keys().map((moduleId: string) => requireContext(moduleId))
    .map((mod: any) => mod.default);

export const ComponentLibrary: React.FC = () => {
    const history = useInstance(HistoryViewModel);
    const match = useRouteMatch();

    return (
        <Page onBack={ history.back} title="Component Library">
            <IonGrid>
                <IonRow>
                    <IonCol size="12" size-md="3" style={{borderRight: '1px solid gray', borderBottom: '1px solid gray'}}>
                        {groups.map(g => (
                            <div key={g.componentName} style={{margin: -10}}>
                                <Button
                                    href={`${match.path}/${g.componentName}`}
                                    color="secondary"
                                    fill="clear"
                                    minWidth={0}
                                >
                                    {g.componentName}
                                </Button>
                            </div>
                        ))}
                    </IonCol>
                    <IonCol size="12" size-md="9" style={{minHeight: '100vw'}}>
                        <div style={{ padding: '0 20px', height: '100%'}}>
                            <Switch>
                                {groups.map(g => {
                                    const url = `${match.path}/${g.componentName}`;
                                    const GroupComponent = g;
                                    return (
                                        <Route path={url} key={g.componentName} exact>
                                            <div style={{height: '100%'}}>
                                                <h1><IonText>{g.componentName}</IonText></h1>
                                                <GroupComponent />
                                            </div>
                                        </Route>
                                    );
                                })};
                                <Route path={match.path}>
                                    <p>Choose a component from the list.</p>
                                </Route>
                            </Switch>
                        </div>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </Page>
    );
};
