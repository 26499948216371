import { isPlatform } from '@ionic/react';
import { UAParser } from 'ua-parser-js';
import { StandalonePage } from './StandalonePage';
import { TrackingService } from '../support/tracking/TrackingService';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useEffect } from 'react';
import { StoreConfigs } from './config/StoreConfigs';

const supportedBrowsers: { name: string, minVersion: number }[] = [
    { name: 'Chrome', minVersion: 115 }, // 07/2023
    { name: 'Firefox', minVersion: 115 }, // 07/2023
    { name: 'Edge', minVersion: 113 }, // 04/2023
    { name: 'Safari', minVersion: 15 }, // 09/2021
    { name: 'Mobile Safari', minVersion: 15 }, // 09/2021
];

export const isUnsupported = () => {
    // supported if native app
    if (isPlatform('capacitor')) {
        return false;
    }
    // supported if local storage override
    if (window.localStorage.getItem('ap:enable-web-access') === 'true') {
        return false;
    }
    // supported if test page
    if ((window.location.search.indexOf('?__test') === 0)) {
        return false;
    }
    // not supported if web on ios/android
    if (isPlatform('ios') || isPlatform('android')) {
        return true;
    }
    // supported if browser meets requirements
    const { name, version } = new UAParser().getBrowser();
    if (supportedBrowsers.some(sb => name === sb.name && Number(version?.split('.')[0]) >= sb.minVersion)) {
        return false;
    }
    // otherwise, not supported
    return true;
};

export const UnsupportedPage = () => {

    // if the redirect_uri is lost from state during the auth flow, the api AuthRouter will
    // fall back to WEB_BASE_URL, which on ios will result in the ASWebAuthSession window
    // landing on this page with error params, so show an appropriate message in this case
    const params = new URLSearchParams(window.location.hash.split('?')[1]);
    const isAuthError = (params.has('error_description') || params.has('error'));

    const tracker = useInstance(TrackingService);

    useEffect(() => {
        if (!isAuthError) {
            tracker.track('Unsupported Browser', () => {
                const { browser, device, os, ua } = new UAParser().getResult();
                return {
                    browser: `${browser.name} ${browser.version}`,
                    device: `${device.vendor} ${device.model} ${device.type || ''}`.trim(),
                    os: `${os.name} ${os.version}`,
                    ua
                };
            });
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (isAuthError) {
        return (
            <StandalonePage showLogo>
                <div style={{maxWidth: 300, margin: '0 auto'}}>
                    <p>Sorry, something went wrong, and we were unable to sign you in. Please close the window and try again.</p>
                </div>
            </StandalonePage>
        );
    }

    return (
        <StandalonePage showLogo>

            {isPlatform('ios') &&
                <div style={{maxWidth: 300, margin: '0 auto'}}>
                    <p>To use AutoPylot, please download it from the <a href={StoreConfigs.ios.installURL}>App Store</a>.</p>
                </div>
            }

            {isPlatform('android') &&
                <div style={{maxWidth: 300, margin: '0 auto'}}>
                    <p>To use AutoPylot, please download it from the <a href={StoreConfigs.android.installURL}>Play Store</a>.</p>
                </div>
            }

            {!isPlatform('ios') && !isPlatform('android') &&
                <div style={{maxWidth: 350, margin: '0 auto'}}>
                    <p>Oops! This browser is not supported.</p>
                    <p>
                        For the optimal experience, we recommend using the latest version of <a href="https://www.google.com/chrome/dr/download/" target="_blank">Chrome</a>.
                        (AutoPylot also works on the latest versions of Safari, Firefox, and Edge.)
                    </p>
                    <p>Or <a href="https://autopylot.io">download our app</a> to use AutoPylot on your phone or tablet.</p>
                </div>
            }

        </StandalonePage>
    );
};
