import { IonInput } from '@ionic/react';
import { useEffect, useRef } from 'react';
import { formatPhone } from '../../support/helpers/format';
import { Color } from '../common';
import { FieldInputProps } from './Field';

export type InputType = 'date' | 'number' | 'tel' | 'text' | 'email';

export type InputMode = 'tel' | 'text' | 'numeric' | 'email';

export interface TextInputProps extends FieldInputProps {
    value?: string;
    onChange?: (value: string) => void;
    onBlur?: (value: string) => void;
    type?: InputType;
    inputmode?: InputMode;
    pattern?: string;
    maxlength?: number;
    title?: string;
    autocapitalize?: 'characters' | 'words' | 'sentences';
    autofocus?: boolean;
    color?: Color;
    placeholder?: string;
}

export const TextInput: React.FC<TextInputProps> = ({ value, onChange, onBlur, type = 'text', inputmode, pattern, maxlength, title, required, bordered, readonly, color, autocapitalize, autofocus, placeholder }) => {
    const inputRef = useRef<any>(null);

    // the "autofocus" attribute provided by ionic it works on the first mount,
    // but seemingly not after that - hence the need for this timeout
    useEffect(() => {
        if (autofocus) {
            setTimeout(() => inputRef.current?.setFocus(), 100);
        }
    }, [autofocus]);

    const inputStyle = bordered ? { maxHeight: 35 } : undefined;

    let displayValue = value;
    if (type === 'tel' && readonly) {
        displayValue = value ? formatPhone(value) : undefined;
    }

    const handleKeyPress = (e: any) => {
        // workaround because maxLength is not applied across all input types (e.g. doesn't work on "number")
        if (maxlength && value && value.length >= maxlength) {
            e.preventDefault();
            return false;
        }
    };

    const handleChange = async (e: any) => {
        // if "pattern" and "title" attributes are set, then "title" should be shown as validation message
        // if value doesn't match "pattern", but this doesn't work on ios, so we need this workaround instead
        if (pattern && title) {
            const matches = (new RegExp(pattern, 'u').test(e.detail.value));
            const el = await e.target.getInputElement();
            el.setCustomValidity(matches ? '' : title);
        }
        if (onChange && !readonly) {
            onChange(e.detail.value);
        }
    };

    const handleBlur = async (e: any) => {
        if (onBlur) {
            onBlur(e.target.value);
        }
    };

    return (
        <IonInput
            ref={inputRef}
            className="smartlook-show"
            style={inputStyle}
            type={readonly ? 'text' : type}
            inputmode={inputmode}
            pattern={pattern}
            maxlength={maxlength}
            title={title}
            required={required}
            readonly={readonly}
            color={color}
            value={displayValue}
            onIonChange={handleChange}
            onKeyPress={handleKeyPress}
            onBlur={handleBlur}
            autocapitalize={autocapitalize}
            autofocus={autofocus}
            placeholder={placeholder}
        />
    );
};
