import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useSubscription } from '@meraki-internal/state';
import { RoundIconButton } from '../../components/button/RoundIconButton';
import { RemoveIcon } from '../../components/icons/Icons';
import { MapViewModel } from '../model/MapViewModel';

export const ZoomOutButton: React.FC = () => {

    const mapVM = useInstance(MapViewModel);
    useSubscription(() => mapVM);

    const zoomIn = () => {
        // if we're already at min zoom, setZoom will do nothing
        mapVM.setZoom(mapVM.getZoom() - 1);
    };

    return (
        <RoundIconButton Icon={RemoveIcon} onClick={zoomIn} />
    );
};
