import { IonToast } from '@ionic/react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useSubscription } from '@meraki-internal/state';
import { useEffect } from 'react';
import { HistoryViewModel } from '../app/HistoryViewModel';
import { CloseIcon } from '../components/icons/Icons';
import { TrackingService } from '../support/tracking/TrackingService';
import { UndoState } from './UndoState';

export const Undo: React.FC = () => {

    const undo = useInstance(UndoState);
    useSubscription(() => undo);

    const history = useInstance(HistoryViewModel);
    const tracker = useInstance(TrackingService);

    // clear undo when we navigate to a different page
    // (if undo has been visible for at least 500ms)
    useEffect(() => {
        history.listen((loc) => {
            if (undo.state?.shownAt && Date.now() - undo.state.shownAt > 500) {
                undo.clear();
            }
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (!undo.state) {
        return null;
    }

    const buttons = [
        {
            text: 'Undo',
            handler: async () => {
                const { message } = undo.state;
                tracker.track('Undo Invoked', () => ({ message }));
                await undo.state.handler();
            }
        },
        {
            icon: CloseIcon.raw,
            role: 'cancel'
        }
    ];

    return (
        <IonToast htmlAttributes={{'data-id': 'undo-toast'}} isOpen={Boolean(undo.state.shownAt)} message={undo.state.message} onDidDismiss={undo.clear} buttons={buttons} />
    );
};
