import { useInstance } from '@meraki-internal/react-dependency-injection';
import { HistoryViewModel } from '../app/HistoryViewModel';
import { Page } from '../components/page/Page';
import { useEditableMissionState } from '../missions/model/useEditableMissionState';
import { usePageView } from '../support/tracking/usePageView';
import { WindyMissionMap } from './windy/WindyMissionMap';
import { MissionColorProvider } from '../flight-checklist/MissionColorProvider';
import { Theme } from '../app/ThemeVariables';

export const WeatherMapPage: React.FC<{ missionId: string }> = ({ missionId }) => {
    usePageView('Weather Map');

    const history = useInstance(HistoryViewModel);

    const colorProvider = useInstance(MissionColorProvider);
    const theme = useInstance(Theme);
    const mission = useEditableMissionState(missionId);

    if (!mission) {
        return null;
    }

    const center = mission.getLocation();
    const radiusMeters = mission.getMissionRadius().getMissionRadius().properties.radiusMeters;
    const startTime = mission.getStartTime();
    const radiusColor = theme.getColor(colorProvider.getColor(mission.getMissionRadius()));
    const radiusShape = mission.getMissionRadius().getMissionRadius().properties.isSquare ? 'square' : 'circle';

    return (
        <Page onBack={() => history.back(mission.pages.weather.path())} fullWidth>
            <WindyMissionMap
                center={center}
                radiusMeters={radiusMeters}
                radiusColor={radiusColor}
                radiusShape={radiusShape}
                startTime={startTime}
            />
        </Page>
    );
};
