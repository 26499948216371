import { IonContent, IonHeader, IonModal } from '@ionic/react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useSubscription } from '@meraki-internal/state';
import { VisibleMapLayersState } from './VisibleMapLayersState';
import { ToggleLayers } from './ToggleLayers';
import { useRef, useEffect } from 'react';
import { RoundIconButton } from '../../components/button/RoundIconButton';
import { CloseIcon } from '../../components/icons/Icons';
import { useBreakpoints } from '../../support/hooks/useBreakpoints';

// TODO: extract Sheet to go with SheetContent and get them into the component library
//  use it for AuthorizationDetails

const SheetContent: React.FC<{}> = ({ children }) => {
    const innerDivRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const onMove = (e: TouchEvent) => {
            e.stopImmediatePropagation();
        };

        const el = innerDivRef.current!;

        el.addEventListener('touchmove', onMove);

        const unsub = () => {
            el.removeEventListener('touchmove', onMove);
        };

        return unsub;
    }, [innerDivRef]);

    // bottom margin or the user can't scroll into the bottom content of the sheet. Bottom drawer does the same thing. */}
    const style: React.CSSProperties = { marginBottom: 60 };

    return (
        <div style={style} ref={innerDivRef}>
            {children}
        </div>
    );

};

export const ToggleLayersSheet = () => {
    const visibleMapLayersState = useInstance(VisibleMapLayersState);
    useSubscription(() => visibleMapLayersState);

    const { isLargerScreen } = useBreakpoints();

    return (
        <IonModal
            isOpen={visibleMapLayersState.state.isShowingControls}
            initialBreakpoint={isLargerScreen ? undefined : 0.95}
            breakpoints={isLargerScreen ? undefined : [0, 0.95]}
            onDidDismiss={() => visibleMapLayersState.hideControls()}
        >
            <IonHeader className="ion-no-border">
                <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', padding: '5px 5px 0'}}>
                    <h5 style={{
                        color: 'var(--ion-text-color',

                        // so this gets most of the space
                        flexGrow: 4,

                        textAlign: 'center',

                        // to offset for the close button (so center is where we want it)
                        marginLeft: 52
                    }}>Map Layers</h5>
                    <RoundIconButton
                        Icon={CloseIcon}
                        onClick={() => visibleMapLayersState.hideControls()}
                        backgroundColor='light'
                        iconColor='medium'
                    />
                </div>
            </IonHeader>
            <IonContent style={{'--padding-start': '15px', '--padding-end': '15px'}}>
                <SheetContent>
                    <ToggleLayers />
                </SheetContent>
            </IonContent>
        </IonModal>
    );
};
