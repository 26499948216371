import { IonCheckbox, IonItem, IonItemSliding, IonLabel, IonList, IonText } from '@ionic/react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useSubscription } from '@meraki-internal/state';
import { useEffect, useState } from 'react';
import { AlertPresenter } from '../app/AlertBinder';
import { HistoryViewModel } from '../app/HistoryViewModel';
import { Button } from '../components/button/Button';
import { Page } from '../components/page/Page';
import { Spinner } from '../components/spinner/Spinner';
import { MissionChecklistStateFactory } from '../mission/checklist/MissionChecklistStateFactory';
import { EditableMissionState } from '../mission/model/EditableMissionState';
import { useEditableMissionState } from '../missions/model/useEditableMissionState';
import { TrackingService } from '../support/tracking/TrackingService';
import { usePageView } from '../support/tracking/usePageView';
import { UndoState } from '../undo/UndoState';
import { SavedChecklistsState } from './SavedChecklistsState';

export const CopySavedChecklistsPage: React.FC = () => {
    usePageView('Select Checklist Templates');

    const history = useInstance(HistoryViewModel);

    const searchParams = history.getCurrentSearchParams();
    const missionId = searchParams.get('toMissionId');

    const mission = useEditableMissionState(missionId!);

    if (!mission) {
        return null;
    }

    return (
        <Page title="Select Checklist Templates" onBack={history.back}>
            <CopySavedChecklists mission={mission} />
        </Page>
    );
};

const CopySavedChecklists: React.FC<{mission: EditableMissionState}> = ({ mission }) => {

    const history = useInstance(HistoryViewModel);
    const tracker = useInstance(TrackingService);
    const alert = useInstance(AlertPresenter);

    const undo = useInstance(UndoState);
    const factory = useInstance(MissionChecklistStateFactory);

    const [selectedIdMap, setSelectedIdMap] = useState<{[key: string]: boolean}>({});

    const vm = useInstance(SavedChecklistsState);
    useSubscription(() => vm);

    useSubscription(() => mission);

    useEffect(() => {
        if (!vm.state.loaded) {
            vm.load();
        }
    }, [vm]);

    if (!vm.state) {
        return null;
    }

    const sortedChecklists = vm.state.checklists.sort((c1, c2) => c1.state.checklist.name.localeCompare(c2.state.checklist.name));

    const onSelectionChanged = (checklistId: string, selected: boolean) => {
        setSelectedIdMap({ ...selectedIdMap, [checklistId]: selected });
    };

    const onCopy = async () => {
        const selectedIds = Object.keys(selectedIdMap).filter(k => selectedIdMap[k]);
        const selectedChecklists = await Promise.all(selectedIds.map(vm.getChecklistById));
        const checklistsToCopy = selectedChecklists.filter(c => c.getItemCount() > 0);

        if (!checklistsToCopy.length) {
            await alert.showAlertV2({ header: 'No Items to Add' });
            return;
        }

        try {
            const missionChecklist = factory.getInstance(mission);
            const oldChecklist = missionChecklist.getChecklist();
            await missionChecklist.copyItems(checklistsToCopy);

            for (const c of checklistsToCopy) {
                tracker.track('Checklist Added to Mission', () => ({ name: c.state.checklist.name, missionId: mission.state.missionId }));
            }

            undo.show({ message: 'Added checklist items', handler: async () => {
                await missionChecklist.updateChecklist(oldChecklist);
            }});
        } catch (e) {
            alert.showAndLogError(e);
        }

        // return to mission checklist page
        history.back();
    };

    return (
        <>
            <div style={{margin: '30px 25px'}}>

                <p style={{marginBottom: 20}}>
                    {sortedChecklists.length > 0 ? 'Select templates to add.' : `You don't have any templates yet.`}
                </p>

                {!vm.state.loaded &&
                    <div style={{textAlign: 'center', marginBottom: 15}}>
                        <Spinner size="small" />
                    </div>
                }

                <IonList data-type="saved-checklists" lines="full">
                    {sortedChecklists.map(checklistState => {
                        const { checklistId, name } = checklistState.state.checklist;

                        return (
                            <IonItemSliding key={`checklist-${checklistId}`}>

                                <IonItem>
                                    <IonCheckbox slot="start" onIonChange={(e) => onSelectionChanged(checklistId!, e.detail.checked)} />
                                    <IonLabel>{name}</IonLabel>
                                    <IonText slot="end" color="medium">{checklistState.getItemCount()}</IonText>
                                </IonItem>

                            </IonItemSliding>
                        );
                    })}
                </IonList>

            </div>

            <div style={{margin: 20}}>
                <Button dataType="copy-to-mission" expand="block" disabled={sortedChecklists.length === 0} onClick={onCopy}>Add to Mission</Button>
            </div>
        </>
    );
};
