import { ISO8601DateTimeString } from '@autopylot-internal/tiles-client';
import { FeatureCollection } from '@turf/turf';
import moment from 'moment';
import { formatLocalDayAndTime } from '../../support/helpers/format';

export class MockAirspaceProvider {
    private mockAirspaceData: FeatureCollection = {
        type: 'FeatureCollection',
        features: []
    };

    // gets set by MapViewModel (likely a better way, but finding a circular mess and this was my last resort)
    map!: mapboxgl.Map;

    get = (): FeatureCollection => {
        return this.mockAirspaceData;
    };

    set = (feature: FeatureCollection) => {
        this.mockAirspaceData = feature;
        const source = this.map.getSource('mock:source:airspace') as mapboxgl.GeoJSONSource;
        source.setData(feature as any);
    };

    reset = () => {
        this.set({
            type: 'FeatureCollection',
            features: []
        });
    };

    repaint = () => {
        this.set(this.get());
    };

    help = () => {
        console.log('.addPartTimeNSUFR() to add a part-time feature that by default starts 90 mins after the start of the current hour and lasts 3 hours');
    };

    addPartTimeNSUFR = ({ startTime }: { startTime?: ISO8601DateTimeString } = {}) => {
        const ALERTTIME = moment().toISOString();
        const defaultStartTime = moment().add(15, 'minute').add(1, 'hour').startOf('h').toISOString();
        const ACTIVETIME = startTime || moment(defaultStartTime).add(30, 'm').toISOString();
        const ENDTIME = moment(ACTIVETIME).add(3, 'hour').toISOString();
        this.set({
            'type': 'FeatureCollection',
            'features': [
                {
                    'type': 'Feature',
                    'properties': {
                        GLOBAL_ID: 'mock-parttime-nsufr',
                        'source': 'parttime-nsufr',
                        ALERTTIME,
                        ACTIVETIME,
                        ENDTIME,
                    },
                    'geometry': {
                        'type': 'Polygon',
                        'coordinates': [
                            [
                                [
                                    -106.2649154663086,
                                    31.758969510446004
                                ],
                                [
                                    -106.21959686279297,
                                    31.758969510446004
                                ],
                                [
                                    -106.21959686279297,
                                    31.781079580020354
                                ],
                                [
                                    -106.2649154663086,
                                    31.781079580020354
                                ],
                                [
                                    -106.2649154663086,
                                    31.758969510446004
                                ]
                            ]
                        ]
                    }
                }
            ]
        });

        const location: [number, number] = [
            -106.2649154663086,
            31.758969510446004
        ];

        this.map.setCenter(location);

        console.log(`active from ${formatLocalDayAndTime({ time: ACTIVETIME, withTimeZone: true, location: { lng: location[0], lat: location[1] } })} to ${formatLocalDayAndTime({ time: ENDTIME, location: { lng: location[0], lat: location[1] }, withTimeZone: true })}`);
        console.log('12300 Montwood Drive el paso tx');

    };
}
