import { IActiveWindow, IControlledAirspaceAdvisoryProperties, MissionFeatureCollection, TimesheetMaterializer } from '@autopylot-internal/tiles-client';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import moment from 'moment';
import { useState } from 'react';
import { AnchorButton } from '../../components/button/AnchorButton';
import { formatLocalTimeRange, formatLocalDateShort } from '../../support/helpers/format';
import { SecondaryWarningAdvisory } from '../SecondaryAdvisory';

export const ScheduledAirspaceAdvisory = (props: IControlledAirspaceAdvisoryProperties  & { mission: MissionFeatureCollection }) => {
    const timesheetMaterializer = useInstance(TimesheetMaterializer);

    const [ schedule, setSchedule ] = useState<IActiveWindow[]>([]);

    if (!(props as any).activeDuring){
        return null;
    }

    const activeTimesOfMission = timesheetMaterializer.materialize({
        windowToMaterialize: {
            startTime: props.mission.getMissionRadius().properties.startTime,
            endTime: props.mission.getMissionRadius().properties.endTime
        },
        timesheets: (props as any).activeDuring
    });

    const location = props.mission.getMissionCenter();

    const showSchedule = () => {
        const startTime = moment(props.mission.getMissionRadius().properties.startTime).startOf('week').toISOString();
        const endTime = moment(props.mission.getMissionRadius().properties.startTime).endOf('week').toISOString();

        const activeTimesOfMissionWeek = timesheetMaterializer.materialize({
            windowToMaterialize: {
                startTime,
                endTime
            },
            timesheets: (props as any).activeDuring
        });
        setSchedule(activeTimesOfMissionWeek);
    };

    const hideSchedule = () => {
        setSchedule([]);
    };

    return (
        <SecondaryWarningAdvisory header={`Scheduled Airspace (${props.IDENT})`} advisoryType={'advise'}>
            <>
                {activeTimesOfMission.length === 0 && (
                    <span>For your awareness: this mission overlaps controlled airspace with a schedule, but your mission is currently outside the schedule. </span>
                )}
                {activeTimesOfMission.length > 0 && (
                    <span>Your mission overlaps {props.IDENT} which is a scheduled airspace. This airspace is active from <strong>{activeTimesOfMission.map(({ startTime, endTime }) => `${formatLocalTimeRange({ startTime, endTime, location, withTimeZone: false })}`).join(', ')} </strong></span>
                )}

                {(!schedule || schedule.length === 0) && (
                    <AnchorButton onClick={showSchedule} data-type="expand-schedule">Show Schedule</AnchorButton>
                )}

                {schedule && schedule.length > 0 && (
                    <>
                        <table style={{width: '100%', marginTop: 0, marginBottom: '10px', marginLeft: '10px'}} >
                            <tbody>
                                {schedule.map(({ startTime, endTime  }, index) => {
                                    const formattedDate = formatLocalDateShort({ date: startTime, location });
                                    const isSameAsLast = index > 0 && formatLocalDateShort({ date: schedule[index -1].startTime, location }) === formattedDate;
                                    return (
                                        <tr key={JSON.stringify({startTime, endTime })} style={{width: '100%'}}>
                                            <td style={{width: '30%', fontWeight: 'bold', verticalAlign: 'top', paddingTop: !isSameAsLast ? '10px' : 'inherit' }}>{!isSameAsLast ? formattedDate : ''}</td>
                                            <td style={{width: '70%', paddingTop: !isSameAsLast ? '10px' : 'inherit'}}>{formatLocalTimeRange({ startTime, endTime, location, withTimeZone: false })}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>

                        <AnchorButton onClick={hideSchedule}>Hide Schedule</AnchorButton>
                    </>
                )}
            </>
        </SecondaryWarningAdvisory>
    );
};
