import { AdvisoryInfoIcon } from '../../components/icons/Icons';
import { SecondaryAdvisory } from '../SecondaryAdvisory';

export const NOTAMAdvisory = () => {
    return (
        <SecondaryAdvisory header="Notices to Air Missions Warning" advisoryType='advise' icon={AdvisoryInfoIcon} iconColor="medium">
            Please review Notices to Air Missions (NOTAMs) for your flight area at <a href="https://notams.aim.faa.gov/notamSearch/" target="_blank">https://notams.aim.faa.gov/notamSearch</a>.
        </SecondaryAdvisory>
    );
};
