import { useInstance } from '@meraki-internal/react-dependency-injection';
import { RoundIconButton } from '../../components/button/RoundIconButton';
import { WindyMapIcon } from '../../components/icons/Icons';
import { WindyMapModalState } from './WindyMapModalState';
import { HistoryViewModel } from '../../app/HistoryViewModel';

export const WindyMapButton: React.FC = () => {
    const history = useInstance(HistoryViewModel);

    const windyModal = useInstance(WindyMapModalState);

    // hide this button when viewing a saved mission, because we already provide
    // access to windy for missions via mission details without AutoPylot+
    if (history.getCurrentLocation().pathname.includes('/missions')) {
        return null;
    }

    // not available for missions in past, or more than 9 days in future
    if (!windyModal.isAvailable()) {
        return null;
    }

    return (
        <RoundIconButton Icon={WindyMapIcon} onClick={windyModal.open} />
    );
};
