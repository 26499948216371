import { useInstance } from '@meraki-internal/react-dependency-injection';
import { Button } from '../../components/button/Button';
import { TrackingService } from '../../support/tracking/TrackingService';
import { MissionsState } from '../model/MissionsState';
import { MapViewModel } from '../../map/model/MapViewModel';
import { HistoryViewModel } from '../../app/HistoryViewModel';

export const NewMissionButton = () => {
    const map = useInstance(MapViewModel);
    const vm = useInstance(MissionsState);
    const tracker = useInstance(TrackingService);
    const history = useInstance(HistoryViewModel);

    const onClick = () => {
        tracker.track('New Mission Button Tapped');

        // if they click "new mission" before moving the map, use burlington as a demo
        const sampleLocation = { lat: 44.476505, lng: -73.221054 };
        const coordinates = map.isInitialCenter() ? sampleLocation : map.getCenter();

        const dropPin = `${coordinates.lng},${coordinates.lat}`;
        history.push(vm.pages.new.path({ dropPin }));
    };

    return (
        <div style={{ textAlign: 'center', margin: '10px 0 0' }}>
            <Button expand="block" onClick={onClick} dataType="create-mission-button">
                New Mission
            </Button>
        </div>
    );
};
