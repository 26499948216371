import { IonIcon } from '@ionic/react';
import { pencil, timeOutline, locationOutline, chevronDownOutline, chevronUpOutline,
    mapSharp, mapOutline, paperPlaneOutline, chatbubbleOutline, personOutline, shareSocialOutline,
    chevronBackOutline, chevronForward, trashOutline, calendarClearOutline,
    close, alert, checkmark, informationCircleOutline,
    closeCircle, alertCircle, checkmarkCircle,
    closeCircleOutline, alertCircleOutline, checkmarkCircleOutline,
    checkmarkDone, checkmarkDoneCircle, checkmarkDoneCircleOutline,
    pulseOutline, ellipsisHorizontal, constructOutline, checkmarkSharp, checkmarkCircleSharp,
    eyeOutline, eyeOffOutline,
    locateOutline,
    notificationsOutline, notificationsCircleOutline,
    addOutline, removeOutline,
    menuSharp, menuOutline,
    settingsOutline,
    arrowUpOutline,
    layersOutline,
    bulbOutline,
    logOutOutline,
    documentTextOutline,
    listOutline,
    partlySunnyOutline,
    list,
    ellipseOutline,
    warning, warningOutline,
    mailOutline, logoApple,
    shieldCheckmarkOutline,
    copy, copyOutline,
    umbrella, umbrellaOutline,
    rocketOutline,
} from 'ionicons/icons';
import { Color, Size } from '../common';

// https://ionic.io/ionicons

/**
 * We expose both the IconComponent and a raw value.
 *
 * eg import { Edit  } from './Icons';
 *
 *
 * eg component usage
 * <Edit />
 *
 * eg raw usage
 * <ActionMenu actions={[{ text: 'Edit', icon: Edit.raw }]}
 *
 * we will inevitably need to expose some props... but til then, YAGNI
*/

export type IconVariant = 'default' | 'outline' | 'filled' | 'inverse';

export type IconComponentProps = { slot?: 'end' | 'start' | 'icon-only', size?: Size, color?: Color, variant?: IconVariant, rotationDegrees?: number, strokeWidth?: number };

export type IIconComponent = React.FC<IconComponentProps>;

export type IIconComponentWithRaw = IIconComponent & {
    raw: string;
    rawOutline?: string;
    rawFilled?: string;
}

/*
 * Why are we using a ref to set the font size?
 * - The IonIcon component has a size property, but it only accepts "small" (18px) and "large" (32px), not medium, so we don't want to use that.
 * - We can set {fontSize: 24} in props.style, but inside an IonItem this has no effect, because ionic overrides with "font-size:32px!important".
 * - We should be able to set {fontSize: '24px!important'}, but the IonIcon component seems to ignore any fontSize that is not numeric.
 * - Hence the last resort of setting directly on the dom element using a ref. Not ideal, but it works.
*/
export const fontSizeSetter = (size?: Size) => (icon: HTMLIonIconElement) => {
    if (size && icon) {
        const fontSize = (size === 'large' ? 32 : size === 'medium' ? 24 : 16);
        icon.style.setProperty('font-size', `${fontSize}px`, 'important');
    }
};

const createIconComponentWithRaw = (rawIcon: string, rawIconOutline?: string, rawIconFilled?: string) => {
    const IconComponent: IIconComponentWithRaw = ({ slot, size, color, variant = 'default', rotationDegrees = 0, strokeWidth}: IconComponentProps) => {
        const icon = { default: rawIcon, outline: rawIconOutline, filled: rawIconFilled, inverse: undefined }[variant];
        if (!icon) {
            throw new Error(`Icon variant "${variant}" not supported!`);
        }
        return (
            <IonIcon
                icon={icon}
                slot={slot || ''}
                color={color}
                style={{
                    transform: rotationDegrees ? `rotate(${rotationDegrees}deg)` : undefined,
                    '--ionicon-stroke-width': strokeWidth ? `${strokeWidth}px` : undefined
                }}
                ref={fontSizeSetter(size)}
            />
        );
    };
    IconComponent.raw = rawIcon;
    IconComponent.rawOutline = rawIconOutline;
    IconComponent.rawFilled = rawIconFilled;
    return IconComponent;
};

// icon names must correspond to svg files placed in public/assets/icon folder
const createIconComponentWithRawFromSvg = (defaultIconName: string, outlineIconName?: string, filledIconName?: string, inverseIconName?: string) => {
    const buildPath = (svgName: string) => `./assets/icon/${svgName}.svg`;
    const IconComponent: IIconComponentWithRaw = ({ slot,  size, color, variant = 'default', rotationDegrees = 0}) => {
        const name = { default: defaultIconName, outline: outlineIconName, filled: filledIconName, inverse: inverseIconName }[variant];
        if (!name) {
            throw new Error(`Icon variant "${variant}" not supported!`);
        }
        return (
            <IonIcon
                src={buildPath(name)}
                slot={slot || ''}
                color={color}
                style={rotationDegrees ? {transform: `rotate(${rotationDegrees}deg)`} : undefined}
                ref={fontSizeSetter(size)}
            />
        );
    };
    IconComponent.raw = buildPath(defaultIconName);
    IconComponent.rawOutline = outlineIconName ? buildPath(outlineIconName) : undefined;
    IconComponent.rawFilled = filledIconName ? buildPath(filledIconName) : undefined;
    return IconComponent;
};

// duplicating the 'outline' variant even though the defaults for most are already outline
export const MapIcon = createIconComponentWithRaw(mapSharp, mapOutline);
export const PlaneIcon = createIconComponentWithRaw(paperPlaneOutline, paperPlaneOutline);
export const ChatIcon = createIconComponentWithRaw(chatbubbleOutline);
export const PersonIcon = createIconComponentWithRaw(personOutline);
export const InsuranceIcon = createIconComponentWithRaw(shieldCheckmarkOutline, shieldCheckmarkOutline);
export const ShareIcon = createIconComponentWithRaw(shareSocialOutline, shareSocialOutline);
export const CalendarIcon = createIconComponentWithRaw(calendarClearOutline, calendarClearOutline);
export const LightbulbIcon = createIconComponentWithRaw(bulbOutline);
export const LogOutIcon = createIconComponentWithRaw(logOutOutline);
export const DocumentIcon = createIconComponentWithRaw(documentTextOutline, documentTextOutline);
export const ListIcon = createIconComponentWithRaw(list, listOutline);
export const PartlySunnyIcon = createIconComponentWithRaw(partlySunnyOutline, partlySunnyOutline);
export const SettingsIcon = createIconComponentWithRaw(settingsOutline, settingsOutline);

export const EditIcon = createIconComponentWithRaw(pencil);
export const TimeIcon = createIconComponentWithRaw(timeOutline);
export const InfoIcon = createIconComponentWithRaw(informationCircleOutline);
export const MissionLocationIcon = createIconComponentWithRaw(locationOutline);

export const AirspaceGoodIcon = createIconComponentWithRaw(checkmark, checkmarkCircleOutline, checkmarkCircle);
export const AirspaceLAANCIcon = createIconComponentWithRaw(alert, alertCircleOutline, alertCircle);
export const AirspaceNotLAANCIcon = createIconComponentWithRaw(close, closeCircleOutline, closeCircle);

export const AdvisoryBlockIcon = createIconComponentWithRaw(close, closeCircleOutline, closeCircle);
export const AdvisoryAttentionIcon = createIconComponentWithRaw(alert, alertCircleOutline, alertCircle);
export const AdvisoryInfoIcon = InfoIcon;
export const AdvisoryCompleteIcon = createIconComponentWithRaw(checkmark, checkmarkCircleOutline, checkmarkCircle);
export const AdvisoryDoneIcon = createIconComponentWithRaw(checkmarkDone, checkmarkDoneCircleOutline, checkmarkDoneCircle);

export const MenuIcon = createIconComponentWithRaw(menuSharp, menuOutline);
export const CloseIcon = createIconComponentWithRaw(close, closeCircleOutline, closeCircle);
export const DownChevronIcon = createIconComponentWithRaw(chevronDownOutline);
export const UpChevronIcon = createIconComponentWithRaw(chevronUpOutline);
export const AddressDirectionsIcon = createIconComponentWithRawFromSvg('directions');
export const BackIcon = createIconComponentWithRaw(chevronBackOutline);
export const ForwardIcon = createIconComponentWithRaw(chevronForward);
export const CancelIcon = createIconComponentWithRaw(closeCircleOutline);
export const DeleteIcon = createIconComponentWithRaw(trashOutline);
export const RestoreIcon = createIconComponentWithRaw(pulseOutline);
export const ActionsIcon = createIconComponentWithRaw(ellipsisHorizontal);

export const LocateMeIcon = createIconComponentWithRaw(locateOutline);

export const InternalActionIcon = createIconComponentWithRaw(constructOutline);

export const CheckmarkIcon = createIconComponentWithRaw(checkmark);
export const DoneIcon = createIconComponentWithRaw(checkmarkSharp, checkmarkCircleOutline, checkmarkCircleSharp);

export const LayersIcon = createIconComponentWithRaw(layersOutline);
export const WindAltitudeIcon = createIconComponentWithRawFromSvg('wind-altitude');

export const RaindropIcon = createIconComponentWithRawFromSvg('precipitation-rain', undefined, 'precipitation-rain-circle', 'precipitation-rain-circle-inverse');
export const SnowflakeIcon = createIconComponentWithRawFromSvg('precipitation-snow', undefined, 'precipitation-snow-circle', 'precipitation-snow-circle-inverse');
export const RainAmountIcon = createIconComponentWithRawFromSvg('precip-amount-rain-circle', undefined, 'precip-amount-rain-circle', 'precip-amount-rain-circle-inverse');
export const SnowAmountIcon = createIconComponentWithRawFromSvg('precip-amount-snow-circle', undefined, 'precip-amount-snow-circle', 'precip-amount-snow-circle-inverse');
export const TemperatureIcon = createIconComponentWithRawFromSvg('temperature-circle', undefined, 'temperature-circle', 'temperature-circle-inverse');
export const CloudsIcon = createIconComponentWithRawFromSvg('clouds-circle', undefined, 'clouds-circle', 'clouds-circle-inverse');
export const HumidityIcon = createIconComponentWithRawFromSvg('dewpoint-circle', undefined, 'dewpoint-circle', 'dewpoint-circle-inverse');
export const SunriseIcon = createIconComponentWithRawFromSvg('sunrise-circle', undefined, 'sunrise-circle', 'sunrise-circle-inverse');
export const SunsetIcon = createIconComponentWithRawFromSvg('sunset-circle', undefined, 'sunset-circle', 'sunset-circle-inverse');
export const VisibilityIcon = createIconComponentWithRawFromSvg('visibility-circle', undefined, 'visibility-circle', 'visibility-circle-inverse');
export const WindDirectionIcon = createIconComponentWithRawFromSvg('wind-direction', undefined, 'wind-direction-circle', 'wind-direction-circle-inverse');
export const WindGustsIcon = createIconComponentWithRawFromSvg('wind-gusts-circle', undefined, 'wind-gusts-circle', 'wind-gusts-circle-inverse');

export const ShowPasswordIcon = createIconComponentWithRaw(eyeOutline);
export const HidePasswordIcon = createIconComponentWithRaw(eyeOffOutline);

export const AddIcon = createIconComponentWithRaw(addOutline);
export const RemoveIcon = createIconComponentWithRaw(removeOutline);
export const NotificationIcon = createIconComponentWithRaw(notificationsOutline, notificationsCircleOutline);
export const UpArrowIcon = createIconComponentWithRaw(arrowUpOutline);

export const CircleIcon = createIconComponentWithRaw(ellipseOutline);
export const WarningIcon = createIconComponentWithRaw(warningOutline, warningOutline, warning);

export const EmailIcon = createIconComponentWithRaw(mailOutline, mailOutline);
export const FacebookIcon = createIconComponentWithRawFromSvg('facebook-logo', undefined, 'facebook-logo');
export const GoogleIcon = createIconComponentWithRawFromSvg('google-logo', undefined, 'google-logo');
export const AppleIcon = createIconComponentWithRaw(logoApple, undefined, logoApple);

export const CopyIcon = createIconComponentWithRaw(copyOutline, copyOutline, copy);

export const UmbrellaIcon = createIconComponentWithRaw(umbrella, umbrellaOutline, umbrella);

export const SquareRadiusIcon = createIconComponentWithRawFromSvg('square-mission');
export const CircleRadiusIcon = createIconComponentWithRawFromSvg('circle-mission');

export const PaywallIcon = createIconComponentWithRaw(rocketOutline);

export const WindyMapIcon = createIconComponentWithRawFromSvg('windy-map', undefined, 'windy-map');
