import { useSubscription } from '@meraki-internal/state';
import { ActionMenu } from '../../components/action-menu/ActionMenu';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { AlertPresenter } from '../../app/AlertBinder';
import { CancelIcon, DeleteIcon, RestoreIcon } from '../../components/icons/Icons';
import { EditableMissionState } from '../model/EditableMissionState';
import { tryAndCatch } from '../../support/helpers/tryAndCatch';

// TODO: icons
export const MissionOtherActionSheet = ({ mission }: { mission: EditableMissionState }) => {
    const { showAndLogError } = useInstance(AlertPresenter);

    useSubscription(() => mission);

    const status = mission.getMissionStatus();

    const actions = [
        {
            text: 'Cancel Mission',
            icon: CancelIcon.raw,
            handler: async () => {
                // NOTE: mission.cancelMission is also called by a button on MissionDetails (when "shouldCancel")
                // so you probably want to add login down in the viewModel, or duplicate it
                await tryAndCatch(mission.cancelMission, showAndLogError);
            },
            disabled: status === 'user-canceled'
        },
        {
            text: 'Reactivate Mission',
            icon: RestoreIcon.raw,
            handler: async () => {
                await tryAndCatch(mission.reactivateMission, showAndLogError);
            },
            disabled: status !== 'user-canceled'
        },
        {
            text: 'Delete Mission',
            icon: DeleteIcon.raw,
            handler: async () => {
                await tryAndCatch(mission.deleteMission, showAndLogError);
            },
            disabled: false
        }
    ];

    return (
        <ActionMenu trigger="action-button" isOpen={mission.state.isShowingOtherActions} onClose={() => mission.hideOtherActions()} actions={actions} />
    );
};
