import { State } from '@meraki-internal/state';

export class FatalApplicationErrorState extends State<{error: any}> {

    static singleton = new FatalApplicationErrorState();

    constructor() {
        if (FatalApplicationErrorState.singleton){
            throw new Error('attempted to construct a 2nd instance of FatalApplicationErrorState');
        }
        super({ error: undefined });
    }

    throwError = (error: any) => {
        this.setState({ error });
        throw error;
    };
}
