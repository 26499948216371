import { Chart } from 'chart.js';
import { State } from '@meraki-internal/state';

interface IActiveElementState {
    active?: { x: number, y: number, index: number },
}

export class ChartJSActiveElementObserver extends State<IActiveElementState> {
    private tickAtState  = 0;
    private timeoutId: NodeJS.Timeout | undefined;
    constructor(private chart: Chart){
        super({ active: undefined });
    }
    startObserving = () => {
        this.chart.canvas.addEventListener('mousemove', this.onMouseMove);
        this.chart.canvas.addEventListener('touchmove', this.onMouseMove);
        this.synchronize();
        return this;
    };

    dispose = () => {
        this.chart.canvas.removeEventListener('mousemove', this.onMouseMove);
        this.chart.canvas.removeEventListener('touchmove', this.onMouseMove);

        // TODO: why can't I call super.dispose();
        (this as any).subscriptions = [];
        this.subscribe = () => {
            throw new Error('Cannot subscribe to a disposed State instance');
        };
    };

    private onMouseMove: (this: HTMLCanvasElement, e: MouseEvent | TouchEvent) => any = (e) => {
        this.synchronize();

        // don't cause the page to scroll when moving the mouse in the canvas
        e.preventDefault();
    };

    public synchronize = () => {
        // if we're already waiting, just wait til then...
        // then we know we already updated state less than 100ms ago
        // and we can wait up to 100ms more to get the latest state
        if (this.timeoutId){
            return;
        }

        const elements = this.chart.getActiveElements();
        let newActive = elements.length > 0 ? { index: elements[0].index, x: elements[0].element.x, y: elements[0].element.y }: undefined;

        // in our chart, there is always visually an active point, even when the mouse moves off the canvas,
        // so if chart doesn't have an active point to give us, get it from the underlying data instead
        if (this.state.active && !newActive) {
            const { x, y } = this.chart.getDatasetMeta(0).data[this.state.active.index];
            newActive = { ...this.state.active, x, y };
        }

        const isChanged = JSON.stringify(newActive) !== JSON.stringify(this.state.active);

        if (isChanged){
            this.tickAtState = 0;
            this.setState({ active: newActive });
        } else {
            this.tickAtState++;
        }

        if (this.tickAtState <= 5){
            // we need to check again in a little while for a few reasons
            // - we think chart js factors in "velocity" ending on a different point then where your touch ended
            //      (we have observed this.chart.getActiveElements() can be different than the last touch event, with no other event to know)
            // - depending on the state of chart js, it might be animating, causing x, y to change over time
            //      (eg when loading the data for the first time, or transitioning between days)
            this.timeoutId = setTimeout(() => {
                this.timeoutId = undefined;
                this.synchronize();

                // if we wait less, we will need to increase this.tickAtState <= 5
                // if we wait more, we're notice more lag in the tooltip moving
            }, 100);
        }
    };
}
