import { useInstance } from '@meraki-internal/react-dependency-injection';
import { RoundIconButton } from '../../components/button/RoundIconButton';
import { CircleRadiusIcon, SquareRadiusIcon } from '../../components/icons/Icons';
import { MissionPinAndRadiusOnMapViewModel } from '../mission-pin-and-radius/MissionPinAndRadiusOnMapViewModel';

export const ToggleRadiusShape: React.FC = () => {
    const vm = useInstance(MissionPinAndRadiusOnMapViewModel);

    if (!vm.getRadius() || !vm.getDoesPanMoveRadius()){
        return null;
    }

    const isSquare = Boolean(vm.getRadius()!.properties.isSquare);

    return (
        <RoundIconButton Icon={isSquare ? SquareRadiusIcon : CircleRadiusIcon} onClick={() => vm.toggleSquareOrCircle()} />
    );
};
