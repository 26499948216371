import { IAdvisoryTypeV3 } from '@autopylot-internal/tiles-client/dist/model/advisories/IAdvisoryPropertiesV3';
import { IonText } from '@ionic/react';
import { Color } from '../components/common';
import { AdvisoryAttentionIcon, AdvisoryBlockIcon } from '../components/icons/Icons';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
    advisoryText: {
        fontSize: 12,
        marginTop: 4,
        marginBottom: 0,
        '& p': {
            marginTop: 4,
            marginBottom: 0
        },
        '& ul': {
            marginTop: 4,
            marginBottom: 0,
            paddingLeft: 20
        }
    }
}));

export const SecondaryAdvisory = ({ advisoryType, header, children, GLOBAL_ID, icon, iconColor }: { advisoryType: IAdvisoryTypeV3, header: string, children: any, GLOBAL_ID?: string, icon?: any, iconColor: Color }) => {
    const classes = useStyles();

    const Icon = icon || (advisoryType === 'block' ? AdvisoryBlockIcon : AdvisoryAttentionIcon);

    return (
        <div data-global-id={GLOBAL_ID || 'none'} style={{ padding: 8, display: 'flex', gap: 20, alignItems: 'flex-start', justifyContent: 'space-between', borderTopRightRadius: 4, borderBottomRightRadius: 4 }}>
            <div>
                <h6 data-advisory-title style={{ margin: 0 }}>{header}</h6>
                <IonText color="medium">
                    <div className={classes.advisoryText}>
                        {children}
                    </div>
                </IonText>
            </div>
            <div style={{ marginRight: 4, marginTop: 4 }}>
                <Icon variant="default" size="medium" color={iconColor} />
            </div>
        </div>
    );
};

export const SecondaryWarningAdvisory: React.FC<{ advisoryType : IAdvisoryTypeV3, header: string, children: any, GLOBAL_ID?: string, iconColor?: Color, icon?: any }> = ({ advisoryType, header, children, GLOBAL_ID, iconColor, icon  })  => {
    return (
        <SecondaryAdvisory header={header} advisoryType={advisoryType} GLOBAL_ID={GLOBAL_ID} iconColor={iconColor || (advisoryType === 'block' ? 'danger' : 'advisory-warning')} icon={icon}>
            {children}
        </SecondaryAdvisory>
    );
};

export const SecondaryLAANCAdvisory: React.FC<{ advisoryType : IAdvisoryTypeV3, header: string, children: any, GLOBAL_ID?: string, icon?: any, iconColor?: Color }> = ({ advisoryType, header, children, GLOBAL_ID, icon, iconColor  })  => {
    return (
        <SecondaryAdvisory header={header} advisoryType={advisoryType} GLOBAL_ID={GLOBAL_ID} iconColor={iconColor || (advisoryType === 'block' ? 'danger' : 'advisory-laanc')} icon={icon}>
            {children}
        </SecondaryAdvisory>
    );
};
