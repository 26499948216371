import { StereInsuranceStyles } from 'stere-insurance-sdk/dist/types';

export const styles: StereInsuranceStyles = {
    startScreen: {
        color: '#000043',
        backgroundColor: 'white'
    },
    input: {
        borderColor: '#A8A8A8',
        backgroundColor: 'white',
        color: '#212121',
        active: {
            borderColor: '#5768D1',
        },
        hover: {
            borderColor: '#5768D1',
        },
    },
    primaryCard: {
        backgroundColor: 'white',
        hover: {
            borderColor: '#75757560',
        },
        active: {
            borderColor: '#5768D1',
        },
    },
    secondaryCard: {
        backgroundColor: '#F5F5F5',
        hover: {
            borderColor: '#75757560',
        },
        active: {
            borderColor: '#5768D1',
        },
    },
    icons: {
        fill: '#5768D1',
    },
    stepper: {
        backgroundColor: '#1A1A56',
        step: {
            checkIcon: { fill: 'white' },
            backgroundColor:
                'linear-gradient(167.29deg,rgba(255,255,255,.1) 9.2%,rgba(255,255,255,0) 100.31%)',
            color: 'white',
            active: {
                borderColor: 'white',
            },
        },
        stepConnector: {
            backgroundColor: 'linear-gradient(to bottom, transparent, white)',
        },
    },
    muiTheme: {
        typography: {
            fontFamily: 'Poppins',
        },
        palette: {
            primary: {
                main: '#1A1A56',
            },
            text: {
                primary: '#5768D1',
                secondary: '#212121',
                tertiary: '#757575',
            },
            background: {
                default: 'white',
            },
        },
    },
};
