import { CardHeader } from '../components/card/CardHeader';
import { DrawerLabel } from '../components/drawer/DrawerLabel';
import { CardContent } from '../components/card/CardContent';
import { Card } from '../components/card/Card';
import { EditableMissionState } from '../mission/model/EditableMissionState';
import { WindyMissionMapPreview } from './windy/WindyMissionMapPreview';
import { useState } from 'react';

export const WeatherMapPreviewCard: React.FC<{mission: EditableMissionState}> = ({ mission }) => {

    const [error, setError] = useState();

    const onClick = () => {
        if (!error) {
            mission.pages.weatherMap.go();
        }
    };

    return (
        <div style={{cursor: error ? undefined : 'pointer'}} onClick={onClick} >
            <Card>

                <CardHeader>
                    <DrawerLabel text="Wind and Precipitation" />
                </CardHeader>

                <CardContent>

                    <div style={{margin: -6, marginTop: -2, borderRadius: 6, overflow: 'hidden'}}>
                        <WindyMissionMapPreview mission={mission} error={error} setError={setError} />
                    </div>

                </CardContent>

            </Card>
        </div>
    );
};
