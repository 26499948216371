export const WeatherMetric: React.FC<{Icon: any, iconAngle?: number, heading: string, subheading: string}> = ({Icon, iconAngle = 0, heading, subheading}) => {
    return (
        <div style={{width: 70, margin: '10px 5px', display: 'flex', alignItems: 'center', gap: 4}}>
            <span style={{width: 24, height: 24, transform: `rotate(${iconAngle}deg)`}}>
                <Icon size="medium" variant="filled" />
            </span>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <span style={{whiteSpace: 'nowrap', fontSize: 13, fontWeight: 'bold', letterSpacing: -1}}>{heading}</span>
                <span style={{fontSize: 10, lineHeight: 1}}>{subheading}</span>
            </div>
        </div>
    );
};
