import mapboxgl, { AnyLayer, GeoJSONSource, Layer, VectorSource } from 'mapbox-gl';
import { ErrataState } from './ErrataState';

export class MapboxVectorWithMockCreator {

    private errataFeatureIdsToRemoveFromMVT: string[] = [];

    private buildFilterForNotRemovedByErrata = () => {
        const globalIdsToRemove = this.errataFeatureIdsToRemoveFromMVT;
        if (globalIdsToRemove.length > 0){
            const valueForRemove = false;
            const valueForNotRemove = true;

            // we can only remove, if in the mvt source, not in the errata source
            // return true for
            return ['==', ['match', ['get', 'GLOBAL_ID'], globalIdsToRemove, valueForRemove, valueForNotRemove], valueForNotRemove];
        }
        else {
            return true;
        }
    };

    private setFilterExcludingFeaturesRemovedByErrata = ({ layerId, filter, map }: { layerId: string, filter: any, map: mapboxgl.Map}) => {
        map.setFilter(layerId, [ 'all', this.buildFilterForNotRemovedByErrata(), filter ]);
    };

    public setErrata = ({ errataState, map }: { errataState: ErrataState, map: mapboxgl.Map }) => {
        // update features to remove in appropriate filters
        // ensure they stay filtered out if other filters are set
        this.errataFeatureIdsToRemoveFromMVT = errataState.getRemoveIds();

        // this layer of the abstraction isn't supposed to know these layer names
        // but I would want to move that to meta on the layer when the layer was created
        // which is too much to bite off right now
        for (const baseLayerId of ['layer:airspace:fill', 'layer:airspace:line', 'layer:airspace:uasfm:fill', 'layer:airspace:uasfm:label', 'layer:airspace:uasfm:line:laanc' ]){
            this.setFilterExcludingFeaturesRemovedByErrata({
                layerId: baseLayerId,
                filter:  (map.getLayer(baseLayerId) as Layer).filter,
                map
            });
        }

        // update the source
        (map.getSource('errata:source:airspace') as GeoJSONSource).setData({
            type: 'FeatureCollection',
            features: errataState.getErrataFeatures()
        });

    };

    private addSources = ({ map, id, source }: { map: mapboxgl.Map, id: string, source: Partial<VectorSource> }): { sources: string[], mainSourceId: string } => {
        const sourceId = `source:${id}`;
        map.addSource(sourceId, {
            'type': 'vector',
            promoteId: 'GLOBAL_ID',
            ...source
        });

        // we cannot add features to a vector layer
        // so we are adding a sister geojson layer we can add features to
        map.addSource(`mock:${sourceId}`, {
            'type': 'geojson',
            'data': { type: 'FeatureCollection', features: [] },
            promoteId: 'GLOBAL_ID',
        });

        // we need an errata source so that we can override the features in our vector tiles
        map.addSource(`errata:${sourceId}`, {
            type: 'geojson',
            'data': { type: 'FeatureCollection', features: [] },
            promoteId: 'GLOBAL_ID',
        });

        return {
            sources: [sourceId, `mock:${sourceId}`, `errata:${sourceId}`],
            mainSourceId: sourceId
        };
    };

    private addLayerAndMock = ({ map, id, sourceId, layer }: { map: mapboxgl.Map, id: string, sourceId: string, layer: Partial<AnyLayer> }): { layerIds: string[]} => {
        const layerIds: string[] = [];

        const layerParams = {
            ...layer,
            id: `layer:${id}:${layer.id || layer.type}`,
            source: sourceId,
            'source-layer': 'geojsonLayer',// NOTE: see scripts/generate-uasfm-tiles.js
        } as AnyLayer;
        layerIds.push(layerParams.id);
        map.addLayer(layerParams);

        // a layer cannot have more than once source (to point to our mock source)
        // so we are adding a sister layer that has the same styles
        const mockLayerParams = {
            ...layer,
            id: `mock:layer:${id}:${layer.id || layer.type}`,
            source: `mock:${sourceId}`,
        } as AnyLayer;

        layerIds.push(mockLayerParams.id);
        map.addLayer(mockLayerParams);

        const errataLayerParams = {
            ...layer,
            id: `errata:layer:${id}:${layer.id || layer.type}`,
            source: `errata:${sourceId}`,
        } as AnyLayer;
        layerIds.push(errataLayerParams.id);
        map.addLayer(errataLayerParams);

        return { layerIds };
    };

    create = ({ id, source, layers, map }: { map: mapboxgl.Map, id: string, source: Partial<VectorSource>, layers: Partial<AnyLayer>[] }): { sources: string[], layers: string[] } => {
        const { sources, mainSourceId } = this.addSources({ map, id, source });
        let layerIds: string[] = [];

        for (const layer of layers) {
            const result = this.addLayerAndMock({ map, id, sourceId: mainSourceId, layer });
            layerIds = [...layerIds, ...result.layerIds];
        }

        return { sources, layers: layerIds };
    };

    /**
     * Most of the app reasons about a single layer eg airspace:fill, but technically that requires 3 layers (an additional
     * one for mock data, used by tests and screenshots), and one for errata.
     *
     * This method allows us to set a filter on all those layers while only reasoning about the one layer.
     */
    setFilter = ({ map, layer, filter }: { layer: string, map: mapboxgl.Map, filter: any[] | boolean }): void => {
        for (const layerId of this.getLayerIds(layer)){
            if (layerId === layer){
                this.setFilterExcludingFeaturesRemovedByErrata({ layerId, map, filter });
            } else {
                map.setFilter(layerId, filter);
            }
        }
    };

    /**
     * Most of the app reasons about a single layer eg airspace:fill, but technically that requires 3 layers (an additional
     * one for mock data, used by tests and screenshots), and one for errata.
     *
     * This method allows a caller to get all those layers without needing to know how.
     *
     */
    getLayerIds = (baseLayerId: string): string[] => {
        return [baseLayerId, `mock:${baseLayerId}`, `errata:${baseLayerId}`];
    };
}
