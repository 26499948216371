import { PageContent } from './PageContent';
import { State } from '@meraki-internal/state';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useEffect } from 'react';
import { Color } from '../common';

export interface PageStatePayload {
    title?: string;
    hideMenu?: boolean;
    noScroll?: boolean;
    onBack?: () => void;
    backLabel?: string;
    onAction?: () => void;
    actionLabel?: string;
    onClose?: () => void;
    color?: Color;
    fullWidth?: boolean;
}

export class PageState extends State<PageStatePayload> {
    constructor() {
        super({ hideMenu: true });
    }
}

/**
 * This Page component wraps the content of every page, with props providing the text / callbacks for
 * the page header. It does not render anything, but updates PageState, which is used by GlobalPage.
 * GlobalPage is rendered once in AutoPylotApp (outside the router) to reduce flicker on page transitions.
 **/
export const Page: React.FC<PageStatePayload> = ({ title, hideMenu, onBack, backLabel, onAction, actionLabel, onClose, noScroll, color, fullWidth, children }) => {
    const pageState = useInstance(PageState);

    useEffect(() => {
        pageState.setState({ title, hideMenu, onBack, backLabel, onAction, actionLabel, onClose, fullWidth });
    }, [pageState, onBack, backLabel, onAction, actionLabel, onClose, title, hideMenu, fullWidth]);

    return (
        <PageContent color={color} noScroll={noScroll} fullWidth={fullWidth}>
            {children}
        </PageContent>
    );
};
