import { ShowMap } from './components/ShowMap';
import { Drawer } from '../components/drawer/Drawer';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useEffect } from 'react';
import { ViewMissionOnMapDrawer } from './components/ViewMissionOnMapDrawer';
import { HistoryViewModel } from '../app/HistoryViewModel';
import { Page } from '../components/page/Page';
import { useEditableMissionState } from '../missions/model/useEditableMissionState';
import { usePageView } from '../support/tracking/usePageView';
import { TrackingService } from '../support/tracking/TrackingService';
import { ToggleLayersSheet } from './toggle-layers/ToggleLayersSheet';

export const MissionMapPage: React.FC<{ missionId: string, isEditingLocation?: boolean, initialDrawerHeight?: number }> = ({ missionId, initialDrawerHeight = 400 }) => {
    const tracker = useInstance(TrackingService);

    const historyVM = useInstance(HistoryViewModel);

    // TODO: likely worth refactoring to the router so that both screenshot and router use the same method (prop)
    const searchParams = historyVM.getCurrentSearchParams();
    const hasEditTimesQueryParam = searchParams.has('editTimes');

    usePageView('Mission Map', () => ({ source: searchParams.get('source') || undefined }));

    const missionVM = useEditableMissionState(missionId);

    useEffect(() => {
        if (missionVM) {

            missionVM.showMissionLocationOnMap();
        };
    }, [missionVM]);

    const onResize = ({ oldHeight, newHeight }: { oldHeight: number, newHeight: number }) => {
        if (newHeight > oldHeight) {
            tracker.track('Flight Checklist Expanded', () => ({ oldHeight, newHeight }));
        }
    };

    const onScroll = () => {
        tracker.track('Flight Checklist Scrolled');
    };

    return (
        <Page onBack={() => historyVM.back(missionVM?.pages.details.path())} noScroll fullWidth>
            <ShowMap />
            {missionVM && (
                <Drawer minHeight={205} initialHeight={initialDrawerHeight} onResize={onResize} onScroll={onScroll}>
                    <ViewMissionOnMapDrawer mission={missionVM} isEditingTimes={hasEditTimesQueryParam} />
                </Drawer>
            )}
            <ToggleLayersSheet />
        </Page>
    );
};
