import { useBreakpoints } from '../../support/hooks/useBreakpoints';
import { Card } from './Card';

export const WrapWithCardOnLargerScreen: React.FC = ({ children }) => {
    const { isLargerScreen } = useBreakpoints();

    if (isLargerScreen) {
        return <Card padded>{children}</Card>;
    } else {
        return <div>{children}</div>;
    }
};
