
import { Redirect, Route, Switch } from 'react-router-dom';
import { IonReactHashRouter } from '@ionic/react-router';
import { AppUrlListener } from './AppUrlListener';
import { PushNotificationListener } from '../push-notifications/PushNotificationListener';
import { ComponentLibrary } from '../components/ComponentLibrary';
import { DebugPage } from '../support/debug/DebugPage';
import { ScreensPage } from '../support/screenshots/ScreensPage';
import { MissionDetailsPage } from '../mission/MissionDetailsPage';
import { MissionListPage } from '../missions/MissionListPage';
import { MapPage } from '../map/MapPage';
import { MissionMapPage } from '../map/MissionMapPage';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { HistoryStorage, HistoryViewModel } from './HistoryViewModel';
import { IonRouterOutlet } from '@ionic/react';
import { GlobalPage } from './GlobalPage';
import { InvalidMissionPage } from '../mission/InvalidMissionPage';
import { ProfilePage } from '../profile/ProfilePage';
import { OperatorState } from '../profile/OperatorState';
import { SetupPage } from '../setup/SetupPage';
import { useSubscription } from '@meraki-internal/state';
import { LogsPage } from '../support/debug/LogsPage';
import { ErrorPage } from './ErrorPage';
import { DefaultPage } from './DefaultPage';
import { AutoPylotPrincipal } from '../auth/AutoPylotPrincipal';
import { LoadingPage } from './LoadingPage';
import { MissionWeatherPage } from '../weather/MissionWeatherPage';
import { WeatherMapPage } from '../weather/WeatherMapPage';
import { MissionNotesPage } from '../mission/notes/MissionNotesPage';
import { HourlyWeatherChartPage } from '../weather/chart/HourlyWeatherChartPage';
import { InsuranceLandingPage } from '../insurance/InsuranceLandingPage';
import { InsuranceUnsupportedStatePage } from '../insurance/InsuranceUnsupportedStatePage';
import { InvitePage } from '../invite/InvitePage';
import { CalendarPage } from '../calendar/CalendarPage';
import { MissionChecklistPage } from '../mission/checklist/MissionChecklistPage';
import { DiscoverPage } from '../discover/DiscoverPage';
import { SavedChecklistsPage } from '../checklists/SavedChecklistsPage';
import { SavedChecklistPage } from '../checklists/SavedChecklistPage';
import { CopySavedChecklistsPage } from '../checklists/CopySavedChecklistsPage';
import { useEffect } from 'react';
import { LiveUpdatesListener } from '../support/live-updates/LiveUpdatesListener';
import { MissionAuthorizationDetailsPage } from '../mission/MissionAuthorizationDetailsPage';
import { RenderChildrenWhenMapsLoaded } from '../map/RenderChildrenWhenMapsLoaded';
import { FAAStatusAlerter } from './FAAStatusAlerter';
import { SettingsPage } from '../menu/SettingsPage';
import { AppStartTimings } from '../support/debug/AppStartTimings';
import { Logger } from '../support/debug/Logger';
import { DismissSplashScreen } from './DismissSplashScreen';
import { DeviceStatusBar } from '../support/DeviceStatusBar';
import { LiveUpdatesManager } from '../support/live-updates/LiveUpdatesManager';
import { AppVersionUpdateAlerter } from './app-updates/AppVersionUpdateAlerter';
import { InsuranceQuotePage } from '../insurance/InsuranceQuotePage';
import { InsuranceDetailsPage } from '../insurance/InsuranceDetailsPage';
import { useIsNative } from '../support/hooks/useIsNative';
import { UnsupportedPage, isUnsupported } from './UnsupportedPage';
import { AuthErrorAlerter } from '../auth/AuthErrorAlerter';
import { BindMapViewModel } from '../map/components/BindMapViewModel';
import { BindWindyMapViewModel } from '../weather/windy/BindWindyMapViewModel';
import { RevenueCatDebugPage } from '../paywall/revenue-cat/RevenueCatDebugPage';
import { PaywallDialog } from '../paywall/PaywallDialog';
import { AnnouncementDialog } from '../components/dialog/AnnouncementDialog';
import { WindyMapModal } from '../weather/windy/WindyMapModal';

const isScreenshotting = window.location.search.startsWith('?screenshots');

export const AppRouter = () => {
    const historyStorage = useInstance(HistoryStorage);
    const timings = useInstance(AppStartTimings);
    const logger = useInstance(Logger);
    const statusBar = useInstance(DeviceStatusBar);

    // <Route> elements must be direct children of <Switch>, so use
    // boolean conditional around each one instead of <IfNative>
    const isNative = useIsNative();

    const { isAdmin } = useInstance(AutoPylotPrincipal);

    const historyVM = useInstance(HistoryViewModel);

    const operatorState = useInstance(OperatorState);
    useSubscription(() => operatorState);

    // if app start path is in local storage, then app is restarting
    // (presumably after installing an update) and we need to navigate
    useEffect(() => {
        timings.add('<AppRouter />');
        logger.info('app start timings', timings.getAll());

        const startPath = historyStorage.appStartPath.get();
        if (startPath) {
            historyStorage.appStartPath.remove();
            historyVM.replace(startPath);
        }
    }, [historyStorage, historyVM, timings, logger]);

    const needsSetup = operatorState.needsSetup() && !isScreenshotting;

    if (isUnsupported()) {
        return <UnsupportedPage />;
    }

    return (
        <IonReactHashRouter history={historyVM.getRawHistory()}>
            <LiveUpdatesListener />
            <AppUrlListener />
            <PushNotificationListener />

            <AuthErrorAlerter />

            <GlobalPage>
                <IonRouterOutlet key={needsSetup ? 'setup' : 'app'} id="main-outlet">
                    <>
                        {needsSetup &&
                            <DismissSplashScreen statusBar={statusBar}>
                                <SetupPage />
                            </DismissSplashScreen>
                        }
                        {!needsSetup && (<>
                            <BindMapViewModel />
                            <BindWindyMapViewModel />

                            <PaywallDialog />
                            <AnnouncementDialog />

                            <WindyMapModal />

                            <RenderChildrenWhenMapsLoaded>
                                <DismissSplashScreen statusBar={statusBar}>

                                    <FAAStatusAlerter />
                                    <AppVersionUpdateAlerter />
                                    {/* // NOTE: IonRouterOutlet route selection is very confusing and so we are using Switch. (see notes in footer of this file). */}
                                    <Switch>

                                        {/* need to support this deprecated route with a redirect for a while,
                                        to handle app restart after live update that replaces it with /map */}
                                        <Route exact path="/missions/new">
                                            <Redirect to="/map" />
                                        </Route>

                                        <Route exact path="/map" render={() => (
                                            <MapPage />
                                        )} />
                                        <Route exact path="/missions/invalid" render={() => (
                                            <InvalidMissionPage />
                                        )} />
                                        <Route exact path="/missions/:missionId" render={(props) => (
                                            <MissionDetailsPage missionId={props.match.params.missionId} />
                                        )} />
                                        <Route exact path="/missions/:missionId/map" render={(props) => (
                                            <MissionMapPage missionId={props.match.params.missionId} />
                                        )} />
                                        <Route exact path="/missions/:missionId/weather" render={(props) => (
                                            <MissionWeatherPage missionId={props.match.params.missionId} />
                                        )} />
                                        <Route exact path="/missions/:missionId/checklist" render={(props) => (
                                            <MissionChecklistPage missionId={props.match.params.missionId} />
                                        )} />
                                        <Route exact path="/missions/:missionId/notes" render={(props) => (
                                            <MissionNotesPage missionId={props.match.params.missionId} />
                                        )} />
                                        <Route exact path="/missions/:missionId/laanc-authorization" render={(props) => (
                                            <MissionAuthorizationDetailsPage missionId={props.match.params.missionId} />
                                        )} />
                                        <Route exact path="/missions/:missionId/weather/map" render={(props) => (
                                            <WeatherMapPage missionId={props.match.params.missionId} />
                                        )} />
                                        <Route exact path="/missions/:missionId/weather/hourly" render={(props) => (
                                            <HourlyWeatherChartPage missionId={props.match.params.missionId} />
                                        )} />
                                        <Route exact path="/missions">
                                            <MissionListPage />
                                        </Route>

                                        <Route exact path="/checklists">
                                            <SavedChecklistsPage />
                                        </Route>
                                        <Route exact path="/checklists/copy">
                                            <CopySavedChecklistsPage />
                                        </Route>
                                        <Route exact path="/checklists/:checklistId" render={(props) => (
                                            <SavedChecklistPage checklistId={props.match.params.checklistId} />
                                        )} />

                                        <Route exact path="/profile">
                                            <ProfilePage />
                                        </Route>
                                        <Route exact path="/settings/profile">
                                            <ProfilePage />
                                        </Route>

                                        <Route exact path="/insurance">
                                            <InsuranceLandingPage />
                                        </Route>
                                        <Route exact path="/insurance/unsupported">
                                            <InsuranceUnsupportedStatePage />
                                        </Route>
                                        <Route exact path="/insurance/quote">
                                            <InsuranceQuotePage />
                                        </Route>
                                        <Route exact path="/insurance/policies/:id" render={(props) => (
                                            <InsuranceDetailsPage id={props.match.params.id} />
                                        )} />

                                        {isNative &&
                                            <Route exact path="/invite">
                                                <InvitePage />
                                            </Route>
                                        }
                                        {isNative &&
                                            <Route exact path="/calendar">
                                                <CalendarPage />
                                            </Route>
                                        }
                                        {isNative &&
                                            <Route exact path="/discover">
                                                <DiscoverPage />
                                            </Route>
                                        }

                                        <Route exact path="/settings">
                                            <SettingsPage />
                                        </Route>
                                        <Route exact path="/error">
                                            <ErrorPage error={new Error('Test error message')} />
                                        </Route>
                                        <Route exact path="/loading">
                                            <LoadingPage />
                                        </Route>
                                        {isAdmin && (
                                            <Route exact path="/debug">
                                                <DebugPage />
                                            </Route>
                                        )}
                                        {isAdmin && (
                                            <Route exact path="/debug-paywall">
                                                <RevenueCatDebugPage />
                                            </Route>
                                        )}
                                        {isAdmin && (
                                            <Route exact path="/logs">
                                                <LogsPage />
                                            </Route>
                                        )}
                                        {isAdmin && (
                                            <Route path="/component-library">
                                                <ComponentLibrary />
                                            </Route>
                                        )}
                                        {isAdmin && (
                                            <Route path="/live-updates-manager">
                                                <LiveUpdatesManager />
                                            </Route>
                                        )}
                                        {isScreenshotting && (
                                            <Route exact path="/screens/:screenId/:permutation" render={(props) => (
                                                <ScreensPage screenId={props.match.params.screenId as any} permutation={props.match.params.permutation} />
                                            )} />
                                        )}
                                        {isScreenshotting && (
                                            <Route exact path="/screens/:screenId" render={(props) => (
                                                <ScreensPage screenId={props.match.params.screenId as any} />
                                            )} />
                                        )}
                                        {isScreenshotting && (
                                            <Route exact path="/screens/" render={(props) => (
                                                <ScreensPage />
                                            )} />
                                        )}
                                        <Route path="/oauth/code">
                                            {/* placeholder to enable LiveUpdatesListener to skip while app reloads */}
                                            {null}
                                        </Route>
                                        <Route exact path="/">
                                            <DefaultPage />
                                        </Route>
                                        <Route>
                                            {/* fallback for all 404s */}
                                            <Redirect to="/" />
                                        </Route>
                                    </Switch>
                                </DismissSplashScreen>
                            </RenderChildrenWhenMapsLoaded>
                        </>)}
                    </>
                </IonRouterOutlet>
            </GlobalPage>
        </IonReactHashRouter >
    );
};

/**
 * What was confusing about routing with IonRouterOutlet
 *      - typically we write our routes like this
 *          - /missions/invalid
 *          - /missions/:missionId
 *          - (none) => redirect
 *     - which achieves 2 things,
 *        - /missions/invalid matches first, so it doesn't hit /missions/:missionId
 *          - if none of them match, we can redirect, or 404
 *     - we get that from react-router <Switch>
 *     - but that is not how IonRouterOutlet works...
 *          - on page load it chooses /missions/invalid
 *          - on hash change it choose /missions/:missionId where missionId is `invalid`
 *          - WTF!
 *     - it also renders in an inconsistent state when changing hash (eg going from mission id 1 to 2, it will say match param is 1, on path /missions/2 ). Likely not an issue, and likely true down in react-router but could cause obscure bugs.
 *     - also, there is no way to have a default / fallback route (eg to redirect to a more useful page, or render a 404 with a link to a more useful page).
 *     - it has no way of doing a default route, and the prescribed way is with a switch
 *     - I then found this thread reinforcing my findings https://forum.ionicframework.com/t/react-router-default-route/199024/6
 */
