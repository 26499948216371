import { useIonToast } from '@ionic/react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { ToastPresenter } from './ToastPresenter';
import './ToastBinder.css';

export const ToastBinder = () => {
    const presenter = useInstance(ToastPresenter);
    const [showToast] = useIonToast();
    presenter._setToastMethods({ showToast });
    return null;
};
