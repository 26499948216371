import { App, AppState } from '@capacitor/app';
import { TileUrlProvider } from '@autopylot-internal/tiles-client';
import { Logger } from '../support/debug/Logger';
import { AlertPresenter } from './AlertBinder';
import { AppInfo } from '../support/AppInfo';
import { HistoryViewModel } from './HistoryViewModel';

export type IAppVersionStatus = 'active' | 'expiring' | 'expired';

export class FAAVersionAlertManager {
    static inject = () => [
        TileUrlProvider,
        AlertPresenter,
        HistoryViewModel,
        AppInfo,
        Logger,
    ];

    constructor(
        private tileUrlProvider: TileUrlProvider,
        private alert: AlertPresenter,
        private history: HistoryViewModel,
        private appInfo: AppInfo,
        private log: Logger,
    ) { }

    private tilesAreStale: boolean = false;
    private faaIsDown: boolean = false;

    isFAADown = () => this.faaIsDown;
    areTilesStale = () => this.tilesAreStale;

    listen = ({ showAlert }: { showAlert: boolean }) => {

        // listen for changes in active-versions.json file
        this.tileUrlProvider.subscribe(() => {
            Promise.resolve().then(async () => {
                const { faaIsDown, tilesAreStale } = await this.tileUrlProvider.getFAAStatus();

                const before = { faaIsDown: this.faaIsDown, tilesAreStale: this.tilesAreStale };
                const after = { faaIsDown, tilesAreStale };
                this.log.info(`checked versions, old: ${JSON.stringify(before)}, new: ${JSON.stringify(after)}`);

                if (showAlert) {
                    if (!this.faaIsDown && faaIsDown) {
                        this.alertFAAIsDown();
                    }
                    else if (!this.tilesAreStale && tilesAreStale) {
                        this.alertTilesAreStale();
                    }
                }

                this.faaIsDown = faaIsDown;
                this.tilesAreStale = tilesAreStale;

            }).catch(err => {
                console.log(err);
                // do nothing, maybe they are offline, etc
            });
        });

        // listen for app coming into foreground
        if (showAlert) {
            App.addListener('appStateChange', ({ isActive }: AppState) => {
                if (isActive) {
                    if (this.faaIsDown) {
                        this.alertFAAIsDown();
                    }
                    else if (this.tilesAreStale) {
                        this.alertTilesAreStale();
                    }
                }
            });
        }

        // listen for user navigation
        if (showAlert) {
            this.history.getRawHistory().listen(async () => {
                if (this.faaIsDown) {
                    this.alertFAAIsDown();
                }
                else if (this.tilesAreStale) {
                    this.alertTilesAreStale();
                }
            });
        }
    };

    private alertTilesAreStale = () => {
        this.alert.showAlert({
            header: 'Mission Creation Not Available',
            // assumptions
            //  - we can use authorizations, even though maybe they are now invalid, b/c that is very unlikely
            //  - we cannot allow creating missions when are tiles are stale (any missions, b/c we don't know uncontrolled)
            //  - we can cancel missions (when our tiles are stale)
            //  - we can show the existing missions, status, authorizations
            message: `We’re experiencing an issue updating FAA Airspace Data, so we cannot guarantee its
                accuracy. Previously approved airspace authorizations remain valid
                unless you are informed otherwise by Autopylot or the FAA. Please check back later.
            `,
            buttons: [ 'OK' ],
            leftAligned: true
        });
    };

    private alertFAAIsDown = () => {
        this.alert.showAlert({
            header: 'LAANC System Outage',
            message: `
                <p>
                    The FAA LAANC System is currently unavailable and unable to process new
                    or modified airspace authorization requests.
                </p>
                <p>
                    Previously approved airspace authorizations (those issued with an FAA reference number)
                    remain valid unless you are informed otherwise by AutoPylot or the FAA. The FAA's DroneZone
                    portal is an alternative source to make new airspace authorization requests.
                    Processing times may vary, and you must receive an authorization approval
                    (issued with an FAA reference number) before you can fly.
                </p>
                <p>
                    Visit <a href="https://faadronezone.faa.gov/">https://faadronezone.faa.gov</a> for additional details.
                </p>
                <p>
                    B4UFLY users may experience inaccurate or incomplete aeronautical information during a LAANC outage and
                    are advised to check or update their data as soon as practical following return to service notification.
                </p>
                <p>
                    Updates about the current LAANC outage including return to service information may be available on the
                    FAA's website or social media channels.
                <p>
            `,
            buttons: [ 'OK' ],
            size: 'large',
            leftAligned: true
        });
    };
}
