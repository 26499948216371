export class ScreenshotHelper {

    waitForElement = async (selector: string, maxWaitMS: number = 10000) => {
        let element = undefined;
        const started = Date.now();
        while (!element) {
            element = document.querySelector(selector);
            if (!element) {
                if(Date.now() - started > maxWaitMS) {
                    throw new Error(`waited ${maxWaitMS} for ${selector}. It still does not exist`);
                }
                else {
                    await new Promise(resolve => setTimeout(resolve, 500));
                }
            }
        }
        return element;
    };
}
