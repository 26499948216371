import { Page } from '../components/page/Page';
import { State, useSubscription } from '@meraki-internal/state';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { HistoryViewModel } from '../app/HistoryViewModel';
import { usePageView } from '../support/tracking/usePageView';
import { useSavedChecklistState } from '../mission/checklist/useSavedChecklistState';
import { Checklist } from './Checklist';
import { ActionMenu } from '../components/action-menu/ActionMenu';
import { SaveChecklistDialog } from './SaveChecklistDialog';
import { AlertPresenter } from '../app/AlertBinder';
import { SavedChecklistsState } from './SavedChecklistsState';
import { TrackingService } from '../support/tracking/TrackingService';

export class SavedChecklistPageState extends State<{isMenuOpen: boolean, isRenaming: boolean, isDuplicating: boolean}> {
    constructor() {
        super({ isMenuOpen: false, isRenaming: false, isDuplicating: false });
    }
    openMenu = () => this.setState({ isMenuOpen: true });
    closeMenu = () => this.setState({ isMenuOpen: false });
    startRenaming = () => this.setState({ isRenaming: true });
    stopRenaming = () => this.setState({ isRenaming: false });
    startDuplicating = () => this.setState({ isDuplicating: true });
    stopDuplicating = () => this.setState({ isDuplicating: false });
}

export const SavedChecklistPage = ({ checklistId }: { checklistId: string }) => {
    usePageView('Saved Checklist');

    const history = useInstance(HistoryViewModel);
    const alert = useInstance(AlertPresenter);
    const tracker = useInstance(TrackingService);

    const checklistsState = useInstance(SavedChecklistsState);
    const checklistState = useSavedChecklistState(checklistId);

    const pageState = useInstance(SavedChecklistPageState);
    useSubscription(() => pageState);

    if (!checklistState) {
        return null;
    }

    const onRenameChecklist = () => {
        pageState.startRenaming();
        pageState.stopDuplicating();
    };

    const onDuplicateChecklist = async () => {
        if (!checklistState.getItemCount()) {
            await alert.showAlertV2({ header: 'No Items to Copy' });
            return;
        }
        pageState.startDuplicating();
        pageState.stopRenaming();
    };

    const onDeleteAllItems = async () => {
        if (!checklistState.getItemCount()) {
            await alert.showAlertV2({ header: 'No Items to Delete' });
            return;
        }
        const response = await alert.confirmDestructiveAction({
            header: 'Delete All Items?',
            message: 'All checklist items will be deleted and cannot be recovered.'
        });
        if (response === 'destroy') {
            try {
                const itemCount = checklistState.getItemCount();
                await checklistState.deleteAllItems();
                tracker.track('Checklist All Items Deleted', () => ({ checklistId, name: checklistState.state.checklist.name, itemCount }));
            } catch (e) {
                alert.showAndLogError(e);
            }
        }
    };

    const onDeleteChecklist = async () => {
        const response = await alert.confirmDestructiveAction({
            header: 'Delete Checklist?',
            message: `${checklistState.getItemCount() > 0 ? 'Your checklist and all items': 'Your checklist'} will be deleted and cannot be recovered.`
        });

        if (response === 'destroy') {
            try {
                await checklistsState.removeChecklist(checklistState.state.checklist);
                tracker.track('Checklist Deleted', () => ({ checklistId, name: checklistState.state.checklist.name }));
                history.back('/checklists');
            } catch (e) {
                alert.showAndLogError(e);
            }
        }
    };

    const onSaveRename = async ({ name }: { name: string }) => {
        try {
            await checklistState.updateName(name);
            tracker.track('Checklist Renamed', () => ({ checklistId, name }));
            pageState.stopRenaming();
        } catch (e) {
            alert.showAndLogError(e);
        }
    };

    const onSaveDuplicate = async ({ name }: { name: string }) => {
        try {
            const checklist = checklistState.getChecklist();
            const saved = await checklistsState.addChecklist({ ...checklist, name });
            tracker.track('Checklist Duplicated', () => ({ checklistId, name }));
            pageState.stopDuplicating();
            history.replace(`/checklists/${saved.checklistId}`);
        } catch (e) {
            alert.showAndLogError(e);
        }
    };

    return (
        <Page title={checklistState.state.checklist.name} onBack={() => history.back('/checklists')} onAction={pageState.openMenu}>

            <Checklist vm={checklistState} />

            <ActionMenu trigger="action-button" isOpen={pageState.state.isMenuOpen} onClose={pageState.closeMenu} actions={[
                { text: 'Rename Checklist', handler: onRenameChecklist },
                { text: 'Duplicate Checklist', handler: onDuplicateChecklist },
                { text: 'Delete All Items', handler: onDeleteAllItems },
                { text: 'Delete Checklist', handler: onDeleteChecklist }
            ]} />

            <SaveChecklistDialog title="Rename Checklist" initialName={checklistState.state.checklist.name} isOpen={pageState.state.isRenaming} onCancel={pageState.stopRenaming} onSave={onSaveRename} />
            <SaveChecklistDialog title="Duplicate Checklist" isOpen={pageState.state.isDuplicating} onCancel={pageState.stopDuplicating} onSave={onSaveDuplicate} />
        </Page>
    );
};
