import { IAggregateLAANCStatus } from '@autopylot-internal/tiles-client';
import { Badge } from '../../components/badge/Badge';
import { Color } from '../../components/common';
import { IMissionStatus } from '../model/EditableMissionState';

const getStatusLabel = ({ missionStatus, laancStatus }: { missionStatus: IMissionStatus, laancStatus?: IAggregateLAANCStatus }) => {
    if (missionStatus === 'active' && laancStatus !== 'authorizing-failed') {
        return 'Upcoming';
    }
    if (missionStatus === 'user-canceled') {
        if (['authorizing-failed'].includes(laancStatus || '')) {
            return 'Canceled (Failed)';
        }
        if (['rescind-acked', 'rescinded', 'invalidated', 'invalidated-acked'].includes(laancStatus || '')) {
            return 'Canceled (per FAA)';
        }
        return 'Canceled (by Pilot)';
    }
    if (laancStatus === 'authorizing-failed') {
        return 'Failed to Authorize';
    }
    if (missionStatus === 'completed') {
        return 'Completed';
    }
    return 'Unknown';
};

const statusColors: {[status in IMissionStatus]: Color} = {
    active: 'primary',
    'user-canceled': 'primary',
    'completed': 'medium'
};

export const MissionStatusBadge = ({ missionStatus, laancStatus }: { missionStatus: IMissionStatus, laancStatus?: IAggregateLAANCStatus }) => {
    return (
        <Badge color={statusColors[missionStatus]}>
            <span data-type="mission-status">{getStatusLabel({ missionStatus, laancStatus })}</span>
        </Badge>
    );
};
