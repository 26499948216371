import { AdvisoryCompleteIcon } from '../../components/icons/Icons';
import { SecondaryAdvisory } from '../SecondaryAdvisory';

export const UncontrolledAirspaceAdvisory = () => {
    return (
        <SecondaryAdvisory header="Uncontrolled Airspace" advisoryType="advise" icon={AdvisoryCompleteIcon} iconColor="success" >
            Your flight is entirely in <strong>Class G</strong> airspace. No active advisories. Airspace authorization is not required. Prior
            to flight, the remote pilot in command must assess local airspace and any flight restrictions.
        </SecondaryAdvisory>
    );
};
