import { ICoordinate } from '@autopylot-internal/tiles-client';
import { getTimezone } from '../support/helpers/format';

export class LocationKeyBuilder {
    // return key to use in dynamo tables including lat, lng, and tz
    // (we include tz in the key so that we store separate weather data for two locations that round to same
    // lat/lng, but are opposite sides of a timezone boundary, and therefore have a different start of day)
    build = (location: ICoordinate): string => {
        const tz = getTimezone(location);
        const { lat, lng } = this.roundLocation(location);
        return `${lat},${lng},${tz}`;
    };
    // round lat/lng to 1dp to improve cache hits (will adjust location by < 3miles in each direction)
    roundLocation = ({ lat, lng }: ICoordinate) => {
        return { lat: this.round(lat, 1), lng: this.round(lng, 1) };
    };

    private round = (value: number, dp: number): number => {
        const divisor = 10 ** dp;
        return Math.round(value * divisor) / divisor;
    };
}
