import { useInstance } from '@meraki-internal/react-dependency-injection';
import moment from 'moment';
import { DateTimePicker } from '../../components/picker/DateTimePicker';
import { DurationPicker } from '../../components/picker/DurationPicker';
import { AlertPresenter } from '../../app/AlertBinder';
import { ICommonMissionState } from '../model/ICommonMissionState';
import { DrawerLabel } from '../../components/drawer/DrawerLabel';
import { Device } from '../../support/Device';
import { ITimeChangeSource } from '../model/NewMissionState';

// Pickers are buttons, but we want to align the text inside the button with its label,
// so we have to offset for the internal padding, which differs between ios/android!
const PickerWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const device = useInstance(Device);

    const iosStyle = { marginTop: -15, marginLeft: -19 };
    const androidStyle = { marginTop: -10, marginLeft: -17 };
    const style = device.mode === 'ios' ? iosStyle : androidStyle;

    return (
        <div style={style}>
            {children}
        </div>
    );
};

export const MissionTimesEditor: React.FC<{mission: ICommonMissionState, source: ITimeChangeSource }>  = ({ mission, source }) => {
    const alert = useInstance(AlertPresenter);

    const onChangeStartTime = async (startTime: string) => {
        try {
            await mission.setStartTime(startTime, source);
        } catch (e: any) {
            e.message = `Failed setting mission start time: ${e.message}`;
            alert.showAndLogError(e);
        }
    };

    const onChangeDuration = async (duration: number) => {
        try{
            await mission.setDurationMinutes(duration, source);
        } catch (err: any){
            err.message = `Failed setting mission duration: ${err.message}`;
            alert.showAndLogError(err);
        }
    };

    const fromDate = moment().toISOString();
    const toDate = moment().add(90, 'd').toISOString();

    return (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 0}}>
            <div>
                <DrawerLabel text="Date and Time" />
                <PickerWrapper>
                    <DateTimePicker fromDate={fromDate} toDate={toDate} dateTime={mission.getStartTime()} location={mission.getLocation()!} onChange={onChangeStartTime} />
                </PickerWrapper>
            </div>
            <div>
                <DrawerLabel text="Duration" />
                <PickerWrapper>
                    <DurationPicker durationMinutes={mission.getDurationMinutes()} onChange={onChangeDuration} />
                </PickerWrapper>
            </div>
        </div>
    );
};
