import { TrackingService } from '../../support/tracking/TrackingService';

export class MissionNotesEventsTracker {
    static inject = () => [TrackingService];
    constructor(private tracking: TrackingService) { }
    track = ({ oldNotesHtml, newNotesHtml }: { oldNotesHtml: string, newNotesHtml: string }) => {
        if (!oldNotesHtml && newNotesHtml) {
            this.tracking.track('Mission Notes Added', () => ({ notesLength: newNotesHtml.length, partialNotes: this.getPartialNotes(newNotesHtml) }));
        } else {
            this.tracking.track('Mission Notes Edited', () => ({
                notesLength: newNotesHtml.length,
                partialNotes: this.getPartialNotes(newNotesHtml),
                oldLength: oldNotesHtml.length,
                oldPartialNotes: this.getPartialNotes(oldNotesHtml)
            }));
        }
    };

    private getPartialNotes = (notesHtml: string) => {
        if (notesHtml.length <= 200) {
            return notesHtml;
        }
        // get first 100 and last 100
        return notesHtml.substring(0, 100) + '  ....  ' + notesHtml.substring(notesHtml.length - 100);
    };
}
