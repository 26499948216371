import { State } from '@meraki-internal/state';
import { DevSettings } from '../../support/DevSettings';
import { IFreeTierLimits, IOfferMeta } from './IOfferMeta';
import { UserFacingError } from '../../support/UserFacingError';
import { AutoPylotPrincipal } from '../../auth/AutoPylotPrincipal';
import { Logger } from '../../support/debug/Logger';
import { StoreConfigs } from '../../app/config/StoreConfigs';
import { Device } from '../../support/Device';
import { RevenueCatSDKModel } from './RevenueCatSDKModel';
import { RevenueCatAPIModel } from './RevenueCatAPIModel';

export const ENTITLEMENTS = {
    PLUS: 'e_plus'
};

const sensibleDefaults: IFreeTierLimits = {
    windyPreviewsPerMonth: 3
};

type IPaymentMode = 'real' | 'bypass' | 'none';

export type IPaymentTerm = 'monthly' | 'annual';

export class RevenueCatModel extends State<Record<string, never>> {
    static inject = () => [
        RevenueCatAPIModel,
        RevenueCatSDKModel,
        AutoPylotPrincipal,
        DevSettings,
        Logger,
        Device
    ];
    constructor(
        private api: RevenueCatAPIModel,
        private sdk: RevenueCatSDKModel,
        private principal: AutoPylotPrincipal,
        private devSettings: DevSettings,
        private logger: Logger,
        private deviceInfo: Device,
    ){
        super({ });
        this.api.subscribe(() => {
            this.setState({});
        });
        this.sdk.subscribe(() => {
            this.setState({});
        });
    }

    getPaymentMode = (): IPaymentMode => {
        const canBypass = this.api.canBypassPayments();
        const useRealPaymentsEvenIfBypass = this.devSettings.useRealPaymentsEvenIfBypass;

        const canReal = this.sdk.canMakeRealPayment();

        // if we prefer real, but real isn't an option
        if (canBypass && useRealPaymentsEvenIfBypass && !canReal){
            return 'bypass';
        }
        if (canBypass && useRealPaymentsEvenIfBypass && canReal){
            return 'real';
        }

        if (canBypass){
            return 'bypass';
        }

        if (canReal){
            return 'real';
        }

        return 'none';
    };

    hasUpgraded = () => {
        return this.api.hasUpgraded();
    };

    isEligibleForTrial = () => {
        if (this.getPaymentMode() === 'real') {
            return this.sdk.isEligibleForTrial();
        } else {
            // assume free trial if not real
            return true;
        }
    };

    getOfferMeta = (): IOfferMeta => {
        return this.api.getOfferMeta();
    };

    getFreeTierLimits = () : IFreeTierLimits => {
        if (this.hasUpgraded()){
            throw new Error('the user has upgraded and has no limits');
        }

        let limits: IFreeTierLimits | undefined = undefined;

        if (this.getPaymentMode() === 'real'){
            limits = this.sdk.getFreeTierLimits();
        } else {
            limits = this.api.getOfferMeta().freeTierLimits;
        }

        if (!limits){
            this.logger.error(`WARN: Revenue Cat is using "sensible defaults" b/c user ${this.principal.userId} was not able to get limits from their offer`);
            limits = sensibleDefaults;
        }

        return limits;
    };

    init = async () => {
        this.logger.info('RevenueCatModel.init-ing');

        await this.api.init();
        await this.sdk.init();
        this.logger.info('RevenueCatModel.init-ed', this.getDiagnostics().diagnostics);
    };

    // the app always shows the purchase page and throws an error if they cannot
    // kept this around for a hint in the debug page for DX
    canUpgrade = () => {
        if (this.hasUpgraded()){
            return false;
        }
        return this.getPaymentMode() !== 'none';
    };

    private guardAgainstCannotUpgrade = () => {
        if (this.getPaymentMode() === 'bypass'){
            return;
        }

        if (this.deviceInfo.platform === 'web'){
            throw new UserFacingError({
                status: 400,
                message: 'web is not yet supported',
                errorCode: 'web-not-supported',
                displayHeader: 'Cannot upgrade on Web',
                displayMessage: 'But you can upgrade using our App',
                links: [
                    {
                        label: 'Get Android App',
                        href: StoreConfigs.android.installURL
                    },
                    {
                        label: 'Get iOS App',
                        href: StoreConfigs.ios.installURL
                    }
                ],
                okLabel: 'Not Yet'
            });
        }

        if (!this.canUpgrade()){
            throw new Error('upgrade was called when .canUpgrade() returns false');
        }
    };

    upgrade = async (term: IPaymentTerm = 'monthly') => {
        this.guardAgainstCannotUpgrade();

        const paymentMode = this.getPaymentMode();
        if (paymentMode === 'real'){
            await this.handleSDKUpgrade(term);
        }
        else if (paymentMode === 'bypass'){
            await this.api.upgrade();
        }
        else {
            throw new Error(`mode ${paymentMode} is not supported`);
        }

        if (!this.hasUpgraded()){
            this.logger.error(`WARN: user ${this.principal.userId} just completed an upgrade in mode ${this.getPaymentMode()} but does not appear to be upgraded`);
        }
    };

    private handleSDKUpgrade = async (term: 'monthly' | 'annual') => {
        await this.sdk.upgrade(term);
        await this.api.fetchEntitlements();
    };

    canRestore = () => {
        if (this.hasUpgraded()){
            return false;
        }
        if (this.deviceInfo.platform === 'web') {
            return false;
        }
        if (this.getPaymentMode() === 'none') {
            return false;
        }
        return true;
    };

    restore = async () => {
        if (!this.canRestore()){
            throw new Error('restore was called when .canRestore() returns false');
        }

        if (this.getPaymentMode() === 'bypass') {
            throw new UserFacingError({
                status: 400,
                message: 'restore not supported',
                errorCode: 'restore-not-supported',
                displayHeader: 'Restore not supported',
                displayMessage: 'Cannot restore in bypass mode'
            });
        }

        await this.sdk.restore();
        await this.api.fetchEntitlements();

        if (!this.hasUpgraded()) {
            throw new UserFacingError({
                status: 400,
                message: 'nothing to restore',
                errorCode: 'no-entitlements-to-restore',
                displayHeader: 'No Prior Purchases',
                displayMessage: 'Sorry, we did not find any prior purchases to restore.'
            });
        }
    };

    canMaybeDowngrade = () => {
        if (!this.hasUpgraded()){
            return false;
        }
        return this.api.canDowngrade();
    };

    /**
     * For internal users that are bypassing payment. This will downgrade.
     *
     * For Android users, this will launch into play store subscriptions. Where this might cancel.
     *
     * If they do, it won't be effective for 3 minutes.
     */
    maybeDowngrade = async () => {
        await this.api.maybeDowngrade();
    };

    getDiagnostics = () => {
        const diagnostics: { [label: string]: any } = {
            userId: this.principal.userId,
            canUpgrade: this.canUpgrade(),
            hasUpgraded: this.hasUpgraded(),
            paymentMode: this.getPaymentMode(),
            deviceInfo: this.deviceInfo,
            freeTierLimits: !this.hasUpgraded() ? this.getFreeTierLimits() : undefined,
            offerMeta: !this.hasUpgraded() ? this.getOfferMeta() : undefined,
            canMaybeDowngrade: this.canMaybeDowngrade()
        };

        return {
            diagnostics,
        };
    };
}
