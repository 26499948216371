import { useEffect, useRef } from 'react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { Logger } from '../../support/debug/Logger';

export class RefreshTimerConfig {
    disableRefresh = false;
}

export const RefreshTimer: React.FC<{getIntervalMs: (elapsedMs: number) => number, handler: () => any}> = ({ getIntervalMs, handler }) => {

    const config = useInstance(RefreshTimerConfig);

    const logger = useInstance(Logger);

    const mountedTimestamp = useRef(Date.now());

    useEffect(() => {
        if (config.disableRefresh){
            return;
        }
        let timeout: NodeJS.Timeout;

        const process = () => {
            const elapsedMs = Date.now() - mountedTimestamp.current;
            const nextDelay = getIntervalMs(elapsedMs);

            // don't invoke the handler immediately (wait for first timeout)
            if (elapsedMs > 1000) {
                logger.info('RefreshTimer', { now: new Date().toISOString(), nextDelay });
                handler();
            }

            timeout = setTimeout(process, nextDelay);
        };

        // kick it off
        process();

        // clear final timeout on unmount
        return () => clearTimeout(timeout);

    // disabled because adding handler to array causes useEffect to fire continuously
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};
