import { IonItem, IonLabel } from '@ionic/react';
import { InternalActionIcon } from '../../components/icons/Icons';
import { EditableMissionState } from '../model/EditableMissionState';
import { useState } from 'react';
import { MockAuthorizationActionSheet } from './MockAuthorizationActionSheet';
import { DevSettings } from '../../support/DevSettings';
import { useInstance } from '@meraki-internal/react-dependency-injection';

export const MockAuthorizationActionSheetButton = ({ mission }: { mission: EditableMissionState }) => {
    const [isShowingActionSheet, setIsShowingActionSheet] = useState(false);
    const devSettings = useInstance(DevSettings) as DevSettings;
    const mockLinks = [
        'simulateRescindAuthorization',
        'invalidateAuthorization',
        'invalidatedUncontrolledToRestricted',
        'invalidatedUncontrolledToLAANC'
    ];
    const missionHasMockLinks = mockLinks.some(key => Object.keys(mission.state.links).includes(key));
    if (!missionHasMockLinks || !devSettings.showMockAuthorizations) {
        return null;
    }
    return (
        <IonItem button detail={false} onClick={() => setIsShowingActionSheet(true)} data-type="mock-authorizations-button">
            <IonLabel>
                <h2>Mock Authorizations</h2>
            </IonLabel>
            <InternalActionIcon slot="end" size="medium" color="medium" />
            <MockAuthorizationActionSheet mission={mission} isOpen={isShowingActionSheet} onClose={() => setIsShowingActionSheet(false)} />
        </IonItem>
    );
};
