import { IonList, IonItem, IonLabel, IonText, isPlatform } from '@ionic/react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { Page } from '../components/page/Page';
import { usePageView } from '../support/tracking/usePageView';
import { HistoryViewModel } from '../app/HistoryViewModel';
import { AuthService } from '../auth/AuthService';
import { useSubscription } from '@meraki-internal/state';
import { Network } from '../support/Network';
import { DeviceStatusBar } from '../support/DeviceStatusBar';
import { AlertPresenter } from '../app/AlertBinder';
import { OperatorState } from '../profile/OperatorState';
import { EnvConfiguration } from '../app/config/EnvConfiguration';
import { AutoPylotPrincipal } from '../auth/AutoPylotPrincipal';
import { DeleteIcon, ForwardIcon, LogOutIcon, PaywallIcon } from '../components/icons/Icons';
import { RevenueCatModel } from '../paywall/revenue-cat/RevenueCatModel';
import { Color } from '../components/common';
import { AutopylotLiveUpdatesService } from '../support/live-updates/AutopylotLiveUpdatesService';
import { ILiveUpdatesService } from '../support/live-updates/ILiveUpdatesService';
import { ReviewLauncher } from '../app/ReviewLauncher';
import { IfNative } from '../support/IfNative';

const SettingsSection: React.FC = ({ children }) => (
    <IonList inset lines="inset">{children}</IonList>
);

const SettingsItem: React.FC<{label: string, onClick: () => void, Icon?: any, color?: Color}> = ({ label, onClick, Icon = ForwardIcon, color }) => (
    <IonItem onClick={onClick} button detail={false}>
        <IonLabel color={color}>{label}</IonLabel>
        <Icon slot="end" color={color || 'medium'} size="medium" />
    </IonItem>
);

export const SettingsPage: React.FC = () => {
    usePageView('Settings');

    const liveUpdatesService = useInstance(AutopylotLiveUpdatesService) as ILiveUpdatesService;
    useSubscription(() => liveUpdatesService);

    const alert = useInstance(AlertPresenter);
    const history = useInstance(HistoryViewModel);
    const statusBar = useInstance(DeviceStatusBar);
    const reviewLauncher = useInstance(ReviewLauncher);

    const config = useInstance(EnvConfiguration);
    const authService = useInstance(AuthService);

    const revenueCat = useInstance(RevenueCatModel);
    useSubscription(() => revenueCat);

    const operatorState = useInstance(OperatorState);
    useSubscription(() => operatorState);
    const isGuest = operatorState.isGuest();

    const principal = useInstance(AutoPylotPrincipal);
    useSubscription(() => principal);

    const network = useInstance(Network);
    useSubscription(() => network);

    const onClickProfile = async () => {
        // guest must sign up before viewing profile
        if (!operatorState.ensureLoggedIn({ from: 'Profile', returnTo: '/settings/profile' })) {
            return;
        }
        history.push('/settings/profile');
    };

    const onClickAppInfo = () => {
        if (!principal.isAdmin) {
            return;
        }
        history.push('/debug');
    };

    const onManageSubscription = async () => {
        await revenueCat.maybeDowngrade();
    };

    const onClickLogout = async () => {
        if (!network.isOnline) {
            // if we know they are offline, don't even try or else they will get stuck with in infinite spinner
            //  and only be partially logged out (logged out of local storage, but still logged into the app / memory,
            //  and still logged in with IDP)
            alert.showAlert({
                header: 'Sorry! Something went wrong',
                message: 'It appears you are currently offline, please try again once your are online.'
            });
        }
        else {
            // on logout, must switch to default style (black status bar text) so it looks right in auth0
            statusBar.setStyle('default');
            await authService.logout();
        }
    };

    const onDeleteAccount = async () => {
        let isUserSure = false;
        const destroyOrCancel = await alert.confirmDestructiveAction({
            header: 'Delete Account?',
            message: 'Your account and all missions will be deleted.'
        });
        isUserSure = destroyOrCancel === 'destroy';
        if (isUserSure){
            await operatorState.deleteAccount();
        }
    };

    return (
        <Page color="light" title="Settings">

            <SettingsSection>
                <SettingsItem onClick={onClickProfile} label="Profile" />
                {revenueCat.hasUpgraded() && revenueCat.canMaybeDowngrade() &&
                    <SettingsItem data-id="manage-subscriptions" onClick={onManageSubscription} label="Manage Subscription" Icon={PaywallIcon} />
                }
                {!isGuest &&
                    <SettingsItem onClick={onClickLogout} label="Sign Out" Icon={LogOutIcon} />
                }
            </SettingsSection>

            <IfNative>
                <SettingsSection>
                    <SettingsItem onClick={reviewLauncher.openAppStorePage} label="Rate AutoPylot" />
                    <SettingsItem onClick={() => history.push('/invite')} label="Invite Friends" />
                    {isPlatform('ios') &&
                        <SettingsItem onClick={() => history.push('/calendar')} label="Connect Calendar" />
                    }
                </SettingsSection>
            </IfNative>

            <SettingsSection>
                <SettingsItem onClick={() => window.open('https://www.autopylot.io/b4ufly-general-disclaimers')} label="B4UFLY General Disclaimers" />
                <SettingsItem onClick={() => window.open('https://www.autopylot.io/terms')} label="AutoPylot Terms and Policies" />
            </SettingsSection>

            {!isGuest &&
                <SettingsSection>
                    <SettingsItem onClick={onDeleteAccount} label="Delete Account" color="danger" Icon={DeleteIcon}  />
                </SettingsSection>
            }

            <div style={{position: 'fixed', left: 0, bottom: 0, padding: 40, width: '100%', textAlign: 'center'}} onClick={onClickAppInfo}>
                <div style={{margin: 10}}>
                    <IonText color="medium">{liveUpdatesService.getActualVersion()} | {config.ENV.toUpperCase()[0]}</IonText>
                </div>
                <div style={{margin: 10}}>
                    <IonText color="medium">© 2022-{new Date().getFullYear()} AutoPylot, Inc</IonText>
                </div>
            </div>

        </Page>
    );
};
