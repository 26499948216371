import mapboxgl from 'mapbox-gl';
import { AttributionActionSheetState } from './AttributionActionSheet';

export class AttributionControl implements mapboxgl.IControl {
    private map: mapboxgl.Map | undefined;
    private container: HTMLElement | undefined;

    static inject = () => [AttributionActionSheetState];
    constructor(private attributionActionSheetState: AttributionActionSheetState) {}

    onAdd(map: mapboxgl.Map): HTMLElement {
        this.map = map;

        const button = document.createElement('button');
        button.id = 'map-attribution-button';
        button.classList.add('mapboxgl-ctrl-icon');
        button.style.width = '20px';
        button.style.height = '20px';
        button.style.borderRadius = '50%';
        button.style.color = '#707070';
        button.style.fontStyle = 'italic';
        button.style.fontWeight = 'bold';
        button.textContent = 'i';

        button.addEventListener('click', async (event: any) => {
            this.attributionActionSheetState.open();
        });

        this.container = document.createElement('div');
        this.container.classList.add('mapboxgl-ctrl');
        this.container.classList.add('mapboxgl-ctrl-group');
        this.container.style.borderRadius = '50%';
        this.container.appendChild(button);

        return this.container;
    }

    onRemove(map: mapboxgl.Map): void {
        if (this.container) {
            this.container.parentNode!.removeChild(this.container);
        }

        this.map = undefined;
    }
}
