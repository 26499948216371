import { useInstance } from '@meraki-internal/react-dependency-injection';
import { RoundIconButton } from '../../components/button/RoundIconButton';
import { AddIcon } from '../../components/icons/Icons';
import { MapViewModel } from '../model/MapViewModel';

export const ZoomInButton: React.FC = () => {

    const mapVM = useInstance(MapViewModel);

    const zoomIn = () => {
        // if we're already at max zoom, setZoom will do nothing
        mapVM.setZoom(mapVM.getZoom() + 1);
    };

    return (
        <RoundIconButton Icon={AddIcon} onClick={zoomIn} />
    );
};
