import { useSubscription } from '@meraki-internal/state';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { IonItem, IonText } from '@ionic/react';
import { ForwardIcon } from '../../components/icons/Icons';
import { RevenueCatModel } from '../../paywall/revenue-cat/RevenueCatModel';
import { PaywallDialogViewModel } from '../../paywall/PaywallDialogViewModel';

export const PreMissionWeatherUnlockButton: React.FC<{returnTo: string}> = ({ returnTo }) => {

    const revenueCat = useInstance(RevenueCatModel);
    useSubscription(() => revenueCat);

    const paywallVM = useInstance(PaywallDialogViewModel);

    if (revenueCat.hasUpgraded()) {
        return null;
    }

    const onClick = async () => {
        await paywallVM.requirePurchase({ triggerSource: 'weather summary', returnTo });
    };

    return (
        <IonItem button detail={false} lines="none" style={{fontSize: 15, '--padding-start': 0, '--padding-end': 0, '--inner-padding-end': 0}} onClick={onClick}>
            <IonText color="medium">See forecasts with a free trial of</IonText>&nbsp;<IonText color="primary"><b>AutoPylot+</b></IonText>
            <ForwardIcon slot="end" size="small" color="medium" />
        </IonItem>
    );

};
