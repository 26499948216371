import { FC, ReactNode } from 'react';
import { IonButton } from '@ionic/react';
import { Badge } from '../badge/Badge';
import { Color } from '../common';
import { LightbulbIcon } from '../icons/Icons';

type INudgeBannerProps = {
    message: string | ReactNode;
    excludeIcon?: boolean;
    color?: Color;
    buttonLabel?: string;
    buttonClickHandler?: () => void;
    'data-type'?: string;
};

export const NudgeBanner: FC<INudgeBannerProps> = ({
    message,
    excludeIcon = false,
    color = 'success',
    buttonLabel,
    buttonClickHandler = () => {},
    'data-type': dataType = 'edit-nudge-badge'
}) => {
    return (
        <div style={{margin: '20px 0'}} data-type={dataType}>
            <Badge color={color} size="small">
                <div style={{display: 'flex', alignItems: 'center'}}>
                    {!excludeIcon && (
                        <div style={{padding: 12}}>
                            <LightbulbIcon color={color} size="large" />
                        </div>
                    )}
                    <div style={{flexGrow: 1, padding: 12}}>{message}</div>
                    {buttonLabel && buttonClickHandler && (
                        <div style={{padding: 10}}>
                            <IonButton fill="clear" onClick={buttonClickHandler}>{buttonLabel}</IonButton>
                        </div>
                    )}
                </div>
            </Badge>
        </div>
    );
};
