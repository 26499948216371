/* eslint-disable jsx-a11y/anchor-is-valid */
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { State, useSubscription } from '@meraki-internal/state';
import { Page } from '../components/page/Page';
import { OperatorState } from './OperatorState';
import { ActionMenu, IActionMenuAction } from '../components/action-menu/ActionMenu';
import { EditIcon } from '../components/icons/Icons';
import { AlertPresenter } from '../app/AlertBinder';
import { HistoryViewModel } from '../app/HistoryViewModel';
import { Field, FieldList } from '../components/field/Field';
import { TextInput } from '../components/field/TextInput';
import { DateInput } from '../components/field/DateInput';
import { SegmentButton, SegmentInput } from '../components/field/SegmentInput';
import { ICertificationType, IOperatorProfile } from './IOperator';
import { useEffect, useRef } from 'react';
import { TrackingService } from '../support/tracking/TrackingService';
import { usePageView } from '../support/tracking/usePageView';
import { WrapWithCardOnLargerScreen } from '../components/card/WrapWithCardOnLargerScreen';

// this is complicated so don't want to risk typos repeating it in the html
// copied from faa-schema.ts in faa-api and also used in MissionOperatorPage.tsx
export const FAA_NAME_PATTERN = '^(\\p{Script=Latin}|\\s|\\p{Punctuation}|\\d)+$';

export class ProfilePageState extends State<{ values: IOperatorProfile, isMenuOpen: boolean, isEditing: boolean }> {
    static inject = () => [OperatorState, TrackingService];
    constructor(private operatorState: OperatorState, private tracker: TrackingService) {
        super({ values: operatorState.state, isMenuOpen: false, isEditing: !operatorState.hasFullContactInfo() });
        operatorState.subscribe(() => {
            this.setState({ values: operatorState.state });
        });
    }
    private getChanges = () => {
        const changes: any = {};
        for (const k of Object.keys(this.state.values) as Array<keyof IOperatorProfile>) {
            if (this.operatorState.state[k] !== this.state.values[k]) {
                changes[k] = this.state.values[k];
            }
        }
        return changes;
    };
    updateValues = (updates: Partial<IOperatorProfile>) => {
        this.setState({ values: {
            ...this.state.values,
            ...updates
        }});
    };
    save = async () => {
        const changes = this.getChanges();
        await this.operatorState.save(this.state.values);
        this.tracker.track('Profile Edited', () => ({ changes }));
        this.setState({ isEditing: false });
    };
    canReset = () => {
        return this.state.isEditing && this.operatorState.hasFullContactInfo();
    };
    reset = () => {
        this.setState({ values: this.operatorState.state, isEditing: !this.operatorState.hasFullContactInfo() });
    };
    getEmail = () => this.operatorState.getEmail();
}

export const ProfilePage: React.FC = () => {
    usePageView('Profile');

    const alert = useInstance(AlertPresenter);

    const pageStateInstance = useInstance(ProfilePageState);
    const pageState = pageStateInstance.state;
    useSubscription(() => pageStateInstance);

    const history = useInstance(HistoryViewModel);
    const fromSettings = (history.getCurrentLocation().pathname.startsWith('/settings'));
    const canGoBack = (fromSettings || pageState.isEditing);

    const formRef = useRef<HTMLFormElement>(null);

    // if we leave the page while editing and come back,
    // we expect it to be in read-only mode again
    useEffect(() => {
        pageStateInstance.reset();
    }, [pageStateInstance]);

    if (!pageState?.values) {
        return null;
    }

    const onChangeCertificationType = (value: string) => {
        pageStateInstance.updateValues({
            certificationType: value as ICertificationType,
            certificationNumber: undefined,
            certificationDate: undefined
        });
    };

    const onChangeHandler = (key: string) => {
        return (value?: string) => {
            pageStateInstance.updateValues({ [key]: value });
        };
    };

    const submit = async () => {
        try {
            if (formRef.current?.reportValidity()) {
                await pageStateInstance.save();
            }
        } catch(e: any) {
            alert.showAndLogError(e);
        }
    };

    const isLicensed = (pageState.values?.certificationType === 'part-107');

    const onBack = () => {
        if (pageStateInstance.canReset()) {
            pageStateInstance.reset();
        } else {
            history.back('/settings');
        }
    };

    const onAction = async () => {
        if (pageState.isEditing) {
            await submit();
        } else {
            pageStateInstance.setState({ isMenuOpen: true });
        }
    };

    const profileActions: (IActionMenuAction & { order: number })[] = [
        {
            text: 'Edit Profile',
            icon: EditIcon.raw,
            handler: () => pageStateInstance.setState({ isEditing: true }),
            order: 1,
        }
    ];

    return (
        <Page title="Profile" onBack={canGoBack ? onBack : undefined} backLabel={pageState.isEditing ? 'Cancel' : undefined} onAction={onAction} actionLabel={pageState.isEditing ? 'Save' : undefined} >
            <WrapWithCardOnLargerScreen>
                <form ref={formRef}>
                    <FieldList>
                        <div id="header" style={{ width: '100%', textAlign: 'center' }}>
                            <h2>{pageState.values.firstName}&nbsp;{pageState.values.lastName}</h2>
                            <h5 style={{ marginTop: -10 }}>{pageStateInstance.getEmail()}</h5>
                        </div>

                        <Field dataSet={{type: 'first'}} label="First Name" required readonly={!pageState.isEditing}>
                            <TextInput value={pageState.values.firstName} onChange={onChangeHandler('firstName')} pattern={FAA_NAME_PATTERN} maxlength={60} title="Must be letters, punctuation, and whitespace" />
                        </Field>

                        <Field dataSet={{type: 'last'}} label="Last Name" required readonly={!pageState.isEditing}>
                            <TextInput value={pageState.values.lastName} onChange={onChangeHandler('lastName')} pattern={FAA_NAME_PATTERN} maxlength={60} title="Must be letters, punctuation, and whitespace" />
                        </Field>

                        <Field dataSet={{type: 'phone'}} label="Phone Number" required readonly={!pageState.isEditing}>
                            <TextInput value={pageState.values.phone} onChange={onChangeHandler('phone')} type="tel" inputmode="tel" pattern="[1-9]\d{9}" maxlength={10} title="Must be 10 digits, and cannot start with a zero" />
                        </Field>

                        <Field dataSet={{type: 'cert-type'}} label="Certificate" readonly>
                            {!pageState.isEditing &&
                                <>
                                    <TextInput value={pageState.values.certificationType === 'recreational' ? 'Recreational (TRUST Certificate)' : 'Part 107 (Remote Pilot Certificate)'} />

                                    {!isLicensed &&
                                        <small style={{marginTop: 5, marginBottom: 15}}>
                                            Got your Part 107 Certification? <a href="#" onClick={(e) => { e.preventDefault(); pageStateInstance.setState({ isEditing: true }); }}>Edit Your Profile</a> to add.
                                        </small>
                                    }
                                </>
                            }
                            {pageState.isEditing &&
                                <SegmentInput value={pageState.values.certificationType} onChange={onChangeCertificationType}>
                                    <SegmentButton value="part-107">Part 107<br/><small>(Remote Pilot Certificate)</small></SegmentButton>
                                    <SegmentButton value="recreational">Recreational<br/><small>(TRUST Certificate)</small></SegmentButton>
                                </SegmentInput>
                            }
                        </Field>

                        <Field dataSet={{type: 'cert-number'}} label={pageState.values.certificationType === 'recreational' ? 'Authorization Token' : 'Certificate Number'} required={false} readonly={!pageState.isEditing}>
                            {isLicensed &&
                                <TextInput value={pageState.values.certificationNumber} onChange={onChangeHandler('certificationNumber')} type="number" inputmode="numeric" maxlength={7} pattern="\d{7}" title="Must be 7 digits" />
                            }
                            {!isLicensed &&
                                <TextInput value={pageState.values.certificationNumber} onChange={onChangeHandler('certificationNumber')} maxlength={15} pattern="[A-Z]{4}\d{11}" title="Must be 4 upper case letters followed by 11 digits" autocapitalize="characters" />
                            }
                        </Field>

                        <Field dataSet={{type: 'cert-date'}} label="Issued/Renewed Date" required={false} readonly={!pageState.isEditing}>
                            <DateInput value={pageState.values.certificationDate} onChange={onChangeHandler('certificationDate')} />
                        </Field>
                    </FieldList>
                </form>
            </WrapWithCardOnLargerScreen>

            <ActionMenu trigger="action-button" isOpen={pageState.isMenuOpen} onClose={() => pageStateInstance.setState({ isMenuOpen: false })} actions={profileActions.sort((a, b) => a.order - b.order)} />

        </Page>
    );
};
