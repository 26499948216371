import { AlertPresenter } from '../app/AlertBinder';
import { IntercomNativeEnvProvider } from '../app/intercom/IntercomNativeEnvProvider';
import { Device } from './Device';
import { StorageProvider } from './StorageProvider';
import { AutopylotLiveUpdatesService } from './live-updates/AutopylotLiveUpdatesService';

export type IonicDeployChannel = 'Production' | 'Staging';

export class DevSettingsStorage {
    static inject = () => [StorageProvider];

    constructor(private storage: StorageProvider) {}
    settings = this.storage.getJSONProvider<{ [key: string]: boolean }>('ap:dev-settings.v1', { userNeutral: true, envNeutral: true, storageType: 'localStorage' });
}

export class DevSettings {
    static inject = () => [DevSettingsStorage];

    constructor(private storageV2: DevSettingsStorage) {
        if (this.storageV2.settings.exists()) {
            // TODO: refactor to make this less error prone
            const settings = this.storageV2.settings.get()!;
            this.skipMergingRadiusToOne = settings.skipMergingRadiusToOne || false;
            this.showLAANCAuthDebug = settings.showLAANCAuthDebug || false;
            this.showMockAuthorizations = settings.showMockAuthorizations || false;
            this.showLogsDuringStartup = settings.showLogsDuringStartup || false;
            this.showErrorInfo = settings.showErrorInfo || false;
            this.disableNotRealWarnings = settings.disableNotRealWarnings || false;
            this.allowForceNotification = settings.allowForceNotification || false;
            this.disableToastAutoDismiss = settings.disableToastAutoDismiss || false;
            this.enableFacebookLogin = settings.enableFacebookLogin || false;
            this.showTimingsOnWelcomePage = settings.showTimingsOnWelcomePage || false;
            this.showGPSMeta = settings.showGPSMeta;
            this.reduceLiveUpdatesDelay = settings.reduceLiveUpdatesDelay || false;
            this.alwaysShowStereFrame = settings.alwaysShowStereFrame || false;
            this.switchIntercomEnv = settings.switchIntercomEnv || false;
            this.useStagingDeployChannel = settings.useStagingDeployChannel || false;

            // we should add a comment as to why this is different
            this.useStaging = this.useStagingDeployChannel;

            this.showNearestOperationalAwarenessPosition = settings.showNearestOperationalAwarenessPosition || false;
            this.previewAirports = settings.previewAirports || false;
            this.simulateNative = settings.simulateNative || false;
            this.rapidPaywallNudge = settings.rapidPaywallNudge || false;
            this.enableWebBypassUpgrade = settings.enableWebBypassUpgrade || false;
            this.useRealPaymentsEvenIfBypass = settings.useRealPaymentsEvenIfBypass || false;
        }
    }

    // sequence here determines how the settings appear on the page
    skipMergingRadiusToOne: boolean = false;
    showLAANCAuthDebug: boolean = false;
    showMockAuthorizations: boolean = false;
    showLogsDuringStartup: boolean = false;
    showErrorInfo: boolean = false;
    disableNotRealWarnings: boolean = false;
    allowForceNotification: boolean = false;
    disableToastAutoDismiss: boolean = false;
    enableFacebookLogin: boolean = false;
    showTimingsOnWelcomePage: boolean = false;
    showGPSMeta: boolean = false;
    reduceLiveUpdatesDelay: boolean = false;
    showNearestOperationalAwarenessPosition: boolean = false;
    alwaysShowStereFrame: boolean = false;
    previewAirports: boolean = false;
    simulateNative: boolean = false;
    rapidPaywallNudge: boolean = false;
    enableWebBypassUpgrade: boolean = false;
    useRealPaymentsEvenIfBypass: boolean = false;

    // leave these at the end because they're more heavily used and we're used to them being there
    switchIntercomEnv: boolean = false;
    useStagingDeployChannel: boolean = false;
    useStaging: boolean = false;
}

export class DevSettingsWriter {
    static inject = () => [
        DevSettings,
        IntercomNativeEnvProvider,
        AlertPresenter,
        DevSettingsStorage,
        AutopylotLiveUpdatesService,
        Device
    ];
    constructor(
        private reader: DevSettings,
        private intercomNativeEnvProvider: IntercomNativeEnvProvider,
        private alertPresenter: AlertPresenter,
        private storageV2: DevSettingsStorage,
        private liveUpdates: AutopylotLiveUpdatesService,
        private deviceInfo: Device
    ){ }

    private isNative = () => {
        return this.deviceInfo.platform !== 'web';
    };

    private setIntercomNativeEnv = async () => {
        if (this.isNative()) {
            let useIntercomStaging = this.reader.useStaging;
            if (this.reader.switchIntercomEnv) {
                useIntercomStaging = !useIntercomStaging;
            }
            const intercomEnv = (useIntercomStaging ? 'intercom-staging' : 'intercom-prod');
            await this.intercomNativeEnvProvider.setEnv(intercomEnv);
        }
    };

    setSetting = async (key: string, value: any) => {
        if (key === 'switchIntercomEnv'){
            this.reader.switchIntercomEnv = value;
            this.persistLocalStorage();

            await this.setIntercomNativeEnv();

            // may require a restart, so assume it does
            // eg intercom is configured for intercom-prod, but shouldn't be anymore
            this.forceFullReload();
        }
        else if (key === 'useStaging') {
            this.reader.useStaging = value;
            this.reader.useStagingDeployChannel = this.reader.useStaging;
            this.reader.switchIntercomEnv = false;
            this.persistLocalStorage();

            await this.liveUpdates.requireInstallOnNextAppStart();

            try{
                await this.setIntercomNativeEnv();
                this.forceFullReload();
            } catch (err: any){
                if (err && err.errorCode === 'cannot-switch-intercom-env'){
                    window.location.reload();
                } else {
                    throw err;
                }
            }
        }
        else {
            (this.reader as any)[key] = value;

            this.persistLocalStorage();

            window.location.reload();
        }
    };

    private forceFullReload = () => {
        if (this.isNative()) {
            this.alertPresenter.showAlertV2({ header: 'Restart Manually', message: 'Restart the app for the new intercom env to take effect.', options: [] });
        } else {
            window.location.reload();
        }
    };

    private persistLocalStorage = () => {
        this.storageV2.settings.set({
            skipMergingRadiusToOne: this.reader.skipMergingRadiusToOne,
            showLAANCAuthDebug: this.reader.showLAANCAuthDebug,
            showMockAuthorizations: this.reader.showMockAuthorizations,
            showLogsDuringStartup: this.reader.showLogsDuringStartup,
            showErrorInfo: this.reader.showErrorInfo,
            disableNotRealWarnings: this.reader.disableNotRealWarnings,
            allowForceNotification: this.reader.allowForceNotification,
            disableToastAutoDismiss: this.reader.disableToastAutoDismiss,
            enableFacebookLogin: this.reader.enableFacebookLogin,
            showTimingsOnWelcomePage: this.reader.showTimingsOnWelcomePage,
            showGPSMeta: this.reader.showGPSMeta,
            reduceLiveUpdatesDelay: this.reader.reduceLiveUpdatesDelay,
            alwaysShowStereFrame: this.reader.alwaysShowStereFrame,
            switchIntercomEnv: this.reader.switchIntercomEnv,
            useStagingDeployChannel: this.reader.useStagingDeployChannel,
            showNearestOperationalAwarenessPosition: this.reader.showNearestOperationalAwarenessPosition,
            previewAirports: this.reader.previewAirports,
            simulateNative: this.reader.simulateNative,
            rapidPaywallNudge: this.reader.rapidPaywallNudge,
            enableWebBypassUpgrade: this.reader.enableWebBypassUpgrade,
            useRealPaymentsEvenIfBypass: this.reader.useRealPaymentsEvenIfBypass,
        });
    };

}
