import { IStadiumAdvisoryProperties, ITFRAdvisoryProperties, ITimeOfDayAdvisoryProperties } from '@autopylot-internal/tiles-client';
import { IAdvisoryPropertiesV3 } from '@autopylot-internal/tiles-client';
import { ICompositeStadiumsAdvisoryProperties } from './advisories/StadiumsAdvisory';
import { MissionFeatureCollection } from '@autopylot-internal/tiles-client';
import { ICompositeTFRAdvisoryProperties } from './advisories/CompositeTFRAdvisory';
import { ICompositeMTRAdvisoryProperties } from './advisories/MilitaryTrainingRoutesAdvisory';
import { IMission } from '../mission/model/EditableMissionState';

/**
 * Produces all secondary advisories for the FlightChecklist.
 *
 * As much as possible this is straight through to reduce data layers we have to reason about. Eg NSUFR
 * ends up using the same properties as we got them from the FAA.
 *
 * While AuthorizationSubDivide ensures that the advisories contains all relevant advisories down to the polygons that drive them.
 *
 * This class does the final touches that are more UI centric.
 *  - sort them in the order we want them
 *  - consolidate many into one where we want to (eg stadiums)
 *  - Note AuthorizationSubDivide does handle consolidation of LAANC (since that is core to authorization, not just for presentation)
 *  - filter out advisories that don't make sense (especially how they are worded), eg class G gets filtered out... Some of the airspace
 *    is CLASS G but the wording doesn't work and so we filter it out unless it is effectively all class G
 *
 * This class is also adding some advisories
 *  - concurrent mission
 *  - invalid status
 *  - notam
 *  - tfr
 *  - day night
 *
 * I am on the fence if day / night belongs here... eg maybe AuthorizationSubDivide should short circuit if
 *  at night and operator is not licensed (eg 44809-AA). But for now it is here.
 */
export class AdvisoryProvider {

    getAdviseSourcesToShowWhenBlocked = () => ['scheduled-airspace', 'operational-awareness', 'rec-flyer-fixed-sites'];

    getAdvisories = (missionRadius: MissionFeatureCollection, concurrentMissions: Partial<IMission>[]) => {
        let advisories = missionRadius.getAdvisories();

        advisories = advisories.concat(this.buildCompositeTFRAdvisory(advisories.filter(a => a.source === 'temporary-flight-restrictions') as ITFRAdvisoryProperties[]));

        if (missionRadius.canFly() === 'cannot-fly') {

            let filtered = advisories
                .filter(p => p.advisoryType === 'block' || this.getAdviseSourcesToShowWhenBlocked().includes(p.source))
                .sort(this.sortBlockAdvisories);

            // elminate duplicate laanc inactive advisories
            if (filtered.some(f => f.source === 'airspace') && filtered.some(f => f.source === 'uasfm+airspace+composite')) {
                filtered = filtered.filter(f => f.source !== 'airspace');
            }

            return filtered;

        } else {
            advisories = advisories
                .concat(this.getConcurrentMissionAdvisories(concurrentMissions));

            advisories = advisories
                // filter out stadiums, so we can re-add as a composite
                .filter(a => a.source !== 'stadiums')
                .concat(this.getCompositeStadiumAdvisoryArray(missionRadius));

            // merge MTR advisories
            advisories = advisories
                .filter(a => a.source !== 'military-training-routes')
                .concat(this.getCompositeMTRAdvisoryArray(missionRadius));

            // NOTE: I was on the fence on if the class g filter belongs here or in the merge
            // I decided it belongs here because from a merge perspective, there is some class G (therefore it should be included)
            // it is here in app, that the wording suggests it is entirely class g and therefore we must filter it out
            const isAnyControlled = missionRadius.getAdvisories().some(a => ['uasfm+airspace', 'airspace'].includes(a.source));
            if (isAnyControlled) {
                advisories = advisories.filter(a => a.source !== 'airspace+class-g');
            }

            return advisories
                .concat([
                    { source: 'no-tfr', advisoryType: 'advise' },
                    { source: 'notam', advisoryType: 'advise' }
                ])
                .sort(this.sortAdvisories);
        }
    };

    private getConcurrentMissionAdvisories = (concurrentMissions: Partial<IMission>[]) => {
        const advisories: IAdvisoryPropertiesV3[] = [];

        if (concurrentMissions.length > 0) {
            advisories.push({
                source: 'concurrent-missions',
                advisoryType: 'advise'
            });
        }

        return advisories;
    };

    private getCompositeStadiumAdvisoryArray = (mission: MissionFeatureCollection): ICompositeStadiumsAdvisoryProperties[] => {
        const stadiumAdvisories = mission.getAdvisories().filter(p => p.source === 'stadiums') as IStadiumAdvisoryProperties[];
        if (stadiumAdvisories.length === 0) {
            return [];
        }
        const stadiumNames = stadiumAdvisories.map(s => s.NAME);

        return [{
            advisoryType: 'advise',
            source: 'stadiums+composite',
            stadiumNames
        }];
    };

    buildCompositeMTRAdvisoryArray = (mtrAdvisories: IAdvisoryPropertiesV3[]): ICompositeMTRAdvisoryProperties[] => {
        if (mtrAdvisories.length === 0) {
            return [];
        }
        const routeNumbers = mtrAdvisories
            .map((a: any) => a.IDENT)
            .filter((n, i, a) => a.indexOf(n) === i) // unique
            .sort();

        return [{
            advisoryType: 'advise',
            source: 'military-training-routes+composite',
            routeNumbers
        }];
    };

    private getCompositeMTRAdvisoryArray = (mission: MissionFeatureCollection): ICompositeMTRAdvisoryProperties[] => {
        const mtrAdvisories = mission.getAdvisories().filter(p => p.source === 'military-training-routes');
        return this.buildCompositeMTRAdvisoryArray(mtrAdvisories);
    };

    private buildCompositeTFRAdvisory = (tfrs: ITFRAdvisoryProperties[]): ICompositeTFRAdvisoryProperties[] => {
        if (tfrs.length === 0){
            return [];
        }
        const blockTFRs = tfrs.filter(tfr => tfr.advisoryType === 'block');
        const anyBlock = blockTFRs.length > 0;

        return [{
            source: 'temporary-flight-restrictions+composite',
            advisoryType: anyBlock ? 'block' : 'advise',
            tfrs: anyBlock ? blockTFRs : tfrs
        }];
    };

    private sortAdvisories = (a: IAdvisoryPropertiesV3, b: IAdvisoryPropertiesV3) => {
        const order: string[] = [
            // 'airspace', // no such thing as a non block, airspace advisory

            // group 1, is most directly tied to primary advisory
            // Fly with Caution, or Good to Fly

            'temporary-flight-restrictions+composite',
            'special-use-airspace',
            'airspace-boundary', // DC SFRA
            'military-training-routes+composite',
            'uasfm+airspace+composite', // and E2

            // FAA B4UFly requires these come after laanc
            'national-parks',
            'wilderness-areas',
            'wildlife-refuges',
            'time-of-day--night',

            // faa requires that the laanc advisory come first
            'rec-flyer-fixed-sites',

            // group 2 - an advise that only the operation know, might block your mission
            // or directly supports group 1
            'scheduled-airspace',

            // group 3 - must do something
            'stadiums+composite',

            // had to move per FAA, Desktop, B4uFly testing
            'airspace+class-g',

            // group 4 - may need to do something
            'concurrent-missions',

            // group 5 - FYI
            'time-of-day--day',
            'no-tfr',
            'notam',
            'tfr',
            'operational-awareness',

            // group 7 - never actually shown
            'uasfm+airspace',
            'uasfm+authorizable',
            'stadiums',
            'temporary-flight-restrictions',
            'military-training-routes'
        ];

        const aSource = this.getSortSource(a);
        const bSource = this.getSortSource(b);
        const aIndex = order.indexOf(aSource);
        const bIndex = order.indexOf(bSource);

        return aIndex - bIndex;
    };

    private getSortSource = (advisory: IAdvisoryPropertiesV3): string => {
        if (advisory.source === 'time-of-day'){
            const entirelyDay = (advisory as ITimeOfDayAdvisoryProperties).relevantTimesOfDay.every(r => r.timeOfDay === 'day');
            return `time-of-day--${entirelyDay ? 'day' : 'night'}`;
        } else {
            return advisory.source;
        }
    };

    private sortBlockAdvisories = (a: IAdvisoryPropertiesV3, b: IAdvisoryPropertiesV3) => {
        const order: string[] = [
            'nsufr',
            'dc-frz',
            'airspace-boundary',
            'parttime-nsufr',
            'special-use-airspace', // this is above TFR to be in compliance with B4UFly
            'temporary-flight-restrictions+composite',

            // `airspace` presents as laanc inactive, so it should be at same sort as  uasfm+airspace+composite
            'airspace',
            'uasfm+airspace+composite',

            // `scheduled-airspace` provides more info about the airspace, so it should alway be right after `airspace` and `uasfm+airspace+composite`
            'scheduled-airspace',

            'rec-flyer-fixed-sites',
            'operational-awareness',

            // `time-of-day` should be last, b/c if you're blocked for other reasons, changing to day won't matter
            'time-of-day', // night

            // never shown
            'uasfm+airspace',
            'uasfm+authorizable',
            'temporary-flight-restrictions',

            // no longer block, per FAA / LAANC
            'national-parks',
            'wilderness-areas',
            'wildlife-refuges',
        ];

        return order.indexOf(a.source) - order.indexOf(b.source);
    };
}
