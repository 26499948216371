import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useEffect } from 'react';
import { FAAVersionAlertManager } from './FAAVersionAlertManager';

/**
 * Non-rendering component that starts listeners for top-level app alerts
 * (for FAA down, stale tiles). This was split out
 * from AppContainer so that it doesn't attempt to alert until after
 * AlertBinder has rendered.
 */
export const FAAStatusAlerter: React.FC = () => {

    const versionAlertManager = useInstance(FAAVersionAlertManager);

    useEffect(() => {
        versionAlertManager.listen({ showAlert: true });
    }, [versionAlertManager]);

    return null;
};
