import { useBreakpoints } from '../../support/hooks/useBreakpoints';
import { FloatingButtonBar } from '../button/FloatingButtonBar';
import { SIDE_DRAWER_WIDTH } from './Drawer';

export const DrawerButtons: React.FC = ({ children }) => {

    const { isLargerScreen: sideDrawer } = useBreakpoints();

    return (
        <FloatingButtonBar width={sideDrawer ? SIDE_DRAWER_WIDTH : '100%'} horizontalOffset={-16}>
            {children}
        </FloatingButtonBar>
    );
};
