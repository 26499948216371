import { IonButton } from '@ionic/react';
import { Color } from '../common';
import { IIconComponent } from '../icons/Icons';

interface IRoundIconButtonProps{
    'data-id'?: string;
    onClick: ()=>void;
    Icon: IIconComponent;
    backgroundColor?: Color;
    iconColor?: Color;
}

export const RoundIconButton: React.FC<IRoundIconButtonProps> = ({ 'data-id': dataId, onClick, Icon, backgroundColor = 'light', iconColor = 'medium' }) => {
    const buttonSize = 44;
    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative', width: buttonSize + 10, height: buttonSize + 10 }} onClick={onClick}>
            <IonButton color={backgroundColor} data-id={dataId} style={{
                width: buttonSize,
                height: buttonSize,
                '--border-radius': '50%',
                '--padding-start': 0,
                '--padding-end': 0,
            }}>
                <Icon slot="icon-only" color={iconColor} />
            </IonButton>
        </div>
    );
};
