import { PushNotifications } from '@capacitor/push-notifications';
import { Logger } from '../support/debug/Logger';
import { Device } from '../support/Device';
import { LocalNotifications } from '@capacitor/local-notifications';
import { PermissionState } from '@capacitor/core';

export class PushNotificationService {

    static inject = () => [
        Device,
        Logger
    ];
    constructor(
        private device: Device,
        private log: Logger
    ) {}

    /**
     * B/c pre Android API 33, notifications were assumed granted. The
     * package PushNotifications is assuming as much. And so documentations says to use
     * LocalNotifications for API 33 and above. LocalNotifications would probably also work for iOS
     * but to limit the risk of this release, we are not changing how iOS requests permissions
     */
    private checkForPermission = async (): Promise<PermissionState> => {
        if (this.device.platform === 'ios'){
            const { receive } = await PushNotifications.checkPermissions();
            return receive;
        } else {
            const { display } = await LocalNotifications.checkPermissions();
            return display;
        }
    };

    private requestPermisison = async (): Promise<PermissionState> => {
        if (this.device.platform === 'ios'){
            const { receive } = await PushNotifications.requestPermissions();
            return receive;
        } else {
            const { display } = await LocalNotifications.requestPermissions();
            return display;
        }
    };

    requestPermissionIfNotYetRequested = async () => {
        if (!['ios', 'android'].includes(this.device.platform)) {
            // capacitor plugin doesn't support web
            return;
        }

        // if user already responded to permission request, nothing to do
        const currentStatus = await this.checkForPermission();

        // app info / permissions
        if (['granted', 'denied'].includes(currentStatus)) {
            return;
        }

        // note: ios will prompt, android will grant without prompting
        const newStatus = await this.requestPermisison();

        if (newStatus === 'granted') {
            this.log.info('Push notifications permission granted');

            // since user had not previously granted, register() will have failed at app start
            // (in PushNotificationListener), so we need to invoke register() again now
            PushNotifications.register();

        } else {
            this.log.info('Push notifications permission denied');
        }
    };
}
