import { useIonPicker } from '@ionic/react';
import { useBreakpoints } from '../../support/hooks/useBreakpoints';
import { DownChevronIcon } from '../../components/icons/Icons';
import { formatDuration } from '../../support/helpers/format';
import { Button } from '../button/Button';

// build list of durations in 15-minute increments ('15m', '30m', '45m', '1h 15m', ... '12h')
const buildDurationList = (selectedValue: number) => {
    const INCREMENT = 15;

    return Array.from(Array(48).keys()).map(i => {
        const minutes = (i + 1) * INCREMENT;

        // selectedValue should already be on an increment boundary, but if not, match with nearest
        const selected = selectedValue > (minutes - INCREMENT/2) && selectedValue < (minutes + INCREMENT/2);

        return { text: formatDuration(minutes), value: minutes, selected };
    });
};

const buildDurationPicker = (durationMinutes: number, animated: boolean, onChange: (value: number) => void) => {
    // this must be regenerated every time the picker is presented, or the UX glitches out
    const durationList = buildDurationList(durationMinutes);

    return {
        columns: [
            {
                name: 'duration',
                options: durationList,
                selectedIndex: durationList.findIndex(d => d.selected)
            },
        ],
        buttons: [
            {
                text: 'Cancel',
                cssClass: 'picker-cancel'
            },
            {
                text: 'Done',
                cssClass: 'picker-done',
                handler: (selected: any) => {
                    const { duration } = selected;
                    onChange(duration.value);
                }
            }
        ],
        animated
    };
};

export const DurationPicker: React.FC<{ durationMinutes: number, onChange: (durationMinutes: number) => void, disabled?: boolean }> = ({ durationMinutes, onChange, disabled }) => {
    const [present] = useIonPicker();

    const { isLargerScreen } = useBreakpoints();

    return (
        <Button disabled={disabled} minWidth={0} fill="clear" dataType="duration-picker" onClick={() => present(buildDurationPicker(durationMinutes, !isLargerScreen, onChange))}>
            <b>{formatDuration(durationMinutes)}</b><DownChevronIcon slot="end" size="small" />
        </Button>
    );
};
