export const colors = [
    'primary',
    'secondary',
    'tertiary',
    'success',
    'warning',
    'danger',
    'medium',
    'light',
    'advisory-warning',
    'advisory-laanc',
    'insurance'
] as const;
export type Color = typeof colors[number];

export const sizes = ['small', 'medium', 'large'] as const;
export type Size = typeof sizes[number];

export const fills = ['solid', 'outline', 'clear'] as const;
export type Fill = typeof fills[number];
