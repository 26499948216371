import React, { ReactNode } from 'react';
import { IonButton } from '@ionic/react';
import { PredefinedColors } from '@ionic/core';
import { createUseStyles } from 'react-jss';

type IStyleKeys = 'button';

interface IStyleProps {
    size?: 'small';
    color?: PredefinedColors
}

const useStyles = createUseStyles<IStyleKeys, IStyleProps>({
    button: {
        '--ion-color-base': ({color}) => `rgba(var(--ion-color-${color}-rgb), 0.2) !important`,
        width: ({size}) => (size === 'small' ? '36px !important' : undefined),
        height: ({size}) => (size === 'small' ? '36px !important' : undefined)
    }
});

interface IRoundButtonProps {
    size?: 'small';
    color?: PredefinedColors;
    target?: string;
    href?: string;
    onClick?: (e: React.MouseEvent) => void;
    children: ReactNode
}

export const RoundButton: React.FC<IRoundButtonProps> = ({ size, color = 'medium', target, href, onClick, children }) => {

    const classes = useStyles({ size, color });

    return (
        <IonButton
            shape="round"
            fill="solid"
            color={color}
            target={target}
            href={href}
            onClick={onClick ? (e: React.MouseEvent) => onClick(e) : undefined}
            className={classes.button}
        >
            {children}
        </IonButton>
    );
};
