import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useEffect, useRef } from 'react';
import { MapViewModel } from '../model/MapViewModel';
import { VisibleMapLayersState } from '../toggle-layers/VisibleMapLayersState';

/**
 * <BindMapViewModel /> binds a dom element to an instance of mapbox gl js
 * and initializes the map with a base style and other general configuration.
 * MapViewModel should not be used until await container.get(MapViewModel).asyncInitialized()
 *
 * To limit that temporal coupling, see <MapViewModelBoundContainer>
 *
 * This is the lower level building block it uses to actually bind and init the map.
 */
export const BindMapViewModel = () => {
    const elRef = useRef(null);
    const mapViewModel = useInstance(MapViewModel);
    const mapVisibleLayers = useInstance(VisibleMapLayersState);

    useEffect(() => {
        if (mapViewModel.isDisabled()){
            return;
        }

        if (elRef.current) {
            mapViewModel.initMap(elRef.current as HTMLElement)
                .then(() => {
                    mapVisibleLayers.init();
                });
        }
    }, [elRef, mapViewModel, mapVisibleLayers]);

    return <div id="permanent-map-container" style={{ display: 'none' }} ref={elRef} />;
};
