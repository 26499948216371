import { State } from '@meraki-internal/state';
import { ILiveUpdatesService } from './ILiveUpdatesService';
const pkgVersion = require('../../../package.json').version;

export class WebLiveUpdatesService extends State<{}> implements ILiveUpdatesService  {
    constructor(){
        super({});
    }

    getActualVersion = () => {
        const shortSha = (process.env.REACT_APP_SHA || '(no sha)').substring(0,7);
        return `${pkgVersion}.${shortSha}`;
    };

    getInstalledVersion = () => this.getActualVersion();

    init = async () => {
        // do nothing
    };
    downloadUpdateIfAvailable = async () => {
        // do nothing
    };
    installOrDownloadUpdateIfAvailable = async () => {
        // do nothing
    };

    showManagerLink = () => false;

    mustWaitForLatestUpdate = async () => false;

    waitForDownloadAndInstall = async () => {
        // do nothing
    };
}
