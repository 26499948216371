import { IonButtons, IonContent, IonModal, IonText } from '@ionic/react';
import { useState } from 'react';
import { Button } from '../components/button/Button';
import { PageHeader } from '../components/page/PageHeader';
import { CopyButton } from '../components/button/CopyButton';

const ContactMethod: React.FC<{ label: string, value: string, href?: string }> = ({ label, value, href, children }) => {

    const onClick = () => {
        var link = document.createElement('a');
        link.href = href!;
        link.target = '_blank';
        link.click();
    };

    return (
        <div style={{display: 'flex', alignItems: 'start', gap: 20}} onClick={href ? onClick : undefined}>
            <div style={{width: 60, lineHeight: 1}}>
                <small>{label}</small>
            </div>

            <div style={{width: 'calc(100% - 100px)'}}>
                <IonText color={href ? 'primary' : undefined}>{children}</IonText>
            </div>

            <IonButtons style={{width: 40, paddingRight: 8}}>
                <CopyButton value={value} size="small" />
            </IonButtons>
        </div>
    );
};

export const InitiateClaimButton = () => {
    const [isClaimModalOpen, setClaimModalOpen] = useState(false);

    const initiateClaim = () => {
        setClaimModalOpen(true);
    };

    return (
        <>
            <Button fill="outline" color="secondary" expand="block" onClick={initiateClaim}>Initiate a Claim</Button>

            <IonModal isOpen={isClaimModalOpen} onDidDismiss={() => setClaimModalOpen(false)}>
                <PageHeader title="Initiate a Claim" onClose={() => setClaimModalOpen(false)} />
                <IonContent className="ion-padding">

                    <p>To initiate a claim, please contact Allianz using your preferred method below.</p>

                    <div style={{marginTop: 20, display: 'flex', flexDirection: 'column', gap: 20}}>

                        <ContactMethod
                            label="Email"
                            value="newloss@agcs.allianz.com"
                            href="mailto:newloss@agcs.allianz.com"
                        >
                            newloss@agcs.allianz.com
                        </ContactMethod>

                        <ContactMethod
                            label="Call"
                            value="800-558-1606"
                            href="tel:800-558-1606"
                        >
                            1-800-558-1606<br />
                            <small>(outside the U.S. +1 314-513-1353)</small>
                        </ContactMethod>

                        <ContactMethod
                            label="Fax"
                            value="888-323-6450"
                        >
                            1-888-323-6450<br />
                            <small>(outside the U.S. +1 314-513-1345)</small>
                        </ContactMethod>

                        <ContactMethod
                            label="Mailing Address"
                            value="Allianz Global Corporate & Specialty, Attn: FNOL Claims Unit, One Progress Point Parkway, O'Fallon, MO 63368"
                        >
                            <div style={{fontSize: 13}}>
                                Allianz Global Corporate & Specialty<br />
                                Attn: FNOL Claims Unit<br />
                                One Progress Point Parkway<br />
                                O'Fallon, MO 63368
                            </div>
                        </ContactMethod>
                    </div>

                    <div style={{margin: 40, textAlign: 'center'}}>
                        <Button size="medium" fill="outline" color="secondary" onClick={() => setClaimModalOpen(false)}>Close</Button>
                    </div>

                </IonContent>
            </IonModal>
        </>
    );
};
