import { WeatherConditionIcon } from './components/WeatherConditionIcon';
import { HourHeading } from './components/HourHeading';
import { CardHeader } from '../components/card/CardHeader';
import { DrawerLabel } from '../components/drawer/DrawerLabel';
import { CardContent } from '../components/card/CardContent';
import { Card } from '../components/card/Card';
import { IHourlyForecast } from './model/IHourlyForecast';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useEffect, useState } from 'react';
import { Spinner } from '../components/spinner/Spinner';
import { WeatherError } from './components/WeatherError';
import { WindIndicator } from './components/WindIndicator';
import { WindLabel } from './components/WindLabel';
import { Logger } from '../support/debug/Logger';
import { ICoordinate } from '@autopylot-internal/tiles-client';
import { CachingWeatherService } from './CachingWeatherService';

type ISO8601 = string;

interface IHourlyForecastCardFromLinkProps {
    link: { href: string; };

    // mission info
    startTime: ISO8601;
    location: ICoordinate;
    endTime: ISO8601;

    onHourClick?: (hour: ISO8601) => void;
}

export const HourlyForecastCardFromLink: React.FC<IHourlyForecastCardFromLinkProps> = ({ link, startTime, endTime, location, onHourClick }) => {

    const weatherService = useInstance(CachingWeatherService);
    const logger = useInstance(Logger);

    const [forecast, setForecast] = useState<IHourlyForecast[]>();
    const [error, setError] = useState<any>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        weatherService.getHourlyForecast({ link, startTime, endTime, location }).then(_forecast => {
            setForecast(_forecast);
            setError(undefined);
        }).catch(err => {
            setError(err);
            setForecast(undefined);
            logger.error(err);
        }).finally(() => {
            setLoading(false);
        });
    }, [weatherService, logger, link, startTime, endTime, location]);

    return <HourlyForecastCard
        forecast={forecast}
        forecastError={error}
        isLoading={loading}
        startTime={startTime}
        endTime={endTime}
        onHourClick={onHourClick}
    />;
};


interface IHourlyForecastCardProps {
    forecast?: IHourlyForecast[];
    forecastError?: any;
    isLoading: boolean;
    startTime?: ISO8601;
    endTime?: ISO8601;

    onHourClick?: (hour: ISO8601) => void;
}

export const HourlyForecastCard: React.FC<IHourlyForecastCardProps> = ({ forecast,  forecastError, isLoading, startTime, endTime, onHourClick }) => {
    if (!startTime || !endTime){
        // this mostly shouldn't happen, made these fields optional to make it easier to integrate with PreMissionWeatherState
        return null;
    }

    const visibleForecast = forecast;

    return (
        <div
            data-type="weather-mission-hourly"
            data-forecast={isLoading ? 'loading': 'loaded'}
            data-first-hour={forecast ? forecast[0].forecastStart : undefined}
            data-start-time={startTime}
            data-location={JSON.stringify(forecast ? forecast[0].location : undefined)}
            data-status="loaded"
        >
            <Card>

                <CardHeader>
                    <DrawerLabel text="Mission Hourly Forecast" />
                </CardHeader>

                <CardContent>

                    <div style={{minHeight: 80, margin: '0 -8px', display: 'flex', overflowX: 'scroll', overflowY: 'hidden', opacity: isLoading ? .5 : 1}} >

                        {forecastError &&
                            <WeatherError error={forecastError} />
                        }

                        {!forecast && !forecastError &&
                            <Spinner size="small" centered />
                        }

                        {visibleForecast && visibleForecast.map((hour, idx) => (
                            <div key={hour.forecastStart}
                                data-type="hourly-forecast-hour"
                                style={{minWidth: 65, display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: (onHourClick ? 'pointer' : 'default') }}
                                onClick={() => {
                                    if (onHourClick){
                                        onHourClick(hour.forecastStart);
                                    }
                                }}
                            >
                                <HourHeading forecast={hour} highlightStart={startTime} highlightEnd={endTime} />

                                <div style={{display: 'flex'}}>
                                    <span style={{marginRight: 3}}><WeatherConditionIcon size="medium" conditionCode={hour.conditionCode} isDaylight={hour.isDaylight} /></span>
                                    <span style={{fontSize: 17, fontWeight: 'bold'}}>{hour.temperatureF}</span>
                                </div>

                                <div style={{display: 'flex', alignItems: 'center', gap: 3}}>
                                    <WindIndicator direction={hour.windDirection} size="small" />
                                    <span style={{fontSize: 10}}><WindLabel direction={hour.windDirection} speedMph={hour.windSpeedMph} /></span>
                                </div>
                            </div>
                        ))}

                    </div>

                </CardContent>

            </Card>
        </div>
    );
};
