import { State } from '@meraki-internal/state';
import { ILoginDialogPresenter, LoginDialog } from '../../auth/LoginDialog';
import { AuthenticationClient } from '@autopylot-internal/autopylot-api-client';
import { DevSettings } from '../../support/DevSettings';

export interface IDialogBaseProps {
    onDismiss: (data?: any) => void;
}

interface IDialogPresenterState {
    Component: React.FunctionComponent<any>;
    props?: { [propName: string]: any };
}

// This serves as the dialog view model (named "presenter" to match consumer pattern used with Toast/Alert).
// Uses controller set by DialogBinder (in AutoPylotApp).
export class DialogPresenter extends State<IDialogPresenterState> implements ILoginDialogPresenter {
    static inject = () => [AuthenticationClient, DevSettings];
    constructor(private authClient: AuthenticationClient, private devSettings: DevSettings){
        super({ Component: () => null, props: undefined });
    }

    private controller?: HTMLIonModalElement;

    private assertController = () => {
        if (!this.controller) {
            throw new Error('setController must be invoked first');
        }
    };

    // invoked once by DialogBinder (which is rendered in AutoPylotApp)
    setController = (controller: HTMLIonModalElement) => {
        this.controller = controller;
    };

    // Shows a dialog, containing provided Component with props. A promise is returned,
    // which resolves with the data passed to onDismiss() by the Component. The Component
    // is rendered inside DialogPage.
    show = async <T extends { [propName: string]: any }>(Component: React.FunctionComponent<T>, props: Omit<T, keyof IDialogBaseProps>): Promise<any> => {
        this.assertController();
        this.setState({ Component, props });

        this.controller!.present();

        const result = await this.controller!.onWillDismiss();

        // we expect data (passed to onDismiss in the dialog page) to be undefined or serializable,
        // but this event will also fire if the dialog is closed by the framework, so we use a catch
        // here to make this handler more resilient to unexpected input
        const { data } = result;
        let payload: any;
        try {
            payload = JSON.parse(data);
        } catch (e) {
            payload = undefined;
        }

        return payload;
    };

    // Can be invoked by consumer to hide a dialog. Is also invoked if a user action (e.g. hitting the escape key)
    // dismisses the dialog, or the dialog page Component calls onDismiss().
    hide = (data?: any) => {
        this.assertController();

        // we expect data (passed to onDismiss by the Component) to be undefined or serializable,
        // but we use a catch here to skip non-serializable input to allow flexibility  to use
        // onClick={onDismiss}, which will pass an event object we want to ignore
        let payload: any;
        try {
            payload = JSON.stringify(data);
        } catch (e) {
            payload = undefined;
        }

        this.controller!.dismiss(payload);
        this.setState({ Component: () => null, props: undefined });
    };

    showLoginDialog = async ({ message, returnTo }: { message?: string, returnTo?: string }) => {
        await this.show(LoginDialog, { message, returnTo });
    };
}
