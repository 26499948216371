export const PropsList: React.FC<{ info: { [key: string]: any }, sorted?: boolean }> = ({ info, sorted }) => {
    if (!info) {
        return null;
    }

    let keys = Object.keys(info).filter(k => info[k] !== undefined);

    if (sorted) {
        keys = keys.sort();
    }

    return (
        <ul>
            {keys.map(key => (
                <li key={key}>{key}: {String(info[key])}</li>
            ))}
        </ul>
    );
};
