import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useSubscription } from '@meraki-internal/state';
import { HistoryViewModel } from '../app/HistoryViewModel';
import { Page } from '../components/page/Page';
import { useEditableMissionState } from '../missions/model/useEditableMissionState';
import { OperatorState } from '../profile/OperatorState';
import { DevSettings } from '../support/DevSettings';
import { usePageView } from '../support/tracking/usePageView';
import { AuthorizationDetails } from './components/AuthorizationDetails';

export const MissionAuthorizationDetailsPage: React.FC<{ missionId: string }> = ({ missionId }) => {
    usePageView('Mission LAANC Authorization Details');
    const history = useInstance(HistoryViewModel);

    const missionVM = useEditableMissionState(missionId);
    const operatorState = useInstance(OperatorState);
    useSubscription(() => operatorState);
    const operator = operatorState.state;

    const devSettings = useInstance(DevSettings);

    if (!missionVM) {
        return null;
    }

    const relevantAuthorizations = missionVM.getRelevantAuthorizations();

    return (
        <Page title="Authorization Details" onBack={() => history.back(missionVM.pages.details.path())}>
            <AuthorizationDetails
                mission={missionVM}
                authorizations={relevantAuthorizations}
                operator={operator}
                devSettings={devSettings}
            />
        </Page>
    );
};
