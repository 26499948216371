import { Group } from '../ComponentLibrary';
import { IonItem, IonLabel, IonList } from '@ionic/react';
import { WindIndicator } from '../../weather/components/WindIndicator';
import { WindLabel } from '../../weather/components/WindLabel';

const DemoWindIndicator: Group & React.FC = () => {
    return (
        <IonList>
            {Array.from(Array(16).keys()).map(index => {
                const direction = Math.round(index * 22.5);
                return (
                    <IonItem key={direction}>
                        <IonLabel>{direction}&deg;</IonLabel>
                        <div style={{display: 'flex', alignItems: 'center', gap: 5}} slot="end">
                            <WindIndicator direction={direction} size="medium" />
                            <WindLabel direction={direction} speedMph={5} showHeading={true} />
                        </div>
                    </IonItem>
                );
            })}
        </IonList>
    );
};

DemoWindIndicator.componentName = 'WindIndicator';

export default DemoWindIndicator;
