import { IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonText } from '@ionic/react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { State, useSubscription } from '@meraki-internal/state';
import { useEffect } from 'react';
import { AlertPresenter } from '../app/AlertBinder';
import { HistoryViewModel } from '../app/HistoryViewModel';
import { Button } from '../components/button/Button';
import { DeleteIcon } from '../components/icons/Icons';
import { Page } from '../components/page/Page';
import { Refresher, RefresherEvent } from '../components/refresher/Refresher';
import { Spinner } from '../components/spinner/Spinner';
import { tryAndCatch } from '../support/helpers/tryAndCatch';
import { TrackingService } from '../support/tracking/TrackingService';
import { usePageView } from '../support/tracking/usePageView';
import { useIsNative } from '../support/hooks/useIsNative';
import { ISavedChecklist } from './model';
import { SaveChecklistDialog } from './SaveChecklistDialog';
import { SavedChecklistsState } from './SavedChecklistsState';
import { OperatorState } from '../profile/OperatorState';

export class SavedChecklistsPageState extends State<{isSaving: boolean}> {
    constructor() {
        super({ isSaving: false });
    }
    startSaving = () => this.setState({ isSaving: true });
    stopSaving = () => this.setState({ isSaving: false });
}

export const SavedChecklistsPage = () => {
    usePageView('Checklist Templates');

    const alert = useInstance(AlertPresenter);
    const history = useInstance(HistoryViewModel);
    const tracker = useInstance(TrackingService);

    const isNative = useIsNative();

    const vm = useInstance(SavedChecklistsState);
    useSubscription(() => vm);

    const pageState = useInstance(SavedChecklistsPageState);
    useSubscription(() => pageState);

    const operatorState = useInstance(OperatorState);
    useSubscription(() => operatorState);

    useEffect(() => {
        if (!vm.state.loaded) {
            vm.load();
        }
    }, [vm]);

    const onAdd = () => {
        // guest must sign up before creating a checklist template
        if (!operatorState.ensureLoggedIn({ from: 'Add Checklist' })) {
            return;
        }

        pageState.startSaving();
    };

    const onSave = async ({ name }: { name: string }) => {
        try {
            await vm.addChecklist({ name, sections: [] });
            tracker.track('Checklist Created', () => ({ name }));
            pageState.stopSaving();
        } catch (e) {
            alert.showAndLogError(e);
        }
    };

    const onDelete = async (checklist: Omit<ISavedChecklist, 'sections'>) => {
        const response = await alert.confirmDestructiveAction({
            header: 'Delete Checklist?',
            message: 'Your checklist and all items items will be deleted and cannot be recovered.'
        });

        if (response === 'destroy') {
            try {
                await vm.removeChecklist(checklist);
                tracker.track('Checklist Deleted', () => ({ checklistId: checklist.checklistId, name: checklist.name }));
            } catch (e) {
                alert.showAndLogError(e);
            }
        }
    };

    const onRefresh = async (event: RefresherEvent) => {
        await tryAndCatch(vm.load, alert.showAndLogError);
        event.detail.complete();
    };

    if (!vm.state) {
        return null;
    }

    const sortedChecklists = vm.state.checklists.sort((c1, c2) => c1.state.checklist.name.localeCompare(c2.state.checklist.name));

    return (
        <Page title="Checklist Templates">

            <Refresher onRefresh={onRefresh} />

            <div style={{margin: '30px 25px'}}>

                <p style={{marginBottom: 20}}>
                    {isNative && <>
                        Manage your reusable checklist templates here. Templates can be used in missions
                        and enable mission-specific reminders!
                    </>}
                    {!isNative && <>
                        Manage your reusable checklist templates here. Templates can be used in missions!&nbsp;
                        <i>Note that to receive mission-specific reminder notifications you'll need to download our mobile app.</i>
                    </>}
                </p>

                <div style={{marginTop: 20, marginBottom: 20}}>
                    <Button dataType="new-checklist" expand="block" onClick={onAdd}>
                        New Checklist
                    </Button>
                </div>

                {!vm.state.loaded &&
                    <div style={{textAlign: 'center', marginBottom: 15}}>
                        <Spinner size="small" />
                    </div>
                }

                <IonList data-type="saved-checklists" lines="full">
                    {sortedChecklists.map(checklistState => {
                        const { checklistId, name } = checklistState.state.checklist;

                        return (
                            <IonItemSliding key={`checklist-${checklistId}`}>

                                <IonItem button onClick={() => history.push(`/checklists/${checklistState.state.checklist.checklistId}`)}>
                                    <IonLabel>{name}</IonLabel>
                                    <IonText data-type="item-count" slot="end" color="medium">{checklistState.getItemCount()}</IonText>
                                </IonItem>

                                <IonItemOptions>
                                    <IonItemOption onClick={() => onDelete(checklistState.state.checklist)} style={{margin: 'auto 0', height: 40}} color="danger">
                                        <DeleteIcon />
                                    </IonItemOption>
                                </IonItemOptions>

                            </IonItemSliding>
                        );
                    })}
                </IonList>

            </div>

            <SaveChecklistDialog title="New Checklist" isOpen={pageState.state.isSaving} onCancel={pageState.stopSaving} onSave={onSave} />

        </Page>
    );
};
