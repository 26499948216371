import { FeedbackAlert } from '../app/FeedbackAlert';
import { Page } from '../components/page/Page';
import { usePageView } from '../support/tracking/usePageView';
import { MissionList } from './components/MissionList';
import { IMissionListSection } from './model/MissionsState';

export const MissionListPage = ({ initialAccordionValue }: { initialAccordionValue?: IMissionListSection}) => {
    usePageView('Missions');

    return (
        <Page title="Missions">

            <FeedbackAlert />

            <MissionList initialAccordionValue={initialAccordionValue} />

        </Page>
    );
};
