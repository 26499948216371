export const TemperatureGradient: React.FC<{min: number, max: number}> = ({min, max}) => {
    const colors = [
        '#5768D1', // navy < 30
        '#309FFB', // blue < 40
        '#00B6B4', // cyan < 50
        '#23AE09', // green < 60
        '#EFD10A', // yellow < 70
        '#F5711B', // orange < 80
        '#DB163D', // red < 90
        '#6D069D'  // violet > 90
    ];
    const gradient: string[] = [];
    for (let i = 0; i < colors.length; i++) {
        const temp = i * 10 + 30;
        if (min < temp) {
            gradient.push(colors[i]);
        }
        if (max < temp) {
            break;
        }
    }
    // gradient needs at least 2 colors
    if (gradient.length === 1) {
        gradient.push(gradient[0]);
    }
    return (
        <div style={{width: 120, height: 10, borderRadius: 5, background: `linear-gradient(to right,${gradient})`}}></div>
    );
};
