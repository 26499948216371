import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useSubscription } from '@meraki-internal/state';
import { useEffect, useRef, useState } from 'react';
import { Spinner } from '../../components/spinner/Spinner';
import { LayerSelector } from './LayerSelector';
import { WindAltitudeSelector } from './WindAltitudeSelector';
import { WindyMapViewModel } from './WindyMapViewModel';

export const ShowWindyMap: React.FC = () => {
    const temporaryMapContainer = useRef(null);

    const mapViewModel = useInstance(WindyMapViewModel);
    useSubscription(() => mapViewModel);

    const [isReady, setReady] = useState(false);

    useEffect(() => {
        mapViewModel.isReady.then(() => {
            setReady(true);

            if (temporaryMapContainer.current) {
                mapViewModel.showMapInDomContainer(temporaryMapContainer.current);
            }
            return () => {
                mapViewModel.removeMapFromDomContainer();
            };
        });
    }, [mapViewModel, temporaryMapContainer, setReady]);

    return (
        <>
            <div id="show-windy-map-container" ref={temporaryMapContainer} style={{ height: '100%', width: '100%', position: 'relative' }}>
                {!isReady && <Spinner size="small" centered />}
            </div>

            <div id="windy-custom-controls">
                {isReady &&
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 12 }}>
                        {mapViewModel.getLayer() === 'wind' &&
                            <WindAltitudeSelector level={mapViewModel.getWindAltitude()} setLevel={mapViewModel.setWindAltitude} />
                        }
                        <LayerSelector layer={mapViewModel.getLayer()} setLayer={mapViewModel.setLayer} />
                    </div>
                }
            </div>
        </>
    );
};
