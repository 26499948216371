import { IonItem, IonLabel, IonList } from '@ionic/react';
import { Group } from '../ComponentLibrary';
import * as icons from './Icons';

const DemoIcons: Group & React.FC = () => {
    return (
        <IonList>
            {Object.keys(icons).map(iconName => {
                if (!iconName.endsWith('Icon')) {
                    // skip functions exported from Icons.tsx
                    return null;
                }
                const Icon = (icons as any)[iconName];
                return (
                    <IonItem key={iconName}>
                        <IonLabel>{iconName}</IonLabel>
                        <Icon size="small" />
                        <Icon size="medium" />
                        <Icon size="large" />
                    </IonItem>
                );
            })}
        </IonList>
    );
};
DemoIcons.componentName = 'Icons';

export default DemoIcons;
