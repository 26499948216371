import { IScreenshotablePage } from '../support/screenshots/IScreenshotablePage';
import { MapPage } from './MapPage';
import { NewMissionState } from '../missions/model/NewMissionState';
import { ICoordinate } from '../support/model/ICoordinate';
import { MapViewModel } from './model/MapViewModel';
import { AuthorizationTileProvider, AuthorizeMissionPolygonFacadeMockAirspaceProvider, ITFRProperties } from '@autopylot-internal/tiles-client';
import { IAddress } from '../support/model/IAddress';
import { OperatorState } from '../profile/OperatorState';
import { FAAVersionAlertManager } from '../app/FAAVersionAlertManager';
import { TrackingService } from '../support/tracking/TrackingService';
import { DrawerState } from '../components/drawer/DrawerState';
import moment from 'moment';
import { Feature, Polygon, Point } from '@turf/turf';
import * as turf from '@turf/turf';
import { AuthorizationFeatureCollection } from '@autopylot-internal/tiles-client/dist/AuthorizationFeatureCollection';
import { EnableAndBindMapForScreenshots } from './EnableAndBindMapForScreenshots';
import { IScreenshotHint } from '../support/screenshots/ScreensPage';
import { ScreenshotHelper } from '../support/screenshots/ScreenshotHelper';
import { MissionPinAndRadiusOnMapViewModel } from './mission-pin-and-radius/MissionPinAndRadiusOnMapViewModel';
import { IHourlyForecast } from '../weather/model/IHourlyForecast';
import { PreMissionWeatherState } from '../weather/PreMissionWeatherState';

interface IMyPageProps {
    newMission?: { address: IAddress, point: ICoordinate, radiusFeet?: number, startTime?: string, durationMinutes?: number };
    emulateLoading?: boolean;
    isEditingStart?: boolean;
    isEditingDuration?: boolean;
    fullHeightDrawer?: boolean;
    expandSchedule?: boolean;
    mockFeatures?: Feature[];
    hint?: IScreenshotHint;
    weather?: Partial<IHourlyForecast>;
}

export default class ScreenshotMapPage implements IScreenshotablePage<IMyPageProps> {
    static inject = () => [
        MapViewModel,
        AuthorizationTileProvider,
        FAAVersionAlertManager,
        TrackingService,
        DrawerState,
        AuthorizeMissionPolygonFacadeMockAirspaceProvider,
        OperatorState,
        ScreenshotHelper,
        MissionPinAndRadiusOnMapViewModel,
        PreMissionWeatherState
    ];

    constructor(
        private mapViewModel: MapViewModel,
        private authorizationTileProvider: AuthorizationTileProvider,
        private versionAlertManager: FAAVersionAlertManager,
        private tracker: TrackingService,
        private drawerState: DrawerState,
        private mockFeatureProvider: AuthorizeMissionPolygonFacadeMockAirspaceProvider,
        private operatorState: OperatorState,
        private helper: ScreenshotHelper,
        private pinAndRadius: MissionPinAndRadiusOnMapViewModel,
        private preMissionWeatherState: PreMissionWeatherState
    ) { }

    screenId = 'new-mission-step-1';

    ComponentUnderTest = MapPage;

    WrapWith = EnableAndBindMapForScreenshots;

    permutationToProps = async ({ newMission, emulateLoading, fullHeightDrawer, mockFeatures, weather }: IMyPageProps) => {

        this.preMissionWeatherState.updateProps = () => {};
        if (weather){
            this.preMissionWeatherState.forecast = [weather as IHourlyForecast];
        };

        this.operatorState.setState({
            firstName: 'Freddy',
            lastName: 'Flyer',
            phone: '802-123-1234',
            email: 'freddy.flyer@gmail.com',
            certificationType: 'part-107',
            agreedToFAAPrivacyStatement: true,
            agreedToLAANCStatement: true,
            agreedToTermsOfService: true,
        });

        let mission: NewMissionState | undefined;

        if (emulateLoading){
            this.authorizationTileProvider.getAuthorizationFeatures = () => {
                return new Promise(resolve => {
                    // never
                });
            };
        }

        if (mockFeatures) {
            this.mockFeatureProvider.get = () => {
                return new AuthorizationFeatureCollection({ features: mockFeatures as any });
            };
        }

        if (newMission) {
            mission = new NewMissionState({
                onSave: async () => {
                },
                getConcurrentLAANCMissions: () => []
            });

            // TODO: friction from property injection
            mission.mapViewModel = this.mapViewModel;
            mission.operator = this.operatorState;
            mission.versionAlertManager = this.versionAlertManager;
            mission.tracker = this.tracker;
            mission.pinAndRadius = this.pinAndRadius;

            mission.setAddress(newMission.address).setCenter(newMission.point);

            if (newMission.radiusFeet) {
                mission.setRadiusFeet(newMission.radiusFeet);
            }

            if (newMission.startTime) {
                mission.setStartTime(newMission.startTime, 'Mission Area and Time');
            }

            if (newMission.durationMinutes) {
                mission.setDurationMinutes(newMission.durationMinutes);
            }
        }

        if (fullHeightDrawer){
            setTimeout(() => {
                this.drawerState.setFullHeight();
            });
        }

        return { screenshotConfig: { mission, wizardPage: 1 } };
    };

    blockScreenshotTil = async ({ emulateLoading, isEditingStart, isEditingDuration, newMission, expandSchedule }: IMyPageProps) => {
        const start = Date.now();
        console.log('waiting for map');
        await this.mapViewModel.waitForIdle();
        console.log(Date.now() - start, 'ms waited for map');

        if (newMission && !emulateLoading){
            console.log('waiting for flight status');
            const started = Date.now();
            await this.helper.waitForElement('[data-type="flight-status"]');
            console.log(Date.now() - started, 'ms waited for flight status');
        }

        if (isEditingStart) {
            const picker: any = await this.helper.waitForElement('[data-type=date-time-picker]');
            picker.click();
        }

        if (isEditingDuration) {
            const picker: any = await this.helper.waitForElement('[data-type=duration-picker]');
            picker.click();
        }

        if (expandSchedule){
            const dropdown: any = await this.helper.waitForElement('[data-type=expand-schedule]');
            dropdown.click();
        }

        // NOTE: avoid .scrollIntoView() here, as it unexpectedly causes the floating buttons to move up, showing drawer content below them
    };

    permutations: {[k: string]: IMyPageProps} = {
        'loading-advisories': {
            newMission: { address: { street: '12025 Coyote Ridge Road', city: 'Deadwood', state: 'SD', zip: '57732' }, point: { lng: -103.64851, lat: 44.30194 } },
            emulateLoading: true
        },
        'uncontrolled': {
            newMission: { address: { street: '12025 Coyote Ridge Road', city: 'Deadwood', state: 'SD', zip: '57732' }, point: { lng: -103.64851, lat: 44.30194 } },
        },
        'laanc': {
            newMission: { address: { street: 'Covington Street', city: 'Rapid City', state: 'SD', zip: '57703' }, point: { lng:-103.14317,lat:44.060875} },
        },
        'not-laanc': {
            newMission: { address: { street: 'East Omaha Street', city: 'Rapid City', state: 'SD', zip: '57701' }, point: { lng:-103.203865,lat:44.081095} },
        },
        'reduced-radius': {
            newMission: { address: { street: '12025 Coyote Ridge Road', city: 'Deadwood', state: 'SD', zip: '57732' }, point: { lng: -103.64851, lat: 44.30194 }, radiusFeet: 200 },
        },
        'max-radius': {
            newMission: { address: { street: 'East Omaha Street', city: 'Rapid City', state: 'SD', zip: '57701' }, point: { lng:-103.203865,lat:44.081095}, radiusFeet: 4000 },
        },
        'max-width-pickers': {
            newMission: { address: { street: '12025 Coyote Ridge Road', city: 'Deadwood', state: 'SD', zip: '57732' }, point: { lng: -103.64851, lat: 44.30194 }, startTime: '2022-10-20T18:00:00.000Z', durationMinutes: 705 },
        },
        'editing-start': {
            newMission: { address: { street: '12025 Coyote Ridge Road', city: 'Deadwood', state: 'SD', zip: '57732' }, point: { lng: -103.64851, lat: 44.30194 } },
            isEditingStart: true
        },
        'editing-duration': {
            newMission: { address: { street: '12025 Coyote Ridge Road', city: 'Deadwood', state: 'SD', zip: '57732' }, point: { lng: -103.64851, lat: 44.30194 } },
            isEditingDuration: true
        },
        'advisories-nsufr': {
            newMission: { address: { street: 'Loma Lane', city: 'Clint', state: 'TX', zip: '11111' }, point: {lng: -106.18205, lat: 31.88369} },
        },
        // 'advisories-parttime-nsufr': {} // to add this we need to mock the data
        'advisories-restricted-sua': {
            newMission: { address: { street: '6 Academy St', city: 'Antwerp', state: 'NY', zip: '13608' }, point: {lng: -75.6104141, lat: 44.1995314} },
        },
        // 'advisories-prohibited-sua': {} // skipped, risk is minimal with restricted covered
        'advisories-dc-frz': {
            newMission: { address: { street: 'DC', city: 'Washington', state: 'DC', zip: '11111' }, point: {lng: -77.03352936547438, lat: 38.912115430029985} },
        },

        // this advisory risk is covered by /screens/mission-map/view-advisories-national-park
        // TODO: we should resurrect this, but the risk covered being the national park feature
        // we would want to zoom out / pan so that we see the feature outline of the national park (and ideally only the national park)
        // 'advisories-national-park': {
        //     newMission: { address: { street: 'Mammoth Hot Springs', city: 'Yellowstone', state: 'WY', zip: '11111' }, point: {lng: -110.706997, lat: 44.968229} },
        // },

        // like national park above, the advisory risk is covered in  /screens/mission-map/
        // TODO: we should resurrect this, but the risk covered being the wildlife  feature
        // 'advisories-wilderness-area': {
        //     newMission: { address: { street: 'Black Elk Wilderness Area', city: 'Not Real', state: 'SD', zip: '11111' }, point: {lng: -103.48233556888772, lat: 43.88926708784166} },
        // },

        // TODO: SAA
        // 'advisories-wildlife-refuge': {
        //     newMission: { address: { street: 'AGASSIZ NATIONAL WILDLIFE REFUGE', city: 'Not Real', state: 'MN', zip: '11111' }, point: { lng: -95.8615073383372, lat: 48.406994224058 } },
        // },

        'advisories-no-laanc': {
            newMission: { address: { street: 'Controlled Airspace with no UASFM grids', city: 'Not Real', state: 'SD', zip: '11111' }, point: {lng: -103.0130062865847, lat: 43.95607953160544} },
        },
        'advisories-laanc-no-aa': {
            newMission: { address: { street: 'LAANC FC but not AA', city: 'Not Real', state: 'SD', zip: '11111' }, point: {lng: -103.07589335308764, lat: 44.024027119681875} },
        },
        'advisories-laanc-inactive': {
            newMission: { address: { street: 'LAANC FC but not AA', city: 'Not Real', state: 'SD', zip: '11111' }, point: {lng: -103.05855555401554, lat: 44.04543884982132} },
        },
        'advisories-laanc-aa': {
            newMission: { address: { street: 'LAANC AA', city: 'Not Real', state: 'SD', zip: '11111' }, point: {lng: -103.14048459598014, lat: 44.032560163358} },
            fullHeightDrawer: true
        },

        'scheduled-airspace--inactive': {
            newMission: {
                address: { street: 'Caldwell, NJ', city: 'Not Real', state: 'SD', zip: '11111' },
                point: { lng: -74.27742427707096, lat: 40.84029115942289 },
                startTime: moment.tz('us/eastern').add(1, 'day').startOf('day').hour(5).toISOString(),
                durationMinutes: 60
                // 0500-0600 is before the airsapce is active at 0645
            },
            hint: { text: 'this should be fly with caution, not blocked because it is during inactive airspace time'}
        },
        'scheduled-airspace--active': {
            newMission: {
                address: { street: 'Caldwell, NJ', city: 'Not Real', state: 'SD', zip: '11111' },
                point: { lng: -74.27742427707096, lat: 40.84029115942289 },
                startTime: moment.tz('us/eastern').add(1, 'day').startOf('day').hour(8).toISOString(),
                durationMinutes: 60
                // 0800-0900 is after the airsapce is active at 0645
            },
            hint: { text: 'this should be laanc because it is during the active airspace time' }
        },

        // TODO: until we can scroll the advisories into view, this screenshot won't show
        // what it is meant to cover
        // 'scheduled-airspace-advisory--inactive': {
        //     newMission: {
        //         address: { street: 'Caldwell, NJ', city: 'Not Real', state: 'SD', zip: '11111' },
        //         point: { lng: -74.27742427707096, lat: 40.84029115942289 },
        //         startTime: moment.tz('us/eastern').add(1, 'day').startOf('day').hour(5).toISOString(),
        //         durationMinutes: 60
        //         // 0500-0600 is before the airsapce is active at 0645
        //     },
        //     fullHeightDrawer: true
        // },

        // no coverage of TFRs... they seem to change to frequently to test with e2e, and most of the non visual risk is covered via
        // tiles tiles/src/e2e/authority-tiles.e2e.ts

        // TODO: until we can scroll the advisories into view, this screenshot won't show
        // what it is meant to cover
        // 'scheduled-airspace-advisory--active-laanc': {
        //     newMission: {
        //         address: { street: 'Caldwell, NJ', city: 'Not Real', state: 'SD', zip: '11111' },
        //         point: { lng: -74.27742427707096, lat: 40.84029115942289 },
        //         startTime: moment.tz('us/eastern').add(1, 'day').startOf('day').hour(8).toISOString(),
        //         durationMinutes: 60
        //         // 0800-0900 is after the airsapce is active at 0645
        //     },
        //     fullHeightDrawer: true
        // },
        // 'scheduled-airspace-advisory--active-schedule-inactive-laanc': {
        //     newMission: {
        //         address: { street: 'Caldwell, NJ', city: 'Not Real', state: 'SD', zip: '11111' },
        //         point: {lng: -74.26973125551734, lat: 40.86032761213454},
        //         startTime: moment.tz('us/eastern').add(1, 'day').startOf('day').hour(8).toISOString(),
        //         durationMinutes: 60
        //         // 0800-0900 is after the airsapce is active at 0645
        //     },
        // },
        // 'scheduled-airspace-advisory--more-than-one-scheduled-airspace': {
        //     newMission: {
        //         address: { street: 'North Vineyard Ave', city: 'Oxnard', state: 'CA', zip: '11111' },
        //         point: {lng: -119.16041, lat: 34.24898},
        //         startTime: moment.tz('us/pacific').add(1, 'day').startOf('day').hour(8).toISOString(),
        //         durationMinutes: 60
        //         // 0800-0900 is after the airsapce is active at 0700
        //     },
        //     fullHeightDrawer: true
        // },
        // 'scheduled-airspace-advisory--complex-expanded-schedule': {
        //     newMission: {
        //         address: { street: 'Worthington', city: 'Worthington', state: 'MN', zip: '11111' },
        //         point: {lng: -95.595643, lat: 43.620506},
        //         startTime: moment.tz('us/central').add(1, 'day').startOf('day').hour(6).toISOString(),
        //         durationMinutes: 12 * 60
        //         // 0600-1800 overlaps two active times
        //     },
        //     fullHeightDrawer: true,
        //     expandSchedule: true
        // },

        'tfr-advise': {
            newMission: {
                address: { street: 'no where', city: 'Frederick', state: 'MD', zip: '11111' },
                point: { lng: -76, lat: 39 },
                startTime: moment.tz('us/eastern').add(1, 'day').startOf('day').hour(6).toISOString(),
                durationMinutes: 12 * 60
            },
            fullHeightDrawer: true,
            mockFeatures: [
                // starts after today, ends perm
                buildTFRAround(
                    { lng: -76, lat: 39 },
                    {
                        effectiveStart: moment.tz('us/eastern').add(1, 'day').startOf('day').hour(6).toISOString(),
                        effectiveEnd: 'PERM'
                    }
                ),
                // starts after today, ends at a specific time
                buildTFRAround(
                    { lng: -76, lat: 39 },
                    {
                        effectiveStart: moment.tz('us/eastern').add(1, 'day').startOf('day').hour(6).toISOString(),
                        effectiveEnd: moment.tz('us/eastern').add(1, 'day').endOf('day').toISOString()
                    }
                ),
                // starts before today, ends perm
                buildTFRAround(
                    { lng: -76, lat: 39 },
                    {
                        effectiveStart: moment.tz('us/eastern').add(-1, 'day').startOf('day').hour(6).toISOString(),
                        effectiveEnd: 'PERM'
                    }
                ),
                // starts before today, ends at a specific time
                buildTFRAround(
                    { lng: -76, lat: 39 },
                    {
                        effectiveStart: moment.tz('us/eastern').add(-1, 'day').startOf('day').hour(6).toISOString(),
                        effectiveEnd: moment.tz('us/eastern').add(1, 'day').endOf('day').toISOString()
                    }
                ),
                // is point TFR
                buildTFRAround(
                    { lng: -76, lat: 39 },
                    {
                        effectiveStart: moment.tz('us/eastern').add(1, 'day').startOf('day').hour(6).toISOString(),
                        effectiveEnd: moment.tz('us/eastern').add(1, 'day').endOf('day').toISOString()
                    },
                    true
                ),
            ]
        },
        'tfr-block': {
            newMission: {
                address: { street: '6514 Royal Lane', city: 'Dallas', state: 'TX', zip: '11111' },
                point: { lng: -96.79233078039061, lat: 32.89484733333454 },
                startTime: moment.tz('us/central').add(1, 'day').startOf('day').hour(6).toISOString(),
                durationMinutes: 12 * 60
            },
        },

        'long-weather-summary': {
            newMission: {
                address: { street: '6514 Royal Lane', city: 'Dallas', state: 'TX', zip: '11111' },
                point: { lng: -96.79233078039061, lat: 32.89484733333454 },
                startTime: moment.tz('us/central').add(1, 'day').startOf('day').hour(6).toISOString(),
                durationMinutes: 12 * 60
            },
            weather: {
                temperatureF: 120,
                conditionCode: 'Blizzard',
                windSpeedMph: 99,
                windDirection: 290,
                windGustMph: 99,
                precipitationType: 'hail',
                precipitationChancePct: 100
            }
        }
    };
}

let tfrNumberCursor = 9901;

const buildTFRAround = (coord: ICoordinate, props: Partial<ITFRProperties>, isPointTFR?: boolean): Feature<Polygon | Point, ITFRProperties> => {
    const properties: ITFRProperties = {
        source: 'temporary-flight-restrictions',
        effectiveStart: moment().toISOString(),
        effectiveEnd: 'PERM',
        advisoryType: 'advise',
        number: `99/${tfrNumberCursor++}`,
        text: '',
        accountId: '',
        lastUpdated: moment().toISOString(),
        classification: '',
        GLOBAL_ID: moment().toISOString(),
        icaoLocation: '',
        id: '',
        issued: moment().toISOString(),
        location: '',
        type: 'N',
        ...props
    };
    if (isPointTFR){
        properties.wasPoint = true;
    }
    return turf.circle([coord.lng, coord.lat], 100, { steps: 5, units: 'meters', properties }) as any;
};
