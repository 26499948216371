import jwtDecode from 'jwt-decode';
import { State } from '@meraki-internal/state';
import { TrackingService } from '../support/tracking/TrackingService';
import { AuthenticationClient } from '@autopylot-internal/autopylot-api-client';
import { Logger } from '../support/debug/Logger';
import { ILoginDialogPresenter } from './LoginDialog';
import { HistoryStorage, HistoryViewModel } from '../app/HistoryViewModel';
import { IStorageOptions, StorageProvider } from '../support/StorageProvider';

const STORAGE_KEY = 'ap:auth';
const STORAGE_OPTIONS: Partial<IStorageOptions> & { storageType: 'localStorage'} = { userNeutral: true, storageType: 'localStorage' };

class LocationProvider{
    get = (): Location => window.location;
}

type IAuthConnection = 'email' | 'facebook' | 'google-oauth2' | 'apple';

export interface IAuthParams {
    redirect_uri: string;
    forceInteractiveLogin: boolean;
    authorization?: string;
}

export class AuthServiceLoginDialogPresenter implements ILoginDialogPresenter {
    showLoginDialog = async (params: { message?: string, returnTo?: string }): Promise<any> => {
        throw new Error('see ioc');
    };
}

interface IPersistableAuth {
    accessToken: string;
    refreshToken: string;
}

export class AuthErrorState extends State<{errorCode?: string, message?: string}> {
    hasError = () => Boolean(this.state?.errorCode);
    clearError = () => this.setState({});
}

export class AuthServiceStorage {
    static inject = () => [StorageProvider];
    constructor(private storage: StorageProvider){}
    auth = this.storage.getJSONProvider<IPersistableAuth>(STORAGE_KEY, STORAGE_OPTIONS);
    isTestUser = this.storage.getBooleanProvider('ap:is-test-user', { envNeutral: true, userNeutral: true, storageType: 'localStorage' });
}

export class AuthService {
    static inject = () =>  [
        AuthenticationClient,
        TrackingService,
        LocationProvider,
        AuthServiceLoginDialogPresenter,
        HistoryViewModel,
        Logger,
        HistoryStorage,
        AuthServiceStorage,
        AuthErrorState
    ];
    constructor(
        private authClient: AuthenticationClient,
        private tracker: TrackingService,
        private locationProvider: LocationProvider,
        private dialog: AuthServiceLoginDialogPresenter,
        private history: HistoryViewModel,
        private logger: Logger,
        private historyStorage: HistoryStorage,
        private authStorage: AuthServiceStorage,
        private authErrorState: AuthErrorState
    ){}

    private isAutomatedTestsBypassingLogin = () => {
        return window.location.search === '?__test_no_login_redirect';
    };

    isOnAuthErrorURL = () => {
        // this is defined as the custom error page in auth0
        return window.location.hash.startsWith('#/oauth/error');
    };

    isOnAuthCallbackURL = () => {
        return window.location.hash.startsWith('#/oauth/code');
    };

    private getLoginRedirectURI = () => `${window.location.origin}${window.location.search}#/oauth/code`;

    private saveHash = (hash: string) => {
        this.historyStorage.previousHash.set(hash);
    };

    private popSavedHash = () => {
        let hash = '';
        if (this.historyStorage.previousHash.exists()) {
            hash = this.historyStorage.previousHash.get() || '';
            this.historyStorage.previousHash.remove();
        }
        return hash;
    };

    showLoginDialog = async ({ message, returnTo }: { message?: string, returnTo?: string } = {}): Promise<any> => {
        if (this.isAutomatedTestsBypassingLogin()) {
            return new Promise(resolve => { });
        }

        const result = await this.dialog.showLoginDialog({ message, returnTo });
        if (!result) {
            this.tracker.track('Auth Options Dismissed');
        }
    };

    private startLoginFlow = async ({ connection, returnTo }: { connection?: IAuthConnection, returnTo?: string } = {}) => {

        // force interactive b/c we should rarely ever go to auth0, the complexity we introduce
        // to track if we're expecting to force a login isn't worth it (we definitely want it if the user
        // just logged out)
        const forceInteractiveLogin = true;

        // save the current / returnTo hash to be restored in loginFromAuthCallback() below
        this.saveHash(returnTo || window.location.hash.substring(1));

        // auth0 docs refer to "email" connections, but they require undefined in this case
        const auth0Connection = (connection === 'email' ? undefined : connection);

        return await this.authClient.loginWithCodeFlow({
            redirect_uri: this.getLoginRedirectURI(),
            forceInteractiveLogin,
            connection: auth0Connection
        });
    };

    login = async ({ connection, returnTo }: { connection: IAuthConnection, returnTo?: string }) => {
        return await this.startLoginFlow({ connection, returnTo });
    };

    private loginAsGuest = async (): Promise<{ accessToken: string, isNewGuest: true }>  => {
        if (this.isAutomatedTestsBypassingLogin()) {
            return new Promise(resolve => { });
        }

        // just in case
        this.removeUserFromLocalStorage();

        // look for local storage flag set for tests (see AppDriver)
        // (sending this param will cause the userId to start with "auto_test-")
        const isTestUser = this.authStorage.isTestUser.exists();

        const { access_token, refresh_token } = await this.authClient.getGuestAccessToken({ isTestUser });

        this.persistTokens({ access_token, refresh_token });

        return { accessToken: access_token, isNewGuest: true };
    };

    loginFromAuthCallbackURL = async (): Promise<{ accessToken: string }> => {
        const location = this.locationProvider.get();

        const params = new URLSearchParams(location.hash.split('?')[1]);
        this.logger.info('AuthService.loginFromAuthCallbackURL()', { location });

        // if we fail on this login callback, then the user would be stuck there
        // it is far more likely that there are error query params (that won't fix on reload)
        // or that the code is no longer valid (which won't fix on reload)
        // so we should get the user off this has before we finish or throw any errors
        this.history.replace('');

        // remove hash from storage here, before error handling below
        const previousHash = this.popSavedHash();

        if (params.has('error_description') || params.has('error')) {
            const errorCode = params.get('error');
            const message = params.get('error_description');
            const err:any = new Error(`Authentication failed: ${errorCode} - ${message}`);
            err.errorCode = errorCode;
            throw err;
        }

        const code = params.get('code') || undefined;

        if (!code) {
            throw Error(`Sorry, authentication failed. Please try again. hash: ${window.location.hash}`);
        }

        try{
            // we send our current user access token, b/c if it is a guest
            // then the API will map the email we authenticated with in auth0 to that userId
            const guestAccessToken = this.getTokenFromStorage()?.accessToken;
            const { access_token, refresh_token } = await this.authClient.exchangeCode({ code, currentUserAccessToken: guestAccessToken });
            this.persistTokens({ access_token, refresh_token });

            const { userId, email } = jwtDecode(access_token) as any;

            const isGuest = !email;
            if (!isGuest) {
                this.tracker.track('User Signed In', () => {
                    const guest = !guestAccessToken ? undefined : jwtDecode(guestAccessToken) as any;
                    return ({ userId, email, abandonedGuestUserId: guest?.userId });
                });
            }

            // use history.replace() here instead of setting window.location.hash,
            // to avoid a bogus POP event firing in our History class
            // (NOTE: guard for /settings is a workaround for a race condition bug in logout)
            if (previousHash !== '/settings') {
                this.history.replace(previousHash);
            }

            return { accessToken: access_token };

        } catch (err: any){
            // we are going to throw, causing the user to get an error page
            // they will have to reload, and hopefully it will work next time.
            this.logger.error(`AuthService.loginFromAuthCallbackURL() failed calling this.authClient.exchangeCode({ code }). Error: ${err.toString()} `);

            throw err;
        }
    };

    on401Callback = async () => {
        // We get here when the api returns a 401, indicating the token is too old.
        // We intentionally do NOT invoke removeUserFromLocalStorage(), so that if the user
        // closes and re-opens the app before signing back in, we'll get another 401 and
        // redirect to auth0 again (vs seeing no token and land on the welcome page).

        const { refreshToken } = this.authStorage.auth.get() || { refreshToken: '' };
        if (!refreshToken){
            this.logger.info('AuthService.on401Callback() - no refresh_token, sending them to the login page');
            await this.showLoginDialog();
            return;
        }

        try{
            const { access_token } = await this.authClient.refreshAccessToken({ refresh_token: refreshToken });

            this.persistTokens({ refresh_token: refreshToken, access_token });

            this.logger.info('AuthService.on401Callback() - used refresh_token to get new access_token, reloading');
            window.location.reload();

            await new Promise(resolve => {});
        }
        catch (err: any){
            // this can fail under normal circumstances, eg their refresh token has been revoked
            this.logger.info(`AuthService.on401Callback() - failed calling this.authClient.refreshAccessToken(), sending to login page. Error ${err.toString()}`);

            await this.showLoginDialog();
        }
    };

    saveNewTokensAndReload = ({ access_token, refresh_token }: { access_token: string, refresh_token: string }) => {
        this.persistTokens({ access_token, refresh_token });
        window.location.reload();
    };

    logout = async () => {
        this.removeUserFromLocalStorage();

        this.tracker.track('Signed Out');
        this.tracker.reset();

        // since we always force interactive login in auth0 we do NOT need to redirect through auth0 to logout there,
        // but we need to clear the hash, or we'll still be on the settings page when we log back in;
        // we set location.href to clear the hash and reload the app in a single step, because setting location.hash
        // then calling location.reload() sometimes creates a race condition on ios causing the reload to be ignored;
        // however, this has continued to be unreliable, so there is also code in login that ignores the prior if it's /settings.
        const location = this.locationProvider.get();
        location.href = location.origin;

        // never resolve, we're redirecting
        await new Promise(() => {});
    };

    // save error to state, which will trigger AuthErrorAlerter to show an alert
    saveErrorState = async () => {
        const location = this.locationProvider.get();
        const params = new URLSearchParams(location.search);

        const errorCode = params.get('error') || 'invalid_request';
        const message = params.get('error_description') || 'Sorry, sign-in failed';

        this.logger.info('AuthService.extractErrorState()', { errorCode, message });
        this.authErrorState.setState({ errorCode, message });

        // remove querystring and hash
        window.history.replaceState(null, document.title, window.location.pathname);
    };

    getToken = async (): Promise<{ accessToken: string, isNewGuest?: boolean }> => {
        if (this.isAutomatedTestsBypassingLogin()) {
            return new Promise(resolve => { });
        }

        if (this.isOnAuthErrorURL()) {
            this.logger.info('AuthService.getToken() => isOnAuthErrorURL');
            this.saveErrorState();
        }

        if (this.isOnAuthCallbackURL()) {
            this.logger.info('AuthService.getToken() => isOnAuthCallbackURL');
            return await this.loginFromAuthCallbackURL();
        }

        // DEPRECATED: this can be removed, 60 days after AP3080-Passwordless": refresh token for new users
        // was released, which was 2023-01-23. So it can be removed after 2023-03-24. This allows a user that
        // got an "old" token, on the 23rd, prior to that release, up to 60 days (the API max exp we chose to
        // allow), to exchange that old token, for a new current one. Which happens automatically, the first
        // time they login. IOW, as we approach 2023-03-24, there should be few users this is benefitting. On
        // 2023-03-25 it is benefitting no one because it is too late to exhange their token.
        else if (this.hasOldTokenThatNeedsToBeExchanged()){
            this.logger.info('exchanging old token for new one with refresh token');
            return await this.exchangeOldTokenAndReload();
        }

        else if (this.getTokenFromStorage()) {
            this.logger.info('AuthService.getToken() => getTokenFromStorage');
            return await this.loginFromLocalStorage();
        }

        else {
            this.logger.info('AuthService.getToken() => loginAsGuest');
            return await this.loginAsGuest();
        }
    };

    private hasOldTokenThatNeedsToBeExchanged = (): boolean => {
        try {
            const { accessToken, refreshToken } = this.authStorage.auth.get()!;
            return Boolean(accessToken) && !refreshToken; // and assuming the accessToken doesn't have refreshTokenDate (b/c that would be a bug)
        } catch (e) {
            return false;
        }
    };

    private async loginFromLocalStorage(): Promise<{ accessToken: string }> {
        const result = this.getTokenFromStorage();
        if (!result){
            throw new Error('loginFromLocalStorage() is only meant to be called when we know there is a token in storage');
        }
        return { accessToken: result.accessToken };
    }

    private exchangeOldTokenAndReload = async (): Promise<any> => {
        const { accessToken: old_access_token } = this.getTokenFromStorage()!;

        this.logger.info('exchanging old token, for new one with refresh token');
        try{
            const { access_token, refresh_token } = await this.authClient.exchangeOldAccessToken({ access_token: old_access_token });

            this.persistTokens({ access_token, refresh_token });

            this.logger.info('saved new tokens, reloading');
        }
        catch (err: any) {
            if (err.errorCode === '401.3'){
                // then their token has expired and so we need to remove it and reload

                // not really an error worth going to sentry, but makes it easier for us to monitor while we're sensitive to it
                this.logger.error(err);

                this.removeUserFromLocalStorage();
            } else {
                throw err;
            }
        }

        window.location.reload();

        // wait forever, about to reload
        await new Promise(resolve => {});
    };

    getTokenFromStorage = () => {
        try {
            return this.authStorage.auth.get();
        } catch (e) {
            return undefined;
        }
    };

    private persistTokens = ({ access_token, refresh_token }: { access_token: string, refresh_token: string }) => {
        this.authStorage.auth.set({
            accessToken: access_token,
            refreshToken: refresh_token
        });
    };

    private removeUserFromLocalStorage() {
        this.authStorage.auth.remove();
    };

}
