import { Group } from '../ComponentLibrary';
import { DrawerLabel } from './DrawerLabel';

const DemoDrawerLabel: Group & React.FC = () => {
    return <DrawerLabel text="My Label" />;
};

DemoDrawerLabel.componentName = 'Label';

export default DemoDrawerLabel;
