import debounce from 'lodash/debounce';
import { FunctionComponent, useRef, useState, useEffect } from 'react';
import { Group } from '../ComponentLibrary';

const TextStyle: FunctionComponent<any> = ({ children, getElement = (parent: any) => parent.firstChild }) => {
    const parentRef = useRef<any>();
    const [styles, setStyles] = useState<any>();

    useEffect(() => {
        const calculateStyles = debounce(() => {
            if (!parentRef.current) {
                return;
            }

            const element = getElement(parentRef.current);
            const computedStyle: any = window.getComputedStyle(element);
            setStyles({
                fontSize: computedStyle['font-size'],
                lineHeight: computedStyle['line-height'],
                fontWeight: computedStyle['font-weight'],
                color: computedStyle['color'],
            });
        }, 100);
        calculateStyles();
        return window.addEventListener('resize', calculateStyles);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div style={{ marginTop: 20, marginBottom: 20 }} ref={parentRef}>
            {children}
            {Boolean(styles) &&
                <p>
                    <strong>fontSize</strong> {styles.fontSize} <strong>lineHeight</strong> {styles.lineHeight} <strong>fontWeight</strong> {styles.fontWeight} <strong>color</strong> {styles.color}
                </p>
            }
        </div>
    );
};

const DemoTypography: Group & FunctionComponent = () => {
    return (
        <div>
            <TextStyle>
                <h1>Heading 1</h1>
            </TextStyle>
            <TextStyle>
                <h2>Heading 2</h2>
            </TextStyle>
            <TextStyle>
                <h3>Heading 3</h3>
            </TextStyle>
            <TextStyle>
                <h4>Heading 4</h4>
            </TextStyle>
            <TextStyle>
                <h5>Heading 5</h5>
            </TextStyle>
            <TextStyle>
                <h6>Heading 6</h6>
            </TextStyle>
            <TextStyle>
                <p>Paragraph text</p>
            </TextStyle>
        </div>
    );
};

DemoTypography.componentName = 'Typography';

export default DemoTypography;
