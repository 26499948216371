import { IScreenshotablePage } from '../support/screenshots/IScreenshotablePage';
import { ChecklistState } from './ChecklistState';
import { CopySavedChecklistsPage } from './CopySavedChecklistsPage';
import { ISavedChecklist } from './model';
import { SavedChecklistsState } from './SavedChecklistsState';
import { HistoryViewModel } from '../app/HistoryViewModel';
import { MissionsState } from '../missions/model/MissionsState';
import { getWellKnownLocation } from '../support/screenshots/getWellKnownLocation';
import { MissionFeatureCollectionFacadeAdapter } from '@autopylot-internal/tiles-client';

interface IMyPageProps {
    checklists?: ISavedChecklist[];
}

export default class ScreenshotSavedChecklistsPage implements IScreenshotablePage<IMyPageProps> {

    static inject = () => [SavedChecklistsState, MissionsState, MissionFeatureCollectionFacadeAdapter, HistoryViewModel];
    constructor(private checklistsState: SavedChecklistsState, private missions: MissionsState, private mfcAdapter: MissionFeatureCollectionFacadeAdapter, private history: HistoryViewModel) { }

    screenId = 'saved-checklists-copy';

    ComponentUnderTest = CopySavedChecklistsPage;

    permutationToProps = ({ checklists }: IMyPageProps) => {
        const missionId = '1';

        (this.history as any).getCurrentSearchParams = () => new URLSearchParams(`toMissionId=${missionId}`);

        const { address, radiusPolygon } = getWellKnownLocation('uncontrolled');
        this.missions.addMissionToState({
            missionId,
            status: 'active',
            startTime: new Date().toISOString(),
            durationMinutes: 60,
            maxAltitude: 100,
            address,
            radiusPolygon: this.mfcAdapter.adaptFromPersisted(radiusPolygon)
        } as any);

        if (checklists) {
            this.checklistsState.setState({
                loaded: true,
                checklists: checklists.map(c => new ChecklistState<ISavedChecklist>(c))
            });
        }

        return {};
    };

    blockScreenshotTil = async () => {

    };

    permutations: { [key: string]: IMyPageProps } = {
        'loading' : {
        },
        'selecting': {
            checklists: [
                { name: 'Real Estate Marketing', sections: [{ id: 'test', label: 'test', items: [
                    { id: '1', name: 'Item 1', completed: false },
                    { id: '2', name: 'Item 2', completed: false },
                    { id: '3', name: 'Item 3', completed: false }
                ] }] },
                { name: 'Cell Tower Inspection', sections: [{ id: 'test', label: 'test', items: [
                    { id: '1', name: 'Item 1', completed: false },
                    { id: '2', name: 'Item 2', completed: false }
                ] }] }
            ]
        }
    };
}
