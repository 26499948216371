import * as Sentry from '@sentry/browser';
import { FileSystemLogger } from './FileSystemLogger';

const sensitiveKeys = [ 'accesstoken', 'password' ];

export class Logger {
    private listener?: (...args: any[]) => void;

    static inject = () => [FileSystemLogger];

    // set by Network, when offline
    // to avoid circular dependency
    public skipSendingToSentry  = false;

    constructor(private filesystemLogger: FileSystemLogger) {}

    private recursivelyScrubSensitiveKeys = (data: any) => {
        if (!data || typeof data !== 'object') {
            return data;
        }
        const result = { ...data };
        for (const key of Object.keys(result)) {
            if (sensitiveKeys.includes(key.toLowerCase())) {
                result[key] = '***';
            } else {
                result[key] = this.recursivelyScrubSensitiveKeys(data[key]);
            }
        }
        return result;
    };

    private consoleLog(...args: any[]) {
        // "undefined" / "null" in logs can be distracting/confusing
        console.log(...args.filter(a => a !== undefined && a !== null));

        if (this.listener) {
            this.listener(...args);
        }
    }

    private log = (message: string, extras: any, level: string) => {
        try {
            extras = this.recursivelyScrubSensitiveKeys(extras);

            this.consoleLog(message, extras);

            this.filesystemLogger.log({ message, extras, level });
        } catch (ignore) {
            // do nothing so that logging isn't the cause of app failures
        }
    };

    listen = (listener: (...args: any[]) => void) => {
        // just support one listener for now
        this.listener = listener;
    };

    error = (error: any, extras?: any) => {
        try {
            extras = this.recursivelyScrubSensitiveKeys(extras);

            this.consoleLog(error, extras);

            this.filesystemLogger.log({ error, extras });

            const is400 = error && error.status && error.status >= 400 && error.status < 500;

            // don't send 400s to sentry, as thse are expected errors (eg validation)
            // don't send errors that happen while offline, as these are likely a result
            //  of being offline and therefore aren't actionable
            if (!is400 && !this.skipSendingToSentry){
                Sentry.withScope(scope => {
                    if (extras) {
                        Object.keys(extras).forEach(key => {
                            scope.setExtra(key, extras[key]);
                        });
                    }
                    Sentry.captureException(error);
                });
            }
        } catch (ignore) {
            // don't ever want to throw anything from this class!
        }
    };

    info = (message: string, extras?: any) => {
        this.log(message, extras, 'INFO');
    };

    flushFileSystemLogs = async () => {
        await this.filesystemLogger.flush();
    };
}
