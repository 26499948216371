import { Color } from '../components/common';
import { LastAuthorizedRadiusModel } from '../map/mission-pin-and-radius/LastAuthorizedRadiusModel';
import { ICommonMissionState } from '../missions/model/ICommonMissionState';
import { AdvisoryAttentionIcon, AirspaceNotLAANCIcon } from '../components/icons/Icons';
import { isDCFRZ } from './advisories/AirspaceBoundaryAdvisory';

const flightAllowedButNotAdvisedSources = ['national-parks', 'wilderness-areas', 'wildlife-refuges'];

export class PrimaryAdvisoryProvider {
    static inject = () => [LastAuthorizedRadiusModel];
    constructor(private lastAuthorizedModel: LastAuthorizedRadiusModel){}

    getPrimaryAdvisory = (mission: ICommonMissionState, color: Color): { heading: string, description: string, Icon?: any, color?: Color  } => {
        const isCanceled = (mission.getMissionStatus() === 'user-canceled');
        const missionRadius = mission.getMissionRadius() || this.lastAuthorizedModel.lastAuthorizedRadius;

        if (!missionRadius){
            throw new Error('cannot build primary advisory without a radius');
        }

        const composite = missionRadius.getLAANCCompositeAdvisory();

        if (missionRadius.canFly() === 'can-fly') {

            if (composite?.apLAANCStatus === 'authorized') {
                return {
                    heading: 'LAANC Authorized',
                    description: 'Authorization approved, be sure to follow all advisories',
                };
            }
            else if (color === 'advisory-warning'){
                if (missionRadius.getAdvisories().some(a => flightAllowedButNotAdvisedSources.includes(a.source))){
                    return {
                        heading: 'Flight Not Advised',
                        Icon: AdvisoryAttentionIcon,
                        description: 'Review Advisories'
                    };
                }

                return {
                    heading: 'Fly with Caution',
                    description: 'Review Special Advisories'
                };
            }
            else {
                return {
                    heading: 'Clear to Fly',
                    description: 'No Approval Required',
                };
            }

        } else if (missionRadius.canFly() === 'can-fly-with-laanc-authorization') {
            if (missionRadius.getAdvisories().some(a => flightAllowedButNotAdvisedSources.includes(a.source))){
                return {
                    heading: 'Flight Not Advised',
                    Icon: AdvisoryAttentionIcon,
                    description: 'Review Advisories'
                };
            }

            return {
                heading: (mission.isNew() ? 'Fly with Approval' : 'LAANC Pending'),
                description: 'Get Automatic LAANC Authorization to Fly',
            };

        } else if (missionRadius.canFly() === 'cannot-fly') {
            const wasUncontrolled = mission.wasUncontrolled();
            if (wasUncontrolled && isCanceled) {
                return {
                    heading: 'Do Not Fly',
                    description: 'Canceled - No Longer Allowed to Fly Mission',
                };
            } else if (wasUncontrolled) {
                return {
                    heading: 'Do Not Fly',
                    description: 'No Longer Allowed to Fly Mission',
                };
            } else if (composite?.apLAANCStatus === 'user-canceled') {
                return {
                    heading: 'Do Not Fly',
                    description: 'Authorization Canceled (by Pilot)',
                    // TODO: dead?
                    Icon: AirspaceNotLAANCIcon,
                    color: 'danger' as Color
                };
            } else if (['rescind-acked', 'invalidated-acked'].includes(composite?.apLAANCStatus!)) {
                return {
                    heading: 'Do Not Fly',
                    description: 'Authorization Canceled (per FAA)',
                };
            } else if (composite?.apLAANCStatus === 'authorizing-failed') {
                return {
                    heading: 'Do Not Fly',
                    description: 'Not Authorized',
                };
            } else if (['rescinded', 'invalidated'].includes(composite?.apLAANCStatus!)) {
                return {
                    heading: 'Do Not Fly',
                    description: 'Authorization is Not Valid',
                };
            }
            // see ColorProvider.onlyBlockReasonIsLAANDAndAirspace
            else if (missionRadius.canFly() === 'cannot-fly' && color === 'advisory-warning'){
                return {
                    heading: 'Fly with Approval',
                    description: 'Caution: Controlled Airspace'
                };
            }
            else {
                const blockAdvisories = missionRadius.getAdvisories().filter(a => a.advisoryType === 'block');
                const strictlyProhibitedSources = [
                    'nsufr',
                    'parttime-nsufr',
                    'special-use-airspace',
                    'temporary-flight-restrictions'
                ];
                const anyStrictlyProhibited = blockAdvisories.some(a => strictlyProhibitedSources.includes(a.source) || isDCFRZ(a));

                if (anyStrictlyProhibited){
                    return {
                        heading: 'Do Not Fly',
                        description: 'Warning: Flight Restricted Airspace'
                    };
                }

                return {
                    heading: 'Do Not Fly',
                    description: 'LAANC Auto Approval Not Available',
                };
            }
        }
        throw new Error(`non-laanc mission got canFly ${missionRadius.canFly()} which is not supported`);
    };
}
