import moment from 'moment';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useEffect, useRef } from 'react';
import { useSubscription } from '@meraki-internal/state';
import { HourlyWeatherChartViewModel, IMetricCode, METRIC_CONFIGS } from './HourlyWeatherChartViewModel';
import { ChartDaysPicker } from './ChartDaysPicker';
import { ChartMetricTooltip } from './ChartMetricTooltip';
import { ChartMetrics } from './ChartMetrics';
import { Chart, registerables } from 'chart.js';
import { Refresher } from '../../components/refresher/Refresher';
import { Logger } from '../../support/debug/Logger';
import { Spinner } from '../../components/spinner/Spinner';
import { ChartJSProvider } from './ChartJSProvider';
import { ISO8601DateTimeString } from '@autopylot-internal/tiles-client';
import { ICoordinate } from '../../support/model/ICoordinate';

// NOTE: we're using chart.js@3.9.1 b/c chart.js@4.0.0-release and chart.js@4.0.1
// won't start on the physical device (due to use of static on a class)

Chart.register(...registerables);

export interface IHourlyWeatherChartProps {
    activeTime?: ISO8601DateTimeString;
    startTime: ISO8601DateTimeString;
    location: ICoordinate;
    link: { href: string };
    disableChartAnimations?: boolean;
}

export const HourlyWeatherChart: React.FC<IHourlyWeatherChartProps> = ({ activeTime, startTime, location, link, disableChartAnimations }) => {
    const chartRef = useRef<HTMLCanvasElement>(null);
    const vm = useInstance(HourlyWeatherChartViewModel);
    const log = useInstance(Logger);
    const chartJSProvider = useInstance(ChartJSProvider);

    useSubscription(() => vm);

    useEffect(() => {
        vm.init({
            startTime,
            location,
            link,
            activeTime: activeTime || moment().startOf('hour').toISOString()
        });
    }, [activeTime, link, location, startTime, vm]);

    const loaded = Boolean(vm.state.allWeather);

    useEffect(() => {
        let _chart: Chart | undefined = undefined;
        let _chartReady = false;

        if (!chartRef.current) {
            return;
        }

        if (!loaded) {
            return;
        }

        _chart = new Chart(chartRef.current, {
            type: 'line',
            data: {
                labels: [],
                datasets: []
            },

            // chart-js only exposes events to plugins, so we created this plugin to enable us to
            // synchronize the chart on first render (page load) and when the selected metric changes
            plugins: [{
                id: 'eventHandler',
                afterRender: () => {
                    if (!_chartReady && _chart && _chart.getActiveElements().length > 0) {
                        _chartReady = true;
                        chartJSProvider.activeElementObserver!.synchronize();
                    }
                },
                afterDatasetUpdate: () => {
                    _chart?.setActiveElements([]);
                    _chartReady = false;
                }
            }],

            options: {
                maintainAspectRatio: false,

                animation : disableChartAnimations ? false : undefined,

                layout: {
                    padding: 10
                },

                interaction: {
                    // use nearest so don't have to be very precise (especially important for touch)
                    mode: 'nearest',

                    // hover doesn't need to be exact for tooltip
                    intersect: false,

                    // find nearest on x axis (as opposed to point, eg when multi-series)
                    axis: 'x'
                },

                plugins: {
                    // don't show the legend because our panel below is effectively our legend
                    legend: {
                        display: false
                    },

                    // don't show the tooltip b/c we're going to do out own
                    tooltip: {
                        enabled: false
                    }
                },

                scales: {
                    x: {
                        // so the last point in the line doesn't get cut off
                        offset: true,

                        ticks: {
                            font: {
                                size: 10,
                                family: 'avenir-next'
                            },

                            // show 4 ticks, 12 am, 6 am, 12pm 6pm
                            maxTicksLimit: 4,

                            // don't rotate the tick label
                            minRotation: 0,
                            maxRotation: 0,
                        },

                        grid: {
                            // don't draw a line from the bottom border to the x axis label
                            drawTicks: false
                        }
                    },

                    yPercentage: {
                        position: 'right',
                        display: 'auto',
                        ticks: {
                            font: { size: 10, family: 'avenir-next' },
                            maxTicksLimit: 5,
                        },
                        min: 0,
                        max: 100,
                    },

                    // define a y-axis for all non-percentage metrics
                    // WARNING: avoid creating > 5 axes here, or chart-js will truncate the tick labels
                    ...Object.entries(METRIC_CONFIGS)
                        .filter(([, config]) => !config.isPercentage)
                        .map(([metricCode]) => metricCode as IMetricCode)
                        .reduce((axes, metricCode) => ({
                            ...axes,
                            [`y${metricCode}`]: {
                                position: 'right',
                                display: 'auto',
                                ticks: {
                                    font: { size: 9, family: 'avenir-next' },
                                    maxTicksLimit: 5
                                },
                                ...vm.getMetricRange(metricCode)
                            }
                        }), {})
                }
            }
        });

        chartJSProvider.setChart(_chart);

        chartJSProvider.activeElementObserver!.subscribe(({ active }) => {
            if (active){
                const activeDataPoint = vm.state.activeDayWeather![active.index];
                vm.setActiveTime(activeDataPoint.forecastStart);
            }
        });

        const vmUnSub = vm.subscribe(() => {
            // TODO: clean up (eg refactor to methods)
            if (vm.state.activeDayWeather) {
                // TODO: considering using a weather version counter instead
                const newData = [{
                    label: vm.getMetricLabel(),
                    data: vm.getMetricData(),
                    type: vm.getChartType(),
                    yAxisID: METRIC_CONFIGS[vm.state.selectedMetricCode].isPercentage ? 'yPercentage' : `y${vm.state.selectedMetricCode}`,

                    // points
                    backgroundColor: getComputedStyle(document.body).getPropertyValue('--ion-color-primary'),

                    // line
                    borderColor: getComputedStyle(document.body).getPropertyValue('--ion-color-primary'),
                    borderWidth: 1.5,

                    // hides the points on the line
                    pointRadius: 0,

                    // hide the active point (b/c we show dot as part of our tooltip)
                    pointHoverRadius: 0,
                }];

                if (JSON.stringify(newData) !== JSON.stringify(_chart!.data.datasets)) {
                    _chart!.data.labels = vm.state.activeDayWeather.map(weather => vm.formatTime(weather.forecastStart,'ha'));
                    _chart!.data.datasets = newData;
                    _chart!.update();
                }

                // TODO: ideally wouldn't need to let activeElementObserver know
                // but chartJSProvider doesn't let us know its data changed...
                // we should refactor so that the above change is at least going through chartJSProvider
                chartJSProvider.activeElementObserver!.synchronize();
            }

            // if active in vm, is not active on chart, make it so
            const elements = _chart!.getActiveElements();
            const activeIndexOnChart = elements.length === 0 ? -1 : elements[0].index;
            const activeIndexInVM = vm.getActiveIndex();

            if (activeIndexOnChart !== activeIndexInVM && activeIndexInVM >= 0) {
                _chart!.setActiveElements([{ datasetIndex: 0, index: activeIndexInVM }]);
            }
            else if (activeIndexOnChart !== activeIndexInVM && activeIndexInVM === -1) {
                _chart!.setActiveElements([]);
            }
        });

        // TODO: need a single class for managing the single chart js instance

        // without this, when launching the app on a physcial device
        // no chart shows up (the canvas likely is there, but with width / height of 0)
        const observer = new ResizeObserver(() => {
            setTimeout(() => {
                if (_chart){
                    _chart.draw();
                    chartJSProvider.activeElementObserver?.synchronize();
                }
            });
        });
        observer.observe(chartRef.current);

        return () => {
            observer.disconnect();
            vmUnSub();
            chartJSProvider.disposeChart();
            _chart = undefined;
        };
    }, [chartRef, vm, chartJSProvider, disableChartAnimations, loaded]);

    const forecast = vm.getAllDataForActiveHour()!;

    return (
        <div style={{position: 'relative', height: '100%'}}>
            <Refresher onRefresh={async e => {
                try{
                    await vm.refresh();
                }
                catch(err){
                    log.error(`failed to refresh weather. User will see weather before refresh. ${(err as Error).toString()}`);
                }
                finally {
                    e.detail.complete();
                }
            }} />
            {!vm.state.allWeather && (
                <Spinner size="medium" centered />
            )}

            <div style={{margin: '5px 10px', paddingBottom: 15, height: '100%', display: 'flex', flexDirection: 'column'}}>

                <ChartDaysPicker />

                <div style={{position: 'relative', flexGrow: 1, flexShrink: 0, maxHeight: '45%'}}>
                    {chartJSProvider.activeElementObserver && (
                        <ChartMetricTooltip />
                    )}

                    {/* NOTE... minimize messing with chart styles
                            eg, use layout: { padding } above instead
                            to make it easier for ChartTooltipLine to mirror the canvas size, and position
                    */}
                    <canvas ref={chartRef} style={{ position: 'absolute', visibility: vm.state.allWeather ? 'visible': 'hidden' }} />
                </div>

                {vm.state.activeTime && vm.state.activeDayWeather && (
                    // TODO: canvas is ending up bigger than its container, hack to work around
                    <div style={{ marginTop: 75 }}>
                        <ChartMetrics metricCode={vm.state.selectedMetricCode} forecast={forecast} />
                    </div>
                )}

            </div>
        </div>
    );
};
