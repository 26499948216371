import moment from 'moment';
import { IonCheckbox, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonReorder, IonReorderGroup, IonText, ItemReorderEventDetail } from '@ionic/react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useSubscription } from '@meraki-internal/state';
import { AddIcon, CircleIcon, DeleteIcon, NotificationIcon } from '../components/icons/Icons';
import { DevSettings } from '../support/DevSettings';
import { ChecklistSectionState } from './ChecklistSectionState';
import { IChecklistItem } from './model';

export const ChecklistSection: React.FC<{vm: ChecklistSectionState, canCompleteItems: boolean, onAdd: () => void, onEdit: (item: IChecklistItem) => void, onForceNotification: (item: IChecklistItem) => void}> = ({vm, canCompleteItems, onAdd, onEdit, onForceNotification}) => {

    const devSettings = useInstance(DevSettings);

    useSubscription(() => vm);

    const handleReorder = (event: CustomEvent<ItemReorderEventDetail>) => {
        vm.moveItem(event.detail.from, event.detail.to);
        event.detail.complete();
    };

    const getNotificationColor = (item: IChecklistItem) => {
        const { notification } = item;
        // if just added and not yet updated with materialized time, return gray to mean "unknown"
        if (!notification?.at) {
            return 'medium';
        }
        // turn it red 15 mins before, in case push notification arrives a bit early
        if (!item.completed && moment(notification.at).subtract(15, 'm').isBefore(moment())) {
            return 'danger';
        }
        // otherwise, the default color is tertiary
        return 'tertiary';
    };

    return (
        <IonList style={{margin: '0 -15px'}} lines="none">
            <IonReorderGroup disabled={false} onIonItemReorder={handleReorder}>
                {vm.state.items.map((item) => (
                    <IonItemSliding key={`item-${item.id}`}>
                        <IonItem data-type="checklist-item" data-id={`item-${item.id}`}>
                            <div slot="start">
                                {canCompleteItems &&
                                    <IonCheckbox checked={item.completed} onIonChange={() => vm.toggleItem(item.id)} />
                                }
                                {!canCompleteItems &&
                                    <div style={{display: 'flex', color: 'var(--ion-color-light-shade)'}}>
                                        <CircleIcon size="large" />
                                    </div>
                                }
                            </div>

                            <IonLabel className="ion-text-wrap" onClick={() => onEdit(item)} color={!item.name ? 'medium' : undefined}>
                                <div data-type="item-name" style={item.completed ? {textDecoration: 'line-through'} : undefined}>
                                    {item.name || '(Unnamed)'}
                                </div>
                                {item.notification &&
                                    <IonText data-type="item-notification" color={getNotificationColor(item)} style={{display: 'flex', alignItems: 'center', gap: 3, fontSize: 14}}>
                                        <NotificationIcon />
                                        {item.notification.label}
                                    </IonText>
                                }
                            </IonLabel>
                            <IonReorder slot="end" />
                        </IonItem>
                        <IonItemOptions>
                            <IonItemOption onClick={() => vm.deleteItem(item.id)} style={{margin: 'auto 0', height: 40}} color="danger"><DeleteIcon /></IonItemOption>
                            {devSettings.allowForceNotification && item.notification &&
                                <IonItemOption onClick={() => onForceNotification(item)} style={{margin: 'auto 0', height: 40}} color="warning"><NotificationIcon /></IonItemOption>
                            }
                        </IonItemOptions>
                    </IonItemSliding>
                ))}
            </IonReorderGroup>
            <IonItem data-type="add-checklist-item" onClick={onAdd}>
                <AddIcon slot="start" color="primary" />
                <IonText color="primary">Add a new item</IonText>
            </IonItem>
        </IonList>
    );
};
