import { Container } from '@meraki-internal/react-dependency-injection';
import * as Sentry  from '@sentry/capacitor';
import * as SentryReact  from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { getEnvironment, IEnvironment } from '../../app/config/EnvConfiguration';
import { DevSettings } from '../DevSettings';
const packageVersion = require('../../../package.json').version;

// instantiate directly as we don't have ioc yet
// this is at parse time / pre-ioc so that errors early in the stack can go to sentry
const devSettings = new Container().get(DevSettings);
let environment = getEnvironment(devSettings);

// Set the sentry context so it's attached to logged data
// This is a no-op in a dev build as we don't initialize sentry
export let setSentryContext = ({ user, appInfo, env }: { user: any, appInfo: any, env: IEnvironment }) => {};

const errorIgnoredFromSentry = (error: any, _user: any): boolean => {
    try{
        if (!error) { // if there is no error, then there is nothing to send to Sentry
            return true;
        }

        // this is thrown sometimes from inside mapbox
        if (error.toString() === 'AbortError: Fetch is aborted') {
            return true;
        }

        // as far as we can tell, the only way to get these errorCodes is
        // if the request is aborted (they are navigating away) or a CORS
        // misconfiguration. The aborted request is too noisy (false positives)
        // and a CORS misconfiguration will fail our tests, so we're going to ignore
        if ([ 'fetch.failed', 'fetch.missingBody' ].includes(error.errorCode)){
            return true;
        }

        // if it looks like failed to fetch b/c we're offline
        if (error.toString().includes('TypeError: Failed to fetch') || error.toString().includes('TypeError Failed to fetch') || error.toString().includes('fetch.failed')){
            return true;
        }

        // we don't support offline, so if we know we're offline, then don't report the error as it
        // won't be actionable. Some will still get through though (eg flaky internet)
        if (!window.navigator.onLine){
            return true;
        }

    } catch (err) {
        console.log('failed to evaluate errorIgnoredFromSentry, assuming not to ignore', err);
    }

    return false;
};

const transactionIgnoredFromSentry = (event: SentryReact.Event): boolean => {
    try {
        // help keep transactions below sentry quota by filtering events we won't need
        if (String(event.user?.id)?.startsWith('auto_test')) {
            return true;
        }
        if (event.request?.url?.includes('?screenshots')) {
            return true;
        }

    } catch (err) {
        console.log('failed to evaluate transactionIgnoredFromSentry, assuming not to ignore', err);
    }

    return false;
};

if (process.env.NODE_ENV === 'production') {
    console.log(`initializing sentry for "${environment}" env`);
    Sentry.init({
        release: `autopylot-app@${packageVersion}.${(process.env.REACT_APP_SHA || '').substring(0,7)}`,
        dsn: 'https://14a23302383a486aaeac6685e3826be2@o1283813.ingest.sentry.io/6493777',
        environment,

        // Ideally we'd have out of memory (OOM) tracking enabled, but it incorrectly alerts every time we login or logout.
        // This is largely due to the fact that we navigate to auth in the main webview (see notes on why we did this in
        // https://docs.google.com/document/d/1xk1rmlkMRl4krHzmYbI-YAMOXrMuQtRxJ1NrQ0d8B54). The sentry OOM determination
        // does not actually check available memory, but instead uses heuristics at app start based on whether the app
        // previously exited cleanly (see https://docs.sentry.io/platforms/apple/guides/ios/configuration/out-of-memory/).
        // It appears to incorrectly assume the entire app crashed if the react page unloads abruptly (as happens when we
        // redirect to auth). So until we next revist our auth flow, the workaround is to disable this setting.
        enableOutOfMemoryTracking: false,

    }, (options: any) => {
        SentryReact.init({
            ...options,
            tracesSampleRate: 1.0,
            integrations: [new Integrations.BrowserTracing() as any],

            beforeSend: (sentryEvent, hint) => {
                if (errorIgnoredFromSentry(hint?.originalException, sentryEvent.user)) {
                    return null;
                }
                return sentryEvent;
            },

            beforeSendTransaction: (sentryEvent) => {
                if (transactionIgnoredFromSentry(sentryEvent)) {
                    return null;
                }

                // set the environment on each event because it may have changed since init
                // (if we switched to faa-testing)
                sentryEvent.environment = environment;

                return sentryEvent;
            }
        });
    });

    setSentryContext = ({ user, appInfo, env }) => {
        console.log('setting sentry context', user, appInfo);

        Sentry.setUser({ id: user.userId, email: user.email });

        // sentry provides all app info automatically except the ionic update info
        // so we provide that in a separate section
        const appSha = process.env.REACT_APP_SHA || '';
        Sentry.setContext('Live Update', { appSha });

        // override the global environment derived from the url
        // to support switching environments (to faa-testing) after login
        environment = env;
    };
}
