import { cloneElement } from 'react';
import flattenChildren from 'react-keyed-flatten-children';
import { IonItem, IonLabel, IonList } from '@ionic/react';

export type InputType = 'date' | 'number' | 'tel' | 'text';

export type InputMode = 'tel' | 'text' | 'numeric';

export interface FieldInputProps {
    required?: boolean;
    readonly?: boolean;
    bordered?: boolean;
}

export interface FieldProps {
    label?: string;
    required?: boolean;
    readonly?: boolean;
    bordered?: boolean;
    dataSet?: {[key: string]: string};
}

export const Field: React.FC<FieldProps> = ({ label, required, readonly, bordered, dataSet = {}, children }) => {

    // build data attributes, e.g. dataSet={id:'test'} -> data-id="test"
    const dataAttributes = Object.keys(dataSet).reduce((map, k) => ({...map, [`data-${k}`]: dataSet[k]}), {});

    const labelPosition = (readonly ? 'stacked' : 'floating');
    const itemStyle = bordered ? { '--min-height': '55px', '--border-color': 'rgb(194,200,208)', '--border-width': '1px', '--border-style': 'solid', '--border-radius': '4px', margin: '20px 0px', overflow: 'visible' } : { '--padding-end': '20px' };
    const labelStyle = bordered ? { marginTop: -10, background: 'white', paddingLeft: 5, paddingRight: 5, width: 'fit-content' } : undefined;
    const lines = bordered || readonly ? 'none' : undefined;

    // render children with some properties passed down from this Field wrapper
    const renderChildren = (_children: any) => {
        return flattenChildren(children).map((child: any) => {
            // don't attempt to copy props to html elements (type "<a>", "<div>", "<span>" etc) or we get a react warning
            const copyProps = typeof child.type !== 'string' ? { required, readonly, bordered } : {};
            return cloneElement(child, copyProps);
        });
    };

    return (
        <IonItem style={itemStyle} lines={lines} shape="round" {...dataAttributes} >
            {label &&
                <IonLabel style={labelStyle} color="medium" position={labelPosition}>{label}{required ? '*' : ''}</IonLabel>
            }
            {renderChildren(children)}
        </IonItem>
    );
};

export const FieldList: React.FC = ({ children }) => {
    return (
        <IonList>
            {children}
        </IonList>
    );
};
