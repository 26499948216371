export const WeatherError: React.FC<{error: any}> = ({ error }) => {
    const errorMessage = error.displayMessage || 'Sorry, something went wrong';
    const helpMessage = (error.errorCode === 'forecast.unavailable' ? '' : 'Pull down on the page to refresh');

    return (
        <div style={{width: '100%', textAlign: 'center'}}>
            <h4>{errorMessage}</h4>
            <small>{helpMessage}</small>
        </div>
    );
};
