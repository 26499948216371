import { ISO8601DateTimeString } from '@autopylot-internal/tiles-client';
import { State } from '@meraki-internal/state';
import moment from 'moment';
import { DefaultMissionTimeCalculator } from '../../missions/model/DefaultMissionTimeCalculator';

interface ApMapboxStylerTimeStateProps {
    startTime: ISO8601DateTimeString;
    endTime: ISO8601DateTimeString;
}
export class ApMapboxStylerTimeState extends State<ApMapboxStylerTimeStateProps> {
    static inject = () => [DefaultMissionTimeCalculator];

    constructor(private defaultTimeCalculator: DefaultMissionTimeCalculator) {
        // to avoid duplicating the correct logic, we're putting in garbage here in the super
        // then calling reset
        super({ startTime: new Date().toISOString(), endTime: new Date().toISOString() });
        this.reset();
    }

    public reset = () => {
        const { startTime } = this.defaultTimeCalculator.calculate();
        const endTime = moment(startTime).add(3, 'days').toISOString();
        this.setState({ startTime, endTime });
    };

    public setTime = ({ startTime, endTime }: ApMapboxStylerTimeStateProps) => {
        this.setState({ startTime, endTime });
    };
}
