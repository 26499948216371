import { IAuthenticationClientRedirecter } from '@autopylot-internal/autopylot-api-client';
import { Device } from '../support/Device';
import { Browser } from '@capacitor/browser';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Logger } from '../support/debug/Logger';
import { EnvConfiguration } from '../app/config/EnvConfiguration';

export class BrowserAuthenticationClientRedirecter implements IAuthenticationClientRedirecter {
    static inject = () => [EnvConfiguration, Device, Logger];
    constructor(private config: EnvConfiguration, private device: Device, private log: Logger){}

    private redirectWithIOSASWebAuthenticationSession = async ({ urlWithoutQueryParams, queryParams }: { urlWithoutQueryParams: string, queryParams: URLSearchParams }): Promise<boolean> => {
        try {
            const appScheme = 'io.autopylot.app';

            const redirectURIHash = queryParams.get('redirect_uri')!.split('#')[1]!;

            queryParams.set('redirect_uri', `${appScheme}://#${redirectURIHash}`);

            const urlWithAppSchemeRedirect = `${urlWithoutQueryParams}?${queryParams.toString()}`;

            this.log.info('ASWebAuthSession.start', { urlWithAppSchemeRedirect });

            const callbackUrl: string = await new Promise((resolve, reject) => (window as any).plugins.ASWebAuthSession.start(appScheme, urlWithAppSchemeRedirect, resolve, reject));
            const hash = decodeURI(callbackUrl.split('io.autopylot.app://')[1]);

            this.log.info('ASWebAuthSession response', { callbackUrl, hash });

            // flush logs before reloading app so we don't lose anything
            await this.log.flushFileSystemLogs();

            // in the unexpected case that the url is not the expected format,
            // ignore it and let the reload take care of the auth state
            if (hash) {
                window.location.hash = hash;
            }

            // warning: make sure there is no async code between setting hash and reloading
            // (otherwise the hash change will be detected by the router and discarded)
            window.location.reload();

            // we could return true here to indicate success to the caller,
            // but we are reloading the app, so best to never resolve
            await new Promise(resolve  => {});

            // never get here, but need to make ts happy
            return true;

        } catch (e: any) {
            // unfortunately, e is always "error" so we can't determine what went wrong,
            // most likely auth flow was aborted by the user, so just do nothing
            return false;
        }
    };

    redirectWithInAppBrowser = async ({ urlWithoutQueryParams, queryParams }: { urlWithoutQueryParams: string, queryParams: URLSearchParams }) => {
        // the app will see this host, close the in app browser and trigger 'appUrlOpen'
        // (this also needs to be recognized by the api as a valid callback)
        const redirectBaseUrl = (this.config.ENV === 'live' ? 'https://app.autopylot.io' : 'https://app.staging.autopylot.io');

        const redirectURIHash = queryParams.get('redirect_uri')!.split('#')[1]!;

        queryParams.set('redirect_uri', `${redirectBaseUrl}/#${redirectURIHash}`);

        const urlWithRedirect = `${urlWithoutQueryParams}?${queryParams.toString()}`;

        return await new Promise<boolean>(resolve => {
            const appUrlListenerPromise = App.addListener('appUrlOpen', async (event: URLOpenListenerEvent) => {
                // auth flow successfully completed
                // example url: https://app.autopylot.io/#/missions, path = #/missions
                const path = event.url.split('.autopylot.io/').pop();

                this.log.info('appUrlOpen', { eventUrl: event.url, path });

                // flush logs before reloading app so we don't lose anything
                await this.log.flushFileSystemLogs();

                // in the unexpected case that the url is not the expected format,
                // ignore it and let the reload take care of the auth state
                if (path) {
                    window.location.hash = path;
                }

                // warning: make sure there is no async code between setting hash and reloading
                // (otherwise the hash change will be detected by the router and discarded)
                window.location.reload();

                // we could resolve(true) here to indicate success to the caller,
                // but we are reloading the app, so best to never resolve
            });

            const browserListenerPromise = Browser.addListener('browserFinished', () => {
                // user aborted without completing sign-in
                resolve(false);

                appUrlListenerPromise.then(listener => listener.remove);
                browserListenerPromise.then(listener => listener.remove);
            });

            this.log.info('Browser.open', { urlWithRedirect });

            Browser.open({ url: urlWithRedirect, windowName: '_self' });
        });
    };

    redirect = async (url: string): Promise<boolean> => {
        this.log.info('BrowserAuthenticationClientRedirecter.redirect', { url });

        const [ urlWithoutQueryParams, queryParamsString] = url.split('?');
        const queryParams = new URLSearchParams(queryParamsString);

        const isRedirectingToAuth0ForLogin = url.includes('autopylot.io/v1/oauth/authorize');

        if (this.device.platform === 'ios' && isRedirectingToAuth0ForLogin){
            return await this.redirectWithIOSASWebAuthenticationSession({ urlWithoutQueryParams, queryParams });
        }
        else if (this.device.platform === 'android' && isRedirectingToAuth0ForLogin){
            return await this.redirectWithInAppBrowser({ urlWithoutQueryParams, queryParams });
        }
        else {
            window.location.href = url;
            // block indefinitely (so that further code can't run while we're trying to redirect)
            return await new Promise(resolve  => {});
        }
    };
}
