import moment, { Moment } from 'moment';

export class Clock {
    now = () => Date.now();

    // IOC.get('<Class>').clock.mock(time => time.add(15, 'days'))
    mock = (del: (time: Moment) => Moment) => {
        const nowEpoch = del(moment()).valueOf();
        this.now = () => nowEpoch;
    };
};
