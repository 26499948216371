import { Group } from '../ComponentLibrary';
import { WeatherConditionIcon } from '../../weather/components/WeatherConditionIcon';
import { weatherConditions } from '../../weather/model/IWeatherCondition';
import { IonItem, IonLabel, IonList } from '@ionic/react';

const IconCol: React.FC = ({children}) => <div style={{marginLeft: 10}}>{children}</div>;

const DemoWeatherConditionIcon: Group & React.FC = () => {
    return (
        <IonList>
            <IonItem key="headings">
                <IonLabel><b>Condition</b></IonLabel>
                <IconCol><b>Day</b></IconCol>
                <IconCol><b>Night</b></IconCol>
            </IonItem>

            {weatherConditions.map(conditionCode =>
                <IonItem key={conditionCode}>
                    <IonLabel>{conditionCode}</IonLabel>
                    <IconCol><WeatherConditionIcon conditionCode={conditionCode} isDaylight={true} size="large" /></IconCol>
                    <IconCol><WeatherConditionIcon conditionCode={conditionCode} isDaylight={false} size="large" /></IconCol>
                </IonItem>
            )}
        </IonList>
    );
};

DemoWeatherConditionIcon.componentName = 'WeatherConditionIcon';

export default DemoWeatherConditionIcon;
