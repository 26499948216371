import { Page } from '../components/page/Page';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useEffect, useState } from 'react';
import { MissionDetails } from './components/MissionDetails';
import { useEditableMissionState } from '../missions/model/useEditableMissionState';
import { HistoryViewModel } from '../app/HistoryViewModel';
import { AuthStatusAlert } from './components/AuthStatusAlert';
import { Blur } from '../components/spinner/Blur';
import { Refresher, RefresherEvent } from '../components/refresher/Refresher';
import { AlertPresenter } from '../app/AlertBinder';
import { usePageView } from '../support/tracking/usePageView';

export const MissionDetailsPage = ({ missionId, cancelOnMount }: { missionId: string, cancelOnMount?: boolean }) => {
    usePageView('Mission Details');

    const history = useInstance(HistoryViewModel);

    const needsAuthAlert = (history.popSearchParam({ key: 'alert' }) === 'true');

    const [waiting, setWaiting] = useState(needsAuthAlert);
    const { showAlert } = useInstance(AuthStatusAlert);

    const alert = useInstance(AlertPresenter);

    const missionVM = useEditableMissionState(missionId);

    useEffect(() => {
        if (missionVM && waiting) {
            if (missionVM.hasLAANC() && !missionVM.isFullyAuthorized() && !missionVM.isNotValid()) {
                // if pending, show spinner with blur for a while, then show alert
                let counter = 0;
                const interval = setInterval(async () => {
                    counter++;
                    try {
                        await missionVM.refresh();
                    } catch (e: any) {
                        console.error('Failed calling mission.refresh()', e);
                        counter = 100; // abort
                    }
                    if (counter >= 6 || missionVM.isFullyAuthorized() || missionVM.isNotValid()) {
                        clearInterval(interval);
                        setWaiting(false);
                        showAlert(missionVM);
                    }
                }, 2000);
            } else {
                // otherwise, show alert immediately
                setWaiting(false);
                showAlert(missionVM);
            }
        };
    }, [missionVM, waiting, showAlert]);

    const onRefresh = async (event: RefresherEvent) => {
        try{
            await missionVM!.refresh();
        }
        catch (err){
            alert.showAndLogError(err);
        }

        // stop the spinner
        event.detail.complete();
    };

    const onBack = () => {
        // use replace() instead of back() here because when you just created a new mission
        // the previous page is /map, and we always want to go back to the missions list
        history.replace('/missions');
    };

    return (
        <Page title="Mission Details" onBack={onBack} onAction={missionVM?.showOtherActions}>
            <>
                {missionVM && (
                    <>
                        <Refresher onRefresh={onRefresh} />
                        <Blur active={waiting}>
                            <MissionDetails mission={missionVM} cancelOnMount={cancelOnMount} />
                        </Blur>
                    </>
                )}
            </>
        </Page>
    );
};
