import { IScreenshotablePage } from '../support/screenshots/IScreenshotablePage';
import { MapPage } from './MapPage';
import { NewMissionState } from '../missions/model/NewMissionState';
import { ICoordinate } from '../support/model/ICoordinate';
import { MapViewModel } from './model/MapViewModel';
import { TileUrlProvider } from '@autopylot-internal/tiles-client';
import { IAddress } from '../support/model/IAddress';
import { OperatorState } from '../profile/OperatorState';
import { FAAVersionAlertManager, IAppVersionStatus } from '../app/FAAVersionAlertManager';
import { AppVersionUpdateManager } from '../app/app-updates/AppVersionUpdateManager';
import { TrackingService } from '../support/tracking/TrackingService';
import { IOperator } from '../profile/IOperator';
import { EnableAndBindMapForScreenshots } from './EnableAndBindMapForScreenshots';
import { ScreenshotHelper } from '../support/screenshots/ScreenshotHelper';
import { MissionPinAndRadiusOnMapViewModel } from './mission-pin-and-radius/MissionPinAndRadiusOnMapViewModel';

interface IMyPageProps {
    search?: string;
    operator?: Partial<IOperator>,
    newMission?: { address: IAddress, point: ICoordinate, radiusFeet?: number, maxAltitude?: number, startTime?: string, durationMinutes?: number };
    tilesAreStale?: boolean;
    showAlert?: boolean;
    faaIsDown?: boolean;
    appVersionStatus?: IAppVersionStatus;
}

export default class ScreenshotMapPage implements IScreenshotablePage<IMyPageProps> {
    static inject = () => [
        MapViewModel,
        FAAVersionAlertManager,
        AppVersionUpdateManager,
        TileUrlProvider,
        TrackingService,
        OperatorState,
        ScreenshotHelper,
        MissionPinAndRadiusOnMapViewModel
    ];

    constructor(
        private mapViewModel: MapViewModel,
        private versionAlertManager: FAAVersionAlertManager,
        private updateAlertManager: AppVersionUpdateManager,
        private tileUrlProvider: TileUrlProvider,
        private tracker: TrackingService,
        private operatorState: OperatorState,
        private helper: ScreenshotHelper,
        private pinAndRadius: MissionPinAndRadiusOnMapViewModel
    ) { }

    screenId = 'new-mission-step-0';

    ComponentUnderTest = MapPage;

    WrapWith = EnableAndBindMapForScreenshots;

    permutationToProps = async ({ newMission, operator, tilesAreStale = false, faaIsDown = false, appVersionStatus = 'active', showAlert }: IMyPageProps) => {
        this.operatorState.setState({
            firstName: 'Freddy',
            lastName: 'Flyer',
            phone: '802-123-1234',
            email: 'freddy.flyer@gmail.com',
            certificationType: 'part-107',
            agreedToFAAPrivacyStatement: true,
            agreedToLAANCStatement: true,
            agreedToTermsOfService: true,
            ...operator
        });

        let mission: NewMissionState | undefined;

        if (tilesAreStale || faaIsDown){
            this.tileUrlProvider.getFAAStatus = async () => ({ tilesAreStale, faaIsDown, appReleases: [] });
            this.versionAlertManager.listen({ showAlert: Boolean(showAlert) });
        }

        if (appVersionStatus !== 'active') {
            this.updateAlertManager._showAlert(appVersionStatus);
        }

        if (newMission) {
            mission = new NewMissionState({
                onSave: async () => {
                },
                getConcurrentLAANCMissions: () => []
            });

            // TODO: friction from property injection
            mission.mapViewModel = this.mapViewModel;
            mission.operator = this.operatorState;
            mission.versionAlertManager = this.versionAlertManager;
            mission.tracker = this.tracker;
            mission.pinAndRadius = this.pinAndRadius;

            mission.setAddress(newMission.address).setCenter(newMission.point);

            if (newMission.radiusFeet) {
                mission.setRadiusFeet(newMission.radiusFeet);
            }

            if (newMission.maxAltitude) {
                mission.setMaxAltitude(newMission.maxAltitude);
            }

            if (newMission.startTime) {
                mission.setStartTime(newMission.startTime, 'Mission Area and Time');
            }

            if (newMission.durationMinutes) {
                mission.setDurationMinutes(newMission.durationMinutes);
            }
        }

        return { screenshotConfig: { mission } };
    };

    blockScreenshotTil = async ({ search, newMission, showAlert }: IMyPageProps) => {
        await this.mapViewModel.waitForIdle();

        if (search) {
            const input: any = await this.helper.waitForElement('ion-searchbar .searchbar-input');
            input.click();
            input.value = search;
            input.dispatchEvent(new Event('input'));
            await new Promise(res => setTimeout(res, 1000));
        }

        if (newMission){
            await this.helper.waitForElement('[data-type="flight-status"]');
        }

        if (showAlert){
            // then we're expecting an alert, so wait til it exists
            await this.helper.waitForElement('ion-alert');
        }

        // NOTE: avoid .scrollIntoView() here, as it unexpectedly causes the floating buttons to move up, showing drawer content below them
    };

    permutations: {[key: string]: IMyPageProps} = {
        'certification-type-alert': {
            operator: { certificationType: undefined }
        },
        'blank-address': {
        },
        'auto-complete': {
            search: '123 coyote'
        },
        'no-results-found': {
            search: 'aasdf 4fjsd'
        },
        'outage-tiles-are-stale-alert': {
            tilesAreStale: true,
            showAlert: true
        },
        'outage-faa-is-down-alert': {
            faaIsDown: true,
            showAlert: true
        },
        'should-update-app-alert': {
            appVersionStatus: 'expiring',
            showAlert: true
        },
        'must-update-app-alert': {
            appVersionStatus: 'expired',
            showAlert: true
        }
    };
}
