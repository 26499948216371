import { SplashScreen } from '@capacitor/splash-screen';
import { DeviceStatusBar } from '../support/DeviceStatusBar';
import { useEffect } from 'react';

export const DismissSplashScreen: React.FC<{ statusBar?: DeviceStatusBar, children: any }> = ({ statusBar, children }) => {

    // this component is used both inside and outside AppContainer, so we can't get statusBar from ioc,
    // instead it is passed in when we are inside AppContainer, and undefined when we are outside

    useEffect(() => {
        setTimeout(async () => {

            await SplashScreen.hide();

            // on android 12+, status bar is blocked by the splash screen,
            // and needs a nudge to repaint itself
            if (statusBar) {
                await statusBar.repaintAndroid();
            }

        }, 100);
    }, [statusBar]);

    return children;
};
