import { IAdvisoryPropertiesV3 } from '@autopylot-internal/tiles-client/dist/model/advisories/IAdvisoryPropertiesV3';
import { SecondaryWarningAdvisory } from '../SecondaryAdvisory';

export interface ICompositeStadiumsAdvisoryProperties extends IAdvisoryPropertiesV3 {
    advisoryType: 'advise';
    source: 'stadiums+composite';
    stadiumNames: string[];
}

export const StadiumsAdvisory = (props: ICompositeStadiumsAdvisoryProperties) => {
    return (
        <SecondaryWarningAdvisory header="Stadium Notice" advisoryType={props.advisoryType}>
            <>
                <p>
                    Flight operations within <strong>3&nbsp;NM</strong> of an FAA-designated stadium are restricted
                    from <strong>1&nbsp;hour before</strong> the planned start time until <strong>1&nbsp;hour after</strong> the end of any qualifying event.
                    Check the stadium schedule{props.stadiumNames.length > 1 ? 's' : ''} (<strong>{props.stadiumNames.join(', ')}</strong>) prior to any flight operations.
                </p>
                <p>
                    <a href="https://www.faa.gov/uas/resources/community_engagement/no_drone_zone/stadiums" target="_blank">
                        Drones Are Prohibited In and Around Stadiums
                    </a>
                </p>
            </>
        </SecondaryWarningAdvisory>
    );
};
