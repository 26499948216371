import { StandalonePage } from './StandalonePage';
import { DevSettings } from '../support/DevSettings';
import { Network } from '../support/Network';
import { getErrorSafeContainer } from './AppContainer';
import React from 'react';
import { Button } from '../components/button/Button';
import { ChatIcon } from '../components/icons/Icons';
import { IntercomService } from './intercom/IntercomService';
import { Container } from '@meraki-internal/react-dependency-injection';

const maybeGet = <T,>(container:Container, key: any): T | undefined => {
    try{
        return container.get(key as any);
    }
    catch (err:any){
        return undefined;
    }
};

export const ErrorPage: React.FC<{ error: any }> = ({ error }) => {

    // NOTE... we may have a very limited IOC, see ErrorSafeContainerContext
    const container = getErrorSafeContainer();

    const devSettings = container.get(DevSettings);
    const { showErrorInfo } = devSettings;

    // TODO: replicate smarter generic from container.get
    const intercom = maybeGet<IntercomService>(container, IntercomService);

    const network = container.get(Network);
    const isOffline = !network.isOnline;

    let errorInfo = error.stack || error.toString();
    if (!errorInfo.includes(error.message)) {
        errorInfo = error.message + '\n' + errorInfo;
    }
    const header = isOffline ?
        `` // intentionally no header per Zannah
        : `We're sorry, something seems to have gone wrong.`;
    const message = isOffline ?
        `It looks like you are not connected to the internet. Please try closing and reopening the app when you are back online.`
        : `We've been notified about this problem, and are looking into it. Please try closing and reopening the app.`;

    return (
        <StandalonePage showLogo={true}>
            <div style={{maxWidth: 600, margin: 'auto'}}>

                <h2 style={{ marginBottom: 20 }}>{header}</h2>

                <p>{message}</p>

                {showErrorInfo &&
                    <div style={{ background: '#eee', width: '100%', fontSize: 8, padding: '5px 10px', marginTop: 20 }}>
                        <pre style={{ marginLeft: 10 }}>
                            {errorInfo}
                        </pre>
                    </div>
                }

                {intercom && intercom.isEnabled() &&
                    <div style={{textAlign: 'center', marginTop: 30}}>
                        <Button color="secondary" fill="outline" onClick={() => intercom.displayMessenger()}><ChatIcon slot="start" />Contact Us</Button>
                    </div>
                }

            </div>
        </StandalonePage>
    );
};
