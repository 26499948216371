import { useIonAlert, AlertOptions, UseIonAlertResult } from '@ionic/react';
import { MenuViewModel } from '../menu/MenuViewModel';
import { Logger } from '../support/debug/Logger';
import { Network } from '../support/Network';
import { SplashScreen } from '@capacitor/splash-screen';
import { UserFacingError } from '../support/UserFacingError';

export type AlertButtonOptions = string | { text: string,  handler: () => boolean | void | Promise<void> };

export interface IAlertOptions {
    header: string;
    subHeader?: string;
    message: string;
    buttons?: AlertButtonOptions[];
    backdropDismiss?: boolean;
    leftAligned?: boolean;
    size?: 'small' | 'large';
}

let __showAlert: UseIonAlertResult[0] | undefined;

/**
 * This has zero IOC dependencies so it can bind before <AppContainer> and
 * be used to show alerts during app start
 */
export const AlertBinder: React.FC = () => {
    const [showAlert] = useIonAlert();
    __showAlert = showAlert;
    return null;
};

/**
 * This has minimal IOC dependencies, so it can be used throughout app start.
 *
 * If additional dependencies are needed, that aren't available early in app start, b/c
 * they are registered specially, in AppContainer, then use property injection and handle
 * that they might not exist
 */
export class AlertPresenter {
    static inject = () => [Network, Logger];
    constructor(private network: Network, private logger: Logger) {
        if (!__showAlert){
            throw new Error(`AlertPresenter was instantianted before AlertBinderV2 was rendered`);
        }
        this._showAlert = __showAlert;
    }

    menu?: MenuViewModel;

    private _showAlert!: (options: AlertOptions) => Promise<void>;

    showAlert = ({ header, subHeader, message, buttons = ['OK'], backdropDismiss = false, leftAligned = false, size = 'small' }: IAlertOptions) => {
        SplashScreen.hide();

        let cssClass: string | undefined;
        if (leftAligned) {
            cssClass += ' left-aligned-alert';
        }
        if (size === 'large') {
            cssClass += ' large-alert';
        }

        // if the menu is open, close it, since it will obfuscate the alert
        this.menu?.close();

        // an action sheet might be animating away, so give it a chance to dissapear
        // or else our alert never shows up
        // NOTE: it also could be tied to another alert animating away but I am 99% that is not the case
        // NOTE: we could explore a better solution that actually talks to some sort of ionic
        // action sheet controller and can check there or even await...
        // NOTE: ionicShowAlert() below is in fact async, and awaits the animation internally,
        // but the way we invoke alerts doesn't make it easy to await on them
        setTimeout(() => {
            this._showAlert({ message, subHeader, buttons, header, backdropDismiss, cssClass });
        }, 100); // 10 wasn't long enough in my exploratory testing, so went with 100 to be safe
    };

    showAndLogError = (unknownError: any) => {
        // showAndLogError is often used in a catch, where error is unkown
        // we don't expect any errors to be undefined (though catch has to embrace that possiblility)
        const error = unknownError as Error & { status?: number, displayMessage?: string };;
        let message = error?.displayMessage || `We've been notified about this problem, and are looking into it. Please try again.`;

        if (!this.network.isOnline) {
            message = 'It looks like you are not connected to the internet. Please try again when you are back online.';
        }

        // log errors with 500 status or no status so the caller of this method doesn't need to remember to log
        if ((error?.status || 500) >= 500) {
            this.logger.error(error);
        }

        if (unknownError && unknownError.errorType === 'UserFacingError'){
            return this.showUserFacingError(unknownError as UserFacingError);
        }

        return this.showAlert({
            header: 'Sorry! Something went wrong',
            message
        });
    };

    private showUserFacingError = async (error: UserFacingError) => {
        const result = await this.showAlertV2({
            header: error.displayHeader || 'Sorry! Something went wrong',
            message: error.displayMessage,
            options: [
                ...(error.links || []).map(({ label }) => ({ label, id: label })),
                {label: error.okLabel || 'OK', id: 'ok'}
            ],
        });
        if (result !== 'ok'){
            const chosenLink = error.links.find(l => l.label === result);
            if (chosenLink){
                window.open(chosenLink.href);
            }
        }
    };

    showAlertV2 = async ({ header, message = '', options =  [{label: 'OK', id: 'ok'}] }: {  header: string, message?: string, options?: { label: string, id: string, role?: string }[] }): Promise<string> => {
        const msForAlertToAnimateAway = 200; // 100 wasn't long enough
        // we found that if we resolve immediately this can cause downstream issues
        // the action sheet awaiting, in order to close, would then cause a full page refresh
        // presumably b/c these 2 animations get into a bad state. So our work around is to wait
        return new Promise(resolve => {
            this.showAlert({
                header,
                message,
                buttons: options.map(({ id, label, role }) => ({
                    text: label,
                    role: role,
                    handler: () => {
                        setTimeout(() => {
                            resolve(id);
                        }, msForAlertToAnimateAway);
                    }
                }))
            });
        });
    };

    confirmDestructiveAction = async ({ message, header, destroyLabel = 'Delete', cancelLabel = 'Cancel' }: { message: string, header: string, destroyLabel?: string, cancelLabel?: string }): Promise<'destroy' | 'cancel'> => {
        return this.showAlertV2({
            header,
            message,
            options: [{
                id: 'destroy',
                label: destroyLabel,
                role: 'destructive'
            }, {
                id: 'cancel',
                label: cancelLabel,
                role: 'cancel'
            }] }) as Promise<'destroy' | 'cancel'>;
    };

    confirmCancelAction = async ({ message, header, cancelNoun }: { message: string, header: string, cancelNoun: string }): Promise<'destroy' | 'cancel'> => {
        return this.confirmDestructiveAction({message, header, destroyLabel:`Cancel ${cancelNoun}`, cancelLabel: 'Not Now'});
    };
}
