import { IonModal } from '@ionic/react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useSubscription } from '@meraki-internal/state';
import { WindyMissionMap } from './WindyMissionMap';
import { FloatingCloseButton } from '../../components/button/FloatingCloseButton';
import { WindyMapModalState } from './WindyMapModalState';
import { WindyPaywallBanner } from './WindyPaywallBanner';

export const WindyMapModal: React.FC = () => {
    const stateInstance = useInstance(WindyMapModalState);
    useSubscription(() => stateInstance);

    const onDismiss = (e: any) => {
        // invoke close(), but only if the user clicked on the backdrop (or hit the escape key)
        // (this avoids invoking handler twice when close button clicked, or close() is invoked directly)
        if (e.detail.role === 'backdrop') {
            stateInstance.close();
        }
    };

    return (
        <IonModal style={{'--width': '100%', '--height': '100%'}} isOpen={stateInstance.state.open} onWillDismiss={onDismiss}>

            <FloatingCloseButton onClick={stateInstance.close} />

            <WindyMissionMap
                center={stateInstance.state.center!}
                zoom={stateInstance.state.zoom! * 1.1}
                startTime={stateInstance.state.startTime!}
                radiusMeters={stateInstance.state.radiusMeters}
                radiusColor={stateInstance.state.radiusColor}
                radiusShape={stateInstance.state.radiusShape}
            />

            <WindyPaywallBanner />

        </IonModal>
    );
};
