import moment from 'moment';
import { Color } from '../components/common';
import { IInsurancePolicy } from './IInsurancePolicy';
import { cityMapping } from 'city-timezones';

// Stere allows a policy to be purchased in any US state or territory.
// The stere applicant_state is "DC", state code, or territory iso code.
// This creates a map from stere state code to an array of timezones
// e.g. stateTimezoneMap['VT'] = ['America/New_York']
const usCountries = ['US', 'AS', 'FM', 'GU', 'MH', 'MP', 'PR', 'PW', 'VI'];
const usCityData = cityMapping.filter(city => usCountries.includes(city.iso2) && city.timezone);
const stateTimezoneMap = usCityData.reduce((acc, city) => {
    const key = (city.province === 'District of Columbia' ? 'DC' : (city.state_ansi || city.iso2));
    acc[key] = acc[key] || [];
    if (!acc[key].includes(city.timezone)) {
        acc[key].push(city.timezone);
    }
    return acc;
}, {} as {[key: string]: string[]});

export const getTimezonesByState = (stereStateCode: string) => {
    return stateTimezoneMap[stereStateCode];
};

// backoff timer for refreshing pending policies
export const getPendingPolicyRefreshIntervalMs = (policy: IInsurancePolicy) => {
    const ONE_MIN_MS = 60 * 1000;
    const policyAgeMs = moment().diff(policy.created_at);
    if (policyAgeMs < (10 * ONE_MIN_MS)) {
        return 2 * ONE_MIN_MS;
    } else if (policyAgeMs < (60 * ONE_MIN_MS)) {
        return 10 * ONE_MIN_MS;
    } else if (policyAgeMs < (24 * 60 * ONE_MIN_MS)) {
        return 60 * ONE_MIN_MS;
    } else {
        return 24 * 60 * ONE_MIN_MS;
    }
};

export const getPolicyStatus = (policy: IInsurancePolicy) => {
    // to be safe, only consider policy active if it's active in every timezone in the state
    const timezones = getTimezonesByState(policy.params.applicant_state);
    const isPast = timezones.some(tz => moment.tz(policy.end_date, tz).add(1, 'minute').isBefore(moment()));
    const isFuture = timezones.some(tz => moment.tz(policy.start_date, tz).add(1, 'minute').isAfter(moment()));
    return isPast ? 'Expired' : isFuture ? 'Scheduled' : 'Active';
};

export const getPolicyColor = (policy: IInsurancePolicy) => {
    if (policy.pending) {
        return 'warning';
    }
    return {
        'Expired': 'danger',
        'Active': 'success',
        'Scheduled': 'warning'
    }[getPolicyStatus(policy)] as Color;
};

export const getPolicyType = (policy: IInsurancePolicy) => policy.premium.term === 'month' ? 'Monthly' : 'Annual';

export const formatPeriod = (policy: IInsurancePolicy) =>  `${moment(policy.start_date).format('M/D/YYYY')} - ${moment(policy.end_date).format('M/D/YYYY')}`;

// stere returns these formatted, so remove dollar symbol and comma separators
export const limitAsNumber = (limit: string) => {
    const value = parseInt(limit.replaceAll(/[$,]/g, ''));
    return isNaN(value) ? 0 : value;
};

export const formatCoverageLimit = (limit: string) => {
    return `$${limitAsNumber(limit).toLocaleString()}`;
};

export const getLiabilityLimit = (policy: IInsurancePolicy) => {
    // stere gives us policy.premium.additional_info.total_coverage, but
    // this is the sum of all liabilities, what we want is just drone liability,
    // which we need to extract from the coverages object
    for (const section of policy.coverage.sections) {
        for (const item of section.children) {
            // newer policies have code, but still need to check label for older policies :(
            if (item.code === 'drone_liability' || item.label.default === 'Drone liability, single limit including Property Damage and Bodily Injury') {
                return limitAsNumber(item.limit?.default || '0');
            }
        }
    }
    return 0;
};

export const getPremium = (policy: IInsurancePolicy) => {
    return Number(policy.premium.premium_value);
};

export const getTaxes = (policy: IInsurancePolicy) => {
    return Number(policy.premium.taxesSurcharges);
};

export const getFees = (policy: IInsurancePolicy) => {
    return Number(policy.payments[0].payment_data?.transaction_fee || 0);
};

export const getPayment = (policy: IInsurancePolicy) => {
    // should be same as getPremium(policy) + getTaxes(policy) + getFees(policy), but may be off by 1c due to rounding
    return Number(policy.payments[0].amount.value);
};
