import { DevSettings } from '../../support/DevSettings';
import { StorageProvider } from '../../support/StorageProvider';

export type IEnvironment = 'live' | 'staging' | 'faa-testing' | 'local';

export class EnvStorage {
    static inject = () => [StorageProvider];
    constructor(private storage: StorageProvider){}
}

export class EnvConfiguration{
    constructor(){
        throw new Error('should not use ctor, see AppContainer');
    }

    ENV: IEnvironment;
    API_BASE_URL: string;
    TILE_VERSION_URL:string;
    SMARTLOOK_API_KEY: string;
    STERE_API_KEY: string;
    STERE_ENV: 'staging' | 'production';
    STERE_DOMAIN: string;
    MIXPANEL_TOKEN: string;
    MAPBOX_TOKEN: string;
    REVENUE_CAT_ANDROID_PUBLIC_KEY: string;
    REVENUE_CAT_IOS_PUBLIC_KEY: string;
}

export const getConfiguration = ({ env, forceMapboxToUseStaging }: { env: IEnvironment, forceMapboxToUseStaging?: boolean }) => {

    const mapBoxConfig = {
        // NOTE: none of these are currently restricted by domain because ios webview with custom url scheme does not send referrer
        local: 'pk.eyJ1IjoiYXV0b3B5bG90LXN0YWdpbmciLCJhIjoiY2x2Y29oaW9hMGl6eDJqbGg1dnkzazFiZyJ9.Flrl630REKu-jeBldUWQyA',
        staging: 'pk.eyJ1IjoiYXV0b3B5bG90LXN0YWdpbmciLCJhIjoiY2x2Y2wwMzM3MGpmMjJqcnN6aG5jN2MweiJ9.uVv_-3wz9WxuXXY_9iJhZw',
        live: 'pk.eyJ1IjoiYXV0b3B5bG90IiwiYSI6ImNsdmNjbW1mbjBoMGkyaWxoeDRjczZiMm4ifQ.pPsPFV6k91YUaLaGBpP6ew'
    };

    // set by tests and screenshots
    if (forceMapboxToUseStaging){
        mapBoxConfig.live = mapBoxConfig.staging;
    }

    // TODO: make this less error prone where it should be copy of staging

    const config: {[env: string]: EnvConfiguration} = {
        live: {
            ENV: 'live',
            API_BASE_URL: 'https://api.autopylot.io/v1',
            TILE_VERSION_URL: 'https://tiles.autopylot.io/active-version.json',
            SMARTLOOK_API_KEY: '77b3eaa7717bc5a7c776113cd9a6330ee32fd223',
            STERE_API_KEY: 'a65e3542-e357-4c9d-aad5-17accb8e5a5d',
            STERE_ENV: 'production',
            STERE_DOMAIN: 'hosted-insurance.us.stere.io',
            MIXPANEL_TOKEN: '8cc02418d74660fd70bc7fbab0723e75',
            MAPBOX_TOKEN: mapBoxConfig.live,
            REVENUE_CAT_ANDROID_PUBLIC_KEY: 'goog_zwQzfDVBLzfGqTJzlpvhkSgDSVC',
            REVENUE_CAT_IOS_PUBLIC_KEY: 'appl_QqcryTToEyTQfnAxUTkvVUOgdbN'
        },
        'faa-testing': {
            ENV: 'faa-testing',
            API_BASE_URL: 'https://api.faa-testing.autopylot.io/v1',
            TILE_VERSION_URL: 'https://tiles.faa-testing.autopylot.io/active-version.json?v=2',
            SMARTLOOK_API_KEY: '', // intentionally disabled
            STERE_API_KEY: '4701eff1-8943-4ef2-9544-9e92420abeae',
            STERE_ENV: 'staging',
            STERE_DOMAIN: 'hosted-insurance.us.sandbox.stere.io',
            MIXPANEL_TOKEN: '', // intentionally disabled
            MAPBOX_TOKEN: mapBoxConfig.staging,
            REVENUE_CAT_ANDROID_PUBLIC_KEY: '',
            REVENUE_CAT_IOS_PUBLIC_KEY: ''
        },
        staging: {
            ENV: 'staging',
            API_BASE_URL: 'https://api.staging.autopylot.io/v1',
            TILE_VERSION_URL: 'https://tiles.staging.autopylot.io/active-version.json?v=2',
            SMARTLOOK_API_KEY: 'a8cf53087f277f05bbcd1cf7e19a5bae2feafd3f',
            STERE_API_KEY: '4701eff1-8943-4ef2-9544-9e92420abeae',
            STERE_ENV: 'staging',
            STERE_DOMAIN: 'hosted-insurance.us.sandbox.stere.io',
            MIXPANEL_TOKEN: '5423dac08c85d703c436d5fdfea10761',
            MAPBOX_TOKEN: mapBoxConfig.staging,
            REVENUE_CAT_ANDROID_PUBLIC_KEY: 'goog_awZfGrJKxDVtAvgMUiNnXNIWQAr',
            REVENUE_CAT_IOS_PUBLIC_KEY: 'appl_IyEbIIVMlMdAuQwOcuhxDXBftab'
        },
        local: {
            ENV: 'local',
            API_BASE_URL: 'https://api.staging.autopylot.io/v1',
            TILE_VERSION_URL: 'https://tiles.staging.autopylot.io/active-version.json?v=2',
            SMARTLOOK_API_KEY: '8b7605a3573ec1709059654405ee35cdbd666070',
            STERE_API_KEY: '4701eff1-8943-4ef2-9544-9e92420abeae',
            STERE_ENV: 'staging',
            STERE_DOMAIN: 'hosted-insurance.us.sandbox.stere.io',
            MIXPANEL_TOKEN: '',
            MAPBOX_TOKEN: mapBoxConfig.local,
            REVENUE_CAT_ANDROID_PUBLIC_KEY: 'goog_awZfGrJKxDVtAvgMUiNnXNIWQAr',
            REVENUE_CAT_IOS_PUBLIC_KEY: 'appl_IyEbIIVMlMdAuQwOcuhxDXBftab'
        }
    };

    const envConfig = config[env];

    // if `REACT_APP__API_BASE_URL` is specified in process.env, use it as `API_BASE_URL`
    // (note: create-react-app only passes through env vars with "REACT_APP__" prefix)
    const keysToCopyFromEnv: { [key:string]: keyof EnvConfiguration} = {
        'REACT_APP__API_BASE_URL': 'API_BASE_URL'
    };

    for (const environmentKey in keysToCopyFromEnv){
        if (Boolean(process.env[environmentKey])){
            const configKey = keysToCopyFromEnv[environmentKey];
            envConfig[configKey] = process.env[environmentKey] as any;
        }
    }

    return envConfig;
};

export const getEnvironment = (devSettings: DevSettings): IEnvironment => {
    const { hostname, protocol, origin } = window.location;

    // detect app installed on device (NOT dev build running locally)
    const iOSInstall = (protocol === 'io.autopylot.app:');
    const androidInstall = (origin === 'http://localhost');
    if (iOSInstall || androidInstall) {
        return devSettings.useStagingDeployChannel ? 'staging' : 'live';
    }

    // if running a deployed web build from aws, the api env
    // is derived directly from the web host
    if (hostname === 'app.staging.autopylot.io') {
        return 'staging';
    }
    if (hostname === 'app.faa-testing.autopylot.io') {
        return 'faa-testing';
    }
    if (hostname === 'app.autopylot.io') {
        return 'live';
    }

    // everything else must be a dev build running locally
    // e.g. "localhost" or a private IP like "192.168.0.1"
    return 'local';
};
