import React, { useState } from 'react';
import { IonLabel, IonText } from '@ionic/react';
import { Typewriter } from 'react-simple-typewriter';
import { Button } from '../components/button/Button';
import { BackgroundVideo } from '../components/video/BackgroundVideo';
import { usePageView } from '../support/tracking/usePageView';
import { ContentWithFooter } from '../components/page/ContentWithFooter';
import { AppStartTimings } from '../support/debug/AppStartTimings';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { DevSettings } from '../support/DevSettings';

export const Welcome: React.FC<{getStarted: () => void, signIn: (fromPage: 'Welcome') => Promise<void>}> = ({ getStarted, signIn }) => {
    usePageView('Welcome');

    const settings = useInstance(DevSettings);
    const timings = useInstance(AppStartTimings);

    const [videoIndex, setVideoIndex] = useState(0);

    const videoPath = './assets/videos/welcome';
    const videoSrcs = [`${videoPath}/check-airspace.mov`, `${videoPath}/get-laanc.mov`, `${videoPath}/manage-missions.mov`];
    const videoPosters = [`${videoPath}/check-airspace.jpg`, `${videoPath}/get-laanc.jpg`, `${videoPath}/manage-missions.jpg`];

    const videoText = ['Check Airspace', 'Get LAANC', 'Manage Missions'][videoIndex];

    return (
        <>
            <BackgroundVideo srcs={videoSrcs} posters={videoPosters} timeoutMs={5000} onNext={setVideoIndex} />

            <div style={{width: '100%', height: '100%', maxWidth: 800, maxHeight: 1200, margin: 'auto'}}>
                <ContentWithFooter data-id="welcome">

                    <div style={{fontSize: '7vw', containerType: 'inline-size', position: 'relative', display: 'flex', flexDirection: 'column', fontWeight: 'bold'} as any}>
                        <img src="./assets/images/logo-white.png" alt="AutoPylot logo" height="52px" style={{margin: '0 auto 100px'}} />
                        <IonText style={{fontSize: '12cqw', whiteSpace: 'nowrap', overflow: 'hidden'}} color="light"><Typewriter key={videoText} words={[videoText]} />&nbsp;</IonText>
                        <IonText color="light" style={{fontSize: '12cqw'}}>with AutoPylot</IonText>
                    </div>

                    <div style={{display: 'flex', flexDirection: 'column', gap: 15, textAlign: 'center', marginTop: 40}}>
                        <Button size="medium" expand="block" onClick={getStarted} dataType="continue">
                            <b>Get Started</b>
                        </Button>
                        <Button color="light" fill="clear" type="button" size="small" onClick={() => signIn('Welcome')} dataType="sign-in">
                            <b style={{fontSize: '110%'}}>Already have an account? Sign In</b>
                        </Button>
                        {settings.showTimingsOnWelcomePage && (
                            <IonLabel color="light">Took {timings.get('container mounted')} ms to start</IonLabel>
                        )}
                    </div>

                </ContentWithFooter>
            </div>
        </>
    );
};
