import { WindAltitudeIcon } from '../../components/icons/Icons';
import { ExpandableSegmentedButtons } from '../../components/segmented-buttons/ExpandableSegmentedButtons';
import { IWindyWindAltitude } from './WindyMapViewModel';

export const WindAltitudeSelector: React.FC<{level: IWindyWindAltitude, setLevel: (value: IWindyWindAltitude) => void}> = ({ level, setLevel }) => {
    return (
        <ExpandableSegmentedButtons
            options={[{ value: 'surface', label: 'Wind at 30 ft'}, { value: '100m', label: 'Wind at 300 ft' }]}
            activeValue={level}
            setActiveValue={value => setLevel(value as IWindyWindAltitude)}
            data-id="wind-altitude"
            icon={<>
                <WindAltitudeIcon size="medium" color="primary" />
                <span style={{ fontSize: 10, display: 'block', color: 'var(--ion-color-primary)', fontWeight: 'bold' }}>
                    {level === 'surface' ? '30' : '300'} ft
                </span>
            </>}
        />
    );
};
