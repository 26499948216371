import { IonItem, IonLabel } from '@ionic/react';
import { ITourKey, tourNameMap } from '../app/intercom/IntercomService';
import { NewFeatureBadge } from '../components/badge/NewFeatureBadge';
import { AdvisoryCompleteIcon, IIconComponent } from '../components/icons/Icons';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
    viewedIcon: {
        position: 'absolute',
        fontSize: 22,
        top: -4,
        left: 40,
        '& ion-icon.md': {
            position: 'relative',
            top: 4,
            left: -4
        }
    }
});

interface ListItemProps {
    icon: IIconComponent;
    tourKey: ITourKey;
    clickHandler: Function;
    toursViewed: Map<string, boolean>,
    newFeatureKey?: string
};
export const DiscoverListItem: React.FC<ListItemProps> = ({ icon: Icon, tourKey, clickHandler, toursViewed, newFeatureKey }) => {
    const classes = useStyles();

    const ViewedIcon = AdvisoryCompleteIcon;
    const viewed = toursViewed.get(tourKey);

    return (
        <IonItem
            detail={false}
            lines="inset"
            onClick={()=>clickHandler(tourKey, newFeatureKey)}
            style={{marginTop: 16}}>
            <Icon variant="outline" slot="start" />
            {viewed ? (
                <div slot="start" className={classes.viewedIcon} data-type="viewed-icon">
                    <ViewedIcon variant="filled" color='success' />
                </div>
            ) : null}
            <IonLabel>{tourNameMap[tourKey]}</IonLabel>
            {newFeatureKey &&
                <NewFeatureBadge slot="end" featureKey={newFeatureKey} forceExpired={viewed} />
            }
        </IonItem>
    );
};
