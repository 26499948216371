import { Drawer } from './Drawer';

const DemoDrawer = () => {
    return (
        <Drawer minHeight={250}>
            <div style={{padding: 30}}>
                <p>Drag to expand or collapse.</p>
            </div>
        </Drawer>
    );
};

DemoDrawer.componentName = 'Drawer';

export default DemoDrawer;
